<template>
  <div :class="wideItem == true ? 'sm:col-span-3 ' : 'sm:col-span-2'">
    <label class="block text-sm font-medium text-gray-700">
      {{ displayTitle }}
      <span v-if="helpText"
        ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
      /></span>
    </label>
    <div class="relative mt-1 block w-full">
      <p
        class="block h-8 w-full rounded-md border-gray-300 bg-gray-200 px-3 py-2 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
      >
        {{ displayValue }}
      </p>
    </div>
  </div>
</template>
<script>
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  props: {
    displayTitle: { type: String, required: false, default: "" },
    displayValue: { required: true },
    wideItem: { type: Boolean, default: true },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  components: {
    ToolTip,
  },
};
</script>

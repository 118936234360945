<template>
  <checkbox-input
    :wideItem="true"
    inputTitle="Copy Existing Crop Details"
    v-model="copyCropDetails"
    @update:model-value="copyValues"
    v-if="isNew"
  />
  <checkbox-input
    :wideItem="true"
    inputTitle="Make new crop the active crop?"
    v-model="currentCrop.active"
    v-if="isNew"
  />
  <select-search
    v-if="currentCrop"
    :wideItem="true"
    inputTitle="Crop"
    :required="true"
    :selectOptions="cropOptions"
    v-model="currentCrop.crop_type"
  />
  <component
    :is="cropType"
    :crop="this.currentCrop"
    @updateFieldDetails="updateDetails"
    v-if="currentCrop.crop_type"
  />
  <div class="sm:col-span-6"></div>
  <select-dropdown
    v-if="currentCrop"
    :wideItem="true"
    inputTitle="Planting Status"
    :selectOptions="statusOptions"
    v-model="currentCrop.details.planting_status"
    class="sm:-mt-8"
  />
  <CheckboxInput
    v-if="currentCrop"
    :wideItem="true"
    inputTitle="Irrigated"
    v-model="currentCrop.details.irrigated"
    class="sm:-mt-2"
  />
  <!-- <date-input
    :wideItem="true"
    inputTitle="From"
    helpText="Date the crop was planted, or crop year begins for planning and financial purposes."
    v-model="currentCrop.harvest_range_start"
  /> -->
  <date-input
    :wideItem="true"
    :required="true"
    inputTitle="Crop Year"
    helpText="Date the crop was harvested, or crop year ends for planning and financial purposes."
    v-model="currentCrop.harvest_range_end"
  />
  <div class="sm:col-span-1">
    <base-button
      buttonText="Mark as current crop"
      :marginLeft="false"
      @buttonClick="setActive"
      v-if="!currentCrop.active"
    />
  </div>
  <!-- </form> -->
</template>

<script>
import DateInput from "@/components/form/DateInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import GrapeDetails from "@/layouts/grower-portal/assets/fields/crop-details/GrapeDetails.vue";
import SoybeanDetails from "@/layouts/grower-portal/assets/fields/crop-details/SoybeanDetails.vue";
import CornDetails from "@/layouts/grower-portal/assets/fields/crop-details/CornDetails.vue";
import WheatDetails from "@/layouts/grower-portal/assets/fields/crop-details/WheatDetails.vue";
import BarleyDetails from "@/layouts/grower-portal/assets/fields/crop-details/BarleyDetails.vue";
import HayDetails from "@/layouts/grower-portal/assets/fields/crop-details/HayDetails.vue";
import FruitTreeDetails from "@/layouts/grower-portal/assets/fields/crop-details/FruitTreeDetails.vue";
import DefaultDetails from "@/layouts/grower-portal/assets/fields/crop-details/DefaultDetails.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { getShortDateString } from "@/components/composables/dateUtils.js";

let cropList = require("@/assets/croplist.js");

export default {
  components: {
    DateInput,
    CheckboxInput,
    SelectDropdown,
    SelectSearch,
    GrapeDetails,
    SoybeanDetails,
    CornDetails,
    HayDetails,
    WheatDetails,
    BarleyDetails,
    FruitTreeDetails,
    DefaultDetails,
    SubmitButton,
    BaseButton,
  },
  // emits: ["updateCropDetails", "deleteCrop"],
  props: {
    crop: {
      type: Object,
      default: null,
    },
    clone: {
      type: Object,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:crop", "update:activeCrop"],
  data() {
    return {
      copyCropDetails: false,
      // currentCrop: {
      //   crop_type: this.crop.crop_type,
      //   harvest_range_start: this.crop.harvest_range_start,
      //   harvest_range_end: this.crop.harvest_range_end,
      //   details: { ...this.crop.details },
      //   active: this.isNew ? false : this.crop.active,
      // },
      pendingActive: false,
      currentCrop: structuredClone(this.crop) || {
        crop_type: null,
        harvest_range_start: null,
        harvest_range_end: null,
        details: {},
        active: false,
      },
    };
  },
  methods: {
    updateDetails(details) {
      this.currentCrop.details = details;
    },
    submit() {
      this.currentCrop.harvest_range_start = getShortDateString(
        this.currentCrop.harvest_range_start,
      );
      this.currentCrop.harvest_range_end = getShortDateString(
        this.currentCrop.harvest_range_end,
      );
      const result = {
        id: this.crop.id,
        ...this.currentCrop,
      };
      this.$emit("updateCropDetails", result);
      return result;
    },
    copyValues() {
      if (this.copyCropDetails) {
        this.currentCrop = {
          crop_type: this.clone.crop_type,
          harvest_range_start: this.clone.harvest_range_start,
          harvest_range_end: this.clone.harvest_range_end,
          details: { ...this.clone.details },
          active: this.currentCrop.active,
        };
      } else {
        this.currentCrop = {
          crop_type: null,
          harvest_range_start: null,
          harvest_range_end: null,
          details: {},
          active: this.currentCrop.active,
        };
      }
    },
    setActive() {
      this.pendingActive = true;
      this.$emit("update:activeCrop", this.currentCrop);
    },
  },
  computed: {
    cropOptions() {
      let options = [];
      for (let crop of cropList) {
        options.push(crop.name);
      }
      return options;
    },
    statusOptions() {
      return [
        { label: "Initital", value: "I" },
        { label: "Prevented", value: "P" },
        { label: "Failed", value: "F" },
        { label: "Subsequent Crop", value: "S" },
        { label: "Double Crop", value: "D" },
        { label: "Repeat", value: "R" },
        { label: "Volunteer", value: "V" },
        { label: "Experimental", value: "E" },
        { label: "Initial Failed", value: "IF" },
        { label: "Initial Prevented", value: "IP" },
        { label: "Subsequent Failed", value: "SF" },
        { label: "Double-cropped Failed", value: "DF" },
        { label: "Double-cropped Prevented", value: "DP" },
      ];
    },
    cropType() {
      switch (this.currentCrop?.crop_type) {
        case "Grape":
          return "GrapeDetails";
        case "Soybeans":
          return "SoybeanDetails";
        case "Corn":
          return "CornDetails";
        case "Apple":
        case "Apricot":
        case "Cherry":
        case "Pear":
        case "Nectarine":
          return "FruitTreeDetails";
        case "Hay":
        case "Alfalfa":
          return "HayDetails";
        case "Wheat":
          return "WheatDetails";
        case "Barley":
          return "BarleyDetails";
        default:
          return "DefaultDetails";
      }
    },
  },
  watch: {
    crop: {
      handler() {
        if (this.currentCrop == this.crop) return; // avoid infinite loop when updating currentCrop,
        //structuredClone tricks it into thinking it is a new object. cloning recomended to reduce side effects
        this.currentCrop = structuredClone(this.crop) || {
          crop_type: null,
          harvest_range_start: null,
          harvest_range_end: null,
          details: {},
          active: false,
        };
      },
      deep: true,
    },
    currentCrop: {
      handler() {
        // console.log("currentCrop changed", this.currentCrop);
        this.currentCrop.changed = true;
        this.$emit("update:crop", this.currentCrop);
      },
      deep: true,
    },
  },
};
</script>

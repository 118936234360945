<template>
  <!-- <div
      class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Field Details
    </div> -->
  <div class="grid grid-cols-1 gap-x-4 sm:col-span-3 sm:grid-cols-2">
    <combo-box
      :wideItem="true"
      inputTitle="Farm Name"
      inputHtmlType="text"
      v-model="currentField.farm"
      :expand-to-content="false"
      :selectOptions="farmOptions"
      class="resize-none whitespace-pre-wrap sm:col-span-3"
      classProp="resize-none"
    />
    <text-input
      :wideItem="true"
      :required="true"
      inputTitle="Field Name"
      inputHtmlType="text"
      v-model="currentField.name"
    />

    <div class="sm:col-span-3">
      <number-input
        inputTitle="Acres"
        :required="true"
        placeholder="Draw your field to calculate"
        v-model="currentField.acres"
      />
    </div>
  </div>
  <div class="flex h-60 sm:col-span-3" v-if="field?.id">
    <a @click="$emit('mapClicked')" class="flex w-full">
      <MapViewer
        :layers="layers"
        width="w-96"
        height="h-full"
        v-if="field?.geometry"
      />
      <span
        v-else
        class="flex h-full w-full items-center justify-center bg-gray-200 text-gray-500"
        >No Field Defined. Click Here to Draw</span
      >
    </a>
  </div>
  <!-- </form> -->
</template>

<script>
// import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import ComboBox from "@/components/form/ComboBox.vue";
import TextInput from "@/components/form/TextInput.vue";
import MapViewer from "@/components/map/MapViewer.vue";

function countDecimals(num) {
  if (num) {
    if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
    return num.toString().split(".")[1].length;
  }
}

export default {
  emits: ["update:field", "mapClicked"],
  props: {
    field: {
      type: Object,
      default: null,
    },
  },
  components: {
    // VariableStringTypeInput,
    NumberInput,
    TextInput,
    ComboBox,
    MapViewer,
  },
  data() {
    let { acres, ...properties } = this.field.properties;
    if (countDecimals(acres) > 2) {
      acres = parseFloat(acres.toFixed(2));
    }
    let layers = [
      {
        name: "field",
        geojson: {
          type: "Feature Collection",
          features: [this.field],
        },
      },
    ];

    return {
      currentField: { acres, ...properties },
      layers,
    };
  },
  computed: {
    farmOptions() {
      let fields = this.$store.state.fields.fields;
      let farms = fields.map((field) => field.properties.farm);
      return [...new Set(farms)];
    },
  },
  methods: {
    // submit() {
    //   this.$emit("updateFieldDetails", this.currentField);
    // },
  },
  watch: {
    "field.properties.acres"(acres) {
      // update acres if field map changes
      this.currentField.acres = acres;
    },
    "field.properties.name"(name) {
      // update tooltip name on map if name changes
      this.currentField.name = name;
      this.layers = [
        {
          name: "field",
          geojson: {
            type: "Feature Collection",
            features: [this.field],
          },
        },
      ];
    },
    currentField: {
      handler() {
        // console.log("currentField changed", this.currentField);
        let newField = structuredClone(this.field); // get copy of field with all properties not shown in form
        newField.properties.name = this.currentField.name; // then update the properties that are shown in the form
        newField.properties.farm = this.currentField.farm;
        newField.properties.acres = this.currentField.acres;
        this.$emit("update:field", newField);
      },
      deep: true,
    },
  },
};
</script>

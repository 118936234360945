<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Listing"
    modalDescription="Are you sure you want to permanently delete this listing?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteProduceListing"
  />
  <page-card
    :headingTitle="
      currentListing
        ? currentListing.crop_type +
          ' - ' +
          currentListing.crop_details?.variety
        : ''
    "
  >
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="editProduceListingFormId"
      />
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
        dataTestid="delete-listing-button"
      />
    </template>
    <template #cardDetails>
      <produce-listing-form
        :listingId="parseInt(listingId)"
        :formId="editProduceListingFormId"
      />
    </template>
  </page-card>
</template>

<script>
import ProduceListingForm from "@/layouts/grower-portal/sell/ProduceListingForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    SubmitButton,
    ProduceListingForm,
    DeleteModal,
  },
  data() {
    return {
      modalIsActive: false,
      editProduceListingFormId: "editProduceListing",
    };
  },
  computed: {
    listingId() {
      // console.log("this.$route.params.listingId", this.$route.params.listingId);
      return this.$route.params.listingId;
    },
    currentListing() {
      // console.log(
      //   "this.$store.getters.getGrowerListingById(this.listingId)",
      //   this.$store.getters.getGrowerListingById(this.listingId),
      // );
      return this.$store.getters.getGrowerListingById(this.listingId);
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    async deleteProduceListing() {
      this.modalIsActive = false;
      await this.$store.dispatch("deleteGrowerListing", {
        id: this.listingId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-listings" });
      }
    },
  },
};
</script>

import client from "@/api/parcel/api.js";
// import { universalGetter } from "@/components/composables/universalGetter";
import _ from "lodash";

export default {
  state() {
    return {
      buyerContractOriginators: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_BUYER_CONTRACT_ORIGINATORS(state, buyerContractOriginatorData) {
      state.buyerContractOriginators = buyerContractOriginatorData;
    },
    UPDATE_BUYER_CONTRACT_ORIGINATOR(state, buyerContractOriginatorObject) {
      state.buyerContractOriginators = state.buyerContractOriginators.filter(
        function (obj) {
          return obj.id !== buyerContractOriginatorObject.id;
        },
      );
      state.buyerContractOriginators.push(buyerContractOriginatorObject);
    },
    DELETE_BUYER_CONTRACT_ORIGINATOR(state, buyerContractOriginatorObject) {
      state.buyerContractOriginators = state.buyerContractOriginators.filter(
        function (obj) {
          return obj.id !== buyerContractOriginatorObject.id;
        },
      );
    },
    RESET_BUYER_CONTRACT_ORIGINATORS(state) {
      state.buyerContractOriginators = [];
      state.filterObject = {};
    },
    SET_FILTER(state, filterObject) {
      state.filterObject = filterObject;
    },
  },
  actions: {
    async getBuyerContractOriginators({ commit }) {
      const data = await client.buyer.contractOriginators.read();
      if (data) {
        commit("SET_BUYER_CONTRACT_ORIGINATORS", data.originators);
      }
    },
    async updateBuyerContractOriginator({ commit }, buyerContractOriginator) {
      const data = await client.buyer.contractOriginators.update(
        buyerContractOriginator.id,
        buyerContractOriginator,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_BUYER_CONTRACT_ORIGINATOR", data);
        // update buyerContractOriginators list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getBuyerContractOriginators");
      }
    },
    async deleteBuyerContractOriginator({ commit }, buyerContractOriginator) {
      const data = await client.buyer.contractOriginators.delete(
        buyerContractOriginator.id,
      );
      if (data) {
        // console.log(data);
        commit("DELETE_BUYER_CONTRACT_ORIGINATOR", buyerContractOriginator);
        this.dispatch("getBuyerContractOriginators");
      }
    },
  },
  getters: {
    getBuyerContractOriginatorById: (state) => (buyerContractOriginatorId) => {
      return state.buyerContractOriginators.find(
        (buyerContractOriginator) =>
          buyerContractOriginator.originator_details.id ===
          parseInt(buyerContractOriginatorId),
      );
    },
    getBuyerContractOriginators: (state) => () => {
      return state.buyerContractOriginators;
    },
    // UNIVERSAL GETTER
    // getBuyerContractOriginators:
    //   (state) =>
    //   ({
    //     filterFunction = null,
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let derivedBuyerContractOriginators = universalGetter(
    //       {
    //         filterFunction: filterFunction,
    //         filter: filter,
    //         match: match,
    //         sort: sort,
    //         pageSize: pageSize,
    //         pageNum: pageNum,
    //       },
    //       state.buyerContractOriginators
    //     );
    //     return derivedBuyerContractOriginators;
    //   },
    getDerivedBuyerContractOriginators:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedBuyerContractOriginators =
          state.buyerContractOriginators.map((item) => ({
            ...item,
          }));
        let filteredBuyerContractOriginators = _.isEmpty(filter)
          ? expandedBuyerContractOriginators
          : _.filter(expandedBuyerContractOriginators, filter);
        let matchedBuyerContractOriginators = _.isEmpty(match)
          ? filteredBuyerContractOriginators
          : _.filter(filteredBuyerContractOriginators, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedBuyerContractOriginators = _.isEmpty(sort)
          ? matchedBuyerContractOriginators
          : _.orderBy(matchedBuyerContractOriginators, sortColumns, sortOrders);
        let paginatedBuyerContractOriginators =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedBuyerContractOriginators,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedBuyerContractOriginators;
        return paginatedBuyerContractOriginators;
      },
    getBuyerContractOriginatorsFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};

<template>
  <!-- Search Container -->
  <section aria-labelledby="filter-heading">
    <slot name="navigation"></slot>
    <h2 id="filter-heading" class="sr-only">Filters</h2>
    <div class="border-b border-gray-200 bg-white pb-4">
      <div
        class="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8"
      >
        <!-- Left Dropdown Slot -->
        <slot name="categories"></slot>
        <!-- Center Input Slot (NEEDS STYLING) -->
        <slot name="input"></slot>
        <!-- Right Filters Slot -->
        <slot name="filters"></slot>
      </div>
    </div>
    <!-- Active filters -->
    <div class="bg-gray-100">
      <slot name="activeFilters"></slot>
    </div>
  </section>
</template>

<script setup></script>

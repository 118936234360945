<template>
  <page-card headingTitle="New Activity">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newTaskFormId"
      />
    </template>
    <template #cardDetails>
      <activity-form
        :formId="newTaskFormId"
        :prepopulatedStatus="prepopulatedStatus"
      />
    </template>
  </page-card>
</template>

<script>
import ActivityForm from "@/layouts/vendor-portal/operations/VendorActivityForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";

export default {
  props: {
    prepopulatedStatus: {
      type: String,
      default: "Ready",
    },
  },
  components: {
    PageCard,
    SubmitButton,
    ActivityForm,
  },
  data() {
    return {
      newTaskFormId: "newTask",
    };
  },
};
</script>

<template>
  <div class="grid grid-cols-1 gap-4 lg:col-span-2">
    <!-- Welcome panel --
    <section aria-labelledby="profile-overview-title">
      <div class="overflow-hidden bg-white rounded-lg shadow">
        <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
        <div class="p-6 bg-white">
          <div class="sm:flex sm:items-center sm:justify-between">
            <div class="sm:flex sm:space-x-5">
              <div class="flex-shrink-0">
                <TruckIcon
                  class="flex-shrink-0 w-20 h-20 mx-auto rounded-full text-twilight-400 group-hover:text-twilight-500"
                  aria-hidden="true"
                />
                <! --<img class="w-20 h-20 mx-auto rounded-full" :src="user.imageUrl" alt="" /> --
              </div>
              <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p class="py-5 text-3xl font-medium text-gray-600">Dashboard</p>
              </div>
            </div>
          </div>
        </div>
        
        <! -- <div
          class="grid grid-cols-1 border-t border-gray-200 divide-y divide-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x"
        >
          <div
            v-for="stat in stats"
            :key="stat.label"
            class="px-6 py-5 text-sm font-medium text-center"
          >
            <span class="text-gray-900">{{ stat.value }}</span>
            {{ " " }}
            <span class="text-gray-600">{{ stat.label }}</span>
          </div>
        </div> -- >
      </div>
    </section>
    -->
    <div>
      <ControllerSitenavActions
        v-if="!isActiveProcess && buyerOrgIsController"
      />
      <AffiliateSitenavActions
        v-if="!isActiveProcess && !buyerOrgIsController"
      />
    </div>
    <!-- Actions panel -->
    <section aria-labelledby="quick-links-title">
      <div
        class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0"
      >
        <h2 class="sr-only" id="quick-links-title">Quick links</h2>
        <div
          v-for="(action, actionIdx) in actions"
          :key="action.name"
          :class="[
            actionIdx === 0
              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
              : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1
              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
              : '',
            'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500',
          ]"
        >
          <component :is="action.type" :action="action" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  // AcademicCapIcon,
  BellIcon,
  // CashIcon,
  // ClockIcon,
  MenuIcon,
  // ReceiptRefundIcon,
  //   ClipboardListIcon,
  //olderIcon,
  //   BookOpenIcon,
  TruckIcon,
  XIcon,
  FlagIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import DashboardStatWidget from "@/components/widgets/DashboardStatWidget.vue";
import DashboardListWidget from "@/components/widgets/DashboardListWidget.vue";
import ChartWidget from "@/components/widgets/ChartWidget.vue";
import StatsPanel from "@/components/widgets/StatsPanel.vue";
import ControllerSitenavActions from "@/layouts/buyer-portal/bp-controller/ControllerSitenavActions.vue";
import AffiliateSitenavActions from "@/layouts/buyer-portal/bp-affiliate/AffiliateSitenavActions.vue";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuIcon,
    SearchIcon,
    TruckIcon,
    XIcon,
    FlagIcon,
    DashboardStatWidget,
    DashboardListWidget,
    ChartWidget,
    StatsPanel,
    ControllerSitenavActions,
    AffiliateSitenavActions,
  },
  data() {
    return {};
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    buyerOrgIsController() {
      if (this.currentOrg.feature_flags.includes("bp-controller")) {
        return true;
      } else {
        return false;
      }
    },
    isActiveProcess() {
      if (this.buyerOrgIsController) {
        return this.$store.getters.getBuyerControllerActionsActiveProcess;
      } else {
        return this.$store.getters.getBuyerActionsActiveProcess;
      }
    },
    allocations() {
      if (this.buyerOrgIsController)
        return this.$store.getters.getAllControllerAllocations;
      else {
        return this.$store.getters.getAllBuyerAllocations;
      }
    },
    actions() {
      let actions = [];
      let allocations = this.allocations;
      let crop_variety = {}; // variety:{ava:}
      let ranches = {};
      let fields = {};
      // let ranch_count = 0;
      let variety_count = 0;
      let field_count = 0;
      let acres_count = 0;
      //TODO: let tons_count = 0;
      //console.log("chart data", allocations);
      allocations.forEach((allocation) => {
        let key = allocation.shared_data.crop_details?.crop_type;

        if (allocation?.crop_details?.details?.variety)
          key =
            allocation.shared_data.crop_details.details.variety +
            " " +
            allocation.shared_data.crop_details?.crop_type;
        if (!crop_variety[key]) {
          crop_variety[key] = {};
          crop_variety[key]["acres"] = Math.round(allocation.acres);
          variety_count++;
          acres_count += Math.round(allocation.acres);
        } else {
          crop_variety[key]["acres"] += Math.round(allocation.acres);
          acres_count += Math.round(allocation.acres);
        }
        //TODO: where to get ranch name from?
        let ranch = "";
        // this.$store.getters.getCustomerFieldById(allocation.field)          ?.properties?.farm;
        if (!ranches[ranch]) {
          ranches[ranch] = 1;
          // ranch_count++;
        }
        let field = allocation.field;
        if (!fields[field]) {
          fields[field] = 1;
          field_count++;
        }
      });
      let variety_sold = [];

      for (let variety in crop_variety) {
        variety_sold.push(crop_variety[variety].acres);
      }
      // console.log(
      //   "chart data",
      //   allocations,
      //   crop_variety,
      //   variety_sold,
      //   ranches,
      //   ranch_count,
      // );
      let action = {};
      if (allocations?.length > 0) {
        action = {
          type: "ChartWidget",
          icon: FlagIcon,
          name: "Acres Allocated",
          // value: tasksDue,
          blurb: "How many acres have been allocated on orders shared with me?",
          routeName: "buyer-orders",
          iconForeground: "text-teal-700",
          iconBackground: "bg-teal-50",
          series: [
            {
              name: "Acres Bought",
              data: variety_sold, //[44, 55, 41, 67, 22, 43],
            },
          ],
          chartOptions: {
            chart: {
              type: "bar",
              height: 200,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                //borderRadius: 20,
              },
            },
            xaxis: {
              type: "category",
              categories: Object.keys(crop_variety),
              labels: { trim: true, offsetX: 2 },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        };
      }
      actions.push(action);
      action = {
        type: "StatsPanel",
        icon: FlagIcon,
        name: "Grower Summary",
        // value: tasksDue,
        // blurb: "What orders do I have today?",
        routeName: "buyer-orders",
        iconForeground: "text-teal-700",
        iconBackground: "bg-teal-50",
        stats: [
          // {
          //   title: "Ranches",
          //   value: ranch_count,
          //   // desc: "Vineyards allocated on orders shared with me",
          //   id: 1,
          // },
          {
            title: "Varieties",
            value: variety_count,
            // desc: "Varieties allocated on orders shared with me",
            id: 2,
          },
          {
            title: "Fields",
            value: field_count,
            // desc: "Fields allocated on orders shared with me",
            id: 3,
          },
          {
            title: "Acres",
            value: acres_count,
            // desc: "Tons allocated on orders shared with me",
            id: 4,
          },
          // {
          //   title: "Tons",
          //   value: tons_count,
          //   // desc: "Tons allocated on orders shared with me",
          //   id: 4,
          // },
        ],
      };
      actions.push(action);
      return actions;
    },
  },
};
</script>

<template>
  <page-card :headingTitle="'Create New Product'">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="true"
        :formId="newProductFormId"
      />
    </template>
    <template #cardDetails>
      <vendor-product-form :formId="newProductFormId" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import VendorProductForm from "@/layouts/vendor-portal/products/VendorProductForm.vue";
// import ListingSectionDetailsForm from "@/layouts/common/listing/section-forms/ListingSectionDetailsForm.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    VendorProductForm,
  },
  data() {
    return {
      newProductFormId: "createVendorProduct",
    };
  },
};
</script>

<template>
  <page-card :headingTitle="'Delivery: ' + $route.params.deliveryId">
    <template #cardDetails>
      <form
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <number-input
          :wideItem="true"
          inputTitle="Crop Order"
          :displayOnly="true"
          :displayOnlyValue="currentDelivery.crop_order"
        />
        <text-input
          :wideItem="true"
          inputTitle="Delivery Location"
          :displayOnly="true"
          :displayOnlyValue="currentDelivery.delivery_location"
        />
        <number-input
          :wideItem="true"
          inputTitle="Quantity"
          :displayOnly="true"
          :displayOnlyValue="currentDelivery.quantity_delivered"
        />
        <text-input
          :wideItem="true"
          inputTitle="Units"
          :displayOnly="true"
          :selectOptions="unitsOptions"
          :displayOnlyValue="currentDelivery.quantity_units"
        />
      </form>
    </template>
  </page-card>
  <div class="mt-2"></div>
  <div v-if="attachments.length === 0" class="text-center">
    <h3 class="mt-2 text-sm font-semibold text-gray-900">
      There's Nothing Here
    </h3>
    <p class="mt-1 text-sm text-gray-500">No attachments have been added.</p>
  </div>
  <base-table v-else>
    <template #headerCells>
      <table-header-cell :isLeadingCell="true" headerText="Attachment" />
      <table-header-cell :isEmptyHeader="true" headerText="Open Attachment" />
    </template>
    <template #bodyRows>
      <tr v-for="attachment in attachments" :key="attachment.id">
        <table-body-cell
          :cellData="attachment.filename"
          :isLeadingCell="true"
        />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="View"
          @buttonCellClick="openFileTab(attachment.url)"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";

export default {
  components: {
    PageCard,
    TextInput,
    NumberInput,
    BaseTable,
    TableBodyCell,
    TableHeaderCell,
  },
  data() {
    const deliveryId = this.$route.params.deliveryId;

    const currentDelivery =
      this.$store.state.buyerDeliveries.buyerDeliveries.find(
        (d) => d.id === parseInt(deliveryId),
      );

    return {
      modalIsActive: false,
      deliveryId,
      unitsOptions: ["Tons", "Acres", "Bushels"],
      currentDelivery,
    };
  },
  computed: {
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(
        this.currentDelivery?.crop_order,
      );
    },
    attachments() {
      const attachments = this.currentDelivery?.attachments || [];
      // console.log("attachments", attachments);
      return attachments;
    },
  },
  methods: {
    openFileTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

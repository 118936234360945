<template>
  <a
    aria-label="tooltip"
    class="relative rounded-full focus:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 md:mt-0"
    @mouseover="showTooltip = true"
    @mouseleave="showTooltip = false"
  >
    <span class="ml-1 text-xs text-blue-500">&#9432;</span>

    <div
      id="tooltip1"
      role="tooltip"
      class="absolute left-0 z-50 -mt-8 ml-8 rounded bg-gray-100 p-2 shadow-lg transition duration-150 ease-in-out"
      :class="customClass"
      v-if="showTooltip"
    >
      <!-- triangle for tooltip popup-->
      <svg
        class="absolute bottom-0 left-0 top-0 -ml-2 h-full"
        width="9px"
        height="16px"
        viewBox="0 0 9 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Tooltips-"
            transform="translate(-874.000000, -1029.000000)"
            fill="#f3f4f6"
          >
            <g
              id="Group-3-Copy-16"
              transform="translate(850.000000, 975.000000)"
            >
              <g id="Group-2" transform="translate(24.000000, 0.000000)">
                <polygon
                  id="Triangle"
                  transform="translate(4.500000, 45.000000) rotate(-90.000000) translate(-4.500000, -62.000000) "
                  points="4.5 57.5 12.5 66.5 -3.5 66.5"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <p class="pb-1 text-sm font-bold text-gray-800" v-if="helpTitle">
        {{ helpTitle }}
      </p>
      <div
        v-html="helpText"
        class="prose pb-3 text-xs leading-4 text-gray-600"
      ></div>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    helpTitle: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "Help Text",
    },
    width: {
      type: String,
      default: "w-64",
    },
  },
  components: {},
  data() {
    return {
      showTooltip: false,
    };
  },
  computed: {
    customClass() {
      return this.width;
    },
  },
};
</script>

<template>
  <ul class="timeline">
    <!-- <li > -->
    <!-- Item {{ item.id }} -->
    <timeline-item
      v-for="item in items"
      :key="item.id"
      class="ti"
      :item="item"
      @timeline-delete="$emit('timeline-delete', item)"
      @timeline-edit="$emit('timeline-edit', item)"
    />
    <!-- </li> -->
  </ul>
</template>

<script>
// import _ from "lodash";
import TimelineItem from "@/components/calendar/TimelineItem.vue";
export default {
  props: ["items"],
  events: ["timeline-delete", "timeline-edit"],
  components: {
    TimelineItem,
  },
};
</script>
<style lang="scss">
@import "https://fonts.googleapis.com/css?family=Libre+Franklin";

body {
  font-family: "Libre Franklin", sans-serif;
}

#timeline-header {
  font-size: 26px;
}

.canceled {
  text-decoration: line-through;
}

.timeline-panel.today {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 0px;
  margin-bottom: 10px;
  background: #000;
  &:before {
    visibility: hidden !important;
    display: none !important;
  }
  &:after {
    visibility: hidden !important;
    display: none !important;
  }
}

.timeline-badge.warning {
  top: -20px !important;
}

.timeline-panel.past {
  background: #eee;
  &:after {
    border-right: 14px solid #eee !important;
  }
}

.timeline-panel.main_element {
  font-weight: bolder;
  color: #ffffff !important;
  background: #0196a3;
  border-color: #0196a3 !important;
  &:after {
    border-right: 14px solid #0196a3 !important;
  }
}

.timeline-panel.selected_past {
  font-weight: bolder;
  color: #ffffff !important;
  background: #333;
  border-color: #333 !important;
  &:after {
    border-right: 14px solid #333 !important;
  }
}

.timeline {
  list-style: none;
  padding: 10px 0 10px;
  position: relative;
  width: 420px;

  &:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
  }

  > li {
    margin-bottom: 10px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    > .timeline-panel {
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 100px;
      padding: 10px;
      position: relative;

      .timeline-heading {
        display: flex;
        flex-wrap: nowrap;
        .timeline-panel-controls {
          // position: absolute;
          // right: 8px;
          // top: 5px;
          margin-left: auto;
          display: flex;

          .timestamp {
            display: inline-block;
            min-width: 100px;
          }

          .controls {
            display: flex;
            flex-direction: row;
            padding-right: 5px;
            border-left: 1px solid #aaa;

            a {
              color: #999;
              font-size: 11px;
              padding: 0 5px;

              &:hover {
                color: #333;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }

          .controls + .timestamp {
            padding-left: 5px;
          }
        }
      }
    }

    .timeline-badge {
      background-color: #999;
      border-radius: 50%;
      color: #fff;
      font-size: 1.4em;
      height: 50px;
      left: 50px;
      line-height: 52px;
      margin-left: -25px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 50px;
      z-index: 0; // this was 100, why?
    }

    .timeline-badge + .timeline-panel {
      &:before {
        border-bottom: 15px solid transparent;
        border-left: 0 solid #ccc;
        border-right: 15px solid #ccc;
        border-top: 15px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -15px;
        right: auto;
        top: 26px;
      }

      &:after {
        border-bottom: 14px solid transparent;
        border-left: 0 solid #fff;
        border-right: 14px solid #fff;
        border-top: 14px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -14px;
        right: auto;
        top: 27px;
      }
    }
  }
}

.timeline-badge {
  &.past {
    background-color: #999 !important;
  }
  &.current {
    background-color: #0196a3 !important;
  }
  &.future {
    background-color: #2e6da4 !important;
  }
  &.undefined {
    background-color: #fcba03 !important;
  }

  &.primary {
    background-color: #2e6da4 !important;
  }
  &.success {
    background-color: #0196a3 !important;
  }
  &.warning {
    background-color: #000000 !important;
  }
  &.danger {
    background-color: #d9534f !important;
  }
  &.info {
    background-color: #5bc0de !important;
  }

  &.planning {
    background-color: #00629c !important;
  }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body {
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

.copy {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;
  > * {
    color: #444;
  }
}

/*============================
				ANIMATIONS
=============================*/
.pulse_wrap {
  animation: pulse 1.5s ease-in-out alternate infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
    transform: scale(0.95);
    //margin-left: -20px;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

<template>
  <user-settings-form
    :userId="Number($route.params.userId)"
    :isUserProfile="false"
    redirectRouteName="grower-portal-settings"
    redirectTab="1"
    :notificationEvents="[
      { event: 'task_assigned', label: 'Task Assigned' },
      { event: 'chat_received', label: 'Chat Received' },
      // { event: 'quote_request_received', label: 'Quote Request Received' },
      { event: 'quote_response_received', label: 'Quote Response Received' },
      // { event: 'order_request_received', label: 'Order Received' },
      { event: 'order_response_received', label: 'Order Response Received' },
      { event: 'order_canceled', label: 'Order Canceled' },
      {
        event: 'opportunity_received',
        label: 'Product Recommendation Received',
      },
      {
        event: 'recommendation_received',
        label: 'Activity Recommendation Received',
      },
      {
        event: 'scenario_created',
        label: 'Carbon Scenario Score Created',
        flag: 'gp-carbon',
      },
      { event: 'crop_order_created', label: 'Crop Order Created' },
      {
        event: 'crop_order_premium_created',
        label: 'Premium Added to Crop Order',
      },
    ]"
  />
</template>

<script>
import UserSettingsForm from "@/layouts/common/UserSettingsForm.vue";

export default {
  components: {
    UserSettingsForm,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

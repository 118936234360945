<template>
  <div class="col-span-6 flex justify-end print:hidden">
    <base-button
      buttonText="Print"
      :marginLeft="false"
      @buttonClick="printReport"
    />
  </div>
  <div>
    <h1 class="pb-4 text-3xl">
      Carbon Intensity Report Prepared for:
      {{ sharedScenario ? enrichedScenario.shared_org_name : org.name }}
    </h1>
    <div class="flex">
      <div class="w-2/4 border-r text-gray-500">
        <h2
          class="pb-2 pt-2 text-2xl text-gray-600"
          v-if="scenario && scenario.crop != 'other'"
        >
          CI Score<sup class="text-xs"> (1)</sup> :
          <span class="font-bold text-gray-900">{{
            scenario?.scores.ci_score
              ? scenario.scores.ci_score.toString().substring(0, 6)
              : "None"
          }}</span>
        </h2>
        <h2 class="pb-2 pt-2 text-2xl text-gray-600">
          Emissions per {{ yieldUnits(scenario?.crop) }}:
          <span class="font-bold text-gray-900"
            >{{
              scenario?.scores.carbon_per_unit.toString().substring(0, 6)
            }}
            grams ({{
              (scenario?.scores.carbon_per_unit / 453.592)?.toFixed(1)
            }}
            lbs ) CO2e</span
          >
        </h2>
        <h2 class="pb-2 pt-2 text-2xl text-gray-600">
          Total Emissions:
          <span class="font-bold text-gray-900"
            >{{
              scenario?.scores.total_carbon.toString().substring(0, 6)
            }}
            metric tons C02e</span
          >
        </h2>
        <hr />
        <h2 class="text-xl">Scenario Name: {{ scenario?.name }}</h2>
        <h2 class="text-xl">Acres: {{ scenario?.inputs?.farm_size }}</h2>
        <h2 class="text-xl">
          Crop:
          {{
            scenario
              ? scenario.crop != "other"
                ? scenario.crop
                : scenario.metadata.other_crop_type
              : ""
          }}
        </h2>
        <h2 class="text-xl" v-if="scenario && scenario.crop != 'other'">
          Yield: {{ scenario?.inputs?.crop_yield }}
          {{ yieldUnits(scenario.crop) }}/acre
        </h2>
        <h2 class="text-xl" v-else>
          Yield: {{ scenario?.inputs?.crop_yield }}
          {{ scenario?.metadata.harvest_units }}/acre
        </h2>
        <h3 class="text-xl">Generated: {{ formatDate(scenario?.updated) }}</h3>
        <h3 class="text-xl">Emissions Breakdown:</h3>
        <div id="chart" class="w-96 object-center">
          <apexchart
            v-if="scenario"
            :type="usageChart.chart.type"
            :options="usageChart.chartOptions"
            :series="usageChart.series"
          ></apexchart>
        </div>
      </div>
      <div id="chart" class="object-center pl-8 pt-4" v-if="layers">
        <fields-map
          :layers="layers"
          height="h-96"
          width="w-96"
          :hideAttribution="true"
        />
      </div>
    </div>
    <p class="pb-8 pt-8" v-if="scenario && scenario.crop != 'other'">
      <sup>(1)</sup> The CI Score is a measure of the carbon intensity of the
      crop in grams / mj per {{ yieldUnits(scenario?.crop) }}
    </p>
    <hr />
    <div className="break-before-page"></div>
    <h3 class="text-xl">Inputs:</h3>
    <table>
      <tr class="text-gray-900">
        <th></th>
        <th class="pr-8 text-left">Recorded Inputs Used:</th>
        <th class="text-left">Defaults Used:</th>
      </tr>
      <tr class="border-t-2 pt-4">
        <td class="text-xl">Energy Usage</td>
        <td></td>
        <td></td>
      </tr>
      <input-row
        label="Diesel"
        input="diesel"
        units="gallons / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Gasoline"
        input="gasoline"
        units="gallons / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Natural Gas"
        input="natural_gas"
        units="cu. ft. / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Liquefied Petroleum Gas"
        input="liquefied_petroleum_gas"
        units="gallons / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Electricity"
        input="electricity"
        units="kWh / acre"
        :scenario="scenario"
        v-if="scenario"
      />

      <tr class="border-t-2 pt-4">
        <td class="text-xl">Nitrogen Fertilizer</td>
        <td></td>
        <td></td>
      </tr>
      <template v-if="scenario?.crop == 'corn' || scenario?.crop == 'soybean'">
        <input-row
          label="Application Management"
          input="technology_for_nitrogen_management"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
      </template>

      <template
        v-if="
          scenario &&
          (scenario.crop == 'corn' ||
            scenario.crop == 'soybean' ||
            scenario.crop == 'sorghum' ||
            scenario.crop == 'rice')
        "
      >
        <input-row
          label="Ammonia Source"
          input="source_of_ammonia"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
      </template>
      <input-row
        label="Ammonia"
        input="ammonia"
        units="lbs N / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Urea"
        input="urea"
        units="lbs N / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Ammonium Nitrate"
        input="ammonium_nitrate"
        units="lbs N / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Ammonium Sulfate"
        input="ammonium_sulfate"
        units="lbs N / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Urea Ammonium Nitrate Solution"
        input="urea_ammonium_nitrate_solution"
        units="lbs N / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Monoammonium Phosphate"
        input="n_monoammonium_phosphate"
        units="lbs N / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Diammonium Phosphate"
        input="n_diammonium_phosphate"
        units="lbs N / acre"
        :scenario="scenario"
        v-if="scenario"
      />

      <tr class="border-t-2 pt-4">
        <td class="text-xl">Phosphorus Fertilizer</td>
        <td></td>
        <td></td>
      </tr>
      <input-row
        label="Monoammonium Phosphate"
        input="p_monoammonium_phosphate"
        units="lbs P / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Diammonium Phosphate"
        input="p_diammonium_phosphate"
        units="lbs P / acre"
        :scenario="scenario"
        v-if="scenario"
      />

      <tr class="border-t-2 pt-4">
        <td class="text-xl">Potash Fertilizer</td>
        <td></td>
        <td></td>
      </tr>
      <input-row
        label="K2O"
        input="potash_fertilizer"
        units="lbs K / acre"
        :scenario="scenario"
        v-if="scenario"
      />

      <tr class="border-t-2 pt-4">
        <td class="text-xl">Lime</td>
        <td></td>
        <td></td>
      </tr>
      <input-row
        label="CaCO3"
        input="lime"
        units="lbs / acre"
        :scenario="scenario"
        v-if="scenario"
      />

      <tr class="border-t-2 pt-4">
        <td class="text-xl">Herbicide</td>
        <td></td>
        <td></td>
      </tr>
      <input-row
        label="Herbicide"
        input="herbicide"
        units="grams / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Insecticide"
        input="insecticide"
        units="grams / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <tr class="border-t-2 pt-4">
        <td class="text-xl">Cover Crop</td>
        <td></td>
        <td></td>
      </tr>
      <input-row
        label="Cover Crop Rotation"
        input="cover_crop"
        units=""
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Cover Crop Farming Energy"
        input="cover_crop_farming_energy"
        units="MJ / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Cover Crop Herbicide Application Rate"
        input="cover_crop_herbicide_application_rate"
        units="lbs / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Cover Crop Yield"
        input="cover_crop_yield"
        units="bu / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <tr class="border-t-2 pt-4">
        <td class="text-xl">Manure</td>
        <td></td>
        <td></td>
      </tr>
      <input-row
        label="Manure Applied"
        input="manure"
        units=""
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Swine"
        input="swine"
        units="tons / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Dairy Cow"
        input="dairy_cow"
        units="tons / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Beef Cattle"
        input="beef_cattle"
        units="tons / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Chicken"
        input="chicken"
        units="tons / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Manure Application Energy"
        input="manure_application_energy"
        units="Btu / acre"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Manure Transportation Distance"
        input="manure_transportation_distance"
        units="miles"
        :scenario="scenario"
        v-if="scenario"
      />
      <input-row
        label="Manure Transportation Energy"
        input="manure_transportation_energy"
        units="BTU / ton / mile"
        :scenario="scenario"
        v-if="scenario"
      />

      <!-- Crop Specific Section -->
      <template v-if="scenario?.crop == 'corn' || scenario?.crop == 'soybean'">
        <tr class="border-t-2 pt-4">
          <td class="text-xl">Soil Organic Carbon</td>
          <td></td>
          <td></td>
        </tr>
        <input-row
          label="Cover Crop"
          input="cover_crop_rotation"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
        <input-row
          label="Manure"
          input="manure"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
        <input-row
          label="Tillage"
          input="tillage"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />

        <!-- Need to update the inputs to include the following --
          <input-row label="SOC Emissions" input="soc_emissions" units="" :scenario="scenario"
v-if="scenario" />
       -->
        <tr class="border-t-2 pt-4">
          <td class="text-xl">Location</td>
          <td></td>
          <td></td>
        </tr>
        <input-row
          label="State"
          input="location_state"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
        <input-row
          label="County"
          input="location_county"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
        <input-row
          label="FIPS"
          input="location_fips"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
        <input-row
          label="Climate Zone"
          input="climate_zone"
          units=""
          :scenario="scenario"
          v-if="scenario"
        />
      </template>

      <template v-if="scenario?.crop == 'rice'">
        <tr class="border-t-2 pt-4">
          <td class="text-xl">Farming Practices</td>
          <td></td>
          <td></td>
        </tr>
        <tr class="border-t">
          <td class="pr-8">Water Regime During Cultivation</td>
          <td>
            <div v-if="scenario.inputs?.water_regime_during_cultivation">
              {{ scenario.inputs.water_regime_during_cultivation }}
            </div>
          </td>
          <td>
            <div v-if="!scenario.inputs?.water_regime_during_cultivation">
              {{ scenario.defaults.water_regime_during_cultivation }}
            </div>
          </td>
        </tr>
        <tr class="border-t">
          <td class="pr-8">Water Regime in Preseason</td>
          <td>
            <div v-if="scenario.inputs?.water_regime_in_preseason">
              {{ scenario.inputs.water_regime_in_preseason }}
            </div>
          </td>
          <td>
            <div v-if="!scenario.inputs?.water_regime_in_preseason">
              {{ scenario.defaults.water_regime_in_preseason }}
            </div>
          </td>
        </tr>
        <tr class="border-t">
          <td class="pr-8">Time for Straw Incorporation</td>
          <td>
            <div v-if="scenario.inputs?.time_for_straw_incorporation">
              {{ scenario.inputs.time_for_straw_incorporation }}
            </div>
          </td>
          <td>
            <div v-if="!scenario.inputs?.time_for_straw_incorporation">
              {{ scenario.defaults.time_for_straw_incorporation }}
            </div>
          </td>
        </tr>
      </template>
      <template v-if="scenario?.crop == 'sugarcane'">
        <tr class="border-t-2 pt-4">
          <td class="text-xl">Farming Practices</td>
          <td></td>
          <td></td>
        </tr>
        <!-- apply_soil_amendment -->
        <tr class="border-t">
          <td class="pr-8">Apply Soil Amendment</td>
          <td>
            <div v-if="scenario.inputs?.apply_soil_amendment">
              {{ scenario.inputs.apply_soil_amendment }}
            </div>
          </td>
          <td>
            <div v-if="!scenario.inputs?.apply_soil_amendment">
              {{ scenario.defaults.apply_soil_amendment }}
            </div>
          </td>
        </tr>
      </template>
    </table>

    <hr />
  </div>
  <!-- <img :src="getImage('carbon-footprint.jpg')" class="" /> -->
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
import { getDateString } from "@/components/composables/dateUtils.js";
import FieldsMap from "@/components/map/MapViewer.vue";
import CarbonScenarioReportInput from "./CarbonScenarioReportInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
export default {
  name: "CiReport",
  props: {
    scenarioId: {
      type: [String, Number],
      required: true,
    },
    sharedScenario: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FieldsMap,
    apexchart: VueApexCharts,
    InputRow: CarbonScenarioReportInput,
    BaseButton,
  },
  computed: {
    scenario() {
      if (this.sharedScenario) {
        return this.$store.getters.getSharedScenarioById(this.scenarioId);
      } else {
        return this.$store.getters.getScenarioById(this.scenarioId);
      }
    },
    enrichedScenario() {
      if (this.sharedScenario) {
        return this.$store.getters.getEnrichedVendorSharedScenarios({
          filter: { id: parseInt(this.scenarioId) },
        })[0];
      } else {
        return null;
      }
    },
    org() {
      return this.$store.getters.getCurrentOrg || {};
    },
    fields() {
      return this.$store.state.fields.fields;
    },
    layers() {
      let layers = [];
      let tempFields = [];
      if (this.sharedScenario === true || this.fields.length === 0) {
        return null;
      }
      if (this.scenario?.metadata?.fields?.length > 0) {
        let fieldIds = this.scenario.metadata.fields;
        let selectedFields = [];
        for (let fieldId of fieldIds) {
          let field = this.fields.find((f) => f.id == fieldId);
          if (field) {
            selectedFields.push(field);
          }
        }
        tempFields = selectedFields;
      } else {
        return null;
      }
      let fieldJSON = {
        type: "FeatureCollection",
        features: tempFields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Fields",
        geojson: fieldJSON,
        style: { fillColor: "blue", opacity: 0.5, weight: 1, color: "blue" },
        key: "",
      };
      layers.push(fieldLayer);
      return layers; // [{"name": "tractor","geojson":this.geojson}];
    },
    usageChart() {
      let chartData = {
        series: [
          this.scenario?.scores?.energy,
          this.scenario?.scores?.nitrogen_fertilizer,
          this.scenario?.scores?.co2_emission_from_field,
          this.scenario?.scores?.n2o_emission_from_field,
          this.scenario?.scores?.other_chemicals,
        ],
        chart: {
          type: "pie",
        },
        chartOptions: {
          labels: [
            "Energy",
            "Nitrogen Fertilizer",
            "CO2 Emissions",
            "N2O Emissions",
            "Other Chemicals",
          ],

          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              // console.log("val", val, "opts", opts);
              let label = opts.w.globals.labels[opts.seriesIndex];
              return label + " (" + val.toFixed(0) + "%)";
            },
          },
          tooltip: {
            y: {
              formatter: (val) => {
                let units = this.yieldUnits(this.scenario?.crop);
                return val + " g/" + units;
              },
            },
          },
          fill: {
            type: "gradient",
          },
          legend: {
            show: false,
          },
          title: {
            text: "",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
      };

      return chartData;
    },
  },
  methods: {
    formatDate(date) {
      return getDateString(date);
    },
    yieldUnits(crop) {
      switch (crop) {
        case "sugarcane":
          return "ton";
        case "rice":
          return "cwt";
        case "other":
          return this.scenario?.metadata.harvest_units;
        default:
          return "bu";
      }
    },
    getImage(img) {
      return require(`../../../assets/${img}`);
    },
    printReport() {
      var tempTitle = document.title;
      document.title = this.scenario.name + ".pdf";
      window.print();
      document.title = tempTitle;
    },
  },
};
</script>

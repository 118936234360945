<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'buyer_details.name', label: 'Name' },
          { name: 'buyer_details.contact_email', label: 'Email' },
          {
            name: 'buyer_details.contact_phone',
            label: 'Phone',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="customers"
        @update:derived-collection="derivedCustomers = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewCustomerPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="customer in derivedCustomers" :key="customer.id">
        <table-data td-label="Name">
          {{ customer.buyer_details.name }}
        </table-data>
        <table-data td-label="Email">
          {{ customer.buyer_details.contact_email }}
        </table-data>
        <table-data td-label="Phone">
          {{ customer.buyer_details.contact_phone }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'edit-grower-portal-customer',
              params: { customerId: customer.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  data() {
    return {
      derivedCustomers: [],
    };
  },
  computed: {
    customers() {
      return this.$store.getters.getGrowerCropCustomers({});
    },
  },
  methods: {
    goToNewCustomerPage() {
      this.$router.push({ name: "new-grower-portal-customer" });
    },
  },
};
</script>

<!-- Default ORDER details -->
<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  props: {
    initialOrderDetails: {
      type: Object,
      default: () => ({ orderUnits: "" }),
    },
  },
  emits: ["updateOrderDetails"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    detailsToReturn() {
      return {
        orderUnits: this.orderUnits,
      };
    },
  },
  watch: {
    detailsToReturn(value) {
      return this.$emit("updateOrderDetails", value);
    },
  },
};
</script>

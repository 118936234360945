<template>
  <div
    v-if="headingTitle != null"
    class="bg-white px-4 py-5 sm:rounded-t-lg sm:px-6"
    :class="inserted ? 'mt-2 border-l border-r border-t' : ''"
  >
    <div
      class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
    >
      <div class="ml-4 mt-4">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          {{ headingTitle }}
        </h3>
        <p v-if="headingDescription != null" class="mt-1 text-sm text-gray-500">
          {{ headingDescription }}
        </p>
      </div>
      <div
        v-if="this.$slots.buttonArea != null"
        class="ml-4 mt-4 flex-shrink-0 print:hidden"
      >
        <slot name="buttonArea"></slot>
      </div>
    </div>
  </div>
  <div
    class="bg-white shadow-sm sm:rounded-b-lg"
    :class="headingTitle == null ? 'sm:rounded-t-lg' : ''"
  >
    <div
      v-if="this.$slots.cardDetails != null"
      class="border-t border-gray-200 px-4 py-5 sm:px-6"
      :class="headingTitle == null ? 'border-none' : ''"
    >
      <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
        <slot name="cardDetails"></slot>
        <!-- Content goes here -->
      </div>
    </div>
    <!-- <div v-if="this.$slots.tabSection != null" class="px-4 py-5 border-t border-gray-200 sm:px-6">
      <slot name="tabSection"></slot>
    </div> -->
    <div
      v-if="this.$slots.componentSection != null"
      class="border-t border-gray-200 px-4 py-5 sm:px-6"
    >
      <slot name="componentSection"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["headingTitle", "headingDescription", "inserted"],
  methods: {},
};
</script>

<style></style>

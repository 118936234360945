<!-- Hauling ORDER details -->
<template>
  <div class="col-span-6">
    <!-- hide page-card grid, positioning is local -->
    <div class="grid grid-cols-2 md:h-32 md:grid-cols-10">
      <div
        class="text-left text-xs font-medium uppercase tracking-wider text-gray-500"
      >
        DELIVER TO
      </div>
      <combo-box
        inputTitle="Location"
        :selectOptions="deliveryLocations"
        v-model="destination_text"
        class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
        classProp="h-20"
        :required="true"
        :wideItem="false"
        :displayOnly="readOnly"
        :displayOnlyValue="destination_text"
        @on-blur="destination_text = $event"
      />
      <date-input
        class="col-span-2 ml-1 md:col-start-7"
        inputTitle="Harvest Date"
        :required="true"
        v-model="date"
        :displayOnly="readOnly"
        :displayOnlyValue="date"
      />
      <time-input
        class="col-span-2 ml-1"
        inputTitle="Pickup Time"
        :required="true"
        v-model="time"
        :displayOnly="readOnly"
        :displayOnlyValue="time"
      />
      <date-input
        class="col-span-2 ml-1 md:col-start-7"
        inputTitle="Delivery Date"
        v-model="deliveryDate"
        :displayOnly="readOnly"
        :displayOnlyValue="deliveryDate"
      />
      <time-input
        class="col-span-2 ml-1"
        inputTitle="Delivery Appt."
        v-model="deliveryTime"
        :displayOnly="readOnly"
        :displayOnlyValue="deliveryTime"
      />
    </div>
    <div class="grid grid-cols-2 md:grid-cols-10">
      <div
        class="text-left text-xs font-medium uppercase tracking-wider text-gray-500"
      >
        CONTENTS
      </div>
      <number-input
        class="ml-1"
        inputTitle="Tons"
        v-model="quantity"
        :step="1"
        :required="true"
        :displayOnly="readOnly"
        :displayOnlyValue="quantity"
      />
      <number-input
        class="ml-1"
        inputTitle="Loads"
        v-model="loads"
        :step="1"
        :required="true"
        :displayOnly="readOnly"
        :displayOnlyValue="loads"
      />
      <!-- <select-dropdown
          inputTitle="Trailer Type"
          :selectOptions="haulingEquipment"
          v-model="selectedEquipmentType"
          class="ml-1"
          :wideItem="true"
          :required="true"
        /> -->
    </div>
  </div>
</template>

<script>
// import SelectSearch from "@/components/form/SelectSearch.vue";
import DateInput from "@/components/form/DateInput.vue";
import TimeInput from "@/components/form/TimeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import ComboBox from "@/components/form/ComboBox.vue";

function getUTCString(date) {
  var utc = new Date(date);
  utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());

  // console.log(new Date(utc), new Date(utc).toISOString());
  return new Date(utc).toISOString();
}

export default {
  props: {
    orderDetails: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // VariableStringTypeInput,
    // SelectSearch,
    DateInput,
    TimeInput,
    NumberInput,
    ComboBox,
  },
  emits: ["updateOrderDetails"],
  data() {
    return {
      // replace
      emptyString: "",
      // selectedCompany: null,
      date: this.orderDetails
        ? this.orderDetails.datetime?.substr(0, 10)
        : null,
      time: this.orderDetails
        ? this.orderDetails.datetime?.substr(11, 5)
        : null,
      quantity: this.orderDetails ? this.orderDetails.quantity : null, //REQUIRED - int
      destination_text: this.orderDetails
        ? this.orderDetails.destination_text
        : null, //REQUIRED - string
      showMapPanel: false,
      missingFieldError: false,
      selectedEquipmentType: null,
      // remove these as we clean up
      deliveryDate: this.orderDetails
        ? this.orderDetails.deliveryDatetime?.substr(0, 10)
        : null,
      deliveryTime: this.orderDetails
        ? this.orderDetails.deliveryDatetime?.substr(11, 5)
        : null,
      loads: this.orderDetails ? this.orderDetails.loads_quantity : null,
      fieldProps: null,
    };
  },
  computed: {
    deliveryLocations() {
      let possibleLocations = [];
      //get delivery locations from previous order locations.
      let agreements = [];
      let orders = [];
      if (this.readOnly === true) {
        // orders = this.$store.getters.getDerivedVendorOrders({});
        // agreements = this.$store.getters.getDerivedVendorQuotes({
        //   filter: { service_category: "Hauling" },
        // });
        return [];
      } else {
        orders = this.$store.getters.getDerivedGrowerOrders({});
        agreements = this.$store.getters.getDerivedGrowerQuotes({
          filter: { service_category: "Hauling" },
        });
      }
      let haulingOrders = orders.filter((order) => {
        return agreements.some((agreement) => {
          return agreement.id === order.agreement;
        });
      });
      let priorDeliveries = Array.from(
        haulingOrders.map((order) => order.details.destination_text),
      );
      possibleLocations = possibleLocations.concat(priorDeliveries);
      return possibleLocations;
    },
    detailsToReturn() {
      let pickupDate =
        this.date && this.time
          ? getUTCString(new Date(this.date + " " + this.time))
          : null;
      let deliveryDate =
        this.deliveryDate && this.deliveryTime
          ? getUTCString(new Date(this.deliveryDate + " " + this.deliveryTime))
          : null;
      // console.log("dates", this.date, this.time, pickupDate, deliveryDate);
      return {
        status: "Requested",
        datetime: pickupDate,
        deliveryDatetime: deliveryDate,
        quantity: this.quantity,
        loads_quantity: this.loads,
        destination_text: this.destination_text,
      };
    },
  },
  methods: {
    partialClearForm() {
      // this.selectedField = null;
      // this.date = null;
      // this.time = null;
      this.quantity = null;
      this.loads = null;
      // this.destination_text = null;
    },
    // generateDeliveryDatetime() {
    //   if (this.deliveryDate && this.deliveryTime) {
    //     return new Date(
    //       this.deliveryDate + " " + this.deliveryTime
    //     ).toISOString();
    //   } else {
    //     return null;
    //   }
    // },
  },
  watch: {
    detailsToReturn: {
      handler: function (value) {
        this.$emit("updateOrderDetails", value);
      },
      deep: true,
    },
    quantity() {
      this.loads = Math.floor(this.quantity / 24);
      if (this.loads != this.quantity / 24) this.loads++;
    },
  },
};
</script>

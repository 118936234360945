<template>
  <div class="text-xl font-bold">What do you want to do today?</div>
  <feature-flagged
    v-for="process in processes"
    :key="process.title"
    :requires="process.featureFlagForAccess"
  >
    <ActionCardProcess
      :title="process.title"
      :description="process.description"
      @closeProcess="removeActiveProcess"
    >
      <template #gridItems>
        <ActionCard
          v-for="(action, index) in process.actions"
          :key="action.routeObject"
          :title="action.title"
          :routeObject="action.routeObject"
          :tags="action.tags"
          :notificationCount="
            $store.getters.actionsBuyerNotificationFactory(
              action.notificationType,
            )
          "
          :stepNumber="index + 1"
          :stepGreen="action.active"
          @clickOnAction="setActiveProcess(process, index)"
        />
      </template>
    </ActionCardProcess>
  </feature-flagged>
</template>

<script>
// import { find } from "@/assets/croplist";
// import DashboardTextWidget from "@/components/widgets/DashboardTextWidget.vue";
import ActionCard from "@/components/cards/ActionCard.vue";
import ActionCardProcess from "@/components/cards/ActionCardProcess.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";

// import {
//   CurrencyDollarIcon,
//   BeakerIcon,
//   ClipboardCheckIcon,
// } from "@heroicons/vue/outline";
export default {
  components: {
    // DashboardTextWidget,
    ActionCard,
    ActionCardProcess,
    FeatureFlagged,
  },
  methods: {
    setActiveProcess(process, index) {
      this.$store.dispatch("setBuyerActionsActiveProcess", process);
      this.$store.dispatch("setActiveProcessBuyerActionToActive", index);
    },
    removeActiveProcess() {
      this.$store.dispatch("clearBuyerActionsActiveProcess");
    },
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    processes() {
      return this.$store.getters.getAllBuyerActionsProcesses;
    },
    // actions() {
    //   let actions = [];
    //   actions.push({
    //     id: "1",
    //     name: "Buy Inputs",
    //     routeName: "buyer-portal-processes",
    //     query: { filter: "category.input:true" },

    //     blurb: "Use Parcel's massive buying power to reduce your costs",
    //     icon: BeakerIcon,
    //     // iconBackground: "bg-blue-500",
    //     // iconForeground: "text-white",
    //   });
    //   if (
    //     this.$store.getters.getAllActivities.length === 0 ||
    //     this.$store.getters.getAllRecords.length === 0
    //   ) {
    //     actions.push({
    //       id: "2",
    //       name: "Enable FMS Operations",
    //       routeName: "buyer-portal-processes",
    //       blurb:
    //         "Enable operations for your account and set yourself up for operations management in Parcel",
    //       icon: CurrencyDollarIcon,
    //       // iconBackground: "bg-blue-500",
    //       // iconForeground: "text-white",
    //     });
    //   }

    //   //if (!this.currentOrg?.feature_flags.find((flag) => flag === "gp-fms")) { need to decide when to hide this
    //   actions.push({
    //     id: "3",
    //     name: "Search within our App",
    //     routeName: "buyer-portal-directory",
    //     blurb: "Explore everything Parcel can do",
    //     icon: ClipboardCheckIcon,
    //     // iconBackground: "bg-blue-500",
    //     // iconForeground: "text-white",
    //   });
    //   //}
    //   return actions;
    // },
  },
};
</script>

import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      companies: [],
      seedCompanies: [],
    };
  },
  mutations: {
    SET_COMPANIES(state, companyData) {
      state.companies = companyData;
    },
    SET_SEED_COMPANIES(state, companyData) {
      state.seedCompanies = companyData;
    },
    RESET_COMPANIES(state) {
      state.companies = [];
      state.seedCompanies = [];
    },
  },
  actions: {
    async getCompanies({ commit }) {
      const data = await client.companies.read();
      if (data) {
        commit("SET_COMPANIES", data.companies);
      }
    },
    async getSeedCompanies({ commit }) {
      const data = await client.companies.readSeeds();
      if (data) {
        commit("SET_SEED_COMPANIES", data.companies);
      }
    },
  },
  getters: {
    getCompanies: (state) => {
      return state.companies;
    },
    getCompanyById: (state) => (companyId) => {
      return state.companies.find(
        (company) => company.id === parseInt(companyId),
      );
    },
    getCompanyBySlug: (state) => (companySlug) => {
      return state.companies.find((company) => company.slug === companySlug);
    },
    getSeedCompanyById: (state) => (companyId) => {
      return state.seedCompanies.find(
        (company) => company.id === parseInt(companyId),
      );
    },
    getSeedCompanyBySlug: (state) => (companySlug) => {
      return state.seedCompanies.find(
        (company) => company.slug === companySlug,
      );
    },
    // getDerivedCompanies:
    //   (state) =>
    //   ({
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let expandedCustomers = state.companies;
    //     let filteredCustomers = _.isEmpty(filter)
    //       ? expandedCustomers
    //       : _.filter(expandedCustomers, filter);
    //     let matchedCustomers = _.isEmpty(match)
    //       ? filteredCustomers
    //       : _.filter(filteredCustomers, (item) =>
    //           _.some(item, (val) =>
    //             _.includes(_.lowerCase(val), _.lowerCase(match))
    //           )
    //         );
    //     let sortedCustomers = _.isEmpty(sort)
    //       ? matchedCustomers
    //       : _.sortBy(matchedCustomers, sort);
    //     let paginatedCustomers =
    //       _.isNumber(pageSize) && _.isNumber(pageNum)
    //         ? _.slice(
    //             sortedCustomers,
    //             pageSize * pageNum, // skip
    //             pageSize * pageNum + pageSize // limit
    //           )
    //         : sortedCustomers;
    //     return paginatedCustomers;
    //   },
  },
};

<template>
  <!-- <div class="bg-white px-4 py-5 sm:px-6">
    <div
      class="-mt-4 -ml-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
    >
      <div class="mt-4 ml-4">
        <p v-if="headingDescription != null" class="mt-1 text-sm text-gray-500">
          Details
        </p>
      </div>
      <div class="mt-4 ml-4 flex-shrink-0">
        <inline-spinner v-if="disableSubmitButton" />
        <base-button
          :buttonText="saveButtonText"
          :marginLeft="true"
          :disabled="disableSubmitButton"
          @buttonClick="clickSave"
        />
      </div>
    </div>
  </div> -->
  <div class="overflow-hidden bg-white">
    <div class="border-t border-none border-gray-200 px-4 py-5 sm:px-6">
      <rich-text-input
        inputTitle=""
        :wideItem="true"
        v-model="CompanyListing.details"
      />
    </div>
  </div>
</template>

<script>
import RichTextInput from "@/components/form/RichTextInput.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
// import InlineSpinner from "@/components/buttons/InlineSpinner.vue";
export default {
  props: {
    headingDescription: {
      type: String,
    },

    saveButtonText: {
      type: String,
      default: "Save",
    },
    orgProfile: {
      type: Object,
    },
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["saveButtonClick", "updateProfile"],
  components: {
    RichTextInput,
    // BaseButton,
    // InlineSpinner,
  },
  data() {
    let CompanyListing = this.orgProfile;
    return {
      CompanyListing: CompanyListing,
    };
  },
  computed: {},
  methods: {
    clickSave() {
      this.$emit("saveButtonClick");
    },
  },
  watch: {
    CompanyListing: {
      handler: function (newVal) {
        // console.log("CompanyListing changed", newVal);
        this.$emit("updateProfile", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style></style>

<template>
  <page-card :headingTitle="currentVendor?.name || ''">
    <template #buttonArea>
      <base-button
        buttonText="Submit"
        :marginLeft="false"
        @buttonClick="submitRequest"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :wideItem="true"
        inputTitle="Message"
        inputHtmlType="text"
        v-model="currentMessage"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";

export default {
  components: { PageCard, BaseButton, VariableStringTypeInput },
  data() {
    return { currentMessage: "" };
  },
  computed: {
    currentVendor() {
      return this.$store.state.companies["companies"].find(
        (company) => company.org === parseInt(this.$route.params.id),
      );
    },
  },
  methods: {
    submitRequest() {
      this.$store.dispatch("createVendor", {
        org: this.$route.params.id,
        message: this.currentMessage,
      });
      this.$router.push({ name: "vendors" });
    },
  },
};
</script>

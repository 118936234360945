<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transition duration-300 ease-out transform"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="showDialog"
          :class="styling"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg border-2 bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <ExclamationIcon
                  class="h-6 w-6 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p
                  class="prose text-sm font-medium text-gray-900"
                  v-html="errorMessage"
                ></p>
                <!-- <p class="mt-1 text-sm text-gray-500">Anyone with a link can now view this file.</p> -->
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  @click="showDialog = false"
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/outline";
import { XIcon } from "@heroicons/vue/solid";

export default {
  props: {
    noticeArray: { type: Array, default: new Array(0) },
    styling: { type: String, default: "border-red-500" },
  },
  components: {
    ExclamationIcon,
    XIcon,
  },
  computed: {
    errorMessage() {
      let errorMessage = "";
      for (let error of this.noticeArray) {
        errorMessage = errorMessage + "<br>" + error.errorDescription;
      }
      // console.log("error message");
      return errorMessage;
    },
    // showDialog() {
    //   if (this.showErrorNotification && !this.isShowing) {
    //     return true
    //   }
    //   return false;
    // }
  },
  data() {
    return {
      showDialog: this.showErrorNotification,
    };
  },
  methods: {},
  watch: {
    noticeArray: {
      handler() {
        if (this.noticeArray.length > 0) this.showDialog = true;
      },
      deep: true,
    },
  },
  // watch: {
  //   showErrorNotification() {
  //     this.showDialog = this.showErrorNotification;
  //   }
  // }
};
</script>

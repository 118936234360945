<template>
  <stats-row title="Account" :stats="affiliateStats" />
  <stats-row title="Growers" :stats="growerStats" />
  <stats-row title="Enrolled Acres" :stats="cropStats" />
</template>

<script>
let cropList = require("@/assets/croplist.js");
import StatsRow from "@/components/cards/StatsRow.vue";
export default {
  components: {
    StatsRow,
  },
  data() {
    return {};
  },
  computed: {
    offers() {
      return this.$store.getters.getBuyerMarketOffers({});
    },
    orders() {
      return this.$store.getters.getDerivedBuyerCropOrders({});
    },
    inquiries() {
      return this.$store.getters.getDerivedBuyerCropOrders({
        filterFunction: (order) => {
          return order.status === "Inquiry";
        },
      });
    },
    inProgressOrders() {
      return this.$store.getters.getDerivedBuyerCropOrders({
        filterFunction: (order) => {
          return order.status === "In Progress";
        },
      });
    },
    cancelledOrders() {
      return this.$store.getters.getDerivedBuyerCropOrders({
        filterFunction: (order) => {
          return order.status === "Cancelled";
        },
      });
    },
    completedOrders() {
      return this.$store.getters.getDerivedBuyerCropOrders({
        filterFunction: (order) => {
          return order.status === "Completed";
        },
      });
    },
    growers() {
      let orders = this.$store.getters.getDerivedBuyerCropOrders({});
      // iterate through orders and get the unique growers from order.seller_details.id and order.seller_details.name
      let growers = [];
      orders.forEach((order) => {
        let grower = {
          id: order.seller_details.id,
          name: order.seller_details.name,
        };
        if (!growers.some((g) => g.id === grower.id)) {
          growers.push(grower);
        }
      });
      return growers;
    },
    allocations() {
      return this.$store.getters.getAllBuyerAllocations;
    },
    affiliateStats() {
      return [
        {
          name: "Active Offers",
          stat: this.offers.length,
        },
        // {
        //   name: "Our Earnings",
        //   stat: 0,
        // },
        // {
        //   name: "Grower Earnings",
        //   stat: 0,
        // },
      ];
    },
    growerStats() {
      return [
        // {
        //   name: "Partnered Growers",
        //   stat: 0,
        // },
        {
          name: "Enrolled Growers",
          stat: this.growers.length,
        },
        // {
        //   name: "Enrolled Growers %",
        //   stat: 0,
        // },
        {
          name: "Enrolled Acres",
          // sum of all allocation.acres
          stat: this.allocations.reduce((acc, a) => acc + a.acres, 0),
        },
        // {
        //   name: "Enrolled Acres %",
        //   stat: 0,
        // },
      ];
    },
    // map through the crops in cropList
    // if there are no allocations with that type of crop, don't add that stat to the array
    // if there are allocations with that type of crop, add a stat with the name of the crop and the sum of all allocation.acres where alloication.shared_data.crop_details.crop_type is that crop
    cropStats() {
      let stats = [];
      cropList.forEach((crop) => {
        let cropType = crop.name;
        let acres = this.allocations.reduce((acc, a) => {
          if (a.shared_data.crop_details.crop_type === cropType) {
            return acc + a.acres;
          }
          return acc;
        }, 0);
        if (acres > 0) {
          stats.push({
            name: cropType,
            stat: acres,
          });
        }
      });
      return stats;
    },
  },
  methods: {},
  watch: {},
};
</script>

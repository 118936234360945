<template>
  <delete-modal
    :open="modalIsActive"
    :modalTitle="cancelModalTitle"
    :modalDescription="cancelModalDescription"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="cancelOrder"
    deleteButtonText="Cancel Order"
    closeButtonText="Back"
  />
  <page-card headingTitle="Review Order">
    <template #buttonArea>
      <base-button
        v-if="
          !['seller_canceled', 'seller_completed'].includes(
            currentOrder?.status,
          )
        "
        buttonText="Cancel Order"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
      <!-- <base-button
        v-if="
          ![
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'seller_completed',
          ].includes(currentOrder?.status)
        "
        buttonText="Update"
        :marginLeft="true"
        @buttonClick="respondOrder"
      /> -->
      <base-button
        v-if="
          ![
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'seller_completed',
          ].includes(currentOrder?.status)
        "
        buttonText="Complete Order"
        :marginLeft="true"
        @buttonClick="completeOrder"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="currentAgreement?.service_title"
        :wideItem="true"
        inputTitle="Listing"
      />

      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          Customer
          <router-link
            v-if="currentAgreement"
            :to="{
              name: 'vendor-single-quote',
              params: { quoteId: currentAgreement?.id },
            }"
            class="text-twilight-600 hover:text-twilight-900"
            >(View Agreement)</router-link
          >
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentAgreement?.buyer_details?.name"
          :wideItem="true"
        />
      </div>
      <!-- <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="currentOrder?.service_title"
        :wideItem="true"
        inputTitle="Product"
      />
      <date-input
        v-model="currentOrder?.start_date"
        :display-only="
          [
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'buyer_confirmed',
            'seller_completed',
          ].includes(currentOrder?.status)
        "
        :display-only-value="currentOrder?.start_date"
        :wideItem="true"
        inputTitle="Agreement Start Date"
      />
      <date-input
        v-model="currentOrder?.end_date"
        :display-only="
          [
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'buyer_confirmed',
            'seller_completed',
          ].includes(currentOrder?.status)
        "
        :display-only-value="currentOrder?.end_date"
        :wideItem="true"
        inputTitle="Agreement End Date"
      /> -->
      <!-- <currency-input
        :wideItem="true"
        inputTitle="Total Price"
        :disabled="currentOrder?.status === 'buyer_confirmed' ? true : false"
        v-model="currentOrder?.total_price"
      /> -->
      <!-- <variable-string-type-input
        :wideItem="true"
        inputTitle="Tracking Id"
        :displayOnly="currentOrder?.status === 'buyer_confirmed' ? false : true"
        v-model="currentOrder?.tracking_id"
      /> -->
      <!-- <variable-string-type-input
        :wideItem="true"
        inputTitle="Memo"
        v-model="currentOrder?.tracking_memo"
      /> -->
      <div v-if="orderClosed" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Closed', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCanceled" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Canceled', status: 'complete' }]"
        />
      </div>
      <div v-else-if="orderCompleted" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Order Completed', status: 'complete' }]"
        />
      </div>
      <div v-else class="sm:col-span-6">
        <horizontal-panel-steps :steps="orderSteps" />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Order Details
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentOrder?.shared_assets?.fields?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Fields
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentOrder?.shared_assets?.equipment?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Equipment
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <!-- <number-input
              :display-only="true"
              :displayOnlyValue="currentOrder?.quantity"
              :step="1"
              :wideItem="true"
              inputTitle="Quantity"
            />
            <text-area
              :displayOnly="true"
              :displayOnlyValue="currentOrder?.delivery_address"
              :wideItem="true"
              inputTitle="Delivery Address"
              inputHtmlType="text"
            /> -->
            <!-- <div
              class="mt-2 sm:col-span-6"
              v-if="productOrderCategory && currentOrder"
            >
              <component
                :is="productOrderCategory"
                :productId="currentOrder?.agreement"
                :orderDetails="currentOrder?.details"
                :readOnly="true"
              />
            </div>
            <h2
              class="mt-2"
              v-if="Object.keys(currentOrder?.form_response)?.length > 0"
            >
              Form Questions
            </h2>
            <text-area
              v-for="(value, key) in currentOrder?.form_response"
              :key="key"
              :wideItem="true"
              :inputTitle="key"
              :placeholder="value"
              :display-only="true"
            />
            <h2
              class="mt-2"
              v-if="Object.keys(currentOrder?.variants)?.length > 0"
            >
              Variants
            </h2>
            <variable-string-type-input
              v-for="(value, key) in currentOrder?.variants"
              :key="key"
              :inputTitle="key"
              :wideItem="true"
              :placeholder="value"
              :display-only="true"
            />
            <h2
              class="mt-2"
              v-if="Object.keys(currentOrder?.options)?.length > 0"
            >
              Options
            </h2>
            <checkbox-input
              v-for="(value, key) in currentOrder?.options"
              :key="key"
              :inputTitle="key"
              :modelValue="value"
              :wideItem="true"
              :display-only="true"
            /> -->
            <order-object-interface
              :product="currentProduct"
              :active-agreement-id="currentAgreement?.id"
              :order="currentOrder"
              :locations="currentOrder?.shared_assets?.locations"
              :read-only="true"
            />
            <shared-fields
              v-if="currentOrder?.shared_assets?.fields.length === 1"
              :fields="currentOrder?.shared_assets?.fields"
              :locations="currentOrder?.shared_assets?.locations"
            />
            <shared-equipment
              v-if="currentOrder?.shared_assets?.equipment.length === 1"
              :equipment="currentOrder?.shared_assets?.equipment"
            />
          </TabPanel>

          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentOrder?.shared_assets?.fields?.length > 0"
          >
            <shared-fields :fields="currentOrder?.shared_assets?.fields" />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentOrder?.shared_assets?.equipment?.length > 0"
          >
            <shared-equipment
              :equipment="currentOrder?.shared_assets?.equipment"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
// import TextArea from "@/components/form/TextArea.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
// import DateTimeInput from "@/components/form/DateTimeInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import DateInput from "@/components/form/DateInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import { useToast } from "vue-toastification";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";
import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";
// import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
// import HaulingOrderDetails from "@/layouts/common/orders/order-details/HaulingOrderDetails.vue";
// import DefaultOrderDetails from "@/layouts/common/orders/order-details/DefaultOrderDetails.vue";
import OrderObjectInterface from "@/layouts/common/orders/OrderObjectInterface.vue";
// import CompleteOrderForm from "@/layouts/common/orders/CompleteOrderForm.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    // DateTimeInput,
    // TextArea,
    // CurrencyInput,
    // NumberInput,
    // DateInput,
    // CheckboxInput,
    HorizontalPanelSteps,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    SharedEquipment,
    SharedFields,
    // ChatSingleView,
    // HaulingOrderDetails,
    // DefaultOrderDetails,
    // CompleteOrderForm,
    OrderObjectInterface,
  },
  data() {
    const toast = useToast();
    return {
      modalIsActive: false,
      // currentOrder: null,
      pendingVendorOrderAttachments: [],
      toast,
    };
  },
  computed: {
    orderId() {
      return this.$route.params.orderId;
    },
    currentOrder() {
      return this.$store.getters.getVendorOrderById(this.orderId);
    },
    currentAgreement() {
      if (!this.currentOrder) return null;
      return this.$store.getters.getVendorQuoteById(
        parseInt(this.currentOrder.agreement),
      );
    },
    currentProduct() {
      if (!this.currentAgreement) return null;
      return this.$store.getters.getVendorProductById(
        parseInt(this.currentAgreement.service),
      );
    },
    orderClosed() {
      if (
        this.currentOrder?.status === "seller_closed" ||
        this.currentOrder?.status === "buyer_closed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderCanceled() {
      if (this.currentOrder?.status === "seller_canceled") {
        return true;
      } else {
        return false;
      }
    },
    orderCompleted() {
      if (this.currentOrder?.status === "seller_completed") {
        return true;
      } else {
        return false;
      }
    },
    orderSteps() {
      // let stepTwoStatuses = [
      //   "seller_responded",
      //   "buyer_confirmed",
      //   "seller_completed",
      // ];
      let steps = [
        { name: "Order Submitted", status: "complete" },
        // {
        //   name: "Price Sent",
        //   status: stepTwoStatuses.includes(this.currentOrder?.status)
        //     ? "complete"
        //     : "upcoming",
        // },
        {
          name: "Order Completed",
          status:
            this.currentOrder?.status === "buyer_confirmed"
              ? "complete"
              : "upcoming",
        },
      ];
      return steps;
    },
    cancelModalTitle() {
      if (this.currentOrder?.status === "buyer_confirmed") {
        return "Cancel Order";
      } else {
        return "Close Order";
      }
    },
    cancelModalDescription() {
      if (this.currentOrder?.status === "buyer_confirmed") {
        return "Are you sure you want to cancel this confirmed order?";
      } else {
        return "Are you sure you want to close this order inquiry?";
      }
    },
    productOrderCategory() {
      switch (this.currentProduct?.category) {
        case "Hauling":
          return "HaulingOrderDetails";
        case "Input":
          return "InputOrderDetails";
        default:
          return "DefaultOrderDetails";
      }
    },
  },
  methods: {
    async respondOrder() {
      await this.$store.dispatch("updateVendorOrder", {
        id: this.currentOrder?.id,
        status: "seller_responded",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Response Sent", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-orders-all" });
    },
    async completeOrder() {
      await this.$store.dispatch("updateVendorOrder", {
        id: this.currentOrder?.id,
        status: "seller_completed",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Response Sent", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-orders-all" });
    },
    async cancelOrder() {
      this.currentOrder.total_price = 0;
      await this.$store.dispatch("updateVendorOrder", {
        id: this.currentOrder?.id,
        status: "seller_canceled",
      });
      this.pendingVendorOrderAttachments = [];
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Concluded", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-orders-all" });
    },

    addFileToPendingAttachments(file) {
      this.pendingVendorOrderAttachments.push(file);
    },
    async uploadVendorOrderAttachments() {
      for (let file of this.pendingVendorOrderAttachments) {
        await this.$store.dispatch("uploadVendorOrderAttachment", {
          orderId: this.orderId,
          file,
        });
      }
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
  },
  watch: {
    // orderId: {
    //   // handler(value) or handler(newValue, oldValue)
    //   async handler() {
    //     // console.log("the fieldId param changed to ", value, " from ", oldValue)
    //     if (this.orderId === undefined) return;
    //     let order = this.order;
    //     if (!order)
    //       order = await this.$store.getters.getVendorOrderById(this.orderId);
    //     console.log("order", order, this.orderId);
    //     let agreement = this.$store.getters.getVendorQuoteById(order.agreement);
    //     console.log("agreement", agreement);
    //     this.agreement = agreement;
    //     let product = this.$store.getters.getVendorProductById(
    //       agreement.service
    //     );
    //     this.currentProduct = product;
    //     // let data = await client.grower.services.readOne(order);
    //     // this.currentProduct = data.data;
    //     // const order = this.$store.getters.getVendorOrderById(value);
    //     // console.log("order", order);
    //     this.currentOrder = order;
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    // },
    // orderId: {
    //   // handler(value) or handler(newValue, oldValue)
    //   async handler() {
    //     if (this.orderId === undefined) return;
    //     let order = this.order;
    //     if (!order)
    //       order = await this.$store.getters.getVendorOrderById(this.orderId);
    //     console.log("order", order, this.orderId);
    //     // let data = await client.grower.services.readOne(order);
    //     // this.currentProduct = data.data;
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    // },
  },
};
</script>

import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      vendorLocations: [],
    };
  },
  mutations: {
    SET_BOUNDARY_BOX(state, boundsData) {
      if (boundsData) {
        state.bounds = [
          [boundsData[1], boundsData[0]],
          [boundsData[3], boundsData[2]],
        ];
      } else {
        state.bounds = [];
      }
    },
    ADD_VENDOR_SERVICE_LOCATIONS(state, vendorLocationsArray) {
      vendorLocationsArray.forEach((partnerLocationObject) => {
        state.vendorLocations.push(partnerLocationObject);
      });
    },
    RESET_VENDOR_SERVICE_LOCATIONS(state) {
      state.vendorLocations = [];
      state.bounds = [];
    },
  },
  actions: {
    async getVendorLocationsByField({ commit }, id) {
      const data = await client.vendor.serviceLocations.read(id);
      if (data) {
        commit("ADD_VENDOR_SERVICE_LOCATIONS", data.locations);
      }
    },
  },
  getters: {
    getVendorLocationsByField: (state) => (partnerLocationId) => {
      return state.vendorLocations.find(
        (partnerLocation) => partnerLocation.id === parseInt(partnerLocationId),
      );
    },
    getVendorLocationsByFieldId: (state) => (fieldId) => {
      return state.vendorLocations.filter(
        (partnerLocation) => partnerLocation.field === parseInt(fieldId),
      );
    },
    getVendorLocationByName: (state) => (partnerLocationName) => {
      return state.vendorLocations.find(
        (partnerLocation) =>
          partnerLocation.properties.name === partnerLocationName,
      );
    },
  },
};

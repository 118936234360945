<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'name', label: 'Grower', filtered: false },
          //   { name: 'crop', label: 'Crop' },
          //   { name: 'quantity', label: 'Quantity Assigned', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="growers"
        @update:derived-collection="derivedGrowers = $event"
      >
        <!-- <template #toolbarButtons>
            <div class="flex">
              <table-toolbar-button
                buttonText="New"
                :usePlusIcon="true"
                @buttonClick="navigateNewOffer"
              />
            </div>
          </template> -->
      </table-head>
    </template>
    <tbody>
      <tr v-for="g in derivedGrowers" :key="g.id">
        <table-data td-label="Contract"> {{ g.name }} </table-data>
        <!-- <table-data td-label="Crop">
          {{ g.crop }}
        </table-data>
        <table-data td-label="Quantity Assigned">
          {{ g.quantity }} {{ g.quantity_units }}
        </table-data> -->
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-bp-affiliate-grower-report',
              params: { growerId: g.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    // TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedGrowers: [],
    };
  },
  computed: {
    growers() {
      let orders = this.$store.getters.getDerivedBuyerCropOrders({});
      // iterate through orders and get the unique growers from order.seller_details.id and order.seller_details.name
      let growers = [];
      orders.forEach((order) => {
        let grower = {
          id: order.seller_details.id,
          name: order.seller_details.name,
        };
        if (!growers.some((g) => g.id === grower.id)) {
          growers.push(grower);
        }
      });
      return growers;
    },
  },
  methods: {},
};
</script>

<style></style>

<template>
  <styled-input
    type="text"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    :classProp="classProp"
    :helpText="helpText"
    :helpTitle="helpTitle"
    v-model="inputValue"
    @blur="setValue"
  />
  <!--
  <div :class="wideItem == false ? ' sm:col-span-1 ' : ' sm:col-span-3 '">
    <label class="block text-sm font-medium text-gray-700">{{
      inputTitle
    }}</label>
    <div class="mt-1" v-if="displayOnly == false">
      <input
        type="text"
        v-model="inputValue"
        :required="required"
        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-twilight-500 focus:border-twilight-500 sm:text-sm"
        @blur="setValue"
      />
    </div>
    <div v-else class="flex mt-1 bg-gray-200 rounded-md shadow-sm">
      <span
        class="flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none rounded-r-md sm:text-sm"
      >
        {{ displayOnlyValue }}
      </span>
    </div>
  </div>
  -->
</template>

<script>
import StyledInput from "./StyledInput.vue";

export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String, default: null },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [String, Number] },
    placeholder: { type: String },
    classProp: { type: String },
    required: { type: Boolean, default: false },
    wideItem: { type: Boolean, default: false },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    modelValue: {},
  },
  emits: ["update:modelValue"],
  data() {
    let amount;
    if (this.displayOnly) {
      amount = this.displayOnlyValue;
    } else {
      amount = this.modelValue;
    }
    // console.log("AMOUNT", amount);
    if (amount == null) amount = 0;
    if (typeof amount == "string") {
      amount = Number(amount.replace(/[^0-9.]+/g, ""));
    }
    amount = this.formatCurrency(amount);
    return {
      inputValue: amount,
    };
  },
  methods: {
    setValue(value) {
      let amount = value;
      if (typeof amount == "string")
        amount = Number(amount.replace(/[^0-9.]+/g, ""));
      // console.log("EMIT", amount);
      this.$emit("update:modelValue", amount);
      amount = this.formatCurrency(amount);
      this.inputValue = amount;
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      let amount = formatter.format(value);
      return amount;
    },
  },
  watch: {
    displayOnly() {
      if (this.displayOnly) {
        let amount = this.displayOnlyValue;
        if (typeof amount == "string")
          amount = Number(amount.replace(/[^0-9.]+/g, ""));
        amount = this.formatCurrency(amount);
        this.inputValue = amount;
      }
    },
    displayOnlyValue() {
      if (this.displayOnly) {
        let amount = this.displayOnlyValue;
        if (typeof amount == "string")
          amount = Number(amount.replace(/[^0-9.]+/g, ""));
        amount = this.formatCurrency(amount);
        this.inputValue = amount;
      }
    },
  },
};
</script>

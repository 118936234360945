<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'properties.farm', label: 'Farm' },
          { name: 'properties.name', label: 'Field', filtered: false },
          { name: 'properties.crop', label: 'Crop' },
          { name: 'properties.details.variety', label: 'Variety' },
          { name: 'task_count', label: 'Activities', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="fields"
        @update:derivedCollection="derivedFields = $event"
        @update:filter="selectFilter"
        :defaultFilter="defaultFilter"
        :defaultSort="defaultSort"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="field in derivedFields" :key="field">
        <table-data td-label="Farm">
          <div class="flex">
            <field-thumbnail
              :title="field.properties.name"
              :geojson="field"
              class="mr-6"
            ></field-thumbnail>
            {{ field.properties.farm }}
          </div>
        </table-data>
        <table-data td-label="Field">
          {{ field.properties.name }}
        </table-data>
        <table-data td-label="Crop">
          {{ field.properties.crop }}
        </table-data>
        <table-data td-label="Variety">
          {{
            field.properties?.details?.variety
              ? field.properties?.details?.variety
              : ""
          }}
        </table-data>
        <table-data td-label="Activities">
          {{ field.task_count }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'field-plan',
              params: { fieldId: field.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
// import { exportToCSV } from "@/components/composables/exportFile";

export default {
  name: "FieldPlans",
  components: {
    FieldThumbnail,
    TableCard,
    TableHead,
    TableData,
    // TableToolbarButton,
  },
  data() {
    // let filterObject = this.$store.getters.getFieldsFilter;
    return {
      derivedFields: [],
      // defaultFilter: filterObject,
      defaultSort: { "properties.farm": "asc", "properties.name": "asc" },
    };
  },
  computed: {
    fields() {
      let fields = this.$store.state.fields.fields;
      fields = fields.map((field) => {
        field.task_count = this.$store.getters.getTasksByField(field.id).length;
        return field;
      });
      return fields;
    },
    defaultFilter() {
      // console.log("computing filter");
      return this.$store.getters.getFieldsFilter;
    },
  },

  methods: {
    selectFilter(e) {
      this.$store.dispatch("setFieldsFilter", e);
    },
  },
};
</script>

<template>
  <page-card :headingTitle="'Grower Report'">
    <template #cardDetails>
      <grower-summary :growerId="parseInt($route.params.growerId)" />
      <!-- <crop-allocation-aggregate
        :allocations="currentAllocations"
        :currentOrderUnits="currentOrder.units"
      /> -->
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Orders
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Fields
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Allocations
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Map
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <controller-orders-table :growerId="parseInt(growerId)" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    {
                      name: 'field_details.properties.name',
                      label: 'Field',
                      filtered: false,
                    },
                    { name: 'crop_details.crop_type', label: 'Crop' },
                    { name: 'crop_details.details.variety', label: 'Variety' },
                    {
                      name: 'field_details.properties.acres',
                      label: 'Acres',
                      filtered: false,
                    },
                    {
                      name: 'totalAllocatedAcres',
                      label: 'Our Allocated Acres',
                      filtered: false,
                    },
                    {
                      name: 'remaining_acres',
                      label: 'Available Acres',
                      filtered: false,
                    },
                    {
                      name: '',
                      label: 'Estimated Yield',
                      filtered: false,
                      sorted: false,
                    },
                    { name: 'actions', label: '', filtered: false },
                  ]"
                  :collection="growerFields"
                  @update:derived-collection="derivedFields = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr v-for="field in derivedFields" :key="field.id">
                  <table-data td-label="Field">
                    <div class="flex">
                      <field-thumbnail
                        :title="field.field_details.properties.name"
                        :geojson="field.field_details"
                        class="mr-6"
                      ></field-thumbnail>
                      {{ field.field_details.properties.name }}
                    </div>
                  </table-data>
                  <table-data td-label="Crop">
                    {{ field.crop_details.crop_type }}
                  </table-data>
                  <table-data td-label="Variety">
                    {{ field.crop_details.details.variety }}
                  </table-data>
                  <table-data td-label="Acres">
                    {{ field.field_details.properties.acres }}
                  </table-data>
                  <table-data td-label="Our Allocated Acres">
                    {{ field.totalAllocatedAcres }}
                  </table-data>
                  <table-data td-label="Available Acres">
                    {{ field.remaining_acres }}
                  </table-data>
                  <table-data td-label="Estimated Yield">
                    {{
                      field.crop_details.details.tpa
                        ? (
                            field.field_details.properties.acres *
                            field.crop_details.details.tpa
                          ).toFixed(2) + " Tons"
                        : "N/A"
                    }}
                  </table-data>
                  <table-data class="text-center">
                    <router-link
                      :to="{
                        name: 'buyer-bp-controller-field-report',
                        params: { fieldId: field.field_details.id },
                      }"
                      class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
                    >
                      View Field
                    </router-link>
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    {
                      name: 'field_details.properties.name',
                      label: 'Field',
                      filtered: false,
                    },
                    { name: 'crop_details.crop_type', label: 'Crop' },
                    { name: 'crop_details.details.variety', label: 'Variety' },
                    {
                      name: 'acres',
                      label: 'Allocated Acres',
                      filtered: false,
                    },
                    {
                      name: '',
                      label: 'Estimated Yield',
                      filtered: false,
                      sorted: false,
                    },
                    { name: 'actions', label: '', filtered: false },
                  ]"
                  :collection="growerAllocations"
                  @update:derived-collection="derivedAllocations = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr
                  v-for="allocation in derivedAllocations"
                  :key="allocation.id"
                >
                  <table-data td-label="Field">
                    <div class="flex">
                      <field-thumbnail
                        :title="
                          allocation.shared_data.field_details.properties.name
                        "
                        :geojson="allocation.shared_data.field_details"
                        class="mr-6"
                      ></field-thumbnail>
                      {{ allocation.shared_data.field_details.properties.name }}
                    </div>
                  </table-data>
                  <table-data td-label="Crop">
                    {{ allocation.shared_data.crop_details.crop_type }}
                  </table-data>
                  <table-data td-label="Variety">
                    {{ allocation.shared_data.crop_details.details.variety }}
                  </table-data>
                  <table-data td-label="Allocated Acres">
                    {{ allocation.acres }}
                  </table-data>
                  <table-data td-label="Estimated Yield">
                    {{
                      allocation.shared_data.crop_details.details.tpa
                        ? allocation.acres *
                            allocation.shared_data.crop_details.details.tpa +
                          " Tons"
                        : "N/A"
                    }}
                  </table-data>
                  <table-data class="text-center">
                    <router-link
                      :to="{
                        name: 'buyer-bp-controller-view-order',
                        params: { orderId: allocation.crop_order },
                      }"
                      class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
                    >
                      View Order
                    </router-link>
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <growers-map :grower-id="growerId" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import GrowerSummary from "@/layouts/buyer-portal/bp-controller/growers/GrowerSummary.vue";
import GrowersMap from "@/layouts/buyer-portal/bp-controller/dashboard/GrowersMap.vue";

import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

import PageCard from "@/components/cards/PageCard.vue";

import ControllerOrdersTable from "@/pages/buyer-portal/bp-controller/orders/ControllerOrdersTable.vue";

// import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
// import Modal from "@/components/modals/PopupModal.vue";
// import StatsRow from "@/components/cards/StatsRow.vue";

// import TextInput from "@/components/form/TextInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";

// const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    GrowerSummary,
    GrowersMap,
    TableCard,
    TableHead,
    TableData,
    FieldThumbnail,
    ControllerOrdersTable,
    // RecordForm,
    // Modal,
    // StatsRow,
    // TextInput,
    // NumberInput,
  },
  data() {
    return {
      derivedAllocations: [],
      derivedFields: [],
    };
  },
  computed: {
    growerId() {
      return this.$route.params.growerId;
    },
    offers() {
      return this.$store.getters.getControllerMarketOffers({});
    },
    orders() {
      return this.$store.getters.getDerivedControllerCropOrders({});
    },
    inquiries() {
      return this.$store.getters.getDerivedControllerCropOrders({
        filterFunction: (order) => {
          return order.status === "Inquiry";
        },
      });
    },
    inProgressOrders() {
      return this.$store.getters.getDerivedControllerCropOrders({
        filterFunction: (order) => {
          return order.status === "In Progress";
        },
      });
    },
    cancelledOrders() {
      return this.$store.getters.getDerivedControllerCropOrders({
        filterFunction: (order) => {
          return order.status === "Cancelled";
        },
      });
    },
    completedOrders() {
      return this.$store.getters.getDerivedControllerCropOrders({
        filterFunction: (order) => {
          return order.status === "Completed";
        },
      });
    },
    growerAllocations() {
      return this.$store.getters.getControllerAllocationsBySeller(
        this.growerId,
      );
    },
    growerFields() {
      // get the ControllerAllocationFields by Seller
      // then getControllerSumAllocatedAcresByFieldId to add the total acres allocated to each field
      let fields = this.$store.getters.getControllerAllocationFieldsBySeller(
        this.growerId,
      );
      fields.forEach((field) => {
        field.totalAllocatedAcres =
          this.$store.getters.getControllerSumAllocatedAcresByFieldId(
            field.field_details.id,
          );
        field.remaining_acres =
          field.field_details.properties.acres - field.totalAllocatedAcres;
      });
      return fields;
    },
  },
  methods: {},
  watch: {},
};
</script>

<!-- Default QUOTE details -->
<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  props: {
    initialQuoteDetails: {
      type: Object,
      default: () => ({ quoteUnits: "" }),
    },
  },
  emits: ["updateQuoteDetails"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    detailsToReturn() {
      return {
        quoteUnits: this.quoteUnits,
      };
    },
  },
  watch: {
    detailsToReturn(value) {
      return this.$emit("updateQuoteDetails", value);
    },
  },
};
</script>

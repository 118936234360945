<template>
  <!-- <div class="bg-white px-4 py-5 sm:px-6"> -->
  <div
    class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
  >
    <div class="ml-4 mt-4">
      <p v-if="headingDescription != null" class="mt-1 text-sm text-gray-500">
        {{ headingDescription }}
      </p>
    </div>
    <!-- <div class="mt-4 ml-4 flex-shrink-0">
        <inline-spinner v-if="disableSubmitButton" />
        <base-button
          :buttonText="saveButtonText"
          :marginLeft="true"
          :disabled="disableSubmitButton"
          @buttonClick="clickSave"
        />
      </div> -->
  </div>
  <!-- </div> -->
  <div class="overflow-hidden bg-white">
    <div class="border-t border-none border-gray-200 px-4 py-5 sm:px-6">
      <div
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6"
        v-for="row in currentRowCount"
        :key="row"
      >
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Title"
          v-model="CompanyListing[formDataType][row - 1].title"
        />
        <text-area
          :wideItem="true"
          inputTitle="Description"
          v-model="CompanyListing[formDataType][row - 1].description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
// import InlineSpinner from "@/components/buttons/InlineSpinner.vue";
export default {
  props: {
    headingDescription: {
      type: String,
    },
    fourEntries: {
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      type: String,
      default: "Save",
    },
    formDataType: {
      type: String,
      default: "features",
    },
    orgProfile: {
      type: Object,
    },
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
    row_count: {
      type: Number,
      default: 3,
    },
  },
  emits: ["saveButtonClick", "updateProfile"],
  components: {
    VariableStringTypeInput,
    TextArea,
    // BaseButton,
    // InlineSpinner,
  },
  data() {
    let CompanyListing = this.orgProfile;
    let currentRowCount = CompanyListing[this.formDataType]?.length;
    return {
      CompanyListing,
      currentRowCount,
    };
  },
  computed: {},
  methods: {
    clickSave() {
      this.$emit("saveButtonClick");
    },
  },
  watch: {
    CompanyListing: {
      handler: function (newVal) {
        // console.log("CompanyListing changed", newVal);
        this.$emit("updateProfile", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style></style>

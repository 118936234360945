<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Order Recommendations
            <!-- <span
              v-if="awaitingResponseOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ awaitingResponseOrders }}</span
            > -->
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Task Recommendations
            <!-- <span
              v-if="closedOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ closedOrders }}</span
            > -->
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel class="pt-10">
        <grower-opps-table />
        <!-- <awaiting-response-buy-grower-orders-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <grower-recs-table />
        <!-- <closed-buy-grower-orders-table /> -->
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import GrowerOppsTable from "@/layouts/grower-portal/recommendations/GrowerOppsTable.vue";
import GrowerRecsTable from "@/layouts/grower-portal/recommendations/GrowerRecsTable.vue";

// import AwaitingResponseBuyGrowerOrdersTable from "@/layouts/grower-portal/buy/orders/AwaitingResponseBuyGrowerOrdersTable.vue";
// import ClosedBuyGrowerOrdersTable from "@/layouts/grower-portal/buy/orders/ClosedBuyGrowerOrdersTable.vue";
// import CompletedBuyGrowerOrdersTable from "@/layouts/grower-portal/buy/orders/CompletedBuyGrowerOrdersTable.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // AwaitingResponseBuyGrowerOrdersTable,
    // ClosedBuyGrowerOrdersTable,
    // CompletedBuyGrowerOrdersTable,
    GrowerOppsTable,
    GrowerRecsTable,
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  computed: {},
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>

<template>
  <tr class="border-t">
    <td class="pr-8">{{ label }}</td>
    <td>
      <div
        class="pr-8"
        v-if="
          scenario &&
          scenario.inputs[input] != undefined &&
          scenario.inputs[input] != null
          //  && scenario.inputs[input] != scenario.defaults[input]
        "
      >
        {{ formatted_input }} {{ units }}
      </div>
    </td>
    <td>
      <div
        v-if="
          scenario &&
          (scenario.inputs[input] == undefined ||
            scenario.inputs[input] == null)
          // || scenario.inputs[input] == scenario.defaults[input]
        "
      >
        {{ formatted_default }} {{ units }}
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  name: "CarbonScenarioReportInput",
  props: {
    scenario: {
      //scenario
      type: Object,
      required: true,
    },
    label: {
      //label
      type: String,
      required: true,
    },
    input: {
      //input
      type: String,
      required: true,
    },
    units: {
      //units
      type: String,
      required: true,
    },
  },
  data() {
    let formatted_input = this.scenario?.inputs[this.input];
    if (formatted_input && !isNaN(formatted_input)) {
      formatted_input = formatted_input.toFixed(1);
    }
    let formatted_default = this.scenario?.defaults[this.input];
    if (formatted_default && !isNaN(formatted_default)) {
      formatted_default = formatted_default.toFixed(1);
    }
    return {
      formatted_input: formatted_input,
      formatted_default: formatted_default,
    };
  },
  computed: {},
};
</script>

<template>
  <div>
    <page-card headingTitle="Originator">
      <template #buttonArea>
        <base-button
          v-if="currentOriginator && !currentOriginator.accepted"
          buttonText="Accept"
          :marginLeft="false"
          @buttonClick="acceptOriginator"
        />
        <base-button
          v-if="currentOriginator && currentOriginator.accepted"
          buttonText="Reject"
          :marginLeft="true"
          :redButton="true"
          @buttonClick="rejectOriginator"
        />
        <base-button
          buttonText="Delete"
          :marginLeft="true"
          :redButton="true"
          @buttonClick="deleteOriginator"
        />
      </template>
      <template #cardDetails>
        <text-input
          v-if="currentOriginator"
          :wideItem="true"
          inputTitle="Name"
          :display-only="true"
          :display-only-value="currentOriginator?.originator_details?.name"
        />
        <text-input
          v-if="currentOriginator"
          :wideItem="true"
          inputTitle="Active"
          :display-only="true"
          :display-only-value="currentOriginator?.accepted"
        />
        <text-input
          v-if="currentOriginator"
          :wideItem="true"
          inputTitle="Contact Email"
          :display-only="true"
          :display-only-value="
            currentOriginator?.originator_details?.contact_email
          "
        />
        <text-input
          v-if="currentOriginator"
          :wideItem="true"
          inputTitle="Contact Phone"
          :display-only="true"
          :display-only-value="
            currentOriginator?.originator_details?.contact_phone
          "
        />
      </template>
    </page-card>
  </div>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";

export default {
  components: {
    TextInput,
    PageCard,
    BaseButton,
  },
  data() {
    return {};
  },
  computed: {
    originatorId() {
      return this.$route.params.originatorId;
    },
    currentOriginator() {
      if (
        this.$store.getters.getBuyerContractOriginatorById(this.originatorId)
      ) {
        return this.$store.getters.getBuyerContractOriginatorById(
          this.originatorId,
        );
      } else {
        return null;
      }
    },
  },
  methods: {
    acceptOriginator() {
      this.$store.dispatch("updateBuyerContractOriginator", {
        id: this.currentOriginator.originator_details.id,
        accepted: true,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-originators" });
      }
    },
    rejectOriginator() {
      this.$store.dispatch("updateBuyerContractOriginator", {
        id: this.currentOriginator.originator_details.id,
        accepted: false,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-originators" });
      }
    },
    deleteOriginator() {
      this.$store.dispatch("deleteBuyerContractOriginator", {
        id: this.currentOriginator.originator_details.id,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-originators" });
      }
    },
  },
};
</script>

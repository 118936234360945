<template>
  <grower-carbon-scenarios-table />
</template>

<script>
import GrowerCarbonScenariosTable from "@/layouts/grower-portal/carbon/GrowerCarbonScenariosTable.vue";

export default {
  components: {
    GrowerCarbonScenariosTable,
  },

  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Recommendation
          </button>
        </Tab>
        <Tab
          as="template"
          v-slot="{ selected }"
          v-if="recommendationType === 'order'"
        >
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Create Order
          </button>
        </Tab>
        <!-- <Tab
            as="template"
            v-slot="{ selected }"
            v-if="recommendationType === 'task'"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Create Task
            </button>
          </Tab> -->
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <!-- VIEW REC -->

        <page-card headingTitle="View Recommendation">
          <template #buttonArea>
            <base-button
              buttonText="Archive Recommendation"
              :redButton="true"
              @buttonClick="archiveOpportunity"
            />
            <base-button
              buttonText="Create Order"
              @buttonClick="changeTab(1)"
            />
          </template>
          <template #cardDetails>
            <variable-string-type-input
              :displayOnly="true"
              :displayOnlyValue="activeAgreement?.service_title"
              :wideItem="true"
              inputTitle="Listing"
            />

            <div class="sm:col-span-3">
              <label
                for="territory"
                class="block text-sm font-medium text-gray-700"
              >
                Vendor
                <router-link
                  v-if="activeAgreement"
                  :to="{
                    name: 'grower-portal-single-quote',
                    params: { quoteId: activeAgreement.id },
                  }"
                  class="text-twilight-600 hover:text-twilight-900"
                  >(View Agreement)</router-link
                >
              </label>
              <variable-string-type-input
                :displayOnly="true"
                :displayOnlyValue="activeAgreement?.seller_details?.name"
                :wideItem="true"
              />
            </div>
            <text-area
              :wideItem="true"
              inputTitle="Memo"
              :display-only="true"
              :display-only-value="currentRecommendation.memo"
            />
            <div class="sm:col-span-3">
              <label
                for="territory"
                class="block text-sm font-medium text-gray-700"
              >
                More Details Link
                <a
                  :href="currentRecommendation.more_info"
                  class="text-twilight-600 hover:text-twilight-900"
                  >(View)</a
                >
              </label>
              <variable-string-type-input
                :displayOnly="true"
                :displayOnlyValue="currentRecommendation.more_info"
                :wideItem="true"
              />
            </div>
          </template>
          <template #componentSection>
            <TabGroup as="div">
              <div class="border-b border-gray-200">
                <TabList class="-mb-px flex space-x-8">
                  <Tab as="template" v-slot="{ selected }">
                    <button
                      :class="[
                        selected
                          ? 'border-indigo-600 text-indigo-600'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                      ]"
                    >
                      Order Details
                    </button>
                  </Tab>
                  <Tab
                    as="template"
                    v-slot="{ selected }"
                    v-if="
                      currentRecommendation?.shared_assets?.fields?.length > 0
                    "
                  >
                    <button
                      :class="[
                        selected
                          ? 'border-indigo-600 text-indigo-600'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                      ]"
                    >
                      Shared Fields
                    </button>
                  </Tab>
                  <Tab
                    as="template"
                    v-slot="{ selected }"
                    v-if="
                      currentRecommendation?.shared_assets?.equipment?.length >
                      0
                    "
                  >
                    <button
                      :class="[
                        selected
                          ? 'border-indigo-600 text-indigo-600'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                      ]"
                    >
                      Shared Equipment
                    </button>
                  </Tab>
                </TabList>
              </div>
              <TabPanels as="template">
                <TabPanel class="text-sm text-gray-500">
                  <order-object-interface
                    :product="selectedProduct"
                    :active-agreement-id="activeAgreement.id"
                    :order="currentRecommendation"
                    :read-only="true"
                  />
                  <shared-fields
                    v-if="
                      currentRecommendation?.shared_assets?.fields.length === 1
                    "
                    :fields="currentRecommendation?.shared_assets?.fields"
                    :locations="currentRecommendation?.shared_assets?.locations"
                  />
                  <shared-equipment
                    v-if="
                      currentRecommendation?.shared_assets?.equipment.length ===
                      1
                    "
                    :equipment="currentRecommendation?.shared_assets?.equipment"
                  />
                </TabPanel>

                <TabPanel
                  class="text-sm text-gray-500"
                  v-if="
                    currentRecommendation?.shared_assets?.fields?.length > 0
                  "
                >
                  <shared-fields
                    :fields="currentRecommendation?.shared_assets?.fields"
                  />
                </TabPanel>
                <TabPanel
                  class="text-sm text-gray-500"
                  v-if="
                    currentRecommendation?.shared_assets?.equipment?.length > 0
                  "
                >
                  <shared-equipment
                    :equipment="currentRecommendation?.shared_assets?.equipment"
                  />
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </template>
        </page-card>
      </TabPanel>
      <TabPanel class="pt-10" v-if="recommendationType === 'order'">
        <!-- CREATE ORDER -->
        <page-card headingTitle="Create Order">
          <template #buttonArea>
            <submit-button
              buttonText="Add to Cart"
              formId="newBuyGrowerOrderForm"
            />
          </template>
          <template #cardDetails>
            <form
              @submit.prevent="() => submitNewBuyGrowerOrderForm()"
              id="newBuyGrowerOrderForm"
              class="col-span-full"
            >
              <order-object-interface
                :product="selectedProduct"
                :active-agreement-id="activeAgreement.id"
                :fields="fields"
                :equipment="equipment"
                :readOnly="false"
                :order="currentOrder"
                @update-order="currentOrder = $event"
              />
            </form>
          </template>
        </page-card>
      </TabPanel>
      <!-- <TabPanel class="pt-10" v-if="recommendationType === 'task'">
          CREATE TASK
          <page-card headingTitle="Create Order">
            <template #buttonArea>
              <submit-button
                buttonText="Submit Task"
                formId="newGrowerTaskForm"
              />
            </template>
            <template #cardDetails>
              <form
                @submit.prevent="() => submitNewGrowerTaskForm()"
                id="newGrowerTaskForm"
                class="col-span-full"
              >
                <date-input
                  class="col-span-2 ml-1 md:col-start-7"
                  inputTitle="Deadline"
                  v-model="currentTask.deadline"
                />
                <variable-string-type-input
                  v-model="currentTask.instructions"
                  :wideItem="true"
                  inputTitle="Instructions"
                />
              </form>
            </template>
          </page-card>
        </TabPanel> -->
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";

// import NeedsActionGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/NeedsActionGrowerQuotesTable.vue";
// import AwaitingResponseGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/AwaitingResponseGrowerQuotesTable.vue";
// import ClosedGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/ClosedGrowerQuotesTable.vue";
// import CompletedGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/CompletedGrowerQuotesTable.vue";
// import InProgressGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/InProgressGrowerQuotesTable.vue";

import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
// import DeleteModal from "@/components/modals/DeleteModal.vue";
// import DateTimeInput from "@/components/form/DateTimeInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import DateInput from "@/components/form/DateInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import { useToast } from "vue-toastification";
import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";
import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";
// import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
// import HaulingOrderDetails from "@/layouts/common/orders/order-details/HaulingOrderDetails.vue";
// import DefaultOrderDetails from "@/layouts/common/orders/order-details/DefaultOrderDetails.vue";
import OrderObjectInterface from "@/layouts/common/orders/OrderObjectInterface.vue";
// import CompleteOrderForm from "@/layouts/common/orders/CompleteOrderForm.vue";

import SubmitButton from "@/components/buttons/SubmitButton.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // NeedsActionGrowerQuotesTable,
    // AwaitingResponseGrowerQuotesTable,
    // ClosedGrowerQuotesTable,
    // CompletedGrowerQuotesTable,
    // InProgressGrowerQuotesTable,
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    // DeleteModal,
    // DateTimeInput,
    TextArea,
    // CurrencyInput,
    // NumberInput,
    // DateInput,
    // CheckboxInput,
    // HorizontalPanelSteps,
    SharedEquipment,
    SharedFields,
    // ChatSingleView,
    // HaulingOrderDetails,
    // DefaultOrderDetails,
    // CompleteOrderForm,
    OrderObjectInterface,
    SubmitButton,
  },
  data() {
    const toast = useToast();
    return {
      selectedTab: 0,
      modalIsActive: false,
      toast,
      currentOrder: null,
      currentTask: null,
    };
  },
  computed: {
    currentRecommendation() {
      let currentRec = this.$store.getters.getGrowerOpportunities({
        filter: { id: parseInt(this.$route.params.oppId) },
      });
      // Careful with universal getter - always returns an array
      return currentRec[0];
    },
    activeAgreement() {
      return this.$store.getters.getGrowerQuoteById(
        parseInt(this.currentRecommendation.agreement),
      );
    },
    selectedProduct() {
      return this.$store.getters.getGrowerProductById(
        parseInt(this.activeAgreement.service),
      );
    },
    fields() {
      return this.currentRecommendation.shared_assets.fields;
    },
    // fieldsForOrder() {
    //   return this.$store.state.fields.fields;
    // },
    equipment() {
      return this.currentRecommendation.shared_assets.equipment;
    },
    // equipmentForOrder() {
    //   return this.$store.state.equipment.equipment;
    // },
    // locations() {
    //   return this.currentRecommendation.shared_assets.locations;
    // },
    // locationsForOrder() {
    //   //return this....shared_assets.locations;
    //   return [];
    // },
    recommendationType() {
      if (this.currentRecommendation.instructions) {
        return "task";
      } else {
        return "order";
      }
    },
    cancelModalTitle() {
      if (this.currentRecommendation?.status === "buyer_confirmed") {
        return "Cancel Order";
      } else {
        return "Close Order";
      }
    },
    cancelModalDescription() {
      if (this.currentRecommendation?.status === "buyer_confirmed") {
        return "Are you sure you want to cancel this confirmed order?";
      } else {
        return "Are you sure you want to close this order inquiry?";
      }
    },
    productOrderCategory() {
      switch (this.currentProduct?.category) {
        case "Hauling":
          return "HaulingOrderDetails";
        case "Input":
          return "InputOrderDetails";
        default:
          return "DefaultOrderDetails";
      }
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    async addToCart(product_id, sku_id, quantity) {
      // console.log("sku: ", sku_id);
      // console.log("quantity: ", quantity);
      let cartItem = {
        productId: product_id,
        sku: sku_id,
        qty: quantity,
        order: null,
        productType: "input",
        priceType: "quoted",
      };
      // console.log("cartItem: ", cartItem);
      await this.$store.dispatch("addCartItem", cartItem);
      if (this.navigateOnSubmit) {
        this.$router.push({ name: "grower-portal-discover" });
      }
    },
    async submitNewBuyGrowerOrderForm() {
      // console.log("submiting Order: ", this.currentOrder);
      if (this.selectedProduct.category === "Recommendation") {
        // add inputs to cart
        for (let index in this.currentOrder.details?.inputs) {
          let input = this.currentOrder.details.inputs[index];
          let sku = await this.$store.dispatch(
            "getGrowerInputUnitById",
            input.sku_id,
          );
          // console.log("adding input to cart: ", input, sku);
          await this.addToCart(input.product_id, sku, input.quantity);
        }
        await this.$store.dispatch("updateGrowerOpportunity", {
          id: this.currentRecommendation.id,
          status: "buyer_confirmed",
        });
        if (!this.$store.getters.getApiError) {
          this.toast.success("Items Added to Cart!", {
            timeout: 1500,
            hideProgressBar: true,
          });
        }
        if (!this.$store.getters.getApiError) {
          this.$router.push({ name: "grower-recommendations" });
        }
      } else {
        await this.$store.dispatch("createGrowerOrder", this.currentOrder);
        this.$store.dispatch("updateGrowerOpportunity", {
          id: this.currentRecommendation.id,
          status: "buyer_confirmed",
        });
        if (!this.$store.getters.getApiError) {
          this.toast.success("Order Created!", {
            timeout: 1500,
            hideProgressBar: true,
          });
          if (this.navigateOnSubmit) {
            this.$router.push({ name: "grower-portal-buy-orders-all" });
          }
        }
      }
    },
    archiveOpportunity() {
      this.$store.dispatch("updateGrowerOpportunity", {
        id: this.currentRecommendation.id,
        status: "buyer_rejected",
      });
      this.toast.success("Recommendation Archived!", {
        timeout: 1500,
        hideProgressBar: true,
      });
      this.$router.push({ name: "grower-recommendations" });
    },
    // async submitNewGrowerTaskForm() {
    //   await this.$store.dispatch("createTask", this.currentTask);
    //   this.toast.success("Task Created!", {
    //     timeout: 1500,
    //     hideProgressBar: true,
    //   });
    // },
  },
  watch: {
    currentRecommendation: {
      handler(value) {
        if (!value) {
          return;
        }
        // console.log(value);
        let recOrder = {
          agreement: value.agreement,
          variants: structuredClone(value.variants),
          options: structuredClone(value.options),
          form_response: structuredClone(value.form_response),
          shared_assets: {
            fields: structuredClone(value.shared_assets.fields),
            equipment: structuredClone(value.shared_assets.equipment),
            locations: structuredClone(value.shared_assets.locations),
          },
          details: structuredClone(value.details),
        };
        this.currentOrder = recOrder;
        // let recTask = {
        //   deadline: value.deadline,
        //   instructions: value.instructions,
        //   category: "Recommendation",
        // };
        // this.currentTask = recTask;
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Recommendation
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Create Tasks
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <!-- VIEW REC -->
        <page-card headingTitle="View Recommendation">
          <template #buttonArea>
            <base-button
              buttonText="Archive Recommendation"
              :redButton="true"
              @buttonClick="archiveRecommendation"
            />
            <base-button
              buttonText="Create Tasks"
              @buttonClick="changeTab(1)"
            />
          </template>
          <template #cardDetails>
            <variable-string-type-input
              :displayOnly="true"
              :displayOnlyValue="activeAgreement?.service_title"
              :wideItem="true"
              inputTitle="Listing"
            />

            <div class="sm:col-span-3">
              <label
                for="territory"
                class="block text-sm font-medium text-gray-700"
              >
                Vendor
                <router-link
                  v-if="activeAgreement"
                  :to="{
                    name: 'grower-portal-single-quote',
                    params: { quoteId: activeAgreement?.id },
                  }"
                  class="text-twilight-600 hover:text-twilight-900"
                  >(View Agreement)</router-link
                >
              </label>
              <variable-string-type-input
                :displayOnly="true"
                :displayOnlyValue="activeAgreement?.seller_details?.name"
                :wideItem="true"
              />
            </div>
            <text-area
              :wideItem="true"
              inputTitle="Memo"
              :display-only="true"
              :display-only-value="currentRecommendation?.memo"
            />
            <div class="sm:col-span-3">
              <label
                for="territory"
                class="block text-sm font-medium text-gray-700"
              >
                More Details Link
                <a
                  :href="currentRecommendation?.more_info"
                  class="text-twilight-600 hover:text-twilight-900"
                  >(View)</a
                >
              </label>
              <variable-string-type-input
                :displayOnly="true"
                :displayOnlyValue="currentRecommendation?.more_info"
                :wideItem="true"
              />
            </div>
          </template>
          <template #componentSection>
            <TabGroup as="div">
              <div class="border-b border-gray-200">
                <TabList class="-mb-px flex space-x-8">
                  <Tab as="template" v-slot="{ selected }">
                    <button
                      :class="[
                        selected
                          ? 'border-indigo-600 text-indigo-600'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                      ]"
                    >
                      Task Details
                    </button>
                  </Tab>
                  <Tab
                    as="template"
                    v-slot="{ selected }"
                    v-if="
                      currentRecommendation?.shared_assets?.fields?.length > 0
                    "
                  >
                    <button
                      :class="[
                        selected
                          ? 'border-indigo-600 text-indigo-600'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                      ]"
                    >
                      Shared Fields
                    </button>
                  </Tab>
                  <Tab
                    as="template"
                    v-slot="{ selected }"
                    v-if="
                      currentRecommendation?.shared_assets?.equipment?.length >
                      0
                    "
                  >
                    <button
                      :class="[
                        selected
                          ? 'border-indigo-600 text-indigo-600'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                      ]"
                    >
                      Shared Equipment
                    </button>
                  </Tab>
                </TabList>
              </div>
              <TabPanels as="template">
                <TabPanel class="text-sm text-gray-500">
                  <service-recommendation-object-interface
                    :active-agreement-id="activeAgreement?.id"
                    :recommendation="currentRecommendation"
                    :read-only="true"
                  />
                  <shared-fields
                    v-if="
                      currentRecommendation?.shared_assets?.fields?.length === 1
                    "
                    :fields="currentRecommendation?.shared_assets?.fields"
                    :locations="currentRecommendation?.shared_assets?.locations"
                  />
                  <shared-equipment
                    v-if="
                      currentRecommendation?.shared_assets?.equipment
                        ?.length === 1
                    "
                    :equipment="currentRecommendation?.shared_assets?.equipment"
                  />
                </TabPanel>

                <TabPanel
                  class="text-sm text-gray-500"
                  v-if="
                    currentRecommendation?.shared_assets?.fields?.length > 0
                  "
                >
                  <shared-fields
                    :fields="currentRecommendation?.shared_assets?.fields"
                  />
                </TabPanel>
                <TabPanel
                  class="text-sm text-gray-500"
                  v-if="
                    currentRecommendation?.shared_assets?.equipment?.length > 0
                  "
                >
                  <shared-equipment
                    :equipment="currentRecommendation?.shared_assets?.equipment"
                  />
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </template>
        </page-card>
      </TabPanel>
      <TabPanel class="pt-10">
        <!-- CREATE Task -->
        <page-card headingTitle="Create Tasks">
          <template #buttonArea>
            <base-button buttonText="Submit Tasks" @buttonClick="submitTasks" />
          </template>
          <template #cardDetails>
            <list-select-2
              class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
              inputTitle="Select Fields"
              :selectOptions="fields"
              select-options-label="properties.name"
              :rows="5"
              v-if="
                currentRecommendation?.shared_assets?.fields?.length > 0 &&
                currentRecommendation?.type === 'field'
              "
              @update-selections="selectedFields = $event"
            />
            <list-select-2
              class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
              inputTitle="Select Equipment"
              :selectOptions="equipment"
              select-options-label="alias"
              :rows="5"
              v-if="
                currentRecommendation?.shared_assets?.equipment?.length > 0 &&
                currentRecommendation?.type === 'equipment'
              "
              @update-selections="selectedEquipment = $event"
            />
            <div class="col-span-6"></div>
            <select-dropdown
              :wideItem="true"
              inputTitle="Category"
              v-model="currentTask.category"
              :selectOptions="taskTypes"
              :noSort="true"
            />
            <select-dropdown
              :wideItem="true"
              inputTitle="Status"
              :selectOptions="statusOptions"
              v-model="currentTask.status"
            />
            <div class="sm:col-span-3">
              <label class="block text-sm font-medium text-gray-700"
                >Assigned To</label
              >
              <div class="mt-1">
                <select
                  v-model="currentTask.user"
                  id="user"
                  name="user"
                  class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
                >
                  <option v-for="u in allUsers" :key="u.id" :value="u.id">
                    {{ u.name }}
                  </option>

                  <option v-if="allPartners?.length > 0" :value="-1">
                    __________________________
                  </option>
                  <option
                    v-for="p in allPartners"
                    :key="p.id"
                    :value="'p' + p.id"
                  >
                    {{ p.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="sm:col-span-6">
              <text-area
                inputTitle="Instructions"
                v-model="currentTask.instructions"
                dataTestid="instructions"
              />
            </div>
            <div class="sm:col-span-3">
              <date-time-input
                inputTitle="Deadline"
                v-model="currentTask.deadline"
              />
            </div>
          </template>
        </page-card>
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";

// import NeedsActionGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/NeedsActionGrowerQuotesTable.vue";
// import AwaitingResponseGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/AwaitingResponseGrowerQuotesTable.vue";
// import ClosedGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/ClosedGrowerQuotesTable.vue";
// import CompletedGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/CompletedGrowerQuotesTable.vue";
// import InProgressGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/InProgressGrowerQuotesTable.vue";

import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
// import DeleteModal from "@/components/modals/DeleteModal.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import DateInput from "@/components/form/DateInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import { useToast } from "vue-toastification";
import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";
import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";
// import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
// import HaulingOrderDetails from "@/layouts/common/orders/order-details/HaulingOrderDetails.vue";
// import DefaultOrderDetails from "@/layouts/common/orders/order-details/DefaultOrderDetails.vue";
import ServiceRecommendationObjectInterface from "@/layouts/common/recommendations/ServiceRecommendationObjectInterface.vue";
// import CompleteOrderForm from "@/layouts/common/orders/CompleteOrderForm.vue";
import ListSelect2 from "@/components/form/ListSelect2.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // NeedsActionGrowerQuotesTable,
    // AwaitingResponseGrowerQuotesTable,
    // ClosedGrowerQuotesTable,
    // CompletedGrowerQuotesTable,
    // InProgressGrowerQuotesTable,
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    // DeleteModal,
    DateTimeInput,
    TextArea,
    // CurrencyInput,
    // NumberInput,
    // DateInput,
    // CheckboxInput,
    // HorizontalPanelSteps,
    SharedEquipment,
    SharedFields,
    // ChatSingleView,
    // HaulingOrderDetails,
    // DefaultOrderDetails,
    // CompleteOrderForm,
    ServiceRecommendationObjectInterface,
    ListSelect2,
    SelectDropdown,
  },
  data() {
    const statusOptions = [
      "Recommended",
      "Requested",
      "Ready",
      "Assigned",
      "InProcess",
      "Complete",
      "Canceled",
    ];
    let taskTypes = [
      "Planting",
      "Tilling",
      "Irrigation",
      "Spraying",
      "Manual / Hand labor",
      "Hauling",
      "Scouting",
      "Observation",
      "Other field task",
      "Harvesting",
      // equipment tasks
      "Maintenance",
      "Repair",
      "Other equipment task",
    ];
    const toast = useToast();
    return {
      selectedTab: 0,
      modalIsActive: false,
      toast,
      statusOptions,
      taskTypes,
      currentTask: {
        category: "", // string
        instructions: "", // string
        status: null, // string - enum
        user: null, // int
        source: null, // int
        field: null, // int
        deadline: null, // string - datetime
      },
      selectedFields: [],
      selectedEquipment: [],
    };
  },
  computed: {
    currentRecommendation() {
      let currentRec = this.$store.getters.getEnrichedGrowerRecommendations({
        filter: { id: parseInt(this.$route.params.recId) },
      });
      // Careful with universal getter - always returns an array
      if (!currentRec || currentRec.length === 0) return null;
      return currentRec[0];
    },
    activeAgreement() {
      if (!this.currentRecommendation) return null;
      return this.$store.getters.getGrowerQuoteById(
        parseInt(this.currentRecommendation.agreement),
      );
    },
    fields() {
      if (!this.currentRecommendation) return [];
      return this.currentRecommendation.shared_assets.fields;
    },
    // fieldsForOrder() {
    //   return this.$store.state.fields.fields;
    // },
    equipment() {
      if (!this.currentRecommendation) return [];
      return this.currentRecommendation.shared_assets.equipment;
    },
    // equipmentForOrder() {
    //   return this.$store.state.equipment.equipment;
    // },
    // locations() {
    //   return this.currentRecommendation.shared_assets.locations;
    // },
    // locationsForOrder() {
    //   //return this....shared_assets.locations;
    //   return [];
    // },
    allUsers() {
      return this.$store.state.users.users;
    },
    allPartners() {
      let partners = [];
      this.$store.state.orgs.orgs.forEach((partner) => {
        // console.log("partner flags", partner.name, partner.services);
        if (
          partner.services.find((service) => service === "agronomic-advice")
        ) {
          // TODO: only show partners that have the work order feature flag vp-work-orders
          partners.push(partner);
        }
      });
      return partners;
    },
    assignedUserId() {
      return this.currentTask.user;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    async submitTasks() {
      // check if the currentRecommendation.type is equal to field or equipment
      // if field, then create a task for each field in the selectedFields array
      // if equipment, then create a task for each equipment in the selectedEquipment array
      if (this.currentRecommendation.type === "field") {
        this.selectedFields.forEach((field) => {
          // set task.field to the field id
          let task = {
            ...this.currentTask,
            field: field.id,
          };
          this.$store.dispatch("createTask", task);
        });
      } else if (this.currentRecommendation.type === "equipment") {
        this.selectedEquipment.forEach((equipment) => {
          let task = {
            ...this.currentTask,
            equipment: equipment.id,
          };
          this.$store.dispatch("createTask", task);
        });
      }
      await this.$store.dispatch("updateGrowerRecommendation", {
        id: this.currentRecommendation.id,
        status: "buyer_confirmed",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Tasks Created!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({
        name: "grower-recommendations",
      });
    },
    async archiveRecommendation() {
      await this.$store.dispatch("updateGrowerRecommendation", {
        id: this.currentRecommendation.id,
        status: "buyer_rejected",
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Recommendation Archived!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({
        name: "grower-recommendations",
      });
    },
  },
  watch: {
    currentRecommendation: {
      handler(value) {
        if (!value) {
          return;
        }
        // console.log(value);
        let recTask = {
          source: this.currentUser.id,
          source_recommendation: value.id,
          field: null,
          equipment: null,
          type: value.type,
          user: null,
          assigned_org: null,
          category: value.category, // String
          status: "", // String
          instructions: value.instructions, // String
          deadline: value.deadline?.substring(0, 16), // date-time
          details: {}, // Object
        };
        this.currentTask = recTask;
      },
      immediate: true,
    },
  },
};
</script>
details: structuredClone(value.details),

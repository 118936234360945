import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      controllerOrders: [],
    };
  },
  mutations: {
    SET_CONTROLLER_CROP_ORDERS(state, orderData) {
      state.controllerOrders = orderData;
    },
    RESET_CONTROLLER_CROP_ORDERS(state) {
      state.controllerOrders = [];
    },
  },
  actions: {
    async getControllerCropOrders({ commit }) {
      const data = await client.controller.cropOrders.read();
      if (data) {
        // console.log("getControllerCropOrders", data);
        commit("SET_CONTROLLER_CROP_ORDERS", data.orders);
      }
    },
  },
  getters: {
    getAllControllerOrders: (state) => {
      return state.controllerOrders;
    },
    getEnrichedControllerOrders:
      (state, getters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // console.log("getEnrichedControllerOrders")
        let controllerAllocations = getters.getAllControllerAllocations;
        // console.log("controllerAllocations", controllerAllocations)
        let initialControllerOrders = state.controllerOrders;
        // console.log("initialControllerOrders", initialControllerOrders);
        initialControllerOrders.forEach((controllerOrder) => {
          // console.log("controllerOrder", controllerOrder.units, controllerOrder.quantity, controllerOrder);
          // Get crop Type
          let crops = controllerOrder.crop_type || "";
          let varieties = controllerOrder.crop_variety || "";
          if (crops == "" && controllerOrder.details?.crop)
            crops = controllerOrder.details.crop;
          let allocatedAcres = 0;
          for (let allocation of controllerAllocations) {
            if (
              allocation.crop_order == controllerOrder.id &&
              crops.indexOf(allocation.shared_data.crop_details?.crop_type) ==
                -1
            ) {
              if (crops.length > 0) crops += ", ";
              crops += allocation.shared_data.crop_details?.crop_type;
            }
            // Get crop Variety
            if (
              allocation.crop_order == controllerOrder.id &&
              varieties.indexOf(
                allocation.shared_data.crop_details?.details?.variety,
              ) == -1
            ) {
              if (varieties.length > 0) varieties += ", ";
              varieties +=
                allocation.shared_data.crop_details?.details?.variety;
            }
            // Get allocated acres
            if (allocation.crop_order == controllerOrder.id) {
              allocatedAcres += allocation.acres;
            }
          }
          controllerOrder.crop_type = crops;
          controllerOrder.crop_variety = varieties;
          controllerOrder.allocated_acres = allocatedAcres;
        });
        // console.log("initialControllerOrders", initialControllerOrders);
        // let expandedControllerOrders = initialControllerOrders.map((order) => ({
        //   ...order,
        // }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterControllerOrders = filterFunction
          ? _.filter(initialControllerOrders, filterFunction)
          : initialControllerOrders;
        let filteredControllerOrders = _.isEmpty(filter)
          ? functionFilterControllerOrders
          : _.filter(functionFilterControllerOrders, filter);
        let matchedControllerOrders = _.isEmpty(match)
          ? filteredControllerOrders
          : _.filter(filteredControllerOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedControllerOrders = _.isEmpty(sort)
          ? matchedControllerOrders
          : _.sortBy(matchedControllerOrders, sort);
        let paginatedControllerOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedControllerOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedControllerOrders;
        return paginatedControllerOrders;
      },
    getControllerOrderById: (state) => (orderId) => {
      return state.controllerOrders.find(
        (order) => order.id === parseInt(orderId),
      );
    },
    // Inquiries
    getControllerInquiries: (state) => {
      return state.controllerOrders.filter(
        (order) => order.status === "Inquiry",
      );
    },
    getControllerInquiriesBySeller: (state, getters) => (seller) => {
      const orders = state.controllerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      const inquiries = orders.filter((order) => order.status === "Inquiry");
      return inquiries.map((order) => {
        order.allocated_acres = getters
          .getControllerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getControllerInquirySellers: (state) => {
      const orders = state.controllerOrders.filter(
        (order) => order.status === "Inquiry",
      );
      // console.log("Inquiry", orders);
      return orders.reduce((sellers, order) => {
        if (sellers[order.seller_details.id] == null) {
          sellers[order.seller_details.id] = order.seller;
          sellers[order.seller_details.id].tons_inquired = 0;
          // sellers[order.seller_details.id].chat_thread_id = 0;
        }
        sellers[order.seller_details.id].tons_inquired += order.quantity;
        return sellers;
      }, {});
    },
    // In Progress
    // NOT CONVERTED TO CONTROLLER
    // getControllerInProgressOrders: (state, getters) => () => {
    //   let orders = state.controllerOrders.filter(
    //     (order) => order.status === "In Progress",
    //   );
    //   orders = orders.filter(
    //     (order) =>
    //       getters.getVendorById(order.seller_details.id)?.seller_confirmed,
    //   );
    //   return orders.map((order) => {
    //     order.allocated_acres = getters
    //       .getControllerAllocationsByOrder(order.id)
    //       .reduce((acres, alloc) => {
    //         return acres + (alloc.acres || 0);
    //       }, 0);
    //     return order;
    //   });
    // },
    getControllerInProgressOrdersBySeller: (state, getters) => (seller) => {
      const orders = state.controllerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      const inProgress = orders.filter(
        (order) => order.status === "In Progress",
      );
      return inProgress.map((order) => {
        order.allocated_acres = getters
          .getControllerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    // NOT CONVERTED TO CONTROLLER
    // getControllerInProgressOrderSellers: (state, getters) => {
    //   let orders = state.controllerOrders.filter(
    //     (order) => order.status === "In Progress",
    //   );
    //   orders = orders.filter(
    //     (order) =>
    //       getters.getVendorById(order.seller_details.id)?.seller_confirmed,
    //   );

    //   // console.log("In Progress Orders", orders);
    //   return orders.reduce((sellers, order) => {
    //     if (sellers[order.seller_details.id] == null) {
    //       sellers[order.seller_details.id] = order.seller;
    //       sellers[order.seller_details.id].tons_ordered = 0;
    //       sellers[order.seller_details.id].allocated_acres = 0;
    //     }
    //     if (order.units == "Tons") {
    //       sellers[order.seller_details.id].tons_ordered += order.quantity;
    //     }
    //     let allocations = getters.getControllerAllocationsByOrder(order.id);
    //     let crop_types = sellers[order.seller_details.id].crop_types || "";
    //     let varieties = sellers[order.seller_details.id].varieties || "";
    //     allocations.forEach((alloc) => {
    //       if (alloc.crop_details?.crop_type) {
    //         if (crop_types.indexOf(alloc.crop_details.crop_type) == -1) {
    //           if (crop_types.length != 0) {
    //             crop_types += ", ";
    //           }
    //           crop_types += alloc.crop_details.crop_type;
    //         }
    //       }
    //       if (alloc.crop_details?.details?.variety) {
    //         if (varieties.indexOf(alloc.crop_details?.details?.variety) == -1) {
    //           if (varieties.length != 0) {
    //             varieties += ", ";
    //           }
    //           varieties += alloc.crop_details?.details?.variety;
    //         }
    //       }
    //     });
    //     sellers[order.seller_details.id].crop_types = crop_types;
    //     sellers[order.seller_details.id].varieties = varieties;
    //     sellers[order.seller_details.id].allocated_acres += getters
    //       .getControllerAllocationsByOrder(order.id)
    //       .reduce((acres, alloc) => {
    //         return acres + (alloc.acres || 0);
    //       }, 0);

    //     return sellers;
    //   }, {});
    // },
    //
    // Canceled
    // NOT CONVERTED TO CONTROLLER
    // getControllerCanceledOrders: (state, getters) => () => {
    //   let orders = state.controllerOrders.filter(
    //     (order) => order.status === "Canceled",
    //   );
    //   orders = orders.filter(
    //     (order) =>
    //       getters.getVendorById(order.seller_details.id)?.seller_confirmed,
    //   );
    //   return orders;
    // },
    getControllerCanceledOrdersBySeller: (state, getters) => (seller) => {
      const orders = state.controllerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      const inProgress = orders.filter((order) => order.status === "Canceled");
      return inProgress.map((order) => {
        order.allocated_acres = getters
          .getControllerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    // Completed
    // NOT CONVERTED TO CONTROLLER
    // getControllerCompletedOrders: (state, getters) => () => {
    //   let orders = state.controllerOrders.filter(
    //     (order) => order.status === "Complete",
    //   );
    //   orders = orders.filter(
    //     (order) =>
    //       getters.getVendorById(order.seller_details.id)?.seller_confirmed,
    //   );
    //   return orders;
    // },
    //
    getControllerCropOrdersBySeller: (state, getters) => (seller) => {
      const orders = state.controllerOrders.filter(
        (order) => order.seller_details.id == seller,
      );
      return orders.map((order) => {
        order.allocated_acres = getters
          .getControllerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getControllerCropOrdersByMarketOffer: (state, getters) => (marketOffer) => {
      const orders = state.controllerOrders.filter(
        (order) => order.offer == marketOffer,
      );
      return orders.map((order) => {
        order.allocated_acres = getters
          .getControllerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return order;
      });
    },
    getControllerCropOrderSellers: (state, getters) => {
      return state.controllerOrders.reduce((sellers, order) => {
        if (sellers[order.seller_details.id] == null) {
          sellers[order.seller_details.id] = order.seller;
          sellers[order.seller_details.id].tons_ordered = 0;
          sellers[order.seller_details.id].allocated_acres = 0;
        }
        if ((order.units == "Tons") == "Tons") {
          sellers[order.seller_details.id].tons_ordered += order.quantity;
        }
        sellers[order.seller_details.id].allocated_acres += getters
          .getControllerAllocationsByOrder(order.id)
          .reduce((acres, alloc) => {
            return acres + (alloc.acres || 0);
          }, 0);
        return sellers;
      }, {});
    },
    getControllerCropOrderGrowers: (state) => {
      // iterate through orders and get the unique growers from order.seller_details.id and order.seller_details.name
      let growers = [];
      state.controllerOrders.forEach((order) => {
        let grower = {
          id: order.seller_details.id,
          name: order.seller_details.name,
        };
        if (!growers.some((g) => g.id === grower.id)) {
          growers.push(grower);
        }
      });
      return growers;
    },
    getControllerCropOrderPremiums: (state) => {
      // iterate through orders
      // for each order, extract all premiums from the array order.premiums
      // add an order_id attribute to each premium from the order.id
      // return all premiums
      let premiums = [];
      state.controllerOrders.forEach((order) => {
        if (!order.premiums) {
          return;
        }
        order.premiums.forEach((premium) => {
          premium.order_id = order.id;
          // if (!premiums.some((p) => p.id === premium.id)) {
          //   premiums.push(premium);
          // }
          premiums.push(premium);
        });
      });
      return premiums;
    },
    getDerivedControllerCropOrders:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedControllerOrders = state.controllerOrders.map((order) => ({
          ...order,
          // seller_name: order.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterControllerOrders = filterFunction
          ? _.filter(expandedControllerOrders, filterFunction)
          : expandedControllerOrders;
        let filteredControllerOrders = _.isEmpty(filter)
          ? functionFilterControllerOrders
          : _.filter(functionFilterControllerOrders, filter);
        let matchedControllerOrders = _.isEmpty(match)
          ? filteredControllerOrders
          : _.filter(filteredControllerOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedControllerOrders = _.isEmpty(sort)
          ? matchedControllerOrders
          : _.sortBy(matchedControllerOrders, sort);
        let paginatedControllerOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedControllerOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedControllerOrders;
        return paginatedControllerOrders;
      },
    // getDerivedControllerCropOrders:
    //   (state) =>
    //   ({
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let expandedControllerCropOrders = state.controllerOrders;
    //     //console.log("expandedControllerCropOrders", expandedControllerCropOrders);

    //     // This section checks if a filter or set of filters exists. If it does, it loops through and checks if any have nested objects to filter,
    //     // like "user.name". If it does, it substitutes the "user.name:value" key for an actual object {user{name:value}}
    //     // NOTE: only currently used in tasks, if it becomes generally needed, we can move to TableHeaderCellWithFilterButton to make it take effect
    //     // globally.
    //     if (
    //       filter &&
    //       Object.keys(filter).length > 0 &&
    //       Object.getPrototypeOf(filter) === Object.prototype
    //     ) {
    //       //console.log("break up", filter);
    //       let newFilter = {};
    //       // loop through each key in the filter to see if we need to parse it.
    //       for (let key in filter) {
    //         //console.log("filterClause", `${key}:${filter[key]}`);
    //         if (key && key.split(".").length > 1) {
    //           var schema = newFilter; // a moving reference to internal objects within obj
    //           var pList = key.split(".");
    //           var len = pList.length;

    //           for (var i = 0; i < len - 1; i++) {
    //             var elem = pList[i];
    //             if (!schema[elem]) schema[elem] = {};
    //             schema = schema[elem];
    //           }
    //           schema[pList[len - 1]] = filter[key];
    //           //console.log("cleaned",newFilter);
    //         } else {
    //           // Not one we need to break up, just copy it to our new object.
    //           newFilter[key] = filter[key];
    //         }
    //       }
    //       filter = newFilter;
    //     }

    //     let filteredControllerCropOrders = _.isEmpty(filter)
    //       ? expandedControllerCropOrders
    //       : _.filter(expandedControllerCropOrders, filter);
    //     let matchedControllerCropOrders = _.isEmpty(match)
    //       ? filteredControllerCropOrders
    //       : _.filter(filteredControllerCropOrders, (item) =>
    //           _.some(item, (val) =>
    //             _.includes(_.lowerCase(val), _.lowerCase(match))
    //           )
    //         );
    //     let sortColumns = Object.keys(sort);
    //     let sortOrders = Object.values(sort);
    //     //console.log("sort in index",sort,sortColumns, sortOrders);
    //     let sortedControllerCropOrders = _.isEmpty(sort)
    //       ? matchedControllerCropOrders
    //       : _.orderBy(matchedControllerCropOrders, sortColumns, sortOrders);
    //     let paginatedControllerCropOrders =
    //       _.isNumber(pageSize) && _.isNumber(pageNum)
    //         ? _.slice(
    //             sortedControllerCropOrders,
    //             pageSize * pageNum, // skip
    //             pageSize * pageNum + pageSize // limit
    //           )
    //         : sortedControllerCropOrders;
    //     return paginatedControllerCropOrders;
    //   },
  },
};

import client from "@/api/parcel/api.js";
// import { universalGetter } from "@/components/composables/universalGetter";
import _ from "lodash";

export default {
  state() {
    return {
      buyerMarketOffers: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_BUYER_MARKET_OFFERS(state, buyerMarketOfferData) {
      state.buyerMarketOffers = buyerMarketOfferData;
    },
    ADD_BUYER_MARKET_OFFER(state, buyerMarketOfferObject) {
      state.buyerMarketOffers.push(buyerMarketOfferObject);
    },
    UPDATE_BUYER_MARKET_OFFER(state, buyerMarketOfferObject) {
      state.buyerMarketOffers = state.buyerMarketOffers.filter(function (obj) {
        return obj.id !== buyerMarketOfferObject.id;
      });
      state.buyerMarketOffers.push(buyerMarketOfferObject);
    },
    DELETE_BUYER_MARKET_OFFER(state, buyerMarketOfferObject) {
      state.buyerMarketOffers = state.buyerMarketOffers.filter(function (obj) {
        return obj.id !== buyerMarketOfferObject.id;
      });
    },
    RESET_BUYER_MARKET_OFFERS(state) {
      state.buyerMarketOffers = [];
      state.filterObject = {};
    },
    SET_FILTER(state, filterObject) {
      state.filterObject = filterObject;
    },
  },
  actions: {
    async getBuyerMarketOffers({ commit }) {
      const data = await client.buyer.marketOffers.read();
      if (data) {
        commit("SET_BUYER_MARKET_OFFERS", data.offers);
      }
    },
    async createBuyerMarketOffer({ commit }, buyerMarketOffer) {
      const data = await client.buyer.marketOffers.create(
        null,
        buyerMarketOffer,
      );
      if (data) {
        // console.log(data);
        commit("ADD_BUYER_MARKET_OFFER", data);
        // update buyerMarketOffers list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getBuyerMarketOffers");
      }
    },
    async updateBuyerMarketOffer({ commit }, buyerMarketOffer) {
      const data = await client.buyer.marketOffers.update(
        buyerMarketOffer.id,
        buyerMarketOffer,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_BUYER_MARKET_OFFER", data);
        // update buyerMarketOffers list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getBuyerMarketOffers");
      }
    },
    async deleteBuyerMarketOffer({ commit }, buyerMarketOffer) {
      const data = await client.buyer.marketOffers.delete(buyerMarketOffer.id);
      if (data) {
        // console.log(data);
        commit("DELETE_BUYER_MARKET_OFFER", buyerMarketOffer);
        this.dispatch("getBuyerMarketOffers");
      }
    },
  },
  getters: {
    getBuyerMarketOfferById:
      (state) =>
      (buyerMarketOfferId, includeDeletedPremiums = false) => {
        let offer = state.buyerMarketOffers.find(
          (buyerMarketOffer) =>
            buyerMarketOffer.id === parseInt(buyerMarketOfferId),
        );

        if (offer) {
          // Create a shallow copy of the offer to prevent direct state mutation
          let offerCopy = { ...offer };

          if (!includeDeletedPremiums) {
            // Filter premiums without mutating the original state
            offerCopy.premiums = offer.premiums.filter(
              (premium) => !premium.deleted,
            );
          }
          return offerCopy;
        }

        return offer;
      },
    getBuyerMarketOffers:
      (state) =>
      (includeDeleted = false) => {
        if (includeDeleted) {
          // Return a shallow copy of the array to avoid mutating the original state
          return [...state.buyerMarketOffers];
        } else {
          // Filter and return a new array without modifying the state
          return state.buyerMarketOffers.filter(
            (buyerMarketOffer) => !buyerMarketOffer.deleted,
          );
        }
      },
    // UNIVERSAL GETTER
    // getBuyerMarketOffers:
    //   (state) =>
    //   ({
    //     filterFunction = null,
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let derivedBuyerMarketOffers = universalGetter(
    //       {
    //         filterFunction: filterFunction,
    //         filter: filter,
    //         match: match,
    //         sort: sort,
    //         pageSize: pageSize,
    //         pageNum: pageNum,
    //       },
    //       state.buyerMarketOffers
    //     );
    //     return derivedBuyerMarketOffers;
    //   },
    getDerivedBuyerMarketOffers:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedBuyerMarketOffers = state.buyerMarketOffers.map((item) => ({
          ...item,
        }));
        let filteredBuyerMarketOffers = _.isEmpty(filter)
          ? expandedBuyerMarketOffers
          : _.filter(expandedBuyerMarketOffers, filter);
        let matchedBuyerMarketOffers = _.isEmpty(match)
          ? filteredBuyerMarketOffers
          : _.filter(filteredBuyerMarketOffers, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedBuyerMarketOffers = _.isEmpty(sort)
          ? matchedBuyerMarketOffers
          : _.orderBy(matchedBuyerMarketOffers, sortColumns, sortOrders);
        let paginatedBuyerMarketOffers =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedBuyerMarketOffers,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedBuyerMarketOffers;
        return paginatedBuyerMarketOffers;
      },
    getBuyerMarketOffersFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'alias', label: 'Name', filtered: false },
          { name: 'equipment_model', label: 'Model' },
          { name: 'equipment_class', label: 'Type' },
          {
            name: 'last_service_date',
            label: 'Last Service Date',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="equipment"
        @update:derived-collection="derivedEquipment = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Import"
              heroIcon="UploadIcon"
              @buttonClick="goToImportEquipmentPage"
            />
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewEquipmentPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="asset in derivedEquipment" :key="asset.id">
        <table-data td-label="Name">
          {{ asset.alias }}
        </table-data>
        <table-data td-label="Model">
          {{ asset.equipment_model }}
        </table-data>
        <table-data td-label="Type">
          {{ asset.equipment_class }}
        </table-data>
        <table-data td-label="Last Service Date">
          {{ asset.last_service_date?.substring(0, 10) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'single-equipment',
              params: { equipmentId: asset.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },

  data() {
    return {
      derivedEquipment: [],
    };
  },
  computed: {
    equipment() {
      return this.$store.getters.getDerivedEquipment({});
    },
  },
  methods: {
    goToNewEquipmentPage() {
      this.$router.push({ name: "new-equipment" });
    },
    goToImportEquipmentPage() {
      this.$router.push({ name: "import-equipment" });
    },
  },
};
</script>

<template>
  <div v-for="(value, key) in mappedAttributes" :key="key">
    <select-search
      :inputTitle="value.label"
      :wideItem="true"
      :required="value.required"
      :selectOptions="options"
      v-model="value.value"
      :maxItem="250"
      @onBlur="updateMapping"
    />
  </div>
</template>
<script>
//import BaseButton from '../buttons/BaseButton.vue';
import SelectSearch from "../form/SelectSearch.vue";
export default {
  components: {
    SelectSearch,
    //BaseButton
  },
  props: {
    attributes: {
      type: Object,
      default: new Object(),
    },
    options: {
      // select-dropdown format, either 'option1', 'option2' or {value: 'option1', label: 'option1'}
      type: Array,
      default: new Array(),
    },
    modelValue: { type: Object },
  },
  emits: ["update:modelValue"],
  data() {
    let mappedAttributes = {};
    for (const [key, { label, required }] of Object.entries(this.attributes)) {
      mappedAttributes[key] = {
        label: label,
        required: required,
      };
      // console.log("key", key, this.options);
      if (
        this.options.find(
          (option) =>
            option?.value.toLowerCase().replace(" ", "_") ==
            key.toLocaleLowerCase().replace(" ", "_"),
        )
      ) {
        mappedAttributes[key].value = key;
      }
    }
    this.$emit("update:modelValue", mappedAttributes);
    return {
      mappings: {}, //[{key, value}]
      mappedAttributes: mappedAttributes, //[{key, value}]
    };
  },
  computed: {},
  methods: {
    updateMapping() {
      // console.log("updateMapping", this.mappedAttributes);
      this.mappings = this.mappedAttributes;
      this.$emit("update:modelValue", this.mappings);
    },
  },
  watch: {},
};
</script>

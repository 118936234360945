import _ from "lodash";
import client from "@/api/parcel/api.js";
import { prettyQuoteStatusGrower } from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      growerQuotes: [],
    };
  },
  mutations: {
    SET_GROWER_QUOTES(state, growerQuoteData) {
      state.growerQuotes = growerQuoteData;
    },
    ADD_GROWER_QUOTE(state, growerQuoteObject) {
      const result = state.growerQuotes.find(
        (growerQuote) => growerQuote.id === parseInt(growerQuoteObject.id),
      );
      if (!result) state.growerQuotes.push(growerQuoteObject);
    },
    UPDATE_GROWER_QUOTE(state, growerQuoteObject) {
      state.growerQuotes = state.growerQuotes.filter(function (obj) {
        return obj.id !== growerQuoteObject.id;
      });
      state.growerQuotes.push(growerQuoteObject);
    },
    RESET_GROWER_QUOTES(state) {
      state.growerQuotes = [];
    },
  },
  actions: {
    async getGrowerQuotes({ commit }) {
      const data = await client.grower.serviceAgreements.read();
      if (data) {
        // console.log("growerQuotes", data);
        commit("SET_GROWER_QUOTES", data.agreements);
      }
    },
    async createGrowerQuote({ commit }, growerQuote) {
      const data = await client.grower.serviceAgreements.create(
        null,
        growerQuote,
      );
      if (data) {
        commit("ADD_GROWER_QUOTE", data);
        this.dispatch("getGrowerQuotes");
        return data;
      }
    },
    async updateGrowerQuote({ commit }, growerQuote) {
      const data = await client.grower.serviceAgreements.update(
        growerQuote.id,
        growerQuote,
      );
      if (data) {
        commit("UPDATE_GROWER_QUOTE", data);
        this.dispatch("getGrowerQuotes");
      }
    },
  },
  getters: {
    getAllGrowerQuotes: (state) => {
      return state.growerQuotes;
    },
    getGrowerQuoteById: (state) => (growerQuoteId) => {
      return state.growerQuotes.find(
        (growerQuote) => growerQuote.id === parseInt(growerQuoteId),
      );
    },
    getDerivedGrowerQuotes:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedGrowerQuotes = state.growerQuotes.map((quote) => ({
          ...quote,
          prettyStatus: prettyQuoteStatusGrower(quote.status),
          seller_name: quote.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterGrowerQuotes = filterFunction
          ? _.filter(expandedGrowerQuotes, filterFunction)
          : expandedGrowerQuotes;
        let filteredGrowerQuotes = _.isEmpty(filter)
          ? functionFilterGrowerQuotes
          : _.filter(functionFilterGrowerQuotes, filter);
        let matchedGrowerQuotes = _.isEmpty(match)
          ? filteredGrowerQuotes
          : _.filter(filteredGrowerQuotes, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedGrowerQuotes = _.isEmpty(sort)
          ? matchedGrowerQuotes
          : _.sortBy(matchedGrowerQuotes, sort);
        let paginatedGrowerQuotes =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerQuotes,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerQuotes;
        return paginatedGrowerQuotes;
      },
    // Always prefer getDerivedQuotes for component data. These are helpers to expose data for specific pages that only needs some data
    getNeedsActionGrowerQuotes: (state) => {
      return state.growerQuotes.filter(
        (growerQuote) => growerQuote.status === "seller_responded",
      );
    },
    getAwaitingResponseGrowerQuotes: (state) => {
      return state.growerQuotes.filter(
        (growerQuote) =>
          growerQuote.status === "buyer_submitted" ||
          growerQuote.status === "buyer_rejected",
      );
    },
    getClosedGrowerQuotes: (state) => {
      return state.growerQuotes.filter(
        (growerQuote) =>
          growerQuote.status === "buyer_closed" ||
          growerQuote.status === "seller_closed" ||
          growerQuote.status === "seller_canceled",
      );
    },
    getInProgressGrowerQuotes: (state) => {
      return state.growerQuotes.filter(
        (growerQuote) => growerQuote.status === "buyer_confirmed",
      );
    },
    getCompletedGrowerQuotes: (state) => {
      return state.growerQuotes.filter(
        (growerQuote) => growerQuote.status === "seller_completed",
      );
    },
    getActiveAgreements: (state) => {
      return state.growerQuotes.filter(
        (growerQuote) => growerQuote.status === "buyer_confirmed",
      );
    },
    getActiveAgreementsByProductId: (state) => (productId) => {
      return state.growerQuotes.filter(
        (growerQuote) =>
          growerQuote.status === "buyer_confirmed" &&
          growerQuote.service === productId,
      );
    },
  },
};

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'title', label: 'Listing', filtered: false },
          { name: 'crop_type', label: 'Crop' },

          { name: 'crop_details.variety', label: 'Variety' },
          {
            name: 'total_available',
            label: 'Quantity',
            filtered: false,
          },
          { name: 'qty_units', label: 'Units' },
          { name: 'asking_price', label: 'Asking Price', filtered: false },

          {
            name: 'min_purchase',
            label: 'Minimum Order Quantity',
            filtered: false,
          },
          {
            name: 'harvest_year',
            label: 'Harvest Year',
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="listings"
        @update:derived-collection="derivedListings = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewListingPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="listing in derivedListings" :key="listing.id">
        <table-data td-label="Title">
          {{ listing?.title }}
        </table-data>
        <table-data td-label="Crop">
          {{ listing?.crop_type }}
        </table-data>
        <table-data td-label="Variety">
          {{ listing?.crop_details?.variety }}
        </table-data>
        <table-data td-label="Quantity">
          {{ listing?.total_available }}
        </table-data>
        <table-data td-label="Units">
          {{ listing?.qty_units }}
        </table-data>
        <table-data td-label="Asking Price">
          {{ "$" + listing?.asking_price.toFixed(2) }}
        </table-data>
        <table-data td-label="Minimum Order Quantity">
          {{ listing?.min_purchase }}
        </table-data>
        <table-data td-label="Harvest Year">
          {{ listing?.harvest_year }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'grower-portal-produce-listing',
              params: { listingId: listing.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  data() {
    return {
      derivedListings: [],
    };
  },
  computed: {
    listings() {
      return this.$store.getters.getDerivedGrowerListings({});
    },
  },
  methods: {
    goToNewListingPage() {
      this.$router.push({ name: "new-grower-portal-produce-listing" });
    },
  },
};
</script>

import client from "@/api/parcel/api.js";

export default {
  state() {
    return { orders: [] };
  },
  mutations: {
    SET_VENDOR_INPUT_ORDERS(state, data) {
      state.orders = data;
    },
    RESET_VENDOR_INPUT_ORDERS(state) {
      state.orders = [];
    },
    UPDATE_VENDOR_INPUT_ORDER(state, order) {
      state.orders = state.orders.filter((o) => o.id !== order.id);
      state.orders.push(order);
    },
    UPDATE_VENDOR_INPUT_ORDER_LINE_ITEM(state, { id, lineItem }) {
      const order = state.orders.find((o) => o.id === id);
      order.line_items = order.line_items.filter((l) => l.id !== lineItem.id);
      order.line_items.push(lineItem);
    },
    REMOVE_VENDOR_INPUT_ORDER_LINE_ITEM(state, { id, lineItem }) {
      const order = state.orders.filter((o) => o.id === id);
      order.line_items = order.line_items.filter((l) => l.id !== lineItem);
    },
  },
  actions: {
    async getVendorInputOrders({ commit, dispatch }) {
      const data = await client.vendor.input_orders.read();
      if (data) {
        commit("SET_VENDOR_INPUT_ORDERS", data.input_orders);
        const requests = new Set();
        data.input_orders.forEach((order) => {
          order.line_items.forEach((item) => {
            if (item.input_request) requests.add(item.input_request);
          });
        });
        // TODO: replace with one API call
        requests.forEach((request) =>
          dispatch("getGrowerInputRequestById", request),
        );
      }
    },
    async updateVendorInputOrder({ commit }, order) {
      const data = await client.vendor.input_orders.update(order.id, order);
      if (data) {
        commit("UPDATE_VENDOR_INPUT_ORDER", data);
        return data;
      }
    },
    async cancelVendorInputOrder({ commit }, order) {
      const data = await client.vendor.input_orders.delete(order.id);
      if (data) {
        commit("UPDATE_VENDOR_INPUT_ORDER", data);
        return data;
      }
    },
    async addVendorInputOrderLineItem(_, { order, lineItem }) {
      const data = await client.vendor.input_orders.createLineItem(
        order,
        lineItem,
      );
      if (data) {
        // commit("ADD_VENDOR_INPUT_ORDER_LINE_ITEM", { id: order, lineItem: data });
        // this.dispatch("getGrowerInputOrders");
        return data;
      }
    },
    async updateVendorInputOrderLineItem({ commit }, { order, lineItem }) {
      const data = await client.vendor.input_orders.updateLineItem(
        lineItem.id,
        lineItem,
      );
      if (data) {
        commit("UPDATE_VENDOR_INPUT_ORDER_LINE_ITEM", {
          id: order,
          lineItem: data,
        });
        return data;
      }
    },
    async removeVendorInputOrderLineItem({ commit }, { order, lineItem }) {
      const data = await client.vendor.input_orders.updateLineItem(lineItem);
      if (data) {
        commit("REMOVE_VENDOR_INPUT_ORDER_LINE_ITEM", { order, lineItem });
        return data;
      }
    },
  },
  getters: {},
};

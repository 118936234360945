<template>
  <page-card
    v-for="(premium, index) in activePremiums"
    :key="premium.id"
    :heading-title="'Premium ' + (index + 1)"
    :inserted="true"
  >
    <template #cardDetails>
      <div
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <premium-form :premium="premium" />
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import PremiumForm from "@/layouts/grower-portal/sell/offers/PremiumForm.vue";

export default {
  props: {
    premiums: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: [Number, String],
      default: null,
    },
  },
  components: {
    PageCard,
    PremiumForm,
  },
  data() {
    return {};
  },
  computed: {
    activePremiums() {
      return this.premiums.filter((p) => !p.deleted);
    },
  },
  methods: {},
};
</script>

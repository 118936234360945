import client from "@/api/parcel/api.js";
// import { universalGetter } from "@/components/composables/universalGetter";
import _ from "lodash";

export default {
  state() {
    return {
      contracts: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_CONTRACTS(state, contractData) {
      state.contracts = contractData;
    },
    ADD_CONTRACT(state, contractObject) {
      state.contracts.push(contractObject);
    },
    UPDATE_CONTRACT(state, contractObject) {
      state.contracts = state.contracts.filter(function (obj) {
        return obj.id !== contractObject.id;
      });
      state.contracts.push(contractObject);
    },
    DELETE_CONTRACT(state, contractObject) {
      state.contracts = state.contracts.filter(function (obj) {
        return obj.id !== contractObject.id;
      });
    },
    RESET_CONTRACTS(state) {
      state.contracts = [];
      state.filterObject = {};
    },
    SET_FILTER(state, filterObject) {
      state.filterObject = filterObject;
    },
  },
  actions: {
    async getContracts({ commit }) {
      const data = await client.controller.contracts.read();
      if (data) {
        commit("SET_CONTRACTS", data.contracts);
      }
    },
    async createContract({ commit }, contract, skipGet = false) {
      const data = await client.controller.contracts.create(null, contract);
      if (data) {
        // console.log(data);
        commit("ADD_CONTRACT", data);
        // update contracts list and crops list to ensure we have the latest elsewhere (like order allocations)
        if (!skipGet) {
          this.dispatch("getContracts");
        }
        return data;
      }
    },
    async updateContract({ commit }, contract) {
      const data = await client.controller.contracts.update(
        contract.id,
        contract,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_CONTRACT", data);
        // update contracts list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getContracts");
      }
    },
    async deleteContract({ commit }, contract) {
      const data = await client.controller.contracts.delete(contract.id);
      if (data) {
        // console.log(data);
        commit("DELETE_CONTRACT", contract);
        this.dispatch("getContracts");
      }
    },
    async uploadContractAttachment({ commit, getters }, attachment) {
      const data = await client.controller.contracts.createAttachment(
        attachment.parentId,
        attachment.file,
      );
      if (data) {
        let contract = getters.getContractById(attachment.parentId);
        contract.attachments.push(data);
        commit("UPDATE_CONTRACT", contract);
        return data;
      }
    },
    async removeContractAttachment({ commit, state }, attachmentId) {
      const data =
        await client.controller.contracts.deleteAttachment(attachmentId);
      if (data) {
        clear: for (let contract of state.contracts) {
          for (let attachment of contract.attachments) {
            if (attachment.id === attachmentId) {
              contract.attachments = contract.attachments.filter(
                (a) => a.id !== attachmentId,
              );
              commit("UPDATE_CONTRACT", contract);
              break clear;
            }
          }
          return data;
        }
      }
    },
  },
  getters: {
    getContractById: (state) => (contractId) => {
      return state.contracts.find(
        (contract) => contract.id === parseInt(contractId),
      );
    },
    getContracts:
      (state) =>
      (includeDeleted = false) => {
        if (includeDeleted) {
          return state.contracts;
        }
        return state.contracts.filter((contract) => !contract.deleted);
      },
    // UNIVERSAL GETTER
    // getContracts:
    //   (state) =>
    //   ({
    //     filterFunction = null,
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let derivedContracts = universalGetter(
    //       {
    //         filterFunction: filterFunction,
    //         filter: filter,
    //         match: match,
    //         sort: sort,
    //         pageSize: pageSize,
    //         pageNum: pageNum,
    //       },
    //       state.contracts
    //     );
    //     return derivedContracts;
    //   },
    getDerivedContracts:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedContracts = state.contracts.map((item) => ({
          ...item,
        }));
        let filteredContracts = _.isEmpty(filter)
          ? expandedContracts
          : _.filter(expandedContracts, filter);
        let matchedContracts = _.isEmpty(match)
          ? filteredContracts
          : _.filter(filteredContracts, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedContracts = _.isEmpty(sort)
          ? matchedContracts
          : _.orderBy(matchedContracts, sortColumns, sortOrders);
        let paginatedContracts =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedContracts,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedContracts;
        return paginatedContracts;
      },
    getContractsFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};

<template>
  <div class="bg-white" v-if="equipment.length > 0">
    <main class="px-4 sm:px-6 sm:pb-32 sm:pt-6 lg:max-w-7xl lg:px-8">
      <!-- <h2 class="mt-2">Equipment to be considered for quote:</h2> -->
      <div v-if="equipment?.length > 0" class="h-45 flex overflow-auto">
        <div class="w-screen">
          <table-card>
            <thead>
              <tr>
                <th>Class</th>
                <th>Make</th>
                <th>Model</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="equipment in equipment" :key="equipment.id">
                <td td-label="Class">
                  {{ equipment.equipment_class }}
                </td>
                <td td-label="Make">
                  {{ equipment.equipment_make }}
                </td>
                <td td-label="Model">
                  {{ equipment.equipment_model }}
                </td>
              </tr>
            </tbody>
          </table-card>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
// import { ChevronLeftIcon } from "@heroicons/vue/solid";
export default {
  components: {
    TableCard,
  },
  props: {
    equipment: {
      type: Object,
    },
  },
  data() {
    return {
      modalIsActive: false,
    };
  },
  computed: {},
  methods: {},
};
</script>

<template>
  <styled-input
    :type="computedType"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    :classProp="classProp"
    :helpText="helpText"
    :helpTitle="helpTitle"
    v-model="inputValue"
  />
</template>

<script>
import StyledInput from "./StyledInput.vue";
import {
  getLocalTimeFromUTC,
  getUTCFromLocalTime,
  getShortDateString,
} from "@/components/composables/dateUtils.js";

export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [String, Date] }, // Allow both String and Date for flexibility
    placeholder: { type: String },
    classProp: { type: String },
    required: { type: Boolean, default: false },
    wideItem: { type: Boolean, default: false },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    modelValue: {}, // UTC date string for input value
    datetime: { type: Boolean, default: false }, // Toggle between date and datetime input types
  },
  emits: ["update:modelValue"],
  data() {
    let inputValue = this.modelValue;

    // Convert the UTC modelValue to local time when loading
    if (this.modelValue && !this.displayOnly) {
      inputValue = getLocalTimeFromUTC(this.modelValue, true); // Convert to local
    }

    if (this.displayOnly)
      inputValue = getShortDateString(this.displayOnlyValue);

    return {
      inputValue,
      lastEmittedValue: this.modelValue, // Track last emitted value to avoid unnecessary updates
    };
  },
  computed: {
    computedType() {
      if (this.displayOnly) return "text"; // Display only mode should always be text
      // Determine whether the input type is 'date' or 'datetime-local'
      return this.datetime ? "datetime-local" : "date";
    },
  },
  methods: {},
  watch: {
    inputValue(value) {
      // Only emit the value if it's different from the last emitted value to prevent recursion
      let outputValue = value;

      if (!this.displayOnly) {
        outputValue = getUTCFromLocalTime(value, true); // Convert back to UTC string
      }

      if (outputValue !== this.lastEmittedValue) {
        this.lastEmittedValue = outputValue;
        this.$emit("update:modelValue", outputValue); // Emit the UTC value
      }
    },
    modelValue(value) {
      // Avoid re-assigning if the modelValue hasn't changed
      if (value !== this.lastEmittedValue) {
        let inputValue = value;

        // Convert the UTC value to local time for display
        if (value) {
          inputValue = getLocalTimeFromUTC(value, true); // Convert to local
        }

        this.inputValue = inputValue;
        this.lastEmittedValue = value; // Track the new value
      }
    },
    displayOnly() {
      if (this.displayOnly)
        this.inputValue = getShortDateString(this.displayOnlyValue);
    },
    displayOnlyValue(newValue) {
      if (this.displayOnly) this.inputValue = getShortDateString(newValue);
    },
  },
};
</script>

<template>
  <div
    class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
  >
    <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
  </div>
  <input
    v-model="inputValue"
    type="text"
    name="textSearch"
    id="textSearch"
    class="block w-7/12 rounded-md border-gray-300 pl-10 text-sm focus:border-twilight-500 focus:ring-twilight-500 lg:w-5/12"
  />
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";
export default {
  components: { SearchIcon },
  props: { modelValue: {} },
  emits: ["update:modelValue"],
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
  },
};
</script>

<style></style>

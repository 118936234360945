<template>
  <!-- Old class definition --- class="flex-shrink-0 w-5 h-5 text-gray-400" -->
  <component
    v-if="isLoaded"
    :is="heroIcons[name]"
    class=""
    :class="classProp"
    aria-hidden="true"
  />
</template>

<script>
import * as heroIcons from "@heroicons/vue/outline";
export default {
  data() {
    return {
      isLoaded: false,
      heroIcons: heroIcons,
    };
  },
  props: {
    // Use the all caps name. Ex: AcademicCapIcon
    name: String,
    classProp: String,
  },
  mounted() {
    this.isLoaded = true;
  },
};
</script>

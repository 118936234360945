<template>
  <div class="flex min-h-screen flex-row">
    <!-- Side menu -->
    <div class="flex w-64 overflow-y-auto border-r border-gray-200 bg-white">
      <div class="mt-5 flex flex-grow flex-col">
        <nav class="flex-1 space-y-1 bg-white px-2" aria-label="Sidebar">
          <span class="text-sm font-medium text-green-600"> Help Topics </span>
          <template v-for="item in navMenu" :key="item.name">
            <div v-if="!item.children">
              <button
                @click="setPage(item.docId)"
                :class="[
                  item.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex w-full items-center rounded-md py-2 pl-7 pr-2 text-sm font-medium',
                ]"
              >
                {{ item.name }}
              </button>
            </div>
            <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
              <DisclosureButton
                :class="[
                  item.current
                    ? 'bg-gray-100 text-gray-900'
                    : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex w-full items-center rounded-md py-2 pr-2 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500',
                ]"
              >
                <svg
                  :class="[
                    open ? 'rotate-90 text-gray-400' : 'text-gray-300',
                    'mr-2 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400',
                  ]"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                </svg>
                {{ item.name }}
              </DisclosureButton>
              <DisclosurePanel class="space-y-1">
                <DisclosureButton
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  as="a"
                  @click="setPage(subItem.docId)"
                  class="group flex w-full items-center rounded-md py-2 pl-10 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                >
                  {{ subItem.name }}
                </DisclosureButton>
              </DisclosurePanel>
            </Disclosure>
          </template>
          <div>
            <button
              @click="showContactForm = true"
              class="mt-8 text-sm font-medium text-green-600 hover:bg-gray-100 hover:text-green-800"
            >
              Contact Us
            </button>
          </div>
        </nav>
      </div>
    </div>
    <!-- Contact Us Form -->
    <Dialog
      :open="showContactForm"
      @close="showContactForm = false"
      class="absolute z-50 -mt-8 border"
    >
      <!-- The backdrop, rendered as a fixed sibling to the panel container -->
      <div class="fixed inset-0 bg-black/30" aria-hidden="true" />

      <!-- Full-screen container to center the panel -->
      <div class="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel>
          <contact-form
            blurb="Have a problem, or a suggestion for the product? Please let us know!"
            routeName="_SELF"
            :first="firstName"
            :last="lastName"
            :company_name="org.name"
            :email_address="profile.email"
            @close="showContactForm = false"
          />
        </DialogPanel>
      </div>
    </Dialog>
    <!-- Main page content -->

    <div
      class="flex w-full overflow-y-auto border-r border-gray-200 bg-white pl-2 font-medium text-gray-600"
    >
      <span v-html="currentPageContent" class="prose"></span>
    </div>
  </div>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import ContactForm from "@/layouts/static/ContactForm.vue";
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Dialog,
    DialogPanel,
    ContactForm,
  },
  data() {
    const helpContent = [
      {
        label: "Getting Started",
        docId: "1",
        category: "Overview",
        content: ` <h1 class="text-lg">Welcome, we are glad you are here! </h1>
            <div class="max-w-2xl mt-4">
                We hope using Parcel is an intuitive experience, and we want to ensure you learn how to take advantage of all the great features
                that have been added to help you manage your operations.

                To get started, make sure you have completed your company listing. This will appear in the <a href="https://discover.parcel.ag" target="_blank">Discover</a> section of the website, and will help you get found by potential customers.
            </div>
            `,
      },
      {
        label: "Company Listing",
        docId: "2",
        category: "Online Marketing",
        content: `  <div class="max-w-2xl mt-4">To create a company listing, you will want to fill out all the relevant fields. If you leave fields blank, they will not appear on your listing. </div>
            </div>
            `,
      },
      {
        label: "Territories",
        docId: "5",
        category: "Operations",
        content: `  <div class="max-w-2xl mt-4">Territories are used to constrain which users your listings are shown to. 
        For example, if you have a listing for a specific state, you can create a territory for that state, and only users in that 
        state will see your listing. </div>
            </div>
            `,
      },
    ];
    let profile = this.$store.state.profile.profile;
    let firstName = profile?.name?.split(" ")?.slice(0, -1)?.join(" ");
    let lastName = profile?.name?.split(" ")?.slice(-1)?.join(" ");
    return {
      helpContent: helpContent,
      // navigation: navigation,
      docId: "1",
      showContactForm: false,
      profile: profile,
      org: this.$store.getters.getCurrentOrg,
      firstName: firstName,
      lastName: lastName,
    };
  },
  methods: {
    setPage(page) {
      // console.log("set page");
      this.docId = page;
    },
  },
  computed: {
    currentPageContent() {
      let helpDoc = this.helpContent.find((page) => {
        return page.docId == this.docId;
      });
      let content = helpDoc?.content;
      //console.log("doc found", this.helpContent, helpDoc, content, this.docId);
      return content;
    },
    navMenu() {
      let menu = [];
      for (let helpDoc of this.helpContent) {
        let menuItem = menu.find((item) => helpDoc.category == item.name);
        if (!menuItem) {
          menu.push({
            name: helpDoc.category,
            current: false,
            children: [],
          });
          // console.log("inserting category", helpDoc.category);
        }
        // console.log("inserting menu item", helpDoc, menuItem);
        menuItem = menu.find((item) => helpDoc.category == item.name);
        menuItem.children.push({ name: helpDoc.label, docId: helpDoc.docId });
      }
      return menu;
    },
  },
};
</script>

<!-- Example navigation for menu
/*
    const navigation = [
      {
        name: "Assets",
        current: false,
        children: [
          { name: "Fields", docId: "fields" },
          { name: "Eqiupment", docId: "equipment" },
          { name: "Map", docId: "map" },
        ],
      },
    */
-->

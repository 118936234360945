<template>
  <delete-modal
    :open="modalIsActive"
    :modalTitle="cancelModalTitle"
    :modalDescription="cancelModalDescription"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="cancelQuote"
    deleteButtonText="Cancel"
    closeButtonText="Back"
  />
  <page-card headingTitle="Review Quote">
    <template #buttonArea>
      <base-button
        v-if="
          ![
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'seller_completed',
          ].includes(currentQuote.status) &&
          quoteFormatedEndDate.getDate() <= new Date().getDate()
        "
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
      <!-- TBD: if we want to allow a vendor to save work in progress
        need to update workflow to have a new state that is not visible to grower
        
        <base-button
        v-if="
          ![
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'buyer_confirmed',
            'seller_completed',
          ].includes(currentQuote.status)
        "
        buttonText="Save Draft"
        :marginLeft="true"
        @buttonClick="updateQuote"
      /> -->
      <base-button
        v-if="
          ![
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'buyer_confirmed',
            'seller_completed',
          ].includes(currentQuote.status)
        "
        buttonText="Submit Quote"
        :marginLeft="true"
        @buttonClick="respondQuote"
      />
      <base-button
        v-if="
          currentQuote.status === 'buyer_confirmed' &&
          quoteFormatedEndDate.getDate() <= new Date().getDate()
        "
        buttonText="Complete"
        :marginLeft="true"
        @buttonClick="completeQuote"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="currentQuote.buyer_details.name"
        :wideItem="true"
        inputTitle="Buyer"
      />
      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          Product
          <router-link
            :to="{
              name: 'vendor-single-product',
              params: {
                productId: currentQuote.service,
                productCategory: currentQuote.service_category,
              },
            }"
            class="text-twilight-600 hover:text-twilight-900"
            >(View Product)</router-link
          >
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentQuote.service_title"
          :wideItem="true"
        />
      </div>

      <date-input
        v-model="currentQuote.start_date"
        :display-only="
          [
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'buyer_confirmed',
            'seller_completed',
          ].includes(currentQuote.status)
        "
        :display-only-value="currentQuote.start_date"
        :wideItem="true"
        inputTitle="Agreement Start Date"
      />
      <date-input
        v-model="currentQuote.end_date"
        :display-only="
          [
            'buyer_closed',
            'seller_closed',
            'seller_canceled',
            'buyer_confirmed',
            'seller_completed',
          ].includes(currentQuote.status)
        "
        :display-only-value="currentQuote.end_date"
        :wideItem="true"
        inputTitle="Agreement End Date"
      />
      <!-- <currency-input
        :wideItem="true"
        inputTitle="Total Price"
        :disabled="currentQuote.status === 'buyer_confirmed' ? true : false"
        v-model="currentQuote.total_price"
      /> -->
      <!-- <variable-string-type-input
        :wideItem="true"
        inputTitle="Tracking Id"
        :displayOnly="currentQuote.status === 'buyer_confirmed' ? false : true"
        v-model="currentQuote.tracking_id"
      /> -->
      <text-area
        :wideItem="true"
        inputTitle="Memo"
        v-model="currentQuote.tracking_memo"
      />
      <div v-if="quoteClosed" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Quote Closed', status: 'complete' }]"
        />
      </div>
      <div v-else-if="quoteCanceled" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Quote Canceled', status: 'complete' }]"
        />
      </div>
      <div v-else-if="quoteCompleted" class="sm:col-span-6">
        <horizontal-panel-steps
          :steps="[{ name: 'Quote Completed', status: 'complete' }]"
        />
      </div>
      <div v-else class="sm:col-span-6">
        <horizontal-panel-steps :steps="quoteSteps" />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Request Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
                <span
                  v-if="currentQuote.attachments"
                  :class="[
                    selected
                      ? 'bg-indigo-100 text-indigo-600'
                      : 'bg-gray-200 text-gray-900',
                    'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                  ]"
                  >{{
                    currentQuote.attachments != null
                      ? currentQuote.attachments.length
                      : ""
                  }}</span
                >
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentQuote.shared_assets?.fields?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Fields
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentQuote.shared_assets?.equipment?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Equipment
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <!-- <number-input
              :display-only="true"
              :displayOnlyValue="currentQuote.quantity"
              :step="1"
              :wideItem="true"
              inputTitle="Quantity"
            />
            <text-area
              :displayOnly="true"
              :displayOnlyValue="currentQuote.delivery_address"
              :wideItem="true"
              inputTitle="Delivery Address"
              inputHtmlType="text"
            /> -->
            <!-- <div class="mt-2 sm:col-span-3">
              <component
                :is="productQuoteCategory"
                :initialOrderDetails="currentQuote?.details"
                :readOnly="true"
              />
            </div>
            <h2
              class="mt-2"
              v-if="Object.keys(currentQuote.form_response)?.length > 0"
            >
              Form Questions
            </h2>
            <text-area
              v-for="(value, key) in currentQuote.form_response"
              :key="key"
              :wideItem="true"
              :inputTitle="key"
              :placeholder="value"
              :display-only="true"
            />
            <h2
              class="mt-2"
              v-if="Object.keys(currentQuote.variants)?.length > 0"
            >
              Variants
            </h2>
            <variable-string-type-input
              v-for="(value, key) in currentQuote.variants"
              :key="key"
              :inputTitle="key"
              :wideItem="true"
              :placeholder="value"
              :display-only="true"
            />
            <h2
              class="mt-2"
              v-if="Object.keys(currentQuote.options)?.length > 0"
            >
              Options
            </h2>
            <checkbox-input
              v-for="(value, key) in currentQuote.options"
              :key="key"
              :inputTitle="key"
              :modelValue="value"
              :wideItem="true"
              :display-only="true"
            /> -->
            <complete-quote-form :vendorPerspective="true" />
          </TabPanel>
          <TabPanel class="text-sm text-gray-500">
            <div class="mt-2"></div>
            <table-toolbar>
              <template #toolbarComponents>
                <div></div>
                <FileSelect
                  buttonText="Add Attachment"
                  @input="addFileToPendingAttachments"
                  accept="*"
                />
              </template>
            </table-toolbar>
            <base-table>
              <template #headerCells>
                <table-header-cell
                  :isLeadingCell="true"
                  headerText="Attachment"
                />
                <!-- <table-header-cell
                :isEmptyHeader="true"
                headerText="Delete Attachments"
              /> -->

                <table-header-cell
                  :isEmptyHeader="true"
                  headerText="View Attachments"
                />
              </template>
              <template #bodyRows>
                <tr
                  v-for="attachment in currentQuote.attachments"
                  :key="attachment.attachment_url"
                >
                  <table-body-cell
                    :cellData="attachment.attachment_name"
                    :isLeadingCell="true"
                  />
                  <!-- <table-body-cell
                  :isButtonCell="true"
                  buttonDisplayText="Delete"
                  @buttonCellClick="
                    addFileToPendingAttachmentRemovals(attachment)
                  "
                /> -->
                  <table-body-cell
                    :isButtonCell="true"
                    buttonDisplayText="View"
                    @buttonCellClick="openFileTab(attachment.attachment_url)"
                  />
                </tr>
              </template>
            </base-table>
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentQuote.shared_assets?.fields?.length > 0"
          >
            <shared-fields
              :fields="currentQuote.shared_assets?.fields"
              :locations="currentQuote?.shared_assets?.locations"
            />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentQuote.shared_assets?.equipment?.length > 0"
          >
            <shared-equipment
              :equipment="currentQuote.shared_assets?.equipment"
            />
          </TabPanel>
          <TabPanel>
            <ChatSingleView :thread-id="existingThread" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
// import DateTimeInput from "@/components/form/DateTimeInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import FileSelect from "@/components/form/FileSelect.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import { useToast } from "vue-toastification";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";

import ChatSingleView from "@/layouts/common/ChatSingleView.vue";

import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";
import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";

// import DefaultQuoteDetails from "@/layouts/common/quotes/quote-details/DefaultQuoteDetails.vue";
// import HaulingQuoteDetails from "@/layouts/common/quotes/quote-details/HaulingQuoteDetails.vue";

import CompleteQuoteForm from "@/layouts/common/quotes/CompleteQuoteForm.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    // DateTimeInput,
    TextArea,
    // CurrencyInput,
    // NumberInput,
    DateInput,
    FileSelect,
    // CheckboxInput,
    HorizontalPanelSteps,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
    TableToolbar,
    SharedEquipment,
    SharedFields,
    ChatSingleView,
    // DefaultQuoteDetails,
    // HaulingQuoteDetails,
    CompleteQuoteForm,
  },
  data() {
    const toast = useToast();
    return {
      modalIsActive: false,
      currentQuote: null,
      pendingVendorQuoteAttachments: [],
      toast,
    };
  },
  computed: {
    quoteId() {
      return this.$route.params.quoteId;
    },
    quoteClosed() {
      if (
        this.currentQuote.status === "seller_closed" ||
        this.currentQuote.status === "buyer_closed"
      ) {
        return true;
      } else {
        return false;
      }
    },
    quoteCanceled() {
      if (this.currentQuote.status === "seller_canceled") {
        return true;
      } else {
        return false;
      }
    },
    quoteCompleted() {
      if (this.currentQuote.status === "seller_completed") {
        return true;
      } else {
        return false;
      }
    },
    quoteSteps() {
      let stepTwoStatuses = [
        "seller_responded",
        "buyer_confirmed",
        "seller_completed",
      ];
      let steps = [
        { name: "Quote Requested", status: "complete" },
        {
          name: "Price Sent",
          status: stepTwoStatuses.includes(this.currentQuote.status)
            ? "complete"
            : "upcoming",
        },
        {
          name: "Quote Accepted",
          status:
            this.currentQuote.status === "buyer_confirmed"
              ? "complete"
              : "upcoming",
        },
      ];
      return steps;
    },
    cancelModalTitle() {
      if (this.currentQuote.status === "buyer_confirmed") {
        return "Cancel Quote";
      } else {
        return "Close Quote";
      }
    },
    cancelModalDescription() {
      if (this.currentQuote.status === "buyer_confirmed") {
        return "Are you sure you want to cancel this confirmed quote?";
      } else {
        return "Are you sure you want to close this quote inquiry?";
      }
    },
    existingThread() {
      return this.$store.getters.getThreadByPartner(
        this.currentQuote?.buyer_details?.id,
      )?.id;
    },
    quoteFormatedEndDate() {
      let end_date = new Date(this.currentQuote.end_date);
      return end_date;
    },
    productQuoteCategory() {
      let service = this.$store.getters.getVendorProductById(
        this.currentQuote?.service,
      );
      switch (service.category) {
        case "Hauling":
          return "HaulingQuoteDetails";
        // case "Input":
        //   return "InputQuoteDetails";
        default:
          return "DefaultQuoteDetails";
      }
    },
  },
  methods: {
    async updateQuote() {
      await this.uploadVendorQuoteAttachments();
      let agreement_start_date = new Date(this.currentQuote.start_date);
      let agreement_end_date = new Date(this.currentQuote.end_date);
      // console.log("start date", agreement_start_date);
      // console.log("end date", agreement_end_date);
      this.currentQuote.start_date = agreement_start_date
        .toISOString()
        .split("T")[0];
      this.currentQuote.end_date = agreement_end_date
        .toISOString()
        .split("T")[0];
      // console.log("start date for sub", this.currentQuote.start_date);
      // console.log("end date for sub", this.currentQuote.end_date);
      // console.log({ ...this.currentQuote, status: "seller_responded" });
      this.$store.dispatch("updateVendorQuote", {
        ...this.currentQuote,
        status: this.currentQuote.status,
      });
      this.pendingVendorQuoteAttachments = [];
      this.$router.push({ name: "vendor-quotes-all" });
    },
    async respondQuote() {
      await this.uploadVendorQuoteAttachments();
      let agreement_start_date = new Date(this.currentQuote.start_date);
      let agreement_end_date = new Date(this.currentQuote.end_date);
      // console.log("start date", agreement_start_date);
      // console.log("end date", agreement_end_date);
      this.currentQuote.start_date = agreement_start_date
        .toISOString()
        .split("T")[0];
      this.currentQuote.end_date = agreement_end_date
        .toISOString()
        .split("T")[0];
      // console.log("start date for sub", this.currentQuote.start_date);
      // console.log("end date for sub", this.currentQuote.end_date);
      // console.log({ ...this.currentQuote, status: "seller_responded" });
      await this.$store.dispatch("updateVendorQuote", {
        ...this.currentQuote,
        status: "seller_responded",
      });
      this.pendingVendorQuoteAttachments = [];
      if (!this.$store.getters.getApiError) {
        this.toast.success("Quote Response Sent", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-quotes-all" });
    },
    async cancelQuote() {
      this.currentQuote.total_price = 0;
      let conclusion =
        this.currentQuote.status === "buyer_confirmed"
          ? "seller_canceled"
          : "seller_closed";
      await this.uploadVendorQuoteAttachments();
      await this.$store.dispatch("updateVendorQuote", {
        ...this.currentQuote,
        status: conclusion,
      });
      this.pendingVendorQuoteAttachments = [];
      if (!this.$store.getters.getApiError) {
        this.toast.success("Quote Concluded", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-quotes-all" });
    },
    async completeQuote() {
      await this.uploadVendorQuoteAttachments();
      await this.$store.dispatch("updateVendorQuote", {
        ...this.currentQuote,
        status: "seller_completed",
      });
      this.pendingVendorQuoteAttachments = [];
      if (!this.$store.getters.getApiError) {
        this.toast.success("Quote Completed", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }
      this.$router.push({ name: "vendor-quotes-all" });
    },

    addFileToPendingAttachments(file) {
      this.pendingVendorQuoteAttachments.push(file);
    },
    async uploadVendorQuoteAttachments() {
      for (let file of this.pendingVendorQuoteAttachments) {
        await this.$store.dispatch("uploadVendorQuoteAttachment", {
          quoteId: this.quoteId,
          file,
        });
      }
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
  },
  watch: {
    quoteId: {
      // handler(value) or handler(newValue, oldValue)
      async handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (this.quoteId === undefined) return;
        const quote = this.$store.getters.getVendorQuoteById(value);
        // console.log("quote", quote);
        this.currentQuote = quote;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

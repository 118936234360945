<template id="timeline-control-template">
  <a href="#" @click="handleClick">
    <OutlineHeroIcon
      :name="control.icon"
      classProp="w-4 h-4  "
      aria-hidden="true"
    />
  </a>
</template>
<script>
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
export default {
  props: ["control"],
  events: ["timeline-delete", "timeline-edit"],
  components: {
    OutlineHeroIcon,
  },
  methods: {
    handleClick: function () {
      if (this.control.method == "delete") {
        this.$emit("timeline-delete");
      } else if (this.control.method == "edit") {
        this.$emit("timeline-edit");
      }
      // else {
      // console.log("Unknown method " + this.control.method);
      // }
    },
  },
};
</script>

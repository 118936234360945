<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'name', label: 'Vendor' },
          { name: '', label: 'Ordered', filtered: false, sorted: false },
          { name: '', label: 'Crops', filtered: false },
          { name: '', label: 'Varieties', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="vendors"
        @update:derived-collection="derivedVendors = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Invite"
              :usePlusIcon="true"
              @buttonClick="navigateNewProduceVendor"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="vendor in derivedVendors" :key="vendor.id">
        <table-data td-label="Vendor">
          {{ vendor.name }}
        </table-data>
        <table-data td-label="Ordered">
          {{ orderQuantities(vendor.id) }}
        </table-data>
        <table-data td-label="Crops">
          {{ matchOrderAggregateToVendor(vendor.id)?.crop_types }}
        </table-data>
        <table-data td-label="Varieties">
          {{ matchOrderAggregateToVendor(vendor.id)?.varieties }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-vendor-single',
              params: { vendorId: vendor.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedVendors: [],
    };
  },
  computed: {
    // allocations() {
    //   return this.$store.state.buyerAllocations.buyerAllocations;
    // },
    orders() {
      return this.$store.state.buyerOrders.buyerOrders;
    },
    vendors() {
      return this.$store.getters.getDerivedVendors({
        filter: { seller_confirmed: true },
      });
    },
    vendorAggreagteInfo() {
      return this.$store.getters.getBuyerInProgressOrderSellers;
    },
  },
  methods: {
    orderQuantities(orgId) {
      // console.log("order quantities for ", orgId, this.orders);
      let orders = this.orders.filter(
        (order) =>
          order.seller?.org === orgId && order.status === "In Progress",
      );
      let units = {};
      orders.forEach((order) => {
        units[order.units] = 0;
      });
      orders.forEach((order) => {
        units[order.units] += order.quantity;
        // console.log(
        //   "review order",
        //   orgId,
        //   order,
        //   order.units,
        //   order.quantity,
        //   units,
        //   units[order.units]
        // );
      });
      let quantities = "";
      for (var unit in units) {
        // console.log("review unit", orgId, unit, units[unit]);
        if (quantities.length > 0) {
          quantities += ", ";
        }
        quantities += unit + ": " + units[unit];
      }
      return quantities;
    },
    matchOrderAggregateToVendor(vendorId) {
      for (const [key, value] of Object.entries(this.vendorAggreagteInfo)) {
        // console.log("key", key, "value", value);
        if (key === vendorId.toString()) {
          return value;
        }
      }
    },
    navigateNewProduceVendor() {
      this.$router.push({ name: "buyer-vendor-new" });
    },
  },
};
</script>

<style></style>

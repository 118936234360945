<template>
  <router-view></router-view>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "App",
  setup() {
    const store = useStore();
    function start() {
      store
        .dispatch("getLoginStatus")
        .then((user) => (user ? store.dispatch("preloadAppData") : null));
    }
    // if (process.env.VUE_APP_STORE_DEBUG_MODE === "1") {
    //   start();
    // } else {
    //   store.restored.then(start);
    // }
    start();
  },
};
</script>

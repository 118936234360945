export default {
  state() {
    return {
      referrer: null,
    };
  },
  mutations: {
    SET_REFERRER(state, referrer) {
      state.referrer = referrer;
    },
    RESET_REFERRER(state) {
      state.referrer = null;
    },
  },
  actions: {
    setReferrer({ commit }, referrer) {
      commit("SET_REFERRER", referrer);
    },
    unsetReferrer({ commit }) {
      commit("RESET_REFERRER");
    },
  },
};

<template>
  <td
    v-if="isLeadingCell"
    scope="col"
    class="max-w-[18rem] truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:max-w-sm sm:pl-6"
  >
    {{ cellData }}
  </td>
  <td
    v-else-if="isLinkCell"
    scope="col"
    class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
  >
    <router-link
      :to="routerLinkObject"
      class="text-violet-500 hover:text-twilight-900"
      >{{ linkDisplayText }}
      <span class="sr-only">, {{ srOnlylinkDescription }}</span>
    </router-link>
  </td>
  <td
    v-else-if="isButtonCell"
    scope="col"
    class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
  >
    <button
      @click="clickButtonCell"
      class="text-twilight-600 hover:text-twilight-900"
    >
      {{ buttonDisplayText }}
      <span class="sr-only">, {{ srOnlylinkDescription }}</span>
    </button>
  </td>
  <td
    v-else
    scope="col"
    class="max-w-sm truncate whitespace-nowrap px-3 py-4 text-sm text-gray-500"
    :class="conditionalBodyCellStyles"
  >
    {{ cellData }}
  </td>
</template>

<script>
export default {
  props: {
    cellData: {},
    hiddenOnSmallScreens: { type: Boolean, default: false },
    hiddenOnMediumAndSmallScreens: { type: Boolean, default: false },
    isLeadingCell: { type: Boolean, default: false }, // key column, so always shows
    isLinkCell: { type: Boolean, default: false }, // only for link column, so always shows
    linkDisplayText: { type: String },
    isButtonCell: { type: Boolean, default: false }, // only for link column, so always shows
    buttonDisplayText: { type: String },
    srOnlylinkDescription: { type: String, default: "Object" },
    routerLinkObject: { default: null },
  },
  emits: ["buttonCellClick"],
  computed: {
    conditionalBodyCellStyles() {
      let styles = {
        "sm:table-cell": this.hiddenOnSmallScreens,
        "lg:table-cell": this.hiddenOnMediumAndSmallScreens,
        hidden: this.hiddenOnSmallScreens || this.hiddenOnMediumAndSmallScreens, // hidden should occur if either hidden clause given
      };
      return styles;
    },
  },
  methods: {
    clickButtonCell() {
      this.$emit("buttonCellClick");
    },
  },
};
</script>

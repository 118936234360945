<template>
  <styled-input
    :type="type"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    :classProp="classProp"
    :helpText="helpText"
    :helpTitle="helpTitle"
    v-model="inputValue"
    :step="step"
  />
  <!--
  <div>
    <label for="datetime" class="block text-sm font-medium text-gray-700">
        {{inputTitle}}
    </label>
    <div v-if="displayOnly == false" class="mt-1">
        <input
        id="datetime"
        name="datetime"
        type="datetime-local"
        :required="required"
        v-model="inputValue"
        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-twilight-500 focus:border-twilight-500 sm:text-sm"
        />
    </div>
    <div v-else class="flex mt-1 bg-gray-200 rounded-md shadow-sm">
      <span
        class="flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none rounded-r-md sm:text-sm"
      >
        {{ displayOnlyValue }}
      </span>
    </div>
  </div>
  -->
</template>

<script>
import StyledInput from "./StyledInput.vue";
export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: String },
    placeholder: { type: String },
    classProp: { type: String },
    required: { type: Boolean, default: false },
    wideItem: { type: Boolean, default: false },
    modelValue: {},
    step: { type: Number, default: 60 },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue"],
  data() {
    // This section is just to be backward compatible when displayOnlyValue was how the value was passed in for read only.
    let inputValue = this.modelValue;
    if (this.displayOnly) inputValue = this.displayOnlyValue;
    //
    return {
      inputValue,
    };
  },
  computed: {
    type() {
      return this.displayOnly && this.inputValue == null
        ? "text"
        : "datetime-local";
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
  },
};
</script>

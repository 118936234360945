<template>
  <div class="relative flex items-center py-5">
    <div class="flex-grow border-t border-gray-400"></div>
    <span class="mx-4 flex-shrink text-gray-400">Start of Table</span>
    <div class="flex-grow border-t border-gray-400"></div>
  </div>
  <div>
    <table-card>
      <template #header>
        <table-head
          :columns="[
            { name: 'name', label: 'Name', multipick: true },
            { name: 'age', label: 'Age', filtered: false },
            { name: 'job', label: 'Job', multipick: false },
          ]"
          :collection="people"
          @update:derived-collection="derivedPeople = $event"
          :defaultFilter="defaultFilter"
          :defaultSort="defaultSort"
          selectionMode="Multiple"
          @update:selectedCollection="selectedPeople = $event"
        >
          <template #toolbarButtons>
            <div class="hidden sm:flex">
              <table-toolbar-button
                buttonText="Import"
                :usePlusIcon="true"
                @buttonClick="showAlert('Import')"
              />
            </div>
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="showAlert('New')"
            />
          </template>
        </table-head>
      </template>
      <tbody>
        <tr v-for="person in derivedPeople" :key="person">
          <table-data td-label="Selected">
            <input
              type="checkbox"
              v-model="person.selected"
              class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
              @change="
                selectedPeople = derivedPeople.filter(
                  (person) => person.selected,
                )
              "
            />
          </table-data>
          <td td-label="Name">
            {{ person.name }}
          </td>
          <td td-label="age">
            {{ person.age }}
          </td>
          <td td-label="job">
            {{ person.job }}
          </td>
        </tr>
      </tbody>
    </table-card>
  </div>

  <div class="relative flex items-center py-5">
    <div class="flex-grow border-t border-gray-400"></div>
    <span class="mx-4 flex-shrink text-gray-400">End of Table</span>
    <div class="flex-grow border-t border-gray-400"></div>
  </div>
</template>
<script>
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableHead,
    TableCard,
    TableToolbarButton,
    TableData,
  },
  data() {
    return {
      defaultFilter: { name: ["Bob", "John"], age: [18, 20, 22] },
      defaultSort: { name: "asc" },
      people: [
        { name: "Bob", age: 22, job: "Developer" },
        { name: "Alice", age: 20, job: "CEO" },
        { name: "John", age: 18, job: "Manager" },
        { name: "Jane", age: 24, job: "Developer" },
      ],
      derivedPeople: [],
      selectedPeople: [],
    };
  },
  methods: {
    showAlert(message) {
      alert(message);
    },
  },
};
</script>

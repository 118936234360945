<!-- Usage
    inputTitle: { type: String, default: "" }. If empty, no group label shown
    selectOptions: Array of options objects. Each option is {label:"First Label", value:"choice1"}
    modelValue: { type: String }. Selected option value will be returned
    groupName: { type: String, default: "" }

Example:
<radio-input 
          v-model="orgType" 
          :selectOptions="[
          {label:'First Label', value:'choice1'},
          {label:'Second Label', value:'choice2'}
        ]"/>

If the user selects the Second Label radio button, choice will be set to 'choice2'
-->

<template>
  <label class="block text-sm font-medium text-gray-700">
    {{ inputTitle }}
    <span v-if="required" class="text-red-600">*</span>
    <span v-if="helpText"
      ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
    /></span>
    <div
      v-for="selectOption in selectOptions"
      :key="selectOption"
      class="form-check"
    >
      <div class="form-check">
        <input
          class="form-check-input float-left mr-2 mt-1 h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
          type="radio"
          :value="selectOption.value"
          v-model="inputValue"
          :required="required"
          :disabled="displayOnly"
          :placeholder="placeholder"
          :class="conditionalInputStyling"
          @blur="this.$emit('onBlur', inputValue)"
          :id="'radioButtonInput' + selectOption.label"
        />
        <label
          class="form-check-label inline-block text-sm text-gray-800"
          :for="'radioButtonInput' + selectOption.label"
        >
          {{ selectOption.label }}
        </label>
      </div>
    </div>
  </label>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  props: {
    inputTitle: { type: String, default: "" },
    selectOptions: { type: Array },
    modelValue: { type: [String, Number, Boolean] },
    groupName: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    classProp: { type: String },
    displayOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    wideItem: { type: Boolean, default: false },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  components: {
    ToolTip,
  },
  emits: ["update:modelValue", "onBlur"],
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  computed: {
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
  },
  methods: {
    /*
    optionClicked(e) {
        console.log("clicked", e);
      
      //this.$emit("update:modelValue", e.target.value);
    },
    */
  },
};
</script>

<template>
  <form
    @submit.prevent="submitForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div class="sm:col-span-6">
      Select the fields you want to assign this plan to:
    </div>
    <select-dropdown
      :wideItem="true"
      inputTitle="Crop Year"
      v-model="year"
      :selectOptions="crop_years"
      :noSort="false"
      :required="true"
    />
    <div class="col-span-6 h-48 overflow-auto">
      <table>
        <tr>
          <th class="pr-4">Select</th>
          <th>Farm</th>
          <th>Field</th>
          <th>Crop</th>
          <th>Tasks</th>
        </tr>
        <tr v-for="field in fields" :key="field.id">
          <td class="pl-2">
            <checkbox-input
              v-model="field.selected"
              inputTitle=""
              :inputId="field.id"
            />
          </td>
          <td class="w-96">{{ field.properties.farm }}</td>
          <td class="w-96">{{ field.properties.name }}</td>
          <td class="w-96">{{ field.properties.crop }}</td>
          <td class="w-96">
            {{
              this.$store.getters.getTasksByField(field.id)
                ? this.$store.getters.getTasksByField(field.id)?.length
                : 0
            }}
          </td>
        </tr>
      </table>
    </div>
    <div class="inline-block sm:col-span-6">
      <radio-input
        v-model="keepOrReplace"
        :selectOptions="[
          {
            label: 'Keep tasks in fields you are assigning this plan to',
            value: 'keep',
          },
          { label: 'Replace all tasks', value: 'replace' },
        ]"
      />
    </div>
    <div class="mt-8 sm:col-span-3">
      <submit-button buttonText="Copy" :formId="formId" />
      <base-button
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="cancelSave"
      />
    </div>
  </form>
</template>
<script>
import RadioInput from "@/components/form/RadioInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
//import TableCard from "@/components/table/TableCard.vue";
export default {
  components: {
    RadioInput,
    CheckboxInput,
    SelectDropdown,
    BaseButton,
    SubmitButton,
    //  TableCard,
  },
  events: ["closeModal"],
  props: {
    currentFieldId: {
      type: Number,
      required: true,
    },
  },
  data() {
    let current_year = new Date().getFullYear();
    let allFields = this.$store.state.fields.fields?.sort((a, b) => {
      if (
        a.properties.farm + a.properties.name <
        b.properties.farm + b.properties.name
      ) {
        return -1;
      }
      if (
        a.properties.farm + a.properties.name >
        b.properties.farm + b.properties.name
      ) {
        return 1;
      }
      return 0;
    });

    let crop_years = [];
    for (let i = 2022; i <= current_year; i++) {
      crop_years.push({
        label: i.toString(),
        value: i,
      });
    }
    return {
      keepOrReplace: "keep",
      year: current_year,
      crop_years: crop_years,
      field: null,
      fields: allFields,
      formId: "plan-retrieve-form",
    };
  },
  methods: {
    submitForm() {
      this.fields.forEach((field) => {
        if (field.selected) {
          // console.log("selected field", field);
          // if keepOrReplace is "replace", then we need to remove the tasks from the field
          if (this.keepOrReplace === "replace") {
            // get the tasks for this field
            let currentTasks = this.$store.getters.getTasksByField(field.id);
            // remove them
            currentTasks.forEach((task) => {
              // console.log("removing task", task);
              this.$store.dispatch("deleteTask", task);
            });
          }
          // copy in tasks from current plan to this field
          let plan = this.$store.getters.getTasksByField(this.currentFieldId);
          // console.log("saving plan", plan, this.currentFieldId);
          plan.forEach((task) => {
            let newTask = { ...task };
            newTask.source = this.$store.getters.getCurrentUser?.id;
            newTask.status = "Ready";
            newTask.field = field.id;
            // console.log("saving task", task, newTask);
            this.$store.dispatch("createTask", newTask);
          });
        }
      });
      this.$emit("closeModal");
    },

    cancelSave() {
      // console.log("cancelSave");
      this.$emit("closeModal");
    },
  },
};
</script>

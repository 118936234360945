<!-- component
this component is used to display a table as a table in desktop resolution and as a card in mobile resolution
 -->
<!-- basic use: create a table formatted as normal. Use a <table-card> tag in lieu of the <table> tag. 
     For each column, also add a td-label attribute to the data tag to define what will appear as a label for that data cell when in card format. e.g. <td td-label="Name"> 

Note: this component supports table-toolbar and table-header-cell-with-filter-button columns. 
To enable the filter to display on mobile, the parent .vue using this component must include a method to show / hide the 
table column headers and filters. To support this, add a computed class to the <thead> tag like  <thead :class="filterViewState"> 
and then include a computed variable to toggle the class.

    filterViewState() {
      /* this is to show the filters on mobile when the filter button is pressed, and always show the column headers on desktop */
      return this.$store.getters.getViewState
        ? "block" // show the filters on mobile if filter button is pressed
        : "sm:table-header-group hidden"; // hide on mobile if viewsState is false, show on desktop as a table header group
    },

 -->
<template>
  <table class="mytable mobile-optimised">
    <slot name="header"></slot>
    <slot></slot>
  </table>
</template>
<script>
// import BaseButton from "../buttons/BaseButton.vue";
export default {
  // components: { BaseButton },
};
</script>
<style lang="postcss" scoped>
/* Table Layout Formatting and defaults for mobile cards if not overridden*/
.mytable {
  @apply my-0 w-full;
}
:slotted(.mytable td) {
  @apply border-b bg-white text-sm text-gray-500;
  padding: 10px;
  vertical-align: top;
}
:slotted(.mytable th) {
  @apply border-t-2 pl-2 text-left text-sm font-semibold text-gray-900;
}
:slotted(table.mobile-optimised td.link) {
  @apply text-center font-semibold text-violet-500;
}
/* Mobile Card Formatting */
@media screen and (max-width: 640px) {
  table.mobile-optimised {
    word-wrap: break-word;
  }
  :slotted(table.mobile-optimised thead) {
    /* @apply absolute -left-full -top-full; */ /* this hides the header */
    /* not hiding the header because we need to show it for the mobile filter. Instead, show / hide in the parent component */
  }
  :slotted(.mobile-optimised td:before) {
    @apply block pl-0 pr-1 pt-0 font-semibold text-gray-900;
    content: attr(td-label);
  }
  :slotted(table.mobile-optimised td) {
    @apply clear-both block w-full border-b border-gray-300 bg-gray-200 pr-1 pt-2.5 text-sm text-gray-500;
  }

  :slotted(table.mobile-optimised tbody),
  :slotted(table.mobile-optimised tr) {
    @apply block;
  }

  :slotted(.mobile-optimised tbody tr) {
    @apply mb-2 block rounded-xl border-2 border-gray-300;
  }
}
</style>

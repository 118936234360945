<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Needs Action
            <span
              v-if="needsActionOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ needsActionOrders }}</span
            >
          </button>
        </Tab>
        <!-- <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Open
            <span
              v-if="openOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ completedOrders }}</span
            >
          </button>
        </Tab> -->
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Closed
            <span
              v-if="closedOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ closedOrders }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Completed
            <span
              v-if="completedOrders"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ completedOrders }}</span
            >
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <!-- <needs-action-vendor-orders-table /> -->
        <vendor-orders-table :tabFilters="needsActionFilter" />
      </TabPanel>
      <!-- <TabPanel as="dl" class="pt-10">
        <vendor-orders-table :tabFilters="openFilter" />
      </TabPanel> -->
      <TabPanel class="pt-10">
        <vendor-orders-table :tabFilters="closedFilter" />
        <!-- <closed-vendor-orders-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <vendor-orders-table :tabFilters="completedFilter" />
        <!-- <completed-vendor-orders-table /> -->
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";

import VendorOrdersTable from "@/layouts/vendor-portal/orders/VendorOrdersTable.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    VendorOrdersTable,
  },
  data() {
    return {
      selectedTab: 0,
      needsActionFilter: function (o) {
        if (o.status === "buyer_rejected" || o.status === "buyer_ordered") {
          return true;
        } else {
          return false;
        }
      },
      openFilter: function (o) {
        if (o.status === "buyer_confirmed" || o.status === "buyer_ordered") {
          return true;
        } else {
          return false;
        }
      },
      closedFilter: function (o) {
        if (
          o.status === "buyer_closed" ||
          o.status === "seller_closed" ||
          o.status === "seller_canceled"
        ) {
          return true;
        } else {
          return false;
        }
      },
      completedFilter: function (o) {
        if (o.status === "seller_completed") {
          return true;
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    allOrders() {
      return [
        ...this.$store.getters.getDerivedVendorOrders({}),
        ...this.$store.state.vendorInputOrders.orders,
      ];
    },
    needsActionOrders() {
      //   let numOrders = this.$store.getters.getNeedsActionVendorOrders.length;
      let numOrders = this.allOrders.filter(this.needsActionFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    openOrders() {
      //   let numOrders = this.$store.getters.getClosedVendorOrders.length;
      let numOrders = this.allOrders.filter(this.openFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    closedOrders() {
      //   let numOrders = this.$store.getters.getClosedVendorOrders.length;
      let numOrders = this.allOrders.filter(this.closedFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    completedOrders() {
      //   let numOrders = this.$store.getters.getCompletedVendorOrders.length;
      let numOrders = this.allOrders.filter(this.completedFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>

<template>
  <div
    class="form-check mt-1"
    :class="wideItem == true ? 'sm:col-span-3 ' : ''"
  >
    <label class="block text-sm font-medium text-gray-700">
      {{ inputTitle }}
    </label>
    <span v-if="helpText"
      ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
    /></span>
    <p v-if="maxSelectedItems" class="block text-sm font-medium text-gray-700">
      Max selections: {{ maxSelectedItems }}
    </p>
    <div v-if="!maxSelectedItems">
      <input
        class="form-check-input float-left mb-1 ml-2 mr-2 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
        type="checkbox"
        v-model="allSelected"
        @change="selectAll()"
      />
      Select All
    </div>
    <div
      class="overflow-auto rounded-md border border-gray-200 pb-2 pl-2"
      :class="rowHeight"
    >
      <div v-for="item in selectableList" :key="item" class="flex">
        <label class="form-check-label inline-block text-sm text-gray-800">
          <input
            class="form-check-input float-left mr-2 mt-1 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
            type="checkbox"
            v-model="item.selected"
            :disabled="displayOnly"
            :class="conditionalInputStyling"
            @blur="this.$emit('updateSelections', selectedItems)"
          />
          {{ item.label }}
        </label>
      </div>
    </div>
    <!-- </label> -->
  </div>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
import _ from "lodash";
export default {
  props: {
    inputTitle: { type: String, default: "" },
    displayOnly: { type: Boolean, default: false },
    selectOptions: { type: Array, required: true },
    selectOptionsLabel: { type: String, required: true },
    wideItem: { type: Boolean, default: false },
    classProp: { type: String, defautl: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    rows: { type: Number, default: 3 },
    maxSelectedItems: { type: Number, default: null },
  },
  components: {
    ToolTip,
  },
  emits: ["updateSelections"],
  data() {
    let disabledStyle = "";
    if (this.displayOnly) disabledStyle = "bg-gray-200";
    let list = _.sortBy(this.selectOptions, ["label"]);
    return {
      allSelected: false,
      disabledStyle,
      list: list,
      selectableList: [],
    };
  },
  computed: {
    rowHeight() {
      // return "h-[6rem]";
      let height = (this.rows + 1).toString();
      return "h-[" + height + "rem]";
    },
    conditionalInputStyling() {
      let style = "";
      if (this.classProp) style += this.classProp;
      return style;
    },
    numberOfSelectedItems() {
      return this.list.filter((item) => item.selected).length;
    },
    selectedItems() {
      // remove the selected key from every item in selections
      // create a new array of each item in selectableList that has a selected key of true
      // each item in the new array should only be the object within the value key, the selected, label, and value keys should be removed
      let selections = this.selectableList
        .filter((item) => item.selected)
        .map((item) => item.value);
      return selections;
    },
  },
  methods: {
    selectAll() {
      // this.allSelected = !this.allSelected;
      // console.log(this.allSelected);
      this.selectableList.forEach((item) => {
        item.selected = this.allSelected;
      });
      this.$emit("updateSelections", this.selectedItems);
    },
  },
  watch: {
    selectOptions: {
      handler(value) {
        // if this.selectOptionsLabel has any dots in it, then we need to split the string into a set of keys to use in a nested object
        // if there are no dots, then we can just use the string as a key
        let labelKeys = this.selectOptionsLabel.split(".");
        // console.log("labelKeys", labelKeys);
        // map through the array and add a selected key and a label key (that is taken from value using selectOptionsLabel as a key)
        let list = value.map((item) => {
          return {
            selected: false,
            label: labelKeys.reduce((obj, key) => obj[key], item),
            value: item,
          };
        });
        this.selectableList = list;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

<template>
  <external-org-settings-form
    redirectRouteName="grower-portal-settings"
    redirectTab="1"
  />
</template>

<script>
import ExternalOrgSettingsForm from "@/layouts/common/ExternalOrgSettingsForm.vue";

export default {
  components: {
    ExternalOrgSettingsForm,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

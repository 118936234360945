<template>
  <div
    v-if="
      !companyListing?.available_on ||
      !activeLobs?.length > 0 ||
      !customers?.length > 0
    "
  >
    <h1 class="text-3xl">
      Welcome to Parcel!
      <p class="pt-2 text-2xl font-bold">LET'S GET STARTED</p>
    </h1>
    <div class="grid grid-cols-1 lg:grid-cols-3">
      <router-link
        :to="{ name: 'vendor-company-listing' }"
        class="focus:outline-none"
      >
        <div
          class="flex h-64 flex-1 flex-col items-center divide-gray-200 rounded-lg bg-white p-8 text-center shadow"
        >
          <div
            class="h-12 w-12 rounded-full bg-green-600 pt-3 text-xl font-bold"
            v-if="companyListing?.available_on != null"
          >
            <div>
              <CheckIcon class="ml-2 h-8 w-8 text-white" />
            </div>
          </div>
          <div
            class="h-12 w-12 rounded-full bg-blue-300 pt-3 text-xl font-bold"
            v-else
          >
            1
          </div>
          <h2 class="pt-4 text-xl font-bold">Market your Business</h2>
          <p>add a company listing to appear in the Parcel Marketplace</p>
          <div
            v-if="!companyListing?.available_on"
            class="cursor-pointer pt-4 italic text-blue-500 hover:text-blue-700"
          >
            click here to create your company listing
          </div>
        </div>
      </router-link>
      <router-link :to="{ name: 'vendor-settings' }" class="focus:outline-none">
        <div
          class="flex h-64 flex-1 flex-col content-start items-center divide-gray-200 rounded-lg bg-white p-8 text-center shadow"
        >
          <h2 class="pt-4 text-xl font-bold">Customize Your Account</h2>
          <p>
            Change your navigation options depending on the products and
            services you're using in Parcel
          </p>
          <div
            class="cursor-pointer pt-4 italic text-blue-500 hover:text-blue-700"
          >
            Click here to update your navigation settings
          </div>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'vendor-customers' }"
        class="focus:outline-none"
      >
        <div
          class="flex h-64 flex-1 flex-col items-center divide-gray-200 rounded-lg bg-white p-8 text-center shadow"
        >
          <div
            class="h-12 w-12 rounded-full bg-green-600 pt-3 text-xl font-bold"
            v-if="customers?.length > 0"
          >
            <div>
              <CheckIcon class="ml-2 h-8 w-8 text-white" />
            </div>
          </div>
          <div
            class="h-12 w-12 rounded-full bg-blue-300 pt-3 text-xl font-bold"
            v-else
          >
            3
          </div>
          <h2 class="pt-4 text-xl font-bold">Onboard Growers</h2>
          <p>
            Invite your customers to join Parcel so you can work with them more
            easily, and get rewarded for their success.
          </p>
          <div
            v-if="
              companyListing?.available_on &&
              activeLobs?.length > 0 &&
              !customers?.length > 0
            "
            class="cursor-pointer pt-4 italic text-blue-500 hover:text-blue-700"
          >
            click here to add customers
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <div v-else>
    <stats-panel :action="action" />
  </div>
</template>

<script>
import { CheckIcon, FlagIcon } from "@heroicons/vue/solid";
import StatsPanel from "@/components/widgets/StatsPanel.vue";
export default {
  components: {
    CheckIcon,
    StatsPanel,
  },
  data() {
    return {};
  },
  computed: {
    companyListing() {
      return this.$store.state.orgs.org_profile;
    },
    activeLobs() {
      return this.$store.getters.getCurrentOrg.services;
    },
    customers() {
      return this.$store.state.customers.customers;
    },
    tasksDue() {
      let derivedTasks = this.$store.getters.getDerivedSharedTasks({});
      derivedTasks = derivedTasks.filter((task) => {
        return task.assigned_org == this.$store.getters.getCurrentOrg?.id;
      });
      return derivedTasks.filter((task) => task.status === "Requested").length;
    },
    num_reservations() {
      let reservations = this.$store.getters.getDerivedVendorOrders({});
      let agreements = this.$store.getters.getDerivedVendorQuotes({
        filter: { service_category: "Hauling" },
      });
      let haulingOrders = reservations.filter((order) => {
        return agreements.some((agreement) => {
          return agreement.id === order.agreement;
        });
      });
      let num_reservations = haulingOrders.filter(
        (reservation) => reservation.status == "buyer_submitted",
      ).length;

      return num_reservations;
      // return this.$store.state.partnerReservations.partnerReservations?.filter(
      //   (reservation) => reservation.status === "Requested"
      // )?.length;
    },
    num_requested_quotes() {
      return this.$store.state.vendorQuotes.vendorQuotes?.filter(
        (quote) =>
          quote.status === "buyer_requested" ||
          quote.status === "buyer_rejected" ||
          quote.status === "buyer_submitted",
      ).length;
    },
    action() {
      let action = {
        icon: FlagIcon,
        name: "Store Summary",
        blurb: "Things to focus on today.",
        iconForeground: "text-teal-700",
        iconBackground: "bg-teal-50",
        stats: [],
      };
      if (this.activeLobs?.includes("agronomic-advice")) {
        action.stats.push({
          title: "Work Orders",
          desc: "Work orders that have been requested that need to be assigned",
          value: this.tasksDue,
          // desc: "Varieties allocated on orders shared with me",
          id: 1,
          routeName: "vendor-activities",
        });
      }
      if (this.activeLobs?.includes("crop-hauling")) {
        action.stats.push({
          title: "Hauling",
          value: this.num_reservations,
          desc: "Hauling reservations awaiting pickup",
          id: 3,
          routeName: "vendor-hauling-reservations",
        });
      }
      if (this.activeLobs?.includes("products")) {
        action.stats.push({
          title: "New Quote Requests",
          value: this.num_requested_quotes,
          desc: "Needing a quote from you",
          id: 2,
          routeName: "vendor-quotes-all",
        });
      }
      return action;
    },
  },
  created() {},
};
</script>

<template>
  <td
    scope="col"
    class="max-w-sm truncate whitespace-nowrap px-3 py-4 text-sm text-gray-500"
  >
    <input
      type="number"
      :step="step"
      v-model="currentAllocationAcres"
      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
      @blur="updateAllocationAcres"
    />
  </td>
</template>

<script>
export default {
  props: {
    allocationId: {
      type: Number,
      required: false,
    },
    acres: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: 0.01,
    },
  },
  emits: ["updateAcres"],
  components: {},
  data() {
    return {
      currentAllocationAcres: this.acres,
    };
  },
  methods: {
    updateAllocationAcres() {
      // let allocation = this.$store.getters.getGrowerAllocationById(this.allocationId);
      // allocation.acres = this.currentAllocationAcres;
      // this.$store.dispatch("updateGrowerAllocation", allocation);
      // console.log(
      //   "updateAllocationAcres",
      //   this.allocationId,
      //   "acres: ",
      //   this.currentAllocationAcres,
      // );
      this.$emit("updateAcres", {
        id: this.allocationId,
        acres: this.currentAllocationAcres,
      });
    },
  },
  watch: {
    modelValue(value) {
      this.currentAllocationAcres = value;
    },
  },
  // watch: {
  //   allocationAcres: {
  //     handler(value) {
  //       this.currentAllocationAcres = value;
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>

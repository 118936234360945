<template>
  <styled-input
    type="text"
    :inputTitle="inputTitle"
    :placeholder="placeholder"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :classProp="classProp"
    v-model="inputValue"
    :helpText="helpText"
    :helpTitle="helpTitle"
  />
  <!--
  <div :class="wideItem == true ? 'sm:col-span-3 ' : ''">
    <label
      v-if="inputTitle && inputTitle.length > 0"
      class="block text-sm font-medium text-gray-700"
      >{{ inputTitle }}</label
    >
    <div class="mt-1" v-if="displayOnly == false">
      <input
        type="text"
        v-model="inputValue"
        :placeholder="placeholder"
        :required="required"
        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-twilight-500 focus:border-twilight-500 sm:text-sm"
      />
    </div>
    <div v-else class="flex mt-1 bg-gray-200 rounded-md shadow-sm">
      <span
        class="flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none h-9 rounded-r-md sm:text-sm"
      >
        {{ displayOnlyValue }}
      </span>
    </div>
  </div>
  -->
</template>

<script>
import StyledInput from "./StyledInput.vue";
export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String, default: "" },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [String, Number] },
    placeholder: { type: String, default: "" },
    classProp: { type: String },
    required: { type: Boolean, default: false },
    modelValue: { type: String },
    wideItem: { type: Boolean, default: false },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue"],
  data() {
    // This section is just to be backward compatible when displayOnlyValue was how the value was passed in for read only.
    let inputValue = this.modelValue;
    if (this.displayOnly) inputValue = this.displayOnlyValue;
    //
    return {
      inputValue: inputValue,
    };
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
  },
};
</script>

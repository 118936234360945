<template>
  <div class="mt-6 overflow-hidden bg-white p-4 shadow sm:rounded-md">
    <form @submit.prevent="saveCsv" id="import">
      <div class="flex">
        <file-select
          buttonText="Step 1: Please select a file to import (.csv)"
          @input="setFile"
          accept=".csv"
        />
        <template v-if="csv != null"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div class="flex">
        <base-button
          @buttonClick="uploadCsvfile"
          buttonText="Step 2: Upload the file"
          :disabled="csv == null"
          loading="loading"
        />
        <template v-if="uploaded"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div v-if="uploaded">
        <p class="mb-4 mt-4 block text-sm font-bold text-twilight-700">
          Step 3: Map the attributes
        </p>
        <map-attributes
          :attributes="assetAttributes"
          :options="options"
          v-model="mappings"
        />
        <div class="mt-4">
          <submit-button
            formId="import"
            buttonText="Step 4: Import the equipment"
            :disabled="!csv"
          />
        </div>
      </div>
    </form>
  </div>
  <modal :show="showReport" title="Import Report">
    <div v-if="importReport">
      <div v-html="importReport" class="prose"></div>
      <base-button
        @buttonClick="showReport = false"
        buttonText="Close"
        :disabled="!uploaded"
      />
    </div>
    <div v-else>Loading data...</div>
  </modal>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import FileSelect from "@/components/form/FileSelect.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import MapAttributes from "@/components/import/MapAttributes.vue";
import Modal from "@/components/modals/PopupModal.vue";
import Papa from "papaparse";
import _ from "lodash";
export default {
  components: {
    CheckCircleIcon,
    FileSelect,
    BaseButton,
    SubmitButton,
    MapAttributes,
    Modal,
  },
  props: {
    assetAttributes: { type: Object },
  },
  data() {
    return {
      csv: null,
      uploaded: false,
      assets: [],
      options: [],
      mappings: null,
      addRecords: true,
      updateRecords: true,
      importReport: "",
      showReport: false,
    };
  },
  computed: {},
  methods: {
    setFile(file) {
      this.csv = file;
    },
    async uploadCsvfile() {
      let componentHandle = this;
      let assets = [];
      let header = [];
      // console.log("uploadCsvfile");

      await Papa.parse(this.csv, {
        complete: function (results) {
          // console.log("parse done", results);
          // console.log("csvArray", results);
          // grab the first column to get column names
          for (let column of results.data[0]) {
            header.push(column);
          }
          // console.log("header", header);
          for (const [i, row] of results.data.entries()) {
            if (i > 0) {
              //skip the header
              let asset = {};
              for (let i = 0; i < header.length; i++) {
                asset[header[i]] = row[i];
              }
              assets.push(asset);
            }
          }
          // console.log("assets uploaded", assets);
          componentHandle.assets = assets;
          componentHandle.setMappingOptions();
          componentHandle.uploaded = true;
        },
      });
    },
    setMappingOptions() {
      // grab first asset in assets and use it to get the attributes
      let options = [];
      if (this.assets.length > 0) {
        //console.log("mapping asset attributes");
        for (let property in this.assets[0]) {
          //console.log("property: ", property);
          options.push({
            value: property.toLowerCase(),
            label: property.toLowerCase(),
          });
        }
      }
      //console.log("options", options);
      this.options = options;
    },
    toLowerKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
      }, {});
    },
    saveCsv() {
      //console.log("saveCsv - mapping ", this.mappings);
      this.assets = this.assets.map((asset) => {
        let newAsset = this.toLowerKeys(asset);
        // console.log("properties", asset);
        for (const [key, { value }] of Object.entries(this.mappings)) {
          if (value) {
            // console.log("mapping", key, value);
            newAsset[key] = newAsset[value];
          }
        }
        // console.log("properties post mapping", newAsset);
        return newAsset;
      });
      // console.log("saving",this.assets);
      this.saveAssets(this.assets);
    },

    async saveAssets(assetsSet) {
      this.showReport = true;
      // console.log("saving assets", assetsSet);
      if (!assetsSet) return;
      this.importReport = "";
      let myAssets = this.$store.state.equipment.equipment;
      let successfulUpdates = 0;

      for (const row of assetsSet) {
        // console.log("processing a row", row);
        // lets limit the object to only valid keys
        let newAsset = _.pick(row, Object.keys(this.assetAttributes));
        // console.log(
        //   "newAsset",
        //   newAsset,
        //   Object.keys(this.assetAttributes),
        //   row
        // );
        if (newAsset.cost)
          newAsset.cost = newAsset.cost.replace(/[^0-9.-]+/g, "");
        if (newAsset.hourly_rate)
          newAsset.hourly_rate = newAsset.hourly_rate.replace(/[^0-9.-]+/g, "");
        if (newAsset.purchase_date)
          newAsset.purchase_date = new Date(newAsset.purchase_date)
            .toISOString()
            .substring(0, 10);
        if (newAsset.purchase_date?.length < 10) newAsset.purchase_date = null;
        if (newAsset.last_service_date)
          newAsset.last_service_date = new Date(
            newAsset.last_service_date,
          ).toISOString();
        if (newAsset.last_service_date?.length < 10)
          newAsset.last_service_date = null;
        let insert = false;
        let currentAsset = null;
        // check if asset exists already - must have unique serial number or name
        if (
          myAssets.filter(
            (asset) => asset.serial_number == newAsset.serial_number,
          ).length == 1
        ) {
          currentAsset = myAssets.filter(
            (asset) => asset.serial_number == newAsset.serial_number,
          )[0];
          insert = false;
        } else if (
          myAssets.filter((asset) => asset.alias == newAsset.alias).length == 1
        ) {
          currentAsset = myAssets.filter(
            (asset) => asset.alias == newAsset.alias,
          )[0];
          insert = false;
        } else {
          currentAsset = {};
          insert = true;
        }
        // no match found
        if (insert) {
          if (this.addRecords) {
            //insert a new asset
            // console.log("inserting new asset", newAsset.alias, row);
            this.importReport += "Adding new asset " + newAsset.alias + "<br>";
            successfulUpdates++;
            await this.$store.dispatch("createEquipment", newAsset);
          } else
            this.importReport +=
              "No match found for " + newAsset.alias + "<br>";
        } else if (currentAsset && this.updateRecords) {
          newAsset = _.merge(currentAsset, newAsset);
          successfulUpdates++;
          // console.log("updating existing asset", newAsset);
          await this.$store.dispatch("updateEquipment", newAsset);
          // console.log("updated asset");
        }
      }
      // console.log("import complete");
      this.importReport += "Updated " + successfulUpdates + " assets";
      this.showReport = true;
    },
  },
};
</script>

import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      contractPremiums: [],
    };
  },
  mutations: {
    SET_CONTRACT_PREMIUMS(state, contractPremiums) {
      state.contractPremiums = contractPremiums;
    },
    ADD_CONTRACT_PREMIUM(state, contractPremium) {
      state.contractPremiums.push(contractPremium);
    },
    UPDATE_CONTRACT_PREMIUM(state, contractPremium) {
      state.contractPremiums = state.contractPremiums.filter(function (obj) {
        return obj.id !== contractPremium.id;
      });
      state.contractPremiums.push(contractPremium);
    },
    DELETE_CONTRACT_PREMIUM(state, contractPremium) {
      state.contractPremiums = state.contractPremiums.filter(function (obj) {
        return obj.id !== contractPremium.id;
      });
    },
    RESET_CONTRACT_PREMIUMS(state) {
      state.contractPremiums = [];
    },
    ADD_PREMIUM_VALIDATION_RECORD(state, { premiumId, validationRecord }) {
      let premium = state.contractPremiums.find(
        (premium) => premium.id === premiumId,
      );
      if (!premium.validationRecords) {
        premium.validationRecords = [];
      }
      premium.validationRecords.push(validationRecord);
    },
    DELETE_PREMIUM_VALIDATION_RECORD(state, { premiumId, validationRecordId }) {
      let premium = state.contractPremiums.find(
        (premium) => premium.id === premiumId,
      );
      if (!premium.validationRecords) {
        return;
      }
      premium.validationRecords = premium.validationRecords.filter(
        (record) => record.id !== validationRecordId,
      );
    },
  },
  actions: {
    async getContractPremiums({ commit }) {
      const data = await client.controller.contractPremiums.read();
      if (data) {
        commit("SET_CONTRACT_PREMIUMS", data.contract_premiums);
      }
    },
    async createContractPremium({ commit }, { contractId, contractPremium }) {
      const data = await client.controller.contractPremiums.create(
        contractId,
        contractPremium,
      );
      if (data) {
        commit("ADD_CONTRACT_PREMIUM", data);
        return data;
      }
    },
    async updateContractPremium({ commit }, contractPremium) {
      const data = await client.controller.contractPremiums.update(
        contractPremium.id,
        contractPremium,
      );
      if (data) {
        commit("UPDATE_CONTRACT_PREMIUM", data);
        return data;
      }
    },
    async deleteContractPremium({ commit }, contractPremium) {
      const data =
        await client.controller.contractPremiums.delete(contractPremium);
      if (data) {
        commit("DELETE_CONTRACT_PREMIUM", contractPremium);
        return data;
      }
    },
    async createPremiumValidationRecord(
      { commit },
      { premiumId, validationRecord },
    ) {
      const data =
        await client.controller.contractPremiums.createValidationRecord(
          premiumId,
          validationRecord,
        );
      if (data) {
        commit("ADD_PREMIUM_VALIDATION_RECORD", {
          premiumId,
          validationRecord: data,
        });
        return data;
      }
    },
    // you need the premiumId to keep the store updated
    async deletePremiumValidationRecord(
      { commit },
      { premiumId, validationRecordId },
    ) {
      console.log(
        "i am deletePremiumValidationRecord",
        premiumId,
        validationRecordId,
      );
      const data =
        await client.controller.contractPremiums.removeValidationRecord(
          validationRecordId,
        );
      if (data) {
        commit("DELETE_PREMIUM_VALIDATION_RECORD", {
          premiumId,
          validationRecordId,
        });
        return data;
      }
    },
  },
  getters: {
    getContractPremiums:
      (state) =>
      (includeDeleted = false) => {
        if (includeDeleted) {
          return state.contractPremiums;
        } else {
          return state.contractPremiums.filter((premium) => !premium.deleted);
        }
      },
    getContractPremiumsByContractId:
      (state) =>
      (contractId, includeDeleted = false) => {
        if (includeDeleted) {
          return state.contractPremiums.filter(
            (premium) => premium.contract === contractId,
          );
        } else {
          return state.contractPremiums.filter(
            (premium) => premium.contract === contractId && !premium.deleted,
          );
        }
      },
    getContractPremiumById: (state) => (id) => {
      return state.contractPremiums.find((premium) => premium.id === id);
    },
  },
};

<template>
  <table-toolbar>
    <template #toolbarComponents>
      <table-toolbar-search v-model="searchValue" />
      <!-- Need to think through how / why a vendor might want to create a task on their own -->
      <!--
        <table-toolbar-button
        buttonText="New"
        :usePlusIcon="true"
        @buttonClick="goToNewTaskPage"
      /> -->
      <div class="flex">
        <div class="hidden sm:flex">
          <table-toolbar-button
            buttonText="Export"
            :usePlusIcon="true"
            @buttonClick="exportTable"
          />
        </div>
      </div>
    </template>
    <template #activeFilters>
      <table-toolbar-active-filters
        :activeFilters="filterObject"
        @clearFilter="clearSelectedFilter"
      />
    </template>
  </table-toolbar>
  <base-table>
    <template #headerCells>
      <table-header-cell-with-filter-button
        :isLeadingCell="true"
        headerText="Category"
        :filterOptions="categoryFilterOptions"
        filterCategory="category"
        @selectFilterValue="selectFilter"
        :sorted="true"
        :sortColumns="pageSorts"
        sortColumn="category"
        @sort="sortColumn($event)"
      />
      <table-header-cell-with-filter-button
        :hiddenOnSmallScreens="true"
        headerText="Status"
        :filterOptions="statusFilterOptions"
        filterCategory="status"
        @selectFilterValue="selectFilter"
        :sorted="true"
        :sortColumns="pageSorts"
        sortColumn="status"
        @sort="sortColumn($event)"
      />
      <!--<table-header-cell :hiddenOnSmallScreens="true" headerText="Status" />-->
      <table-header-cell-with-filter-button
        :hiddenOnSmallScreens="true"
        headerText="Assignee"
        :filterOptions="assigneeFilterOptions"
        filterCategory="user.name"
        @selectFilterValue="selectFilter"
        :sorted="true"
        :sortColumns="pageSorts"
        sortColumn="user.name"
        @sort="sortColumn($event)"
      />
      <!--<table-header-cell :hiddenOnMediumAndSmallScreens="true" headerText="Assignee" />-->
      <!--<table-header-cell :hiddenOnMediumAndSmallScreens="true" headerText="Field" />-->
      <table-header-cell-with-filter-button
        :hiddenOnSmallScreens="true"
        headerText="On"
        :filterOptions="fieldOrEquipmentFilterOptions"
        filterCategory="fieldOrEquipment"
        @selectFilterValue="selectFilter"
        :sorted="true"
        :sortColumns="pageSorts"
        sortColumn="fieldOrEquipment"
        @sort="sortColumn($event)"
      />
      <table-header-cell
        :hiddenOnMediumAndSmallScreens="true"
        headerText="Assigned By"
        :sorted="true"
        :sortColumns="pageSorts"
        sortColumn="task.source.name"
        @sort="sortColumn($event)"
      />
      <table-header-cell :isEmptyHeader="true" headerText="Edit Task" />
    </template>
    <template #bodyRows>
      <tr v-for="task in derivedTasks" :key="task.id">
        <table-body-cell :isLeadingCell="true" :cellData="task.category" />
        <table-body-cell :hiddenOnSmallScreens="true" :cellData="task.status" />
        <table-body-cell
          :hiddenOnSmallScreens="true"
          :cellData="task.user?.name"
        />
        <table-body-cell
          :hiddenOnMediumAndSmallScreens="true"
          :cellData="task?.equipment?.alias"
          v-if="
            task.category == 'Maintenance' ||
            task.category == 'Repair' ||
            task.category == 'Other equipment task'
          "
        />
        <table-body-cell
          :hiddenOnMediumAndSmallScreens="true"
          :cellData="task.field?.properties?.name"
          v-else
        />
        <table-body-cell
          :hiddenOnSmallScreens="true"
          :cellData="task.customer_org?.name"
        />
        <table-body-cell
          :isLinkCell="true"
          linkDisplayText="Edit"
          :routerLinkObject="{
            name: 'vendor-single-activity',
            params: { activityId: task.id },
          }"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import TableToolbar from "@/components/table/TableToolbar.vue";
import TableToolbarSearch from "@/components/table/TableToolbarSearch.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableToolbarActiveFilters from "@/components/table/TableToolbarActiveFilters.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableHeaderCellWithFilterButton from "@/components/table/TableHeaderCellWithFilterButton.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import { exportToCSV } from "@/components/composables/exportFile";
export default {
  components: {
    TableToolbar,
    TableToolbarSearch,
    TableToolbarButton,
    TableToolbarActiveFilters,
    BaseTable,
    TableHeaderCell,
    TableHeaderCellWithFilterButton,
    TableBodyCell,
  },
  data() {
    return {
      searchValue: "",
      filterObject: {},
      pageFixedFilters: {},
      pageSorts: { deadline: "asc" },
    };
  },
  computed: {
    combinedFilters() {
      return {
        ...this.filterObject,
        ...this.pageFixedFilters,
      };
    },
    derivedTasks() {
      let derivedTasks = this.$store.getters.getDerivedSharedTasks({
        filter: this.combinedFilters, // {category: "Irrigation"} - return elements where category is exactly "Irrigation"
        match: this.searchValue, // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort: this.pageSorts,
      });
      // console.log("derivedTasks", derivedTasks);
      // TODO: uncomment when assigned_org is ready
      derivedTasks = derivedTasks.filter((task) => {
        return task.assigned_org == this.$store.getters.getCurrentOrg?.id;
      });
      return derivedTasks;
    },
    categoryFilterOptions() {
      let distinctTasks = [];
      for (let task of this.$store.state.tasks.tasks) {
        if (!distinctTasks.includes(task.category))
          distinctTasks.push(task.category);
      }
      //console.log(distinctTasks.length);
      return distinctTasks.sort(); //TODO: sort by deadline.
    },
    statusFilterOptions() {
      let distinctTasks = [];
      for (let task of this.$store.state.tasks.tasks) {
        if (!distinctTasks.includes(task.status))
          distinctTasks.push(task.status);
      }
      //console.log(distinctTasks.length);
      return distinctTasks.sort(); //TODO: sort by deadline.
    },
    assigneeFilterOptions() {
      let distinctTasks = [];
      for (let task of this.$store.state.sharedTasks.tasks) {
        if (!distinctTasks.includes(this.userNameById(task.user)))
          distinctTasks.push(this.userNameById(task.user));
      }
      //console.log(distinctTasks.length);
      return distinctTasks.sort(); //TODO: sort by deadline.
    },
    fieldOrEquipmentFilterOptions() {
      let distinctTasks = [];
      for (let task of this.$store.state.sharedTasks.tasks) {
        if (!distinctTasks.includes(this.fieldById(task.field)))
          distinctTasks.push(this.fieldById(task.field));

        if (!distinctTasks.includes(this.equipmentAliasById(task.equipment)))
          distinctTasks.push(this.equipmentAliasById(task.equipment));
      }
      //console.log("field", distinctTasks.length);
      return distinctTasks.sort(); //TODO: sort by deadline.
    },
    tasksFromState() {
      return this.$store.state.sharedTasks.tasks;
    },
    users() {
      return this.$store.state.users.users;
    },
    fields() {
      return this.$store.state.sharedFields.fields;
    },
    equipment() {
      return this.$store.state.sharedEquipment.equipment;
    },
  },
  methods: {
    selectFilter(e) {
      // console.log("filter on", e);
      this.filterObject[Object.keys(e)[0]] = String(e[Object.keys(e)[0]]);
    },
    clearSelectedFilter(e) {
      delete this.filterObject[e];
    },
    goToNewTaskPage() {
      // console.log("go to new activity");
      this.$router.push({ name: "vendor-new-activity" });
    },
    userNameById(userId) {
      if (userId != null) {
        return this.$store.getters.getUserById(userId).name;
      } else {
        return "";
      }
    },
    equipmentAliasById(equipmentId) {
      if (equipmentId != null) {
        return this.$store.getters.getSharedEquipmentById(equipmentId)?.alias;
      } else {
        return "";
      }
    },
    fieldById(fieldId) {
      if (fieldId != null) {
        return this.$store.getters.getSharedFieldById(fieldId)?.properties
          ?.name;
      } else {
        return "";
      }
    },
    sortColumn(sort) {
      this.pageSorts = sort;
    },
    exportTable() {
      let exportData = this.derivedTasks.map((r) => {
        return {
          id: r.id,
          fieldOrEquipment: r.fieldOrEquipment,
          category: r.category,
          status: r.status,
          deadline: r.deadline,
          instructions: r.instructions,
          assignee: this.userNameById(r.user.id),
        };
      });
      exportToCSV(exportData, "workorders.csv");
    },
  },
  async mounted() {
    this.tasksToDisplay = this.tasksFromState;
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- mobile side menu button -->
  <button
    type="button"
    class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-twilight-500 md:hidden"
    @click="$emit('clickSidebarMenu')"
  >
    <span class="sr-only">Open sidebar</span>
    <OutlineHeroIcon
      name="MenuAlt2Icon"
      classProp="w-6 h-6"
      aria-hidden="true"
    />
  </button>
  <div class="flex flex-1 justify-between px-4">
    <div class="flex flex-1 items-center">
      <div>
        <div>
          <nav class="sm:hidden" aria-label="Back">
            <router-link
              :to="{ name: 'grower-portal-home' }"
              class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              <HomeIcon
                class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              Home
            </router-link>
          </nav>
          <nav class="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div>
                  <router-link
                    :to="{ name: homeRouteName }"
                    class="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      class="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span class="sr-only">Home</span>
                  </router-link>
                </div>
              </li>
              <li v-for="crumb in crumbs" :key="crumb.name">
                <div class="flex items-center">
                  <ChevronRightIcon
                    class="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <router-link
                    :to="{ name: crumb.to }"
                    class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    {{ crumb.path }}
                  </router-link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div class="mt-2 md:flex md:items-center md:justify-between">
          <div class="min-w-0 flex-1">
            <h2
              class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl"
            >
              {{ this.$route.meta.displayTitle }}
            </h2>
          </div>
          <!-- <div class="flex flex-shrink-0 mt-4 md:mt-0 md:ml-4">
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-twilight-500"
        >
          Edit
        </button>
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-twilight-600 hover:bg-twilight-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-twilight-500"
        >
          Publish
        </button>
      </div> -->
        </div>
      </div>
      <!-- <form class="flex w-full md:ml-0" action="#" method="GET">
              <label for="search-field" class="sr-only">Search</label>
              <div
                class="relative w-full text-gray-400 focus-within:text-gray-600"
              >
                <div
                  class="absolute inset-y-0 left-0 flex items-center pointer-events-none "
                >
                  <SearchIcon class="w-5 h-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  class="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  type="search"
                  name="search"
                />
              </div>
            </form> -->
    </div>

    <!-- three dot menu to the right to access sign out and solutions -->
    <div class="ml-4 flex items-center md:ml-6">
      <!-- notification button -->
      <!-- <div class="px-1">
        <base-button
          :buttonText="
            currentOrgProfile.subscription_tier ? 'Manage Account' : 'Upgrade'
          "
          @buttonClick="toUpgradePage"
        />
      </div> -->
      <div class="px-1">
        <notification-center />
      </div>
      <div class="px-1">
        <dropdown-menu :menuNavItems="solutions" @logout="logout" />
        <!--class="sm:hidden" and un-comment nav if we want to show the options-->
      </div>
      <div class="px-1">
        <img
          class="-mt-2 h-8 w-auto"
          src="/img/icons/parcel-logo.svg"
          alt="Parcel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenu from "@/components/dropdowns/DropdownMenu.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
import {
  // ChevronLeftIcon,
  ChevronRightIcon,
  HomeIcon,
} from "@heroicons/vue/solid";
import NotificationCenter from "../grower-portal/NotificationCenter.vue";

const routeNames = [
  { path: "reservations", name: "vendor-hauling-reservations" },
  { path: "listings", name: "vendor-listings" },
  { path: "recommendation", name: "vendor-recommendations" },
  { path: "vendor-activity", name: "vendor-activities" },
  { path: "quotes", name: "vendor-quotes-all" },
  { path: "orders", name: "vendor-orders-all" },
  { path: "products", name: "vendor-products-all" },
];

export default {
  components: {
    // ChevronLeftIcon,
    OutlineHeroIcon,
    ChevronRightIcon,
    HomeIcon,
    DropdownMenu,
    NotificationCenter,
    // BaseButton,
  },
  props: ["homeRouteName", "solutions"],
  emits: ["clickSidebarMenu"],
  data() {
    return {
      currentOrgProfile: this.$store.state.orgs.org_profile,
    };
  },
  methods: {
    async logout() {
      // console.log("attempting logout");
      await this.$store.dispatch("logout").then(() => {
        this.$router.push({ path: "/login" });
      });
    },
    // toUpgradePage() {
    //   this.$router.push({ name: "vendor-subscription" });
    // },
  },
  computed: {
    crumbs: function () {
      //console.log(routeNames);
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path) => {
        var route = routeNames.filter((obj) => {
          return obj.path == path;
        });
        let to = "";
        if (route[0]) to = route[0].name;
        //console.log("breadcrumb", path, to, route);
        breadcrumbArray.push({
          path: path,
          to: to,
          // to: {name: this.$route.matched[idx].name},
          // text: this.$route.matched[idx].meta.breadCrumb || path,
        });
        return breadcrumbArray;
      }, []);
      breadcrumbs.shift();
      return breadcrumbs;
    },
  },
};
</script>

<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Needs Action
            <span
              v-if="needsActionQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ needsActionQuotes }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Awaiting Response
            <span
              v-if="awaitingResponseQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ awaitingResponseQuotes }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            In Progress
            <span
              v-if="confirmedQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ confirmedQuotes }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Closed
            <!-- <span
              v-if="closedQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ closedQuotes }}</span
            > -->
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Completed
            <!-- <span
              v-if="completedQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ completedQuotes }}</span
            > -->
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <all-vendor-quotes-table :tabFilters="needsActionFilter" />
        <!-- <needs-action-vendor-quotes-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <all-vendor-quotes-table :tabFilters="awaitingResponseFilter" />
        <!-- <awaiting-response-vendor-quotes-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <all-vendor-quotes-table :tabFilters="confirmedFilter" />
        <!-- <confirmed-vendor-quotes-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <all-vendor-quotes-table :tabFilters="closedFilter" />
        <!-- <closed-vendor-quotes-table /> -->
      </TabPanel>
      <TabPanel class="pt-10">
        <all-vendor-quotes-table :tabFilters="completedFilter" />
        <!-- <completed-vendor-quotes-table /> -->
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";

// import NeedsActionVendorQuotesTable from "@/layouts/vendor-portal/quotes/NeedsActionVendorQuotesTable.vue";
// import AwaitingResponseVendorQuotesTable from "@/layouts/vendor-portal/quotes/AwaitingResponseVendorQuotesTable.vue";
// import ClosedVendorQuotesTable from "@/layouts/vendor-portal/quotes/ClosedVendorQuotesTable.vue";
// import CompletedVendorQuotesTable from "@/layouts/vendor-portal/quotes/CompletedVendorQuotesTable.vue";
// import ConfirmedVendorQuotesTable from "@/layouts/vendor-portal/quotes/ConfirmedVendorQuotesTable.vue";
import AllVendorQuotesTable from "@/layouts/vendor-portal/quotes/AllVendorQuotesTable.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // NeedsActionVendorQuotesTable,
    // AwaitingResponseVendorQuotesTable,
    // ClosedVendorQuotesTable,
    // CompletedVendorQuotesTable,
    // ConfirmedVendorQuotesTable,
    AllVendorQuotesTable,
  },
  data() {
    return {
      selectedTab: 0,
      awaitingResponseFilter: function (o) {
        if (o.status === "seller_responded") {
          return true;
        } else {
          return false;
        }
      },
      closedFilter: function (o) {
        if (
          o.status === "buyer_closed" ||
          o.status === "seller_closed" ||
          o.status === "seller_canceled"
        ) {
          return true;
        } else {
          return false;
        }
      },
      confirmedFilter: function (o) {
        if (o.status === "buyer_confirmed" || o.status === "buyer_ordered") {
          return true;
        } else {
          return false;
        }
      },
      completedFilter: function (o) {
        if (o.status === "seller_completed") {
          return true;
        } else {
          return false;
        }
      },
      needsActionFilter: function (o) {
        if (o.status === "buyer_submitted" || o.status === "buyer_rejected") {
          return true;
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    allQuotes() {
      return [
        ...this.$store.getters.getDerivedVendorQuotes({}),
        ...this.$store.state.vendorInputOrders.orders,
      ];
    },
    needsActionQuotes() {
      //   let numOrders = this.$store.getters.getNeedsActionVendorOrders.length;
      let numOrders = this.allQuotes.filter(this.needsActionFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    awaitingResponseQuotes() {
      //   let numOrders = this.$store.getters.getNeedsActionVendorOrders.length;
      let numOrders = this.allQuotes.filter(this.awaitingResponseFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    confirmedQuotes() {
      //   let numOrders = this.$store.getters.getClosedVendorOrders.length;
      let numOrders = this.allQuotes.filter(this.confirmedFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    closedQuotes() {
      //   let numOrders = this.$store.getters.getClosedVendorOrders.length;
      let numOrders = this.allQuotes.filter(this.closedFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
    completedQuotes() {
      //   let numOrders = this.$store.getters.getCompletedVendorOrders.length;
      let numOrders = this.allQuotes.filter(this.completedFilter).length;
      if (numOrders > 0) {
        return numOrders;
      } else {
        return null;
      }
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>

<template>
  <table-card v-if="orders.length > 0">
    <template #header>
      <table-head
        :columns="[
          {
            name: 'buyer_details_name',
            label: 'Customer',
            filtered: true,
            sorted: true,
          },
          {
            name: 'service_title',
            label: 'Product',
            filtered: true,
            sorted: true,
          },

          { name: 'prettyStatus', label: 'Status' },
          { name: 'prettyDate', label: 'Date' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="orders"
        @update:derived-collection="derivedOrders = $event"
        :defaultSort="defaultSort"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="o in derivedOrders" :key="o.id">
        <table-data td-label="Customer">
          {{ o.buyer_details_name }}
        </table-data>
        <table-data td-label="Product">
          {{ o.service_title }}
        </table-data>
        <table-data td-label="Status">
          {{ o.prettyStatus }}
        </table-data>
        <table-data td-label="Date">
          {{ o.prettyDate }}
        </table-data>

        <table-data class="text-center">
          <router-link
            :to="{
              name: o.service_category
                ? 'vendor-single-order'
                : 'vendor-single-input-order',
              params: { orderId: o.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
  <div v-else>
    <div class="text-center">
      <h3 class="mt-2 text-sm font-semibold text-gray-900">No results</h3>
      <p class="mt-1 text-sm text-gray-500">Select a new tab</p>
    </div>
  </div>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import { prettyQuoteStatusVendor } from "@/components/composables/formatStringUtils.js";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
  },
  props: {
    tabFilters: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      derivedOrders: [],
      defaultSort: { prettyDate: "desc" },
    };
  },
  computed: {
    agreements() {
      return this.$store.state.vendorQuotes.vendorQuotes;
    },
    inputAgreements() {
      return this.$store.state.vendorInputAgreements.agreements;
    },
    inputListings() {
      return this.$store.state.vendorInputListings.listings;
    },
    inputUnits() {
      return this.$store.state.vendorInputListings.units;
    },
    inputRequests() {
      return this.$store.state.growerInputListings.requests;
    },
    orders() {
      let orders = [
        ...this.$store.getters.getEnrichedVendorOrders({
          filterFunction: this.tabFilters,
        }),
        ...this.$store.state.vendorInputOrders.orders
          .filter(
            (o) =>
              o.status !== "buyer_submitted" && o.status !== "seller_responded",
          )
          .filter(this.tabFilters),
      ];
      for (let order of orders) {
        if (!order.buyer_details_name)
          order.buyer_details_name = (
            order.service_category
              ? this.getAgreement(order.agreement)
              : this.getInputAgreement(order.input_agreement)
          )?.buyer_details.name;
        if (!order.service_title)
          order.service_title = order.service_category
            ? this.getAgreement(order.agreement)?.service_title
            : this.getOrderProductNames(order);
        if (!order.prettyStatus)
          order.prettyStatus = prettyQuoteStatusVendor(order.status);
        order.prettyDate = order.updated?.substring(0, 10);
      }
      return orders;
    },
  },
  methods: {
    getAgreement(id) {
      return this.agreements.find((a) => a.id == id);
    },
    getInputAgreement(id) {
      return this.inputAgreements.find((a) => a.id == id);
    },
    getInputListing(id) {
      return this.inputListings.find((a) => a.id == id);
    },
    getInputUnit(id) {
      return this.inputUnits.find((a) => a.id == id);
    },
    getInputRequest(id) {
      return this.inputRequests.find((a) => a.id == id);
    },
    getOrderProductNames(o) {
      let title = "";
      for (let item of o.line_items || []) {
        // console.log("item", item);
        if (item.input_unit) {
          const unit = this.getInputUnit(item.input_unit);
          if (unit) {
            const listing = this.getInputListing(unit.input_listing);
            if (listing) {
              if (title.length) {
                title = title.concat(", ");
              }
              title = title.concat(listing.title);
            }
          }
        }
        if (item.input_request) {
          const request = this.getInputRequest(item.input_request);
          if (request) {
            if (title.length) {
              title = title.concat(", ");
            }
            title = title.concat(request.product_name);
          }
        }
      }
      return title;
    },
    prettyQuoteStatusVendor,
  },
};
</script>

<template>
  <page-card
    :headingTitle="headingTitle"
    :headingDescription="headingDescription"
  >
    <template #cardDetails>
      <div class="sm:col-span-6">
        <table-card>
          <template #header>
            <table-head
              :columns="[
                { name: 'name', label: 'Org', filtered: false },
                { name: 'actions', label: '', filtered: false },
              ]"
              :default-sort="{ granted: 'asc', name: 'asc' }"
              :collection="availableOrgs"
              @update:derived-collection="derivedOrgs = $event"
              :toolbar="false"
            >
            </table-head>
          </template>
          <tbody>
            <tr v-for="org in derivedOrgs" :key="org.id">
              <table-data td-label="Org">
                <div class="flex">
                  <Avatar :username="org.name" :src="org.logo_url" />
                  {{ org.name }}
                </div>
              </table-data>
              <table-data class="text-center">
                <base-button
                  v-if="org.granted"
                  buttonText="Revoke Access"
                  :marginLeft="false"
                  @buttonClick="() => revokeOrg(org.id)"
                  :redButton="true"
                />
                <base-button
                  v-else
                  buttonText="Grant Access"
                  :marginLeft="false"
                  @buttonClick="() => grantOrg(org.id)"
                />
              </table-data>
            </tr>
          </tbody>
        </table-card>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

import Avatar from "@/components/identity/UserAvatar.vue";
// import _ from "lodash";

export default {
  components: {
    PageCard,
    BaseButton,
    TableCard,
    TableHead,
    TableData,
    Avatar,
  },
  props: {
    headingTitle: {
      type: String,
      default: "Partner Organizations",
    },
    headingDescription: {
      type: String,
      default:
        "Manage which partners can submit offers and search crop supply.",
    },
  },
  data() {
    return {
      derivedOrgs: [],
    };
  },
  methods: {
    async grantOrg(orgId) {
      await this.$store.dispatch("createAffiliateOrg", { id: orgId });
      await this.$store.dispatch("getGrowerMarketOffers");
    },
    async revokeOrg(orgId) {
      await this.$store.dispatch("deleteAffiliateOrg", { id: orgId });
      await this.$store.dispatch("getGrowerMarketOffers");
    },
  },
  computed: {
    availableOrgs() {
      const result = this.$store.state.externalOrgs.availableOrgs
        .filter((org) => org.id != this.currentOrgId)
        .map((org) => ({
          ...org,
          granted: this.$store.getters.getAffiliateOrgById(org.id),
        }));
      // console.log("Available Orgs", result);
      return result;
    },
    currentOrgId() {
      return this.$store.getters.getCurrentOrg?.id;
    },
  },
};
</script>

<template>
  <div class="bg-white" v-if="fields.length > 0">
    <main class="px-4 sm:px-6 sm:pb-8 sm:pt-6 lg:max-w-7xl lg:px-8">
      <!-- <h2 class="mt-2">Fields to be considered for quote:</h2> -->
      <div v-if="fields?.length > 0" class="flex h-96">
        <MapViewer
          :layers="layers"
          :showGoToFeature="false"
          :showTurnByTurn="true"
          width="w-96"
        />
        <div class="w-screen">
          <table-card>
            <thead>
              <tr>
                <th>Field</th>
                <th>Crop</th>
                <th>Variety</th>
                <th>Acres</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="field in fields" :key="field.id">
                <td td-label="Field">
                  {{ field.properties?.name }}
                </td>
                <td td-label="Crop">
                  {{ field.properties?.crop }}
                </td>
                <td td-label="Variety">
                  {{ field.properties?.details?.variety }}
                </td>
                <td td-label="Acres">
                  {{ field.properties?.acres }}
                </td>
              </tr>
            </tbody>
          </table-card>
          <!-- <field-list :vendorId="vendorId" /> -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import MapViewer from "@/components/map/MapViewer.vue";
import TableCard from "@/components/table/TableCard.vue";
// import { ChevronLeftIcon } from "@heroicons/vue/solid";
export default {
  components: {
    MapViewer,
    TableCard,
  },
  props: {
    fields: {
      type: Object,
    },
    locations: {
      type: Object,
    },
  },
  data() {
    return {
      modalIsActive: false,
    };
  },
  computed: {
    layers() {
      return [
        {
          name: "fields for quote",
          geojson: {
            type: "Feature Collection",
            features: this.fields,
          },
        },
        {
          name: "locations", // layer name to show in layer selector
          geojson: {
            type: "Feature Collection",
            features: this.locations ? this.locationGeoJSON : [],
          },
        },
      ];
    },
    locationGeoJSON() {
      let locations = [];
      if (this.locations) {
        for (let location of this.locations) {
          locations.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: location.point.coordinates,
            },
            properties: {
              name: location.type,
            },
          });
        }
      }
      return locations;
    },
  },
  methods: {},
};
</script>

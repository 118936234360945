<template>
  <styled-input
    type="email"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    v-model="inputValue"
    @blur="checkValidInput"
    :isValid="isValid"
    :classProp="classProp"
    :helpText="helpText"
    :helpTitle="helpTitle"
  />

  <!--
<div class="mt-1">
  <label
    v-if="inputTitle.length > 0"
    class="block text-sm font-medium text-gray-700"
  >
    {{ inputTitle }}</label
  >
  <div class="relative block w-full border-gray-300 shadow-sm focus:ring-twilight-500 focus:border-twilight-500 sm:text-sm">
  <input
    type="email"
    :placeholder="placeholder"
    @change="checkValidInput"
    v-model="inputValue"
    :required="required"
    class="w-full border-gray-300 rounded-md"
  />
    <div
        v-if="!isValid"
        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none "
      >
        <ExclamationCircleIcon class="w-5 h-5 text-red-500" />
  </div>
  </div>
  </div>
  -->
</template>

<script>
// import * as yup from "yup";
import { z } from "zod";
import StyledInput from "./StyledInput.vue";
export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    required: { type: Boolean, default: false },
    modelValue: { type: String },
    wideItem: { type: Boolean, default: false },
    displayOnly: { type: Boolean, default: false },
    classProp: { type: String, default: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue", "valid"],
  data() {
    return {
      inputValue: this.modelValue,
      isValid: true,
    };
  },
  methods: {
    // Not 100% that the failure case triggers properly.
    checkValidInput() {
      // console.log("email check");
      // let emailSchema = yup.string().email();
      let emailSchema = z.string().email();
      emailSchema.isValid(this.inputValue).then((result) => {
        // console.log("valid check", result);
        this.isValid = result;
      });
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
    // displayOnly() {
    //   if (this.displayOnly) this.inputValue = this.displayOnly;
    // },
    // displayOnlyValue() {
    //   if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    // },
    isValid() {
      this.$emit("valid", this.isValid);
    },
  },
};
</script>

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'category', label: 'Category' },
          { name: 'status', label: 'Status' },
          {
            name: 'company.name',
            label: 'Company',
            filtered: false,
            sorted: false,
          }, // Needs to be included in an enriched object to be filterable
          { name: 'field.properties.name', label: 'Field', filtered: false }, // Needs to be included in an enriched object to be filterable
          { name: 'task.source?.name', label: 'Assigned By', filtered: false }, // Needs to be included in an enriched object to be filterable
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="tasks"
        @update:derived-collection="derivedTasks = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewTaskPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="task in derivedTasks" :key="task.id">
        <table-data td-label="Category">
          {{ task.category }}
        </table-data>
        <table-data td-label="Status">
          {{ task.status }}
        </table-data>
        <table-data td-label="Company">
          {{ task.customer_org?.name }}
        </table-data>
        <table-data td-label="Field">
          {{ task.field?.properties?.name }}
        </table-data>
        <table-data td-label="Assigned By">
          {{ task.source?.name }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'vendor-recommendation',
              params: { id: task.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedTasks: [],
    };
  },
  computed: {
    tasks() {
      let derivedTasks = this.$store.getters.getDerivedSharedTasks({});
      derivedTasks = derivedTasks.filter((task) => {
        return task.source_org == this.$store.getters.getCurrentOrg.id;
      });
      return derivedTasks;
    },
  },
  methods: {
    goToNewTaskPage() {
      this.$router.push({ name: "vendor-new-recommendation" });
    },
  },
};
</script>

import TextInputVue from '@/components/form/TextInput.vue'; import { current }
from 'tailwindcss/colors'; import { current } from 'tailwindcss/colors'; import
{ text } from 'stream/consumers';
<template>
  <text-input
    inputTitle="EPA Registration Number"
    :wideItem="true"
    v-model="l.epa_number"
    @blur="this.$emit('update:currentProduct', l)"
  />
  <text-input
    inputTitle="Type of Product"
    :wideItem="true"
    v-model="l.type"
    @blur="this.$emit('update:currentProduct', l)"
  />
  <text-input
    inputTitle="Manufacturer"
    :wideItem="true"
    v-model="l.manufacturer"
    @blur="this.$emit('update:currentProduct', l)"
  />
  <text-input
    inputTitle="Active Ingredients"
    :wideItem="true"
    v-model="l.active_ingredients"
    @blur="this.$emit('update:currentProduct', l)"
  />
  <text-area
    :wideItem="true"
    inputTitle="Pests Registered for Use"
    v-model="l.pests"
    @onBlur="this.$emit('update:currentProduct', l)"
  />
  <text-area
    :wideItem="true"
    inputTitle="Crops Registered for Use"
    v-model="l.crops"
    @onBlur="this.$emit('update:currentProduct', l)"
  />
  <text-area
    :wideItem="true"
    inputTitle="States Registered for Use"
    v-model="l.states"
    @onBlur="this.$emit('update:currentProduct', l)"
  />
  <list-input
    :taglist="l.keywords"
    @updateTags="setCurrentProductKeywords"
    inputTitle="Search Keywords"
    help-text="Add additional keywords that don't appear in your product name or description. These will be used to help growers find your product."
  />
</template>
<script>
import TextArea from "@/components/form/TextArea.vue";
import TextInput from "@/components/form/TextInput.vue";
import ListInput from "@/components/form/ListInput.vue";
export default {
  props: {
    currentProduct: {},
  },
  emits: ["update:currentProduct"],
  components: {
    TextArea,
    TextInput,
    ListInput,
  },
  data() {
    return {
      l: this.currentProduct,
    };
  },
  methods: {
    setCurrentProductKeywords(keywordsArray) {
      this.l.keywords = keywordsArray;
    },
  },
};
</script>

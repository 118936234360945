export function calculatePrice(sku, store) {
  let inputOrders = store.getters.getGrowerInputOrders;
  let lastUpdate = sku.updated;
  if (!lastUpdate) lastUpdate = sku.created;
  let price = null;
  // console.log("CALCULATE PRICE", sku, lastUpdate, inputOrders);
  for (let order of inputOrders) {
    if (
      order.status === "buyer_ordered" ||
      order.status === "seller_completed"
    ) {
      let lineItems = order.line_items;
      for (let lineItem of lineItems) {
        let lineItemDate = lineItem.updated;
        if (!lineItemDate) lineItemDate = lineItem.created;
        if (lineItem.input_unit === sku.id)
          if (
            lineItem.input_unit === sku.id &&
            lineItemDate > lastUpdate &&
            lineItem.unit_price
          ) {
            // console.log("LINE ITEM DATE", lineItem.input_unit, sku.id, lineItemDate, lastUpdate, lineItem.unit_price, price);
            if (!price || lineItem.unit_price < price) {
              price = lineItem.unit_price;
            }
          }
      }
    }
  }
  // console.log("FINAL PRICE", price);
  return price;
}

export function formatCurrency(value) {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

<template>
  <alert-modal
    :open="alertModalIsActive"
    modalTitle="Field Shape Required"
    modalDescription="You must draw a shape to save a new field."
    @clickDismiss="() => (alertModalIsActive = false)"
  />
  <page-card headingTitle="New Field">
    <template #buttonArea>
      <submit-button buttonText="Save" :marginLeft="false" formId="new-crop" />
    </template>
    <template #cardDetails>
      <form
        @submit.prevent="createField"
        id="new-crop"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <!-- <field-form
          :formId="newFieldFormId"
          :field="currentField"
          @updateFieldDetails="createField"
        /> -->
        <field-form v-model:field="currentField" />
        <crop-form v-model:crop="activeCrop" :isNew="false" />
      </form>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Map
              </button>
            </Tab>
            <!-- <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Records
                </button>
              </Tab> -->
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10">
            <div class="-mt-4 flex text-sm text-gray-600">
              You must add your field boundary. Click
              <img
                :src="require('@/assets/polygon.svg')"
                alt="add boundary"
                class="ml-1 h-4 w-4"
              />
              &nbsp;to add a boundary.
            </div>
            <div
              class="border-t border-gray-200 px-4 py-5 sm:col-span-6 sm:px-6"
            >
              <field-leaflet-map
                :fieldId="null"
                @edited="updateFieldShape"
                :key="null"
                :layers="layers"
                :show-layer-selector="true"
              >
                <!-- <modal :show="showLocationModal" title="save a location">
                  <select-dropdown
                    v-model="markedLocationType"
                    :selectOptions="['Entrance', 'Pick up']"
                    :placeholder="'Select a location'"
                    inputTitle="Location Type"
                  />
                  <base-button
                    @buttonClick="saveLocation"
                    buttonText="Save"
                  ></base-button>
                  <base-button
                    @buttonClick="showLocationModal = false"
                    buttonText="Cancel"
                    :redButton="true"
                  ></base-button>
                </modal> -->
              </field-leaflet-map>
            </div>
          </TabPanel>
          <!-- <TabPanel class="pt-10" :unmount="false">
              <records-table :currentField="fieldId" ref="recordsTable" />
            </TabPanel> -->
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import FieldForm from "@/layouts/grower-portal/assets/fields/FieldForm.vue";
import CropForm from "@/layouts/grower-portal/assets/fields/CropForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import FieldLeafletMap from "@/components/map/FieldEditMap.vue";
// import RecordsTable from "@/layouts/grower-portal/records/RecordsTable.vue";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import AlertModal from "@/components/modals/AlertModal.vue";
// import modal from "@/components/modals/PopupModal.vue";
export default {
  components: {
    PageCard,
    // BaseButton,
    SubmitButton,
    // SelectDropdown,
    FieldForm,
    CropForm,
    FieldLeafletMap,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    AlertModal,
    // modal,
  },
  data() {
    return {
      activeCrop: {
        crop_type: null,
        details: {},
        active: true,
      },
      newFieldFormId: "newField",
      showLocationModal: false,
      alertModalIsActive: false,
      currentField: {
        type: "Feature",
        geometry: {}, // object
        properties: {
          farm: null, // string
          name: null, // string
          acres: null, // int
          owner: null, // int
          crop: null, // string
          details: {}, // object
        },
      },
    };
  },
  computed: {
    fieldHasGeometry() {
      if (this.currentField.geometry?.coordinates?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    layers() {
      let layers = [];
      let fields = this.$store.state.fields.fields;

      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Other Fields",
        geojson: fieldJSON,
        style: { fillColor: "red", opacity: 1, weight: 1, color: "green" },
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);
      return layers;
    },
  },
  methods: {
    updateFieldShape(feature, acres) {
      // console.log("update that shape!")
      // console.log("New vals", feature, acres)
      this.currentField.properties.acres = acres;
      this.currentField.geometry = feature.geometry;
      // console.log(this.currentField)
    },
    async createField() {
      if (this.fieldHasGeometry) {
        // let { crop_type, details, ...restOfCropDetails } =
        //   this.$refs.cropForm.submit();
        // this.currentField.properties = {
        //   ...fieldProperties,
        //   crop: crop_type,
        //   details: details,
        // };
        const result = await this.$store.dispatch(
          "createField",
          this.currentField,
        );
        // console.log("RESULT", result);
        // const {
        //   properties: {
        //     current_crop: { id },
        //   },
        // } = result;
        this.activeCrop.id = result.properties.current_crop.id;
        // console.log("CROP ID", this.activeCrop.id);
        await this.$store.dispatch("updateCrop", this.activeCrop);
        if (!this.$store.getters.getApiError) {
          this.$router.push({ name: "fields" });
        }
      } else {
        this.alertModalIsActive = true;
      }
    },
  },
};
</script>

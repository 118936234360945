import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      buyerCropVendors: [],
    };
  },
  mutations: {
    SET_BUYER_CROP_VENDORS(state, vendorData) {
      state.buyerVendors = vendorData;
    },
    ADD_BUYER_CROP_VENDOR(state, vendorObject) {
      state.buyerVendors.push(vendorObject);
    },
    UPDATE_BUYER_CROP_VENDOR(state, vendorObject) {
      state.buyerVendors = state.buyerVendors.filter(function (obj) {
        return obj.id !== vendorObject.id;
      });
      state.buyerVendors.push(vendorObject);
    },
    DELETE_BUYER_CROP_VENDOR(state, vendorObject) {
      state.buyerVendors = state.buyerVendors.filter(function (obj) {
        return obj.id !== vendorObject.id;
      });
    },
    RESET_BUYER_CROP_VENDORS(state) {
      state.buyerVendors = [];
    },
  },
  actions: {
    async getBuyerCropVendors({ commit }) {
      const data = await client.buyer.cropVendors.read();
      if (data) {
        // console.log("getBuyerCropVendors", data);
        commit("SET_BUYER_CROP_VENDORS", data.vendors);
      }
    },
    async createBuyerCropVendor({ commit }, vendor) {
      const data = await client.buyer.cropVendors.create(null, vendor);
      if (data) {
        // console.log(data);
        commit("ADD_BUYER_CROP_VENDOR", data);
        this.dispatch("getBuyerCropVendors");
        return data.id;
      }
    },
    async updateBuyerCropVendor({ commit }, vendor) {
      const data = await client.buyer.cropVendors.update(vendor.id, vendor);
      if (data) {
        // console.log(data);
        commit("UPDATE_BUYER_CROP_VENDOR", data);
        this.dispatch("getBuyerCropVendors");
      }
    },
  },
  getters: {
    getBuyerCropVendorById: (state) => (vendorId) => {
      return state.buyerVendors.find(
        (vendor) => vendor.id === parseInt(vendorId),
      );
    },
    getBuyerCropVendors:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedBuyerVendors = state.buyerVendors.map((vendor) => ({
          ...vendor,
          // seller_name: vendor.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterBuyerVendors = filterFunction
          ? _.filter(expandedBuyerVendors, filterFunction)
          : expandedBuyerVendors;
        let filteredBuyerVendors = _.isEmpty(filter)
          ? functionFilterBuyerVendors
          : _.filter(functionFilterBuyerVendors, filter);
        let matchedBuyerVendors = _.isEmpty(match)
          ? filteredBuyerVendors
          : _.filter(filteredBuyerVendors, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedBuyerVendors = _.isEmpty(sort)
          ? matchedBuyerVendors
          : _.sortBy(matchedBuyerVendors, sort);
        let paginatedBuyerVendors =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedBuyerVendors,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedBuyerVendors;
        return paginatedBuyerVendors;
      },
  },
};

<template>
  <carbon-scenario-form
    onSubmitNavigationRouteName="vendor-carbon-single-scenario"
    :sharingCarbonAgreements="carbonAgreements"
  />
</template>

<script>
import CarbonScenarioForm from "@/layouts/common/carbon/CarbonScenarioForm.vue";

export default {
  components: {
    CarbonScenarioForm,
  },

  data() {
    return {
      carbonAgreementsFilter: function (o) {
        if (
          o.service_category === "Carbon" &&
          o.service_data_policy.scenarios === "Read/Write"
        ) {
          return true;
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    carbonAgreements() {
      let agreements = this.$store.getters.getDerivedVendorQuotes({
        filterFunction: this.carbonAgreementsFilter,
        // filter: { service_category: "Carbon" },
      });
      if (agreements.length === 0) {
        return null;
      } else {
        return agreements;
      }
    },
  },
  methods: {},
};
</script>

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'contract_name', label: 'Contract', filtered: false },
          { name: 'crop', label: 'Crop' },
          { name: 'quantity', label: 'Quantity', filtered: false },
          { name: 'premiums', label: 'Attached Premiums', filtered: false },
          { name: 'client_name', label: 'Client Name' },
          { name: 'status', label: 'Status' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="contracts"
        @update:derived-collection="derivedContracts = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="navigateNewContract"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="contract in derivedContracts" :key="contract.id">
        <table-data td-label="Contract">
          {{ contract.contract_name }}
        </table-data>
        <table-data td-label="Crop">
          {{ contract.crop }}
        </table-data>
        <table-data td-label="Quantity">
          {{ contract.quantity }} {{ contract.quantity_units }}
        </table-data>
        <table-data td-label="Premiums">
          {{ contract.premiums.length }}
        </table-data>
        <table-data td-label="Client">
          {{ contract.client_name }}
        </table-data>
        <table-data td-label="Status">
          {{ statuses[contract.status] }}
        </table-data>

        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-contract-single',
              params: { contractId: contract.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedContracts: [],
      statuses: {
        active: "Active",
        invoice_sent: "Invoice Sent",
        invoice_paid: "Invoice Paid",
        growers_paid: "Growers Paid",
        affiliates_paid: "Affiliates Paid",
        complete: "Complete",
      },
    };
  },
  computed: {
    contracts() {
      // console.log(
      //   "GETTING CONTRACTS",
      //   this.$store.getters.getContracts({}),
      // );
      let contracts = this.$store.getters.getContracts({});
      // grab the premiums that have a contract_id that matches the contract and insert them into the contract object at contract.premiums
      contracts = contracts.map((contract) => {
        contract.premiums = this.$store.getters.getContractPremiumsByContractId(
          contract.id,
        );
        return contract;
      });
      contracts = contracts.filter((contract) => contract.deleted == null);
      return contracts;
    },
  },
  methods: {
    navigateNewContract() {
      this.$router.push({ name: "buyer-contract-new" });
    },
  },
};
</script>

<style></style>

<template>
  <hr class="mt-2 w-full" />
  <div class="flex">
    <div class="mt-4 font-bold">
      Questions for the grower
      <ToolTip
        helpText="Add questions you need the grower to answer."
        helpTitle=""
      />
    </div>
    <button
      @click="addQuestion"
      class="ml-8 mt-3 h-8 w-12 rounded border bg-twilight-700 text-white"
    >
      + Add
    </button>
  </div>
  <div
    class="mb-4 mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div
      v-for="(l, index) in formList"
      :key="l"
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
    >
      <!-- "`Set ${index + 1} Options`" -->
      <text-area
        :inputTitle="`Question ${index + 1}`"
        :wideItem="true"
        :model-value="l.question"
        @update:model-value="
          (newVal) => {
            formList[index].question = newVal;
          }
        "
      />

      <button @click="formList.splice(index, 1)" class="-mt-3 h-10">
        <trash-icon class="h-6 w-6" />
      </button>
    </div>
  </div>
</template>

<script>
// import TextInput from "@/components/form/TextInput.vue";
import { TrashIcon } from "@heroicons/vue/outline";
import ToolTip from "@/components/modals/ToolTip.vue";
import TextArea from "@/components/form/TextArea.vue";

export default {
  components: {
    TextArea,
    TrashIcon,
    ToolTip,
  },
  props: {
    initialFormList: {
      type: Array,
      default: () => [{ label: "", question: "" }],
    },
  },
  emits: ["updateFormList"],
  data() {
    return {
      numberOfQuestions: this.setInitialNumberOfFormQuestions(),
      formList: this.initialFormList,
    };
  },
  computed: {},
  methods: {
    addQuestion() {
      this.formList.push({ question: "" });
    },
    setInitialNumberOfFormQuestions() {
      let numQuestions = 0;
      if (this.initialFormList.length >= 1) {
        numQuestions = this.initialFormList.length;
      }
      return numQuestions;
    },
  },
  watch: {
    numberOfQuestions() {
      if (this.formList.length < this.numberOfQuestions) {
        while (this.formList.length < this.numberOfQuestions) {
          this.formList.push({ label: "", question: "" });
        }
      } else if (this.formList.length > this.numberOfQuestions) {
        while (this.formList.length > this.numberOfQuestions) {
          this.formList.pop();
        }
      }
    },
    formList: {
      handler: function (newVal) {
        // console.log("Form list: ", newVal);
        this.$emit("updateFormList", newVal);
      },
      deep: true,
    },
  },
};
</script>

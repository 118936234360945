<template>
  <!-- Main 3 column grid -->
  <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
    <!-- Left column -->
    <div class="grid grid-cols-1 gap-4 lg:col-span-2">
      <slot name="main"></slot>
    </div>
    <!-- Right column -->
    <div class="grid grid-cols-1 gap-4">
      <slot name="minor"></slot>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

let lobs = [
  "crop-hauling",
  "agronomic-advice",
  "invoices",
  // "products",
  "carbon",
];
let inits = [
  {
    serviceType: "invoices",
    featureFlag: "invoices",
    iconName: "CashIcon",
    title: "Invoices",
    description:
      "Send invoices and accept payments. Parcel partners with Stripe to securely accept Credit Card and ACH payments in the U.S.",
    highlights: [],
    agreementLink: "https://parcel.ag/tos/vendor",
    agreementNotice:
      "By continuing you accept the Parcel Invoices Terms of Service. ",
    buttonText: "Start Accepting Payments",
    buttonRouteName: "invoices-onboarding",
    learnMoreRouteName: "vendor-invoices",
  },
  {
    serviceType: "crop-hauling",
    featureFlag: "vp-hauling",
    iconName: "TruckIcon",
    title: "Hauling",
    description:
      "Digitize your ag hauling business with our turnkey solution. Define your territory, connect with your customers, and manage hauling reservations through Parcel.",
    highlights: [
      {
        highlightTitle: "Pricing",
        highlightDescription:
          "We bill you only for completed loads. See terms for pricing details.",
      },
      {
        highlightTitle: "Territory",
        highlightDescription:
          "Make your service available only to customers in your service area.",
      },
      {
        highlightTitle: "Orders",
        highlightDescription:
          "Receive orders from your customers and confirm or reject them. Forward incoming orders via email, text, or API to a destination of your choice.",
      },
    ],
    agreementLink: "https://parcel.ag/tos/vendor",
    agreementNotice:
      "By continuing you accept the Parcel Hauling Terms of Service.",
    buttonText: "Start Hauling",
    buttonRouteName: "vendor-hauling",
    learnMoreRouteName: "vendor-hauling",
  },
  // REQUIRE FEATURE FLAG TO BE ENABLED MANUALLY FOR THIS LOB
  // {
  //   serviceType: "agronomic-advice",
  //   featureFlag: "vp-consulting",
  //   iconName: "ClipboardCheckIcon",
  //   title: "Agronomic Consulting",
  //   description:
  //     "Do you have something to say? Collaborate with your customers. Provide recommendations to the growers you provide agronomic services for.",
  //   highlights: [
  //     {
  //       highlightTitle: "Pricing",
  //       highlightDescription:
  //         "We bill you annually for each accepted customer. See terms for pricing details.",
  //     },
  //     {
  //       highlightTitle: "Territory",
  //       highlightDescription:
  //         "Make your service available only to customers in your service area.",
  //     },
  //     {
  //       highlightTitle: "Recommendations",
  //       highlightDescription:
  //         "Start Consulting. Make recommendations to growers in your territory and get paid for your advice",
  //     },
  //   ],
  //   agreementLink: "https://parcel.ag/tos/vendor",
  //   agreementNotice:
  //     "By continuing you accept the Parcel Agronomic Consulting Terms of Service. ",
  //   buttonText: "Start Consulting",
  //   buttonRouteName: "vendor-activities",
  //   learnMoreRouteName: "vendor-activities",
  // },
  // {
  //   serviceType: "products",
  //   featureFlag: "vp-products",
  //   iconName: "ShoppingBagIcon",
  //   title: "Products",
  //   description: "List individual products and one-time services for sale.",
  //   highlights: [
  //     {
  //       highlightTitle: "Pricing",
  //       highlightDescription:
  //         "Parcel earns a small commission for each completed sale. See terms for pricing details.",
  //     },
  //   ],
  //   agreementLink: "https://parcel.ag/tos/vendor",
  //   agreementNotice:
  //     "By continuing you accept the Parcel Products Terms of Service. ",
  //   buttonText: "Start Selling Products",
  //   buttonRouteName: "vendor-products-dashboard",
  //   learnMoreRouteName: "vendor-products-dashboard",
  // },

  // REQUIRE FEATURE FLAG TO BE ENABLED MANUALLY FOR THIS LOB
  // {
  //   serviceType: "carbon",
  //   featureFlag: "vp-carbon",
  //   iconName: "PresentationChartLineIcon",
  //   title: "Carbon",
  //   description:
  //     "Provide carbon scoring and analysis services to your customers.",
  //   highlights: [
  //     {
  //       highlightTitle: "Pricing",
  //       highlightDescription:
  //         "You pay Parcel an annual fee for each grower we help you generate scores for. See terms for pricing details.",
  //     },
  //   ],
  //   agreementLink: "https://parcel.ag/tos/vendor",
  //   agreementNotice:
  //     "By continuing you accept the Parcel Products Terms of Service. ",
  //   buttonText: "Start Selling Carbon Services",
  //   buttonRouteName: "vendor-products-dashboard",
  //   learnMoreRouteName: "vendor-products-dashboard",
  // },
];

export default {
  state() {
    return {
      linesOfBusiness: lobs,
      initLobs: inits,
    };
  },
  getters: {
    getLobByServiceType: (state) => (serviceType) => {
      return state.initLobs.find(
        (lobInit) => lobInit.serviceType === serviceType,
      );
    },
  },
};

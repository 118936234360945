import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";
import { formatStatus } from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      growerOpportunities: [],
    };
  },
  mutations: {
    SET_GROWER_OPPORTUNITIES(state, growerOpportunityData) {
      state.growerOpportunities = growerOpportunityData;
    },
    UPDATE_GROWER_OPPORTUNITY(state, growerOpportunityObject) {
      state.growerOpportunities = state.growerOpportunities.filter(
        function (obj) {
          return obj.id !== growerOpportunityObject.id;
        },
      );
      state.growerOpportunities.push(growerOpportunityObject);
    },
    RESET_GROWER_OPPORTUNITIES(state) {
      state.growerOpportunities = [];
    },
  },
  actions: {
    async getGrowerOpportunities({ commit }) {
      const data = await client.grower.serviceOpportunities.read();
      if (data) {
        // console.log("growerOpportunities", data);
        commit("SET_GROWER_OPPORTUNITIES", data.opportunities);
      }
    },
    async getOneGrowerOpportunity(_, opportunityId) {
      const data =
        await client.grower.serviceOpportunities.readOne(opportunityId);
      return data;
    },
    async updateGrowerOpportunity({ commit }, growerOpportunity) {
      const data = await client.grower.serviceOpportunities.update(
        growerOpportunity.id,
        growerOpportunity,
      );
      if (data) {
        commit("UPDATE_GROWER_OPPORTUNITY", data);
        this.dispatch("getGrowerOpportunities");
      }
    },
  },
  getters: {
    // UNIVERSAL GETTER
    getGrowerOpportunities:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let growerOpportunities = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.growerOpportunities,
        );
        return growerOpportunities;
      },
    getEnrichedGrowerOpportunities:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let agreements = rootGetters.getAllGrowerQuotes;
        // console.log(agreements)
        let initalRecs = state.growerOpportunities;
        initalRecs.forEach((rec) => {
          let agreement = agreements.find(
            (agreement) => agreement.id === parseInt(rec.agreement),
          );
          rec.agreement_object = agreement;
        });
        let enrichedRecs = initalRecs.map((rec) => ({
          ...rec,
          status: formatStatus(rec.status),
          service_title: rec.agreement_object?.service_title,
          seller_name: rec.agreement_object?.seller_details.name,
        }));
        // for rec in enrichedRecs delete rec.agreement_object
        let readyRecs = enrichedRecs.map((rec) => {
          delete rec.agreement_object;
          return rec;
        });
        let enrichedGrowerOpportunities = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          readyRecs,
        );
        return enrichedGrowerOpportunities;
      },
  },
};

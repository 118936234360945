<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="changeTab">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Needs Action
            <span
              v-if="needsActionQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ needsActionQuotes }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Awaiting Response
            <span
              v-if="awaitingResponseQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ awaitingResponseQuotes }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Active
            <span
              v-if="inProgressQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
              ]"
              >{{ inProgressQuotes }}</span
            >
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Closed
            <!-- <span
              v-if="closedQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ closedQuotes }}</span
            > -->
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Completed
            <!-- <span
              v-if="completedQuotes"
              :class="[
                selected
                  ? 'bg-indigo-100 text-indigo-600'
                  : 'bg-gray-200 text-gray-900',
                'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block',
              ]"
              >{{ completedQuotes }}</span
            > -->
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <!-- <needs-action-grower-quotes-table /> -->
        <all-grower-quotes-table :tabFilters="needsActionFilter" />
      </TabPanel>
      <TabPanel class="pt-10">
        <!-- <awaiting-response-grower-quotes-table /> -->
        <all-grower-quotes-table :tabFilters="awaitingResponseFilter" />
      </TabPanel>
      <TabPanel class="pt-10">
        <!-- <in-progress-grower-quotes-table /> -->
        <all-grower-quotes-table :tabFilters="openFilter" />
      </TabPanel>
      <TabPanel class="pt-10">
        <!-- <closed-grower-quotes-table /> -->
        <all-grower-quotes-table :tabFilters="closedFilter" />
      </TabPanel>
      <TabPanel class="pt-10">
        <!-- <completed-grower-quotes-table /> -->
        <all-grower-quotes-table :tabFilters="completedFilter" />
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";

// import NeedsActionGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/NeedsActionGrowerQuotesTable.vue";
// import AwaitingResponseGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/AwaitingResponseGrowerQuotesTable.vue";
// import ClosedGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/ClosedGrowerQuotesTable.vue";
// import CompletedGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/CompletedGrowerQuotesTable.vue";
// import InProgressGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/InProgressGrowerQuotesTable.vue";
import AllGrowerQuotesTable from "@/layouts/grower-portal/buy/quotes/AllGrowerQuotesTable.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // NeedsActionGrowerQuotesTable,
    // AwaitingResponseGrowerQuotesTable,
    // ClosedGrowerQuotesTable,
    // CompletedGrowerQuotesTable,
    // InProgressGrowerQuotesTable,
    AllGrowerQuotesTable,
  },
  data() {
    return {
      selectedTab: 0,
      awaitingResponseFilter: function (o) {
        if (o.status === "buyer_submitted" || o.status === "buyer_rejected") {
          return true;
        } else {
          return false;
        }
      },
      openFilter: function (o) {
        if (o.status === "buyer_confirmed" || o.status === "buyer_ordered") {
          return true;
        } else {
          return false;
        }
      },
      closedFilter: function (o) {
        if (
          o.status === "buyer_closed" ||
          o.status === "seller_closed" ||
          o.status === "seller_canceled"
        ) {
          return true;
        } else {
          return false;
        }
      },
      completedFilter: function (o) {
        if (o.status === "seller_completed") {
          return true;
        } else {
          return false;
        }
      },
      needsActionFilter: function (o) {
        if (o.status === "seller_responded") {
          return true;
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    needsActionQuotes() {
      let numQuotes = this.$store.getters.getNeedsActionGrowerQuotes.length;
      if (numQuotes > 0) {
        return numQuotes;
      } else {
        return null;
      }
    },
    awaitingResponseQuotes() {
      let numQuotes =
        this.$store.getters.getAwaitingResponseGrowerQuotes.length;
      if (numQuotes > 0) {
        return numQuotes;
      } else {
        return null;
      }
    },
    inProgressQuotes() {
      let numQuotes = this.$store.getters.getInProgressGrowerQuotes.length;
      if (numQuotes > 0) {
        return numQuotes;
      } else {
        return null;
      }
    },
    closedQuotes() {
      let numQuotes = this.$store.getters.getClosedGrowerQuotes.length;
      if (numQuotes > 0) {
        return numQuotes;
      } else {
        return null;
      }
    },
    completedQuotes() {
      let numQuotes = this.$store.getters.getCompletedGrowerQuotes.length;
      if (numQuotes > 0) {
        return numQuotes;
      } else {
        return null;
      }
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>

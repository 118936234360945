<template>
  <text-input
    v-model="searchTerm"
    input-title="What do you want to do?"
    :wideItem="true"
    placeholder="Search processes..."
  />
  <ActionCardProcess
    v-for="process in filteredProcesses"
    :key="process.title"
    :title="process.title"
    :description="process.description"
    @closeProcess="removeActiveProcess"
  >
    <template #gridItems>
      <ActionCard
        v-for="(action, index) in process.actions"
        :key="action.routeObject"
        :title="action.title"
        :routeObject="action.routeObject"
        :tags="action.tags"
        :notificationCount="
          $store.getters.actionsGrowerNotificationFactory(
            action.notificationType,
          )
        "
        :stepNumber="index + 1"
        :stepGreen="action.active"
        @clickOnAction="setActiveProcess(process, index)"
      />
    </template>
  </ActionCardProcess>
</template>

<script>
import ActionCard from "@/components/cards/ActionCard.vue";
import ActionCardProcess from "@/components/cards/ActionCardProcess.vue";
import TextInput from "@/components/form/TextInput.vue";

export default {
  components: { ActionCard, ActionCardProcess, TextInput },
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    processes() {
      return this.$store.getters.getAllGrowerActionsProcesses;
    },
    filteredProcesses() {
      if (!this.searchTerm) {
        return this.processes;
      } else {
        // return this.actions.filter((action) =>
        //   action.title.toLowerCase().includes(this.searchTerm.toLowerCase())
        // );
        return this.processes.filter((process) => {
          return process.title
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase());
        });
      }
    },
  },
  methods: {
    setActiveProcess(process, index) {
      this.$store.dispatch("setGrowerActionsActiveProcess", process);
      this.$store.dispatch("setActiveProcessGrowerActionToActive", index);
    },
    removeActiveProcess() {
      this.$store.dispatch("clearGrowerActionsActiveProcess");
    },
  },
};
</script>

<template>
  <div class="sm:col-span-6">
    <stats-row :stats="allocationData.stats" />

    <table-card v-if="dataIsLoaded && !readOnly">
      <template #header>
        <table-head
          :columns="[
            { name: 'farm', label: 'Farm' },
            { name: 'field', label: 'Field', filtered: false },
            {
              name: 'crop_type',
              label: 'Crop',
            },
            { name: 'details.variety', label: 'Variety' },
            {
              name: 'acres',
              label: 'Total Acres',
              filtered: false,
            },
            // {
            //   name: 'details.tpa',
            //   label: 'Estimated Yield',
            //   filtered: false,
            // },
            {
              name: 'currentOrderAcresAllocated',
              label: 'Acres Allocated to Current Order',
              filtered: false,
            },
            {
              name: 'remainingAcres',
              label: 'Available Acres',
              filtered: false,
            },
            { name: 'actions', label: '', filtered: false },
          ]"
          :collection="crops"
          @update:derived-collection="derivedCrops = $event"
        >
        </table-head>
      </template>
      <tbody>
        <tr v-for="crop in derivedCrops" :key="crop.id">
          <table-data td-label="Farm">
            {{ crop.farm }}
          </table-data>
          <table-data td-label="Field">
            <router-link
              :to="{
                name: 'single-field',
                params: { fieldId: crop.fieldId },
              }"
              class="flex text-violet-500 hover:text-twilight-900 hover:underline sm:pr-6 print:hidden"
            >
              {{ crop.field }}
              <!--<external-link-icon class="ml-2 h-3 w-3" />-->
            </router-link>
          </table-data>
          <table-data td-label="Crop">
            {{ crop.crop_type }}
          </table-data>
          <table-data td-label="Variety">
            {{ crop.details?.variety }}
          </table-data>
          <table-data td-label="Total Acres">
            {{ crop.acres }}
          </table-data>
          <!-- <table-data td-label="Estimated Yield">
          <input
            :step="0.01"
            :value="crop.details.tpa"
            :disabled="crop.historical"
            @blur="updateEstimatedTPA($event.target.value, crop.id)"
            class="block h-8 w-full rounded-md border border-gray-300 px-2 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
            :class="crop.historical ? 'bg-gray-200 ' : 'bg-white'"
          />
        </table-data> -->
          <table-data td-label="Acres Allocated to Current Order">
            <input
              v-if="crop.currentOrderAllocationId"
              :step="0.01"
              :value="crop.currentOrderAcresAllocated"
              :disabled="crop.historical"
              @blur="
                updateAllocationAcres(
                  $event.target.value,
                  crop.currentOrderAllocationId,
                )
              "
              class="block h-8 w-full rounded-md border border-gray-300 px-2 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
              :class="crop.historical ? 'bg-gray-200 ' : 'bg-white'"
            />
            <span v-else>
              {{ crop.currentOrderAcresAllocated.toFixed(1) }}
            </span>
          </table-data>
          <table-data td-label="Available Acres">
            {{ crop.remainingAcres ? crop.remainingAcres.toFixed(1) : 0 }}
          </table-data>
          <!-- <table-data td-label="Est Allocated Qty">
          {{ crop.estimatedAllocatedQty }}
        </table-data> -->
          <table-data td-label="Control Allocation to Order">
            <button
              v-if="crop.currentOrderAllocationId"
              @click="removeAllocation(crop)"
              class="ml-1 cursor-pointer rounded-lg border border-gray-300 bg-red-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <OutlineHeroIcon
                name="XIcon"
                classProp="w-5 h-5 text-gray-700"
                aria-hidden="true"
              />
            </button>
            <button
              v-else
              @click="addAllocation(crop)"
              class="ml-1 cursor-pointer rounded-lg border border-gray-300 bg-green-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <OutlineHeroIcon
                name="PlusIcon"
                classProp="w-5 h-5 text-gray-700"
                aria-hidden="true"
              />
            </button>
          </table-data>
        </tr>
      </tbody>
    </table-card>
    <table-card v-else>
      <template #header>
        <table-head
          :columns="[
            { name: 'farm', label: 'Farm' },
            { name: 'field', label: 'Field', filtered: false },
            {
              name: 'crop_type',
              label: 'Crop',
            },
            { name: 'details.variety', label: 'Variety' },
            {
              name: 'acres',
              label: 'Total Acres',
              filtered: false,
            },
            {
              name: 'currentOrderAcresAllocated',
              label: 'Acres Allocated to Current Order',
              filtered: false,
            },
            {
              name: 'validated',
              label: 'Validated Against All Premiums',
              filtered: false,
            },
          ]"
          :collection="readOnlyCrops"
          @update:derived-collection="derivedReadOnlyCrops = $event"
        >
        </table-head>
      </template>
      <tbody>
        <tr v-for="crop in derivedReadOnlyCrops" :key="crop.id">
          <table-data td-label="Farm">
            {{ crop.farm }}
          </table-data>
          <table-data td-label="Field">
            <router-link
              :to="{
                name: 'single-field',
                params: { fieldId: crop.fieldId },
              }"
              class="flex text-violet-500 hover:text-twilight-900 hover:underline sm:pr-6 print:hidden"
            >
              {{ crop.field }}
            </router-link>
          </table-data>
          <table-data td-label="Crop">
            {{ crop.crop_type }}
          </table-data>
          <table-data td-label="Variety">
            {{ crop.details?.variety }}
          </table-data>
          <table-data td-label="Total Acres">
            {{ crop.acres }}
          </table-data>
          <table-data td-label="Acres Allocated to Current Order">
            {{ crop.currentOrderAcresAllocated.toFixed(1) }}
          </table-data>
          <table-data td-label="Validated Against All Premiums">
            <div
              class="ml-1 rounded-lg border border-gray-300 bg-green-300 p-1 text-left shadow-sm sm:text-sm"
              v-if="
                crop.currentOrderValidatedPremiums.length ===
                crop.currentOrderUniquePremiums.length
              "
            >
              <OutlineHeroIcon
                name="CheckIcon"
                classProp="w-5 h-5 text-gray-700"
                aria-hidden="true"
              />
            </div>
            <div
              class="ml-1 cursor-pointer rounded-lg border border-gray-300 bg-red-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
              v-else
              @click="navigateToOrderPremiums"
            >
              <!-- Make the Edit Validation Text inline with the OutlineHeroIcon component -->
              <OutlineHeroIcon
                name="XIcon"
                classProp="w-5 h-5 text-gray-700 inline"
                aria-hidden="true"
              />
              {{ " " }} Edit Validation
            </div>
          </table-data>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>
<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
// import { ExternalLinkIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";
import StatsRow from "@/components/cards/StatsRow.vue";
import { tonsToBushels } from "@/components/composables/convertUnits";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    OutlineHeroIcon,
    // ExternalLinkIcon,
    StatsRow,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateAllocationData"],
  data() {
    return {
      derivedCrops: [],
      derivedReadOnlyCrops: [],
      timesCropsCalculated: 0,
      toast: useToast(),
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    allocations() {
      return this.$store.getters.getGrowerAllocationsByOrderId(this.orderId);
    },
    order() {
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    offer() {
      return this.$store.getters.getGrowerMarketOfferById(this.order.offer);
    },
    crops() {
      let crops = structuredClone(this.$store.getters.getExpandedCrops({}));
      crops.forEach((crop) => {
        let allocation = this.allocations.find(
          (allocation) => allocation.crop === crop.id,
        );
        if (allocation) {
          crop.currentOrderAllocationId = allocation?.id;
          crop.currentOrderAcresAllocated = allocation?.acres;
          crop.currentOrderUniquePremiums = this.offer?.premiums;
          // filter the allocation.validations_met array to only include ids that are within the this.offer.premiums array of objects
          crop.currentOrderValidatedPremiums =
            allocation.validations_met.filter((validation) =>
              this.offer.premiums.find((premium) => premium.id === validation),
            );
        } else {
          crop.currentOrderAllocationId = null;
          crop.currentOrderAcresAllocated = 0;
        }
      });
      let cropHistory = this.$store.getters.getExpandedCropHistory({});
      for (let crop of cropHistory) {
        let allocation = this.allocations.find(
          (allocation) => allocation.crop === crop.id,
        );
        if (allocation) {
          crop.currentOrderAllocationId = allocation.id;
          crop.currentOrderAcresAllocated = allocation.acres;
          crop.historical = true;
          crops.push(crop);
        }
      }
      let orderedCrops = crops.sort(function (x, y) {
        // sort by 'isAllocated' field first
        if (x.currentOrderAllocationId != y.currentOrderAllocationId) {
          return x.currentOrderAllocationId < y.currentOrderAllocationId
            ? 1
            : -1;
        }
      });
      return orderedCrops;
    },
    readOnlyCrops() {
      return this.crops.filter((crop) => crop.currentOrderAllocationId);
    },
    allocationCropTypes() {
      let cropTypes = [];
      for (let crop of this.crops) {
        if (
          crop.currentOrderAllocationId &&
          !cropTypes.includes(crop.crop_type)
        ) {
          cropTypes.push(crop.crop_type);
        }
      }
      return cropTypes.sort().join(", ");
    },
    sumAllocatedAcres() {
      return this.crops
        .reduce((acc, crop) => acc + crop.currentOrderAcresAllocated, 0)
        .toFixed(1);
    },
    estAllocatedQty() {
      // iterate through crops. If the crop has a currentOrderAllocationId then multiply the currentOrderAcresAllocated by the crop.details.tpa and add it to the sum
      let units = this.$store.getters.getGrowerCropOrderById(
        this.orderId,
      )?.units;
      let estTonsQty = 0;
      let acresQty = 0;
      for (let crop of this.crops) {
        if (crop.currentOrderAllocationId) {
          acresQty += crop.currentOrderAcresAllocated;
          let tpa = crop.details?.tpa;
          if (tpa) {
            estTonsQty += tpa * crop.currentOrderAcresAllocated;
          }
        }
      }
      let qty = estTonsQty; // default is estimate in tons
      if (units) {
        if (units.toLowerCase() == "bushels" || units.toLowerCase() == "bu") {
          let cropType = this.allocationCropTypes;
          qty = tonsToBushels(cropType, estTonsQty);
        }
        if (units.toLowerCase() == "acres") {
          qty = acresQty;
        }
      }
      return qty.toFixed(1);
    },
    estAllocatedQtyOld() {
      let tpaEstimates = [];
      let cropTypes = [];
      let units = this.$store.getters.getGrowerCropOrderById(
        this.orderId,
      )?.units;
      for (let crop of this.crops) {
        if (crop?.details?.tpa) {
          tpaEstimates.push(parseFloat(crop?.details?.tpa));
        }
        // if crop_type is not in the array of strings cropTypes, add it. Otherwise, skip it.
        if (!cropTypes.includes(crop.crop_type)) {
          cropTypes.push(crop.crop_type);
        }
      }
      if (tpaEstimates.length > 0) {
        let tons = this.median(tpaEstimates) * this.sumAllocatedAcres;
        let qty = tons; // default is estimate in tons
        if (units) {
          if (units.toLowerCase() == "bushels" || units.toLowerCase() == "bu") {
            // set cropType equal to the most common crop type in the array
            let cropType = cropTypes.sort(
              (a, b) =>
                cropTypes.filter((v) => v === a).length -
                cropTypes.filter((v) => v === b).length,
            )[0];
            qty = tonsToBushels(cropType, tons);
          }
        }
        return qty.toFixed(1);
      } else {
        return "Estimate at least one allocated field's TPA to receive an estimate";
      }
    },
    allocationData() {
      return {
        stats: [
          { name: "Crop Types", stat: this.allocationCropTypes },
          { name: "Allocated Acres", stat: this.sumAllocatedAcres },
          { name: "Est. Allocated Quantity", stat: this.estAllocatedQty },
        ],
        crop_types: this.allocationCropTypes,
        acres_allocated: this.sumAllocatedAcres,
        estimated_qty: this.estAllocatedQty,
        count: this.allocations.length,
        anyZero: this.allocations.filter((a) => a.acres === 0).length > 0,
      };
    },
  },
  methods: {
    async addAllocation(crop) {
      let allocationToSubmit = {
        crop_order: this.orderId,
        crop: crop.id,
        acres: crop.remainingAcres,
      };
      await this.$store.dispatch("createGrowerAllocation", allocationToSubmit);
      if (!this.$store.getters.getApiError) {
        this.toast.success("Allocation Saved to Order!", {
          timeout: 750,
          hideProgressBar: true,
        });
      }
    },
    async updateAllocationAcres(inputValue, allocationId) {
      // console.log("updateAllocationAcres", inputValue, allocationId);
      await this.$store.dispatch("updateGrowerAllocation", {
        id: allocationId,
        acres: inputValue,
      });
      if (!this.$store.getters.getApiError) {
        this.toast.success("Allocation Updated on Order!", {
          timeout: 750,
          hideProgressBar: true,
        });
      }
    },
    removeAllocation(crop) {
      this.$store.dispatch("deleteGrowerAllocation", {
        id: crop.currentOrderAllocationId,
      });
      this.toast.success("Allocation Removed from Order!", {
        timeout: 750,
        hideProgressBar: true,
      });
    },
    // ADD THIS BACK IN AFTER UPDATING CROP YIELD FLOW
    // async updateEstimatedTPA(inputValue, cropId) {
    //   // await this.$store.dispatch("updateCropDetails", {
    //   //   id: cropId,
    //   //   tpa: tpa,
    //   // });
    //   // if (!this.$store.getters.getApiError) {
    //   //   this.toast.success("TPA Estimate Updated!", {
    //   //     timeout: 750,
    //   //     hideProgressBar: true,
    //   //   });
    //   // }
    //   console.log("updateEstimatedTPA", {
    //     id: cropId,
    //     tpa: inputValue,
    //   });
    // },
    median(values) {
      if (values.length === 0) throw new Error("No inputs");
      values.sort(function (a, b) {
        return a - b;
      });
      var half = Math.floor(values.length / 2);
      if (values.length % 2) return values[half];
      return (values[half - 1] + values[half]) / 2.0;
    },
    navigateToOrderPremiums() {
      this.$router.push({
        name: "grower-portal-offer-order-premiums",
        params: { orderId: this.orderId },
      });
    },
  },
  watch: {
    allocationData: {
      handler: function (newVal) {
        this.$emit("updateAllocationData", newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
  <page-card headingTitle="View Recommendation">
    <!-- <template #buttonArea>
      <base-button
        v-if="
          !['seller_closed', 'buyer_confirmed', 'buyer_rejected'].includes(
            currentRecommendation?.status
          )
        "
        buttonText="Archive"
        :redButton="true"
        @buttonClick="archiveOpportunity"
      />
    </template> -->
    <template #cardDetails>
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="activeAgreement?.service_title"
        :wideItem="true"
        inputTitle="Listing"
      />

      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          Customer
          <router-link
            v-if="activeAgreement"
            :to="{
              name: 'vendor-single-quote',
              params: { quoteId: activeAgreement?.id },
            }"
            class="text-twilight-600 hover:text-twilight-900"
            >(View Agreement)</router-link
          >
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="activeAgreement?.buyer_details?.name"
          :wideItem="true"
        />
      </div>
      <text-area
        :wideItem="true"
        inputTitle="Memo"
        :display-only="true"
        :display-only-value="currentRecommendation?.memo"
      />
      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          More Details Link
          <a
            :href="currentRecommendation?.more_info"
            class="text-twilight-600 hover:text-twilight-900"
            >(View)</a
          >
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentRecommendation?.more_info"
          :wideItem="true"
        />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Order Details
              </button>
            </Tab>
            <!-- <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentRecommendation.type === 'task'"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Task Details
              </button>
            </Tab> -->
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentRecommendation?.shared_assets?.fields?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Fields
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentRecommendation?.shared_assets?.equipment?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Equipment
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <order-object-interface
              :product="selectedProduct"
              :active-agreement-id="activeAgreement?.id"
              :order="currentRecommendation"
              :read-only="true"
            />
            <shared-fields
              v-if="currentRecommendation?.shared_assets?.fields.length === 1"
              :fields="currentRecommendation?.shared_assets?.fields"
              :locations="currentRecommendation?.shared_assets?.locations"
            />
            <shared-equipment
              v-if="
                currentRecommendation?.shared_assets?.equipment.length === 1
              "
              :equipment="currentRecommendation?.shared_assets?.equipment"
            />
          </TabPanel>
          <!-- <TabPanel
            class="text-sm text-gray-500"
            v-if="currentRecommendation.type === 'task'"
          >
            <p>Task Details</p>
          </TabPanel> -->
          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentRecommendation?.shared_assets?.fields?.length > 0"
          >
            <shared-fields
              :fields="currentRecommendation?.shared_assets?.fields"
            />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentRecommendation?.shared_assets?.equipment?.length > 0"
          >
            <shared-equipment
              :equipment="currentRecommendation?.shared_assets?.equipment"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
// import DeleteModal from "@/components/modals/DeleteModal.vue";
// import DateTimeInput from "@/components/form/DateTimeInput.vue";
// import CurrencyInput from "@/components/form/CurrencyInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";
// import DateInput from "@/components/form/DateInput.vue";
// import CheckboxInput from "@/components/form/CheckboxInput.vue";
// import HorizontalPanelSteps from "@/components/progress/HorizontalPanelSteps.vue";
import { useToast } from "vue-toastification";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";
import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";
// import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
// import HaulingOrderDetails from "@/layouts/common/orders/order-details/HaulingOrderDetails.vue";
// import DefaultOrderDetails from "@/layouts/common/orders/order-details/DefaultOrderDetails.vue";
import OrderObjectInterface from "@/layouts/common/orders/OrderObjectInterface.vue";
// import CompleteOrderForm from "@/layouts/common/orders/CompleteOrderForm.vue";

export default {
  components: {
    PageCard,
    // BaseButton,
    VariableStringTypeInput,
    // DeleteModal,
    // DateTimeInput,
    TextArea,
    // CurrencyInput,
    // NumberInput,
    // DateInput,
    // CheckboxInput,
    // HorizontalPanelSteps,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    SharedEquipment,
    SharedFields,
    // ChatSingleView,
    // HaulingOrderDetails,
    // DefaultOrderDetails,
    // CompleteOrderForm,
    OrderObjectInterface,
  },
  data() {
    const toast = useToast();
    return {
      modalIsActive: false,
      // currentRecommendation: null,
      pendingVendorOrderAttachments: [],
      toast,
    };
  },
  computed: {
    currentRecommendation() {
      let currentRec = this.$store.getters.getEnrichedVendorOpportunities({
        filter: { id: parseInt(this.$route.params.oppId) },
      });
      // Careful with universal getter - always returns an array
      return currentRec[0];
    },
    activeAgreement() {
      if (!this.currentRecommendation) return null;
      return this.$store.getters.getVendorQuoteById(
        parseInt(this.currentRecommendation.agreement),
      );
    },
    selectedProduct() {
      if (!this.activeAgreement) return null;
      return this.$store.getters.getVendorProductById(
        parseInt(this.activeAgreement.service),
      );
    },
    fields() {
      return this.currentRecommendation.shared_assets.fields;
    },
    equipment() {
      return this.currentRecommendation.shared_assets.equipment;
    },
    locations() {
      return this.currentRecommendation.shared_assets.locations;
    },

    cancelModalTitle() {
      if (this.currentRecommendation?.status === "buyer_confirmed") {
        return "Cancel Order";
      } else {
        return "Close Order";
      }
    },
    cancelModalDescription() {
      if (this.currentRecommendation?.status === "buyer_confirmed") {
        return "Are you sure you want to cancel this confirmed order?";
      } else {
        return "Are you sure you want to close this order inquiry?";
      }
    },
    productOrderCategory() {
      switch (this.currentProduct?.category) {
        case "Hauling":
          return "HaulingOrderDetails";
        case "Input":
          return "InputOrderDetails";
        default:
          return "DefaultOrderDetails";
      }
    },
  },
  methods: {
    // async archiveRecommendation() {
    //   this.currentRecommendation.total_price = 0;
    //   this.$store.dispatch("updateVendorOrder", {
    //     id: this.currentRecommendation?.id,
    //     status: "seller_canceled",
    //   });
    //   this.pendingVendorOrderAttachments = [];
    //   this.toast.success("Order Concluded", {
    //     timeout: 1500,
    //     hideProgressBar: true,
    //   });
    //   this.$router.push({ name: "vendor-orders-all" });
    // },

    openFileTab(url) {
      window.open(url, "_blank");
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
  },
  watch: {
    // orderId: {
    //   // handler(value) or handler(newValue, oldValue)
    //   async handler() {
    //     // console.log("the fieldId param changed to ", value, " from ", oldValue)
    //     if (this.orderId === undefined) return;
    //     let order = this.order;
    //     if (!order)
    //       order = await this.$store.getters.getVendorOrderById(this.orderId);
    //     console.log("order", order, this.orderId);
    //     let agreement = this.$store.getters.getVendorQuoteById(order.agreement);
    //     console.log("agreement", agreement);
    //     this.agreement = agreement;
    //     let product = this.$store.getters.getVendorProductById(
    //       agreement.service
    //     );
    //     this.currentProduct = product;
    //     // let data = await client.grower.services.readOne(order);
    //     // this.currentProduct = data.data;
    //     // const order = this.$store.getters.getVendorOrderById(value);
    //     // console.log("order", order);
    //     this.currentRecommendation = order;
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    // },
    // orderId: {
    //   // handler(value) or handler(newValue, oldValue)
    //   async handler() {
    //     if (this.orderId === undefined) return;
    //     let order = this.order;
    //     if (!order)
    //       order = await this.$store.getters.getVendorOrderById(this.orderId);
    //     console.log("order", order, this.orderId);
    //     let agreement = this.$store.getters.getVendorQuoteById(order.agreement);
    //     console.log("agreement", agreement);
    //     this.activeAgreement = agreement;
    //     let product = this.$store.getters.getVendorProductById(
    //       agreement.service
    //     );
    //     this.currentProduct = product;
    //     // let data = await client.grower.services.readOne(order);
    //     // this.currentProduct = data.data;
    //   },
    //   // this will cause the handler to be run immediately on component creation
    //   immediate: true,
    // },
  },
};

// computed: {
//   currentRecommendation() {
//     let currentRec = this.$store.getters.getVendorRecommendations({
//       filter: { id: parseInt(this.$route.params.recId) },
//     });
//     // Careful with universal getter - always returns an array
//     return currentRec[0];
//   },
//   activeAgreement() {
//     return this.$store.getters.getVendorQuoteById(
//       parseInt(this.currentRecommendation.agreement)
//     );
//   },
//   selectedProduct() {
//     return this.$store.getters.getVendorProductById(
//       parseInt(this.activeAgreement.service)
//     );
//   },
//   fields() {
//     return this.currentRecommendation.shared_assets.fields;
//   },
//   equipment() {
//     return this.currentRecommendation.shared_assets.equipment;
//   },
//   locations() {
//     return this.currentRecommendation.shared_assets.locations;
//   },
// },
</script>

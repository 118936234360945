<template>
  <modal
    :show="showErrorModal"
    @closeModal="showErrorModal = false"
    title="Error"
  >
    <div class="text-red-500">
      <p>
        {{ errorMessage }}
      </p>
    </div>
    <button
      @click="showErrorModal = false"
      class="mt-6 w-12 rounded border bg-twilight-700 text-white"
    >
      Close
    </button>
  </modal>
  <modal :show="showModal" @closeModal="showModal = false" title="Add Variant">
    <div class="">
      <text-input
        :wideItem="true"
        placeholder="Product Variant Description"
        inputTitle=""
        :displayOnly="false"
        v-model="variantName"
        @keyup.enter="addVariant"
      />
    </div>
    <button
      @click="addVariant"
      class="mt-6 w-12 rounded border bg-twilight-700 text-white"
    >
      Add
    </button>
  </modal>

  <hr class="mt-2 w-full" />
  <div class="flex">
    <div class="mt-4 font-bold">
      Variants
      <ToolTip
        helpText="Use variants where the user picks one option of several, such as size (sm, m, l)"
        helpTitle=""
      />
    </div>
    <button
      @click="showModal = true"
      class="ml-8 mt-3 h-8 w-12 rounded border bg-twilight-700 text-white"
    >
      + Add
    </button>
  </div>
  <div
    class="mb-4 mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <!-- <number-input
      :wideItem="true"
      inputTitle="Sets of Variants"
      :step="1"
      v-model="numberOfVariantSets"
      :required="true"
    /> -->
    <div
      v-for="(l, index) in variantList"
      :key="l"
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
    >
      <!-- <text-input
        :wideItem="true"
        :model-value="l.label"
        @update:model-value="
          (newVal) => {
            variantList[index].label = newVal;
          }
        "
        :inputTitle="`Set ${index + 1} Name`"
      /> -->
      <!-- "`Set ${index + 1} Variants`" -->
      <list-input
        :inputTitle="l.label"
        :taglist="l.choices"
        placeholder="Add variation..."
        @updateTags="
          (tagArray) => {
            variantList[index].choices = tagArray;
          }
        "
      />
      <button @click="variantList.splice(index, 1)" class="mt-6 h-10">
        <trash-icon class="h-6 w-6" />
      </button>
    </div>
  </div>
</template>

<script>
// import NumberInput from "@/components/form/NumberInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import ListInput from "@/components/form/ListInput.vue";
import { TrashIcon } from "@heroicons/vue/outline";
import Modal from "@/components/modals/PopupModal.vue";
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  components: {
    // NumberInput,
    TextInput,
    ListInput,
    TrashIcon,
    ToolTip,
    Modal,
  },
  props: {
    initialVariantList: {
      type: Array,
      default: () => [{ label: "", choices: [] }],
    },
  },
  emits: ["updateVariantList"],
  data() {
    return {
      numberOfVariantSets: this.setInitialNumberOfVariantSets(),
      variantList: this.convertInitialVariantListFormat(
        this.initialVariantList,
      ),
      variantName: "",
      showModal: false,
      errorMessage: "",
      showErrorModal: false,
    };
  },
  computed: {},
  methods: {
    addVariant() {
      if (this.variantList.find((obj) => obj.label === this.variantName)) {
        this.showModal = false;
        this.errorMessage = "Variant already exists";
        this.showErrorModal = true;
        return;
      }
      if (this.variantName.length === 0) {
        this.showModal = false;
        this.errorMessage = "Please provide a variant name";
        this.showErrorModal = true;
        return;
      }
      this.variantList.push({ label: this.variantName, choices: [] });
      this.showModal = false;
      // this.numberOfVariantSets++;
    },
    convertInitialVariantListFormat() {
      // for each object in this.initialVariantList, map the choices array (an array of objects with format {name: string}) to an array of strings
      let finalVal = this.initialVariantList.map((obj) => {
        return {
          label: obj.label,
          choices: obj.choices.map((choice) => {
            return choice.name;
          }),
        };
      });
      return finalVal;
    },
    setInitialNumberOfVariantSets() {
      let numSets = 0;
      if (this.initialVariantList.length >= 1) {
        numSets = this.initialVariantList.length;
      }
      return numSets;
    },
  },
  watch: {
    // numberOfVariantSets() {
    //   if (this.variantList.length < this.numberOfVariantSets) {
    //     while (this.variantList.length < this.numberOfVariantSets) {
    //       this.variantList.push({ label: "", choices: [] });
    //     }
    //   } else if (this.variantList.length > this.numberOfVariantSets) {
    //     while (this.variantList.length > this.numberOfVariantSets) {
    //       this.variantList.pop();
    //     }
    //   }
    // },
    variantList: {
      handler: function (newVal) {
        // for each object in newVal, map the choices array (an array of strings) to an array of objects wwith format {name: string}
        let finalVal = newVal.map((obj) => {
          return {
            label: obj.label,
            choices: obj.choices.map((choice) => {
              return { name: choice };
            }),
          };
        });
        this.$emit("updateVariantList", finalVal);
      },
      deep: true,
    },
  },
};
</script>

import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";
import { formatStatus } from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      vendorOpportunities: [],
    };
  },
  mutations: {
    SET_VENDOR_OPPORTUNITIES(state, vendorOpportunityData) {
      state.vendorOpportunities = vendorOpportunityData;
    },
    ADD_VENDOR_OPPORTUNITY(state, vendorOpportunityObject) {
      const result = state.vendorOpportunities.find(
        (vendorOpportunity) =>
          vendorOpportunity.id === parseInt(vendorOpportunityObject.id),
      );
      if (!result) state.vendorOpportunities.push(vendorOpportunityObject);
    },
    UPDATE_VENDOR_OPPORTUNITY(state, vendorOpportunityObject) {
      state.vendorOpportunities = state.vendorOpportunities.filter(
        function (obj) {
          return obj.id !== vendorOpportunityObject.id;
        },
      );
      state.vendorOpportunities.push(vendorOpportunityObject);
    },
    RESET_VENDOR_OPPORTUNITIES(state) {
      state.vendorOpportunities = [];
    },
  },
  actions: {
    async getVendorOpportunities({ commit }) {
      const data = await client.vendor.serviceOpportunities.read();
      if (data) {
        // console.log("vendorOpportunities", data);
        commit("SET_VENDOR_OPPORTUNITIES", data.opportunities);
      }
    },
    async createVendorOpportunity({ commit }, vendorOpportunity) {
      const data = await client.vendor.serviceOpportunities.create(
        null,
        vendorOpportunity,
      );
      if (data) {
        commit("ADD_VENDOR_OPPORTUNITY", data);
        this.dispatch("getVendorOpportunities");
        return data;
      }
    },
    async updateVendorOpportunity({ commit }, vendorOpportunity) {
      const data = await client.vendor.serviceOpportunities.update(
        vendorOpportunity.id,
        vendorOpportunity,
      );
      if (data) {
        commit("UPDATE_VENDOR_OPPORTUNITY", data);
        this.dispatch("getVendorOpportunities");
      }
    },
  },
  getters: {
    // UNIVERSAL GETTER
    getVendorOpportunities:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let vendorOpportunities = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.vendorOpportunities,
        );
        return vendorOpportunities;
      },
    getEnrichedVendorOpportunities:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let agreements = rootGetters.getAllVendorQuotes;
        // console.log(agreements)
        let initalRecs = state.vendorOpportunities;
        initalRecs.forEach((rec) => {
          let agreement = agreements.find(
            (agreement) => agreement.id === parseInt(rec.agreement),
          );
          rec.agreement_object = agreement;
        });
        let enrichedRecs = initalRecs.map((rec) => ({
          ...rec,
          status: formatStatus(rec.status),
          service_title: rec.agreement_object?.service_title,
          buyer_name: rec.agreement_object?.buyer_details.name,
        }));
        // for rec in enrichedRecs delete rec.agreement_object
        let readyRecs = enrichedRecs.map((rec) => {
          delete rec.agreement_object;
          return rec;
        });
        let enrichedVendorOpportunities = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          readyRecs,
        );
        return enrichedVendorOpportunities;
      },
  },
};

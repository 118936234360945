<template>
  <div class="grid grid-cols-1 gap-4 lg:col-span-2">
    <div id="chart" class="object-center">
      <apexchart
        :type="action.chartOptions.chart.type"
        :options="action.chartOptions"
        :series="action.series"
      ></apexchart>
    </div>
    <p class="mt-2 text-sm text-gray-800">
      {{ action.blurb }}
    </p>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      action: {},
    };
  },
  created() {
    let tasks = this.$store.getters.getActivities();
    let fields = this.$store.state.fields.fields;

    // Activity Report default chart (acres per activity category by status)
    let task_category = {}; // task_category:{assigned:acres, completed:acres, total:acres}
    tasks.forEach((task) => {
      let key = task.category;
      if (task.field == null) return;
      //console.log("task", task);
      let acres = this.$store.getters.getFieldById(task.field)?.properties
        ?.acres;
      //console.log("acres", this.$store.getters.getFieldById(task.field), acres);
      if (!acres) return;
      if (!task_category[key]) task_category[key] = {};
      if (!task_category[key][task.status]) {
        // console.log("new key", key, task.status);
        task_category[key][task.status] = Math.round(acres);
      } else {
        task_category[key][task.status] += Math.round(acres);
        // console.log(
        //   "existing key",
        //   key,
        //   task.status,
        //   acres,
        //   task_category[key][task.status]
        // );
      }
    });
    let task_planned = [];
    let task_assigned = [];
    let task_completed = [];
    //console.log("before loop", task_category);
    for (let category in task_category) {
      let ready = 0;
      let assigned = 0;
      let complete = 0;
      if (task_category[category].Ready) ready = task_category[category].Ready;
      if (task_category[category].Assigned)
        assigned = task_category[category].Assigned;
      if (task_category[category].Complete)
        complete = task_category[category].Complete;
      //console.log("category", category, ready, assigned, complete);
      task_planned.push(ready);
      task_assigned.push(assigned);
      task_completed.push(complete);
    }
    //console.log("after loop", task_assigned, task_completed);
    if (fields.length > 0) {
      this.action = {
        blurb: "Activities by task type and status",
        series: [
          {
            name: "Planned",
            data: task_planned, //[44, 55, 41, 67, 22, 43],
          },
          {
            name: "Assigned",
            data: task_assigned, //[44, 55, 41, 67, 22, 43],
          },
          {
            name: "Complete",
            data: task_completed, // [13, 23, 20, 8, 13, 27],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              //borderRadius: 20,
            },
          },
          xaxis: {
            type: "category",
            categories: Object.keys(task_category),
            labels: { trim: true, offsetX: 2 },
          },
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        },
      };
    }
  },
};
</script>

<template>
  <div class="bg-gray-50">
    <main>
      <div class="bg-white">
        <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">
          My Order Book
        </h1>
        <!-- <p class="max-w-xl mt-4 text-sm text-gray-700">
          We're partnering with growers to give you a best in class buying
          experience.
        </p> -->
        <div v-if="loggedIn">
          <!-- Table of Orders goes here -->
          <TabGroup as="div">
            <div class="border-b border-gray-200">
              <TabList class="-mb-px flex space-x-8">
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Overview
                  </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Vendors
                  </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Chat
                  </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                  <button
                    :class="[
                      selected
                        ? 'border-indigo-600 text-indigo-600'
                        : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                      'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                    ]"
                  >
                    Inquiries
                  </button>
                </Tab>
              </TabList>
            </div>
            <TabPanels as="template">
              <TabPanel as="dl" class="text-sm text-gray-500">
                <buyer-allocations-chart />
              </TabPanel>
              <TabPanel as="dl" class="text-sm text-gray-500">
                <VendorsTable />
              </TabPanel>
              <TabPanel as="dl" class="text-sm text-gray-500">
                <ChatWindow
                  :threadId="thread?.toString()"
                  @fetch-messages="setThread"
                />
              </TabPanel>
              <TabPanel as="dl" class="text-sm text-gray-500">
                <inquiries-table />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
        <div v-else>
          <p>Please sign up or log in.</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import VendorsTable from "@/layouts/buyer-portal/bp-affiliate/VendorsTable.vue";
import ChatWindow from "@/layouts/common/ChatWindow.vue";
import InquiriesTable from "@/pages/buyer-portal/bp-affiliate/orders/InquiriesTable.vue";
import BuyerAllocationsChart from "@/layouts/buyer-portal/bp-affiliate/BuyerAllocationsChart.vue";

export default {
  components: {
    VendorsTable,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    ChatWindow,
    InquiriesTable,
    BuyerAllocationsChart,
  },
  data() {
    return { thread: null };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getUserData;
    },
  },
  methods: {
    setThread(e) {
      this.thread = e.room.roomId;
    },
  },
};
</script>

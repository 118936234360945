<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'customer', label: 'Vendor' },
          { name: 'name', label: 'Memo', filtered: false },
          { name: 'amount', label: 'Amount', filtered: false },
          { name: 'status', label: 'Status' },
          { name: 'created', label: 'Date' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="invoices"
        @update:derived-collection="derivedInvoices = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="i in derivedInvoices" :key="i.id">
        <table-data td-label="Vendor">
          {{ i.customer }}
        </table-data>
        <table-data td-label="Memo">
          {{ i.name }}
        </table-data>
        <table-data td-label="Amount">
          {{ i.amount }}
        </table-data>
        <table-data td-label="Status">
          {{ i.status }}
        </table-data>
        <table-data td-label="Date">
          {{ new Date(i.created).toLocaleString("en", dateFormat) }}
        </table-data>
        <table-data class="cursor-pointer text-center">
          <button
            v-if="i.stripe_invoice_link"
            @click="() => navigateStripe(i.stripe_invoice_link)"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Details
          </button>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
  },
  data() {
    return {
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
      derivedInvoices: [],
    };
  },
  methods: {
    navigateStripe(target) {
      window.open(target, "_blank");
    },
  },
  computed: {
    currentInvoices() {
      return this.$store.state.growerInvoices.growerInvoices;
    },
    invoices() {
      return this.currentInvoices.map((invoice) => ({
        ...invoice,
        customer:
          this.$store.getters.getVendorById(invoice.seller_id)?.name ||
          this.$store.getters.getGrowerProductSellerById(invoice.seller_id)
            ?.name,
      }));
    },
  },
  async mounted() {
    // console.log("INVOICES", JSON.stringify(this.invoices));
  },
};
</script>

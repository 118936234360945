<template>
  <error-notification />
  <div
    class="flex h-screen overflow-hidden bg-gray-100 pt-5 sm:pt-0 print:overflow-visible"
  >
    <side-menu
      settingsPath="buyer-settings"
      homePath="buyer-portal-home"
      orgPath="buyer-switch-org"
      :navigation="navigation"
      :showSideBar="showSideBar"
      barTheme="bg-storm-900 text-white"
      @sidebarOpen="(open) => (this.showSideBar = open)"
    />
    <!-- main page area -->
    <div
      class="flex w-0 flex-1 flex-col overflow-hidden print:overflow-visible"
    >
      <div
        class="relative z-10 flex h-20 flex-shrink-0 bg-white shadow print:hidden"
      >
        <!-- App top bar -->
        <buyer-frame-page-header
          homeRouteName="buyer-portal-home"
          :solutions="solutions"
          @clickSidebarMenu="this.showSideBar = true"
        />
      </div>
      <!-- main content area -->
      <main
        class="relative flex-1 overflow-y-auto focus:outline-none print:overflow-y-visible"
      >
        <div class="py-6 print:py-0">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <!-- Router View Page Content -->
            <ActionCardProcess
              v-if="activeActionsProcess"
              :title="activeActionsProcess.title"
              :description="activeProcessDescription"
              @closeProcess="removeActiveProcess"
            >
              <template #gridItems>
                <ActionCard
                  v-for="(action, index) in activeActionsProcess.actions"
                  :key="action.routeObject"
                  :title="action.title"
                  :routeObject="action.routeObject"
                  :tags="action.tags"
                  :notificationCount="
                    buyerOrgIsController
                      ? $store.getters.actionsBuyerControllerNotificationFactory(
                          action.notificationType,
                        )
                      : $store.getters.actionsBuyerNotificationFactory(
                          action.notificationType,
                        )
                  "
                  :stepGreen="action.active"
                  :stepNumber="index + 1"
                  @clickOnAction="activateAction(index)"
                />
              </template>
            </ActionCardProcess>
            <router-view></router-view>
            <!-- /End page content -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import BuyerFramePageHeader from "@/layouts/buyer-portal/common/BuyerFramePageHeader.vue";
// import { ShoppingCartIcon } from "@heroicons/vue/outline";
import SideMenu from "@/layouts/common/SideMenu.vue";
import ErrorNotification from "@/components/notifications/ErrorNotification.vue";
import ActionCard from "@/components/cards/ActionCard.vue";
import ActionCardProcess from "@/components/cards/ActionCardProcess.vue";

const solutions = [];

export default {
  components: {
    BuyerFramePageHeader,
    SideMenu,
    ErrorNotification,
    ActionCard,
    ActionCardProcess,
  },
  setup() {
    return {
      solutions,
    };
  },
  data() {
    return {
      // For whatever reason :src="parcelLogoPath" didn't work so the img path is hard coded for now
      // parcelLogoPath: "../../assets/parcel-logo.svg",
      showSideBar: false,
    };
  },
  methods: {
    activateAction(index) {
      if (this.buyerOrgIsController) {
        this.$store.dispatch(
          "setActiveProcessBuyerControllerActionToActive",
          index,
        );
      } else {
        this.$store.dispatch("setActiveProcessBuyerActionToActive", index);
      }
    },
    removeActiveProcess() {
      if (this.buyerOrgIsController) {
        this.$store.dispatch("clearBuyerControllerActionsActiveProcess");
      } else {
        this.$store.dispatch("clearBuyerActionsActiveProcess");
      }
    },
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    buyerOrgIsController() {
      if (this.currentOrg?.feature_flags.includes("bp-controller")) {
        return true;
      } else {
        return false;
      }
    },
    activeActionsProcess() {
      if (this.buyerOrgIsController) {
        return this.$store.getters.getBuyerControllerActionsActiveProcess;
      } else {
        return this.$store.getters.getBuyerActionsActiveProcess;
      }
    },
    activeProcessDescription() {
      if (this.buyerOrgIsController) {
        if (
          this.$store.getters.getBuyerControllerActionsActiveProcessActiveAction
        ) {
          return this.$store.getters
            .getBuyerControllerActionsActiveProcessActiveAction.description;
        } else {
          return this.activeActionsProcess.description;
        }
      } else {
        if (this.$store.getters.getBuyerActionsActiveProcessActiveAction) {
          return this.$store.getters.getBuyerActionsActiveProcessActiveAction
            .description;
        } else {
          return this.activeActionsProcess.description;
        }
      }
    },

    navigation() {
      if (!this.currentOrg) {
        return { sections: [] };
      } else if (this.currentOrg.feature_flags.includes("bp-controller")) {
        return this.bpControllerNavigation;
      } else {
        return this.bpAffiliateNavigation;
      }
    },
    bpControllerNavigation() {
      let appNavigation = { sections: [] };
      appNavigation.sections.push(
        {
          sectionHeading: "Home",
          featureFlagForAccess: "allow",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Dashboard",
              routeName: "buyer-portal-home",
              icon: "TemplateIcon",
              featureFlagForAccess: "allow",
            },
          ],
        },
        {
          sectionHeading: "Contracts",
          featureFlagForAccess: "bp-controller",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Overview",
              routeName: "buyer-bp-controller-reporting",
              icon: "PresentationChartBarIcon",
              featureFlagForAccess: "bp-controller",
            },
            {
              id: 2,
              name: "Contracts",
              routeName: "buyer-contracts",
              icon: "ShoppingCartIcon",
              featureFlagForAccess: "bp-controller",
            },
            {
              id: 3,
              name: "Affililates",
              routeName: "buyer-affiliates",
              icon: "UsersIcon",
              featureFlagForAccess: "bp-controller",
            },
            {
              id: 4,
              name: "Assignments",
              routeName: "buyer-assignments",
              icon: "BriefcaseIcon",
              featureFlagForAccess: "bp-controller",
            },
            {
              id: 5,
              name: "Growers",
              routeName: "buyer-bp-controller-growers",
              icon: "ShoppingCartIcon",
              featureFlagForAccess: "bp-controller",
            },
            {
              id: 6,
              name: "Orders",
              routeName: "buyer-bp-controller-orders",
              icon: "CurrencyDollarIcon",
              featureFlagForAccess: "bp-controller",
            },
          ],
        },
        {
          sectionHeading: "Supply",
          featureFlagForAccess: "bp-controller-supply",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Inquiries",
              routeName: "buyer-bp-controller-inquiries",
              icon: "ShoppingCartIcon",
              featureFlagForAccess: "bp-controller",
            },
            {
              id: 2,
              name: "Orders",
              routeName: "buyer-bp-controller-orders",
              icon: "ClipboardListIcon",
              featureFlagForAccess: "bp-controller",
            },
          ],
        },
      );

      return appNavigation;
    },
    bpAffiliateNavigation() {
      let appNavigation = { sections: [] };
      appNavigation.sections.push(
        {
          sectionHeading: "Home",
          featureFlagForAccess: "allow",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Dashboard",
              routeName: "buyer-portal-home",
              icon: "TemplateIcon",
              featureFlagForAccess: "allow",
            },
            {
              id: 2,
              name: "Grower Listings",
              routeName: "buyer-listings",
              icon: "BookOpenIcon",
              featureFlagForAccess: "bp-affiliate-listings",
            },
            {
              id: 3,
              name: "Search Crop Supply",
              routeName: "affiliate-crop-supply",
              icon: "UserIcon",
              featureFlagForAccess: "bp-affiliate",
            },
          ],
        },
        {
          sectionHeading: "Contracts",
          featureFlagForAccess: "bp-affiliate",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Overview",
              routeName: "buyer-bp-affiliate-reporting",
              icon: "PresentationChartBarIcon",
              featureFlagForAccess: "bp-affiliate",
            },
            {
              id: 2,
              name: "Originators",
              routeName: "buyer-originators",
              icon: "LibraryIcon",
              featureFlagForAccess: "bp-affiliate",
            },
            {
              id: 3,
              name: "Contract Offers",
              routeName: "buyer-market-offers",
              icon: "BriefcaseIcon",
              featureFlagForAccess: "bp-affiliate",
            },
            {
              id: 4,
              name: "Growers",
              routeName: "buyer-bp-affiliate-growers",
              icon: "UsersIcon",
              featureFlagForAccess: "bp-affiliate",
            },
            {
              id: 5,
              name: "Premiums",
              routeName: "buyer-bp-affiliate-premiums",
              icon: "CurrencyDollarIcon",
              featureFlagForAccess: "bp-affiliate",
            },
          ],
        },
        {
          sectionHeading: "Supply",
          featureFlagForAccess: "bp-affiliate",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Inquiries",
              routeName: "buyer-inquiries",
              icon: "ShoppingCartIcon",
              featureFlagForAccess: "bp-affiliate",
            },
            {
              id: 2,
              name: "Orders",
              routeName: "buyer-orders",
              icon: "ClipboardListIcon",
              featureFlagForAccess: "bp-affiliate",
            },
          ],
        },
      );

      return appNavigation;
    },
  },
};
</script>

<template>
  <div class="bg-gray-50">
    <div>
      <search-container>
        <template #navigation>
          <search-navigation current-navigation="products" />
        </template>
        <template v-slot:categories>
          <!-- <search-lob-select /> -->
          <div class="flex">
            <!--<span class="mr-4 mt-0.5 text-sm font-bold">Filters: </span> -->
            <filter-menu
              :filters="filterOptions"
              @update:filters="updateFilters"
            />
          </div>
          <!-- Put filters in here to distinguish between buy now products and quoted products and services. See Ebay for example UI (auction, buy it now)
            <div class="justify start flex">
            <div
              :class="
                showCompanies ? 'bg-gray-200 text-blue-700 underline' : ''
              "
              class="m-2 rounded-lg border-2 pl-2 pr-2"
            >
              <button @click="showCompanies = !showCompanies">Companies</button>
            </div>
            <div
              :class="showProducts ? 'bg-gray-200 text-blue-700 underline' : ''"
              class="m-2 rounded-lg border-2 pl-2 pr-2"
              v-if="products?.length > 0"
            >
              <button @click="showProducts = !showProducts">Products</button>
            </div>
          </div>  -->
        </template>
        <template v-slot:input>
          <search-input v-model="search" />
        </template>
        <!-- <template v-slot:filters>
              <CommonSearchFilters
                :filters="filterOptions"
                @checkedToggle="toggleFilter"
              />
            </template> -->
        <!-- <template v-slot:activeFilters>
              <CommonSearchActiveFilters
                :activeFilters="activeFilters"
                @removeActiveFilter="toggleFilter"
              />
            </template> -->
      </search-container>
      <div
        class="mt-4 pl-4 text-lg font-bold text-blue-700"
        v-if="showProducts && activeProducts?.length > 0"
      >
        Products
      </div>
      <search-grid v-if="showProducts && activeProducts?.length > 0">
        <template #gridItems>
          <product-search-grid-card
            v-for="product in activeProducts"
            :key="product.id"
            :product="product"
            @gridItemClicked="navigateToProduct"
          />
        </template>
      </search-grid>
      <!-- <div class="mt-4 pl-4 text-lg font-bold text-blue-700">Companies</div>
      <search-grid @gridItemClicked="navigateToCompany">
        <template #gridItems>
          <company-search-grid-card
            :gridItems="activeCompanies"
            @gridItemClicked="navigateToCompany"
          />
        </template>
      </search-grid> -->
    </div>
  </div>
</template>

<script>
// import Fuse from "fuse.js";
import { FilterProducts } from "@/layouts/grower-portal/discover/search/FilterComposable.js";
import SearchContainer from "@/layouts/grower-portal/discover/search/SearchContainer.vue";
import SearchNavigation from "@/layouts/grower-portal/discover/search/SearchNavigation.vue";
import SearchInput from "@/layouts/grower-portal/discover/search/SearchInput.vue";
import SearchGrid from "@/layouts/grower-portal/discover/search/SearchGrid.vue";
// import SearchLobSelect from "@/layouts/grower-portal/discover/search/SearchLobSelect.vue";
import ProductSearchGridCard from "@/layouts/grower-portal/discover/search/ProductSearchGridCard.vue";
// import CompanySearchGridCard from "@/layouts/grower-portal/discover/search/CompanySearchGridCard.vue";
import FilterMenu from "@/components/dropdowns/FilterMenu.vue";

export default {
  components: {
    SearchContainer,
    SearchNavigation,
    SearchInput,
    SearchGrid,
    // SearchLobSelect,
    ProductSearchGridCard,
    // CompanySearchGridCard,
    FilterMenu,
  },
  data() {
    let filterOptions = {};
    let products = this.$store.state.growerProducts["growerProducts"];

    // let productCategories = products.map((product) => product.category);
    // add category if at least one product of that category exists
    // let categories = new Set(productCategories);

    // filterOptions.category = {
    //   id: "category",
    //   name: "Category",
    //   filterColumn: "category",
    //   active: true,
    //   options: {},
    // };
    // for (let category of categories) {
    //   switch (category) {
    //     case "Product":
    //       filterOptions.category.options.product = {
    //         value: "Product",
    //         label: "Ag Products",
    //         checked: false,
    //       };
    //       break;
    //     case "Carbon":
    //       filterOptions.category.options.carbon = {
    //         value: "Carbon",
    //         label: "Sustainability",
    //         checked: false,
    //       };
    //       break;
    //     case "Hauling":
    //       filterOptions.category.options.hauling = {
    //         value: "Hauling",
    //         label: "Hauling",
    //         checked: false,
    //       };
    //       break;
    //     case "Service":
    //       filterOptions.category.options.service = {
    //         value: "Service",
    //         label: "Ag Services",
    //         checked: false,
    //       };
    //       break;
    //     case "Recommendation":
    //       filterOptions.category.options.recommendation = {
    //         value: "Recommendation",
    //         label: "Recommendation Services",
    //         checked: false,
    //       };
    //       break;
    //   }
    // }

    const queryCategory = this.$route.query.category;

    let productTypes = products.map((product) => product.category);
    // remove undefined, null, and empty strings from product types. These will be from non-crop protection products
    productTypes = productTypes.filter(
      (type) => type !== undefined && type !== "" && type !== null,
    );
    // add type if at least one product of that type exists
    let types = new Set(productTypes);
    filterOptions.category = {
      id: "category",
      name: "Category",
      filterColumn: "category",
      active: true,
      options: {},
    };
    for (let type of types) {
      filterOptions.category.options[type] = {
        value: type,
        label: type,
        checked: type == queryCategory,
      };
    }

    return {
      search: this.$route.query.search ? this.$route.query.search : "",
      showCompanies: true,
      showProducts: true,
      filterOptions: filterOptions,
      // companies: this.$store.state.companies["companies"],
      // seeds: this.$store.state.companies["seedCompanies"],
    };
  },
  computed: {
    products() {
      let products = this.$store.state.growerProducts["growerProducts"];
      for (let product of products) {
        product["searchString"] = product.details;
      }
      return products;
    },
    activeProducts() {
      let activeProducts = this.products;
      activeProducts = FilterProducts(
        activeProducts,
        this.search,
        this.filterOptions,
      );
      // // filter product list by selected filters
      // for (let filter in this.filterOptions) {
      //   if (this.filterOptions[filter].active) {
      //     let activeOptions = [];
      //     // console.log("filter ", filter, this.filterOptions[filter]);
      //     for (let option in this.filterOptions[filter].options) {
      //       if (this.filterOptions[filter].options[option].checked) {
      //         activeOptions.push(
      //           this.filterOptions[filter].options[option].value
      //         );
      //       }
      //     }
      //     if (activeOptions.length > 0) {
      //       // console.log("activeOptions", activeOptions);
      //       for (let option of activeOptions) {
      //         let filterColumn = this.filterOptions[filter].filterColumn;
      //         activeProducts = activeProducts.filter((product) => {
      //           return product[filterColumn]?.includes(option);
      //         });
      //       }
      //     }
      //   }
      // }

      // // search for products in filtered list
      // if (this.search.length > 0) {
      //   const fuse = new Fuse(activeProducts, {
      //     useExtendedSearch: false,
      //     keys: ["title", "keywords", "description", "searchString"],
      //     threshold: 0.3,
      //     distance: 5000,
      //   });
      //   activeProducts = fuse
      //     .search(this.search)
      //     .map((product) => product.item);
      // }
      return activeProducts;
    },
  },
  methods: {
    navigateToCompany(company) {
      this.$router.push({
        name: "grower-portal-discover-company",
        params: { companySlug: company.slug },
      });
    },
    navigateToProduct(product) {
      if (product.category === "Input") {
        this.$router.push({
          name: "grower-portal-discover-single-input",
          params: { productId: product.id },
        });
      } else {
        this.$router.push({
          name: "grower-portal-discover-single-product",
          params: { productId: product.id },
        });
      }
    },
    updateFilters(filters) {
      this.filterOptions = filters;
    },
  },
};
</script>

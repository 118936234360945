<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'category', label: 'Category' },
          { name: 'status', label: 'Status' },
          { name: 'user.name', label: 'Assignee' },
          { name: 'fieldOrEquipment', label: 'On' },
          { name: 'source.name', label: 'Assigned By' },
          { name: 'updated', label: 'Created', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="tasks"
        @update:derived-collection="derivedTasks = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewTaskPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="task in derivedTasks" :key="task.id">
        <table-data td-label="Category">
          {{ task.category }}
        </table-data>
        <table-data td-label="Status">
          {{ task.status }}
        </table-data>
        <table-data td-label="Asignee">
          {{ task.user.name }}
        </table-data>
        <table-data td-label="On">
          {{ task.fieldOrEquipment }}
        </table-data>
        <table-data td-label="Assigned By">
          {{ task.source?.name }}
        </table-data>
        <table-data td-label="Created">
          {{ new Date(task.updated).toLocaleString("en", dateFormat) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'single-activity',
              params: { activityId: task.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  data() {
    return {
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
      derivedTasks: [],
    };
  },
  computed: {
    tasks() {
      return this.$store.getters.getDerivedTasks({});
    },
  },
  methods: {
    goToNewTaskPage() {
      this.$router.push({ name: "new-activity" });
    },
  },
};
</script>

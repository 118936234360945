<template>
  <Modal :show="showContactModal" title="Contact">
    <form
      @submit.prevent="submitContactForm"
      class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <div class="col-span-2 flex justify-end">
        <button
          type="button"
          class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
          @click="showContactModal = false"
        >
          <span class="sr-only">Close menu</span>
          <XIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="sm:col-span-2">
        <label for="message" class="block text-sm font-medium text-gray-700"
          >Message<span class="text-red-600"> *</span></label
        >
        <div class="mt-1">
          <textarea
            v-model="message_text"
            required
            id="message"
            name="message"
            rows="4"
            class="block w-full rounded-md border border-gray-300 px-4 py-3 shadow-sm focus:border-parcelOrange-600 focus:ring-parcelOrange-600"
          />
        </div>
      </div>
      <div class="sm:col-span-2">
        <button
          type="submit"
          class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-parcelOrange-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-parcelOrange-600 focus:outline-none focus:ring-2 focus:ring-parcelOrange-600 focus:ring-offset-2"
        >
          Send Message
        </button>
      </div>
    </form>
  </Modal>
  <ListingContainer>
    <template #heroImage>
      <img
        v-if="!currentCompany.logo_url"
        src="@/assets/no_logo_default.jpeg"
        class="object-contain object-center"
        :alt="`${currentCompany.name} Logo`"
      />
      <img
        v-else
        :src="currentCompany.logo_url"
        :alt="`${currentCompany.name} Logo`"
        class="object-contain object-center"
      />
    </template>
    <template #heroTitle>{{ currentCompany.name }} </template>
    <template #heroDescription>{{ currentCompany.desc_rtf }} </template>
    <template #heroActions>
      <button
        type="button"
        class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50"
        @click="handleContactRequest"
      >
        Contact
      </button>
    </template>
    <!-- <template #recommendationsTop>
    </template> -->
    <template #sections>
      <ListingSections :tabSlots="tabs">
        <template v-if="currentCompany.details" #details>
          <div v-html="currentCompany.details" class="prose"></div>
        </template>
        <template v-if="products.length > 0" #products>
          <search-grid>
            <template #gridItems>
              <product-search-grid-card
                v-for="product in products"
                :key="product.id"
                :product="product"
                @gridItemClicked="navigateToProduct"
              />
            </template>
          </search-grid>
        </template>
        <template v-if="currentCompany.benefits_list.length > 0" #benefits>
          <div
            class="mt-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
            v-for="benefit in currentCompany.benefits_list"
            :key="benefit"
          >
            <div class="px-4 py-5 text-lg sm:px-6" v-if="benefit.title">
              <!-- Content goes here -->
              <!-- We use less vertical padding on card headers on desktop than on body sections -->
              {{ benefit.title }}
            </div>
            <div class="break-words px-4 py-5 sm:p-6" v-if="benefit.title">
              <!-- Content goes here -->
              {{ benefit.description }}
            </div>
          </div>
        </template>
        <template v-if="currentCompany.features_list.length > 0" #features>
          <div
            class="mt-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
            v-for="feature in currentCompany.features_list"
            :key="feature"
          >
            <div class="px-4 py-5 text-lg sm:px-6" v-if="feature.title">
              <!-- Content goes here -->
              <!-- We use less vertical padding on card headers on desktop than on body sections -->
              {{ feature.title }}
            </div>
            <div class="break-words px-4 py-5 sm:p-6" v-if="feature.title">
              <!-- Content goes here -->
              {{ feature.description }}
            </div>
          </div>
        </template>
        <template v-if="currentCompany.resources_list.length > 0" #resources>
          <div
            class="mt-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
            v-for="resource in currentCompany.resources_list"
            :key="resource"
          >
            <div
              class="flex items-center px-4 py-5 text-lg sm:px-6"
              v-if="resource.title"
            >
              <!-- Content goes here -->
              <!-- We use less vertical padding on card headers on desktop than on body sections -->
              <a :href="resource.link_url" v-if="resource.link_url"
                >{{ resource.title
                }}<ExternalLinkIcon class="ml-1 inline-block h-6 w-6"
              /></a>
              <div v-else>{{ resource.title }}</div>
            </div>
            <div
              class="flex break-words px-4 py-5 sm:p-6"
              v-if="resource.title"
            >
              <!-- Content goes here -->
              <div
                v-if="resource.image_url"
                class="mr-4 flex-shrink-0 self-center"
              >
                <img class="h-16 w-16" :src="resource.image_url" />
              </div>
              <div>
                {{ resource.description }}
              </div>
            </div>
          </div>
        </template>
        <template v-if="currentCompany.faq_list.length > 0" #faq>
          <div
            class="mt-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
            v-for="faq in currentCompany.faq_list"
            :key="faq"
          >
            <div class="px-4 py-5 text-lg sm:px-6">
              <!-- Content goes here -->
              <!-- We use less vertical padding on card headers on desktop than on body sections -->
              {{ faq.title }}
            </div>
            <div class="break-words px-4 py-5 sm:p-6">
              <!-- Content goes here -->
              {{ faq.description }}
            </div>
          </div>
        </template>
        <template v-if="chatThread" #chat>
          <ChatWindow :isOpen="false" :threadId="chatThread?.toString()" />
        </template>
      </ListingSections>
    </template>
    <!-- <template #recommendationsBot>
    </template> -->
  </ListingContainer>
</template>

<script>
import ListingContainer from "@/layouts/common/listing/ListingContainer.vue";
import ListingSections from "@/layouts/common/listing/ListingSections.vue";
import ChatWindow from "@/layouts/common/ChatWindow.vue";
import SearchGrid from "@/layouts/grower-portal/discover/search/SearchGrid.vue";
import ProductSearchGridCard from "@/layouts/grower-portal/discover/search/ProductSearchGridCard.vue";
import Modal from "@/components/modals/PopupModal.vue";
import { XIcon, ExternalLinkIcon } from "@heroicons/vue/solid";

import ky from "ky";
const BASE_URL = process.env.VUE_APP_API_URL || "https://api.parcel.ag";
const CONTACT_URL = BASE_URL + "/v1/contact";

import { useToast } from "vue-toastification";
export default {
  components: {
    ListingContainer,
    ListingSections,
    ChatWindow,
    SearchGrid,
    ProductSearchGridCard,
    Modal,
    XIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      toast: useToast(),
      showContactModal: false,
      message_text: "",
    };
  },
  computed: {
    companies() {
      return this.$store.getters.getCompanies;
    },
    currentCompany() {
      // console.log("currentCompany", this.$route.params.companySlug);
      // console.log("currentCompany", this.companies);
      return this.companies.find(
        (company) => company.slug === this.$route.params.companySlug,
      );
    },
    myCompany() {
      return this.$store.getters.getCurrentOrg;
    },
    me() {
      return this.$store.getters.getCurrentUser;
    },
    chatThread() {
      return this.$store.getters.getThreadByPartner(this.currentCompany.org)
        ?.id;
    },
    contactRequest() {
      return {
        email: this.me.email,
        first_name: "Contact Request:",
        last_name: this.me.name + " at " + this.myCompany.name,
        company: this.currentCompany.name,
        message: this.message_text,
      };
    },
    products() {
      const products = this.$store.state.growerProducts[
        "growerProducts"
      ]?.filter(
        (product) => product.seller_details?.id == this.currentCompany.org,
      );
      return products.map((product) => {
        return {
          name: product.title,
          title: product.title,
          id: product.id,
          image_url: product.image_url,
          category: product.keywords[0],
          keywords: product.keywords,
          description: product.description,
          pills: [],
        };
      });
    },
    tabs() {
      let tabs = [];
      if (this.currentCompany.details)
        tabs.push({ slotName: "details", displayName: "Details" });
      if (this.products?.length > 0)
        tabs.push({ slotName: "products", displayName: "Products" });
      if (this.currentCompany.benefits_list.length > 0)
        tabs.push({ slotName: "benefits", displayName: "Benefits" });
      if (this.currentCompany.features_list.length > 0)
        tabs.push({ slotName: "features", displayName: "Features" });
      if (this.currentCompany.resources_list.length > 0)
        tabs.push({ slotName: "resources", displayName: "Resources" });
      if (this.currentCompany.faq_list.length > 0)
        tabs.push({ slotName: "faq", displayName: "FAQs" });
      if (this.chatThread) tabs.push({ slotName: "chat", displayName: "Chat" });
      return tabs;
    },
  },
  methods: {
    async handleContactRequest() {
      let thread = null;
      // actual vendor
      // let's open a chat session
      // is there a chat thread for this vendor?
      // console.log(this.$store.state.threads.threads);
      thread = this.$store.state.threads.threads.find(
        (thread) =>
          thread.org == this.currentCompany.org ||
          thread.partner == this.currentCompany.org,
      );
      // console.log("thread", thread);
      // if not, let's create one
      if (!thread) {
        await this.$store.dispatch("createThread", {
          partner: this.currentCompany.org,
          subject:
            this.$store.getters.getCurrentOrg.name +
            " & " +
            this.currentCompany.name,
        });
        this.chatThread = this.$store.getters.getThreadByPartner(
          this.currentCompany.org,
        )?.id;
      }
      // if yes, let's open it
      else {
        this.chatThread = thread.id;
      }
      this.showContactModal = true;
      this.selectedTab = this.lastTab;
      // console.log("handleContactRequest", this.chatThread, this.selectedTab);
    },
    // console.log("openGetQuoteTab");
  },
  changeTab(index) {
    this.selectedTab = index;
  },
  navigateToListingButton() {
    window.open(this.currentCompany.btn_1_link, "_blank");
    // console.log("navigateToListingButton", this.currentCompany.btn_1_link);
  },
  sendMessage(message) {
    this.$store.dispatch("createMessage", {
      threadId: this.chatThread,
      message,
    });
    this.showContactModal = false;
    this.selectedTab = this.lastTab;
  },
  async submitContactForm() {
    // console.log(BASE_URL);
    if (this.currentCompanyIsSeed) {
      // const json =
      await ky.post(CONTACT_URL, { json: this.contactRequest }).json();
      // console.log(json);
      this.showContactModal = false;
    } else {
      this.sendMessage({
        content: this.contactRequest.message,
      });
    }
  },
  mounted() {
    // programatically set document title so we get unique titles for each product
    // console.log("currentProduct: ", this.currentProduct);
    document.title = `${this.currentCompany.name} - Parcel`;
  },
};
</script>

<style></style>

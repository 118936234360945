import growerPortalRoutes from "@/routes/growerPortalRoutes";

let growerRouteActions = [];
for (let route of growerPortalRoutes) {
  // flatten the growerPortalRoutes array to get an array of objects without nesting
  if (route.children) {
    for (let childRoute of route.children) {
      // skip the route if it has a redirect property
      if (childRoute.redirect) {
        continue;
      }
      // if the route path contains a colon skip it
      if (childRoute.path.includes(":")) {
        continue;
      }
      growerRouteActions.push({
        title:
          childRoute.meta.title.replace(" | Parcel", "") ||
          childRoute.meta.displayTitle,
        routeObject: { name: childRoute.name },
        tags: [],
        notificationType: null,
      });
    }
  } else {
    growerRouteActions.push({
      title: route.meta.title || route.meta.displayTitle,
      routeObject: { name: route.name },
      tags: [],
      notificationType: null,
    });
  }
}

let processes = [
  {
    title: "Buy Inputs",
    description: "Search for, buy, and respond to quotes for crop inputs ",
    htmlDescription:
      "<div class='flex'> <img class='h-12 my-4 mx-4' src='https://parcel.ag/img/basf.png'> <img class='h-12 my-4 mx-4' src='https://parcel.ag/img/bayer.png'> <img class='h-12 my-4 mx-4' src='https://parcel.ag/img/corteva.png'> <img class='h-12 my-4 mx-4' src='https://parcel.ag/img/syngenta.png'> <img class='h-8 my-4 mx-4' src='https://parcel.ag/img/fmc.png'> and more...</div>",
    actions: [
      {
        title: "Go Shopping",
        routeObject: { name: "grower-portal-discover-search-inputs" },
        description:
          "Search for inputs to buy. Find what you want and add it to your cart.",
        tags: ["Inputs", "Buy", "Respond", "Quotes"],
        notificationType: null,
        active: false,
      },
      {
        title: "Checkout",
        routeObject: { name: "grower-portal-discover-checkout" },
        description:
          "Enter your shipping information and submit your cart for a quote.",
        tags: ["Inputs", "Buy", "Checkout"],
        notificationType: null,
        active: false,
      },
      {
        title: "Approve the Quote",
        routeObject: { name: "grower-portal-quotes-all" },
        description: "Review and accept a quotes to place an order.",
        tags: ["Inputs", "Buy", "Respond", "Quotes"],
        notificationType: "inputQuoteResponseNeeded",
        active: false,
      },
    ],
  },
  // {
  //   title: "Crop Practice Premiums",
  //   description:
  //     "Secure more revenue by working with buyers willing to pay a premium.",
  //   actions: [
  //     {
  //       title: "View and Choose an Offer",
  //       routeObject: { name: "grower-portal-market-offers" },
  //       description: "",
  //       tags: ["Offers", "Sell", "View"],
  //       notificationType: null,
  //       active: false,
  //     },
  //     {
  //       title: "Review and Accept an Offer Inquiry",
  //       routeObject: { name: "grower-portal-produce-inquiries" },
  //       description: "",
  //       tags: ["Inquiry", "Offer", "Sell", "Accept"],
  //       notificationType: "cropInquiries",
  //       active: false,
  //     },
  //     {
  //       title: "Allocate Fields to Crop Orders",
  //       routeObject: { name: "grower-portal-produce-orders" },
  //       description: "",
  //       tags: ["Crop", "Order", "Allocate"],
  //       notificationType: null,
  //       active: false,
  //     },
  //     {
  //       title: "Deliver the Crop and Receive Payment",
  //       routeObject: { name: "grower-portal-produce-orders" },
  //       description: "",
  //       tags: ["Crop", "Order", "Complete"],
  //       notificationType: null,
  //       active: false,
  //     },
  //   ],
  // },
  {
    title: "Enable FMS Operations",
    description:
      "Enable operations for your account and set yourself up for operations management in Parcel.",
    actions: [
      {
        title: "Enable Operations",
        routeObject: {
          name: "grower-portal-settings-tab",
          params: { tab: "features" },
        },
        description: "",
        tags: ["Settings", "Operations"],
        notificationType: null,
        active: false,
      },
      {
        title: "Create a Field",
        routeObject: { name: "fields" },
        description: "",
        tags: ["Fields", "Assets", "Create"],
        notificationType: null,
        active: false,
      },
      {
        title: "Assign a Task",
        routeObject: { name: "activities" },
        description: "",
        tags: ["Operations", "Activities", "Create", "Assign"],
        notificationType: null,
        active: false,
      },
      {
        title: "Record the Results",
        routeObject: { name: "fields" },
        description: "",
        tags: ["Records", "Assets", "Operations"],
        notificationType: null,
        active: false,
      },
      {
        title: "Make a Plan",
        routeObject: { name: "field-plans" },
        description: "",
        tags: ["Plans", "Operations", "Create"],
        notificationType: null,
        active: false,
      },
    ],
  },
];

export default {
  state: {
    actions: growerRouteActions,
    processes: processes,
    activeProcess: null,
  },
  mutations: {
    SET_ACTIVE_PROCESS(state, process) {
      // create a deep copy of the process object
      state.activeProcess = JSON.parse(JSON.stringify(process));
    },
  },
  actions: {
    setGrowerActionsActiveProcess({ commit }, process) {
      commit("SET_ACTIVE_PROCESS", process);
    },
    clearGrowerActionsActiveProcess({ commit }) {
      commit("SET_ACTIVE_PROCESS", null);
    },
    setActiveProcessGrowerActionToActive({ state, commit }, actionIndex) {
      let process = state.activeProcess;
      for (let action of process.actions) {
        action.active = false;
      }
      process.actions[actionIndex].active = true;
      commit("SET_ACTIVE_PROCESS", process);
    },
  },
  getters: {
    getAllGrowerActions(state) {
      return state.actions;
    },
    getAllGrowerActionsProcesses(state, rootGetters) {
      let currentOrg = rootGetters.getCurrentOrg;
      let processes = structuredClone(state.processes);
      // console.log(
      //   "getAllGrowerActionProcesses",
      //   currentOrg,
      //   processes,
      //   rootGetters.getGrowerMarketOffers(),
      // );
      if (rootGetters.getGrowerMarketOffers().length === 0) {
        // remove the crop practice premiums process from the processes array
        processes = processes.filter(
          (process) => process.title !== "Crop Practice Premiums",
        );
      }
      if (
        rootGetters.getAllActivities.length > 0 &&
        rootGetters.getAllRecords.length > 0
      ) {
        // remove the Enable FMS operations process from the processes array
        processes = processes.filter(
          (process) => process.title !== "Enable FMS Operations",
        );
      }
      // now filter out any processes that don't have matching feature flags
      if (!currentOrg?.feature_flags.includes("gp-buy")) {
        processes = processes.filter(
          (process) => process.title !== "Buy Inputs",
        );
      }
      if (!currentOrg?.feature_flags.includes("gp-sell")) {
        processes = processes.filter(
          (process) => process.title !== "Crop Practice Premiums",
        );
      }
      return processes;
    },
    getGrowerActionsActiveProcess(state) {
      return state.activeProcess;
    },
    getGrowerActionsActiveProcessActiveAction(state) {
      return state.activeProcess.actions.find((action) => action.active);
    },
    actionsGrowerNotificationFactory:
      (state, getters, rootState, rootGetters) => (actionNotificationType) => {
        switch (actionNotificationType) {
          case "inputQuoteResponseNeeded":
            return rootGetters.getGrowerInputOrderSellerRespondedCount;
          case "cropInquiries":
            return rootGetters.getPartnerInquiries.length;
          default:
            return null;
        }
      },
  },
};

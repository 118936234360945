<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'seller_details.name', label: 'Vendor' },
          { name: 'service_title', label: 'Listing' },
          { name: 'prettyStatus', label: 'Status' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="quotes"
        @update:derived-collection="derivedQuotes = $event"
        :defaultSort="defaultSort"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Find Products and Services"
              :usePlusIcon="true"
              @buttonClick="goToDiscoverProductsPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="q in derivedQuotes" :key="q.id">
        <table-data td-label="Vendor">
          {{ q.seller_details?.name }}
        </table-data>
        <table-data td-label="Listing">
          {{ q.service_title }}
        </table-data>
        <table-data td-label="Status">
          {{ q.prettyStatus }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: q.input_agreement
                ? 'grower-portal-single-input-order'
                : 'grower-portal-single-quote',
              params: q.input_agreement ? { orderId: q.id } : { quoteId: q.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import { prettyQuoteStatusGrower } from "@/components/composables/formatStringUtils";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  props: {
    tabFilters: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      derivedQuotes: [],
    };
  },
  computed: {
    inputAgreements() {
      return this.$store.state.growerInputAgreements.agreements;
    },
    inputListings() {
      return this.$store.state.growerInputListings.listings;
    },
    inputUnits() {
      return this.$store.state.growerInputListings.units;
    },
    inputRequests() {
      return this.$store.state.growerInputListings.requests;
    },
    quotes() {
      const orders = this.$store.state.growerInputOrders.orders.filter(
        this.tabFilters,
      );
      for (let order of orders) {
        if (!order.seller_details)
          order.seller_details = this.getInputAgreement(
            order.input_agreement,
          )?.seller_details;
        if (!order.service_title)
          order.service_title = this.getOrderProductNames(order);
        if (!order.prettyStatus)
          order.prettyStatus = prettyQuoteStatusGrower(order.status);
      }
      const quotes = this.$store.getters.getDerivedGrowerQuotes({
        filterFunction: this.tabFilters,
      });
      return [...quotes, ...orders];
    },
    defaultSort() {
      return { updated: "desc" };
    },
  },
  methods: {
    goToDiscoverProductsPage() {
      this.$router.push({
        name: "grower-portal-discover-search",
      });
    },
    getInputAgreement(id) {
      return this.inputAgreements.find((a) => a.id == id);
    },
    getInputListing(id) {
      return this.inputListings.find((a) => a.id == id);
    },
    getInputUnit(id) {
      return this.inputUnits.find((a) => a.id == id);
    },
    getInputRequest(id) {
      return this.inputRequests.find((a) => a.id == id);
    },
    getOrderProductNames(o) {
      let title = "";
      for (let item of o.line_items || []) {
        if (item.input_unit) {
          const unit = this.getInputUnit(item.input_unit);
          if (unit) {
            const listing = this.getInputListing(unit.input_listing);
            if (!listing) continue;
            if (title.length) title = title.concat(", ");
            title = title.concat(listing.title);
          }
        }
        if (item.input_request) {
          const request = this.getInputRequest(item.input_request);
          if (title.length) title = title.concat(", ");
          title = title.concat(request.product_name);
        }
      }
      return title;
    },
    prettyQuoteStatusGrower,
  },
};
</script>

<template>
  <text-input
    :wideItem="true"
    inputTitle="Grower"
    :display-only="true"
    :display-only-value="growerName"
  />
  <!-- <div class="sm:col-span-3"></div> -->
  <number-input
    :wideItem="false"
    inputTitle="Active Offers"
    :display-only="true"
    :display-only-value="growerOffers.length"
  />
  <number-input
    :wideItem="false"
    inputTitle="Active Orders"
    :display-only="true"
    :display-only-value="growerOrders.length"
  />
  <number-input
    :wideItem="false"
    inputTitle="Allocated Acres"
    :display-only="true"
    :display-only-value="sumAllocationAcres"
  />
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";

export default {
  props: {
    growerId: {
      type: Number,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
  },
  components: {
    TextInput,
    NumberInput,
  },
  data() {
    return {
      currentOrder: null,
    };
  },
  computed: {
    offers() {
      return this.$store.getters.getControllerMarketOffers({});
    },
    orders() {
      return this.$store.getters.getDerivedControllerCropOrders({});
    },
    allocations() {
      return this.$store.getters.getDerivedControllerAllocations({});
    },
    growerName() {
      // grab the grower name from the first order
      return this.growerOrders[0]?.seller_details?.name;
    },
    growerOffers() {
      let uniqueGrowerOfferIds = [];
      for (let order of this.orders) {
        if (order.offer) {
          uniqueGrowerOfferIds.push(order.offer);
        }
      }
      uniqueGrowerOfferIds = [...new Set(uniqueGrowerOfferIds)];
      return uniqueGrowerOfferIds;
    },
    growerOrders() {
      // loop through orders and pull out the orders for the current grower where order.seller_details.id = this.growerId
      return this.orders.filter(
        (order) => order?.seller_details?.id === this.growerId,
      );
    },
    growerAllocations() {
      return this.$store.getters.getControllerAllocationsBySeller(
        this.growerId,
      );
    },
    sumAllocationAcres() {
      let totalAcres = 0;
      for (let allocation of this.growerAllocations) {
        totalAcres += allocation.acres;
      }
      return totalAcres.toFixed(1);
    },
  },
  methods: {},
  watch: {},
};
</script>

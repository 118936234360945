<!--Renders a chart in a widget. 
action: object which defines the icon, route, etc.

Must include chartOptions, and series for this widget to work.
example: 
{
      type: "ChartWidget",
      icon: ClipboardListIcon,
      name: "Chart Test",
      value: tasksDue,
      blurb: "Things you need to manage this week.",
      routeName: "activities",
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
      chartOptions: {
        chart: {
          width: 380,
          type: "donut",
        },
        labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: [44, 55, 13, 43, 22]
    }
-->

<template>
  <div>
    <span
      :class="[
        action.iconBackground,
        action.iconForeground,
        'inline-flex rounded-lg p-3 ring-4 ring-white',
      ]"
    >
      <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
    </span>
  </div>
  <div class="mt-8">
    <h3 class="text-lg font-medium">
      <router-link :to="{ name: action.routeName }" class="focus:outline-none">
        <!-- Extend touch target to entire panel -->
        <span class="absolute inset-0" aria-hidden="true" />
        {{ action.name }}
      </router-link>
    </h3>
    <p class="mt-2 text-sm text-gray-500">
      {{ action.blurb }}
    </p>
    <div id="chart" class="object-center">
      <apexchart
        :type="action.chartOptions.chart.type"
        :options="action.chartOptions"
        :series="action.series"
      ></apexchart>
    </div>
  </div>
  <span
    class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
    aria-hidden="true"
    >Click here to select
    <svg
      class="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
      />
    </svg>
  </span>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  props: ["action", "chartOptions"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {};
  },
};
</script>

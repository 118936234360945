<template>
  <section
    aria-labelledby="products-heading"
    class="mx-auto max-w-2xl px-4 pb-16 pt-12 sm:px-6 sm:pb-24 sm:pt-16 lg:max-w-7xl lg:px-8"
  >
    <h2 id="products-heading" class="sr-only">Products</h2>
    <div
      class="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
    >
      <slot name="gridItems">
        <div>
          <div class="text-center">
            <h3 class="mt-2 text-sm font-semibold text-gray-900">No results</h3>
            <p class="mt-1 text-sm text-gray-500">Try a different search</p>
          </div>
        </div>
      </slot>
    </div>
  </section>
</template>

<template>
  <modal
    :show="showErrorModal"
    @closeModal="showErrorModal = false"
    title="Error"
  >
    <div class="text-red-500">
      <p>
        {{ errorMessage }}
      </p>
    </div>
    <button
      @click="showErrorModal = false"
      class="mt-6 w-12 rounded border bg-twilight-700 text-white"
    >
      Close
    </button>
  </modal>
  <modal :show="showModal" @closeModal="showModal = false" title="Add Option">
    <div class="">
      <text-input
        :wideItem="true"
        placeholder="Product Option Description"
        inputTitle=""
        :displayOnly="false"
        v-model="optionName"
        @keyup.enter="addOption"
      />
    </div>
    <button
      @click="addOption"
      class="text-white0 mt-6 w-12 rounded border bg-twilight-700"
    >
      Add
    </button>
  </modal>

  <hr class="mt-2 w-full" />
  <div class="flex">
    <div class="mt-4 font-bold">
      Options
      <ToolTip
        helpText="Use options where the user can select an option or not to be included, such as a warranty."
        helpTitle=""
      />
    </div>
    <button
      @click="showModal = true"
      class="ml-8 mt-3 h-8 w-12 rounded border bg-twilight-700 text-white"
    >
      + Add
    </button>
  </div>
  <div
    class="mb-4 mt-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <div
      v-for="(l, index) in optionsList"
      :key="l"
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
    >
      <!-- "`Set ${index + 1} Options`" -->
      <div class="col-span-5">{{ l.name }}</div>
      <button @click="optionsList.splice(index, 1)" class="-mt-3 h-10">
        <trash-icon class="h-6 w-6" />
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import { TrashIcon } from "@heroicons/vue/outline";
import Modal from "@/components/modals/PopupModal.vue";
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  components: {
    TextInput,
    TrashIcon,
    Modal,
    ToolTip,
  },
  props: {
    initialOptionsList: {
      type: Array,
      default: () => [{ name: "", description: "" }],
    },
  },
  emits: ["updateOptionsList"],
  data() {
    return {
      numberOfOptions: this.setInitialNumberOfOptions(),
      optionsList: this.initialOptionsList,
      optionName: "",
      showModal: false,
      errorMessage: "",
      showErrorModal: false,
    };
  },
  computed: {},
  methods: {
    addOption() {
      if (this.optionsList.find((obj) => obj.name === this.optionName)) {
        this.showModal = false;
        this.errorMessage = "Option already exists";
        this.showErrorModal = true;
        return;
      }
      if (this.optionName.length === 0) {
        this.showModal = false;
        this.errorMessage = "Please provide an option label";
        this.showErrorModal = true;
        return;
      }
      this.optionsList.push({ name: this.optionName, description: "" });
      this.showModal = false;
      // this.numberOfOptionSets++;
    },
    setInitialNumberOfOptions() {
      let numOptions = 0;
      if (this.initialOptionsList.length >= 1) {
        numOptions = this.initialOptionsList.length;
      }
      return numOptions;
    },
  },
  watch: {
    numberOfOptions() {
      if (this.optionsList.length < this.numberOfOptions) {
        while (this.optionsList.length < this.numberOfOptions) {
          this.optionsList.push({ name: "", description: "" });
        }
      } else if (this.optionsList.length > this.numberOfOptions) {
        while (this.optionsList.length > this.numberOfOptions) {
          this.optionsList.pop();
        }
      }
    },
    optionsList: {
      handler: function (newVal) {
        // console.log("Options list: ", newVal);
        this.$emit("updateOptionsList", newVal);
      },
      deep: true,
    },
  },
};
</script>

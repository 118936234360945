<template>
  <!-- Listing Container -->
  <div class="bg-white">
    <main
      class="mx-auto px-4 pb-24 pt-14 sm:px-6 sm:pb-32 sm:pt-16 lg:max-w-7xl lg:px-8"
    >
      <div
        class="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16"
      >
        <div class="lg:col-span-3 lg:row-end-1">
          <div
            class="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100"
          >
            <slot name="heroImage"></slot>
          </div>
        </div>
        <div
          class="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none"
        >
          <div class="flex flex-col-reverse">
            <div class="mt-4">
              <h1
                class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl"
              >
                <slot name="heroTitle"></slot>
              </h1>
              <slot name="heroDescription"></slot>
            </div>
          </div>
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
            <slot name="heroActions"></slot>
          </div>
        </div>
      </div>
      <slot name="recommendationsTop"></slot>
      <slot name="sections"></slot>
      <slot name="recommendationsBot"></slot>
    </main>
  </div>
</template>

<script setup></script>

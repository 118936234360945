<template>
  <form
    @submit.prevent="submitProduceListingForm"
    :id="formId"
    class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
  >
    <text-input
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Title"
      :required="true"
      inputHtmlType="text"
      v-model="currentListing.title"
    />
    <select-dropdown
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Crop"
      :required="true"
      :selectOptions="cropOptions"
      v-model="currentListing.crop_type"
    />
    <!-- <div class="sm:col-span-6"> -->
    <text-input
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Listing Image (url)"
      :required="false"
      inputHtmlType="text"
      v-model="currentListing.image"
    />
    <text-area
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Description"
      v-model="currentListing.description"
    />

    <!-- </div> -->
    <!-- <text-input
      :wideItem="true"
      inputTitle="Variety"
      :required="true"
      inputHtmlType="text"
      v-model="currentListing.crop_details?.variety"
    /> -->
    <number-input
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Quantity Available"
      :required="true"
      :step="1"
      v-model="currentListing.total_available"
    />
    <select-dropdown
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Units"
      :required="true"
      :selectOptions="unitsOptions"
      v-model="currentListing.qty_units"
    />
    <currency-input
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Asking Price per Unit"
      :required="true"
      :step="0.01"
      v-model="currentListing.asking_price"
    />
    <number-input
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Min Order Quantity"
      :required="true"
      :step="1"
      v-model="currentListing.min_purchase"
    />
    <number-input
      v-if="currentListing"
      :wideItem="true"
      inputTitle="Harvest Year"
      :required="true"
      :step="1"
      v-model="currentListing.harvest_year"
    />
    <component
      :is="cropType"
      @updateFieldDetails="updateFieldDetails"
      :crop="crop"
    />
  </form>
</template>

<script>
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import GrapeDetails from "@/layouts/grower-portal/assets/fields/crop-details/GrapeDetails.vue";
import SoybeanDetails from "@/layouts/grower-portal/assets/fields/crop-details/SoybeanDetails.vue";
import CornDetails from "@/layouts/grower-portal/assets/fields/crop-details/CornDetails.vue";
import DefaultDetails from "@/layouts/grower-portal/assets/fields/crop-details/DefaultDetails.vue";
let cropList = require("@/assets/croplist.js");

export default {
  props: {
    listingId: {
      type: Number,
      default: null,
    },
    // needed to allow a submit button outside the form tags to submit the form
    formId: {
      type: String,
      required: true,
    },
  },
  components: {
    NumberInput,
    CurrencyInput,
    SelectDropdown,
    TextInput,
    TextArea,
    GrapeDetails,
    SoybeanDetails,
    CornDetails,
    DefaultDetails,
  },
  data() {
    const unitsOptions = ["Tons", "Acres"];

    return {
      listOfCropOptionObjects: cropList,
      unitsOptions,
      currentListing: null,
      crop: {
        details: {},
      },
    };
  },
  computed: {
    cropOptions() {
      let options = [];
      for (let crop of this.listOfCropOptionObjects) {
        options.push(crop.name);
      }
      return options.sort();
    },
    cropType() {
      switch (this.currentListing?.crop_type) {
        case "Grape":
          return "GrapeDetails";
        case "Soybeans":
          return "SoybeanDetails";
        case "Corn":
          return "CornDetails";
        default:
          return "DefaultDetails";
      }
    },
    existingListing() {
      return this.$store.getters.getGrowerListingById(this.listingId);
    },
  },
  methods: {
    submitProduceListingForm() {
      if (this.listingId) {
        this.updateListing();
        if (!this.$store.getters.getApiError) {
          this.$router.push({ name: "grower-portal-produce-listings" });
        }
      } else {
        this.createListing();
      }
    },
    updateFieldDetails(e) {
      this.currentListing.crop_details = e;
    },
    readListing() {
      this.currentListing = this.$store.getters.getGrowerListingById(
        this.listingId,
      );
      this.crop.details = this.currentListing?.crop_details;
    },
    async createListing() {
      //this.currentListing.category = "Produce";
      await this.$store.dispatch("createGrowerListing", this.currentListing);
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-listings" });
      }
    },
    updateListing() {
      this.$store.dispatch("updateGrowerListing", this.currentListing);
    },
    clearForm() {
      this.currentListing = {
        harvest_year: null, // Num
        crop_type: null, // string
        crop_details: {
          variety: null, // string
        },
        total_available: null, // Num
        qty_units: null, // String
        asking_price: null, // Num
        min_purchase: null, // Num
        description: null, // String
        title: "",
      };
    },
  },
  watch: {
    listingId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readListing();
        } else {
          this.clearForm();
          this.currentListing.asking_price = 0;
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    existingListing: {
      handler(value) {
        this.currentListing = value;
        this.crop.details = this.currentListing?.crop_details;
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <page-card headingTitle="View Record">
    <template #buttonArea>
      <base-button
        :buttonText="'Delete'"
        :redButton="true"
        :marginLeft="false"
        @buttonClick="deleteRecord(record)"
      />
    </template>
    <template #cardDetails>
      <div
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          :wideItem="true"
          inputTitle="Record Type"
          :displayOnly="true"
          :displayOnlyValue="record?.category"
        />
        <div class="sm:col-span-3">
          <label
            for="territory"
            class="block text-sm font-medium text-gray-700"
          >
            {{ capitalizeFirstLetter(parentAsset.type) }}
            <router-link
              :to="parentAsset.routerObject"
              class="text-twilight-600 hover:text-twilight-900"
              >(View Asset)</router-link
            >
          </label>
          <text-input
            :wideItem="true"
            :displayOnly="true"
            :displayOnlyValue="parentAsset.displayName"
          />
        </div>
        <record-form-layout
          v-if="record"
          :recordProp="record"
          :recordType="record?.category"
          :displayOnly="true"
        />
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import { useToast } from "vue-toastification";
// import AlertModal from "@/components/modals/AlertModal.vue";
// RecordTypes imports
import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};

export default {
  props: {
    // needed to allow a submit button outside the form tags to submit the form
    // formId: {
    //   type: String,
    //   required: true,
    // },
  },
  components: {
    RecordFormLayout,
    PageCard,
    BaseButton,
    TextInput,
  },
  data() {
    return {
      toast: useToast(),
      // Metadata
      recordType: null,
      selectedFields: [],
      // Submit
    };
  },
  computed: {
    record() {
      return this.$store.getters.getRecordById(this.$route.params.recordId);
    },
    parentAsset() {
      if (this.record?.field) {
        let field = this.$store.getters.getFieldById(this.record.field);
        return {
          type: "field",
          id: this.record?.field,
          displayName: field?.properties.name,
          routerObject: {
            name: "single-field",
            params: { fieldId: this.record.field },
          },
        };
      } else if (this.record?.equipment) {
        let equipment = this.$store.getters.getEquipmentById(
          this.record.equipment,
        );
        return {
          type: "equipment",
          id: this.record.equipment,
          displayName: equipment.alias,
          routerObject: {
            name: "single-equipment",
            params: { equipmentId: this.record.equipment },
          },
        };
      } else if (this.record?.task) {
        return {
          type: "task",
          id: this.record.task,
          displayName: this.record.task.toString(),
          routerObject: {
            name: "single-activity",
            params: { activityId: this.record.task },
          },
        };
      } else if (this.record?.crop) {
        let crop = this.$store.getters.getAnyCropById(this.record.crop);
        if (!crop) {
          return {
            type: "",
            id: null,
            displayName: "",
            routerObject: {
              name: "grower-portal-home",
            },
          };
        }
        return {
          type: "crop",
          id: this.record.crop,
          displayName: crop.crop_type,
          routerObject: {
            name: "single-field",
            params: { fieldId: crop.field },
            query: { cropId: this.record.crop },
          },
        };
      } else {
        return {
          type: "",
          id: null,
          displayName: "",
          routerObject: {
            name: "grower-portal-home",
          },
        };
      }
    },
  },
  methods: {
    deleteRecord(record) {
      this.$store.dispatch("deleteRecord", record);
      this.$router.push({ name: "records" });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

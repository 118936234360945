// Core
import GrowerPortalFrame from "@/pages/grower-portal/GrowerPortalFrame";
import GrowerPortalHome from "@/pages/grower-portal/GrowerPortalHome";
import GrowerPortalHelp from "@/pages/grower-portal/GrowerPortalHelp.vue";
import GrowerPortalDirectory from "@/pages/grower-portal/GrowerPortalDirectory";
import GrowerPortalProcesses from "@/pages/grower-portal/GrowerPortalProcesses";

// Settings
import SettingsAll from "@/pages/grower-portal/settings/SettingsAll";
import SettingsSingleUser from "@/pages/grower-portal/settings/SettingsSingleUser";
import SettingsSingleExternalOrg from "@/pages/grower-portal/settings/SettingsSingleExternalOrg.vue";
import SettingsSingleAffiliateOrg from "@/pages/grower-portal/settings/SettingsSingleAffiliateOrg.vue";
import SharedOrgs from "@/pages/auth/SharedOrgs.vue";

// Assets
import FieldsDashboard from "@/pages/grower-portal/assets/FieldsDashboard.vue";
import EquipmentDashboard from "@/pages/grower-portal/assets/EquipmentDashboard.vue";
import Fsa578Report from "@/layouts/grower-portal/assets/fields/Fsa578Report.vue";
//import Fields from "@/pages/grower-portal/assets/FieldsAll.vue";
import NewField from "@/pages/grower-portal/assets/NewField.vue";
import EditField from "@/pages/grower-portal/assets/EditField.vue";
// import Equipment from "@/pages/grower-portal/assets/Equipment.vue";
import EquipmentForm from "@/pages/grower-portal/assets/EquipmentForm.vue";
import GrowerPortalMap from "@/layouts/grower-portal/assets/fields/FieldsMap.vue";
import ImportFields from "@/pages/grower-portal/assets/ImportFields.vue";
import ImportEquipment from "@/pages/grower-portal/assets/ImportEquipment.vue";
import ViewRecords from "@/pages/grower-portal/records/ViewRecords.vue";
import NewRecord from "@/pages/grower-portal/records/NewRecord.vue";
import SingleRecord from "@/pages/grower-portal/records/SingleRecord.vue";
import ImportRecords from "@/pages/grower-portal/records/ImportRecords.vue";

// Operations
import Activities from "@/pages/grower-portal/operations/ActivitiesAll.vue";
import Carbon from "@/pages/grower-portal/operations/carbon/CarbonScenarios.vue";
import NewCarbonScenario from "@/pages/grower-portal/operations/carbon/NewCarbonScenario.vue";
import EditCarbonScenario from "@/pages/grower-portal/operations/carbon/EditCarbonScenario.vue";
import NewCarbonWorksheet from "@/pages/grower-portal/operations/carbon/NewCarbonWorksheet.vue";
import NewActivity from "@/pages/grower-portal/operations/NewActivity.vue";
import EditActivity from "@/pages/grower-portal/operations/EditActivity.vue";
import ChatThread from "@/pages/grower-portal/operations/ChatThread.vue";
import FieldPlans from "@/pages/grower-portal/operations/FieldPlans.vue";
import FieldPlan from "@/pages/grower-portal/operations/FieldPlan.vue";
import ToDo from "@/layouts/grower-portal/operations/ToDo.vue";

// Buy
import VendorsTable from "@/pages/grower-portal/buy/VendorsTable.vue";
import VendorRequest from "@/pages/grower-portal/buy/VendorRequest.vue";
import VendorForm from "@/pages/grower-portal/buy/GrowerVendorForm.vue";
import GrowerDiscoverHome from "@/pages/grower-portal/discover/GrowerDiscoverHome.vue";
import GrowerDiscoverSearchCompanies from "@/pages/grower-portal/discover/GrowerDiscoverSearchCompanies.vue";
import GrowerDiscoverSearchInputs from "@/pages/grower-portal/discover/GrowerDiscoverSearchInputs.vue";
import GrowerDiscoverSearchProducts from "@/pages/grower-portal/discover/GrowerDiscoverSearchProducts.vue";
import GrowerDiscoverShoppingCart from "@/pages/grower-portal/discover/cart/ShoppingCart.vue";
import GrowerDiscoverCheckout from "@/pages/grower-portal/discover/cart/GrowerDiscoverCheckout.vue";
import GrowerDiscoverCompany from "@/pages/grower-portal/discover/GrowerDiscoverCompany.vue";
import GrowerDiscoverVendorProduct from "@/pages/grower-portal/discover/GrowerDiscoverVendorProduct.vue";
import GrowerDiscoverInputProduct from "@/pages/grower-portal/discover/GrowerDiscoverInputProduct.vue";
import GrowerQuotesAll from "@/pages/grower-portal/buy/quotes/GrowerQuotesAll.vue";
import GrowerQuoteEdit from "@/pages/grower-portal/buy/quotes/GrowerQuoteEdit.vue";
import GrowerOrdersAll from "@/pages/grower-portal/buy/orders/GrowerOrdersAll.vue";
import GrowerOrderEdit from "@/pages/grower-portal/buy/orders/GrowerOrderEdit.vue";
import GrowerInputOrderEdit from "@/pages/grower-portal/buy/orders/GrowerInputOrderEdit.vue";
import AllRecs from "@/pages/grower-portal/buy/recommendations/AllRecs.vue";
import ViewRec from "@/pages/grower-portal/buy/recommendations/ViewRec.vue";
import ViewOpp from "@/pages/grower-portal/buy/recommendations/ViewOpp.vue";

// --- Hauling
import GrowerPortalHaulingDashboard from "@/pages/grower-portal/buy/hauling/GrowerPortalHaulingDashboard.vue";
import GrowerPortalHaulingOrder from "@/pages/grower-portal/buy/hauling/GrowerPortalHaulingOrder.vue";
import GrowerPortalHaulingSubmit from "@/pages/grower-portal/buy/hauling/GrowerPortalHaulingSubmit.vue";
import GrowerPortalHaulingReservations from "@/pages/grower-portal/buy/hauling/GrowerPortalHaulingReservations.vue";
import GrowerPortalHaulingSingleReservation from "@/pages/grower-portal/buy/hauling/GrowerPortalHaulingSingleReservation.vue";

// Sell
import GrowerPortalProduceDashboard from "@/pages/grower-portal/sell/produce/GrowerPortalProduceDashboard";
import GrowerPortalProduceListings from "@/pages/grower-portal/sell/produce/listings/GrowerPortalProduceListings";
import NewProduceListing from "@/pages/grower-portal/sell/produce/listings/NewProduceListing";
import EditProduceListing from "@/pages/grower-portal/sell/produce/listings/EditProduceListing";
import GrowerPortalProduceInquiries from "@/pages/grower-portal/sell/produce/listings/InquiriesTable.vue";
import GrowerPortalProduceOrders from "@/pages/grower-portal/sell/produce/orders/GrowerPortalProduceOrders.vue";
import GrowerPortalProduceAllocations from "@/pages/grower-portal/sell/produce/GrowerPortalAllocationsReport.vue";
import GrowerPortalAllocationsByField from "@/layouts/grower-portal/sell/GrowerPortalAllocationsByField.vue";
import NewCropOrder from "@/pages/grower-portal/sell/produce/orders/NewCropOrder";
import EditCropOrder from "@/pages/grower-portal/sell/produce/orders/EditCropOrder";
import EditOfferCropOrder from "@/pages/grower-portal/sell/produce/orders/EditOfferCropOrder";
import EditOfferCropOrderPremiums from "@/pages/grower-portal/sell/produce/orders/EditOfferCropOrderPremiums";
import FinalizeOfferCropOrder from "@/pages/grower-portal/sell/produce/orders/FinalizeOfferCropOrder";
import CropDeliveryDetail from "@/pages/grower-portal/sell/produce/orders/CropDeliveryDetail.vue";
import CropDeliveryNew from "@/pages/grower-portal/sell/produce/orders/CropDeliveryNew.vue";
import ImportOrders from "@/pages/grower-portal/sell/produce/orders/ImportOrders.vue";
import GrowerPortalMarketOffers from "@/pages/grower-portal/sell/produce/offers/GrowerPortalMarketOffers.vue";
import ViewOffer from "@/pages/grower-portal/sell/produce/offers/ViewOffer.vue";
import UsdaEqip from "@/layouts/grower-portal/sell/offers/UsdaEqip.vue";
import EditCropInquiry from "@/pages/grower-portal/sell/produce/orders/EditCropInquiry.vue";

// Customer Management
import CustomersTable from "@/pages/grower-portal/sell/produce/customers/CustomersTable.vue";
import EditCustomer from "@/pages/grower-portal/sell/produce/customers/EditCustomer.vue";
import NewCustomer from "@/pages/grower-portal/sell/produce/customers/NewCustomer.vue";

import InvoiceTable from "@/pages/grower-portal/buy/invoices/InvoiceTable.vue";

export default [
  {
    path: "/app",
    component: GrowerPortalFrame,
    name: "grower-portal-frame",
    redirect: "/app/home",
    meta: {
      title: "App | Parcel",
      displayTitle: "App",
      requiresAuth: true,
      //storeGetActions: ["getGrowerPortalDataOnAppStart"],
    },
    children: [
      {
        path: "home",
        component: GrowerPortalHome,
        name: "grower-portal-home",
        //redirect: "/app/fields",
        meta: {
          auth: true,
          title: "App Home | Parcel",
          displayTitle: "Home",
          mainMenu: "grower-portal-home",
        },
      },
      {
        path: "directory",
        component: GrowerPortalDirectory,
        name: "grower-portal-directory",
        meta: {
          auth: true,
          title: "Directory | Parcel",
          displayTitle: "Home",
        },
      },
      {
        path: "processes",
        component: GrowerPortalProcesses,
        name: "grower-portal-processes",
        meta: {
          auth: true,
          title: "Processes | Parcel",
          displayTitle: "Home",
        },
      },
      {
        path: "help",
        component: GrowerPortalHelp,
        name: "grower-portal-help",
        meta: {
          auth: true,
          title: "Help | Parcel",
          displayTitle: "Help",
          mainMenu: "grower-portal-help",
        },
      },
      {
        path: "settings",
        component: SettingsAll,
        name: "grower-portal-settings",
        meta: {
          auth: true,
          title: "Settings | Parcel",
          displayTitle: "Settings",
          mainMenu: "grower-portal-settings",
        },
      },
      {
        path: "settings/:tab",
        component: SettingsAll,
        name: "grower-portal-settings-tab",
        meta: {
          auth: true,
          title: "Settings | Parcel",
          displayTitle: "Settings",
          mainMenu: "grower-portal-settings",
        },
      },
      {
        path: "settings/users",
        component: SettingsSingleUser,
        name: "settings-new-user",
        meta: {
          auth: true,
          admin: true,
          title: "Add User | Parcel",
          displayTitle: "Add User",
          mainMenu: "grower-portal-settings",
        },
      },
      {
        path: "settings/users/:userId",
        component: SettingsSingleUser,
        name: "single-user",
        meta: {
          auth: true,
          admin: true,
          title: "View User | Parcel",
          displayTitle: "View User",
          mainMenu: "grower-portal-settings",
        },
      },
      {
        path: "settings/external-org",
        component: SettingsSingleExternalOrg,
        name: "settings-new-external-org",
        meta: {
          auth: true,
          title: "Manage External Orgs | Parcel",
          displayTitle: "Manage External Orgs",
          mainMenu: "grower-portal-settings",
        },
      },
      {
        path: "settings/affiliate-org",
        component: SettingsSingleAffiliateOrg,
        name: "settings-new-affiliate-org",
        meta: {
          auth: true,
          title: "Manage Affiliate Orgs | Parcel",
          displayTitle: "Manage Affiliate Orgs",
          mainMenu: "grower-portal-settings",
        },
      },
      {
        path: "switch-org",
        component: SharedOrgs,
        name: "switch-org",
        meta: {
          auth: true,
          title: "Switch Org | Parcel",
        },
      },
      // {
      //   path: "fields-home",
      //   name: "fields-home",
      //   component: FieldsDashboard,
      //   meta: {
      //     auth: true,
      //     title: "My Fields | Parcel",
      //     displayTitle: "My Fields",
      //     storeGetActions: ["getFields"],
      //   },
      // },
      {
        path: "fields",
        name: "fields",
        component: FieldsDashboard,
        meta: {
          auth: true,
          title: "My Fields | Parcel",
          displayTitle: "My Fields",
          storeGetActions: ["getFields"],
          mainMenu: "fields",
        },
      },
      {
        path: "fields/new",
        name: "new-field",
        component: NewField,
        meta: {
          auth: true,
          title: "Add Field | Parcel",
          displayTitle: "Add Field",
          mainMenu: "fields",
        },
      },
      {
        path: "fields/578report",
        name: "578-report",
        component: Fsa578Report,
        meta: {
          auth: true,
          title: "578 Report | Parcel",
          displayTitle: "FSA 578 Acreage Report",
          mainMenu: "fields",
        },
      },
      {
        path: "fields/:fieldId",
        name: "single-field",
        component: EditField,
        meta: {
          auth: true,
          title: "Edit Field | Parcel",
          displayTitle: "View Field",
          mainMenu: "fields",
        },
      },
      {
        path: "fields/import",
        name: "import-fields",
        component: ImportFields,
        meta: {
          auth: true,
          title: "Import Fields | Parcel",
          displayTitle: "Import Fields",
          mainMenu: "fields",
        },
      },
      {
        path: "field-plans",
        name: "field-plans",
        component: FieldPlans,
        meta: {
          auth: true,
          title: "Field Plans | Parcel",
          displayTitle: "Field Management Plans",
          mainMenu: "field-plans",
        },
      },
      {
        path: "field-plans/:fieldId",
        name: "field-plan",
        component: FieldPlan,
        meta: {
          auth: true,
          title: "Field Plan | Parcel",
          displayTitle: "Field Management Plan",
          mainMenu: "field-plans",
        },
      },
      {
        path: "map",
        name: "grower-portal-map",
        component: GrowerPortalMap,
        meta: {
          auth: true,
          title: "Map | Parcel",
          displayTitle: "Map",
          mainMenu: "fields",
        },
      },
      {
        path: "equipment",
        name: "equipment",
        component: EquipmentDashboard,
        meta: {
          auth: true,
          title: "My Equipment | Parcel",
          displayTitle: "My Equipment",
          storeGetActions: ["getEquipment"],
          mainMenu: "equipment",
        },
      },
      {
        path: "equipment/new",
        name: "new-equipment",
        component: EquipmentForm,
        meta: {
          auth: true,
          title: "Add Equipment | Parcel",
          displayTitle: "Add Field",
          mainMenu: "equipment",
        },
      },
      {
        path: "equipment/:equipmentId",
        name: "single-equipment",
        component: EquipmentForm,
        meta: {
          auth: true,
          title: "Edit Equipment | Parcel",
          displayTitle: "View Equipment",
          mainMenu: "equipment",
        },
      },
      {
        path: "equipment/import",
        name: "import-equipment",
        component: ImportEquipment,
        meta: {
          auth: true,
          title: "Import Equipment | Parcel",
          displayTitle: "Import Equipment",
          mainMenu: "equipment",
        },
      },
      {
        path: "records",
        name: "records",
        component: ViewRecords,
        meta: {
          auth: true,
          title: "Records | Parcel",
          displayTitle: "Records",
          storeGetActions: ["getRecords"],
          mainMenu: "records",
        },
      },
      {
        path: "records/new",
        name: "new-record",
        component: NewRecord,
        meta: {
          auth: true,
          title: "Add Record | Parcel",
          displayTitle: "Add Record",
          mainMenu: "records",
        },
      },
      {
        path: "records/:recordId",
        name: "single-record",
        component: SingleRecord,
        meta: {
          auth: true,
          title: "View Record | Parcel",
          displayTitle: "View Record",
          mainMenu: "records",
        },
      },
      {
        path: "records/import",
        name: "import-records",
        component: ImportRecords,
        meta: {
          auth: true,
          title: "Import Records | Parcel",
          displayTitle: "Import Records",
          mainMenu: "records",
        },
      },
      {
        path: "carbon",
        name: "carbon",
        component: Carbon,
        meta: {
          auth: true,
          title: "Carbon | Parcel",
          displayTitle: "Carbon",
          storeGetActions: ["getScenarios", "getSharedScenarios"],
          mainMenu: "carbon",
        },
      },
      {
        path: "carbon/scenario/new",
        name: "carbon-new-scenario",
        component: NewCarbonScenario,
        meta: {
          auth: true,
          title: "New Scenario | Parcel",
          displayTitle: "New Scenario",
          mainMenu: "carbon",
        },
      },
      {
        path: "carbon/worksheet/new",
        name: "carbon-new-worksheet",
        component: NewCarbonWorksheet,
        meta: {
          auth: true,
          title: "New Scenario | Parcel",
          displayTitle: "New Scenario",
          mainMenu: "carbon",
        },
      },
      {
        path: "carbon/scenario/:scenarioId",
        name: "carbon-single-scenario",
        component: EditCarbonScenario,
        meta: {
          auth: true,
          title: "Edit Scenario | Parcel",
          displayTitle: "Carbon Intensity",
          mainMenu: "carbon",
        },
      },
      {
        path: "activities",
        name: "activities",
        component: Activities,
        meta: {
          auth: true,
          title: "Activities | Parcel",
          displayTitle: "Activities",
          mainMenu: "activities",
        },
      },
      {
        path: "todo",
        name: "todo",
        component: ToDo,
        meta: {
          auth: true,
          title: "To do | Parcel",
          displayTitle: "To Do",
          storeGetActions: ["getTasks", "getRecords", "getVendors"],
          mainMenu: "activities",
        },
      },
      {
        path: "activities/new",
        name: "new-activity",
        component: NewActivity,
        props: true,
        meta: {
          auth: true,
          title: "Add Task | Parcel",
          displayTitle: "Add Task",
          mainMenu: "activities",
        },
      },
      {
        path: "activities/:activityId",
        name: "single-activity",
        component: EditActivity,
        meta: {
          auth: true,
          title: "Edit Task | Parcel",
          displayTitle: "View Task",
          mainMenu: "activities",
        },
      },
      {
        path: "activity/:activityId",
        redirect: { name: "single-activity" },
      },
      {
        path: "chat",
        name: "chat",
        component: ChatThread,
        meta: {
          auth: true,
          title: "Discussions | Parcel",
          displayTitle: "Chat",
          storeGetActions: ["getThreads", "getMessages"],
          mainMenu: "chat",
        },
      },
      {
        path: "chat/:threadId",
        name: "chat-thread",
        component: ChatThread,
        meta: {
          auth: true,
          title: "Chat | Parcel",
          displayTitle: "Chat",
          mainMenu: "chat",
        },
      },
      {
        path: "invoices",
        name: "grower-invoices",
        component: InvoiceTable,
        meta: {
          auth: true,
          title: "Invoices | Parcel",
          displayTitle: "Invoices",
          storeGetActions: ["getGrowerInvoices"],
          mainMenu: "grower-invoices",
        },
      },
      {
        path: "buy/hauling",
        name: "grower-portal-hauling-dashboard",
        component: GrowerPortalHaulingDashboard,
        meta: {
          auth: true,
          title: "Hauling Overview | Parcel",
          displayTitle: "Hauling Dashboard",
          storeGetActions: ["getGrowerOrders"],
          mainMenu: "grower-portal-hauling-dashboard",
        },
      },

      {
        path: "discover",
        name: "grower-portal-discover",
        component: GrowerDiscoverHome,
        redirect: { name: "grower-portal-discover-search" },
        meta: {
          auth: true,
          title: "Discover | Parcel",
          displayTitle: "Discover",
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/cart",
        name: "grower-portal-discover-cart",
        component: GrowerDiscoverShoppingCart,
        meta: {
          auth: true,
          title: "Cart | Parcel",
          displayTitle: "Cart",
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/checkout",
        name: "grower-portal-discover-checkout",
        component: GrowerDiscoverCheckout,
        meta: {
          auth: true,
          title: "Checkout | Parcel",
          displayTitle: "Checkout",
          storeGetActions: ["getGrowerInputAgreements"],
          mainMenu: "grower-portal-discover",
        },
      },
      // This is currently a redirect to the inputs search page
      // Need to go through and cleanup the routeName usage of
      // grower-portal-discover-search vs grower-portal-discover-search-inputs
      {
        path: "discover/search",
        name: "grower-portal-discover-search",
        component: GrowerDiscoverSearchInputs,
        redirect: { name: "grower-portal-discover-search-inputs" },
        meta: {
          auth: true,
          title: "Search | Parcel",
          displayTitle: "Search",
          storeGetActions: ["getGrowerInputListings"],
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/search/companies",
        name: "grower-portal-discover-search-companies",
        component: GrowerDiscoverSearchCompanies,
        meta: {
          auth: true,
          title: "Search Companies | Parcel",
          displayTitle: "Search",
          storeGetActions: ["getCompanies", "getGrowerProducts"],
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/search/inputs",
        name: "grower-portal-discover-search-inputs",
        component: GrowerDiscoverSearchInputs,
        meta: {
          auth: true,
          title: "Search Companies | Parcel",
          displayTitle: "Search",
          storeGetActions: ["getGrowerInputListings"],
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/search/products",
        name: "grower-portal-discover-search-products",
        component: GrowerDiscoverSearchProducts,
        meta: {
          auth: true,
          title: "Search Companies | Parcel",
          displayTitle: "Search",
          storeGetActions: ["getGrowerProducts"],
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/companies/:companySlug",
        name: "grower-portal-discover-company",
        component: GrowerDiscoverCompany,
        meta: {
          auth: true,
          title: "Company | Parcel",
          displayTitle: "Company Profile",
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/inputs/:productId",
        name: "grower-portal-discover-single-input",
        component: GrowerDiscoverInputProduct,
        meta: {
          auth: true,
          title: "Product | Parcel",
          displayTitle: "Product",
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "discover/products/:productId",
        name: "grower-portal-discover-single-product",
        component: GrowerDiscoverVendorProduct,
        meta: {
          auth: true,
          title: "Product | Parcel",
          displayTitle: "Product",
          mainMenu: "grower-portal-discover",
        },
      },
      {
        path: "buy/quotes",
        name: "grower-portal-quotes-all",
        component: GrowerQuotesAll,
        meta: {
          auth: true,
          title: "Quotes | Parcel",
          displayTitle: "Quotes",
          storeGetActions: ["getGrowerQuotes", "getGrowerInputOrders"],
          mainMenu: "grower-portal-quotes-all",
        },
      },
      {
        path: "buy/quotes/:quoteId",
        name: "grower-portal-single-quote",
        component: GrowerQuoteEdit,
        meta: {
          auth: true,
          title: "Quotes | Parcel",
          displayTitle: "Quotes",
          mainMenu: "grower-portal-quotes-all",
        },
      },
      {
        path: "buy/orders",
        name: "grower-portal-buy-orders-all",
        component: GrowerOrdersAll,
        meta: {
          auth: true,
          title: "Orders | Parcel",
          displayTitle: "Purchases",
          storeGetActions: ["getGrowerOrders", "getGrowerInputOrders"],
          mainMenu: "grower-portal-buy-orders-all",
        },
      },
      {
        path: "buy/orders/:orderId/category/product",
        name: "grower-portal-buy-edit-single-order",
        component: GrowerOrderEdit,
        meta: {
          auth: true,
          title: "Orders | Parcel",
          displayTitle: "Purchase",
          mainMenu: "grower-portal-buy-orders-all",
        },
      },
      {
        path: "buy/orders/:orderId/category/input",
        name: "grower-portal-single-input-order",
        component: GrowerInputOrderEdit,
        meta: {
          auth: true,
          title: "Orders | Parcel",
          displayTitle: "Orders",
          storeGetActions: ["getGrowerInputOrders"],
          mainMenu: "grower-portal-buy-orders-all",
        },
      },
      {
        path: "recommendations",
        name: "grower-recommendations",
        component: AllRecs,
        meta: {
          auth: true,
          title: "Recommendations | Parcel",
          displayTitle: "Recommendations",
          storeGetActions: [
            "getGrowerRecommendations",
            "getGrowerOpportunities",
          ],
          mainMenu: "grower-recommendations",
        },
      },
      {
        path: "recommendations/order/:oppId",
        name: "grower-single-opportunity",
        component: ViewOpp,
        meta: {
          auth: true,
          title: "View Recommendation | Parcel",
          displayTitle: "View Recommendation",
          mainMenu: "grower-recommendations",
        },
      },
      {
        path: "recommendations/task/:recId",
        name: "grower-single-recommendation",
        component: ViewRec,
        meta: {
          auth: true,
          title: "View Recommendation | Parcel",
          displayTitle: "View Recommendation",
          mainMenu: "grower-recommendations",
        },
      },
      {
        path: "buy/hauling/order",
        name: "grower-portal-hauling-order",
        component: GrowerPortalHaulingOrder,
        meta: {
          auth: true,
          title: "New Hauling Order | Parcel",
          displayTitle: "New Hauling Order",
          mainMenu: "grower-portal-hauling-dashboard",
        },
      },
      {
        path: "buy/hauling/submit",
        name: "grower-portal-hauling-submit",
        component: GrowerPortalHaulingSubmit,
        meta: {
          auth: true,
          title: "Review Hauling Cart | Parcel",
          displayTitle: "Review Reservations",
          mainMenu: "grower-portal-hauling-dashboard",
        },
      },
      {
        path: "buy/hauling/reservations",
        name: "grower-portal-hauling-reservations",
        component: GrowerPortalHaulingReservations,
        meta: {
          auth: true,
          title: "Hauling Reservations | Parcel",
          displayTitle: "Hauling Reservations",
          storeGetActions: ["getGrowerOrders"],
          mainMenu: "grower-portal-hauling-dashboard",
        },
      },
      {
        path: "buy/hauling/reservations/:reservationId",
        name: "grower-portal-hauling-single-reservation",
        component: GrowerPortalHaulingSingleReservation,
        meta: {
          auth: true,
          title: "Hauling Reservation | Parcel",
          displayTitle: "View Hauling Reservation",
          storeGetActions: ["getGrowerOrders"],
          mainMenu: "grower-portal-hauling-dashboard",
        },
      },
      // Selling Routes
      {
        path: "sell/produce",
        name: "grower-portal-produce-dashboard",
        component: GrowerPortalProduceDashboard,
        meta: {
          auth: true,
          title: "Produce | Parcel",
          displayTitle: "Crop Sales Dashboard",
          storeGetActions: ["getGrowerListings", "getGrowerCropOrders"],
          mainMenu: "grower-portal-produce-dashboard",
        },
      },
      {
        path: "sell/produce/allocations",
        name: "grower-portal-produce-allocations",
        component: GrowerPortalProduceAllocations,
        meta: {
          auth: true,
          title: "Allocations | Parcel",
          displayTitle: "Crop Allocations",
          storeGetActions: ["getGrowerListings", "getGrowerCropOrders"],
          mainMenu: "grower-portal-produce-dashboard",
        },
      },
      {
        path: "sell/produce/allocations/:fieldId",
        name: "grower-portal-allocations-by-field",
        component: GrowerPortalAllocationsByField,
        meta: {
          auth: true,
          title: "Crops | Parcel",
          displayTitle: "Crop Allocations",
          storeGetActions: ["getGrowerAllocations", "getGrowerCropOrders"],
          mainMenu: "grower-portal-produce-dashboard",
        },
      },
      {
        path: "sell/produce/listings",
        name: "grower-portal-produce-listings",
        component: GrowerPortalProduceListings,
        meta: {
          auth: true,
          title: "Listings | Parcel",
          displayTitle: "View Listings",
          storeGetActions: ["getGrowerListings"],
          mainMenu: "grower-portal-produce-dashboard",
        },
      },
      {
        path: "sell/produce/listing/new",
        name: "new-grower-portal-produce-listing",
        component: NewProduceListing,
        meta: {
          auth: true,
          title: "New Listing | Parcel",
          displayTitle: "New Listing",
          mainMenu: "grower-portal-produce-listings",
        },
      },
      {
        path: "sell/produce/listing/:listingId",
        name: "grower-portal-produce-listing",
        component: EditProduceListing,
        meta: {
          auth: true,
          title: "Edit Listing | Parcel",
          displayTitle: "Edit Listing",
          mainMenu: "grower-portal-produce-listings",
        },
      },
      {
        path: "sell/produce/inquiries",
        name: "grower-portal-produce-inquiries",
        component: GrowerPortalProduceInquiries,
        meta: {
          auth: true,
          title: "Inquiries | Parcel",
          displayTitle: "View Inquiries",
          storeGetActions: ["getGrowerAllocations", "getGrowerCropOrders"],
          mainMenu: "grower-portal-produce-inquiries",
        },
      },
      {
        path: "sell/produce/inquiries/:inquiryId",
        name: "grower-portal-produce-inqury",
        component: EditCropInquiry,
        meta: {
          auth: true,
          title: "Inquiry | Parcel",
          displayTitle: "View Inquiry",
          storeGetActions: ["getCustomers"],
          mainMenu: "grower-portal-produce-inquiries",
        },
      },
      {
        path: "sell/produce/orders",
        name: "grower-portal-produce-orders",
        component: GrowerPortalProduceOrders,
        meta: {
          auth: true,
          title: "Orders | Parcel",
          displayTitle: "View Orders",
          storeGetActions: ["getGrowerAllocations", "getGrowerCropOrders"],
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/produce/order/new",
        name: "new-grower-portal-produce-order",
        component: NewCropOrder,
        meta: {
          auth: true,
          title: "New Order | Parcel",
          displayTitle: "New Order",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/produce/order/:orderId",
        name: "grower-portal-produce-order",
        component: EditCropOrder,
        meta: {
          auth: true,
          title: "Edit Order | Parcel",
          displayTitle: "Edit Order",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/offer/order/:orderId",
        name: "grower-portal-offer-order",
        component: EditOfferCropOrder,
        meta: {
          auth: true,
          title: "Edit Order | Parcel",
          displayTitle: "Edit Order",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/offer/order/:orderId/premiums",
        name: "grower-portal-offer-order-premiums",
        component: EditOfferCropOrderPremiums,
        meta: {
          auth: true,
          title: "Order Premiums | Parcel",
          displayTitle: "Edit Order",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/offer/order/:orderId/finalize",
        name: "grower-portal-finalize-offer-order",
        component: FinalizeOfferCropOrder,
        meta: {
          auth: true,
          title: "Finalize Order | Parcel",
          displayTitle: "Finalize Order",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/produce/order/:orderId/delivery/new",
        name: "grower-portal-produce-delivery-new",
        component: CropDeliveryNew,
        meta: {
          auth: true,
          title: "New Delivery | Parcel",
          displayTitle: "New Delivery",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/produce/delivery/:deliveryId",
        name: "grower-portal-produce-delivery",
        component: CropDeliveryDetail,
        meta: {
          auth: true,
          title: "Edit Delivery | Parcel",
          displayTitle: "Edit Delivery",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/produce/offers",
        name: "grower-portal-market-offers",
        component: GrowerPortalMarketOffers,
        meta: {
          auth: true,
          title: "Offers | Parcel",
          displayTitle: "View Offers",
          storeGetActions: ["getGrowerMarketOffers"],
          mainMenu: "grower-portal-market-offers",
        },
      },
      {
        path: "sell/produce/offers/:offerId",
        name: "grower-portal-market-offer-single",
        component: ViewOffer,
        meta: {
          auth: true,
          title: "View Offer | Parcel",
          displayTitle: "View Offer",
          mainMenu: "grower-portal-market-offers",
        },
      },
      {
        path: "sell/produce/offers/usda-eqip",
        name: "grower-portal-market-offer-usda-eqip",
        component: UsdaEqip,
        meta: {
          auth: true,
          title: "View Offer | Parcel",
          displayTitle: "View Offer",
          mainMenu: "grower-portal-market-offers",
        },
      },
      {
        path: "sell/produce/order/import",
        name: "import-orders",
        component: ImportOrders,
        meta: {
          auth: true,
          title: "Import Orders | Parcel",
          displayTitle: "Import Orders",
          mainMenu: "grower-portal-produce-orders",
        },
      },
      {
        path: "sell/customers",
        name: "grower-portal-customers",
        component: CustomersTable,
        meta: {
          auth: true,
          title: "Customers | Parcel",
          displayTitle: "View Customers",
          storeGetActions: ["getCustomers"],
          mainMenu: "grower-portal-customers",
        },
      },
      {
        path: "sell/customers/new",
        name: "new-grower-portal-customer",
        component: NewCustomer,
        meta: {
          auth: true,
          title: "New Customer | Parcel",
          displayTitle: "New Customer",
          mainMenu: "grower-portal-customers",
        },
      },
      {
        path: "sell/customers/:customerId",
        name: "edit-grower-portal-customer",
        component: EditCustomer,
        meta: {
          auth: true,
          title: "Customer Details | Parcel",
          displayTitle: "View Customer",
          mainMenu: "grower-portal-customers",
        },
      },
      {
        path: "vendors",
        name: "vendors",
        component: VendorsTable,
        meta: {
          title: "Vendors | Parcel",
          displayTitle: "All Vendors",
          storeGetActions: ["getVendors"],
          mainMenu: "vendors",
        },
      },
      {
        path: "vendors/:id",
        name: "request-vendor",
        component: VendorRequest,
        meta: {
          title: "Vendors | Parcel",
          displayTitle: "Vendor Request",
          mainMenu: "vendors",
        },
      },
      {
        path: "vendors/manage/:vendorId",
        name: "manage-vendor",
        component: VendorForm,
        meta: {
          continueAfterLogin: true,
          title: "Manage Vendor | Parcel",
          displayTitle: "Manage Vendor",
          mainMenu: "vendors",
        },
      },
      {
        path: "redirect/trimble",
        redirect: {
          name: "grower-portal-settings-tab",
          params: { tab: "integrations" },
        },
      },
      {
        path: "redirect/johndeere",
        redirect: (to) => {
          window.location = `${process.env.VUE_APP_API_URL}/v1/auth/johndeere?code=${to.query.code}`;
        },
      },
    ],
  },
];

import _ from "lodash";

export function universalGetter(
  {
    filterFunction = null,
    filter = {},
    match = "",
    sort = [],
    pageSize = null,
    pageNum = 0,
  },
  data,
) {
  // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
  let functionFilterData = filterFunction
    ? _.filter(data, filterFunction)
    : data;
  let filteredData = _.isEmpty(filter)
    ? functionFilterData
    : _.filter(functionFilterData, filter);
  let matchedData = _.isEmpty(match)
    ? filteredData
    : _.filter(filteredData, (item) =>
        _.some(item, (val) => _.includes(_.lowerCase(val), _.lowerCase(match))),
      );
  let sortedData = _.isEmpty(sort) ? matchedData : _.sortBy(matchedData, sort);
  let paginatedData =
    _.isNumber(pageSize) && _.isNumber(pageNum)
      ? _.slice(
          sortedData,
          pageSize * pageNum, // skip
          pageSize * pageNum + pageSize, // limit
        )
      : sortedData;
  return paginatedData;
}

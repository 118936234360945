<template>
  <section aria-labelledby="filter-heading">
    <h2 id="filter-heading" class="sr-only">Product filters</h2>

    <div class="flex items-center justify-between">
      <!-- SortDropdown goes here if desired -->
      <!-- Button to open Mobile Filter Drawer -->
      <span class="mx-2 h-6 w-px bg-gray-200 sm:hidden" aria-hidden="true" />
      <button
        type="button"
        class="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
        @click="mobileFiltersOpen = true"
      >
        Filters
      </button>

      <!-- Desktop Menus for filter -->
      <PopoverGroup class="hidden sm:flex sm:items-baseline sm:space-x-8">
        <Popover
          as="div"
          v-for="(section, sectionIdx) in workingFilters"
          :key="section.name"
          id="menu"
          class="relative z-10 inline-block text-left"
        >
          <div v-if="section.active">
            <div>
              <PopoverButton
                class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:font-bold hover:text-gray-900"
              >
                <span>{{ section.name }}</span>
                <!-- don't show count if we are showing the filter pills
                <span
                  v-if="selectedOptions[sectionIdx] > 0"
                  class="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700"
                  >{{ selectedOptions[sectionIdx] }}</span
                >
                -->
                <ChevronDownIcon
                  class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </PopoverButton>
            </div>

            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <PopoverPanel
                class="absolute -right-32 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <form class="space-y-4">
                  <div
                    v-for="(option, optionIdx) in section.options"
                    :key="option.value"
                    class="flex items-center"
                  >
                    <input
                      :id="`filter-${section.id}-${optionIdx}`"
                      :name="`${section.id}[]`"
                      :value="option.value"
                      v-model="
                        workingFilters[sectionIdx].options[optionIdx].checked
                      "
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      :for="`filter-${section.id}-${optionIdx}`"
                      class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                    >
                      {{ option.label }}
                    </label>
                  </div>
                </form>
              </PopoverPanel>
            </transition>
          </div>
        </Popover>
      </PopoverGroup>
    </div>
    <div v-if="filterPredicatePretty.length > 0">
      <table-toolbar-active-filters
        :activeFilters="filterPredicatePretty"
        @clearFilter="clearSelectedFilter"
        :active="records"
        :total="totalrecords"
      />
    </div>
  </section>
  <section>
    <!-- Mobile filter dialog -->
    <TransitionRoot as="template" :show="mobileFiltersOpen">
      <Dialog
        as="div"
        class="relative z-40 sm:hidden"
        @close="mobileFiltersOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel
              class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl"
            >
              <div class="flex items-center justify-between px-4">
                <h2 class="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  @click="mobileFiltersOpen = false"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <!-- Filters -->
              <form class="mt-4">
                <Disclosure
                  as="div"
                  v-for="(section, sectionIdx) in workingFilters"
                  :key="section.name"
                  class="border-t border-gray-200 px-4 py-6"
                  v-slot="{ open }"
                >
                  <div v-if="section.active">
                    <h3 class="-mx-2 -my-3 flow-root">
                      <DisclosureButton
                        class="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400"
                      >
                        <span class="font-medium text-gray-900">{{
                          section.name
                        }}</span>
                        <span class="ml-6 flex items-center">
                          <ChevronDownIcon
                            :class="[
                              open ? '-rotate-180' : 'rotate-0',
                              'h-5 w-5 transform',
                            ]"
                            aria-hidden="true"
                          />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel class="pt-6">
                      <div class="space-y-6">
                        <div
                          v-for="(option, optionIdx) in section.options"
                          :key="option.value"
                          class="flex items-center"
                        >
                          <input
                            :id="`filter-${section.id}-${optionIdx}`"
                            :name="`${section.id}[]`"
                            :value="option.value"
                            v-model="
                              workingFilters[sectionIdx].options[optionIdx]
                                .checked
                            "
                            type="checkbox"
                            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <!--
                          <input
                            :id="`filter-mobile-${section.id}-${optionIdx}`"
                            :name="`${section.id}[]`"
                            :value="option.value"
                            type="checkbox"
                            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          -->
                          <label
                            :for="`filter-mobile-${section.id}-${optionIdx}`"
                            class="ml-3 text-sm text-gray-500"
                            >{{ option.label }}</label
                          >
                        </div>
                      </div>
                    </DisclosurePanel>
                  </div>
                </Disclosure>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </section>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";

import { ChevronDownIcon } from "@heroicons/vue/solid";
import TableToolbarActiveFilters from "@/components/table/TableToolbarActiveFilters.vue";
import { XIcon } from "@heroicons/vue/outline";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";

const sampleFilters = {
  category: {
    id: "category",
    name: "Category",
    active: true,
    options: {
      inputs: { value: "inputs", label: "Crop Protection", checked: false },
      fertilizer: {
        value: "fertilizer",
        label: "Crop Nutrition",
        checked: false,
      },
      fungicides: {
        value: "fungicides",
        label: "Fungicides",
        checked: false,
      },
      hauling: { value: "hauling", label: "Hauling", checked: false },
      finance: {
        value: "finance",
        label: "Finace and Insurance",
        checked: false,
      },
    },
  },
};

export default {
  props: {
    filters: {
      type: [Array, Object],
      default: () => sampleFilters,
    },
    records: {
      type: Number,
      default: 0,
    },
    totalrecords: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:filters"],
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    TableToolbarActiveFilters,
    XIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  data() {
    return {
      mobileFiltersOpen: false,
      workingFilters: this.filters,
      selectedOptions: [],
    };
  },
  computed: {
    filteredRecords() {
      let filtered = [];
      for (let key in this.workingFilters) {
        // console.log("key", key);
        for (let option in this.workingFilters[key].options) {
          if (this.workingFilters[key].options[option].checked) {
            filtered.push(key);
            continue;
          }
        }
      }
      return filtered;
    },
    filterPredicatePretty() {
      let pretty = [];
      for (let key in this.workingFilters) {
        let filter = "";
        for (let option in this.workingFilters[key].options) {
          if (this.workingFilters[key].options[option].checked) {
            filter += this.workingFilters[key].options[option].label + ", ";
          }
        }
        // console.log("filter", key, ":", filter);
        if (filter.length > 0) pretty.push(filter.slice(0, -2));
        // console.log("pretty", pretty);
      }
      return pretty;
    },
  },
  methods: {
    clearSelectedFilter(value) {
      let filter = this.filteredRecords[value];
      for (let option in this.workingFilters[filter]?.options) {
        // console.log("option", value, this.filteredRecords[value], option);
        if (this.workingFilters[filter]?.options[option]?.checked) {
          this.workingFilters[filter].options[option].checked = false;
        }
      }
    },
    filterCounts() {
      // compute how many options are selected for each filter
      this.selectedOptions = {};
      for (let filter in this.workingFilters) {
        this.selectedOptions[filter] = 0;
        for (let option in this.workingFilters[filter].options) {
          if (this.workingFilters[filter].options[option].checked) {
            this.selectedOptions[filter]++;
          }
        }
      }
    },
  },
  watch: {
    workingFilters: {
      handler(newVal) {
        // NOT USED, we are showing filter pills instead
        //  this.filterCounts();
        this.$emit("update:filters", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style></style>

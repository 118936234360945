<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'customer', label: 'Customer' },
          { name: 'name', label: 'Memo', filtered: false },
          { name: 'amount', label: 'Amount', filtered: false },
          { name: 'status', label: 'Status' },
          { name: 'created', label: 'Date', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="invoices"
        @update:derived-collection="derivedInvoices = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="navigateNewInvoice"
            />
            <table-toolbar-button
              buttonText="Stripe Portal"
              :useLinkIcon="true"
              @buttonClick="navigateStripe"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="i in derivedInvoices" :key="i.id">
        <table-data td-label="Customer">
          {{ i.customer }}
        </table-data>
        <table-data td-label="Memo">
          {{ i.name }}
        </table-data>
        <table-data td-label="Amount">
          {{ i.amount }}
        </table-data>

        <table-data td-label="Status">
          {{ i.status }}
        </table-data>
        <table-data td-label="Date">
          {{ new Date(i.created)?.toLocaleString("en", dateFormat) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'vendor-invoices-edit',
              params: { invoiceId: i.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import client from "@/api/parcel/api.js";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
      derivedInvoices: [],
    };
  },
  methods: {
    navigateNewInvoice() {
      this.$router.push({ name: "vendor-invoices-new" });
    },
    async navigateStripe() {
      const data = await client.stripe.login();
      window.open(data.url, "_blank");
    },
  },
  computed: {
    invoices() {
      return this.$store.state.vendorInvoices.vendorInvoices.map((invoice) => ({
        ...invoice,
        customer: this.$store.getters.getCustomerById(invoice.buyer_id)?.name,
      }));
    },
  },
  // async mounted() {
  //   console.log("INVOICES", JSON.stringify(this.invoices));
  // },
};
</script>

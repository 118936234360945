<!--
REFERENCE
https://tailwindui.com/components/ecommerce/page-examples/storefront-pages#component-464ebe7691b135031411ad52b3560cc1
-->
<template>
  <!-- Search bar -->
  <div class="relative flex bg-white p-2 text-gray-600">
    <input
      class="h-10 w-full rounded-lg border-2 border-gray-300 bg-white px-5 pr-16 text-sm focus:outline-none"
      type="search"
      name="search"
      placeholder="Need something? Search companies and products from the marketplace."
      @keydown.enter="searchMarketplace"
      v-model="search"
    />
    <button @click="searchMarketplace" class="absolute right-0 top-0 mr-4 mt-5">
      <SearchIcon class="h-4 w-4 text-gray-400" />
    </button>
  </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/outline";

// More products...
//discover/search?search=einride
export default {
  components: {
    SearchIcon,
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    searchMarketplace() {
      this.$router.push({
        name: "grower-portal-discover-search",
        query: { search: this.search },
      });
    },
  },
};
</script>

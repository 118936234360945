<template>
  <label class="block text-sm font-medium text-gray-700">
    <div
      class="inline-flex items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="conditionalButtonStyling"
    >
      <span v-if="selectedFile">Selected File: {{ selectedFile }}</span>
      <span v-else>{{ buttonText }}</span>
    </div>
    <input
      type="file"
      @change="handleFileChange"
      class="hidden"
      :accept="accept"
    />
  </label>
</template>

<script>
export default {
  props: {
    buttonText: { type: String, default: "Select File" },
    value: File,
    accept: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    redButton: { type: Boolean, default: false },
    marginLeft: { type: Boolean, default: true },
  },
  emits: ["input"],
  data() {
    return {
      selectedFile: null,
    };
  },
  computed: {
    conditionalButtonStyling() {
      let styles = {
        "bg-twilight-700": !this.redButton && !this.disabled,
        "hover:bg-twilight-800": !this.redButton && !this.disabled,
        "focus:ring-twilight-500": !this.redButton && !this.disabled,
        "bg-red-600": this.redButton && !this.disabled,
        "hover:bg-red-700": this.redButton && !this.disabled,
        "focus:ring-red-500": this.redButton && !this.disabled,
        "ml-1": this.marginLeft,
        "bg-gray-200": this.disabled,
        "hover:bg-gray-200": this.disabled,
      };
      return styles;
    },
  },
  methods: {
    handleFileChange(e) {
      if (e.target?.files[0]) {
        this.selectedFile = e.target.files[0].name;
        this.$emit("input", e.target.files[0]);
      } else this.selectedFile = null;
    },
  },
};
</script>

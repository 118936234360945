<template>
  <Disclosure as="nav" class="bg-white shadow">
    <div class="mx-auto max-w-7xl">
      <div class="flex justify-between">
        <div class="flex">
          <div class="hidden md:ml-6 md:flex md:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <router-link
              :to="{ name: 'grower-portal-discover-search-inputs' }"
              class="inline-flex items-center px-1 pt-1 text-sm font-medium hover:border-gray-300 hover:text-gray-700"
              :class="
                currentNavigation === 'inputs' ? activeClass : inactiveClass
              "
              >Inputs</router-link
            >
            <router-link
              :to="{ name: 'grower-portal-discover-search-products' }"
              class="inline-flex items-center px-1 pt-1 text-sm font-medium hover:border-gray-300 hover:text-gray-700"
              :class="
                currentNavigation === 'products' ? activeClass : inactiveClass
              "
              >Products</router-link
            >
            <router-link
              :to="{ name: 'grower-portal-discover-search-companies' }"
              class="0 inline-flex items-center px-1 pt-1 text-sm font-medium hover:border-gray-300 hover:text-gray-700"
              :class="
                currentNavigation === 'companies' ? activeClass : inactiveClass
              "
              >Companies</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </Disclosure>
</template>

<script setup>
import { ref } from "vue";
import { Disclosure } from "@headlessui/vue";
//define props
defineProps({
  currentNavigation: String,
});
const activeClass = ref(
  "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500",
);
const inactiveClass = ref(
  "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300",
);
</script>

<template>
  <error-notification />
  <!-- component -->
  <div class="bg-grey-lighter flex min-h-screen flex-col">
    <div
      class="container mx-auto flex max-w-3xl flex-1 flex-col items-center justify-center px-2"
    >
      <img :class="logo_class" :src="logo" alt="Parcel Logo" />
      <div class="w-full rounded bg-white px-6 py-8 text-black shadow-md">
        <h1 class="mb-8 text-center text-3xl">Choose your partners</h1>
        <p class="pb-6 text-center"></p>
        <external-orgs
          headingTitle="Simplify My Data Entry"
          headingDescription="Select the external organizations that have access to your account and can help you configure it."
        />
        <SettingsSingleAffiliateOrg
          headingTitle="Crop Supply and Premiums"
          headingDescription="Select the partners you work with. They will provide you practice premium offers and inquiries."
        />
        <p class="px-2">&nbsp;</p>
        <div class="px-10">
          What Comes Next
          <ul class="list-disc">
            <li>Industry leading sustainability market access through GRP.</li>
            <li>
              Free access to the innovative Parcel farm management platform.
            </li>
            <li>Data management integration lowering your reporting burden.</li>
            <div v-if="!(referrer == 'GRP')">
              <li>
                Access to the Parcel Marketplace including purchasing crop
                inputs for less.
              </li>
            </div>
          </ul>
        </div>
        <button
          @click="goToHomePage"
          class="hover:bg-green-dark my-1 w-full rounded bg-green-400 py-3 text-center text-white focus:outline-none"
        >
          Get Started
        </button>
      </div>
      <div class="text-grey-dark mt-6">
        <a
          :href="homeURL"
          class="mt-6 self-center pt-4 text-sm lowercase text-twilight-600"
          >Return to homepage
        </a>
      </div>
    </div>
    <modal
      :show="showLoading"
      title="Getting things ready, setting up your account..."
    >
      <div class="pt-8">
        <div class="pl-32"><loading-spinner /></div>
      </div>
    </modal>
  </div>
</template>

<script>
// const stateList = require("@/assets/statelist.js");
// import PhoneInput from "@/components/form/PhoneInput.vue";
import ErrorNotification from "@/components/notifications/ErrorNotification.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Modal from "@/components/modals/PopupModal.vue";
import SettingsSingleAffiliateOrg from "@/pages/grower-portal/settings/SettingsSingleAffiliateOrg.vue";
import ExternalOrgs from "@/layouts/common/ExternalOrgSettingsForm.vue";
export default {
  components: {
    // PhoneInput,
    ErrorNotification,
    LoadingSpinner,
    Modal,
    SettingsSingleAffiliateOrg,
    ExternalOrgs,
  },
  data() {
    let referrer = this.$store.state.signupReferrer.referrer;
    let homeURL = "https://parcel.ag";
    if (process.env.VUE_APP_ENV === "development") {
      homeURL = "https://dev.parcel.ag";
    }
    if (process.env.VUE_APP_ENV === "sandbox") {
      homeURL = "https://sandbox.parcel.ag";
    }

    return {
      referrer,
      accountType: "new",
      orgType: "grower",
      company: "",
      fullname: "",
      email: "",
      emailValid: true,
      phone: null,
      //   phoneValid: true,
      grower: false,
      vendor: false,
      buyer: false,
      creatingOrg: false,
      homeURL: homeURL,
      errorArray: [],
    };
  },
  computed: {
    logo() {
      let logo = require("../../assets/parcel-logo-name.svg");
      if (this.referrer) {
        let sponsor = this.$store.getters.getSponsorById(this.referrer);
        // console.log("referrer", this.$route.query.referrer, sponsor);
        if (sponsor) {
          logo = sponsor.login_logo_url;
        }
      }
      return logo;
    },
    logo_class() {
      let logo_class = "h-12 w-auto";
      if (this.$route.query.referrer) {
        let sponsor = this.$store.getters.getSponsorById(
          this.$route.query.referrer,
        );
        // console.log("referrer", this.$route.query.referrer, sponsor);
        if (sponsor) {
          logo_class = sponsor.login_logo_class;
        }
      }
      return logo_class;
    },
    showLoading() {
      return false;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    adminUser() {
      let user = this.$store.getters.getCurrentUser;
      user.name = this.fullname;
      user.admin = true;
      user.company = this.company;
      return user;
    },
  },
  methods: {
    async goToHomePage() {
      // this.$router.push({ name: "grower-portal-home" });
      const { redirect, params } = this.$route.query;
      if (redirect) {
        this.$router.push({
          name: redirect,
          params: params ? JSON.parse(params) : {},
        });
      } else {
        this.$router.push({ name: "grower-portal-home" });
      }
    },
  },
  watch: {
    currentUser: {
      handler: function (newVal) {
        if (newVal && newVal.email) {
          // console.log("newVal deep", newVal);
          this.email = newVal.email;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

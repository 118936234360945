<template>
  <purchase-modal
    :open="isPurchaseModalOpen"
    :product="suggestedPurchaseToOpen"
    @clickDismiss="closePurchaseModal"
  />
  <div v-if="derivedProducts.length > 0">
    <base-table>
      <template #headerCells>
        <table-header-cell :isLeadingCell="true" headerText="Product" />
        <table-header-cell
          :hiddenOnMediumAndSmallScreens="true"
          headerText="Price"
        />
        <table-header-cell
          :hiddenOnMediumAndSmallScreens="true"
          headerText="Source"
        />
        <table-header-cell :isEmptyHeader="true" headerText="Product Details" />
      </template>
      <template #bodyRows>
        <tr v-for="product in derivedProducts" :key="product.rowid">
          <table-body-cell :cellData="product.name" :isLeadingCell="true" />
          <table-body-cell
            :hiddenOnSmallScreens="true"
            :cellData="product.price"
          />
          <table-body-cell
            :hiddenOnSmallScreens="true"
            :cellData="product.source"
          />
          <table-body-cell
            :isButtonCell="true"
            @buttonCellClick="openPurchaseModal(product.id)"
            buttonDisplayText="View Product"
          />
        </tr>
      </template>
    </base-table>
  </div>
  <div v-else>
    <table-body-cell
      :hiddenOnMediumAndSmallScreens="false"
      cellData="No Suggested Purchases"
    />
  </div>
</template>

<script>
import PurchaseModal from "@/components/modals/PurchaseModal.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";

const exampleProducts = [
  {
    id: 1,
    name: "Acre Edge Seed",
    price: "$375 / Bag",
    rating: 4.7,
    href: "#",
    source: "Landus Cooperative",
    description:
      "Landus' AcreEdge® seed brand is a geographically-targeted soybean seed lineup bred for results on your acres.",
    imageSrc:
      "https://landus.s3.amazonaws.com/website-landus/images/Acre-Edge-logo.png",
    imageAlt: "Acre Edge Soybean Seed.",
    colors: [
      {
        name: "Washed Black",
        bgColor: "bg-gray-700",
        selectedColor: "ring-gray-700",
      },
      { name: "White", bgColor: "bg-white", selectedColor: "ring-gray-400" },
      {
        name: "Washed Gray",
        bgColor: "bg-gray-500",
        selectedColor: "ring-gray-500",
      },
    ],
  },
  {
    id: 2,
    name: "Fertilizer",
    price: "$220 / Ton",
    rating: 4.7,
    href: "#",
    source: "Landus Cooperative",
    description:
      "Creating a strong, robust plant at emergence is vital to a healthy and productive growing season. Integrus™ starter fertilizer is a proprietary Landus product that is seed-safe and provides an early season source of nutrients.",
    imageSrc:
      "https://images.unsplash.com/photo-1558906307-1a1c52b5ac8a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1744&q=80",
    imageAlt: "Tractor in a field spraying fertilizer.",
    colors: [
      {
        name: "Washed Black",
        bgColor: "bg-gray-700",
        selectedColor: "ring-gray-700",
      },
      { name: "White", bgColor: "bg-white", selectedColor: "ring-gray-400" },
      {
        name: "Washed Gray",
        bgColor: "bg-gray-500",
        selectedColor: "ring-gray-500",
      },
    ],
  },
];

export default {
  components: {
    PurchaseModal,
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
  },
  props: {
    currentField: { type: Number, default: null },
    currentEquipment: { type: Number, default: null },
    currentTask: { type: Number, default: null },
  },
  data() {
    return {
      isPurchaseModalOpen: false,
      suggestedPurchaseToOpen: null,
      pageFixedFilters: {},
      pageSorts: { completed_at: "asc" },
    };
  },
  methods: {
    openPurchaseModal(suggestedPurchaseId) {
      this.suggestedPurchaseToOpen = this.derivedProducts.find(
        (product) => product.id === suggestedPurchaseId,
      );
      this.isPurchaseModalOpen = true;
    },
    closePurchaseModal() {
      this.isPurchaseModalOpen = false;
    },
  },
  computed: {
    combinedFilters() {
      return {
        ...this.filterObject,
        ...this.pageFixedFilters,
      };
    },
    derivedProducts() {
      let derivedProducts = exampleProducts;
      //   let derivedProducts = this.$store.getters.getderivedProducts({
      //     filter: this.combinedFilters, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
      //     match: this.searchValue, // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
      //     sort: this.pageSorts,
      //   });
      //   derivedProducts = derivedProducts.filter(
      //     (product) => product.task == this.currentTask
      //   );
      //   derivedProducts = derivedProducts.concat(this.newRecords);
      //   for (let i = 0; i < derivedProducts.length; i++) {
      //     derivedProducts[i].rowid = i;
      //   }
      return derivedProducts;
    },
  },
};
</script>

<style></style>

<template>
  <div v-if="!nofields" class="mx-auto px-4 sm:px-6 md:px-8">
    <h1 class="text-2xl font-semibold text-gray-900">My Fields</h1>
  </div>
  <div class="mx-auto px-4 sm:px-6 md:px-8">
    <PageCard
      v-if="nofields"
      headingTitle="No Fields"
      headingDescription="Please add a field before viewing the map."
    />
    <fields-map
      :layers="layers"
      :showLayerSelector="true"
      :showTurnByTurn="true"
      :showGoToFeature="true"
      height="h-screen-3/4"
      v-else
    />
  </div>
</template>

<script>
import FieldsMap from "@/components/map/MapViewer.vue";
import PageCard from "@/components/cards/PageCard.vue";

export default {
  data() {
    return {
      nofields: this.$store.state.fields.fields.length === 0,
    };
  },
  components: {
    PageCard,
    FieldsMap,
  },
  methods: {
    navToFields() {
      this.$router.push({ name: "fields" });
    },
  },
  computed: {
    layers() {
      let layers = [];
      let equipment = this.$store.state.equipment.equipment;
      let equipmentJSON = {
        type: "FeatureCollection",
        features: equipment.map((asset) => ({
          type: "Feature",
          id: asset.id,
          geometry: asset.location,
          properties: {
            name: asset.alias,
            link: "/app/equipment/" + asset.id,
          },
        })),
      };
      let equipmentLayer = {
        name: "Equipment",
        geojson: equipmentJSON,
      };
      layers.push(equipmentLayer);

      let fields = this.$store.state.fields.fields;
      let fieldJSON = {
        type: "FeatureCollection",
        features: fields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Fields",
        geojson: fieldJSON,
        //style:{fillColor:"red", opacity: 1, weight: 1, color: "green",}
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);

      return layers; // [{"name": "tractor","geojson":this.geojson}];
    },
  },
};
</script>
<!--
<style>
 page {
    display:flex;
    flex-flow:row;
}
</style>
-->

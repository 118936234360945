<template>
  <div>
    <span
      :class="[
        action.iconBackground,
        action.iconForeground,
        'inline-flex rounded-lg p-3 ring-4 ring-white',
      ]"
    >
      <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
    </span>
  </div>
  <div class="mt-8">
    <h3 class="text-lg font-medium">
      <router-link :to="{ name: clickAction }" class="focus:outline-none">
        <!-- Extend touch target to entire panel -->
        <span class="absolute inset-0" aria-hidden="true" />
        {{ action.name }}
      </router-link>
    </h3>
    <p class="mb-2 mt-2 text-sm text-gray-500">
      {{ action.blurb }}
    </p>
    <div v-for="item in action.list" :key="item">
      <div class="flex">
        <div v-if="item.status == 'complete'">
          <check-circle-icon class="mr-2 h-5 w-5 text-green-400" />
        </div>
        <div v-else><x-circle-icon class="mr-2 h-5 w-5" /></div>
        {{ item.title }}
      </div>
    </div>
  </div>
  <span
    class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
    aria-hidden="true"
    v-if="clickAction?.length > 0"
    >Click here to select
    <!-- arrow -->
    <svg
      class="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
      />
    </svg>
  </span>
</template>
<script>
import { XCircleIcon, CheckCircleIcon } from "@heroicons/vue/outline";
export default {
  components: {
    XCircleIcon,
    CheckCircleIcon,
  },
  props: ["action"],
  computed: {
    clickAction() {
      let clickAction = this.action.routeName;
      for (let item of this.action.list) {
        if (item.status != "complete") {
          clickAction = item.routeName;
          break;
        }
      }
      // console.log("clickAction", clickAction, ":", this.action);
      return clickAction;
    },
  },
};
</script>

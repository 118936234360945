<template>
  <div>
    <page-card
      :headingTitle="
        $store.getters.getBuyerCropVendorById(vendorId)?.seller_details.name ||
        'Vendor'
      "
    >
      <template #buttonArea>
        <submit-button
          buttonText="Save"
          :marginLeft="false"
          :formId="vendorFormId"
        />
      </template>
      <template #cardDetails>
        <form
          @submit.prevent="updateVendor"
          :id="vendorFormId"
          class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
        >
          <text-input
            :wideItem="true"
            inputTitle="Name"
            :required="true"
            inputHtmlType="text"
            v-model="currentVendor.seller_details.name"
          />
          <email-input
            :wideItem="true"
            inputTitle="Contact Email"
            inputHtmlType="text"
            v-model="currentVendor.seller_details.contact_email"
          />
          <checkbox-input
            :wideItem="true"
            inputTitle="View orders from vendor"
            inputHtmlType="text"
            v-model="currentVendor.buyer_confirmed"
            class="pt-6"
          />
        </form>
      </template>
    </page-card>
  </div>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";

export default {
  components: {
    TextInput,
    CheckboxInput,
    EmailInput,
    PageCard,
    SubmitButton,
  },
  data() {
    return {
      vendorFormId: "edit-vendor-form",
      currentVendor: null,
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  methods: {
    readVendor() {
      this.currentVendor = this.$store.getters.getBuyerCropVendorById(
        this.vendorId,
      );
    },
    updateVendor() {
      // this.$store.dispatch("updateBuyerCropVendor", this.currentVendor);
      this.$store.dispatch("updateBuyerCropVendor", {
        id: this.currentVendor.id,
        buyer_confirmed: this.currentVendor.buyer_confirmed,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-vendors" });
      }
    },
  },
  watch: {
    vendorId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readVendor();
        }
        // else {
        // console.log("no vendor id");
        // }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

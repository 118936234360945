<template>
  <div>
    <page-card headingTitle="New Affiliate">
      <template #buttonArea>
        <base-button
          buttonText="Invite"
          :marginLeft="false"
          :redButton="false"
          @buttonClick="addAffiliate"
        />
      </template>
      <template #cardDetails>
        <text-input
          :wideItem="true"
          inputTitle="Email"
          help-text="Enter the email of the affiliate you would like to invite."
          v-model="inviteEmail"
        />
      </template>
    </page-card>
  </div>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";

export default {
  components: {
    TextInput,
    PageCard,
    BaseButton,
  },
  data() {
    return {
      inviteEmail: "",
    };
  },
  methods: {
    addAffiliate() {
      this.$store.dispatch("createControllerMarketAffiliate", {
        invite_email: this.inviteEmail,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "buyer-affiliates" });
      }
    },
  },
};
</script>

import { Magic } from "magic-sdk";

const m = new Magic(process.env.VUE_APP_MAGIC_KEY);

export default {
  async getEmailToken(email) {
    await m.auth.loginWithMagicLink(email);
    const token = await m.user.getIdToken();
    m.user.logout();
    return token;
  },
  async getSMSToken(phoneNumber) {
    await m.auth.loginWithSMS({ phoneNumber });
    const token = await m.user.getIdToken();
    m.user.logout();
    return token;
  },
};

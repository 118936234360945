<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'buyer_name', label: 'Customer' },
          { name: 'type', label: 'Type' },
          { name: 'category', label: 'Category' },
          { name: 'status', label: 'Status' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="recommendations"
        @update:derived-collection="derivedRecommendations = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewRecPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="rec in derivedRecommendations" :key="rec.id">
        <table-data td-label="Customer">
          {{ rec.buyer_name }}
        </table-data>
        <table-data td-label="Type">
          {{ rec.type }}
        </table-data>
        <table-data td-label="Category">
          {{ rec.category }}
        </table-data>
        <table-data td-label="Status">
          {{ rec.status }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'vendor-single-recommendation',
              params: { recId: rec.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedRecommendations: [],
    };
  },
  computed: {
    recommendations() {
      return this.$store.getters.getEnrichedVendorRecommendations({});
    },
  },
  methods: {
    goToNewRecPage() {
      this.$router.push({ name: "vendor-new-recommendation" });
    },
  },
};
</script>

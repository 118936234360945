import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      tasks: [],
    };
  },
  mutations: {
    SET_SHARED_TASKS(state, taskData) {
      state.tasks = taskData;
    },
    ADD_SHARED_TASK(state, taskObject) {
      state.tasks.push(taskObject);
    },
    UPDATE_SHARED_TASK(state, taskObject) {
      state.tasks = state.tasks.filter(function (obj) {
        return obj.id !== taskObject.id;
      });
      state.tasks.push(taskObject);
    },
    DELETE_SHARED_TASK(state, taskObject) {
      state.tasks = state.tasks.filter(function (obj) {
        return obj.id !== taskObject.id;
      });
    },
    RESET_SHARED_TASKS(state) {
      state.tasks = [];
    },
  },
  actions: {
    async getSharedTasks({ commit, rootState }) {
      //console.log("getSharedTasks");

      let taskList = [];
      // first get tasks from customers if the org is a vendor
      for (let customer of rootState.customers.customers) {
        if (
          customer.seller_confirmed === true &&
          customer.buyer_confirmed === true
        ) {
          //console.log("getting tasks for org", customer.id, rootState.customers.customers);
          const data = await client.tasks.read(null, {
            org: customer.id,
          });
          if (data) {
            // add each set of fields to the collection.
            for (let task of data.tasks) {
              task.org = customer.id;
            }
            // console.log("got shared tasks", data.tasks);
            taskList = taskList.concat(data.tasks);
          }
        }
      }
      // then get tasks from growers if the org is a buyer
      for (let vendor of rootState.vendors.vendors) {
        //console.log("getting tasks for org", customer.id, rootState.customers.customers);
        if (
          vendor.seller_confirmed === true &&
          vendor.buyer_confirmed === true
        ) {
          const data = await client.tasks.read(null, {
            org: vendor.id,
          });
          if (data) {
            // add each set of fields to the collection.
            for (let task of data.tasks) {
              task.org = vendor.id;
            }
            // console.log("got shared grower tasks", data.tasks);
            taskList = taskList.concat(data.tasks);
          }
        }
      }
      commit("SET_SHARED_TASKS", taskList);
      // console.log(
      //   "got shared tasks",
      //   taskList,
      //   rootState.sharedTasks.tasks,
      //   rootState.customers.customers
      // );
    },
    async createSharedTask({ commit }, task) {
      // console.log("creating task for customer");
      const data = await client.tasks.create(
        null,
        task,
        task.org ? { org: task.org } : {},
      );
      if (data) {
        // console.log(data);
        commit("ADD_SHARED_TASK", data);
        this.dispatch("getSharedTasks");
      }
    },
    // Object payload
    async updateSharedTask({ commit, rootState }, { taskId, task }) {
      let my_org = rootState.orgs.orgs.find((org) => org.self === true);

      const data = await client.tasks.update(
        taskId,
        task,
        task.org != my_org.id ? { org: task.org } : {},
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_SHARED_TASK", data);
        this.dispatch("getSharedTasks");
      }
    },
    async deleteSharedTask({ commit, rootState }, task) {
      let my_org = rootState.orgs.orgs.find((org) => org.self === true);
      const data = await client.tasks.delete(
        task.id,
        null,
        task.org != my_org.id ? { org: task.org } : {},
      ); //task.id
      if (data) {
        // console.log(data);
        commit("DELETE_SHARED_TASK", task);
        this.dispatch("getSharedTasks");
      }
    },
  },
  getters: {
    getSharedTaskById: (state) => (taskId) => {
      return state.tasks.find((task) => task.id === parseInt(taskId));
    },
    getSharedTasksByField: (state) => (fieldId) => {
      return state.tasks.filter((task) => task.field === fieldId);
    },
    getSharedTasksAssigned: (state, getters) => {
      return state.tasks.filter(
        (task) => task.assigned_org === getters.getCurrentOrg.id,
      );
    },
    getSharedRecommendations: (state) => () => {
      return state.tasks.filter((task) => task.status === "Recommended");
    },
    getSharedActivities: (state) => () => {
      return state.tasks.filter((task) => task.status !== "Recommended");
    },
    getDerivedSharedTasks:
      (state, getters) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // add the user and source user objects to the task so we can search / filter on them.
        let expandedSharedTasks = state.tasks.map((task) => {
          let user = getters.getUserById(task.user) || {};
          let source = getters.getUserById(task.source) || {};

          let field = getters.getSharedFieldById(task.field) || {};
          let equipment = getters.getSharedEquipmentById(task.equipment) || {};
          //let source_org = getters.getOrgById(task.org) || {};
          let customer_org = getters.getOrgById(task.org);
          //console.log("source org in derived", source_org, task.source_org);

          return {
            fieldOrEquipment: field?.properties?.name || equipment?.alias,
            ...task,
            user,
            source,
            customer_org,
            //source_org,
            field,
            equipment,
          };
        });

        // This section checks if a filter or set of filters exists. If it does, it loops through and checks if any have nested objects to filter,
        // like "user.name". If it does, it substitutes the "user.name:value" key for an actual object {user{name:value}}
        // NOTE: only currently used in tasks, if it becomes generally needed, we can move to TableHeaderCellWithFilterButton to make it take effect
        // globally.
        if (
          filter &&
          Object.keys(filter).length > 0 &&
          Object.getPrototypeOf(filter) === Object.prototype
        ) {
          //console.log("break up", filter);
          let newFilter = {};
          // loop through each key in the filter to see if we need to parse it.
          for (let key in filter) {
            //console.log("filterClause", `${key}:${filter[key]}`);
            if (key && key.split(".").length > 1) {
              var schema = newFilter; // a moving reference to internal objects within obj
              var pList = key.split(".");
              var len = pList.length;

              for (var i = 0; i < len - 1; i++) {
                var elem = pList[i];
                if (!schema[elem]) schema[elem] = {};
                schema = schema[elem];
              }
              schema[pList[len - 1]] = filter[key];
              //console.log("cleaned",newFilter);
            } else {
              // Not one we need to break up, just copy it to our new object.
              newFilter[key] = filter[key];
            }
          }
          filter = newFilter;
        }
        //console.log("filter", filter);

        let filteredSharedTasks = _.isEmpty(filter)
          ? expandedSharedTasks
          : _.filter(expandedSharedTasks, filter);
        let matchedSharedTasks = _.isEmpty(match)
          ? filteredSharedTasks
          : _.filter(filteredSharedTasks, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedSharedTasks = _.isEmpty(sort)
          ? matchedSharedTasks
          : _.orderBy(matchedSharedTasks, sortColumns, sortOrders);
        let paginatedSharedTasks =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedSharedTasks,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedSharedTasks;
        return paginatedSharedTasks;
      },
  },
};

<template>
  <vendor-recommendations />
</template>

<script>
import VendorRecommendations from "@/pages/vendor-portal/support/VendorRecommendations.vue";

export default {
  components: {
    VendorRecommendations,
  },
  data() {
    return {};
  },
};
</script>

export default {
  state() {
    return {
      posts: [],
    };
  },
  mutations: {
    SET_POSTS(state, posts) {
      state.posts = posts;
    },
  },
  actions: {
    initBlog({ commit }) {
      let postsArray = [];

      postsArray.push({
        id: 1,
        slug: "farm-management-software",
        title: "Farm Management Software",
        subtitle: "What is it, and do I need it?",
        content: `<a class="underline text-blue-600" href="/fms">Farm management software</a> centralizes, manages, and optimizes the production activities and operations of farms. 
           <p class="pt-4">With farm management software, farmers can become strategic and efficient in their daily farm-related tasks and responsibilities. 
           Farm management software automates the recording and storage of farm data, monitors and analyzes farm activities and consumption, 
           and tracks business expenses and farm budgets. <p>Additionally, the software can support farm financial management with accounting programs, 
           farm planning and procurement functionality, and marketing and budgeting tools.

           <p class="pt-4">While farm management software, crop management software, and precision agriculture software all solve overlapping production and yield concerns, the scope of the former is larger and more comprehensive. Furthermore, specialized farm management solutions exist, tailoring the record-keeping and farm production monitoring functionalities to the specific business needs of dairy or cattle farms, grain farms, cannabis farms, and other types of agribusinesses. However, vendors do offer more generalized, sector-agnostic farm management solutions, benefiting farmers, ranchers, growers, and other agribusiness employees who manage and oversee operations that are multi-sector.
          
           <p class="pt-4">To be considered farm management software, a product must:
          
          <ul class="list-disc pt-4">
          <li>  Track and provide insights into the day-to-day operations and activities of a farm </li>
          <li>Provide tools to improve production efficiency and profitability of a farm</li>
          <li>Track and monitor field workers’ progress and facilitates in-field communication and collaboration</li>
          <li>Offer crop management functionality</li>
          <li>Coexist with other agriculture/agribusiness solutions</li>
          </ul>`,
        image: "keyboard.jpg",
      });
      postsArray.push({
        id: 2,
        slug: "top-farm-management-software-vendors",
        title: "Top 5 Farm Management Software Vendors",
        subtitle:
          "Who should I consider if I want to start using farm management software?",
        content: `
              <a href="/fms" class="underline text-blue-600">Parcel.ag. </a> 
              Parcel focuses on 3 key areas of farming. Discovery, Connection and Collaboration. Because no farming operations operate entirely in a silo with only their own employees doing all the work, Parcel was built from the ground up to safely share information between the farm and their business partners, such as produce buyers, pest control advisors, and produce hauling carriers.  
              
              It provides simple and easy to use field and equipment maintenance work orders, hauling reservations, and sales order management to ensure that all commitments are delivered on. 
              
              <a href="https://discover.parcel.ag" class="underline text-blue-600">Parcel’s Discovery marketplace </a>enables growers to find the latest technologies to increase productivity on the farm. 
              
              Parcel Farm Management Software is free to growers. 
              <p class="pt-4">
              <a href="https://agworld.com" class="underline text-blue-600">AgWorld </a>
              AgWorld provides planning, scheduling, precision ag, and compliance modules. AgWorld focuses on enabling pest control advisors to work with growers on pest and fertilizer applications. 
              
              AgWorld is between $1995 and $4995 per farm per year, depending on features.
              <p class="pt-4">
              <a href="https://conservis.ag" class="underline text-blue-600">Conservis </a>
              Conservis is focused on helping growers identify the profitability of their operations. It includes: Crop plans, work orders, tracking field activity, managing inventory, yield analysis and reporting true cost of production.
              
              Conservis does not publish pricing information.
              <p class="pt-4">
              <a href="https://farmlogs.com"class="underline text-blue-600"> FarmLogs</a>
              FarmLogs gives you the tools and insights you need to: Automatically calculate your cost of production based on the things you actually do. Manage your day-to-day operations with real-time field condition data. Accurately document work for easy reporting and analysis. Track your marketing position and make more profitable crop sales
              FarmLogs is between $180 and $1428 a year for farms less than 10,000 acres
              <p class="pt-4">
              <a href="https://granular.ag"class="underline text-blue-600"> Granular </a>
              Granular is farm management software that makes it easier to run a profitable farm. Their cloud and mobile software helps growers collaborate more effectively in the field and in the office, and make the right calls with the right analytics - anywhere, anytime.

              Granular provides two products, agronomy and business. Agronomy is focused on providing variable rate seeding prescriptions and fertility prescriptions. Business is focused on capturing all field costs to report on farm profitability. It also includes crop planning, input inventory management, harvest reconciliation and work order management. 

              Granular pricing is between $3 and $6 per acre depending on features.
              `,
        image: "advice.jpg",
      });
      postsArray.push({
        id: 3,
        slug: "selling-ag-products-online",
        title: "Selling Ag Products Online",
        subtitle:
          "I have great products, but I don't know how to find growers to sell to. What should I do?",
        content: `Do you sell fertilizers, ag chemicals, or other farm inputs? Do you have unique farm technology that you want to sell to growers? 
             Do you have the best in field sensors or IoT technology for growers? Do you provide services to growers such as hauling or custom farm management?
            <p class="pt-4">
              Prospecting can be tedious and time-consuming, but it’s something that has to be done to keep pipelines healthy.

              <p class="pt-4">
              However, while sales reps can’t avoid prospecting, they can certainly make it easier for themselves. 
              By having a go-to list of places to find prospects, and a procedure for each platform, 
              we no longer have to wonder where or how to find prospects.
              <p class="pt-4">
              Here are three ways salespeople can find farmers fast and keep filling up their pipelines month after month.
              <ol class="list-decimal pt-4">
              <li>
              <h3 class="font-bold"> Let them come to you</h3>
              <p>
              <a href="/discover" class="underline text-blue-600">Parcel.ag</a> makes it easy for growers to find suppliers that meet their needs through its
               ag supplier marketplace, <a href = "https://discover.parcel.ag" class="underline text-blue-600"> discover.parcel.ag </a>. 
               This site enables any grower, regardless of whether they are using parcel.ag FMS, to find and connect with ag suppliers. 
               This is the fastest way to get new leads. 
              Best of all, since they are reaching out to you, they are already interested in what you have to offer, making that first call a warm lead. 
              </li>
              <li>
              <h3 class="font-bold">Local industry groups</h3>
              <p>
              Farmers get a lot of value from sharing ideas and best practices at industry groups. 
              Often led by university cooperative extensions, coops, or group associations such as winegrower commissions in your area, attending scheduled meetings can be a good chance to get to know the growers in your local area. 
              Word of warning, don’t try to overtly pitch your products at these sessions, growers are very sensitive to being sold to, and won’t take kindly to someone trying to co-opt their time. 
              It’s better to meet at sessions like this, and reach out at a more convenient time for the grower to try to generate interest in your offerings. 
              As you attend these meetings, check with the organizers to see if they will accept sponsored content in their newsletters, 
              on their website, or onsite events. 
              Often, in exchange for helping defray the costs of the meeting, you can get a dedicated slot to present your products or services.
              </li>
              <li>
              <h3 class="font-bold">Social media</h3>
              <p>
              Younger farmers are now looking to social media platforms like facebook and linkedin to keep up with what’s happening in the industry. They often post details on their farming operations to help communicate with their prospective customers. This can be a great opportunity to find and connect with growers, regardless of location. 
              
              </li>
              </ol>
              <p class="pt-4">
              When talking about qualified leads -- there's no such thing as "fast prospecting." It takes time to build mutually beneficial relationships and develop the rapport necessary to reach out to a prospect for the first time. But these sources will give you access to large pools of leads just waiting to be developed into your next qualified prospects.  `,
        image: "panning.jpg",
      });
      postsArray.push({
        id: 4,
        slug: "grower-relations",
        title: "Managing Grower Relations",
        subtitle:
          "Want to keep track of every field, when it is ready to harvest, and how yield and quality are shaping up?",
        content: `Keeping track of your produce supply is a complex problem. You may have your own internal growing operations, and you may supplement with outside growers to round out your supply. 
              As your demand forecasts change throughout the year, are you prepared to ensure you have enough supply? 
              <p class="pt-4">
              How do you collaborate with your growers to get all your fruit delivered on time, and with the quality you expect? 
              <p class="pt-4">
              Can they effectively communicate with you when fruit loads either exceed, or may come up short due to weather conditions or other factors? 
              <p class="pt-4">
              Do you visit the field regularly? Do you find it challenging to remember how to get to the field? 
              <p class="pt-4">
              Do you get emails with the latest lab results or field brix readings? 
              Do you find it challenging to keep track of field readiness and the harvest schedule for multiple growers and dozens of fields?
              <p class="pt-4">
              <a href="/srm" class="underline text-blue-600">Parcel.ag’s supplier management</a> provides a free solution to collaborate with your growers, 
              negotiate harvest dates, track field activities, and manage your total supply. Because the tools are free for both you and your growers, 
              you can invite all of them to work together with you. 
              <p class="pt-4">
              If you aren’t using it yet, you really need to check it out. 
                 `,
        image: "grapes.jpg",
      });
      postsArray.push({
        id: 5,
        slug: "how-to-farm-sustainably",
        title: "How to Farm Sustainably",
        subtitle: "How do I farm sustainably, aren’t I already doing that?",
        content: `<p><span style="font-size:14pt"><span style="font-family:Arial"><span style="color:#000000"><strong>What is sustainable farming?</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">We hear a lot about sustainability in the news these days. Some of it is focused on consumers - reduce, reuse, recycle. Other news is focused on industries that pollute or emit greenhouse gasses.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Farming seems to be coming under the sustainability spotlight more and more. You can&rsquo;t turn around without running into a new company offering to help you earn carbon credits by changing farming practices.&nbsp;</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">One of the biggest misconceptions about farming is that it isn&rsquo;t sustainable. First, let&rsquo;s clear some things up. Farming is likely the one industry that focuses on sustainability more than any other. Most farms are multi-generational, and that leads to a lot of focus on leaving the farm in as good, or better, condition than when it was inherited.&nbsp;</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Where there may be opportunities to improve our farming practices is in expanding what we mean by sustainable farming. Sustainability can mean not just making the farm a better farm, but also contributing to the planet&rsquo;s overall health. There are three key areas we can explore that the farm can have a significant impact on the planet as a whole: Biodiversity, Greenhouse gasses, and Water.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Biodiversity</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Much of the midwest focuses heavily on corn and soybeans as the majority crops. Growing just these crops limits the types of wildlife that can survive in these regions. Our focus on clean fields leads us to fight weeds every chance we get. This leads to a reduction in biodiversity of plants and animals.&nbsp;</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Greenhouse gasses</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Climate change has become a political football, but if we consider the overall health of the planet in our sustainability definition, there&rsquo;s no question that reducing fossil fuel consumption will lead to less air pollution, regardless of its effect on the climate.&nbsp;</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Water</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">&ldquo;Whiskey is for drinking and water is for fighting&rdquo;. This quote is attributed to Mark Twain, but has been repeated many times throughout time, especially throughout the West. The styles and venues of the fights have changed, but the foundation remains the same. Water, especially in the West, is our most valuable resource, our lifeblood. It is used to grow food and to grow cities. It provides power to run our homes, factories, and businesses. It sustains our forests and deserts for wildlife and recreation. It is our most powerful and yet most fragile natural resource. Asserting and protecting water rights in the West is a time-honored tradition. Many feel fighting over water is a matter of survival. Unfortunately, agriculture is often a main target of battles over water and who should have access to it.&nbsp;</span></span></span></p>
          <p><br />
          <br />
          &nbsp;</p>
          <p><span style="font-size:14pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Making things better</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">So if we decide to expand our definition of sustainability, where do we start? Is it going to cost more to farm this way? Let&rsquo;s examine some practices that could be applied to our farms.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:12pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Biodiversity</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Increasing biodiversity can help in many unexpected ways. Not only is our planet better off (the next breakthrough medicine may be hiding in a caterpillar munching away on a weed in your headlands), it can actually reduce your direct farming costs. Integrated pest management, or IPM, is a process you can use to solve pest problems while minimizing risks to people and the environment. IPM can be used to manage all kinds of pests anywhere&ndash; agricultural, and wildland or natural areas. IPM is an ecosystem-based strategy that focuses on long-term prevention of pests or their damage through a combination of techniques such as biological control, habitat manipulation, modification of cultural practices, and use of resistant varieties. Pesticides are used only after monitoring indicates they are needed according to established guidelines, and treatments are made with the goal of removing only the target organism. Pest control materials are selected and applied in a manner that minimizes risks to human health, beneficial and nontarget organisms, and the environment.</span></span></span></p>
          <p><br />
          &nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Practical Measures</strong></span></span></span></p>
          <ul>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Manage crops on profitability, not sterility. It&rsquo;s OK to have some weeds in fields, so long as they don&rsquo;t have an economic impact on your crops. We have tried to stop seeds from spreading by knocking everything back for long enough to know this is a fool&#39;s errand.&nbsp;</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create wildlife corridors along water margins, field margins and headlands</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Retain and conserve semi-natural grasslands</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Protect and where necessary restore wetlands including floodplain management</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Follow IPM best practices, as available from your local cooperative extension service</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Cover crops in winter can host insects, spiders, birds and other wildlife</span></span></span></li>
          </ul>
          <p><br />
          &nbsp;</p>
          <p><span style="font-size:12pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Greenhouse gasses</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">There are two ways to reduce greenhouse gasses. First is to emit less, and the second is to convert a gas to something that does not trap heat in our atmosphere.&nbsp;</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Through tweaks to current practices, farms are in a good position to lower their carbon footprint and lock carbon into the soil and vegetation. This process is known as creating &lsquo;carbon sinks&rsquo; or sometimes referred to as &lsquo;carbon sequestration&rsquo;.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Practical Measures</strong></span></span></span></p>
          <ul>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Apply fertilizer, especially Nitrogen, when the plant needs it. Ensure that the form, type, the amount and timing of nitrogen being applied will not result in significant losses due to denitrification, volatilization or leaching. N</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><sub>2</sub></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">O is an extremely potent greenhouse gas which can be released as a result of improper fertilization.&nbsp;</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Take action to control soil erosion</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Protect wetlands from damage by avoiding plowing, drainage and overgrazing</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Consider reduced tillage to protect farm soils and reduce carbon losses. No-till and minimum till practices allow for carbon from plants to be incorporated into the soil, thus trapping carbon there, not enabling it to become carbon dioxide in the atmosphere.</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">On tillage based systems, retaining and incorporating straw and other crop residues will help to maintain soil organic matter</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Manage existing farm woodlands and consider new planting. Trees contain a lot of carbon, and will trap it for dozens or hundreds of years.</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Cover crops can increase soil organic matter, including carbon</span></span></span></li>
          </ul>
          <p>&nbsp;</p>
          <p><span style="font-size:12pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Water</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">As premier stewards of our nation&rsquo;s private lands, farmers and ranchers have several available options that can help save energy while they implement the latest conservation technologies. Irrigation water management plays a crucial role in the conservation of water, and it can also save the producer money.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Agricultural water supply is emerging as a critical natural resource issue. Irrigated agriculture is essential in meeting our food and fiber production needs. As the Nation&rsquo;s largest water user, agriculture accounts for about 80 percent of the country&rsquo;s annual water consumption.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Irrigation water management encourages the application of water in an amount that meets the needs of the growing plant in a manner that avoids extended soil saturation and runoff. By increasing application precision and reducing unneeded applications, water can be conserved and energy can be saved.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">According to the 2002 Farm and Ranch Irrigation Survey, approximately 27 million U.S. acres are under sprinkler irrigation. About 80 percent of these acres use center pivot systems. Studies on the High Plains show that if the acres under medium pressure systems were converted to low pressure, the energy savings could add up to $15 per acre. The conversion of high-pressure systems to low pressure could result in savings of up to $66 per acre.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Other types of irrigation systems can be upgraded to increase irrigation efficiency. Switching from high or medium pressure drip sprinklers to low pressure systems can conserve water and reduce distribution costs.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Diesel-powered pumps are used on about 10 million irrigated acres. A 10 percent improvement in water-use efficiency could reduce annual diesel consumption by 8 gallons per acre, saving about $18,000 on 1,000 acres. Nationwide, farmers could save up to 27 million gallons of fuel and $55 million per year. Replacing old diesel engines and pumps with new, energy-efficient models would further reduce fuel consumption and emissions.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">In addition to improving irrigation pumping and application systems, producers can convert to crops that use less water or implement a water recovery program.</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Practical Measures</strong></span></span></span></p>
          <ul>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Switch to irrigation methods that have less evaporation - drip irrigation, subsoil irrigation, micro-sprinklers.&nbsp;</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Utilize telemetry (soil moisture meters, dendrometers) to water the right amount at the right time to optimize growth and reduce water waste.</span></span></span></li>
            <li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Utilize cover crops to reduce soil erosion and increase water filtration.&nbsp;</span></span></span></li>
          </ul>
          <p><br />
          &nbsp;</p>
          <p><span style="font-size:14pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Summary</strong></span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">As you can see, becoming more sustainable is not just good for the environment, it&#39;s good for your farm. It will save you money, and potentially increase yields and quality as well. As consumers begin to demand better sustainable practices from their grocers, pressure will be applied to growers to implement and maintain good practices.&nbsp;</span></span></span></p>
          <p>&nbsp;</p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Also note that it is not all or nothing. Improving irrigation practices can have a positive effect on fertilizer runoff, reduce diesel emissions (and thus greenhouse gas emissions) and save money.&nbsp;</span></span></span></p>
          <p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Start today - pick a field and experiment. Plant a cover crop this winter. How far can you go?&nbsp;</span></span></span></p>
          <p>&nbsp;</p>
          `,
        image: "sustainable.png",
      });
      commit("SET_POSTS", postsArray);
    },
  },
  getters: {
    getBlogPosts: (state) => () => {
      // console.log("getBlogPosts", state.posts);
      return state.posts;
    },
    getBlogPostById: (state) => (blogId) => {
      return state.posts.find((post) => post.id === blogId);
    },
    getBlogPostBySlug: (state) => (blogSlug) => {
      return state.posts.find((post) => post.slug === blogSlug);
    },
  },
};

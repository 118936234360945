<template>
  <div class="mt-6 bg-white p-4 shadow sm:rounded-md">
    <form @submit.prevent="saveCsv" id="import">
      <div class="flex py-2">
        <select-dropdown
          inputTitle="Step 1: Select Record Type to Import"
          :selectOptions="recordTypes"
          v-model="recordType"
          :wideItem="true"
        />
        <div v-if="recordType" class="ml-4 mt-6">
          <base-button
            @buttonClick="saveTemplate"
            buttonText="Save Template"
            :disabled="recordType == null"
            :helpText="`Download a template for ${recordType} records. This will show what attributes can be imported for this
          record type.`"
          />
        </div>
        <div v-if="recordType" class="ml-4 mt-6 flex max-w-96">
          <checkbox-input
            v-model="mergeRecords"
            inputTitle="Merge records"
            :wideItem="false"
            helpText="If checked, records with the same completion date and category will be merged. Any existing data will be retained. If both new and existing are present, the new one will overwrite the existing one."
          />
        </div>
      </div>
      <div class="flex pb-2">
        <file-select
          buttonText="Step 2: Select a file to import (.csv)"
          @input="setFile"
          accept=".csv"
          :disabled="recordType == null"
        />
        <template v-if="csv != null"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div class="flex">
        <base-button
          @buttonClick="uploadCsvfile"
          buttonText="Step 3: Upload the file"
          :disabled="csv == null"
        />
        <template v-if="uploaded"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div v-if="uploaded">
        <p class="mb-4 mt-4 block text-sm font-bold text-twilight-700">
          Step 4: Map the attributes
        </p>
        <map-attributes
          :attributes="recordAttributes"
          :options="options"
          v-model="mappings"
        />
        <div class="mt-4">
          <submit-button
            formId="import"
            buttonText="Step 5: Import the fields"
            :disabled="!csv"
          />
        </div>
      </div>
    </form>
  </div>
  <modal :show="showReport" title="Import Report">
    <div v-if="importReport">
      <div v-html="importReport" class="prose"></div>
      <base-button
        @buttonClick="closeReport"
        buttonText="Close"
        :disabled="!uploaded"
      />
    </div>
    <div v-else>Loading data...</div>
  </modal>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import FileSelect from "@/components/form/FileSelect.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import MapAttributes from "@/components/import/MapAttributes.vue";
import Modal from "@/components/modals/PopupModal.vue";
import Papa from "papaparse";
import { getNPKEquivalents } from "@/components/composables/convertUnits";
import { postgresDate } from "@/components/composables/dateUtils";

// RecordTypes imports
const RecordTypeSchemas = require("@/layouts/grower-portal/records/RecordTypes.json");
//import L from "leaflet";
// import _ from "lodash";

export default {
  components: {
    CheckCircleIcon,
    FileSelect,
    CheckboxInput,
    BaseButton,
    SubmitButton,
    SelectDropdown,
    MapAttributes,
    Modal,
  },
  props: {},
  data() {
    return {
      csv: null,
      uploaded: false,
      records: [],
      options: [],
      mappings: null,
      addRecords: true,
      updateRecords: true,
      importReport: "",
      missingFields: [],
      showReport: false,
      recordType: null,
      assetType: "field",
      mergeRecords: false,
    };
  },
  computed: {
    fields() {
      return this.$store.getters.getAllFields;
    },
    currentRecords() {
      return this.$store.getters.getAllRecords;
    },
    recordTypes() {
      let recordTypeSchemas = RecordTypeSchemas.filter(
        (recordTypeSchema) => !recordTypeSchema.readonly,
      );
      let filteredRecordTypeSchemas = recordTypeSchemas.filter(
        (recordTypeSchema) => recordTypeSchema.assets === this.assetType,
      );
      recordTypeSchemas = filteredRecordTypeSchemas.map((recordTypeSchema) => {
        return {
          label: recordTypeSchema.category,
          value: recordTypeSchema.category,
        };
      });
      recordTypeSchemas.sort((a, b) => a.label.localeCompare(b.label));
      return recordTypeSchemas;
    },
    recordTypeSchema() {
      return RecordTypeSchemas.find(
        (recordTypeSchema) => recordTypeSchema.category === this.recordType,
      );
    },

    recordAttributes() {
      let record = RecordTypeSchemas.find(
        (recordTypeSchema) => recordTypeSchema.category === this.recordType,
      );
      let attributes = {};
      if (!record) return attributes;
      console.log("record", record);
      attributes.farm = {
        label: "Farm",
        required: false,
      };
      attributes.field = {
        label: "Field",
        required: true,
      };

      if (record.data.result.component != "ComputedString") {
        attributes.result = {
          label: record.data.result.label,
        };
      }
      for (let attribute of record.data.payload) {
        if (attribute.component != "RepeatedSection") {
          attributes[attribute.value] = {
            label: attribute.label,
            required: attribute.required,
          };
        }
      }
      console.log("attributes", attributes);
      return attributes;
    },
  },
  methods: {
    closeReport() {
      this.showReport = false;
      // now reset the upload so we can grab another file
      this.csv = null;
      this.uploaded = false;
      this.records = [];
      this.options = [];
      this.mappings = null;
      this.addRecords = true;
      this.updateRecords = true;
      this.importReport = "";
    },
    setFile(file) {
      this.csv = file;
    },
    async saveTemplate() {
      // save a CSV file with the first rown being the headers found in recordAttributes
      let headers = [];
      // console.log("recordAttributes", this.recordAttributes);
      for (let attribute in this.recordAttributes) {
        // console.log("attribute", attribute);
        headers.push(this.recordAttributes[attribute].label);
      }
      console.log("headers", headers);
      let csv = Papa.unparse([headers]);
      let blob = new Blob([csv], { type: "text/csv" });
      let url = URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = this.recordType + ".csv";
      a.click();
      URL.revokeObjectURL(url);
    },
    async uploadCsvfile() {
      let componentHandle = this;
      let records = [];
      let header = [];
      // console.log("uploadCsvfile");

      await Papa.parse(this.csv, {
        complete: function (results) {
          // console.log("parse done", results);
          // console.log("csvArray", results);
          // grab the first column to get column names
          for (let column of results.data[0]) {
            header.push(column);
          }
          // console.log("header", header);
          for (const [i, row] of results.data.entries()) {
            if (i > 0) {
              //skip the header
              let record = {};
              for (let i = 0; i < header.length; i++) {
                record[header[i]] = row[i];
              }
              records.push(record);
            }
          }
          // console.log("records uploaded", records);
          componentHandle.records = records;
          componentHandle.setMappingOptions();
          componentHandle.uploaded = true;
        },
      });
    },
    setMappingOptions() {
      // grab first record in records and use it to get the attributes
      let options = [];
      if (this.records.length > 0) {
        //console.log("mapping record attributes");
        for (let property in this.records[0]) {
          //console.log("property: ", property);
          options.push({
            value: property.toLowerCase().replace(" ", "_"),
            label: property,
          });
        }
      }
      // console.log("options", options);
      this.options = options;
    },
    toLowerKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase().replace(" ", "_")] = obj[key];
        return accumulator;
      }, {});
    },
    saveCsv() {
      this.formatAsRecords();
    },
    parseNPK(product) {
      console.log("product", product);
      const match = product.match(
        /(\d{1,2})-(\d{1,2})-(\d{1,2})-(\d{1,2}-\d{1,2})/,
      );
      if (match) {
        return [match[1], match[2], match[3], match[4]];
      } else {
        const match = product.match(/(\d{1,2})-(\d{1,2})-(\d{1,2})/);
        if (match) {
          return [match[1], match[2], match[3], null];
        }
      }
    },

    fertilizerAdjustments(record) {
      let payload = record.payload;
      let result = record.result;
      if (payload.nitrogen || payload.phosphorus || payload.potassium) {
        return payload;
      }
      let parsedNPK = this.parseNPK(payload.product);
      if (parsedNPK) {
        Object.assign(payload, {
          nitrogen: parsedNPK[0],
          phosphorus: parsedNPK[1],
          potassium: parsedNPK[2],
          micronutrients: parsedNPK[3],
        });
      }
      let nameNPK = getNPKEquivalents(payload.product);
      if (nameNPK) {
        Object.assign(payload, {
          nitrogen: nameNPK.nitrogen,
          phosphorus: nameNPK.phosphorus,
          potassium: nameNPK.potassium,
          micronutrients: nameNPK.micronutrients,
        });
      }

      if (!result) result = payload.product;
      return record;
    },

    inputAdjustments(record) {
      let payload = record.payload;
      let result = record.result;
      if (!result) result = payload.products;
      return record;
    },

    multiRowPayload(newRecords, record) {
      let existingProduct = newRecords.find(
        (r) =>
          r.fieldName === record.field &&
          r.date === record.completed_at &&
          r.category === record.category,
      );
      // if fertilzer or input, try to put multiple products into one record
      if (existingProduct) {
        let subPayload = {
          product: record.product,
          rate: record.avg_rate,
          area: record.acres_applied,
          units: record.units,
        };
        if (!existingProduct.payload.mix_products) {
          existingProduct.payload.mix_products = [];
        }
        // console.log("adding to existing product", existingProduct, payload);
        existingProduct.payload.mix_products.push(subPayload);
        existingProduct.result = "Multiple Products Applied";
      }
      console.log("existingProduct", existingProduct);
      return existingProduct ? true : false;
    },
    computeResult(record) {
      let result = "";
      console.log("recordTypeSchema", this.recordTypeSchema);
      if (this.recordTypeSchema?.data?.result?.component == "ComputedString") {
        for (let attribute of this.recordTypeSchema.data.result.variables) {
          if (attribute[0] == "{") {
            let variable = attribute.substring(2, attribute.length - 2);
            if (variable == "completed_at") {
              result += record.completed_at;
            } else result += record.payload[variable];
          } else {
            result += attribute;
          }
        }
      } else result = record.result;
      console.log("result", result);
      return result;
    },
    formatAsRecords() {
      let fields = this.fields.map((f) => {
        f.name = f.properties.name.toLowerCase().replace("'", "");
        return f;
      });
      let newRecords = [];
      console.log("records at format", this.records);
      for (let row of this.records) {
        // map the record to the schema
        row = this.toLowerKeys(row, this.mappings);
        let record = {
          payload: {},
        };
        console.log("mappings", this.mappings, row);
        record.category = this.recordType;

        for (const [key, { value }] of Object.entries(this.mappings)) {
          if (value) {
            console.log("mapping", key, value);
            if (key == "farm") record.farm = row[key];
            else if (key == "field") record.field = row[key];
            else if (key == "result") record.result = row[value];
            else if (key == "completed_at")
              record.completed_at = postgresDate(row[value]);
            else record.payload[key] = row[value];
          }
        }
        let existingProduct = false;
        switch (record.category) {
          case "Fertilizer Application":
            record = this.fertilizerAdjustments(record);
            existingProduct = this.multiRowPayload(newRecords, record);
            break;
          case "Input Application":
            record = this.inputAdjustments(record);
            existingProduct = this.multiRowPayload(newRecords, record);
            break;
          default:
            break;
        }
        if (!record.completed_at) {
          // assume today
          record.completed_at = postgresDate(new Date());
        }
        console.log("mapped record", record, existingProduct);
        // find field by farm and field
        let field = fields.find(
          (f) =>
            f.properties.name === record.field &&
            f.properties.farm === record.farm,
        );
        // if not fall back to just field
        if (!field) {
          field = fields.find((f) => f.properties.name === record.field);
        }
        // console.log("field", field);
        if (!field) {
          this.missingFields.push(record.field);
          continue;
        }
        // console.log("field", field);
        // let's figure out which crop to attach to.
        // should get the one who's harvest date is closest but after. fallback is current crop.
        let allCrops = this.$store.getters.getAnyCropByFieldId(field.id);
        // sort newest to oldest so we get the closest match.
        allCrops = allCrops.sort((a, b) => {
          return (
            new Date(b.harvest_range_end).getTime() -
            new Date(a.harvest_range_end).getTime()
          );
        });

        let assignedCrop = null;
        for (let crop of allCrops) {
          if (
            new Date(crop.harvest_range_end).getTime() >
            new Date(record.completed_at).getTime()
          ) {
            assignedCrop = crop;
          }
        }
        if (!assignedCrop)
          assignedCrop = this.$store.getters.getCropByFieldId(field.id);
        let crop = assignedCrop;
        if (!crop) continue;
        record.result = this.computeResult(record);
        // console.log("assignedCrop", assignedCrop);
        console.log("result", record);

        if (!existingProduct) {
          newRecords.push({
            fieldName: record.field,
            crop: crop.id,
            category: record.category,
            payload: record.payload,
            result: record.result,
            completed_at: record.completed_at,
          });
        }
      }
      console.log("new records", newRecords);
      this.saveRecords(newRecords);
    },

    async saveRecords(recordsSet) {
      this.showReport = true;
      // console.log("saving records", recordsSet);
      if (!recordsSet) return;
      this.importReport = "";
      let successfulUpdates = 0;
      for (const row of recordsSet) {
        // console.log("processing a row", row.properties.name);
        let insert = false;
        // check if record exists already

        let matchingRecords = this.currentRecords.filter(
          (r) =>
            r.crop == row.crop &&
            r.result == row.result &&
            r.category == row.category &&
            new Date(r.completed_at).getTime() ==
              new Date(row.completed_at).getTime(),
        );
        let merging = false;
        if (matchingRecords.length >= 1) {
          if (this.mergeRecords) {
            // console.log("merging record", row);
            // delete the existing record
            await this.$store.dispatch("deleteRecord", matchingRecords[0]);
            // fill any data that is missing from the new record
            for (let key in matchingRecords[0].payload) {
              if (!row.payload[key]) {
                row.payload[key] = matchingRecords[0].payload[key];
              }
            }
            merging = true;
            insert = true;
          } else {
            this.importReport +=
              "Record already exists for " + row.fieldName + ", skipping.<br>";
            insert = false;
          }
        } else {
          // console.log(
          //   "inserting new record",
          //   row,
          //   this.currentRecords.filter(
          //     (r) =>
          //       r.crop == row.crop &&
          //       r.result == row.result &&
          //       r.category == row.category,
          //   ),
          // );
          insert = true;
        }
        // no record exists yet, so insert
        if (insert && merging) {
          this.importReport += "Merging ";
        } else if (insert) {
          this.importReport += "Adding new ";
        }
        if (insert) {
          this.importReport +=
            row.category + " on " + row.completed_at + "<br>";
          successfulUpdates++;
          await this.$store.dispatch("createRecord", row, true);
        }
      }
      // console.log("import complete");
      this.importReport +=
        "Successfully inserted " + successfulUpdates + " records.";
      if (this.missingFields.length > 0) {
        this.importReport +=
          "<br>Skipped adding records for Fields: " +
          this.missingFields.join(", ") +
          " which could not be found.";
      }
      this.showReport = true;
    },
  },
};
</script>

export default {
  state() {
    return {
      chosenProduct:
        process.env.VUE_APP_ENV === "development" ||
        process.env.VUE_APP_ENV === "sandbox"
          ? "https://buy.stripe.com/test_dR6cOD7YZav37h65km"
          : "https://buy.stripe.com/bIY8Ac1rYfr7e1q288",
    };
  },
  mutations: {
    SET_CHOSEN_PRODUCT(state, product) {
      // console.log("Subscription choice:", product);
      state.chosenProduct = product;
    },
  },
};

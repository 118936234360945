import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      growerProducts: [],
    };
  },
  mutations: {
    SET_GROWER_PRODUCTS(state, growerProductData) {
      state.growerProducts = growerProductData;
    },
    RESET_GROWER_PRODUCTS(state) {
      state.growerProducts = [];
    },
  },
  actions: {
    async getGrowerProducts({ commit }) {
      const data = await client.grower.services.read();
      if (data) {
        // console.log("growerProducts", data);
        commit("SET_GROWER_PRODUCTS", data.services);
      }
    },
    async getOneGrowerProduct(_, productId) {
      const data = await client.grower.services.readOne(productId);
      return data;
    },
  },
  getters: {
    getGrowerProductSellerById: (state) => (sellerId) => {
      const result = state.growerProducts.find(
        (growerProduct) =>
          growerProduct.seller_details?.id === parseInt(sellerId),
      );
      return result.seller_details;
    },
    getGrowerProductById: (state) => (growerProductId) => {
      return state.growerProducts.find(
        (growerProduct) => growerProduct.id === parseInt(growerProductId),
      );
    },
    getDerivedGrowerProducts:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedGrowerProducts = state.growerProducts.map((product) => ({
          ...product,
          seller_name: product.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterGrowerProducts = filterFunction
          ? _.filter(expandedGrowerProducts, filterFunction)
          : expandedGrowerProducts;
        let filteredGrowerProducts = _.isEmpty(filter)
          ? functionFilterGrowerProducts
          : _.filter(functionFilterGrowerProducts, filter);
        let matchedGrowerProducts = _.isEmpty(match)
          ? filteredGrowerProducts
          : _.filter(filteredGrowerProducts, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedGrowerProducts = _.isEmpty(sort)
          ? matchedGrowerProducts
          : _.sortBy(matchedGrowerProducts, sort);
        let paginatedGrowerProducts =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerProducts,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerProducts;
        return paginatedGrowerProducts;
      },
  },
};

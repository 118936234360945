<template>
  <div class="bg-white">
    <main
      class="mx-auto px-4 pb-24 pt-4 sm:px-6 sm:pb-32 sm:pt-6 lg:max-w-7xl lg:px-8"
    >
      <button
        class="flex text-violet-500 hover:text-twilight-900"
        @click="
          $router.replace({
            name: 'buyer-vendor-single',
            params: { vendorId },
            query: { tab: 'fields' },
          })
        "
      >
        <chevron-left-icon class="mt-1 h-4 w-4" />back
        <span class="sr-only">, Back Button</span>
      </button>
      <h1 class="mb-10 text-3xl font-extrabold tracking-tight text-gray-900">
        Field: {{ field?.properties?.name }}
      </h1>

      <h1 class="mb-10 text-xl font-extrabold tracking-tight text-gray-900">
        Orders
      </h1>
      <base-table>
        <template #headerCells>
          <table-header-cell :isLeadingCell="true" headerText="Order" />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Acres"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Crop"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Estimated Harvest"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Notes"
          />
        </template>
        <template #bodyRows>
          <tr v-for="alloc in allocations" :key="alloc.id">
            <table-body-cell
              :isLeadingCell="true"
              :cellData="'Order #' + alloc.order"
            />
            <table-body-cell
              :hiddenOnMediumAndSmallScreens="true"
              :cellData="alloc.acres"
            />
            <table-body-cell
              :hiddenOnMediumAndSmallScreens="true"
              :cellData="alloc.crop_details.crop_type"
            />
            <table-body-cell
              :hiddenOnMediumAndSmallScreens="true"
              :cellData="alloc.crop_details.harvest_range_start"
            />
            <table-body-cell
              :hiddenOnMediumAndSmallScreens="true"
              :cellData="alloc.notes"
            />
          </tr>
        </template>
      </base-table>
      <h1
        class="mb-10 mt-10 text-xl font-extrabold tracking-tight text-gray-900"
      >
        Observations
      </h1>
      <base-table>
        <template #headerCells>
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="What was observed"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Observed Value"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Location"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Completed On"
          />

          <!-- <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Attachment"
          /> -->
        </template>
        <template #bodyRows>
          <template v-for="rec in records">
            <tr v-if="rec.category == 'Observation'" :key="rec.id">
              <table-body-cell
                :hiddenOnMediumAndSmallScreens="true"
                :cellData="rec.payload?.observation"
              />
              <table-body-cell
                :hiddenOnMediumAndSmallScreens="true"
                :cellData="rec.payload?.value"
              />
              <table-body-cell
                :hiddenOnMediumAndSmallScreens="true"
                :cellData="rec.payload?.location"
              />
              <table-body-cell
                :hiddenOnMediumAndSmallScreens="true"
                :cellData="rec.completed_at?.substring(0, 10)"
              />
              <!-- <table-body-cell
                  :hiddenOnMediumAndSmallScreens="true"
                  :cellData="rec.attachment"
                /> -->
            </tr>
          </template>
        </template>
      </base-table>
      <!-- TODO: uncomment when hauling reservation data is available 
        
      <h1
        class="mt-10 mb-10 text-3xl font-extrabold tracking-tight text-gray-900"
      >
        Hauling
      </h1>
      <base-table>
        <template #headerCells>
          <table-header-cell :isLeadingCell="true" headerText="Field" />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Harvest Date"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Delivery Appointment"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Estimated Tons"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Estimated Loads"
          />
          <table-header-cell
            :hiddenOnMediumAndSmallScreens="true"
            headerText="Hauled By"
          />
        </template>
        <template #bodyRows>
          <template v-for="alloc in allocations">
            <template v-for="rec in alloc.crop_details.records">
              <tr v-if="rec" :key="rec.id">
                <table-body-cell
                  :isLeadingCell="true"
                  :cellData="'Task #' + rec.task"
                />
                <table-body-cell
                  :hiddenOnMediumAndSmallScreens="true"
                  :cellData="rec.result"
                />
                <table-body-cell
                  :hiddenOnMediumAndSmallScreens="true"
                  :cellData="rec.completed_at"
                />
                <table-body-cell
                  :hiddenOnMediumAndSmallScreens="true"
                  :cellData="rec.attachment"
                />
              </tr>
            </template>
          </template>
        </template>
      </base-table> -->
    </main>
  </div>
</template>

<script>
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import { ChevronLeftIcon } from "@heroicons/vue/outline";
export default {
  components: {
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
    ChevronLeftIcon,
  },
  props: {},
  data() {
    // console.log(
    //   "loading order",
    //   parseInt(this.$route.params.fieldId),
    //   this.$store.getters.getSharedFieldById(
    //     parseInt(this.$route.params.fieldId)
    //   ),
    //   this.$store.getters.getBuyerAllocationsByField(
    //     this.$route.params.fieldId
    //   )
    // );
    let vendorId = this.$route.params.vendorId;
    let allocations = this.$store.getters.getBuyerAllocationsByField(
      this.$route.params.fieldId,
    );
    let records = [];
    allocations.forEach((allocation) => {
      // console.log("allocation", allocation);
      if (allocation.shared_data.crop_details?.records) {
        allocation.shared_data.crop_details?.records.forEach((record) => {
          // prevent duplicates
          if (
            records.findIndex((thisRecord) => {
              return record.id === thisRecord.id;
            }) === -1
          ) {
            if (record) {
              let allocationRecord = { ...record };
              let task = this.$store.getters.getSharedTaskById(record.task);
              if (task) {
                allocationRecord.instructions = task.category;
                // console.log("task", task, task.instructions);
                if (task.instructions?.length > 0)
                  allocationRecord.instructions += ": " + task.instructions;
              }
              records.push(allocationRecord);
              if (task) task.instructions = null;
            }
          }
        });
      }
    });

    return {
      allocations,
      records,
      vendorId,
      // records,
      //TODO: add hauling data when available
      // reservations: this.$store.getters.getReservationsByField(
      //   this.$route.params.fieldId
      // ),
    };
  },
  computed: {
    field() {
      let field = {};
      if (this.allocations && this.allocations[0])
        field = this.allocations[0]?.field_details;
      field.id = this.allocations[0]?.field;
      return field;
    },
  },
};
</script>

<template>
  <table-card>
    <table-head
      :columns="[
        { name: 'fieldName', label: 'Field', filtered: false },
        {
          name: 'pickup_datetime',
          label: 'Pickup',
          filtered: false,
        },
        {
          name: 'delivery_datetime',
          label: 'Delivery',
          filtered: false,
        },
        { name: 'prettyStatus', label: 'Status' },
        { name: 'actions', label: '', filtered: false },
      ]"
      :collection="derivedReservations"
      @update:derivedCollection="reservations = $event"
      :defaultFilter="{}"
      :defaultSort="pageSorts"
    >
      <template #toolbarButtons>
        <table-toolbar-button
          buttonText="New"
          :usePlusIcon="true"
          @buttonClick="goToNewReservationPage"
        />
      </template>
    </table-head>

    <tbody>
      <tr v-for="reservation in reservations" :key="reservation.id">
        <table-data td-label="Field" class="flex">
          <field-thumbnail
            :title="reservation.fieldName"
            :geojson="reservation.fieldGeojson"
            v-if="reservation.selectedFieldId"
          ></field-thumbnail>
          {{ reservation.fieldName }}
        </table-data>
        <table-data td-label="Pickup">
          {{ reservation.pickup_datetime_str }}
        </table-data>
        <table-data td-label="Delivery">
          {{ reservation.delivery_datetime_str }}
        </table-data>
        <table-data td-label="Status">
          {{ reservation.prettyStatus }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'grower-portal-buy-edit-single-order',
              params: { orderId: reservation.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import moment from "moment";
export default {
  components: {
    FieldThumbnail,
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  data() {
    return {
      reservations: [],
      searchValue: "",
      pageSorts: { updated: "desc" },
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      },
    };
  },
  computed: {
    derivedReservations() {
      // Filter down to Hauling only ...
      // !!!!!
      //
      let agreements = [];
      let orders = [];

      orders = this.$store.getters.getDerivedGrowerOrders({});
      agreements = this.$store.getters.getDerivedGrowerQuotes({
        filter: { service_category: "Hauling" },
      });

      // console.log("orders", orders, agreements);
      let haulingOrders = orders.filter((order) => {
        return agreements.some((agreement) => {
          return agreement.id === order.agreement;
        });
      });

      // only show reservations for the current year
      let derivedReservations = haulingOrders.filter((reservation) => {
        return (
          new Date(reservation.details?.datetime).getYear() ===
          new Date().getYear()
        );
      });
      let offset = new Date().getTimezoneOffset();

      for (let o of derivedReservations) {
        o.pickup_datetime = moment(new Date(o.details?.datetime))
          .add(offset, "m")
          .toDate();
        o.pickup_datetime_str = o.pickup_datetime.toLocaleString(
          "en",
          this.dateFormat,
        );
        if (o.details?.deliveryDatetime) {
          o.delivery_datetime = moment(new Date(o.details?.deliveryDatetime))
            .add(offset, "m")
            .toDate();
          o.delivery_datetime_str = o.delivery_datetime.toLocaleString(
            "en",
            this.dateFormat,
          );
        } else {
          o.delivery_datetime_str = "N/A";
        }
        if (o.shared_assets.fields.length > 0) {
          o.selectedFieldId = o.shared_assets.fields[0].id;
        } else {
          o.selectedFieldId = o.details?.selectedField?.selectedFieldId;
        }
        let field = this.getFieldInfo(o.selectedFieldId);
        o.fieldName = field?.properties?.name;
        o.fieldGeojson = field;
        o.prettyStatus = this.prettyStatus(o.status);
      }
      return derivedReservations;
    },
  },
  methods: {
    getFieldInfo(fieldId) {
      return this.$store.getters.getFieldById(fieldId);
    },
    getListingInfo(listingId) {
      return this.$store.getters.getBuyerListingById(listingId);
    },
    updateSearchResults(e) {
      this.reservationsToDisplay = e;
    },
    goToNewReservationPage() {
      this.$router.push({ name: "grower-portal-hauling-order" });
    },
    sortColumn(sort) {
      this.pageSorts = sort;
    },
    prettyStatus(status) {
      switch (status) {
        case "buyer_submitted":
          return "Ordered";
        case "buyer_canceled":
          return "Buyer Canceled";
        case "buyer_closed":
          return "Buyer Canceled";
        default:
          return status;
      }
    },
  },
};
</script>

<template>
  <div
    class="mx-auto mt-8 w-full max-w-2xl lg:col-span-4 lg:mt-12 lg:max-w-none"
  >
    <TabGroup as="div" :selectedIndex="selectedTabIndex" @change="changeTab">
      <!-- Tab Buttons for larger screens -->
      <div class="hidden border-b border-gray-200 sm:block">
        <TabList class="-mb-px flex space-x-8">
          <Tab
            v-for="tab in tabSlots"
            :key="tab.slotName"
            as="template"
            v-slot="{ selected }"
          >
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              {{ tab.displayName }}
            </button>
          </Tab>
        </TabList>
      </div>

      <!-- Dropdown for smaller screens -->
      <div class="sm:hidden">
        <select
          class="my-2 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
          v-model="selectedTab"
        >
          <option
            v-for="(tab, index) in tabSlots"
            :key="tab.slotName"
            :value="index"
          >
            {{ tab.displayName }}
          </option>
        </select>
      </div>

      <TabPanels as="template">
        <TabPanel
          v-for="tab in tabSlots"
          :key="tab.slotName"
          class="text-sm text-gray-500"
        >
          <slot :name="tab.slotName" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";

export default {
  props: {
    tabSlots: {
      type: Array,
      requried: true,
    },
    activeTab: { type: Number, default: 0 },
  },
  emits: ["changeTab"],
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
  },
  data() {
    return {
      selectedTab: this.activeTab,
    };
  },
  computed: {
    selectedTabIndex() {
      return this.selectedTab;
    },
  },
  methods: {
    changeTab(index) {
      this.selectedTab = index;
      this.$emit("changeTab", index);
    },
  },
  watch: {
    activeTab(newVal) {
      this.selectedTab = newVal;
    },
  },
};
</script>

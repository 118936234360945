export default {
  state() {
    return {
      show: false,
    };
  },
  mutations: {
    SHOW(state) {
      state.show = true;
    },
    HIDE(state) {
      state.show = false;
    },
  },
  actions: {
    showView() {
      this.commit("SHOW");
    },
    hideView() {
      this.commit("HIDE");
    },
    toggleView() {
      // console.log("toggleView", this.state);
      this.state.viewState.show ? this.commit("HIDE") : this.commit("SHOW");
    },
  },
  getters: {
    getViewState: (state) => {
      return state.show;
    },
  },
};

<template>
  <div class="block sm:table-cell sm:py-3" :class="conditionalShowHeader">
    <div class="inline-flex items-center">
      <th
        v-if="isLeadingCell"
        scope="col"
        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
      >
        <sortable-column
          v-if="sorted"
          :sorted="sorted"
          :headerText="headerText"
          :sortColumns="sortColumns"
          :sortColumn="sortColumn"
          @sort="toggleSort"
        />
        <div class="flex pl-3" v-else>{{ headerText }}</div>
      </th>
      <th
        v-else-if="isEmptyHeader"
        scope="col"
        class="relative py-3.5 pl-3 pr-4 sm:pr-6"
      >
        <span class="sr-only">{{ headerText }}</span>
      </th>
      <th
        v-else
        scope="col"
        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        :class="conditionalHeaderCellStyles"
      >
        <!-- {{ headerText }} -->
        <sortable-column
          v-if="sorted"
          :sorted="sorted"
          :headerText="headerText"
          :sortColumns="sortColumns"
          :sortColumn="sortColumn"
          @sort="toggleSort"
        />
        <div class="flex pl-3" v-else>{{ headerText }}</div>
      </th>
      <div class="hidden py-3 sm:table-cell">
        <Listbox v-if="filterOptions != []">
          <ListboxButton
            class="ml-1 cursor-pointer rounded-lg border border-gray-300 p-1 text-left shadow-sm hover:bg-gray-100 focus:outline-none focus-visible:border-twilight-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            :data-testid="`filter-button-${headerText}`"
          >
            <FilterIcon
              class="hidden h-5 w-5 text-gray-400 sm:block"
              aria-hidden="true"
            />
          </ListboxButton>
          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-100"
          >
            <ListboxOptions
              class="absolute z-50 max-h-60 w-2/12 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                v-for="option in filterOptions"
                :key="option"
                @click="selectFilterValue(option)"
                as="template"
              >
                <li
                  class="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 hover:bg-gray-100"
                >
                  <span class="block truncate font-normal">{{ option }}</span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </Listbox>
      </div>
    </div>
  </div>
  <div class="sm:hidden" v-if="$store.getters.getViewState == true">
    <SelectSearch
      :inputTitle="headerText"
      :wideItem="wideItem"
      :selectOptions="filterOptions"
      v-model="selectSearchIdAttr"
      @onBlur="selectFilterValue($event)"
    />
  </div>
</template>

<script>
import SortableColumn from "./SortableColumn.vue";
import SelectSearch from "../form/SelectSearch.vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { FilterIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    FilterIcon,
    SortableColumn,
    SelectSearch,
  },
  props: {
    headerText: { type: String },
    hiddenOnSmallScreens: { type: Boolean, default: false },
    hiddenOnMediumAndSmallScreens: { type: Boolean, default: false },
    isLeadingCell: { type: Boolean, default: false }, // key column, so always shows
    isEmptyHeader: { type: Boolean, default: false }, // only for link column, so always shows
    filterOptions: { default: [] },
    filterCategory: { type: String },
    sorted: { type: Boolean, default: false },
    sortColumns: { type: Object, default: new Object() },
    sortColumn: { type: String, default: "" },
  },
  data() {
    return {
      selectSearchIdAttr: "",
      wideItem: false,
    };
  },
  emits: ["selectFilterValue", "sort"],
  computed: {
    conditionalHeaderCellStyles() {
      let styles = {
        "sm:table-cell": this.hiddenOnSmallScreens,
        "lg:table-cell": this.hiddenOnMediumAndSmallScreens,
        hidden: this.hiddenOnSmallScreens || this.hiddenOnMediumAndSmallScreens, // hidden should occur if either hidden clause given
      };
      return styles;
    },
    conditionalShowHeader() {
      return this.isLeadingCell && this.viewFilterState == false
        ? ""
        : "hidden ";
    },
    viewFilterState() {
      return this.$store.getters.getViewState;
    },
  },
  methods: {
    selectFilterValue(filterValue) {
      // console.log("selectFilterValue", filterValue);
      let lodashFilterObject = {};
      lodashFilterObject[this.filterCategory] = filterValue;
      // console.log("LODASH OBJECT", lodashFilterObject);
      this.$emit("selectFilterValue", lodashFilterObject);
      this.selectSearchIdAttr = "";
    },
    toggleSort(order) {
      //console.log("sort in tableheader cell", order);
      this.$emit("sort", order);
    },
  },
};
</script>

<template>
  <div class="sm:table-cell sm:py-3" :class="conditionalShowHeader">
    <div class="inline-flex items-center">
      <th
        v-if="isLeadingCell"
        scope="col"
        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
      >
        <!-- {{ headerText }} -->
        <template v-if="viewFilterState != true">
          <sortable-column
            v-if="sorted"
            :sorted="sorted"
            :headerText="headerText"
            :sortColumns="sortColumns"
            :sortColumn="sortColumn"
            @sort="toggleSort"
          />
          <div class="flex" v-else>{{ headerText }}</div>
        </template>
      </th>
      <th
        v-else-if="isEmptyHeader"
        scope="col"
        class="relative py-3.5 pl-3 pr-4 sm:pr-6"
      >
        <span class="sr-only">{{ headerText }}</span>
      </th>
      <th
        v-else
        scope="col"
        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        :class="conditionalHeaderCellStyles"
      >
        <sortable-column
          v-if="sorted"
          :sorted="sorted"
          :headerText="headerText"
          :sortColumns="sortColumns"
          :sortColumn="sortColumn"
          @sort="toggleSort"
        />
        <div class="flex" v-else>{{ headerText }}</div>
      </th>
    </div>
  </div>
</template>

<script>
import SortableColumn from "./SortableColumn.vue";
export default {
  components: {
    SortableColumn,
  },
  props: {
    headerText: { type: String },
    hiddenOnSmallScreens: { type: Boolean, default: false },
    hiddenOnMediumAndSmallScreens: { type: Boolean, default: false },
    isLeadingCell: { type: Boolean, default: false }, // key column, so always shows
    isEmptyHeader: { type: Boolean, default: false }, // only for link column, so always shows
    sorted: { type: Boolean, default: false },
    sortColumns: { type: Object, default: new Object() },
    sortColumn: { type: String, default: "" },
  },
  emits: ["sort"],
  computed: {
    conditionalHeaderCellStyles() {
      let styles = {
        "sm:table-cell": this.hiddenOnSmallScreens,
        "lg:table-cell": this.hiddenOnMediumAndSmallScreens,
        hidden: this.hiddenOnSmallScreens || this.hiddenOnMediumAndSmallScreens, // hidden should occur if either hidden clause given
      };
      return styles;
    },
    conditionalShowHeader() {
      return this.isLeadingCell && this.viewFilterState == false
        ? ""
        : "hidden";
    },
    viewFilterState() {
      return this.$store.getters.getViewState;
    },
  },
  methods: {
    toggleSort(order) {
      //console.log("sort in tableheader cell", order);
      this.$emit("sort", order);
    },
  },
};
</script>

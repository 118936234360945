<template>
  <div :class="itemWidth">
    <label class="block text-sm font-medium text-gray-700">
      <span v-if="!noTitle">
        {{ inputTitle }}
        <span v-if="required" class="text-red-600">*</span>
        <span v-if="helpText">
          <ToolTip :helpText="helpText" :helpTitle="helpTitle" />
        </span>
      </span>
      <div class="margin-auto relative block" v-if="selectOptions">
        <!-- Dropdown Input -->
        <div class="flex flex-row">
          <input
            class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
            :class="conditionalInputStyling"
            :name="name"
            @focus="showOptions()"
            @blur="exit()"
            @keyup="keyMonitor"
            v-model="searchFilter"
            :disabled="displayOnly"
            :required="required"
            :placeholder="placeholder"
            autocomplete="off"
          />
          <ChevronDownIcon
            class="pointer-events-none absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 transform text-gray-500"
            aria-hidden="true"
          />
        </div>
        <!-- Dropdown Menu -->
        <div
          class="absolute z-50 max-h-56 w-full overflow-auto rounded-md border border-gray-400 bg-white drop-shadow-xl"
          v-show="optionsShown && !displayOnly"
        >
          <div
            class="line-height block p-2 hover:bg-gray-200 sm:text-sm"
            @mousedown="selectOption(option)"
            v-for="(option, index) in filteredOptions"
            :key="index"
          >
            {{ option.label || option.value || "-" }}
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/solid";
import ToolTip from "@/components/modals/ToolTip.vue";
import _ from "lodash";

export default {
  components: {
    ChevronDownIcon,
    ToolTip,
  },
  props: {
    inputTitle: { type: String, required: false, default: "Input Field" },
    noTitle: { type: Boolean, required: false, default: false },
    name: { type: String, required: false, default: "dropdown" },
    wideItem: { type: Boolean, default: true },
    narrowItem: { type: Boolean, default: false },
    selectOptions: { type: Array, required: true, default: () => [] },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
    },
    classProp: { type: String },
    required: { type: Boolean, required: false, default: false },
    maxItem: { type: Number, required: false, default: -1 },
    noSort: { type: Boolean, default: false },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: String },
    modelValue: { type: [Number, String] },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue", "onBlur", "filter"],
  data() {
    let options = this.selectOptions;
    options = this.formatOptions(options);

    let searchFilter = "";
    if (this.modelValue) {
      searchFilter =
        options.find((option) => option?.value == this.modelValue)?.label || "";
    }

    return {
      selected: {},
      optionsShown: false,
      searchFilter: searchFilter,
      listOptions: options,
      firstClick: true,
    };
  },
  computed: {
    itemWidth() {
      let itemWidth = "sm:col-span-2";
      if (this.wideItem) itemWidth = "sm:col-span-3";
      if (this.narrowItem) itemWidth = "sm:col-span-1";
      return itemWidth;
    },
    filteredOptions() {
      let filtered = [];
      let options = this.listOptions;
      if (this.firstClick) {
        filtered = options;
      } else {
        const regOption = new RegExp(_.escapeRegExp(this.searchFilter), "ig");
        for (const option of options) {
          if (option.label == null) continue;
          if (this.searchFilter?.length < 1 || option.label.match(regOption)) {
            if (this.maxItem < 0 || filtered.length < this.maxItem)
              filtered.push(option);
          }
        }
      }
      return filtered;
    },
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  methods: {
    selectOption(option) {
      this.firstClick = true;
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.label;
      this.$emit("update:modelValue", this.selected.value);
    },
    showOptions() {
      if (!this.disabled) {
        this.optionsShown = true;
      }
    },
    formatOptions(options) {
      let cleanOptions = [];
      for (let option of options) {
        if (option != null) {
          cleanOptions.push(option);
        }
      }
      options = cleanOptions;
      if (options && typeof options[0] == "string") {
        options = options.map((option) => ({ label: option, value: option }));
      }
      if (this.displayOnly && this.displayOnlyValue) {
        options = [
          { label: this.displayOnlyValue, value: this.displayOnlyValue },
        ];
      }
      if (!this.noSort) {
        if (options.length > 0) {
          options = options.sort((a, b) =>
            a.label == b.label ? 0 : a.label > b.label ? 1 : -1,
          );
        }
      }
      return options;
    },
    exit() {
      this.$emit("onBlur", this.selected.value);
      this.optionsShown = false;
    },
    keyMonitor(event) {
      this.firstClick = false;
      if (event.key === "Enter" && this.filteredOptions[0]) {
        this.selectOption(this.filteredOptions[0]);
      }
    },
  },
  watch: {
    searchFilter(newValue) {
      // console.log("searchFilter Watcher", newValue, this.filteredOptions);
      if (this.displayOnly) {
        this.searchFilter = this.displayOnlyValue;
        return;
      }
      const option = this.filteredOptions.find(
        (opt) => opt.label.toLowerCase() === newValue.toLowerCase(),
      );
      if (option) {
        this.selected = option;
        this.$emit("update:modelValue", this.selected.value);
      } else {
        this.$emit("update:modelValue", newValue);
        this.selected = {};
      }
    },
    selectOptions() {
      this.listOptions = this.formatOptions(this.selectOptions);
      // console.log("selectOptions watcher", this.listOptions, this.modelValue);
      if (this.modelValue) {
        // console.log(
        //   "selectOptions Watcher",
        //   this.modelValue,
        //   this.searchFilter,
        // );
        this.searchFilter =
          this.listOptions.find((option) => option?.value == this.modelValue)
            ?.label || "";
      }
    },
    modelValue(value) {
      if (value && this.listOptions?.length > 0) {
        // console.log(
        //   "computing modelValue",
        //   value,
        //   this.listOptions,
        //   this.modelValue,
        // );

        const option = this.listOptions.find((option) => option.value == value);
        if (option) {
          this.selected = option;
          this.searchFilter = option.label;
        } else {
          this.selected = {};
          this.searchFilter = value; // Set searchFilter to raw value if not found in options
        }
      } else {
        this.searchFilter = "";
      }
    },
    displayOnly() {
      if (this.displayOnly) this.searchFilter = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.searchFilter = this.displayOnlyValue;
    },
  },
};
</script>

<style scoped>
/* Add any additional styles if needed */
.line-height {
  line-height: 1.25rem;
}
.hover\\:bg-gray-200:hover {
  background-color: #e2e8f0; /* Slightly gray color for hover */
}
.border-twilight-500 {
  border-color: #5a67d8; /* Focused border color */
}
</style>

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          {
            name: 'shared_org_name',
            label: 'Partner',
          },
          { name: 'name', label: 'Scenario Name', filtered: false },
          { name: 'crop', label: 'Crop' },
          { name: 'inputs.farm_size', label: 'Acres', filtered: false },

          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="scenarios"
        @update:derived-collection="derivedScenarios = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New Carbon Scenario"
              :usePlusIcon="true"
              @buttonClick="goToNewScenarioPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="s in derivedScenarios" :key="s.id">
        <table-data td-label="Partner Org">
          {{ s.shared_org_name }}
        </table-data>
        <table-data td-label="Scenario Name">
          {{ s.name }}
        </table-data>
        <table-data td-label="Crop">
          {{ s.crop }}
        </table-data>
        <table-data td-label="Acres">
          {{ s.inputs.farm_size }}
        </table-data>

        <table-data class="text-center">
          <router-link
            :to="{
              name: carbonSingleRouteName,
              params: { sharedScenarioId: s.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
  <!-- <div v-else>
      <div class="text-center">
        <h3 class="mt-2 text-sm font-semibold text-gray-900">No results</h3>
        <p class="mt-1 text-sm text-gray-500">Select a new tab</p>
      </div>
    </div> -->
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  props: {},
  data() {
    return {
      derivedScenarios: [],
    };
  },
  computed: {
    scenarios() {
      return this.$store.getters.getEnrichedVendorSharedScenarios({});
    },
    carbonSingleRouteName() {
      return "vendor-carbon-single-shared-scenario";
    },
    carbonNewRouteName() {
      return "vendor-carbon-new-scenario";
    },
  },
  methods: {
    goToNewScenarioPage() {
      this.$router.push({
        name: this.carbonNewRouteName,
      });
    },
  },
};
</script>

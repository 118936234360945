<template>
  <div
    class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6"
    v-if="workingProduct"
  >
    <div
      v-if="
        (workingProduct.order_layout.data_sharing.fields == true ||
          workingProduct.order_layout.data_sharing.equipment == true) &&
        !readOnly
      "
      class="col-span-full grid grid-cols-2 md:grid-cols-10"
    >
      <div
        class="text-left text-xs font-medium uppercase tracking-wider text-gray-500"
      >
        <!-- ORIGIN or ASSET-->
        {{ sharedAssetLabel }}
      </div>
      <div
        v-if="sharedAssetMax"
        class="col-span-7 grid grid-cols-1 md:grid-cols-7"
      >
        <select-search
          class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
          inputTitle="Field"
          :selectOptions="fieldListOptions"
          v-model="singleSelectedField"
          :required="true"
          v-if="
            workingProduct.order_layout.data_sharing.fields == true && !readOnly
          "
          @update:modelValue="setSharedFields"
        />
        <select-search
          class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
          inputTitle="Equipment"
          :selectOptions="equipmentListOptions"
          v-model="singleSelectedEquipment"
          :required="true"
          v-if="
            workingProduct.order_layout.data_sharing.equipment == true &&
            !readOnly
          "
          @update:modelValue="setSharedEquipment"
        />
        <checkbox-input
          v-if="productOrderCategory === 'HaulingOrderDetails'"
          class="col-span-3 ml-4 h-full resize-none whitespace-pre-wrap"
          inputTitle="Include Entry, Parking, & Other Locations?"
          v-model="includeFieldLocations"
        />
      </div>
      <!-- Multiple assets on order -->
      <div v-else class="col-span-7 grid grid-cols-1 md:grid-cols-7">
        <!-- These two selects should only be shown on the order form when a grower is filling it out. The order page / layout will use Shared Assets to display info when readonly. -->
        <!-- Add a map viewer when selecting fields / equipment to ensure the grower is selecting the correct ones. -->
        <list-select-2
          class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
          inputTitle="Select Fields"
          :selectOptions="fields"
          select-options-label="properties.name"
          :maxSelectedItems="sharedAssetMax"
          :rows="5"
          v-if="
            workingProduct.order_layout.data_sharing.fields == true && !readOnly
          "
          @update-selections="currentOrder.shared_assets.fields = $event"
        />
        <list-select-2
          class="col-span-3 ml-1 h-full resize-none whitespace-pre-wrap"
          inputTitle="Select Equipment"
          :selectOptions="equipment"
          select-options-label="alias"
          :maxSelectedItems="sharedAssetMax"
          :rows="5"
          v-if="
            workingProduct.order_layout.data_sharing.equipment == true &&
            !readOnly
          "
          @update-selections="currentOrder.shared_assets.equipment = $event"
        />
      </div>
    </div>

    <div class="mt-2 sm:col-span-6">
      <component
        :is="productOrderCategory"
        :orderDetails="currentOrder.details"
        @updateOrderDetails="updateOrderDetails"
        :readOnly="readOnly"
        ref="orderDetailsComponent"
        :fields="this.currentOrder.shared_assets.fields"
      />
    </div>
    <text-area
      v-for="question in workingProduct.order_layout.form"
      :key="question.question"
      :wideItem="true"
      :inputTitle="question.question"
      v-model="currentOrder.form_response[question.question]"
      required
      :displayOnly="readOnly"
      :displayOnlyValue="currentOrder.form_response[question.question]"
    />
    <select-dropdown
      v-for="variant in workingProduct.order_layout.variants"
      :key="variant.label"
      :inputTitle="variant.label"
      :wideItem="true"
      required
      :selectOptions="variant.choices.map((choice) => choice.name)"
      v-model="currentOrder.variants[variant.label]"
      :displayOnly="readOnly"
      :displayOnlyValue="currentOrder.variants[variant.label]"
    />
    <checkbox-input
      v-for="option in workingProduct.order_layout.options"
      :key="option.name"
      :inputTitle="option.name"
      :wideItem="true"
      v-model="currentOrder.options[option.name]"
      :displayOnly="readOnly"
      :displayOnlyValue="currentOrder.options[option.name]"
    />
  </div>
  <!-- {{ currentOrder }} -->
</template>

<script>
import TextArea from "@/components/form/TextArea.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import ListSelect from "@/components/form/ListSelect.vue";
import ListSelect2 from "@/components/form/ListSelect2.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";

import DefaultOrderDetails from "@/layouts/common/orders/order-details/DefaultOrderDetails.vue";
import HaulingOrderDetails from "@/layouts/common/orders/order-details/HaulingOrderDetails.vue";
import InputOrderDetails from "@/layouts/common/orders/order-details/InputOrderDetails.vue";
import { useToast } from "vue-toastification";

// import moment from "moment";
export default {
  components: {
    TextArea,
    SelectDropdown,
    CheckboxInput,
    ListSelect,
    DefaultOrderDetails,
    HaulingOrderDetails,
    InputOrderDetails,
    SelectSearch,
    ListSelect2,
  },
  emits: ["updateOrder"],
  props: {
    // product is the product id that will determine what the order builder looks like
    // REQUIRED to render the order form
    product: {
      type: Object,
      default: null,
    },
    // activeAgreementId is the agreement id that will be used to create the order
    // REQUIRED to create a submissable order object
    activeAgreementId: {
      type: Number,
      default: null,
    },
    // order is if you have a recommendation or saved partial order to load in
    order: {
      type: Object,
      default: null,
    },
    // fields is an array of fields that you will be allowed to select if asset_sharing fields is on
    fields: {
      type: Array,
      default: null,
    },
    // equipment is an array of equipment that you will be allowed to select if asset_sharing equipment is on
    equipment: {
      type: Array,
      default: null,
    },
    // locations is an array of locations that will be transmitted alongside their parent assets
    locations: {
      type: Array,
      default: null,
    },
    // readOnly - determines if all the fields should accept input or not
    readOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const toast = useToast();
    return {
      currentOrder: this.order
        ? this.order
        : {
            agreement: null,
            variants: {},
            options: {},
            form_response: {},
            shared_assets: {
              fields: [],
              equipment: [],
              locations: [],
            },
            details: {},
          },
      includeFieldLocations: true,
      singleSelectedField: null,
      singleSelectedEquipment: null,
      toast,
    };
  },
  computed: {
    workingProduct() {
      return this.product;
    },
    workingAgreementId() {
      return this.activeAgreementId;
    },
    productOrderCategory() {
      switch (this.workingProduct.category) {
        case "Hauling":
          return "HaulingOrderDetails";
        case "Recommendation":
          return "InputOrderDetails";
        default:
          return "DefaultOrderDetails";
      }
    },
    sharedAssetLabel() {
      switch (this.workingProduct.category) {
        case "Hauling":
          return "Origin";
        default:
          return "Asset";
      }
    },
    // determines if the form should only allow a single asset of any approved type to be shared
    sharedAssetMax() {
      switch (this.workingProduct.category) {
        case "Hauling":
          return 1;
        default:
          return null; // no max
      }
    },
    selectedLocations() {
      let selectedLocations = [];
      if (
        this.locations &&
        this.includeFieldLocations &&
        this.currentOrder.shared_assets.fields &&
        this.productOrderCategory === "HaulingOrderDetails"
      ) {
        this.currentOrder.shared_assets.fields.forEach((field) => {
          // for each location in the field, add it to the locations array
          let locations = this.locations.filter(
            (loc) => loc.field === parseInt(field.id),
          );
          selectedLocations = selectedLocations.concat(locations);
        });
      }
      return selectedLocations;
    },
    fieldListOptions() {
      if (this.fields) {
        return this.fields.map((field) => {
          return {
            label: field.properties.name,
            value: field.id,
          };
        });
      } else {
        return [];
      }
    },
    equipmentListOptions() {
      if (this.equipment) {
        return this.equipment.map((equip) => {
          return {
            label: equip.alias,
            value: equip.id,
          };
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    updateOrderDetails(e) {
      this.currentOrder.details = e;
    },
    resetOrderObject() {
      this.currentOrder = this.order
        ? this.order
        : {
            agreement: null,
            variants: {},
            options: {},
            form_response: {},
            shared_assets: {
              fields: [],
              equipment: [],
              locations: [],
            },
            details: {},
          };
    },
    partialClearDetailsForm() {
      this.$refs.orderDetailsComponent.partialClearForm();
    },
    setSharedFields() {
      this.currentOrder.shared_assets.fields = this.fields.filter(
        (field) => field.id == this.singleSelectedField,
      );
    },
    setSharedEquipment() {
      this.currentOrder.shared_assets.equipment = this.equipment.filter(
        (equip) => equip.id == this.singleSelectedEquipment,
      );
    },
  },
  watch: {
    workingProduct() {
      this.resetOrderObject();
    },
    currentOrder: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        value.agreement = this.workingAgreementId;
        value.shared_assets.locations = this.selectedLocations;
        this.$emit("updateOrder", value);
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
      deep: true,
    },
  },
};
</script>

<!-- stolen shamelessly from vue-avatar. vue-avatar would not work in Vue3, so this was updated to work accordingly -->
<template>
  <div
    class="vue-avatar--wrapper"
    :style="[style, customStyle]"
    :key="styleUpdated"
    aria-hidden="true"
    @click="clickEdit"
  >
    <!-- this img is not displayed; it is used to detect failure-to-load of div background image -->
    <img
      v-if="this.isImage"
      style="display: none"
      :src="this.src"
      @error="onImgError"
      data-test="userImage"
    />
    <span v-show="!this.isImage" data-test="userInitial">{{
      userInitial
    }}</span>
  </div>
</template>

<script>
const getInitials = (username) => {
  let initials = "";

  if (username) {
    let parts = username.split(/[ -]/);
    for (var i = 0; i < parts.length; i++) {
      initials += parts[i].charAt(0);
    }
    if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
      initials = initials.replace(/[a-z]+/g, "");
    }

    initials = initials.substr(0, 3).toUpperCase();
  }
  return initials;
};

export default {
  emits: ["editAvatarImage", "avatar-initials"],
  props: {
    username: {
      type: String,
    },
    initials: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    color: {
      type: String,
    },
    customStyle: {
      type: Object,
    },
    inline: {
      type: Boolean,
    },
    size: {
      type: Number,
      default: 50,
    },
    src: {
      type: String,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    lighten: {
      type: Number,
      default: 80,
    },
    parser: {
      type: Function,
      default: getInitials,
      validator: (parser) => typeof parser("John", getInitials) === "string",
    },
  },

  data() {
    return {
      backgroundColors: [
        "#5f28b8",
        "#0051c8",
        "#006897",
        "#00718f",
        "#00798f",
        "#00828e",
        "#008e84",
        "#009a70",
        "#00a948",
        "#48af08",
      ],
      imgError: false,
      styleUpdated: 0,
    };
  },

  mounted() {
    if (!this.isImage) {
      this.$emit("avatar-initials", this.username, this.userInitial);
    }
  },

  computed: {
    background() {
      // console.log("background", this.username)
      if (this.username && !this.isImage) {
        let seed = 0;
        for (let i = 0; i < this.username.length; i++) {
          seed += this.username.charCodeAt(i);
        }
        seed = seed % this.backgroundColors.length;
        return (
          this.backgroundColor ||
          this.randomBackgroundColor(
            // this.username.length,
            seed,
            this.backgroundColors,
          )
        );
      } else return "white";
    },

    fontColor() {
      if (!this.isImage) {
        return this.color || this.lightenColor(this.background, this.lighten);
      } else return "black";
    },

    isImage() {
      // console.log("isImage",!this.imgError && Boolean(this.src));
      return !this.imgError && Boolean(this.src);
    },
    // Note: similar style if we want to switch to Tailwind: class="flex items-center justify-center w-10 h-10 bg-gray-400 rounded-full ring-8 ring-white"
    style() {
      const style = {
        display: this.inline ? "inline-flex" : "flex",
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.rounded ? "50%" : 0,
        lineHeight: `${this.size + Math.floor(this.size / 20)}px`,
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        userSelect: "none",
      };

      const imgBackgroundAndFontStyle = {
        background: `transparent url('${this.src}') no-repeat scroll 0% 0% / ${this.size}px ${this.size}px content-box border-box`,
      };

      const initialBackgroundAndFontStyle = {
        backgroundColor: this.background,
        font: `${Math.floor(this.size / 2.5)}px/${
          this.size
        }px Helvetica, Arial, sans-serif`,
        color: this.fontColor,
      };

      let backgroundAndFontStyle = this.isImage
        ? imgBackgroundAndFontStyle
        : initialBackgroundAndFontStyle;

      Object.assign(style, backgroundAndFontStyle);
      //console.log("avatar style", style,this.customStyle);
      this.$forceUpdate(); // necessary because the styles are in an array and VUE doesn't pick up the change to an array element.
      return style;
    },

    userInitial() {
      if (!this.isImage) {
        const initials =
          this.initials || this.parser(this.username, getInitials);
        return initials;
      }
      return "";
    },
  },

  methods: {
    initial: getInitials,

    onImgError() {
      //console.log("image error", evt); // would add evt back to parameters if enabling
      this.imgError = true;
    },

    randomBackgroundColor(seed, colors) {
      return colors[seed % colors.length];
    },

    lightenColor(hex, amt) {
      // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
      var usePound = false;

      if (hex[0] === "#") {
        hex = hex.slice(1);
        usePound = true;
      }

      var num = parseInt(hex, 16);
      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    clickEdit() {
      this.$emit("editAvatarImage");
    },
  },
};
</script>

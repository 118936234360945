<template>
  <div
    class="form-check mt-1"
    :class="wideItem == true ? 'sm:col-span-3 ' : ''"
  >
    <label class="form-check-label inline-block text-sm text-gray-800">
      {{ inputTitle }}
      <img
        :src="displayOnlyValue"
        alt="inputTitle"
        :class="conditionalInputStyling"
      />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    inputTitle: { type: String, default: "" },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [String, Boolean], default: "" },
    placeholder: { type: String, default: "" },
    modelValue: { type: String },
    wideItem: { type: Boolean, default: false },
    classProp: { type: String, defautl: null },
  },
  emits: ["update:modelValue", "onBlur"],
  data() {
    let disabledStyle = "";
    if (this.displayOnly) disabledStyle = "bg-gray-200";
    return {
      disabledStyle,
      inputValue: this.modelValue,
    };
  },
  computed: {
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) {
        style += this.classProp;
      } else {
        style += "h-64" + " w-64";
      }
      return style;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

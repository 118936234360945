<template>
  <display-only
    displayTitle="Total Delivered Quantity"
    :displayValue="totalDelivered"
  />
</template>

<script>
import DisplayOnly from "@/components/form/DisplayOnly.vue";

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    perspective: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ["grower", "buyer", "controller"].includes(value);
      },
    },
  },
  components: {
    DisplayOnly,
  },
  computed: {
    currentOrderDeliveries() {
      let deliveries = [];
      switch (this.perspective) {
        case "buyer":
          deliveries = this.$store.state.buyerDeliveries.buyerDeliveries.filter(
            (d) => d.crop_order === parseInt(this.orderId),
          );
          break;
        case "controller":
          deliveries =
            this.$store.state.controllerDeliveries.controllerDeliveries.filter(
              (d) => d.crop_order === parseInt(this.orderId),
            );
      }
      return deliveries;
    },
    totalDelivered() {
      if (!this.currentOrderDeliveries) return 0;
      let sum = 0;
      for (let d of this.currentOrderDeliveries) {
        sum += d.quantity_delivered;
      }
      return sum;
    },
  },
  methods: {},
};
</script>

<template>
  <page-card :headingTitle="'New Recommendation'">
    <template #buttonArea>
      <submit-button
        buttonText="Submit Recommendation"
        :formId="
          entityType === 'opportunity'
            ? 'NewVendorOpportunityForm'
            : 'NewVendorRecommendationForm'
        "
      />
    </template>
    <template #cardDetails>
      <select-dropdown
        inputTitle="Recipient"
        :selectOptions="agreementOptions"
        v-model="selectedAgreementId"
        class="ml-1"
        :required="true"
        :wideItem="true"
      />
      <select-dropdown
        inputTitle="Recommendation Type"
        :selectOptions="[
          { label: 'Order', value: 'opportunity' },
          { label: 'Task', value: 'recommendation' },
        ]"
        v-model="entityType"
        class="ml-1"
        :required="true"
        :wideItem="true"
      />
      <text-area
        :wideItem="true"
        inputTitle="Memo"
        placeholder="Description here"
        v-model="memo"
      />
      <text-input
        :wideItem="true"
        inputTitle="More Details URL"
        placeholder="https://www.example.com/"
        v-model="moreInfo"
      />
    </template>
    <template #componentSection>
      <form
        @submit.prevent="() => submitNewVendorOpportunity()"
        id="NewVendorOpportunityForm"
        v-if="selectedProduct && entityType === 'opportunity'"
      >
        <order-object-interface
          :product="selectedProduct"
          :active-agreement-id="activeAgreement.id"
          :fields="fields"
          :equipment="equipment"
          :locations="locations"
          :readOnly="false"
          @updateOrder="updateOpportunity($event)"
        />
      </form>
      <form
        @submit.prevent="() => submitNewVendorRecommendation()"
        id="NewVendorRecommendationForm"
        v-else-if="selectedProduct && entityType === 'recommendation'"
      >
        <service-recommendation-object-interface
          :active-agreement-id="activeAgreement.id"
          :fields="fields"
          :equipment="equipment"
          :locations="locations"
          :readOnly="false"
          @updateRecommendation="updateRecommendation($event)"
        />
      </form>
      <p v-else>Please select a Recipient</p>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import TextArea from "@/components/form/TextArea.vue";
import TextInput from "@/components/form/TextInput.vue";

import OrderObjectInterface from "@/layouts/common/orders/OrderObjectInterface.vue";
import ServiceRecommendationObjectInterface from "@/layouts/common/recommendations/ServiceRecommendationObjectInterface.vue";
// import { getUTCString } from "@/components/composables/dateUtils.js";

export default {
  components: {
    PageCard,
    SubmitButton,
    SelectDropdown,
    OrderObjectInterface,
    ServiceRecommendationObjectInterface,
    TextArea,
    TextInput,
  },
  data() {
    return {
      entityType: "opportunity",
      selectedAgreementId: null,
      currentOpporunity: null,
      currentRecommendation: null,
      memo: "",
      moreInfo: "",
    };
  },
  computed: {
    // This needs to look at all agreements, get the ones that are options
    agreementOptions() {
      let quotes = this.$store.getters.getDerivedVendorQuotes({
        filterFunction: (quote) => {
          return quote.status === "buyer_confirmed";
        },
      });
      // sort the quotes array by quote.buyer_details.name
      quotes.sort((a, b) => {
        if (a.buyer_details.name < b.buyer_details.name) {
          return -1;
        }
        if (a.buyer_details.name > b.buyer_details.name) {
          return 1;
        }
        return 0;
      });
      // map through quotes and create an array of objects with label and value
      // the label should be quote.buyer_details.name + quote.service_title
      // the value should be quote.id
      let options = quotes.map((quote) => {
        return {
          label: `${quote.buyer_details.name} - ${quote.service_title}`,
          value: quote.id,
        };
      });
      return options;
    },
    activeAgreement() {
      return this.$store.getters.getVendorQuoteById(this.selectedAgreementId);
    },
    selectedProduct() {
      if (this.activeAgreement) {
        return this.$store.getters.getVendorProductById(
          this.activeAgreement.service,
        );
      } else {
        return null;
      }
    },
    fields() {
      return this.activeAgreement.shared_assets.fields;
    },
    equipment() {
      return this.activeAgreement.shared_assets.equipment;
    },
    locations() {
      return this.activeAgreement.shared_assets.locations;
    },
  },
  methods: {
    updateOpportunity(e) {
      this.currentOpportunity = e;
    },
    updateRecommendation(e) {
      this.currentRecommendation = e;
    },
    async submitNewVendorOpportunity() {
      await this.$store.dispatch("createVendorOpportunity", {
        ...this.currentOpportunity,
        memo: this.memo,
        more_info: this.moreInfo,
      });
      this.$router.push({ name: "vendor-recommendations" });
    },
    async submitNewVendorRecommendation() {
      // console.log("rec for submit", this.currentRecommendation);
      await this.$store.dispatch("createVendorRecommendation", {
        ...this.currentRecommendation,
        memo: this.memo,
        more_info: this.moreInfo,
      });
      this.$router.push({ name: "vendor-recommendations" });
    },
  },
};
</script>

import buyerPortalRoutes from "@/routes/buyerPortalRoutes";

let buyerRouteActions = [];
for (let route of buyerPortalRoutes) {
  // flatten the buyerPortalRoutes array to get an array of objects without nesting
  if (route.children) {
    for (let childRoute of route.children) {
      // skip the route if it has a redirect property
      if (childRoute.redirect) {
        continue;
      }
      // if the route path contains a colon skip it
      if (childRoute.path.includes(":")) {
        continue;
      }
      buyerRouteActions.push({
        title:
          childRoute.meta.title.replace(" | Parcel", "") ||
          childRoute.meta.displayTitle,
        routeObject: { name: childRoute.name },
        tags: [],
        notificationType: null,
      });
    }
  } else {
    buyerRouteActions.push({
      title: route.meta.title || route.meta.displayTitle,
      routeObject: { name: route.name },
      tags: [],
      notificationType: null,
    });
  }
}

let processes = [
  {
    title: "Crop Order Flow",
    description:
      "View assigned contracts, adjust public offers, review inquiries, and stay current with crop orders.",
    featureFlagForAccess: "bp-affiliate",
    actions: [
      {
        title: "View and Adjust Offers",
        routeObject: { name: "buyer-market-offers" },
        description: "",
        tags: ["Offers", "Buy", "View"],
        notificationType: "activeBuyerOffers",
        active: false,
      },
      {
        title: "Review Inquiries",
        routeObject: { name: "buyer-inquiries" },
        description: "",
        tags: ["Inquiry", "Offer", "Buy", "Accept"],
        notificationType: "cropOrderInquiries",
        active: false,
      },
      {
        title: "View active Crop Orders",
        routeObject: { name: "buyer-orders" },
        description: "",
        tags: ["Crop", "Order", "Review"],
        notificationType: null,
        active: false,
      },
    ],
  },
];

export default {
  state: {
    actions: buyerRouteActions,
    processes: processes,
    activeProcess: null,
  },
  mutations: {
    SET_ACTIVE_PROCESS(state, process) {
      // create a deep copy of the process object
      state.activeProcess = JSON.parse(JSON.stringify(process));
    },
  },
  actions: {
    setBuyerActionsActiveProcess({ commit }, process) {
      commit("SET_ACTIVE_PROCESS", process);
    },
    clearBuyerActionsActiveProcess({ commit }) {
      commit("SET_ACTIVE_PROCESS", null);
    },
    setActiveProcessBuyerActionToActive({ state, commit }, actionIndex) {
      let process = state.activeProcess;
      for (let action of process.actions) {
        action.active = false;
      }
      process.actions[actionIndex].active = true;
      commit("SET_ACTIVE_PROCESS", process);
    },
  },
  getters: {
    getAllBuyerActions(state) {
      return state.actions;
    },
    getAllBuyerActionsProcesses(state) {
      let processes = state.processes;
      // place state dependent filters here
      return processes;
    },
    getBuyerActionsActiveProcess(state) {
      return state.activeProcess;
    },
    getBuyerActionsActiveProcessActiveAction(state) {
      return state.activeProcess.actions.find((action) => action.active);
    },
    actionsBuyerNotificationFactory:
      (state, getters, rootState, rootGetters) => (actionNotificationType) => {
        switch (actionNotificationType) {
          case "activeBuyerOffers":
            return rootGetters.getBuyerMarketOffers.length;
          case "cropOrderInquiries":
            return rootGetters.getBuyerInquiries.length;
          default:
            return null;
        }
      },
  },
};

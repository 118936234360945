<template>
  <SlotModal
    :open="modalIsActive"
    modalTitle="Estimate Quantity"
    modalDescription="Enter the acreage and rate to calculate the quantity, or enter the rate and quantity to calculate the acres."
    @closeModal="
      open = false;
      $emit('closeModal');
    "
  >
    <template #content>
      <NumberInput
        inputTitle="Acres"
        :wideItem="false"
        v-model="acres"
        @blur="updateQuantity"
      />
      <NumberInput
        inputTitle="Application Rate"
        :wideItem="false"
        v-model="rate"
        @blur="updateQuantity"
      />
      <SelectDropdown
        inputTitle="Unit"
        :wideItem="false"
        :narrow-item="true"
        v-model="unit"
        :selectOptions="unitOptions"
        @onBlur="updateQuantity"
      />
      <SelectDropdown
        inputTitle="SKU"
        :wideItem="false"
        :narrow-item="true"
        v-model="skuId"
        :selectOptions="skuListOptions"
        @onBlur="updateQuantity"
      />
      <div class="text-xs" v-if="skuSizeInSelectedUnit > 0">
        {{ skuSizeInSelectedUnit }} {{ unit }}
      </div>
      <div class="text-xs" v-else>
        Cannot convert between {{ unit }} and {{ parsedSku.unit }}
      </div>
      <NumberInput
        inputTitle="Quantity"
        :wideItem="false"
        v-model="quantity"
        @blur="updateAcres"
      />
    </template>
    <template #buttons
      ><button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        @click="submitRateCalc"
      >
        Submit
      </button>
      <button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        @click="$emit('closeModal')"
      >
        Cancel
      </button>
    </template>
  </SlotModal>
</template>
<script>
import SlotModal from "@/components/modals/SlotModal.vue";
import NumberInput from "@/components/form/NumberInput.vue";
// import SelectSearch from "@/components/form/SelectSearch.vue";
import { parseSku } from "@/components/composables/scriptUtils";
import convert from "convert";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  components: {
    SlotModal,
    NumberInput,
    // SelectSearch,
    SelectDropdown,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    skuList: {
      type: Array,
      required: true,
    },
    currentSkuId: {
      type: Number,
      required: false,
    },
    currentQty: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalIsActive: this.showModal,
      acres: null,
      rate: null,
      quantity: null,
      unit: null,
      skuId: null,
    };
  },
  computed: {
    parsedSku() {
      let sku = this.skuList.find((sku) => sku.id === this.skuId);
      return parseSku(sku.title, this.quantity);
    },
    skuListOptions() {
      return this.skuList.map((sku) => {
        return {
          label: sku.title,
          value: sku.id,
        };
      });
    },
    unitOptions() {
      let skuUnit = this.parsedSku.unit;
      let mass = [
        { value: "g", label: "grams" },
        { value: "kg", label: "kilograms" },
        { value: "oz", label: "ounces" },
        { value: "lb", label: "pounds" },
        { value: "ton", label: "tons" },
        { value: "mg", label: "milligrams" },
      ];
      let volume = [
        { value: "ml", label: "milliliters" },
        { value: "l", label: "liters" },
        { value: "gal", label: "gallons" },
        { value: "fl oz", label: "fluid ounces" },
        { value: "pt", label: "pints" },
        { value: "qt", label: "quarts" },
        { value: "cup", label: "cups" },
        { value: "tbsp", label: "tablespoons" },
        { value: "tsp", label: "teaspoons" },
      ];
      // console.log("unitOptions: ", mass, volume, skuUnit);
      let options = [];
      switch (skuUnit) {
        case "g":
        case "kg":
        case "oz":
        case "lb":
        case "lbs":
        case "ton":
          options = mass;
          break;
        case "mg":
        case "ml":
        case "l":
        case "gal":
        case "fl-oz":
        case "fl oz":
        case "pt":
        case "qt":
        case "cup":
        case "tbsp":
        case "tsp":
          options = volume;
          break;
      }
      return options;
    },
    currentSkuQuantity() {
      let currentQuantity = null;

      if (this.skuId) {
        let sku = this.skuList.find((sku) => sku.id === this.skuId);
        let skuSize = parseSku(sku.title, this.quantity);
        if (!skuSize) return null;
        currentQuantity = skuSize.displayText;
      }
      return currentQuantity;
    },
    skuSizeInSelectedUnit() {
      let skuSize = 0;
      let unit = this.unit;
      if (this.parsedSku) {
        let skuUnit = this.parsedSku.unit;
        // console.log("calculate size unit: ", unit, "skuUnit: ", skuUnit);
        if (unit && skuUnit) {
          if (unit == "fl-oz") {
            unit = "fl oz";
          }
          if (skuUnit == "fl-oz") {
            skuUnit = "fl oz";
          }
          if (unit == "ton") {
            unit = "short ton";
          }
          if (skuUnit == "ton") {
            skuUnit = "short ton";
          }
          if (skuUnit == "lbs") {
            skuUnit = "pounds";
          }
          try {
            skuSize = convert(this.parsedSku.qty, skuUnit).to(unit);
            // console.log(
            //   "skuSize: ",
            //   skuSize,
            //   this.parsedSku.qty,
            //   skuUnit,
            //   unit,
            // );
          } catch (e) {
            // console.log("error converting: ", e);
            skuSize = -1;
          }
        }
      }
      return skuSize;
    },
  },
  methods: {
    submitRateCalc() {
      // console.log("submitRateCalc: ", this.quantity, this.skuId);
      this.$emit("submitRateCalc", {
        quantity: this.quantity,
        skuId: this.skuId,
      });
      // submit rate calc
    },
    updateQuantity() {
      // console.log(
      //   "updateQuantity calc: ",
      //   this.acres,
      //   this.rate,
      //   this.unit,
      //   this.skuSizeInSelectedUnit,
      // );
      if (this.acres && this.rate && this.unit) {
        this.quantity = Math.ceil(
          (this.acres * this.rate) / this.skuSizeInSelectedUnit,
        );
        if (this.skuSizeInSelectedUnit < 0) this.quantity = this.currentQty;
        // console.log("updateQuantity: ", this.quantity);
      }
    },
    updateAcres() {
      if (this.quantity && this.rate && this.unit) {
        this.acres = (this.quantity * this.skuSizeInSelectedUnit) / this.rate;
      }
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler(val) {
        this.modalIsActive = val;
      },
    },
    currentSkuId: {
      immediate: true,
      handler(val) {
        // console.log("currentSku watcher: ", val);
        this.skuId = val;
      },
    },
    currentQty: {
      immediate: true,
      handler(val) {
        this.quantity = val;
      },
    },
  },
};
</script>

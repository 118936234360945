import client from "@/api/parcel/api.js";
// import { universalGetter } from "@/components/composables/universalGetter";
import _ from "lodash";

export default {
  state() {
    return {
      growerMarketOffers: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_GROWER_MARKET_OFFERS(state, growerMarketOfferData) {
      state.growerMarketOffers = growerMarketOfferData;
    },
    RESET_GROWER_MARKET_OFFERS(state) {
      state.growerMarketOffers = [];
      state.filterObject = {};
    },
    SET_FILTER(state, filterObject) {
      state.filterObject = filterObject;
    },
  },
  actions: {
    async getGrowerMarketOffers({ commit }) {
      const data = await client.grower.marketOffers.read();
      if (data) {
        commit("SET_GROWER_MARKET_OFFERS", data.offers);
      }
    },
  },
  getters: {
    getGrowerMarketOfferById:
      (state) =>
      (growerMarketOfferId, includeDeletedPremiums = false) => {
        let offer = state.growerMarketOffers.find(
          (growerMarketOffer) =>
            growerMarketOffer.id === parseInt(growerMarketOfferId),
        );
        // Was previosuly mutating offer.premiums directly, now returns a new object
        if (offer) {
          return {
            ...offer,
            premiums: includeDeletedPremiums
              ? offer.premiums
              : offer.premiums.filter((premium) => !premium.deleted),
          };
        }
        return null;
      },
    getGrowerMarketOffers:
      (state) =>
      (includeDeleted = false) => {
        if (includeDeleted) {
          // Return a shallow copy of the array to prevent direct mutation
          return [...state.growerMarketOffers];
        } else {
          // Filter and return a new array without mutating the state
          return state.growerMarketOffers.filter(
            (growerMarketOffer) => !growerMarketOffer.deleted,
          );
        }
      },

    // //  UNIVERSAL GETTER
    // getGrowerMarketOffers:
    //   (state) =>
    //   ({
    //     filterFunction = null,
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let derivedGrowerMarketOffers = universalGetter(
    //       {
    //         filterFunction: filterFunction,
    //         filter: filter,
    //         match: match,
    //         sort: sort,
    //         pageSize: pageSize,
    //         pageNum: pageNum,
    //       },
    //       state.growerMarketOffers
    //     );
    //     return derivedGrowerMarketOffers;
    //   },
    getDerivedGrowerMarketOffers:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedGrowerMarketOffers = state.growerMarketOffers.map(
          (item) => ({
            ...item,
          }),
        );
        let filteredGrowerMarketOffers = _.isEmpty(filter)
          ? expandedGrowerMarketOffers
          : _.filter(expandedGrowerMarketOffers, filter);
        let matchedGrowerMarketOffers = _.isEmpty(match)
          ? filteredGrowerMarketOffers
          : _.filter(filteredGrowerMarketOffers, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedGrowerMarketOffers = _.isEmpty(sort)
          ? matchedGrowerMarketOffers
          : _.orderBy(matchedGrowerMarketOffers, sortColumns, sortOrders);
        let paginatedGrowerMarketOffers =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerMarketOffers,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerMarketOffers;
        return paginatedGrowerMarketOffers;
      },
    getGrowerMarketOffersFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};

<template>
  <delete-modal
    :open="deleteModalIsActive"
    modalTitle="Delete User"
    modalDescription="Are you sure you want to permanently delete this user?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteUser"
  />
  <!-- <modal
    :show="loginPreferenceModalIsActive"
    title="Choose your default portal for login"
  >
    <login-preference-settings
      @closeModal="loginPreferenceModalIsActive = false"
      :userId="currentUser.id"
    />
  </modal> -->
  <page-card headingTitle="User Profile">
    <template #buttonArea>
      <base-button
        buttonText="Save"
        :marginLeft="false"
        @buttonClick="submitUserSettingsForm"
      />
      <base-button
        v-if="userId"
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-3">
        <div class="flex justify-center sm:col-span-3 md:justify-start">
          <img
            v-if="currentUser.name == '' && !currentUser.image"
            src="@/assets/no_logo_default.jpeg"
            class="h-36 w-36"
          />
          <Avatar
            v-else
            :username="currentUser.name"
            :src="currentUser.image"
            :size="150"
            :edit="true"
          />
        </div>
      </div>
      <div class="sm:col-span-3"></div>
      <variable-string-type-input
        :wideItem="true"
        inputTitle="Name"
        v-model="currentUser.name"
      />
      <variable-string-type-input
        :wideItem="true"
        inputTitle="Title"
        v-model="currentUser.title"
      />
      <email-input
        :wideItem="true"
        inputTitle="Email"
        v-model="currentUser.email"
        inputHtmlType="email"
      />
      <phone-input-2
        :wideItem="true"
        inputTitle="Phone"
        v-model="currentUser.phone"
        inputHtmlType="tel"
      />
      <CheckboxInput
        :wideItem="true"
        inputTitle="Admin"
        v-model="currentUser.admin"
        v-if="userIsAdmin && loggedInUser.id != userId && !isUserProfile"
      />
      <div class="flex align-middle sm:col-span-6">
        <file-select
          v-if="!userId"
          class="h-full"
          buttonText="Edit Avatar"
          accept="image/*"
          @input="uploadAvatarImage"
        />
        <!-- <base-button
          buttonText="Login Preferences"
          @buttonClick="loginPreferenceModalIsActive = true"
        /> -->
      </div>
    </template>
  </page-card>
  <div class="mt-2"></div>
  <page-card headingTitle="Notification Settings">
    <template #cardDetails>
      <div class="col-span-6">
        <div
          v-for="notificationEvent in notificationEvents"
          :key="notificationEvent.event"
          class="pt-4 text-base"
        >
          <FeatureFlagged :requires="notificationEvent.flag || 'allow'">
            {{ notificationEvent.label }}
            <table>
              <tr
                class="text-sm font-normal text-gray-900"
                v-if="
                  this.userSettings &&
                  this.userSettings[notificationEvent.event]
                "
              >
                <td
                  v-for="(value, key) in notificationMethods"
                  :key="key"
                  class="pb-4 pl-8"
                >
                  <checkbox-input
                    :inputTitle="notificationMethodLabels[value]"
                    :wideItem="true"
                    v-model="this.userSettings[notificationEvent.event][value]"
                  />
                </td>
              </tr>
            </table>
          </FeatureFlagged>
        </div>
        <div></div>
        <div class="col-span-6 pt-2 text-sm text-gray-500">
          To start receving SMS alerts, text START to +1 (707) 755-7220. Reply
          STOP any time to opt out.
        </div>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import FileSelect from "@/components/form/FileSelect.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import PhoneInput2 from "@/components/form/PhoneInput2.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";
// import Modal from "@/components/modals/PopupModal.vue";
// import LoginPreferenceSettings from "@/layouts/grower-portal/settings/LoginPreferenceSettings.vue";

import DeleteModal from "@/components/modals/DeleteModal.vue";
import Avatar from "@/components/identity/UserAvatar.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import { useToast } from "vue-toastification";
export default {
  props: {
    userId: {
      type: Number,
      default: null,
    },
    isUserProfile: {
      type: Boolean,
      required: true,
    },
    redirectRouteName: {
      type: String,
    },
    redirectTab: {
      type: String,
    },
    notificationEvents: {
      type: Array,
    },
    // needed to allow a submit button outside the form tags to submit the form
  },
  components: {
    VariableStringTypeInput,
    BaseButton,
    PageCard,
    PhoneInput2,
    EmailInput,
    // Modal,
    // LoginPreferenceSettings,
    DeleteModal,
    Avatar,
    FileSelect,
    CheckboxInput,
    FeatureFlagged,
  },
  data() {
    return {
      notificationMethods: ["email", "sms", "in_app"],
      notificationMethodLabels: {
        email: "Email",
        sms: "SMS",
        in_app: "In-App",
      },
      currentUser: {},
      deleteModalIsActive: false,
      notificationModalIsActive: false,
      // loginPreferenceModalIsActive: false,
      userSettings: null,
      toast: useToast(),
    };
  },
  computed: {
    userIsAdmin() {
      return this.$store.getters.getUserAdminStatus;
    },
    isNewUser() {
      if (!this.userId && !this.isUserProfile) {
        return true;
      } else {
        return false;
      }
    },
    loggedInUser() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    submitUserSettingsForm() {
      if (this.userId) {
        this.updateUser();
      } else if (this.isNewUser) {
        this.createUser();
      } else {
        this.updateProfile();
      }
    },
    // Form Methods
    async readUser() {
      // console.log("readUser");
      this.currentUser = this.$store.getters.getUserById(this.userId);
      await this.$store
        .dispatch("adminGetSettings", this.userId)
        .then((response) => {
          this.userSettings = response;
        });
      // console.log("user this.userSettings", this.userSettings);
    },
    async readProfile() {
      if (!this.$store.getters.getCurrentUser.id) {
        await this.$store.dispatch("getProfile");
      }
      this.currentUser = this.$store.getters.getCurrentUser;
      await this.$store.dispatch("getSettings").then((response) => {
        // console.log("response", response);
        this.userSettings = response;
      });
      // console.log("profile this.userSettings", this.userSettings);
    },
    async createUser() {
      let response = await this.$store.dispatch("createUser", this.currentUser);
      if (response) {
        // console.log("attempting to update settings");
        this.$store.dispatch("adminUpdateSettings", {
          user: response.id,
          ...this.userSettings,
        });
      }
      // console.log("about to redirect", this.redirectRouteName);
      if (this.redirectRouteName) {
        this.$router.push({
          name: this.redirectRouteName,
          params: { tab: "organization" },
          // query: { tab: this.redirectTab },
        });
      }
    },
    async updateUser() {
      await this.$store.dispatch("updateUser", this.currentUser);
      await this.$store.dispatch("adminUpdateSettings", {
        user: this.currentUser.id,
        ...this.userSettings,
      });
      if (this.redirectRouteName) {
        this.$router.push({
          name: this.redirectRouteName,
          query: { tab: this.redirectTab },
        });
      } else {
        if (!this.$store.getters.getApiError) {
          this.toast.success("Settings Saved!", {
            timeout: 1500,
            hideProgressBar: true,
          });
        }
      }
    },
    async updateProfile() {
      await this.$store.dispatch("updateProfile", this.currentUser);
      await this.$store.dispatch("adminUpdateSettings", {
        user: this.currentUser.id,
        ...this.userSettings,
      });
      if (this.redirectRouteName) {
        this.$router.push({
          name: this.redirectRouteName,
          query: { tab: this.redirectTab },
        });
      } else {
        if (!this.$store.getters.getApiError) {
          this.toast.success("Settings Saved!", {
            timeout: 1500,
            hideProgressBar: true,
          });
        }
      }
    },
    deleteUser() {
      this.deleteModalIsActive = false;
      this.$store.dispatch("deleteUser", this.currentUser);
      if (this.redirectRouteName) {
        this.$router.push({
          name: this.redirectRouteName,
          query: { tab: this.redirectTab },
        });
      }
    },
    clearForm() {
      this.currentUser = {
        name: "", // string
        title: "", // string
        company: "", // string
        phone: "", // string
        email: "", // string
        admin: false, // Boolean
      };
      this.userSettings = {
        task_assigned: {
          email: false,
          sms: false,
          in_app: true,
        },
        chat_received: {
          email: false,
          sms: false,
          in_app: true,
        },
        reservation_received: {
          email: false,
          sms: false,
          in_app: true,
        },
        quote_request_received: {
          email: false,
          sms: false,
          in_app: true,
        },
        quote_response_received: {
          email: false,
          sms: false,
          in_app: true,
        },
        order_request_received: {
          email: false,
          sms: false,
          in_app: true,
        },
        order_response_received: {
          email: false,
          sms: false,
          in_app: true,
        },
        order_canceled: {
          email: false,
          sms: false,
          in_app: true,
        },
        opportunity_received: {
          email: false,
          sms: false,
          in_app: true,
        },
        recommendation_received: {
          email: false,
          sms: false,
          in_app: true,
        },
      };
    },
    intializeDeleteModal() {
      this.deleteModalIsActive = true;
    },
    cancelDelete() {
      this.deleteModalIsActive = false;
    },
    async uploadAvatarImage(avatarImage) {
      let response = await this.$store.dispatch(
        "uploadProfileAvatar",
        avatarImage,
      );
      if (!this.$store.getters.getApiError) {
        // console.log("avatar uploaded");
        // console.log(response);
      }
      this.currentUser.image = response.image;
    },
    // async submitUserNotificationSettings() {
    //   // console.log("SAVE", this.singleUser);
    //   this.userSettings.task_assigned.email =
    //     this.settings.task_assigned.channels.email.selected;
    //   this.userSettings.task_assigned.sms =
    //     this.settings.task_assigned.channels.sms.selected;
    //   this.userSettings.task_assigned.notification =
    //     this.settings.task_assigned.channels.notification.selected;
    //   this.userSettings.chat_received.email =
    //     this.settings.chat_received.channels.email.selected;
    //   this.userSettings.chat_received.sms =
    //     this.settings.chat_received.channels.sms.selected;
    //   this.userSettings.chat_received.notification =
    //     this.settings.chat_received.channels.notification.selected;
    //   this.userSettings.order_received.email =
    //     this.settings.order_received.channels.email.selected;
    //   this.userSettings.order_received.sms =
    //     this.settings.order_received.channels.sms.selected;
    //   this.userSettings.order_received.notification =
    //     this.settings.order_received.channels.notification.selected;
    // console.log("this.userSettings updating via request", this.userSettings);
    //   this.$store.dispatch("adminUpdateSettings", {
    //     user: this.userId,
    //     ...this.userSettings,
    //   });
    // console.log("this.userSettings updated ");
    // },
    // async submitUserNotificationSettingsForNewUser(newUserId) {
    //   // console.log("SAVE", this.singleUser);
    //   this.userSettings.task_assigned.email =
    //     this.settings.task_assigned.channels.email.selected;
    //   this.userSettings.task_assigned.sms =
    //     this.settings.task_assigned.channels.sms.selected;
    //   this.userSettings.task_assigned.notification =
    //     this.settings.task_assigned.channels.notification.selected;
    //   this.userSettings.chat_received.email =
    //     this.settings.chat_received.channels.email.selected;
    //   this.userSettings.chat_received.sms =
    //     this.settings.chat_received.channels.sms.selected;
    //   this.userSettings.chat_received.notification =
    //     this.settings.chat_received.channels.notification.selected;
    //   this.userSettings.order_received.email =
    //     this.settings.order_received.channels.email.selected;
    //   this.userSettings.order_received.sms =
    //     this.settings.order_received.channels.sms.selected;
    //   this.userSettings.order_received.notification =
    //     this.settings.order_received.channels.notification.selected;
    //   this.$store.dispatch("adminUpdateSettings", {
    //     user: newUserId,
    //     ...this.userSettings,
    //   });
    // },
  },
  watch: {
    userId: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value) {
          this.readUser();
        } else if (this.isNewUser) {
          this.clearForm();
        } else {
          this.readProfile();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

<template>
  <csv-import :orderAttributes="orderAttributes" />
</template>

<script>
import CsvImport from "@/layouts/grower-portal/sell/orders/CsvImport.vue";

export default {
  components: {
    CsvImport,
  },
  props: {},
  data() {
    let orderAttributes = {
      buyer: { required: true, label: "Buyer" },
      contract_number: { required: true, label: "Contract Number" },
      order_quantity: { required: false, label: "Order Quantity" },
      order_units: { required: false, label: "Order Units" },
      terms: { required: false, label: "Terms" },
      name: { required: true, label: "Field Name" },
      farm: { required: true, label: "Farm" },
      allocated_acres: { required: false, label: "Allocated Acres" },
      primary_contact: { required: false, label: "Primary Contact" },
      price: { required: false, label: "Price" },
      // target_tons: { required: false, label: "Target Tons" },
      // max_tons: { required: false, label: "Max Tons" },
      // contract_number: { required: false, label: "Contract Number" },
      // contract_year_end: { required: false, label: "Contract Year End" },
    };

    return {
      csv: null,
      importReport: "",
      showSlideOut: false,
      orderAttributes: orderAttributes,
    };
  },
  computed: {},
  methods: {},
  setup() {},
  async created() {},
  mounted() {},
};
</script>

<template>
  <!-- <router-view /> -->
  <hauling-reservations-all />
</template>

<script>
import HaulingReservationsAll from "@/pages/vendor-portal/hauling/HaulingReservationsAll.vue";

export default {
  components: {
    HaulingReservationsAll,
  },
  data() {
    return {};
  },
};
</script>

<template>
  <table
    class="min-w-full divide-y divide-gray-300"
    :class="roundedTop ? 'rounded-t' : ''"
  >
    <thead class="bg-gray-50">
      <tr>
        <div
          class="-mr-5 w-screen sm:mr-0 sm:hidden"
          v-if="$store.getters.getViewState == true"
        >
          <base-button
            buttonText="Close"
            @buttonClick="$store.dispatch('hideView')"
          />
        </div>
        <slot name="headerCells"></slot>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <slot name="bodyRows">
        <!-- <tr v-for="">
          table cell components
        </tr> -->
      </slot>
    </tbody>
  </table>
</template>

<script>
import BaseButton from "../buttons/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    roundedTop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

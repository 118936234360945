import client from "@/api/parcel/api.js";

export default {
  state() {
    return { agreements: [] };
  },
  mutations: {
    SET_VENDOR_INPUT_AGREEMENTS(state, data) {
      state.agreements = data;
    },
    RESET_VENDOR_INPUT_AGREEMENTS(state) {
      state.agreements = [];
    },
    UPDATE_VENDOR_INPUT_AGREEMENT(state, agreement) {
      state.agreements = state.agreements.filter((a) => a.id !== agreement.id);
      state.agreements.push(agreement);
    },
  },
  actions: {
    async getVendorInputAgreements({ commit }) {
      const data = await client.vendor.input_agreements.read();
      if (data) {
        commit("SET_VENDOR_INPUT_AGREEMENTS", data.input_agreements);
      }
    },
    async updateVendorInputAgreement({ commit }, agreement) {
      const data = await client.vendor.input_agreements.update(
        agreement.id,
        agreement,
      );
      if (data) {
        commit("UPDATE_VENDOR_INPUT_AGREEMENT", data);
        return data;
      }
    },
  },
  getters: {},
};

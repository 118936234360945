<template>
  <csv-import :productAttributes="productAttributes" />
</template>

<script>
import CsvImport from "@/layouts/vendor-portal/products/product-form-inputs/CsvImport.vue";

export default {
  components: {
    CsvImport,
  },
  props: {},
  data() {
    let productAttributes = {
      category: { required: true, label: "Category" },
      title: { required: true, label: "Product Name" },
      image_url: { required: false, label: "Image URL" },
      description: {
        required: false,
        label: "Description",
      },
      details: { required: false, label: "Details" },
      default_duration: { required: false, label: "Agreement Valid" },
      epa_number: { required: false, label: "EPA Number" },
      type: { required: false, label: "Type" },
      manufacturer: { required: false, label: "Manufacturer" },
      active_ingredients: {
        required: false,
        label: "Active Ingredients",
      },
      mode_of_action: { required: false, label: "Mode of Action" },
      pests: { required: false, label: "Pests" },
      crops: { required: false, label: "Crops" },
      states: { required: false, label: "States" },
      skus: { required: false, label: "SKUS" },
      active: { required: false, label: "Active" },
    };

    return {
      csv: null,
      importReport: "",
      showSlideOut: false,
      productAttributes: productAttributes,
    };
  },
  computed: {},
  methods: {},
  setup() {},
  async created() {},
  mounted() {},
};
</script>

<template>
  <modal
    :show="showRecordModal"
    dialogPanelClass="max-w-screen-lg"
    :title="null"
  >
    <record-form
      @closeRecord="showRecordModal = false"
      :recordProp="activeRecord"
      :displayOnly="true"
      :recordType="activeRecord.category"
    />
  </modal>
  <page-card
    :headingTitle="currentField?.field_details?.properties?.name || ' '"
  >
    <template #cardDetails>
      <text-input
        :wideItem="true"
        inputTitle="Field Name"
        :display-only="true"
        :display-only-value="currentField?.field_details?.properties?.name"
      />
      <text-input
        :wideItem="true"
        inputTitle="Farm Name"
        :display-only="true"
        :display-only-value="currentField?.field_details?.properties?.farm"
      />
      <number-input
        inputTitle="Acres"
        :wideItem="true"
        :display-only="true"
        :display-only-value="currentField?.field_details?.properties?.acres"
      />
      <number-input
        inputTitle="Our Allocated Acres"
        :wideItem="true"
        :display-only="true"
        :display-only-value="currentField?.field_allocated_acres"
      />
      <number-input
        inputTitle="Available Acres"
        :wideItem="true"
        :display-only="true"
        :display-only-value="
          currentField?.field_details?.properties?.acres -
          currentField?.field_allocated_acres
        "
      />
      <text-input
        :wideItem="true"
        inputTitle="Crop"
        :display-only="true"
        :display-only-value="currentField?.crop_details?.crop_type"
      />
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Records
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                <div class="flex">Allocations</div>
              </button>
            </Tab>
            <!-- 
              TODO: Figure out why map is not rendering unless recompiled when visible.
              <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                <div class="flex">Map</div>
              </button>
            </Tab> -->
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10" :unmount="false">
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    {
                      name: 'category',
                      label: 'Category',
                      filtered: false,
                    },
                    { name: 'result', label: 'Result' },
                    { name: '', label: '', filtered: false, sorted: false },
                  ]"
                  :collection="currentRecords"
                  @update:derived-collection="derivedRecords = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr v-for="(record, idx) in derivedRecords" :key="record">
                  <table-data td-label="Category">
                    {{ record.category }}
                  </table-data>
                  <table-data td-label="Result">
                    {{ record.result }}
                  </table-data>
                  <table-data class="text-center">
                    <button
                      @click="showRecord(idx)"
                      :disabled="false"
                      v-if="record.category != 'Note'"
                    >
                      View
                    </button>
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <TabPanel class="pt-10" :unmount="false">
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    { name: 'crop_details.crop_type', label: 'Crop' },
                    { name: 'crop_details.details.variety', label: 'Variety' },
                    {
                      name: 'acres',
                      label: 'Allocated Acres',
                      filtered: false,
                    },
                    {
                      name: '',
                      label: 'Estimated Yield',
                      filtered: false,
                      sorted: false,
                    },
                    { name: 'actions', label: '', filtered: false },
                  ]"
                  :collection="currentAllocations"
                  @update:derived-collection="derivedAllocations = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr
                  v-for="allocation in derivedAllocations"
                  :key="allocation.id"
                >
                  <table-data td-label="Crop">
                    {{ allocation.shared_data.crop_details.crop_type }}
                  </table-data>
                  <table-data td-label="Variety">
                    {{ allocation.shared_data.crop_details.details.variety }}
                  </table-data>
                  <table-data td-label="Allocated Acres">
                    {{ allocation.acres }}
                  </table-data>
                  <table-data td-label="Estimated Yield">
                    {{
                      allocation.shared_data.crop_details.details.tpa
                        ? allocation.acres *
                            allocation.shared_data.crop_details.details.tpa +
                          " Tons"
                        : "N/A"
                    }}
                  </table-data>
                  <table-data class="text-center">
                    <router-link
                      :to="{
                        name: 'buyer-bp-controller-view-order',
                        params: { orderId: allocation.crop_order },
                      }"
                      class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
                    >
                      View Order
                    </router-link>
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <!-- <TabPanel class="pt-10" :unmount="false">
            <GrowerMap :fieldId="currentField.id" />
          </TabPanel> -->
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
// import FieldLeafletMap from "@/components/map/FieldEditMap.vue";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import Modal from "@/components/modals/PopupModal.vue";
// import GrowerMap from "@/layouts/buyer-portal/dashboard/GrowersMap.vue";

export default {
  components: {
    PageCard,
    TextInput,
    NumberInput,
    // FieldLeafletMap,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TableCard,
    TableHead,
    TableData,
    RecordForm,
    Modal,
    // GrowerMap,
  },
  data() {
    return {
      derivedRecords: [],
      derivedAllocations: [],
      showRecordModal: false,
    };
  },
  computed: {
    fieldId() {
      return parseInt(this.$route.params.fieldId);
    },
    readyToLoadData() {
      if (this.$store.getters.getAllControllerAllocations.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    currentField() {
      if (!this.readyToLoadData) {
        return {};
      }
      return this.$store.getters.getControllerAllocationFieldByFieldId(
        this.fieldId,
      );
    },
    // currentFieldForMap() {

    //   return {
    //     type: "Feature",
    //     geometry: this.currentField.field_details.geometry,
    //     properties: {
    //       name: this.currentField.field_details.properties.name,
    //       farm: this.currentField.field_details.properties.farm,
    //       acres: this.currentField.field_details.properties.acres,
    //     },
    //   };
    // },
    currentAllocations() {
      if (!this.readyToLoadData) {
        return [];
      }
      return this.$store.getters.getControllerAllocationsByFieldId(
        this.fieldId,
      );
    },
    fieldHasGeometry() {
      if (this.currentField?.field_details?.geometry?.coordinates?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    currentRecords() {
      // iterate through currentAllocations and get records from each in an array at record.shared_data.record_details
      let records = [];
      this.currentAllocations.forEach((allocation) => {
        allocation.shared_data.record_details.forEach((record) => {
          records.push(
            Object.assign({}, record, {
              allocation: allocation.id,
              field_details: allocation.shared_data.field_details,
              crop_details: allocation.shared_data.crop_details,
            }),
          );
        });
      });
      return records;
    },
  },
  methods: {
    showRecord(id) {
      this.activeRecord = this.derivedRecords[id];
      this.displayOnly = true;
      this.showRecordModal = true;
    },
  },
  watch: {},
};
</script>

<template>
  <!-- <div :id="id" width="40px" height="40px"> -->
  <div width="40px" height="40px">
    <!-- <svg
      opacity=".5"
      stroke="1"
      :width="width"
      :height="height"
      :id="'svg' + id"
    > -->
    <svg opacity="1" stroke="1" :width="width" :height="height">
      <!-- <g class="map" :id="'g' + id"></g> -->
      <g class="map" ref="gmap"></g>
    </svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  components: {},
  props: {
    title: String,
    // id: String,
    geojson: Object,
  },
  data() {
    return {
      width: "40px",
      height: "40px",
    };
  },
  methods: {
    makeThumbnail: function () {
      var width = 40;
      var height = 40;
      //console.log("make thumbnail", this.geojson.properties.name,this.geojson);
      if (
        this.geojson.geometry &&
        (this.geojson.geometry.type == "Polygon" ||
          this.geojson.geometry.type == "MultiPolygon")
      ) {
        let geojson = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: this.geojson.geometry.type,
                coordinates: [[]],
              },
              properties: this.geojson.properties,
            },
          ],
        };
        geojson.features[0].geometry.coordinates =
          this.geojson.geometry.coordinates;

        //console.log("geojson");
        //console.log(JSON.stringify(geojson));
        let projection = d3.geoIdentity().fitSize([width, height], geojson); // use instead of albersusa because the coordinates are already mapped onto a projection

        let path = d3.geoPath().projection(projection);

        // let selectStr = "#" + this.id + " g.map";
        // d3.select(selectStr)
        d3.select(this.$refs.gmap)
          .selectAll("path")
          .data(geojson.features)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("transform", "scale(1, -1) translate(0,-" + height + ")")
          .attr("fill", "#6b7280"); // was "gray" or #003A47
        // console.log(selectStr);
      } else {
        // Not a valid field shape to thumbnail, put in a dummy image.
        // let selectStr = "#" + this.id + " g.map";
        // d3.select(selectStr)
        d3.select(this.$refs.gmap)
          .append("circle")
          .attr("cx", "50%")
          .attr("cy", "50%")
          .attr("r", 20)
          .style("fill", "gray");
      }
    },
  },
  mounted() {
    this.makeThumbnail();
  },
};
</script>

<style>
content {
  /* fill: #d1fae5;
  stroke: #d1fae5; */
  fill: #ddd;
  stroke: #aaa;
}
</style>

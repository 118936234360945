<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'seller_details.name', label: 'Name' },
          { name: 'seller_details.contact_email', label: 'Email' },
          {
            name: 'seller_details.contact_phone',
            label: 'Phone',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="vendors"
        @update:derived-collection="derivedVendors = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewVendorPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="vendor in derivedVendors" :key="vendor.id">
        <table-data td-label="Name">
          {{ vendor.seller_details.name }}
        </table-data>
        <table-data td-label="Email">
          {{ vendor.seller_details.contact_email }}
        </table-data>
        <table-data td-label="Phone">
          {{ vendor.seller_details.contact_phone }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'edit-buyer-portal-vendor',
              params: { vendorId: vendor.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  data() {
    return {
      derivedVendors: [],
    };
  },
  computed: {
    vendors() {
      return this.$store.getters.getBuyerCropVendors({});
    },
  },
  methods: {
    goToNewVendorPage() {
      this.$router.push({ name: "new-buyer-portal-vendor" });
    },
  },
};
</script>

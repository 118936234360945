import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";
import _ from "lodash";

export default {
  state() {
    return {
      crops: [], // only active crops
      cropHistory: [], // only crops that are not active
    };
  },
  mutations: {
    SET_CROPS(state, cropData) {
      state.crops = cropData;
    },
    SET_CROP_HISTORY(state, cropData) {
      state.cropHistory = cropData;
    },
    ADD_CROP(state, cropObject) {
      if (cropObject.active === true) {
        state.crops.push(cropObject);
      } else {
        state.cropHistory.push(cropObject);
      }
    },
    UPDATE_CROP(state, cropObject) {
      // check if we are swapping from active to history or vice versa
      let currentState = state.crops.find((crop) => crop.id === cropObject.id);
      let currentHistory = state.cropHistory.find(
        (crop) => crop.id === cropObject.id,
      );
      if (
        (currentState && cropObject.active == false) ||
        (currentHistory && cropObject.active == true)
      ) {
        // we are swapping from active to history or vice versa
        if (currentState) {
          // this is a current crop
          if (cropObject.active === false) {
            state.crops = state.crops.filter(function (obj) {
              return obj.id !== cropObject.id;
            });
            state.cropHistory.push(cropObject);
            // console.log("moved to history", cropObject, state.cropHistory);
          }
        } else if (currentHistory) {
          // this is a crop in history
          if (cropObject.active === true) {
            state.cropHistory = state.cropHistory.filter(function (obj) {
              return obj.id !== cropObject.id;
            });
            state.crops.push(cropObject);
            // console.log("moved to active", cropObject, state.crops);
          }
        }
      } else {
        // we are updating a crop in the same state
        if (cropObject.active === true) {
          state.crops = state.crops.filter(function (obj) {
            return obj.id !== cropObject.id;
          });
          state.crops.push(cropObject);
        } else {
          state.cropHistory = state.cropHistory.filter(function (obj) {
            return obj.id !== cropObject.id;
          });
          state.cropHistory.push(cropObject);
        }
      }
    },
    DELETE_CROP(state, cropObject) {
      state.crops = state.crops.filter(function (obj) {
        return obj.id !== cropObject.id;
      });
      state.cropHistory = state.cropHistory.filter(function (obj) {
        return obj.id !== cropObject.id;
      });
    },
    RESET_CROPS(state) {
      state.crops = [];
      state.cropHistory = [];
      state.bounds = [];
    },
  },
  actions: {
    async getCrops({ commit }) {
      const data = await client.crops.read(true);
      if (data) {
        commit("SET_CROPS", data.crops);
      }
    },
    async getCropHistory({ commit }) {
      const data = await client.crops.read(false);
      if (data) {
        commit("SET_CROP_HISTORY", data.crops);
      }
    },
    async createCrop({ commit }, cropObject) {
      // console.log(
      //   "create crop",
      //   cropObject.fieldId,
      //   cropObject.crop,
      //   commit,
      //   dispatch,
      // );
      const data = await client.crops.create(
        cropObject.fieldId,
        cropObject.crop,
      );
      if (data) {
        // console.log(data);
        commit("ADD_CROP", data);
        // console.log("crop added", state.crops);
        // this.dispatch("getCrops");
        // this.dispatch("getCropHistory");
        return data;
      }
    },
    async updateCrop({ commit }, crop) {
      const data = await client.crops.update(crop.id, crop);
      if (data) {
        // console.log(data);
        commit("UPDATE_CROP", data);
        // this.dispatch("getCrops");
        // this.dispatch("getCropHistory");
      }
    },
    async deleteCrop({ commit }, crop) {
      const data = await client.crops.delete(crop.id);
      if (data) {
        // console.log(data);
        commit("DELETE_CROP", crop);
        this.dispatch("getCrops");
        this.dispatch("getCropHistory");
      }
    },
  },
  getters: {
    getAllCrops(state) {
      return state.crops;
    },
    getAllHistoricalCrops(state) {
      return state.cropHistory;
    },
    getCropById: (state) => (cropId) => {
      return state.crops.find((crop) => crop.id === parseInt(cropId));
    },
    getCropHistoryById: (state) => (cropId) => {
      return state.cropHistory.find((crop) => crop.id === parseInt(cropId));
    },
    getAnyCropById: (state) => (cropId) => {
      // finds crop in active or crop history
      let allCrops = state.crops.concat(state.cropHistory);
      return allCrops.find((crop) => crop.id === parseInt(cropId));
    },
    getAnyCropByFieldId: (state) => (fieldId) => {
      // finds crop in active or crop history
      let allCrops = state.crops.concat(state.cropHistory);
      return allCrops.filter((crop) => crop.field === parseInt(fieldId));
    },
    getFieldIdByCropId: (state) => (cropId) => {
      // finds field ID from active crops
      return state.crops.find((crop) => crop.id === parseInt(cropId))?.field;
    },
    getCropsByFieldId: (state) => (fieldId) => {
      // finds crop in active or crop history
      let allCrops = state.crops.concat(state.cropHistory);
      let fieldCrops = allCrops.filter(
        (crop) => crop.field === parseInt(fieldId),
      );
      return _.sortBy(fieldCrops, "harvest_range_end");
    },
    getCropByFieldId: (state) => (fieldId) => {
      return state.crops.find((crop) => crop.field === parseInt(fieldId));
    },
    getCropByType: (state) => (cropType) => {
      return state.crops.find((crop) => crop.crop_type === cropType);
    },
    getNonAllocatedCropAcres: (state) => (cropId) => {
      // get sum of all allocation acres attached to this cropID
      // sumtract the sum from this crop's acres
      // return the value
      return state.growerAllocations.filter(
        (allocation) => allocation.org === parseInt(cropId),
      );
    },
    getCrops:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let crops = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.crops,
        );
        return crops;
      },
    getExpandedCrops:
      (state, getters, rootState) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let crops = state.crops;
        let growerAllocations = rootState.growerAllocations.growerAllocations;
        let fields = rootState.fields.fields;
        let expandedCrops = crops.map((crop) => {
          let cropField = fields.find((field) => field.id === crop.field);
          let farm = cropField?.properties.farm;
          let field = cropField?.properties.name;
          let fieldId = cropField?.id;
          let acres = cropField?.properties.acres;
          let cropAllocations = growerAllocations.filter(
            (allocation) => allocation.crop === crop.id,
          );
          let sum = cropAllocations.reduce((accumulator, object) => {
            return accumulator + object.acres;
          }, 0);
          let isAllocated = sum > 0 ? true : false;
          let acresAllocated = sum;
          let remainingAcres = acres - sum;
          return {
            ...crop,
            isAllocated,
            acresAllocated,
            remainingAcres,
            farm,
            field,
            fieldId,
            acres,
          };
        });
        let finalCrops = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          expandedCrops,
        );
        // console.log("expanded crops", finalCrops);
        return finalCrops;
      },

    getExpandedCropHistory:
      (state, getters, rootState) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let crops = state.cropHistory;
        // console.log("crop History", crops, filter);
        let growerAllocations = rootState.growerAllocations.growerAllocations;
        let fields = rootState.fields.fields;
        let expandedCrops = crops.map((crop) => {
          let cropField = fields.find((field) => field.id === crop.field);
          let farm = cropField?.properties.farm;
          let field = cropField?.properties.name;
          let fieldId = cropField?.id;
          let acres = cropField?.properties.acres;
          let cropAllocations = growerAllocations.filter(
            (allocation) => allocation.crop === crop.id,
          );
          let sum = cropAllocations.reduce((accumulator, object) => {
            return accumulator + object.acres;
          }, 0);
          let isAllocated = sum > 0 ? true : false;
          let acresAllocated = sum;
          let remainingAcres = acres - sum;
          return {
            ...crop,
            isAllocated,
            acresAllocated,
            remainingAcres,
            farm,
            field,
            fieldId,
            acres,
          };
        });
        let finalCrops = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          expandedCrops,
        );
        // console.log("expanded crops", finalCrops);
        return finalCrops;
      },
  },
};

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          {
            name: 'originator_details.name',
            label: 'Originator',
            filtered: false,
          },
          { name: 'accepted', label: 'Active' },
          {
            name: 'originator_details.contact_email',
            label: 'Contact Email',
            filtered: false,
          },
          {
            name: 'originator_details.contact_phone',
            label: 'Contact Phone',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="originators"
        @update:derived-collection="derivedOriginators = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="o in derivedOriginators" :key="o.originator_details.id">
        <table-data td-label="Originator">
          {{ o.originator_details?.name }}
        </table-data>
        <table-data td-label="Active">
          {{ o.accepted }}
        </table-data>
        <table-data td-label="Contact Email">
          {{ o.originator_details?.contact_email }}
        </table-data>
        <table-data td-label="Contact Phone">
          {{ o.originator_details?.contact_phone }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-originator-single',
              params: { originatorId: o.originator_details.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedOriginators: [],
    };
  },
  computed: {
    originators() {
      let originators = this.$store.getters.getBuyerContractOriginators({});
      // originators = originators.filter(
      //   (originator) => originator.deleted == null,
      // );
      return originators;
    },
  },
  methods: {},
};
</script>

<style></style>

<template>
  <styled-input
    :type="type"
    :inputTitle="inputTitle"
    :required="required"
    :displayOnly="displayOnly"
    :wideItem="wideItem"
    :placeholder="placeholder"
    :classProp="classProp"
    :helpText="helpText"
    :helpTitle="helpTitle"
    v-model="inputValue"
  />
</template>

<script>
import StyledInput from "./StyledInput.vue";
export default {
  components: {
    StyledInput,
  },
  props: {
    inputTitle: { type: String },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: String },
    placeholder: { type: String },
    classProp: { type: String },
    required: { type: Boolean, default: false },
    wideItem: { type: Boolean, default: false },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    modelValue: {},
  },
  emits: ["update:modelValue"],
  data() {
    // This section is just to be backward compatible when displayOnlyValue was how the value was passed in for read only.
    let inputValue = this.modelValue;
    if (this.displayOnly) inputValue = this.displayOnlyValue;
    //
    return {
      inputValue,
    };
  },
  computed: {
    type() {
      return this.displayOnly && this.inputValue == null ? "text" : "date";
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
  },
};
</script>

export default {
  state() {
    return {
      parcelApiError: null,
      parcelApiErrorResponse: null,
    };
  },
  mutations: {
    SET_PARCEL_API_ERROR(state, error) {
      state.parcelApiError = error.error;
      state.parcelApiErrorResponse = error.parcelApiErrorResponse;
    },
    CLEAR_PARCEL_API_ERROR(state) {
      state.parcelApiError = null;
      state.parcelApiErrorResponse = null;
    },
  },
  actions: {
    async catchParcelApiError({ commit }, error) {
      let responseObj = {};
      if (error.response) {
        let text = await error.response.text();
        if (text) responseObj = JSON.parse(text);
      }
      let errorObj = {
        error: error,
        parcelApiErrorResponse: responseObj,
      };
      commit("SET_PARCEL_API_ERROR", errorObj);
    },
    async clearParcelApiError({ commit }) {
      commit("CLEAR_PARCEL_API_ERROR");
    },
  },
  getters: {
    getApiError: (state) => {
      return state.parcelApiError;
    },
    getparcelApiErrorResponse: (state) => {
      return state.parcelApiErrorResponse;
    },
  },
};

<template>
  <page-card headingTitle="New Contract Assignment">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newOfferFormId"
      />
    </template>
    <template #cardDetails>
      <assignment-form :formId="newOfferFormId" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import AssignmentForm from "@/layouts/buyer-portal/bp-controller/assignments/AssignmentForm.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    AssignmentForm,
  },

  data() {
    return {
      newOfferFormId: "new-offer-form",
    };
  },
  computed: {},
  methods: {},
};
</script>

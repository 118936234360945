<template>
  <display-only
    displayTitle="Total Delivered Quantity"
    :displayValue="totalDelivered"
  />
</template>

<script>
import DisplayOnly from "@/components/form/DisplayOnly.vue";

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  components: {
    DisplayOnly,
  },
  computed: {
    currentOrderDeliveries() {
      let deliveries =
        this.$store.state.growerDeliveries.growerDeliveries.filter(
          (d) => d.crop_order === parseInt(this.orderId),
        );
      return deliveries;
    },
    totalDelivered() {
      let sum = 0;
      for (let d of this.currentOrderDeliveries) {
        sum += d.quantity_delivered;
      }
      return sum;
    },
  },
  methods: {},
};
</script>

<template>
  <section>
    <hauling-schedule :vendorPerspective="true" />
  </section>
  <h1 class="pt-4">All Reservations</h1>
  <table-card>
    <table-head
      :columns="[
        { name: 'service_title', label: 'Listing' },
        { name: 'buyer_name', label: 'Customer' },
        {
          name: 'pickup_datetime',
          label: 'Date/Time',
          filtered: false,
        },
        { name: 'pretty_status', label: 'Status' },
        { name: 'actions', label: '', filtered: false },
      ]"
      :collection="haulingOrders"
      @update:derivedCollection="derivedOrders = $event"
      @update:filter="selectFilter"
      :defaultFilter="pageFixedFilters"
      :defaultSort="pageSorts"
    >
      <template #toolbarButtons>
        <div class="flex">
          <div class="hidden sm:flex">
            <table-toolbar-button
              buttonText="Export"
              heroIcon="DownloadIcon"
              @buttonClick="exportTable"
            />
          </div>
        </div>
      </template>
    </table-head>
    <tbody>
      <tr v-for="p in derivedOrders" :key="p">
        <table-data td-label="Listing">
          <div class="flex">
            {{ p.service_title }}
          </div>
        </table-data>
        <table-data td-label="Customer">
          {{ p.buyer_name }}
        </table-data>
        <table-data td-label="Date/Time">
          {{ p.pickup_datetime_str }}
        </table-data>
        <table-data td-label="Status">
          {{ p.pretty_status }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'vendor-single-order',
              params: { orderId: p.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import HaulingSchedule from "@/layouts/common/hauling/HaulingSchedule.vue";
import { exportToCSV } from "@/components/composables/exportFile";
import { flattenObj } from "@/components/composables/scriptUtils.js";
import moment from "moment";
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
    HaulingSchedule,
  },
  data() {
    return {
      derivedOrders: [],
      searchValue: "",
      filterObject: {},
      pageFixedFilters: {},
      pageSorts: { pickup_datetime: "desc" },
    };
  },
  methods: {
    selectFilter(e) {
      // console.log("selectFilter", e);
      this.$store.dispatch("setFieldsFilter", e);
    },
    exportTable() {
      let exportData = this.derivedOrders.map((o) => {
        let f = flattenObj(o);
        return {
          ...f,
        };
      });
      exportToCSV(exportData, "reservations.csv");
    },
    getAgreement(id) {
      return this.agreements.find((a) => a.id == id);
    },
    prettyStatus(status) {
      switch (status) {
        case "buyer_submitted":
          return "Ready to Fulfill";
        case "seller_completed":
          return "Fulfilled";
        case "seller_canceled":
          return "Seller Canceled";
        case "buyer_canceled":
        case "buyer_closed":
          return "Buyer Canceled";
        default:
          return status;
      }
    },
  },
  computed: {
    haulingOrders() {
      let dateFormat = {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      let haulingAgreements = this.$store.getters.getDerivedVendorQuotes({
        filter: { service_category: "Hauling" },
      });
      let haulingAgreementIds = haulingAgreements.map((ha) => {
        return ha.id;
      });
      let orders = this.$store.state.vendorOrders.vendorOrders;
      let ordersWithHaulingAgreement = orders.filter((o) => {
        return haulingAgreementIds.includes(o.agreement);
      });
      // only show reservations for the current year
      ordersWithHaulingAgreement = ordersWithHaulingAgreement.filter(
        (reservation) => {
          return (
            new Date(reservation.details?.datetime).getYear() ===
            new Date().getYear()
          );
        },
      );
      // Add any additional columns we need for the table so our filters can be properly applied
      let offset = new Date().getTimezoneOffset();
      for (let o of ordersWithHaulingAgreement) {
        let agreement = this.$store.state.vendorQuotes.vendorQuotes.find(
          (a) => a.id == o.agreement,
        );
        o.service_title = agreement?.service_title;
        o.buyer_name = agreement?.buyer_details.name;
        o.pretty_status = this.prettyStatus(o.status);
        o.pickup_datetime = moment(new Date(o.details?.datetime))
          .add(offset, "m")
          .toDate();
        o.pickup_datetime_str = o.pickup_datetime.toLocaleString(
          "en",
          dateFormat,
        );
      }
      return ordersWithHaulingAgreement;
    },
  },
};
</script>

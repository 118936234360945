<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          {
            name: 'affiliate_details.name',
            label: 'Affiliate',
            filtered: false,
          },
          { name: 'accepted', label: 'Active' },
          {
            name: 'affiliate_details.contact_email',
            label: 'Contact Email',
            filtered: false,
          },
          {
            name: 'affiliate_details.contact_phone',
            label: 'Contact Phone',
            filtered: false,
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="affiliates"
        @update:derived-collection="derivedAffiliates = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="navigateNewAffiliate"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="a in derivedAffiliates" :key="a.affiliate_details.id">
        <table-data td-label="Affiliate">
          {{ a.affiliate_details.name }}
        </table-data>
        <table-data td-label="Active">
          {{ a.accepted }}
        </table-data>
        <table-data td-label="Contact Email">
          {{ a.affiliate_details?.contact_email }}
        </table-data>
        <table-data td-label="Contact Phone">
          {{ a.affiliate_details?.contact_phone }}
        </table-data>
        <table-data class="text-center">
          <button
            @click="deleteAffiliate(a.affiliate_details.id)"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Delete
          </button>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedAffiliates: [],
    };
  },
  computed: {
    affiliates() {
      let affiliates = this.$store.getters.getControllerMarketAffiliates;
      return affiliates;
    },
  },
  methods: {
    navigateNewAffiliate() {
      this.$router.push({ name: "buyer-affiliate-new" });
    },
    deleteAffiliate(affiliateId) {
      this.$store.dispatch("deleteControllerMarketAffiliate", affiliateId);
    },
  },
};
</script>

<style></style>

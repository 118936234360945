<template>
  <!-- <complete-product-listing
    :currentProduct="currentProduct"
    :vendorPerspective="false"
  /> -->
  <ListingContainer>
    <template #heroImage>
      <img
        v-if="!currentProduct.image_url"
        src="@/assets/no_logo_default.jpeg"
        class="object-contain object-center"
        :alt="`${currentProduct.title} Logo`"
      />
      <img
        v-else
        :src="currentProduct.image_url"
        :alt="`${currentProduct.title} Logo`"
        class="object-contain object-center"
      />
    </template>
    <template #heroTitle>{{ currentProduct.title }} </template>
    <template #heroDescription
      ><p class="mt-6 text-gray-500">{{ currentProduct.description }}</p>
    </template>
    <template #heroActions>
      <button
        type="button"
        class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
        @click="openGetQuoteTab"
      >
        {{ activeAgreement ? "Order Now" : "Get Quote" }}
      </button>
    </template>
    <!-- <template #recommendationsTop>
    </template> -->
    <template #sections>
      <ListingSections
        :tabSlots="tabs"
        :activeTab="selectedTab"
        @changeTab="changeTab"
      >
        <template #details>
          <div v-html="currentProduct.details" class="prose"></div>
        </template>
        <template #attachments>
          <view-attachments-table :attachments="currentProduct.attachments" />
        </template>
        <template #form>
          <div class="mt-2" v-if="activeAgreement">
            <form
              @submit.prevent="() => submitNewBuyGrowerOrderForm()"
              id="newBuyGrowerOrderForm"
            >
              <order-object-interface
                :read-only="false"
                :product="currentProduct"
                :active-agreement-id="activeAgreement.id"
                :fields="fields"
                :equipment="equipment"
                :locations="locations"
                v-model="currentOrder"
                @update-order="currentOrder = $event"
              />
              <div class="mt-2"></div>
              <submit-button
                buttonText="Submit Order Form"
                formId="newBuyGrowerOrderForm"
              />
            </form>
          </div>
          <complete-quote-form :vendorPerspective="false" v-else />
        </template>
      </ListingSections>
    </template>
    <!-- <template #recommendationsBot>
    </template> -->
  </ListingContainer>
</template>

<script>
import ListingContainer from "@/layouts/common/listing/ListingContainer.vue";
import ListingSections from "@/layouts/common/listing/ListingSections.vue";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import CompleteQuoteForm from "@/layouts/common/quotes/CompleteQuoteForm.vue";
import OrderObjectInterface from "@/layouts/common/orders/OrderObjectInterface.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";

import { useToast } from "vue-toastification";
export default {
  components: {
    ListingContainer,
    ListingSections,
    ViewAttachmentsTable,
    CompleteQuoteForm,
    OrderObjectInterface,
    SubmitButton,
  },
  data() {
    return {
      toast: useToast(),
      currentOrder: null,
      selectedTab: 0,
    };
  },
  computed: {
    currentProduct() {
      let product = this.$store.getters.getGrowerProductById(
        this.$route.params.productId,
      );
      return product;
    },
    fields() {
      return this.$store.state.fields.fields;
    },
    equipment() {
      return this.$store.state.equipment.equipment;
    },
    locations() {
      return this.$store.state.growerLocations.growerLocations;
    },
    activeAgreement() {
      let agreements = this.$store.getters.getActiveAgreementsByProductId(
        this.currentProduct.id,
      );
      if (agreements.length > 0) {
        return agreements[0];
      } else {
        return null;
      }
    },
    tabs() {
      let tabs = [];
      tabs.push({ slotName: "details", displayName: "Details" });
      tabs.push({ slotName: "attachments", displayName: "Attachments" });
      if (this.activeAgreement) {
        tabs.push({ displayName: "Order", slotName: "form" });
      } else tabs.push({ displayName: "Quote", slotName: "form" });
      return tabs;
    },
  },
  methods: {
    async submitNewBuyGrowerOrderForm() {
      await this.$store.dispatch("createGrowerOrder", this.currentOrder);
      if (!this.$store.getters.getApiError) {
        this.toast.success("Order Submitted!", {
          timeout: 1500,
          hideProgressBar: true,
        });
        if (this.navigateOnSubmit) {
          this.$router.push({ name: "grower-portal-buy-orders-all" });
        }
      }
    },
    openGetQuoteTab() {
      this.selectedTab = 2;
      // console.log("openGetQuoteTab");
    },
    changeTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>

<style></style>

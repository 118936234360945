<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'seller_details.name', label: 'Grower' },
          { name: 'crop_type', label: 'Crop' },
          { name: 'crop_details.variety', label: 'Variety' },
          {
            name: 'asking_price',
            label: 'Asking Price',
            filtered: false,
          },
          {
            name: 'total_available',
            label: 'Qty. Avail.',
            filtered: false,
          },
          {
            name: 'qty_units',
            label: 'Sold By',
          },
          {
            name: 'harvest_year',
            label: 'Harvest Year',
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="listings"
        @update:derivedCollection="derivedListings = $event"
        :defaultFilter="defaultFilter"
        :defaultSort="defaultSort"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="listing in derivedListings" :key="listing.id">
        <td td-label="Vendor">{{ listing.seller_details.name }}</td>
        <td td-label="Variety">{{ listing.crop_type }}</td>

        <td td-label="Variety">{{ listing.crop_details?.variety }}</td>
        <td td-label="Asking Price">${{ listing.asking_price }}</td>
        <td td-label="Quantity Available">
          {{ listing.total_available }}
        </td>
        <td td-label="Sold By">
          {{ listing.qty_units }}
        </td>
        <td td-label="Harvest Year">{{ listing.harvest_year }}</td>
        <td class="link hover:bg-gray-100 hover:text-violet-600">
          <router-link
            :to="{
              name: 'buyer-produce-listings-single',
              params: { listingId: listing.id },
            }"
            class="text-violet-500 hover:text-twilight-900"
            >View / Inquire
            <span class="sr-only">View</span>
          </router-link>
        </td>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";

export default {
  components: {
    TableCard,
    TableHead,
  },
  data() {
    return {
      derivedListings: [],
      defaultFilter: {},
      defaultSort: { title: "asc", description: "asc" },
    };
  },
  computed: {
    listings() {
      return this.$store.state.buyerListings.buyerListings;
    },
    loggedIn() {
      return this.$store.getters.getUserData;
    },
  },
  methods: {},
};
</script>

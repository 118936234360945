<template>
  <div>
    <!-- <h2 class="text-sm font-medium text-gray-500">Actions</h2> -->
    <ul
      role="list"
      class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
    >
      <slot name="gridItems"></slot>
    </ul>
  </div>
</template>

<script></script>

<template>
  <page-card
    :headingTitle="
      currentOrder?.quantity +
      ' ' +
      currentOffer?.quantity_units +
      ' of ' +
      currentOrder?.details?.crop +
      ' to ' +
      currentOrder?.buyer_details?.name
    "
    :headingDescription="'Order # ' + $route.params.orderId"
  >
    <template #buttonArea>
      <base-button buttonText="Back to Order" @buttonClick="navigateToOrder" />
      <base-button
        buttonText="Back"
        :marginLeft="true"
        :disabled="currentStep == 1"
        @buttonClick="handleStepChange(currentStep - 1)"
      />
      <base-button
        buttonText="Next"
        :marginLeft="true"
        :disabled="currentStep == 3"
        @buttonClick="handleStepChange(currentStep + 1)"
      />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-6">
        <horizontal-form-steps :steps="finalizeOrderSteps" />
      </div>
      <allocations-manager
        v-show="currentStep == 1"
        :orderId="parseInt($route.params.orderId)"
        :readOnly="true"
      />
      <premiums-manager
        v-show="currentStep == 2"
        :orderId="parseInt($route.params.orderId)"
        @updatePremiumData="setPremiumData"
      />
      <p v-if="currentStep == 3" class="sm:col-span-6">
        I hereby certify that all information provided is true, complete, and
        accurate. I understand that any false or misleading information may
        result in the disqualification of my application or participation in the
        Program, and I may be subject to any applicable penalties or
        consequences.
      </p>
      <base-button
        v-if="currentStep == 3"
        buttonText="Finalize and Submit Order"
        @buttonClick="finalizeOrder"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import { useToast } from "vue-toastification";

import HorizontalFormSteps from "@/components/progress/HorizontalFormSteps.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import AllocationsManager from "@/layouts/grower-portal/sell/orders/AllocationsManager.vue";
import PremiumsManager from "@/layouts/grower-portal/sell/orders/PremiumsManager.vue";

// import { z } from "zod";

export default {
  components: {
    PageCard,
    BaseButton,
    HorizontalFormSteps,
    AllocationsManager,
    PremiumsManager,
  },
  data() {
    return {
      toast: useToast(),
      premiumData: null,
    };
  },
  computed: {
    currentStep() {
      if (this.$route.query.step > 3 || this.$route.query.step < 1) {
        this.handleStepChange(0);
      }
      return this.$route.query.step ? parseInt(this.$route.query.step) : 1;
    },
    finalizeOrderSteps() {
      return [
        {
          name: "Confirm Allocations",
          status: this.stepStatus(1),
          id: 1,
        },
        {
          name: "Confirm Premiums",
          status: this.stepStatus(2),
          id: 2,
        },
        {
          name: "Complete Order",
          status: this.stepStatus(3),
          id: 3,
        },
      ];
    },
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    // CORE DATA
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the order
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the offer
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    allPremiumsValidated() {
      if (!this.premiumData) {
        return false;
      }
      if (
        this.premiumData.completedPremiums === this.premiumData.totalPremiums
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    navigateToOrder() {
      this.$router.push({
        name: "grower-portal-offer-order",
        params: { orderId: this.orderId },
      });
    },
    handleStepChange(step) {
      this.$router.push({
        query: {
          step: step,
        },
      });
    },
    stepStatus(stepIndex) {
      if (stepIndex < this.currentStep) {
        return "complete";
      } else if (stepIndex == this.currentStep) {
        return "current";
      } else {
        return "inactive";
      }
    },
    async finalizeOrder() {
      if (!this.allPremiumsValidated) {
        this.toast.error(
          "Please validate all premiums before finalizing order.",
        );
        this.handleStepChange(2);
        return;
      }
      this.$store.dispatch("updateGrowerCropOrder", {
        id: this.orderId,
        status: "Completed",
      });
      this.toast.success("Order has been finalized and submitted.", {
        timeout: 1500,
        hideProgressBar: true,
      });
      this.$router.push({
        name: "grower-portal-produce-orders",
      });
    },
    setPremiumData(data) {
      this.premiumData = data;
    },
  },
  watch: {},
};
</script>

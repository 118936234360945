<template>
  <chat-window
    :room-id="threadId"
    :rooms="formattedRooms"
    :messages="formattedMessages"
    :current-user-id="currentUserId"
    :loading-rooms="false"
    :rooms-loaded="true"
    :messages-loaded="true"
    @send-message="sendMessage"
    :show-audio="false"
    :show-files="false"
    :show-reaction-emojis="false"
    :username-options="{ minUsers: 1, currentUser: false }"
    :message-actions="[]"
    :menu-actions="[]"
    :load-first-room="false"
    :single-room="true"
  />
</template>

<script>
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import moment from "moment";
import { io } from "socket.io-client";

export default {
  props: {
    threadId: Number,
  },
  components: {
    ChatWindow,
  },
  data() {
    return {
      users: this.$store.state.messages.users,
      currentUser: this.$store.getters.getCurrentUser,
      currentUserId: this.$store.getters.getCurrentUser.id,
    };
  },
  computed: {
    threadsFromState() {
      return this.$store.getters.getDerivedThreads({
        sort: { latest_sent: "desc" },
        filter: { id: this.threadId },
      });
    },
    formattedRooms() {
      let result = [];
      this.threadsFromState.forEach((thread) => {
        result.push({
          roomId: thread.id,
          roomName: thread.subject,
          lastMessage: thread.latest
            ? {
                content: thread.latest.content,
                senderId: thread.latest.user,
                timestamp: moment(thread.latest.sent).fromNow(),
              }
            : undefined,
          users: this.$store.state.messages.users.map((user) => {
            return {
              _id: user.id,
              username: user.name,
            };
          }),
        });
      });
      return result;
    },
    formattedMessages() {
      let result = [];
      if (this.threadId) {
        this.$store.getters
          .getDerivedMessages({
            filter: { thread: Number(this.threadId) },
            sort: { sent: "asc" },
          })
          .forEach((message, index) => {
            result.push({
              _id: message.id,
              indexId: index,
              content: message.content,
              date: moment(message.sent).format("MMMM Do, YYYY"),
              timestamp: moment(message.sent).format("h:mm A"),
              senderId: message.user,
              username: this.$store.state.messages.users.filter(
                (user) => user.id == message.user,
              )[0]?.name,
            });
          });
      }
      return result;
    },
  },
  methods: {
    sendMessage(e) {
      let message = {
        content: e.content,
      };
      this.$store.dispatch("createMessage", {
        threadId: this.threadId,
        message,
      });
    },
  },
  mounted() {
    this.socket = io(process.env.VUE_APP_API_URL, {
      path: "/stream/socket.io",
      withCredentials: true,
    });
    this.socket.on("new-message", (message) => {
      if (message.user !== this.currentUserId) {
        this.$store.commit("ADD_MESSAGE", message);
      }
    });
  },
  unmounted() {
    this.socket.close();
  },
};
</script>

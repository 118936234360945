<template>
  <page-card headingTitle="New Order">
    <template #cardDetails>
      <div class="col-span-6">
        <div class="grid grid-cols-2 md:grid-cols-10">
          <div
            class="text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Order From
          </div>
          <select-dropdown
            inputTitle="Vendors"
            :selectOptions="haulingOptions"
            v-model="selectedServiceId"
            class="ml-1"
            :required="true"
          />
        </div>
        <form
          @submit.prevent="() => submitNewBuyGrowerOrderForm()"
          id="newBuyGrowerOrderForm"
          v-if="selectedService"
        >
          <order-object-interface
            :product="selectedService"
            :active-agreement-id="selectedAgreementId"
            :fields="fields"
            :locations="locations"
            :readOnly="false"
            ref="orderInterfaceComponent"
            @update-order="currentOrder = $event"
          />
          <div class="mt-2"></div>
          <submit-button
            buttonText="Submit Order Form"
            formId="newBuyGrowerOrderForm"
          />
        </form>
        <!-- <div class="mt-2 flex justify-end">
          <button
            @click="clearForm"
            type="button"
            class="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-twilight-500 focus:ring-offset-2"
          >
            Clear Form
          </button>
          <button
            type="submit"
            class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-twilight-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-twilight-700 focus:outline-none focus:ring-2 focus:ring-twilight-500 focus:ring-offset-2"
          >
            Add Reservation to Cart
          </button>
        </div> -->
      </div>
    </template>
    <!-- <template #componentSection>
      <div>
        <buy-grower-orders-table />
      </div>
    </template> -->
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
// import CompleteOrderForm from "@/layouts/common/orders/CompleteOrderForm.vue";
// import BuyGrowerOrdersTable from "@/layouts/grower-portal/buy/orders/BuyGrowerOrdersTable.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import OrderObjectInterface from "@/layouts/common/orders/OrderObjectInterface.vue";
import { useToast } from "vue-toastification";
export default {
  components: {
    PageCard,
    SelectDropdown,
    // CompleteOrderForm,
    // BuyGrowerOrdersTable,
    SubmitButton,
    OrderObjectInterface,
  },
  data() {
    return {
      selectedServiceId: null,
      currentOrder: null,
      toast: useToast(),
    };
  },
  computed: {
    // this can be improved. Instead you should get derivedGrowerAgreements that are active ('buyer_confirmed') and where service_category === 'Hauling'
    // from there you should hit the growerServices getOneService endpoint to get the service details
    activeAgreements() {
      let activeAgreements = this.$store.getters.getActiveAgreements;
      return activeAgreements;
    },
    haulingOptions() {
      let haulingServices = this.$store.getters.getDerivedGrowerProducts({
        filter: { category: "Hauling" },
      });
      if (this.activeAgreements.length <= 0) {
        return [];
      }
      let activeAgreementServiceIds = [];
      this.activeAgreements.forEach((agreement) => {
        activeAgreementServiceIds.push(agreement.service);
      });
      let validHaulingServices = [];
      // for each hauling service, add it to validHaulingServices if the id is in activeAgreementServiceIds
      haulingServices.forEach((service) => {
        if (activeAgreementServiceIds.includes(service.id)) {
          validHaulingServices.push(service);
        }
      });
      // map validHaulingServices to an array of objects with label equal to title and value equal to the entire object.
      // also add the activeAgreementId to the value object
      validHaulingServices = validHaulingServices.map((service) => {
        return {
          label: service.title + " - " + service.seller_details.name,
          value: service.id,
        };
      });
      return validHaulingServices;
    },
    selectedService() {
      if (!this.selectedServiceId) {
        return null;
      } else {
        let service = this.$store.getters.getGrowerProductById(
          this.selectedServiceId,
        );
        return service;
      }
    },

    fields() {
      return this.$store.state.fields.fields;
    },
    equipment() {
      return this.$store.state.equipment.equipment;
    },
    locations() {
      return this.$store.state.growerLocations.growerLocations;
    },
    selectedAgreementId() {
      if (!this.selectedServiceId) {
        return null;
      } else {
        let selectedAgreement = this.activeAgreements.find(
          (agreement) => agreement.service === this.selectedServiceId,
        );
        return selectedAgreement.id;
      }
    },
  },
  methods: {
    clearForm() {
      this.selectedService = null;
    },
    async submitNewBuyGrowerOrderForm() {
      await this.$store.dispatch("createGrowerOrder", this.currentOrder);
      this.toast.success("Order Submitted!", {
        timeout: 1500,
        hideProgressBar: true,
      });
      this.$refs.orderInterfaceComponent.partialClearDetailsForm();
      // if (!this.$store.getters.getApiError) {
      //   if (this.navigateOnSubmit) {
      //     this.$router.push({ name: "grower-portal-buy-orders-all" });
      //   }
      // }
    },
  },
  watch: {},
};
</script>

<template>
  <slot-modal
    :open="modalIsActive"
    modalTitle="Respond to Listing"
    modalDescription="Submit your response to the interested buyer. You will be connected with the buyer upon submission so that specific terms may be confirmed."
    @closeModal="cancelRequest"
  >
    <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template>
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <text-input
          :wideItem="true"
          inputTitle="Crop"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentListing?.crop_type"
        />
        <number-input
          :wideItem="true"
          inputTitle="Quantity Available"
          inputHtmlType="text"
          :required="true"
          :displayOnly="true"
          :displayOnlyValue="currentListing?.total_available"
        />
        <text-input
          :wideItem="true"
          inputTitle="Units"
          inputHtmlType="text"
          :displayOnly="true"
          :displayOnlyValue="currentListing?.qty_units"
        />
        <div class="hidden sm:col-span-3" />
        <number-input
          :wideItem="true"
          inputTitle="Quantity You'd Like to Buy"
          inputHtmlType="text"
          :required="true"
          v-model="inquiryQuantity"
        />
      </div>
    </template>
    <template #buttons
      ><button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        @click="openListingInquiry"
      >
        Submit
      </button></template
    >
  </slot-modal>
  <div class="bg-white">
    <main
      class="mx-auto px-4 pb-24 pt-4 sm:px-6 sm:pb-32 sm:pt-6 lg:max-w-7xl lg:px-8"
    >
      <button
        class="flex text-violet-500 hover:text-twilight-900"
        @click="
          $router.replace({
            name: parentRouteId,
          })
        "
      >
        <chevron-left-icon class="mt-1 h-4 w-4" />back
        <span class="sr-only">, Back Button</span>
      </button>
      <!-- Product -->
      <div
        class="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16"
      >
        <!-- Product image -->
        <div class="lg:col-span-4 lg:row-end-1">
          <div
            class="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100"
          >
            <img
              :src="product.imageSrc"
              :alt="product.imageAlt"
              class="object-cover object-center"
            />
          </div>
        </div>

        <!-- Product details -->
        <div
          class="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none"
        >
          <div class="flex flex-col-reverse">
            <div class="mt-4">
              <h1
                class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl"
              >
                {{ currentListing.title }}
              </h1>

              <h2 id="information-heading" class="sr-only">
                Product information
              </h2>
              <p class="mt-2 text-sm text-gray-500">
                {{ crop }}
              </p>
            </div>
          </div>

          <button
            type="button"
            class="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            @click="intializeRequestModal(listingId)"
            v-if="listingId"
          >
            Open Inquiry
          </button>
          <div class="mt-10 border-t border-gray-200 pt-10">
            <h3 class="text-sm font-medium text-gray-900">Highlights</h3>
            <div class="prose prose-sm mt-4 text-gray-500">
              <ul role="list">
                <li v-for="highlight in product.highlights" :key="highlight">
                  {{ highlight }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-span-6">
          <p class="mt-6 text-gray-500">{{ currentListing.description }}</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ChevronLeftIcon } from "@heroicons/vue/outline";
import SlotModal from "@/components/modals/SlotModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";

export default {
  components: {
    ChevronLeftIcon,
    SlotModal,
    TextInput,
    NumberInput,
    PaperAirplaneIcon,
  },
  data() {
    let parentRouteId = this.$route.path.includes("grape")
      ? "buyer-listings-grape"
      : "buyer-listings-produce";

    let id = this.$route.params.listingId;
    let listing = this.$store.getters.getBuyerListingById(id);
    let product = {};
    let crop = "";
    if (listing) {
      // Add additional case for each crop type
      switch (listing.crop_type) {
        case "Grape":
          product.imageSrc = "/img/pictures/vineyard.jpg";
          break;
        default:
          product.imageSrc = "/img/pictures/produce.jpg";
      }
      if (listing.org_image) product.imageSrc = listing.org_image;
      if (listing.image) product.imageSrc = listing.image;
      product.imageAlt = listing.title;
      product.highlights = [];

      if (listing.crop_details?.variety)
        crop = listing.crop_details?.variety + " ";
      crop += listing.crop_type;
      product.version = crop;
      // product.highlights.push("Crop: " + crop);
      product.highlights.push(
        "Quantity: " + listing.total_available + " " + listing.qty_units,
      );
      product.highlights.push(
        "Minium Order: " + listing.min_purchase + " " + listing.qty_units,
      );
      let unit = "";
      switch (listing.qty_units) {
        case "Bushels":
          unit = "Bushel";
          break;
        case "Acres":
          unit = "Acre";
          break;
        case "Tons":
          unit = "Ton";
          break;
        default:
          unit = listing.qty_units;
      }
      product.highlights.push(
        "Price: $" + listing.asking_price + " per " + unit,
      );
      if (listing.crop_details?.ava)
        product.highlights.push("AVA: " + listing.crop_details?.ava);
      if (listing.crop_details?.planted)
        product.highlights.push(
          "Year Planted: " + listing.crop_details?.planted,
        );
      if (listing.crop_details?.clone)
        product.highlights.push("Clone: " + listing.crop_details?.clone);
      if (listing.crop_details?.rootstock)
        product.highlights.push(
          "Rootstock: " + listing.crop_details?.rootstock,
        );
      if (listing.shipper)
        product.highlights.push("Shipper: " + listing.shipper);
    } else {
      listing = {};
      listing.id = null;
      product.imageSrc = "/img/pictures/vineyard.jpg";
      product.imageAlt = "Vineyard";
      product.highlights = [
        "Sorry, this listing has been removed by its owner",
      ];
    }

    return {
      toast: useToast(),
      product,
      modalIsActive: false,
      crop,
      inquiryQuantity: null,
      parentRouteId,
      listingIdToRequest: null,
    };
  },
  computed: {
    listingId() {
      return this.$route.params.listingId;
    },
    currentListing() {
      return this.$store.getters.getBuyerListingById(this.listingId);
    },
  },
  methods: {
    intializeRequestModal(listingId) {
      this.modalIsActive = true;
      this.listingIdToRequest = listingId;
    },
    async openListingInquiry() {
      if (!this.currentListing) {
        await this.$store.dispatch("getBuyerListings");
      }
      // console.log("openListingInquiry", this.currentListing);
      if (this.inquiryQuantity === null) {
        this.toast.error("Please enter a quantity");
        return;
      }
      let newThreadId = await this.$store.dispatch("createThread", {
        partner: this.currentListing?.seller_details?.id,
        subject: "Order Discussion ",
      });
      // console.log("newThreadId", newThreadId);
      await this.$store.dispatch("createBuyerCropOrder", {
        listing: parseInt(this.listingId),
        quantity: this.inquiryQuantity,
        units: this.currentListing?.qty_units,
        terms:
          this.inquiryQuantity +
          " " +
          this.currentListing?.qty_units +
          " of " +
          this.currentListing?.crop_type,
        details: {
          threadId: newThreadId,
          crop: this.currentListing?.crop_type,
        },
      });
      let messageContent =
        " We are interested in purchasing " +
        this.inquiryQuantity.toString() +
        " " +
        this.currentListing?.qty_units +
        " of " +
        this.currentListing?.crop_type;
      let message = {
        content: messageContent,
      };
      this.$store.dispatch("createMessage", {
        threadId: newThreadId,
        message,
      });
      // Route to orders
      this.$router.push({ name: "buyer-inquiries" });
      this.listingIdToRequest = undefined;
      this.modalIsActive = false;
    },
    cancelRequest() {
      this.modalIsActive = false;
      this.listingIdToRequest = undefined;
    },
  },
};
</script>

import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    // console.log("setting state");
    return {
      equipment: [],
      bounds: [],
    };
  },
  mutations: {
    SET_SHARED_EQUIPMENT(state, equipmentData) {
      state.equipment = equipmentData;
    },
    SET_SHARED_BOUNDARY_BOX(state, boundsData) {
      if (boundsData) {
        state.bounds = [
          [boundsData[1], boundsData[0]],
          [boundsData[3], boundsData[2]],
        ];
      } else {
        state.bounds = [];
      }
    },
    ADD_SHARED_EQUIPMENT(state, equipmentObject) {
      state.equipment.push(equipmentObject);
    },
    UPDATE_SHARED_EQUIPMENT(state, equipmentObject) {
      state.equipment = state.equipment.filter(function (obj) {
        return obj.id !== equipmentObject.id;
      });
      state.equipment.push(equipmentObject);
    },
    DELETE_SHARED_EQUIPMENT(state, equipmentObject) {
      state.equipment = state.equipment.filter(function (obj) {
        return obj.id !== equipmentObject.id;
      });
    },
    RESET_SHARED_EQUIPMENT(state) {
      state.equipment = [];
      state.bounds = [];
    },
  },
  actions: {
    async getSharedEquipment({ commit, rootState }) {
      let equipmentList = [];
      for (let customer of rootState.customers.customers) {
        if (
          customer.seller_confirmed === true &&
          customer.buyer_confirmed === true
        ) {
          const data = await client.equipment.read(null, {
            org: customer.id,
          });
          if (data) {
            // add each set of equipment to the collection.
            equipmentList = equipmentList.concat(data.equipment);
          }
        }
      }
      commit("SET_SHARED_EQUIPMENT", equipmentList);
      // console.log(
      //   "got shared equipment",
      //   equipmentList,
      //   rootState.sharedEquipment.equipment,
      //   rootState.customers.customers
      // );
    },
  },
  getters: {
    getSharedEquipmentById: (state) => (equipmentId) => {
      return state.equipment.find(
        (equipment) => equipment.id === parseInt(equipmentId),
      );
    },
    getSharedEquipmentByName: (state) => (equipmentName) => {
      return state.equipment.find(
        (equipment) => equipment.properties.name === equipmentName,
      );
    },
    getDerivedSharedEquipment:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = [], // ["updated", "id"] - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedEquipment = state.equipment.map((item) => ({
          ...item,
          ...item.properties,
          ...item.properties.details,
        }));
        let filteredEquipment = _.isEmpty(filter)
          ? expandedEquipment
          : _.filter(expandedEquipment, filter);
        let matchedEquipment = _.isEmpty(match)
          ? filteredEquipment
          : _.filter(filteredEquipment, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedEquipment = _.isEmpty(sort)
          ? matchedEquipment
          : _.orderBy(matchedEquipment, sortColumns, sortOrders);
        let paginatedEquipment =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedEquipment,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedEquipment;
        return paginatedEquipment;
      },
  },
};

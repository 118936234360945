<template>
  <!-- <div class="bg-white px-4 py-5 sm:px-6"> -->

  <div
    class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
  >
    <div class="ml-4 mt-4">
      <p v-if="headingDescription != null" class="mt-1 text-sm text-gray-500">
        {{ headingDescription }}
      </p>
    </div>
    <!-- <div class="mt-4 ml-4 flex-shrink-0">
        <inline-spinner v-if="disableSubmitButton" />
        <base-button
          :buttonText="saveButtonText"
          :marginLeft="true"
          :disabled="disableSubmitButton"
          @buttonClick="clickSave"
        />
      </div> -->
  </div>
  <!-- </div> -->
  <div class="overflow-hidden bg-white">
    <div class="border-t border-none border-gray-200 px-4 sm:px-6">
      <!-- <div class="grid grid-cols-1 sm:grid-cols-3"> -->
      <div class="mt-2 border-t border-none border-gray-200 px-4 py-5 sm:px-6">
        <div
          class="flex border-t pb-4"
          v-for="row in currentRowCount"
          :key="row"
        >
          <div>
            <div v-if="CompanyListing.resources_list[row - 1].image_url">
              <img
                :src="CompanyListing.resources_list[row - 1].image_url"
                class="h-16 w-16"
              />
              <button @click="removeImage($event, row - 1)">
                Remove image
              </button>
            </div>
            <div v-else>
              <file-select
                buttonText="Select an image"
                @change="onFileChange($event, row - 1)"
                @input="setResourceImageIndex($event, row - 1)"
                accept="image/*"
              />
            </div>
          </div>
          <div class="grid w-full grid-cols-1 pl-2 sm:grid-cols-6">
            <variable-string-type-input
              :wideItem="true"
              inputTitle="Title"
              v-model="CompanyListing.resources_list[row - 1].title"
            />
            <variable-string-type-input
              :wideItem="true"
              inputTitle="Link"
              v-model="CompanyListing.resources_list[row - 1].link_url"
            />
            <text-area
              :fullWidth="true"
              inputTitle="Description"
              v-model="CompanyListing.resources_list[row - 1].description"
            />
            <!-- <variable-string-type-input
              :wideItem="true"
              inputTitle="Link Text"
              v-model="CompanyListing.resources_list[row - 1].link_text"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import TextArea from "@/components/form/TextArea.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";
import FileSelect from "@/components/form/FileSelect.vue";
// import InlineSpinner from "@/components/buttons/InlineSpinner.vue";
// import FileSelect from "@/components/form/FileSelect.vue";

export default {
  props: {
    headingDescription: {
      type: String,
    },
    saveButtonText: {
      type: String,
      default: "Save",
    },
    orgProfile: {
      type: Object,
    },
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
    row_count: {
      type: Number,
      default: 3,
    },
  },
  emits: ["saveResources", "updateProfile", "updateImages"],
  components: {
    VariableStringTypeInput,
    TextArea,
    FileSelect,
    // BaseButton,
    // InlineSpinner,
    // FileSelect,
  },
  data() {
    let CompanyListing = this.orgProfile;
    let currentRowCount = CompanyListing.resources_list?.length;
    return {
      CompanyListing,
      currentRowCount,
      imageFiles: [],
    };
  },

  computed: {
    resources_list() {
      return this.orgProfile.resources_list;
    },
  },
  methods: {
    onFileChange(e, i) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.CompanyListing.resources_list[i].image_url = URL.createObjectURL(
        files[0],
      );
    },
    removeImage: function (e, i) {
      if (
        !this.CompanyListing.resources_list[i].image_url?.startsWith("blob")
        // if the image is not a blob, then it is a saved image and we need to remove from server
      ) {
        this.$store.dispatch("deleteOrgProfileResourceImage", { index: i });
      }
      this.CompanyListing.resources_list[i].image_url = null;
      this.imageFiles[i] = null;
    },
    setResourceImageIndex(file, index) {
      // console.log("set image ", file, index);
      this.imageFiles[index] = file;
      // console.log("image files", this.imageFiles);
    },
    clickSave() {
      this.$emit("saveResources");
    },
  },
  watch: {
    CompanyListing: {
      handler: function (newVal) {
        // console.log("CompanyListing changed");
        this.$emit("updateProfile", newVal);
      },
      deep: true,
    },
    imageFiles: {
      handler: function (newVal) {
        // console.log("imageFiles changed");
        this.$emit("updateImages", newVal);
      },
      deep: true,
    },
    resources_list: {
      handler: function (newVal) {
        // watch the resources coming from the prop, it will get an updated image url from the server after the image is uploaded in the parent component
        //console.log("resources from prop changed", newVal);
        let resources_list = newVal;
        // add blank rows to the array if the saved rows are less than the row count
        if (resources_list.length <= this.row_count) {
          for (let i = resources_list.length; i <= this.row_count; i++) {
            resources_list.push({
              title: "",
              description: "",
              link_text: "",
              link_url: "",
              image_url: "",
            });
          }
        }
        this.CompanyListing.resources_list = resources_list;
      },
      deep: true,
    },
  },
};
</script>

<style></style>

<template>
  <td class="whitespace-nowrap px-6 py-4">
    <div class="flex items-center">
      <div class="h-10 w-10 flex-shrink-0">
        <slot name="cellImage"></slot>
      </div>
      <div class="ml-4">
        <div class="text-sm font-medium text-gray-900">
          {{ cellData }}
        </div>
      </div>
    </div>
  </td>
</template>

<script>
export default {
  props: {
    cellData: {},
  },
};
</script>

import client from "@/api/parcel/api.js";
import _ from "lodash";
export default {
  state() {
    return { listings: [], units: [], requests: [] };
  },
  mutations: {
    SET_GROWER_INPUT_LISTINGS(state, data) {
      state.listings = data;
    },
    RESET_GROWER_INPUT_LISTINGS(state) {
      state.listings = [];
    },
    SET_GROWER_INPUT_LISTING_UNITS(state, data) {
      state.units = data;
    },
    ADD_GROWER_INPUT_LISTING_UNITS(state, data) {
      for (let unit of data) {
        if (!state.units.find((u) => u.id === unit.id)) state.units.push(unit);
      }
    },
    ADD_REQUESTS(state, data) {
      for (let request of data) {
        if (!state.requests.find((r) => r.id === request.id))
          state.requests.push(request);
      }
    },
    RESET_GROWER_INPUT_LISTING_UNITS(state) {
      state.units = [];
    },
    RESET_REQUESTS(state) {
      state.requests = [];
    },
  },
  actions: {
    async getGrowerInputListings({ commit, dispatch }) {
      const data = await client.grower.input_listings.read();
      if (data) {
        commit("SET_GROWER_INPUT_LISTINGS", data.input_listings);
        for (let listing of data.input_listings) {
          dispatch("getGrowerInputUnits", listing.id);
        }
      }
    },
    async getGrowerInputUnits({ commit }, listing) {
      const data = await client.grower.input_listings.readUnits(listing);
      if (data) {
        commit("ADD_GROWER_INPUT_LISTING_UNITS", data.input_units);
        return data.input_units;
      }
    },
    async getGrowerInputUnitById({ commit }, unit) {
      const data = await client.grower.input_units.read(unit);
      if (data) {
        commit("ADD_GROWER_INPUT_LISTING_UNITS", [data]);
        return data;
      }
    },
    async getGrowerInputRequestById({ commit }, request) {
      const data = await client.grower.input_requests.read(request);
      if (data) {
        commit("ADD_REQUESTS", [data]);
        return data;
      }
    },
  },
  getters: {
    getGrowerInputListingById: (state) => (id) => {
      return state.listings.find((listing) => listing.id === parseInt(id));
    },
    getDerivedGrowerInputListings:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedVendors = state.listings;
        let filteredVendors = _.isEmpty(filter)
          ? expandedVendors
          : _.filter(expandedVendors, filter);
        let matchedVendors = _.isEmpty(match)
          ? filteredVendors
          : _.filter(filteredVendors, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedVendors = _.isEmpty(sort)
          ? matchedVendors
          : _.sortBy(matchedVendors, sort);
        let paginatedVendors =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendors,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendors;
        return paginatedVendors;
      },
    getGrowerInputUnitsByListingId: (state) => (id) => {
      // console.log("getGrowersInputUnitByListingId", id, state.units);
      return state.units.filter((unit) => unit.input_listing === parseInt(id));
    },
  },
};

<template>
  <div class="bg-white">
    <main>
      <div class="mx-auto max-w-2xl sm:pt-4">
        <h1
          class="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
        >
          Shopping Cart
        </h1>
        <div v-if="cartItems?.length === 0" class="mt-4 text-center">
          <p class="text-lg text-gray-500">Your cart is empty</p>
          <!--  This was to load test data into the cart
            <button 
            type="button"
            class="mt-4 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
            @click="loadCart"
          >
            Load Cart
          </button> -->
          <div class="mt-6 text-center text-xl text-gray-500">
            <p>
              <router-link
                :to="{ name: 'grower-portal-discover' }"
                class="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Let's Start Shopping!
                <span aria-hidden="true"> &rarr;</span>
              </router-link>
            </p>
          </div>
        </div>
        <div v-else>
          <form class="mt-12" @submit.prevent="checkOut">
            <section aria-labelledby="cart-heading">
              <h2 id="cart-heading" class="sr-only">
                Items in your shopping cart
              </h2>

              <ul
                role="list"
                class="divide-y divide-gray-200 border-b border-t border-gray-200"
              >
                <li
                  v-for="product in cartItems"
                  :key="product.id"
                  class="flex py-6"
                >
                  <div class="flex-shrink-0">
                    <img
                      v-if="product.image"
                      :src="product.image"
                      :alt="product.imageAlt"
                      class="h-24 w-24 rounded-md object-scale-down object-center sm:h-32 sm:w-32"
                    />
                  </div>

                  <div class="ml-4 flex flex-1 flex-col sm:ml-6">
                    <div>
                      <div class="flex justify-between">
                        <h4 class="text-sm">
                          <a
                            :href="product.href"
                            class="font-medium text-gray-700 hover:text-gray-800"
                            >{{ product.name }}</a
                          >
                        </h4>
                        <p
                          v-if="product.myPrice"
                          class="text-sm font-medium text-gray-900"
                        >
                          {{ product.myPrice }}
                        </p>
                        <p
                          class="ml-4 text-sm font-medium text-gray-900"
                          v-else
                        >
                          {{
                            product.priceType == "contracted"
                              ? `Price is your contracted price`
                              : product.productType == "request"
                                ? "You will receive a quote for this product if it is available"
                                : "You will receive a quote for this product"
                          }}
                        </p>
                      </div>
                      <div v-if="product?.order">
                        <div
                          v-for="(answer, question) in product.order
                            .form_response"
                          :key="question"
                        >
                          <p class="mt-1 text-sm text-gray-500">
                            {{ question }} : {{ answer }}
                          </p>
                        </div>
                        <div
                          v-for="(answer, question) in product.order.variants"
                          :key="question"
                        >
                          <p class="mt-1 text-sm text-gray-500">
                            {{ question }} : {{ answer }}
                          </p>
                        </div>
                        <div
                          v-for="(answer, question) in product.order.options"
                          :key="question"
                        >
                          <p class="mt-1 text-sm text-gray-500">
                            {{ question }} : {{ answer }}
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <p class="mt-1 text-sm text-gray-500">
                          Quantity: {{ product.qty }}
                        </p>
                        <p
                          class="mt-1 text-sm text-gray-500"
                          v-if="product.sku"
                        >
                          Size : {{ product.sku.title || product.package }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ product.productDetails?.company_name }}
                        </p>
                      </div>
                    </div>

                    <div class="mt-4 flex flex-1 items-end justify-between">
                      <!--  <p
                        class="flex items-center space-x-2 text-sm text-gray-700"
                      >
                        <CheckIcon
                          v-if="product.inStock"
                          class="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <ClockIcon
                          v-else
                          class="h-5 w-5 flex-shrink-0 text-gray-300"
                          aria-hidden="true"
                        />
                        <span>{{
                          product.inStock
                            ? "In stock"
                            : `Will ship in ${product.leadTime}`
                        }}</span>
                      </p>
                    -->
                      <div class="ml-4">
                        <button
                          type="button"
                          class="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                          @click="removeProduct(product)"
                        >
                          <span>Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>

            <!-- Order summary -->
            <section aria-labelledby="summary-heading" class="mt-10">
              <h2 id="summary-heading" class="sr-only">Order summary</h2>

              <div>
                <dl class="space-y-4" v-if="subtotal">
                  <div class="flex items-center justify-between">
                    <dt class="text-base font-medium text-gray-900">
                      Subtotal
                    </dt>
                    <dd class="ml-4 text-base font-medium text-gray-900">
                      {{ subtotal }}
                    </dd>
                  </div>
                </dl>
                <p class="mt-1 text-sm text-gray-500">
                  Shipping and taxes will be included in the quotes or as
                  defined in your agreement with the vendor.
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  Selecting Checkout will take you to a confirmation screen
                  where you can commit your order / quote request.
                </p>
              </div>

              <div class="mt-10">
                <button
                  type="submit"
                  class="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Checkout
                </button>
              </div>

              <div class="mt-6 text-center text-sm text-gray-500">
                <p>
                  or
                  <router-link
                    :to="{ name: 'grower-portal-discover' }"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Continue Shopping
                    <span aria-hidden="true"> &rarr;</span>
                  </router-link>
                </p>
              </div>
            </section>
          </form>
        </div>
      </div>

      <!-- Policy grid -- >
      <section
        aria-labelledby="policies-heading"
        class="border-t border-gray-200 bg-gray-50"
      >
        <h2 id="policies-heading" class="sr-only">Our policies</h2>

        <div class="mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div
            class="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-0"
          >
            <div
              v-for="policy in policies"
              :key="policy.name"
              class="text-center md:flex md:items-start md:text-left lg:block lg:text-center"
            >
              <div class="md:flex-shrink-0">
                <div class="flow-root">
                  <img
                    class="-my-1 mx-auto h-24 w-auto"
                    :src="getImage(policy.imageUrl)"
                    alt=""
                  />
                </div>
              </div>
              <div class="mt-6 md:ml-4 md:mt-0 lg:ml-0 lg:mt-6">
                <h3 class="text-base font-medium text-gray-900">
                  {{ policy.name }}
                </h3>
                <p class="mt-3 text-sm text-gray-500">
                  {{ policy.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    --></main>
  </div>
</template>

<script>
// import { CheckIcon, ClockIcon } from "@heroicons/vue/solid";
// const products = [
//   // this is a mock of the cart items
//   {
//     id: 1,
//     qty: 1,
//     productId: 45,
//     productType: "service",
//     priceType: "quoted", // quoted, contracted, fixed
//   },
//   {
//     id: 2,
//     qty: 4,
//     productId: 84,
//     productType: "service",
//     priceType: "quoted", // quoted, contracted, fixed
//   },
//   {
//     id: 3,
//     qty: 4,
//     productId: 84,
//     productType: "input",
//     priceType: "quoted", // quoted, contracted, fixed
//   },
// ];
// const policies = [
//   {
//     name: "How it works",
//     imageUrl: "rfq.png",
//     description:
//       "If an item is marked as 'Quoted', when you check out, a request will be sent to the vendor to generate a quote. You will then get the quote in Buy / Quotes. If you like the price, you can order it. You are not committing to buy when you check out.",
//   },
//   {
//     name: "Shipping",
//     imageUrl: "icon-calendar-light.svg",
//     description:
//       "Vendors will review your order, and choose the lowest cost shipping method. This will be included in any quotes you recieve.",
//   },
//   {
//     name: "Rebates",
//     imageUrl: "icon-gift-card-light.svg",
//     description:
//       "Learn more about how you can make money by buying crop protection and nutrition from Parcel.",
//   },
//   {
//     name: "For the planet",
//     imageUrl: "icon-planet-light.svg",
//     description:
//       "Learn more about how you can help the planet by using our eco-friendly products, and sell your crops for a premium",
//   },
// ];
import { calculatePrice, formatCurrency } from "./priceCalcs.js";

export default {
  components: {
    // CheckIcon,
    // ClockIcon,
  },
  data() {
    return {
      // products,
      // policies,
      subtotal: null,
    };
  },
  computed: {
    cartItems() {
      let cartItems = this.$store.state.shoppingCart["cart_items"];
      if (!cartItems) return [];
      cartItems = cartItems?.map((item) => {
        let listing = null;
        if (item.productType === "service") {
          listing = this.$store.getters.getGrowerProductById(item.productId);
          if (listing) {
            let agreements = this.$store.getters.getActiveAgreementsByProductId(
              listing.id,
            );
            if (agreements.length > 0) {
              listing.priceType = "contracted";
            } else {
              listing.priceType = "quoted";
            }
          }
        } else if (item.productType === "request") {
          listing = {};
          listing.priceType = item.priceType;
          listing.title = item.productDetails.product_name;
          listing.image_url = null;
          listing.seller_details = {
            name: item.productDetails.company_name,
            id: -1,
          };
        } else {
          listing = {};
          let inputListing = this.$store.getters.getGrowerInputListingById(
            item.productId,
          );
          listing.priceType = item?.priceType;
          listing.title = inputListing?.title;
          listing.image_url = inputListing?.image_url;
        }

        item.priceType = listing?.priceType;
        item.name = listing?.title;
        item.image = listing?.image_url;
        item.imageAlt = listing?.title + " image";
        let previousPrice = calculatePrice(item.sku, this.$store);
        if (previousPrice) {
          item.myPrice = formatCurrency(previousPrice * item.qty);
        }
        return item;
      });
      return cartItems;
    },
  },
  methods: {
    getImage(imageUrl) {
      if (!imageUrl) return require("@/assets/no_logo_default.jpeg");
      return require(`@/assets/${imageUrl}`);
    },
    loadCart() {
      this.products.map((product) => {
        this.$store.dispatch("addCartItem", product);
      });
    },
    removeProduct(product) {
      this.$store.dispatch("deleteCartItem", product);
    },
    checkOut() {
      this.$router.push({ name: "grower-portal-discover-checkout" });
    },
  },
};
</script>

<template>
  <!-- Tailwind UI Reference - https://tailwindui.com/components/application-ui/headings/section-headings -->
  <div class="mt-2 bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3
        class="group flex items-center text-lg font-medium leading-6 text-gray-900"
      >
        <OutlineHeroIcon
          v-if="iconName"
          :name="iconName"
          classProp="flex-shrink-0 w-6 h-6 mr-4 text-twilight-300"
          aria-hidden="true"
        />
        {{ title }}
      </h3>
      <div v-if="description" class="mt-2 max-w-xl text-sm text-gray-500">
        <p>
          {{ description }}
        </p>
      </div>
      <div
        v-if="this.$slots.cardDetails != null"
        class="border-t border-none border-gray-200 px-4 py-5 sm:px-6"
      >
        <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
          <slot name="cardDetails"></slot>
          <!-- Content goes here -->
        </div>
      </div>
      <div v-if="this.$slots.buttonArea != null" class="mt-4 flex-shrink-0">
        <slot name="buttonArea"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";

export default {
  components: {
    OutlineHeroIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
  },
};
</script>

import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      vendorInvoices: [],
    };
  },
  mutations: {
    SET_VENDOR_SERVICE_INVOICES(state, invoiceData) {
      state.vendorInvoices = invoiceData;
    },
    ADD_VENDOR_SERVICE_INVOICE(state, invoiceObject) {
      state.vendorInvoices.push(invoiceObject);
    },
    UPDATE_VENDOR_SERVICE_INVOICE(state, invoiceObject) {
      state.vendorInvoices = state.vendorInvoices.filter(function (obj) {
        return obj.id !== invoiceObject.id;
      });
      state.vendorInvoices.push(invoiceObject);
    },
    RESET_VENDOR_SERVICE_INVOICES(state) {
      state.vendorInvoices = [];
    },
  },
  actions: {
    async getVendorInvoices({ commit }) {
      const data = await client.vendor.serviceInvoices.read();
      if (data) {
        commit("SET_VENDOR_SERVICE_INVOICES", data.invoices);
      }
    },
    async createVendorInvoice({ commit }, invoice) {
      const data = await client.vendor.serviceInvoices.create(null, invoice);
      if (data) {
        // console.log(data);
        commit("ADD_VENDOR_SERVICE_INVOICE", data);
        this.dispatch("getVendorInvoices");
      }
    },
    async deleteVendorInvoice({ commit }, invoiceId) {
      const data = await client.vendor.serviceInvoices.delete(invoiceId);
      if (data) {
        // console.log(data);
        commit("UPDATE_VENDOR_SERVICE_INVOICE", data);
        this.dispatch("getVendorInvoices");
      }
    },
  },
  getters: {
    getVendorInvoiceById: (state) => (invoiceId) => {
      return state.vendorInvoices.find(
        (invoice) => invoice.id === parseInt(invoiceId),
      );
    },
  },
};

<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Delivery"
    modalDescription="Are you sure you want to permanently delete this delivery?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteDelivery"
  />
  <page-card :headingTitle="'Delivery: ' + $route.params.deliveryId">
    <template #buttonArea>
      <base-button
        buttonText="Save"
        :marginLeft="false"
        @buttonClick="saveDelivery"
      />
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <form
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <number-input
          :wideItem="true"
          inputTitle="Crop Order"
          :displayOnly="true"
          :displayOnlyValue="currentDelivery.crop_order"
        />
        <text-input
          :wideItem="true"
          inputTitle="Delivery Location"
          :required="true"
          v-model="currentDelivery.delivery_location"
        />
        <number-input
          :wideItem="true"
          inputTitle="Quantity"
          :required="true"
          v-model="currentDelivery.quantity_delivered"
        />
        <select-dropdown
          :wideItem="true"
          inputTitle="Units"
          :required="true"
          :selectOptions="unitsOptions"
          v-model="currentDelivery.quantity_units"
        />
        <select-search
          :wide-item="true"
          input-title="Field"
          :select-options="fieldCrops"
          v-model="currentDelivery.crop"
        />
      </form>
    </template>
  </page-card>
  <div class="mt-2"></div>
  <table-toolbar>
    <template #toolbarComponents>
      <div></div>
      <FileSelect
        buttonText="Add Attachment"
        @input="uploadAttachment"
        accept="*"
      />
    </template>
  </table-toolbar>
  <div v-if="attachments.length === 0" class="text-center">
    <h3 class="mt-2 text-sm font-semibold text-gray-900">
      There's Nothing Here
    </h3>
    <p class="mt-1 text-sm text-gray-500">No attachments have been added.</p>
  </div>
  <base-table v-else>
    <template #headerCells>
      <table-header-cell :isLeadingCell="true" headerText="Attachment" />
      <table-header-cell :isEmptyHeader="true" headerText="Delete Attachment" />
      <table-header-cell :isEmptyHeader="true" headerText="Open Attachment" />
    </template>
    <template #bodyRows>
      <tr v-for="attachment in attachments" :key="attachment.id">
        <table-body-cell
          :cellData="attachment.filename"
          :isLeadingCell="true"
        />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="Delete"
          @buttonCellClick="removeAttachment(attachment.id)"
        />
        <table-body-cell
          :isButtonCell="true"
          buttonDisplayText="View"
          @buttonCellClick="openFileTab(attachment.url)"
        />
      </tr>
    </template>
  </base-table>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableToolbar from "@/components/table/TableToolbar.vue";
import FileSelect from "@/components/form/FileSelect.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    DeleteModal,
    TextInput,
    NumberInput,
    BaseTable,
    TableBodyCell,
    TableHeaderCell,
    TableToolbar,
    FileSelect,
    SelectSearch,
    SelectDropdown,
  },
  data() {
    const deliveryId = this.$route.params.deliveryId;

    const currentDelivery =
      this.$store.state.growerDeliveries.growerDeliveries.find(
        (d) => d.id === parseInt(deliveryId),
      );

    return {
      modalIsActive: false,
      deliveryId,
      unitsOptions: [
        { label: "Bushels", value: "bu" },
        { label: "Tons", value: "tons" },
        { label: "Pounds", value: "lbs" },
      ],
      currentDelivery,
    };
  },
  computed: {
    currentOrder() {
      return this.$store.getters.getGrowerCropOrderById(
        this.currentDelivery?.crop_order,
      );
    },
    attachments() {
      const attachments = this.currentDelivery?.attachments || [];
      // console.log("attachments", attachments);
      return attachments;
    },
    fieldCrops() {
      const results = [{ label: "", value: null }];
      for (let field of this.$store.state.fields.fields) {
        results.push({
          label: field.properties.name,
          value: field.properties.current_crop.id,
        });
      }
      return results;
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    async deleteDelivery() {
      this.modalIsActive = false;
      await this.$store.dispatch("deleteGrowerDelivery", {
        id: this.deliveryId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push(this.orderPageNavigation(this.currentOrder));
      }
    },
    async saveDelivery() {
      // console.log("save delivery");
      // console.log(this.currentDelivery.crop_order);
      await this.$store.dispatch("updateGrowerDelivery", this.currentDelivery);
      if (!this.$store.getters.getApiError) {
        this.$router.push(this.orderPageNavigation(this.currentOrder));
      }
    },
    async uploadAttachment(file) {
      const attachment = await this.$store.dispatch(
        "uploadGrowerCropDeliveryAttachment",
        {
          deliveryId: this.deliveryId,
          file,
        },
      );
      this.currentDelivery.attachments.push(attachment);
    },
    async removeAttachment(id) {
      await this.$store.dispatch("removeGrowerCropDeliveryAttachment", id);
      this.currentDelivery.attachments =
        this.currentDelivery.attachments.filter((a) => a.id !== id);
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
    orderPageNavigation(order) {
      if (order.offer) {
        return {
          name: "grower-portal-offer-order",
          params: { orderId: order.id },
        };
      } else {
        return {
          name: "grower-portal-produce-order",
          params: { orderId: order.id },
          query: { tab: 3 },
        };
      }
    },
  },
  mounted() {},
};
</script>

<template>
  <login-form />
</template>
<script>
import LoginForm from "@/layouts/auth/LoginForm.vue";

export default {
  components: { LoginForm },
};
</script>

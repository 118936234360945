<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'properties.farm', label: 'Farm' },
          { name: 'properties.name', label: 'Field', filtered: false },
          { name: 'properties.crop', label: 'Crop' },
          { name: 'properties.details.variety', label: 'Variety' },
          { name: 'acres', label: 'Acres', filtered: false },
          { name: 'sold_acres', label: 'Sold Acres', filtered: false },
          {
            name: 'available_acres',
            label: 'Available Acres',
            filtered: false,
          },
          { name: 'sold_status', label: 'Sold' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="fields"
        @update:derived-collection="derivedFields = $event"
        @update:filter="selectFilter"
        :defaultFilter="defaultFilter"
        :defaultSort="defaultSort"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="field in derivedFields" :key="field">
        <table-data td-label="Farm">
          <div class="flex">
            <field-thumbnail
              :title="field.properties.name"
              :geojson="field"
              class="mr-6"
            ></field-thumbnail>
            {{ field.properties.farm }}
          </div>
        </table-data>
        <table-data td-label="Field">
          {{ field.properties.name }}
        </table-data>
        <table-data td-label="Crop">
          {{ field.properties.crop }}
        </table-data>
        <table-data td-label="Variety">
          {{
            field.properties?.details?.variety
              ? field.properties?.details?.variety
              : ""
          }}
        </table-data>
        <table-data td-label="Acres">
          {{ field.properties.acres }}
        </table-data>
        <table-data td-label="Sold Acres">
          {{ field.sold_acres.toFixed(1) }}
        </table-data>
        <table-data td-label="Available Acres">
          {{ field.unsold_acres.toFixed(1) }}
        </table-data>
        <table-body-cell-pill
          :hiddenOnSmallScreens="true"
          :cellData="field.sold_status"
          :pillColorIndex="soldFilterOptions.indexOf(field.sold_status)"
        />
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'grower-portal-allocations-by-field',
              params: { fieldId: field.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Adjust
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

import TableBodyCellPill from "@/components/table/TableBodyCellPill.vue";
// import _ from "lodash";
export default {
  components: {
    FieldThumbnail,
    TableCard,
    TableHead,
    TableData,
    TableBodyCellPill,
  },
  data() {
    // let filterObject = this.$store.getters.getFieldsFilter;
    return {
      derivedFields: [],
      // defaultFilter: filterObject,
      defaultSort: { "properties.farm": "asc", "properties.name": "asc" },
    };
  },
  computed: {
    soldFilterOptions() {
      let distinct = ["Available", "Sold", "Over Sold"];
      return distinct;
    },
    fields() {
      let fields = this.$store.state.fields.fields;
      fields = fields.map((field) => {
        let sold_acres = this.$store.getters.getSumAllocationAcresByCropId(
          field.properties?.current_crop?.id,
        );
        return {
          ...field,
          sold_acres: sold_acres,
          unsold_acres: field.properties.acres - sold_acres,
          sold_status:
            field.properties.acres - sold_acres < 0
              ? "Over Sold"
              : field.properties.acres - sold_acres > 0
                ? "Available"
                : "Sold",
        };
      });
      return fields;
    },
    defaultFilter() {
      // console.log("computing filter");
      return this.$store.getters.getFieldsFilter;
    },
  },
  methods: {
    selectFilter(e) {
      this.filterObject = e;
      this.$store.dispatch("setFieldsFilter", this.filterObject);
    },
  },
};
</script>

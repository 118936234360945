<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'agreement_object.seller_details.name', label: 'Vendor' },
          { name: 'agreement_object.service_title', label: 'Listing' },
          {
            name: 'prettyStatus',
            label: 'Status',
          },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="orders"
        @update:derived-collection="derivedOrders = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Find Products and Services"
              :usePlusIcon="true"
              @buttonClick="goToDiscoverProductsPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="o in derivedOrders" :key="o.id">
        <table-data td-label="Vendor">
          {{ o.agreement_object?.seller_details?.name }}
        </table-data>
        <table-data td-label="Listing">
          {{ o.agreement_object.service_title }}
        </table-data>
        <table-data td-label="Status">
          {{ o.prettyStatus }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: o.input_agreement
                ? 'grower-portal-single-input-order'
                : 'grower-portal-buy-edit-single-order',
              params: { orderId: o.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
  <!-- <div v-else>
    <div class="text-center">
      <h3 class="mt-2 text-sm font-semibold text-gray-900">No results</h3>
      <p class="mt-1 text-sm text-gray-500">Select a new tab</p>
    </div>
  </div> -->
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import { prettyOrderStatusGrower } from "@/components/composables/formatStringUtils";

export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableToolbarButton,
  },
  props: {
    tabFilters: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      derivedOrders: [],
    };
  },
  computed: {
    orders() {
      const orders = [
        ...this.$store.getters.getEnrichedGrowerOrders({
          filterFunction: this.tabFilters,
        }),
        ...this.$store.state.growerInputOrders.orders.filter(this.tabFilters),
      ];
      for (let order of orders) {
        if (!order.agreement_object) {
          order.agreement_object = {
            service_title: this.getOrderProductNames(order),
            seller_details: this.getInputAgreement(order.input_agreement)
              ?.seller_details,
          };
        }
        if (!order.prettyStatus)
          order.prettyStatus = prettyOrderStatusGrower(order.status);
      }
      return orders;
    },
    inputAgreements() {
      return this.$store.state.growerInputAgreements.agreements;
    },
    inputListings() {
      return this.$store.state.growerInputListings.listings;
    },
    inputUnits() {
      return this.$store.state.growerInputListings.units;
    },
    inputRequests() {
      return this.$store.state.growerInputListings.requests;
    },
  },
  methods: {
    goToDiscoverProductsPage() {
      this.$router.push({
        name: "grower-portal-discover-search",
      });
    },
    getInputAgreement(id) {
      return this.inputAgreements.find((a) => a.id == id);
    },
    getInputListing(id) {
      return this.inputListings.find((a) => a.id == id);
    },
    getInputUnit(id) {
      return this.inputUnits.find((a) => a.id == id);
    },
    getInputRequest(id) {
      return this.inputRequests.find((a) => a.id == id);
    },
    getOrderProductNames(o) {
      let title = "";
      for (let item of o.line_items || []) {
        if (item.input_unit) {
          const unit = this.getInputUnit(item.input_unit);
          if (unit) {
            const listing = this.getInputListing(unit.input_listing);
            if (title.length) title = title.concat(", ");
            title = title.concat(listing?.title);
          }
        }
        if (item.input_request) {
          const request = this.getInputRequest(item.input_request);
          if (title.length) title = title.concat(", ");
          title = title.concat(request?.product_name);
        }
      }
      return title;
    },
    prettyOrderStatusGrower,
  },
};
</script>

<template>
  <div class="sm:col-span-3">
    <label
      class="block text-sm font-medium text-gray-700"
      :for="inputTitle"
      v-if="inputTitle"
    >
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText"
        ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
      /></span>
      <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex flex-grow items-stretch focus-within:z-10">
          <input
            class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
            type="text"
            :placeholder="placeholder"
            v-model="tag"
            @keyup="characterInput"
            :id="inputTitle"
          />
        </div>
        <button
          type="button"
          class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-twilight-500 focus:outline-none focus:ring-1 focus:ring-twilight-500"
          @click="valueInput"
        >
          <!-- <BarsArrowUpIcon class="h-5 w-5 text-gray-400" aria-hidden="true" /> -->
          <span>Add</span>
        </button>
      </div>
    </label>
    <!-- <ul>
      <li class="tag" v-for="(item, key) in tags" :key="key">
        <span class="title">{{ item }}</span>
        <button class="delete-btn" @click="removeTag(key)">X</button>
      </li>
    </ul> -->
    <div class="mt-2 sm:mt-2">
      <div class="-m-1 flex flex-wrap items-center">
        <span
          v-for="(item, key) in tags"
          :key="key"
          class="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
        >
          <span>{{ item }}</span>
          <button
            type="button"
            @click="removeTag(key)"
            class="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
          >
            <span class="sr-only">Remove {{ item }}</span>
            <svg
              class="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                stroke-linecap="round"
                stroke-width="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
function cloneArray(arr) {
  return arr.reduce(function (agg, val) {
    return agg.concat(val);
  }, []);
}

export default {
  emits: ["updateTags"],
  props: {
    taglist: {
      type: Array,
      default: undefined,
    },
    inputTitle: { type: String },
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: "Add item ..." },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  components: {
    ToolTip,
  },
  data() {
    return {
      tags: this.taglist ? cloneArray(this.taglist) : [],
      tag: "",
    };
  },

  methods: {
    characterInput(keyValue) {
      var savedInput = "";
      switch (keyValue.key) {
        case "Enter":
          savedInput = this.tag;
          break;
        // case " ":
        case ",":
        case ";":
          savedInput = this.tag.substring(0, this.tag.length - 1);
          break;
        default: // do nothing
      }
      if (savedInput != "") {
        if (this.tags.indexOf(savedInput) === -1) {
          this.tags.push(savedInput);
          this.tag = "";
          this.$emit("updateTags", cloneArray(this.tags));
          // console.log("tags", this.tags);
        } else {
          this.tag = "";
        }
      }
    },
    valueInput() {
      var savedInput = "";
      savedInput = this.tag;
      if (savedInput != "") {
        if (this.tags.indexOf(savedInput) === -1) {
          this.tags.push(savedInput);
          this.tag = "";
          this.$emit("updateTags", cloneArray(this.tags));
          // console.log("tags", this.tags);
        } else {
          this.tag = "";
        }
      }
      if (savedInput != "") {
        if (this.tags.indexOf(savedInput) === -1) {
          this.tags.push(savedInput);
          this.tag = "";
          this.$emit("updateTags", cloneArray(this.tags));
          // console.log("tags", this.tags);
        } else {
          this.tag = "";
        }
      }
    },
    removeTag(id) {
      this.tags.splice(id, 1);
      this.$emit("updateTags", cloneArray(this.tags));
    },
  },
  watch: {
    taglist(taglist) {
      this.tags = taglist.reduce(function (agg, val) {
        return agg.concat(val);
      }, []);
    },
  },
};
</script>

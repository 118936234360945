export function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  let monday = new Date(d.setDate(diff));
  monday.setHours(0, 0, 0, 0);
  return monday;
}

export function getDayofMonth(date, offset) {
  let d = new Date(date);
  d.setDate(d.getDate() + offset);
  return d.getDate();
}

// getUTCString(new Date(this.date + " " + this.time)
export function getUTCString(date) {
  var utc = new Date(date);
  utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());

  // console.log(new Date(utc), new Date(utc).toISOString());
  return new Date(utc).toISOString();
}

export function postgresDate(date, yearOnly = false, timezone = true) {
  let d = new Date(date);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  let year = d.getFullYear();
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  let timezoneOffset = d.getTimezoneOffset();
  let timezoneString = timezone
    ? `-${Math.floor(timezoneOffset / 60)
        .toString()
        .padStart(2, "0")}:${(timezoneOffset % 60).toString().padStart(2, "0")}`
    : "";
  let dateString = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  let timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return yearOnly ? year : `${dateString} ${timeString}${timezoneString}`;
}

export function getDateString(date) {
  var d = new Date(date);
  return d.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function getDateYearAsNumber(date) {
  var d = new Date(date);
  return d.getFullYear();
}
export function getShortDateString(date) {
  return date ? new Date(date).toISOString().split("T")[0] : undefined;
}

export function getLocalTimeFromUTC(date) {
  var local = new Date(date);
  local.setMinutes(local.getMinutes() + local.getTimezoneOffset());
  return local;
}

export function getUTCFromLocalTime(date) {
  var utc = new Date(date);
  utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());
  return utc;
}

// Convert local date (or date string in "YYYY-MM-DD") to API acceptable UTC string
export function complexGetLocalTimeFromUTC(
  date,
  asString = false,
  trimTime = false,
) {
  // Handle string input in the format "YYYY-MM-DD"
  let local;
  if (typeof date === "string" && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
    local = new Date(`${date}T00:00:00`); // Ensure it's treated as a local date
  } else {
    local = new Date(date);
  }

  // Adjust to the local timezone from UTC
  local.setMinutes(local.getMinutes() + local.getTimezoneOffset());

  // If asString is true, return the date in ISO 8601 format
  if (asString) {
    if (trimTime) {
      // Return "YYYY-MM-DD" if trimTime is true
      return local.toISOString().split("T")[0];
    } else {
      // Return full ISO string with "YYYY-MM-DDTHH:MM:SSZ"
      return local.toISOString().split(".")[0] + "Z";
    }
  }

  return local;
}

// Convert local date (or date string in "YYYY-MM-DD") to UTC for API submission
export function complexGetUTCFromLocalTime(
  date,
  asString = false,
  trimTime = false,
) {
  let utc;
  if (typeof date === "string" && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
    utc = new Date(`${date}T00:00:00`); // Handle date as a local date string
  } else {
    utc = new Date(date);
  }

  // Adjust to UTC from the local timezone
  utc.setMinutes(utc.getMinutes() - utc.getTimezoneOffset());

  // If asString is true, return the UTC date in ISO 8601 format
  if (asString) {
    if (trimTime) {
      // Return "YYYY-MM-DD" if trimTime is true
      return utc.toISOString().split("T")[0];
    } else {
      // Return full ISO string with "YYYY-MM-DDTHH:MM:SSZ"
      return utc.toISOString().split(".")[0] + "Z";
    }
  }

  return utc;
}

// Date formatting functions
export function getDisplayYear(date) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
  });
}

export function getShortDisplayDate(date) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function getLongDisplayDate(date) {
  var d = new Date(date);
  return d.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'title', label: 'Title', filtered: false },
          { name: 'active', label: 'Status' },
          { name: 'updated', label: 'Updated', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="vendorProducts"
        @update:derived-collection="derivedVendorProducts = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewProductPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="p in derivedVendorProducts" :key="p.id">
        <table-data td-label="Title">
          {{ p.title }}
        </table-data>
        <table-data td-label="Active">
          {{ p.active }}
        </table-data>
        <table-data td-label="Updated">
          {{ new Date(p?.updated)?.toLocaleString("en", dateFormat) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'vendor-single-product',
              params: { productId: p.id, productCategory: productCategory },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
      derivedVendorProducts: [],
    };
  },
  methods: {
    goToNewProductPage() {
      this.$router.push({ name: "vendor-new-product" });
    },
  },
  props: {
    productCategory: {
      type: String,
      required: false,
      default: "Hauling",
    },
  },
  computed: {
    vendorProducts() {
      let derivedProducts = this.$store.getters.getDerivedVendorProducts({
        filter: { category: this.productCategory },
      });
      // derivedlistings.forEach((p) => {
      //   // if equipment type is older record style (just a string), don't try to convert from array to string
      //   if (p.equipment_type && typeof p.equipment_type === "object") {
      //     p.equipment_type = p.equipment_type.join(", ");
      //   }
      // });
      //console.log(derivedReservations);
      return derivedProducts;
    },
  },
};
</script>

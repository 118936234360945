<template>
  <span v-if="files && resetEnabled"
    >Selected File:
    <div v-for="(file, index) in files" :key="index">
      <span>{{ file.name }}</span>
    </div>
    <div
      class="inline-flex items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="conditionalButtonStyling"
    >
      <button type="button" :disabled="!files" @click="reset()">Reset</button>
    </div>
  </span>
  <span v-else>
    <div
      class="inline-flex items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="conditionalButtonStyling"
    >
      <button type="button" @click="open()">{{ buttonText }}</button>
    </div></span
  >
</template>

<script setup>
// NOTE: This component is written in composition API style
// because useFileDialog is a composition function, and not adapted to Options API
import { useFileDialog } from "@vueuse/core";

const props = defineProps({
  buttonText: { type: String, default: "Select File" },
  value: File,
  accept: { type: String, default: "" },
  disabled: { type: Boolean, default: false },
  redButton: { type: Boolean, default: false },
  marginLeft: { type: Boolean, default: true },
  resetEnabled: { type: Boolean, default: false },
});
const emit = defineEmits(["files"]);

// console.log(props.foo);
const { files, open, reset, onCancel, onChange } = useFileDialog({
  directory: true,
});
onChange((directory) => {
  console.log("selected", directory);
  let files = [];
  //convert from object to array
  for (let i = 0; i < directory["length"]; i++) {
    files.push(directory[i]);
  }
  emit("files", files);
});

onCancel(() => {
  /** do something on cancel */
});

const conditionalButtonStyling = {
  "bg-twilight-700": !props.redButton && !props.disabled,
  "hover:bg-twilight-800": !props.redButton && !props.disabled,
  "focus:ring-twilight-500": !props.redButton && !props.disabled,
  "bg-red-600": props.redButton && !props.disabled,
  "hover:bg-red-700": props.redButton && !props.disabled,
  "focus:ring-red-500": props.redButton && !props.disabled,
  "ml-1": props.marginLeft,
  "bg-gray-200": props.disabled,
  "hover:bg-gray-200": props.disabled,
};
</script>

<!-- <script>
export default {
  // props: {
  //   buttonText: { type: String, default: "Select File" },
  //   value: File,
  //   accept: { type: String, default: "" },
  //   disabled: { type: Boolean, default: false },
  //   redButton: { type: Boolean, default: false },
  //   marginLeft: { type: Boolean, default: true },
  //   resetEnabled: { type: Boolean, default: false },
  // },
  // emits: ["files"],
  data() {
    return { selectedFiles: null };
  },
  computed: {},
  methods: {
    // handleFileChange(e) {
    //   if (e.target?.files[0]) {
    //     this.selectedFile = e.target.files[0].name;
    //     this.$emit("input", e.target.files[0]);
    //   } else this.selectedFile = null;
    // },
  },
};
</script> -->

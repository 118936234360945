//import { ref, computed } from 'vue'
// corn is 56 lbs per bushel
// soybeans are 60 lbs per bushel
// wheat is 60 lbs per bushel
// barley is 48 lbs per bushel

export function tonsToBushels(crop, tons) {
  let bushels = 0;
  if (!crop) {
    bushels = tons;
    return bushels;
  }
  switch (crop.toLowerCase()) {
    case "corn":
      bushels = (tons * 2000) / 56;
      break;
    case "soybeans":
      bushels = (tons * 2000) / 60;
      break;
    case "wheat":
      bushels = (tons * 2000) / 60;
      break;
    case "barley":
      bushels = (tons * 2000) / 48;
      break;
    default:
      bushels = tons;
      break;
  }
  //console.log("converted tons to bushels: ", tons, bushels);
  return bushels ?? 0; // in case tons is null or undefined
}

export function gramsEmittedByInputPerAcre(unit) {
  let grams = 0;
  if (!unit) {
    return grams;
  }
  switch (unit.toLowerCase()) {
    case "diesel":
      //0.0943 grams per BTU
      // 138690 BTU per gallon
      grams = 0.0943 * gallonsToBTU(1, "diesel"); // 13082.5 grams per gallon
      break;
    case "gasoline":
      grams = 0.0955 * gallonsToBTU(1, "gasoline");
      break;
    case "propane":
      grams = 0.083 * gallonsToBTU(1, "propane");
      break;
    case "natural gas":
      grams = 0.0819 * naturalGasToBTU(1);
      break;
    case "electricity":
      grams = 0.1287 * kwhToBTU(1);
      break;
    //ferilizer emissions are in grams per ton from GREET
    case "ammonia":
      grams = 2141581 / 2000;
      break;
    case "ammonium_nitrate":
      grams = 2017428 / 2000;
      break;
    case "ammonium_sulfate":
      grams = 626296 / 2000;
      break;
    case "diammonium phosphate":
      grams = 1231105 / 2000;
      break;
    case "monoammonium phosphate":
      grams = 1122385 / 2000;
      break;
    case "potash":
      grams = 437478 / 2000;
      break;
    case "urea":
      grams = 938382 / 2000;
      break;
    case "urea_ammonium_nitrate_solution":
      grams = 4135376 / 2000;
      break;
    case "lime":
      grams = 8351 / 2000;
      break;
    case "insecticide":
      grams = 21.28;
      break;
    case "herbicide":
      grams = 29.52;
      break;
    default:
      grams = 0;
      break;
  }
  return grams;
}

export function kwhToBTU(kwh) {
  return kwh * 3412.14;
}
export function btuToKwh(btu) {
  return btu / 3412.14;
}
export function naturalGasToBTU(ccf) {
  return ccf * 100;
}
export function btuToNaturalGas(btu) {
  return btu / 100;
}
export function gallonsToBTU(gallons, fuel) {
  let btu = 0;
  if (!fuel) {
    return btu;
  }
  switch (fuel.toLowerCase()) {
    case "diesel":
      btu = gallons * 138690;
      break;
    case "gasoline":
      btu = gallons * 125000;
      break;
    case "propane":
      btu = gallons * 91500;
      break;
    case "natural gas":
      btu = gallons * 100000;
      break;
    default:
      btu = gallons * 138690;
      break;
  }
  //console.log("converted gallons to btu: ", gallons, btu);
  return btu;
}

export function btuToGallons(btu, fuel) {
  if (!fuel) {
    return btu / 138690;
  }
  let gallons = 0;
  switch (fuel.toLowerCase()) {
    case "diesel":
      gallons = btu / 138690;
      break;
    case "gasoline":
      gallons = btu / 125000;
      break;
    case "propane":
      gallons = btu / 91500;
      break;
    case "natural gas":
      gallons = btu / 100000;
      break;
    default:
      gallons = btu / 138690;
      break;
  }
  //console.log("converted btu to gallons: ", btu, gallons);
  return gallons;
}

export function bushelsToTons(crop, bushels) {
  let tons = 0;
  if (!crop) {
    tons = bushels;
    return tons;
  }
  switch (crop.toLowerCase()) {
    case "corn":
      tons = (bushels * 56) / 2000;
      break;
    case "soybeans":
      tons = (bushels * 60) / 2000;
      break;
    case "wheat":
      tons = (bushels * 60) / 2000;
      break;
    case "barley":
      tons = (bushels * 48) / 2000;
      break;
    default:
      tons = bushels; // assumption is harvest units are in tons
      break;
  }
  //console.log("converted bushels to tons: ", bushels, tons);
  return tons;
}

export function harvestUnits(crop) {
  let units = {};
  if (!crop) {
    return { label: "Pounds", value: "lbs" };
  }
  switch (crop.toLowerCase()) {
    // bushel crops
    case "apples":
      units = { label: "Bushels", value: "bu" };
      break;
    case "barley":
      units = { label: "Bushels", value: "bu" };
      break;
    case "corn":
      units = { label: "Bushels", value: "bu" };
      break;
    case "corn - silage":
      units = { label: "Tons", value: "tons" };
      break;
    case "soybeans":
      units = { label: "Bushels", value: "bu" };
      break;
    case "wheat":
      units = { label: "Bushels", value: "bu" };
      break;
    // ton crops
    case "grape":
      units = { label: "Tons", value: "tons" };
      break;
    case "alfalfa":
      units = { label: "Tons", value: "tons" };
      break;
    case "hay":
      units = { label: "Tons", value: "tons" };
      break;
    default:
      units = { label: "Pounds", value: "lbs" };
      break;
  }
  return units;
}

export function getNPKEquivalents(form) {
  let nitrogen = 0;
  let phosphorus = 0;
  let potassium;

  if (!form) {
    return { nitrogen, phosphorus, potassium };
  }

  switch (form.toLowerCase()) {
    case "ammonia":
      nitrogen = 82;
      phosphorus = 0;
      potassium = 0;
      break;
    case "ammonium nitrate":
      nitrogen = 34;
      phosphorus = 0;
      potassium = 0;
      break;
    case "ammonium sulfate":
      nitrogen = 21;
      phosphorus = 0;
      potassium = 0;
      break;
    case "diammonium phosphate":
      nitrogen = 18;
      phosphorus = 46;
      potassium = 0;
      break;
    case "monoammonium phosphate":
      nitrogen = 11;
      phosphorus = 52;
      potassium = 0;
      break;
    case "potash":
      nitrogen = 0;
      phosphorus = 0;
      potassium = 60;
      break;
    case "urea":
      nitrogen = 46;
      phosphorus = 0;
      potassium = 0;
      break;
    case "urea ammonium nitrate":
      nitrogen = 28;
      phosphorus = 0;
      potassium = 0;
      break;
  }
  return { nitrogen, phosphorus, potassium };
}

export function isMassUnit(unit) {
  let massUnits = ["lb", "lbs", "kg", "ton", "g", "mg", "oz"];
  return massUnits.includes(unit);
}
export function isVolumeUnit(unit) {
  let volumeUnits = [
    "ml",
    "l",
    "gallons",
    "fl oz",
    "pt",
    "qt",
    "cup",
    "tbsp",
    "tsp",
  ];
  return volumeUnits.includes(unit);
}

<template>
  <page-card headingTitle="View Recommendation">
    <template #cardDetails>
      <variable-string-type-input
        :displayOnly="true"
        :displayOnlyValue="activeAgreement?.service_title"
        :wideItem="true"
        inputTitle="Listing"
      />

      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          Customer
          <router-link
            v-if="activeAgreement"
            :to="{
              name: 'vendor-single-quote',
              params: { quoteId: activeAgreement?.id },
            }"
            class="text-twilight-600 hover:text-twilight-900"
            >(View Agreement)</router-link
          >
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="activeAgreement?.buyer_details?.name"
          :wideItem="true"
        />
      </div>
      <text-area
        :wideItem="true"
        inputTitle="Memo"
        :display-only="true"
        :display-only-value="currentRecommendation?.memo"
      />
      <div class="sm:col-span-3">
        <label for="territory" class="block text-sm font-medium text-gray-700">
          More Details Link
          <a
            :href="currentRecommendation?.more_info"
            class="text-twilight-600 hover:text-twilight-900"
            >(View)</a
          >
        </label>
        <variable-string-type-input
          :displayOnly="true"
          :displayOnlyValue="currentRecommendation?.more_info"
          :wideItem="true"
        />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Recommendation Details
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentRecommendation?.shared_assets?.fields?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Fields
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentRecommendation?.shared_assets?.equipment?.length > 0"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Shared Equipment
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="text-sm text-gray-500">
            <service-recommendation-object-interface
              :active-agreement-id="activeAgreement?.id"
              :recommendation="currentRecommendation"
              :read-only="true"
            />
            <shared-fields
              v-if="currentRecommendation?.shared_assets?.fields.length === 1"
              :fields="currentRecommendation?.shared_assets?.fields"
              :locations="currentRecommendation?.shared_assets?.locations"
            />
            <shared-equipment
              v-if="
                currentRecommendation?.shared_assets?.equipment.length === 1
              "
              :equipment="currentRecommendation?.shared_assets?.equipment"
            />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentRecommendation?.shared_assets?.fields?.length > 0"
          >
            <shared-fields
              :fields="currentRecommendation?.shared_assets?.fields"
            />
          </TabPanel>
          <TabPanel
            class="text-sm text-gray-500"
            v-if="currentRecommendation?.shared_assets?.equipment?.length > 0"
          >
            <shared-equipment
              :equipment="currentRecommendation?.shared_assets?.equipment"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import { useToast } from "vue-toastification";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
import SharedEquipment from "@/layouts/common/shared-assets/SharedEquipment.vue";
import SharedFields from "@/layouts/common/shared-assets/SharedFields.vue";
import ServiceRecommendationObjectInterface from "@/layouts/common/recommendations/ServiceRecommendationObjectInterface.vue";
import TextArea from "@/components/form/TextArea.vue";

export default {
  components: {
    PageCard,
    VariableStringTypeInput,
    Tab,
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    SharedEquipment,
    SharedFields,
    ServiceRecommendationObjectInterface,
    TextArea,
  },
  data() {
    const toast = useToast();
    return {
      modalIsActive: false,
      toast,
    };
  },
  computed: {
    currentRecommendation() {
      let currentRec = this.$store.getters.getEnrichedVendorRecommendations({
        filter: { id: parseInt(this.$route.params.recId) },
      });
      // Careful with universal getter - always returns an array
      if (!currentRec || currentRec.length == 0) return null;
      return currentRec[0];
    },
    activeAgreement() {
      if (!this.currentRecommendation) return null;
      return this.$store.getters.getVendorQuoteById(
        parseInt(this.currentRecommendation.agreement),
      );
    },
    fields() {
      if (!this.currentRecommendation.shared_assets) return [];
      return this.currentRecommendation.shared_assets.fields;
    },
    equipment() {
      if (!this.currentRecommendation.shared_assets) return [];
      return this.currentRecommendation.shared_assets.equipment;
    },
    locations() {
      if (!this.currentRecommendation.shared_assets) return [];
      return this.currentRecommendation.shared_assets.locations;
    },
  },
  methods: {
    openFileTab(url) {
      window.open(url, "_blank");
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
  },
  watch: {},
};
</script>

<template>
  <button
    @click="clickButton"
    type="button"
    class="relative ml-1 inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100"
    :class="conditionalButtonStyling"
    :disabled="disabled"
  >
    <PlusIcon
      v-if="usePlusIcon"
      class="h-5 w-5 text-gray-400"
      aria-hidden="true"
    />
    <FilterIcon
      v-if="useFilterIcon"
      class="h-5 w-5 text-gray-400"
      aria-hidden="true"
    />
    <LinkIcon
      v-if="useLinkIcon"
      class="h-5 w-5 text-gray-400"
      aria-hidden="true"
    />
    <SolidHeroIcon
      v-if="heroIcon"
      class="h-5 w-5 text-gray-400"
      aria-hidden="true"
      :name="heroIcon"
    />
    <span>{{ buttonText }}</span>
  </button>
</template>

<script>
import { PlusIcon, FilterIcon, LinkIcon } from "@heroicons/vue/solid";
import SolidHeroIcon from "@/components/icons/SolidHeroIcon.vue";
export default {
  components: { PlusIcon, FilterIcon, LinkIcon, SolidHeroIcon },
  props: {
    buttonText: { type: String },
    usePlusIcon: { type: Boolean, default: false },
    useFilterIcon: { type: Boolean, default: false },
    useLinkIcon: { type: Boolean, default: false },
    heroIcon: { type: String, default: null },
    marginLeft: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  emits: ["buttonClick"],
  computed: {
    conditionalButtonStyling() {
      let styles = {
        "ml-1": this.marginLeft,
        "opacity-50 cursor-not-allowed": this.disabled,
      };
      return styles;
    },
  },
  methods: {
    clickButton() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style></style>

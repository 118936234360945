<template>
  <TabGroup as="div">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            List View
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }" v-if="hasLocation">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Map View
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <equipment />
      </TabPanel>
      <TabPanel class="pt-10">
        <all-equipment-map />
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import Equipment from "@/layouts/grower-portal/assets/equipment/AllEquipment.vue";
import AllEquipmentMap from "@/layouts/grower-portal/assets/equipment/AllEquipmentMap.vue";
export default {
  name: "EquipmentDashboard",
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Equipment,
    AllEquipmentMap,
  },

  data() {
    return {};
  },
  computed: {
    hasLocation() {
      let equipment = this.$store.state.equipment.equipment;
      let hasLocation = false;
      equipment.forEach((asset) => {
        if (asset.location) {
          hasLocation = true;
          return;
        }
      });
      return hasLocation;
    },
  },
  methods: {},
};
</script>

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          {
            name: 'contract_name',
            label: 'Contract',
            hidden: offerId ? true : false,
          },
          { name: 'seller_name', label: 'Seller' },
          { name: 'quantity', label: 'Qty Ordered', filtered: false },
          { name: 'units', label: 'Units', filtered: false },
          {
            name: 'allocated_acres',
            label: 'Allocated Acres',
            filtered: false,
          },
          { name: 'crop_type', label: 'Crop' },
          { name: 'crop_variety', label: 'Variety' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="orders"
        @update:derived-collection="derivedOrders = $event"
      >
        <!-- <template #toolbarButtons>
          <div class="flex">
            <div class="hidden sm:flex">
              <table-toolbar-button
                buttonText="Export"
                heroIcon="DownloadIcon"
                @buttonClick="exportXLSX"
              />
            </div>
          </div>
        </template> -->
      </table-head>
    </template>
    <tbody>
      <tr v-for="order in derivedOrders" :key="order.id">
        <table-data td-label="Contract" v-if="!offerId">
          {{ order?.contract_name }}
        </table-data>
        <table-data td-label="Seller">
          {{ order.seller_details?.name }}
        </table-data>
        <table-data td-label="Qty Ordered">
          {{ order.quantity }}
        </table-data>
        <table-data td-label="Units">
          {{ order.units }}
        </table-data>
        <table-data td-label="Acres">
          {{ getAllocatedAcres(order.id)?.toFixed(1) }}
          <!-- {{ order.allocated_acres.toFixed(1) }}  this is being returned as zero, so computing from allocations-->
        </table-data>
        <table-data td-label="Crop">
          <!-- {{ getCropsbyControllerOrderId(order.id) }} -->
          {{ order.crop_type }}
        </table-data>
        <table-data td-label="Variety">
          <!-- {{ getVarietiesbyControllerOrderId(order.id) }} -->
          {{ order.crop_variety }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-bp-controller-view-order',
              params: { orderId: order.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableHead,
    TableCard,
    TableData,
    // TableToolbarButton,
  },
  props: {
    offerId: {
      type: [Number, String],
      default: null,
    },
    growerId: {
      type: [Number, String],
      default: null,
    },
    contractId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      derivedOrders: [],
    };
  },
  computed: {
    orders() {
      let orders = this.$store.getters.getEnrichedControllerOrders({
        filterFunction: (order) => {
          return (
            order.status === "In Progress" ||
            order.status === "Completed" ||
            order.status === "Canceled"
          );
        },
      });
      if (this.offerId) {
        orders = orders.filter((order) => order.offer == this.offerId);
      }
      if (this.growerId) {
        orders = orders.filter(
          (order) => order.seller_details.id == this.growerId,
        );
      }
      orders = orders.map((order) => {
        let offer = this.$store.getters.getControllerMarketOfferById(
          order.offer,
        );
        if (this.contractId) {
          if (offer?.buyer_contract !== parseInt(this.contractId)) {
            return {};
          }
        }
        order.seller_name = order.seller_details?.name;
        order.contract_name = offer?.contract_name;
        return order;
      });
      // console.log("orders: ", orders);
      // remvoe {} from orders
      orders = orders.filter((order) => Object.keys(order).length > 0);
      return orders;
    },
  },
  methods: {
    getAllocatedAcres(orderId) {
      let allocations =
        this.$store.getters.getControllerAllocationsByOrder(orderId);
      let acres = 0;
      allocations.forEach((allocation) => {
        acres += allocation.acres;
      });
      return acres;
    },
  },
};
</script>

<style></style>

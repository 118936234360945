<template>
  <csv-import :assetAttributes="assetAttributes" />
</template>

<script>
import CsvImport from "@/layouts/grower-portal/assets/equipment/CsvImport.vue";

export default {
  components: {
    CsvImport,
  },
  props: {},
  data() {
    let assetAttributes = {
      alias: { required: true, label: "Name" },
      equipment_class: {
        required: false,
        label: "Category (Tractor, Attachment, Other)",
      },
      equipment_make: { required: false, label: "Make" },
      equipment_model: { required: false, label: "Model" },
      serial_number: { required: false, label: "Serial / VIN" },
      engine_number: { required: false, label: "Engine Number" },
      asset_number: { required: false, label: "Asset Number" },
      billing_category: { required: false, label: "Billing Category" },
      external_id: { required: false, label: "Other ID" },
      hourly_rate: { required: false, label: "Hourly Rate" },
      home_location: { required: false, label: "Home Location" },
      cost: { required: false, label: "Cost" },
      supervisor: { required: false, label: "Supervisor" },
      purchase_date: { required: false, label: "Purchase Date" },
      engine_hours: { required: false, label: "Engine Hours" },
      last_service_date: { required: false, label: "Last Service Date" },
    };

    return {
      csv: null,
      importReport: "",
      showSlideOut: false,
      assetAttributes: assetAttributes,
    };
  },
  computed: {},
  methods: {},
  setup() {},
  async created() {},
  mounted() {},
};
</script>

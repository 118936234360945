<template>
  <hauling-dashboard-action-panel></hauling-dashboard-action-panel>
  <in-app-footer></in-app-footer>
</template>

<script>
import HaulingDashboardActionPanel from "@/layouts/grower-portal/buy/hauling/HaulingDashboardActionPanel.vue";

export default {
  components: {
    HaulingDashboardActionPanel,
  },
};
</script>

<template>
  <page-card>
    <template #cardDetails>
      <span class="col-span-6">
        <!-- Scheduled Hauling - Week of {{ thisWeek }} -->

        <weekly-schedule
          @back="back"
          @forward="forward"
          title="Scheduled Hauling"
        >
          <tr
            class="border"
            v-for="destination in groupedOrders"
            :key="destination"
          >
            <!-- <td class="border">{{ destination.destination }}</td> -->
            <td class="border"></td>
            <td v-for="day in destination.days" :key="day" class="border">
              <div v-for="order in day" :key="order" class="border">
                <div
                  class="ml-1"
                  :class="
                    order.details.status != 'Confirmed' ? 'bg-red-100' : ''
                  "
                >
                  <!-- cell content here-->
                  <router-link
                    :to="
                      vendorPerspective
                        ? {
                            name: 'vendor-single-order',
                            params: { orderId: order.id },
                          }
                        : {
                            name: 'grower-portal-buy-edit-single-order',
                            params: { orderId: order.id },
                          }
                    "
                  >
                    <div class="text-blue-500">
                      {{ order.details.loads_quantity }} loads
                    </div>
                    <div class="">
                      {{ order.details.field?.details?.variety }}
                      {{ order.details.commodity }}
                    </div>
                    <div class="">
                      {{
                        //new Date(order.details.datetime)
                        //   .toLocaleTimeString([], {
                        //     hour: "2-digit",
                        //     minute: "2-digit",
                        //   })
                        order.localdatetime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}
                    </div>
                  </router-link>
                  <!-- end cell -->
                </div>
              </div>
            </td>
            <td><!-- spacer for end column--></td>
          </tr>
        </weekly-schedule>
      </span>
    </template>
  </page-card>
</template>
<script>
import PageCard from "@/components/cards/PageCard.vue";
import WeeklySchedule from "@/components/table/WeeklySchedule.vue";
import _ from "lodash";
import { getMonday } from "@/components/composables/dateUtils.js";
import moment from "moment";
export default {
  components: {
    PageCard,
    WeeklySchedule,
  },
  props: {
    vendorPerspective: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      monday: getMonday(new Date()),
      searchValue: "",
      pageSorts: { datetime: "asc" },
    };
  },
  computed: {
    offset() {
      return new Date().getTimezoneOffset();
    },
    derivedOrders() {
      // Filter down to Hauling only ...
      // !!!!!
      //
      let agreements = [];
      let orders = [];
      if (this.vendorPerspective === true) {
        orders = this.$store.getters.getDerivedVendorOrders({});
        agreements = this.$store.getters.getDerivedVendorQuotes({
          filter: { service_category: "Hauling" },
        });
      } else {
        orders = this.$store.getters.getDerivedGrowerOrders({});
        agreements = this.$store.getters.getDerivedGrowerQuotes({
          filter: { service_category: "Hauling" },
        });
      }
      // console.log("orders", orders, agreements);
      let haulingOrders = orders.filter((order) => {
        return agreements.some((agreement) => {
          return agreement.id === order.agreement;
        });
      });
      // console.log("total orders", orders);
      let monday = this.monday;
      for (let order of haulingOrders) {
        order.localdatetime = moment(new Date(order.details.datetime))
          .add(this.offset, "m")
          .toDate();
      }
      // console.log("offset", this.offset, haulingOrders);
      let derivedOrders = haulingOrders.filter((order) => {
        let orderDate = order.localdatetime;
        let sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        return orderDate > monday && orderDate < sunday;
      });
      // console.log("derivedOrders", derivedOrders);
      return derivedOrders;
    },
    groupedOrders() {
      /*
      [                         M               T   W   T   F   S   S
       "rowlable":"row label",[{res.},{res.}],[ ],[ ],[ ],[ ],[ ],[ ]

      ]
      */
      let orders = this.derivedOrders;

      let grouped = _.mapValues(
        _.groupBy(orders, "destination_text"),
        (res_list) =>
          res_list.map((order) => _.omit(order, "destination_text")),
      );
      let groupArray = [];
      for (const [key, value] of Object.entries(grouped)) {
        let days = new Array(7).fill([]);
        for (let i = 0; i < 7; i++) {
          days[i] = value.filter((order) => {
            // console.log(
            //   "day of week for order",
            //   (new Date(order.details.datetime).getTime() -
            //     this.monday.getTime()) /
            //     (1000 * 60 * 60 * 24)
            // );
            return (
              // subtract monday from order date and divide by 24 hours to get day of week
              Math.floor(
                (order.localdatetime.getTime() - this.monday.getTime()) /
                  (1000 * 60 * 60 * 24),
              ) === i
            );
          });
        }
        groupArray.push({ destination: key, days: days });
      }
      // console.log("destinations", grouped, groupArray);
      return groupArray;
    },
  },
  methods: {
    back(monday) {
      this.monday = new Date(monday); // force retrieval of orders for the new week
    },
    forward(monday) {
      this.monday = new Date(monday); // force retrieval of orders for the new week
    },
  },
};
</script>

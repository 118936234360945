<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'name', label: 'Territory', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="territories"
        @update:derived-collection="derivedTerritories = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="goToNewTerritoryPage"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="territory in derivedTerritories" :key="territory.id">
        <table-data td-label="Name">
          {{ territory.name }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'vendor-revenue-territories-edit',
              params: { id: territory.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Edit
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedTerritories: [],
    };
  },
  computed: {
    territories() {
      return this.$store.state.vendorTerritories.vendorTerritories;
    },
  },
  methods: {
    goToNewTerritoryPage() {
      this.$router.push({ name: "vendor-revenue-territories-new" });
    },
  },
};
</script>

<!-- Format of timeline item:
      // id: 1,
      //       element_status: "",
      //       icon: "glyphicon glyphicon-log-out",
      //       icon_status: "success",
      //       title: "DELIVERY",
      //       controls: [],
      //       created: "",
      //       body: "To plan, created by Insurer",
      //       action_needed: "pulse_wrap",
      -->
<template id="timeline-item-template">
  <li class="timeline-item" :class="item.action_needed">
    <div class="timeline-badge hidden sm:block" :class="item.icon_status">
      <OutlineHeroIcon
        :name="item.icon"
        classProp="w-8 h-8 mt-2 ml-2 text-gray-900"
        aria-hidden="true"
        v-if="item.icon"
      />
      <div v-else class="p-1 text-sm">
        <span v-html="item.badge_label"></span>
      </div>
      <!-- <i class="{{ item.icon }}"></i> -->
    </div>
    <div
      class="timeline-panel"
      :class="item.element_status + ' ' + item.element_day_marker"
    >
      <div class="timeline-heading">
        <h4 class="timeline-title" :class="item.text_status">
          {{ item.title }}
        </h4>
        <div class="timeline-panel-controls">
          <div class="timestamp" v-if="item.created != '1970-01-01'">
            <small class="">{{ item.created }}</small>
          </div>
          <div class="timestamp" v-else>
            <small class="">No deadline&nbsp;</small>
          </div>
          <div class="controls">
            <timeline-control
              v-for="control in item.controls"
              :key="control.id"
              :control="control"
              @timeline-delete="$emit('timeline-delete')"
              @timeline-edit="$emit('timeline-edit')"
            />
          </div>
        </div>
      </div>
      <div class="timeline-body">{{ item.body }}&nbsp;</div>
      <!-- &nbsp; is a hack to prevent collapse of empty div -->
    </div>
  </li>
</template>
<script>
import TimelineControl from "./TimelineControl.vue";
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
// import SolidHeroIcon from "../ui-app/icons/SolidHeroIcon.vue"

export default {
  components: {
    TimelineControl,
    OutlineHeroIcon,
  },
  events: ["timeline-delete", "timeline-edit"],
  props: ["item"],
  methods: {},
};
</script>

import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      customers: [],
    };
  },
  mutations: {
    SET_CUSTOMERS(state, customerData) {
      state.customers = customerData;
    },
    ADD_CUSTOMER(state, customerData) {
      state.customers.push(customerData);
    },
    UPDATE_CUSTOMER(state, customerObject) {
      state.customers = state.customers.filter(function (obj) {
        return obj.id !== customerObject.id;
      });
      state.customers.push(customerObject);
    },
    DELETE_CUSTOMER(state, customerId) {
      state.customers = state.customers.filter(function (obj) {
        return obj.id !== parseInt(customerId);
      });
    },
    RESET_CUSTOMERS(state) {
      state.customers = [];
    },
  },
  actions: {
    async getCustomers({ commit }) {
      const data = await client.customers.read();
      if (data) {
        commit("SET_CUSTOMERS", data.customers);
      }
    },
    async createCustomer({ commit }, customer) {
      const data = await client.customers.create(null, customer);
      if (data) {
        //console.log(data);
        commit("ADD_CUSTOMER", data);
        this.dispatch("getCustomers");
        return data;
      }
    },
    async updateCustomer({ commit }, customer) {
      const data = await client.customers.update(customer.id, customer);
      if (data) {
        commit("UPDATE_CUSTOMER", { id: customer.id, ...customer.details });
        this.dispatch("getCustomers");
      }
    },
    async deleteCustomer({ commit }, customerId) {
      const data = await client.customers.delete(customerId);
      if (data) {
        // console.log(data);
        commit("DELETE_CUSTOMER", customerId);
        this.dispatch("getGrowerLocations");
      }
    },
  },
  getters: {
    getCustomerById: (state) => (customerId) => {
      return state.customers.find(
        (customer) => customer.id === parseInt(customerId),
      );
    },
    getDerivedCustomers:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedCustomers = state.customers;
        let filteredCustomers = _.isEmpty(filter)
          ? expandedCustomers
          : _.filter(expandedCustomers, filter);
        let matchedCustomers = _.isEmpty(match)
          ? filteredCustomers
          : _.filter(filteredCustomers, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedCustomers = _.isEmpty(sort)
          ? matchedCustomers
          : _.sortBy(matchedCustomers, sort);
        let paginatedCustomers =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedCustomers,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedCustomers;
        return paginatedCustomers;
      },
  },
};

<template>
  <page-card headingTitle="Review Hauling Reservations">
    <template #buttonArea>
      <base-button
        buttonText="Submit"
        :marginLeft="false"
        @buttonClick="submitHaulingReservations"
      />
      <base-button
        buttonText="Edit Cart"
        :redButton="true"
        @buttonClick="returnToHaulingCartBuilder"
      />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-6">
        <base-table>
          <template #headerCells>
            <table-header-cell :isLeadingCell="true" headerText="Field" />
            <table-header-cell
              :hiddenOnSmallScreens="true"
              headerText="Pickup"
            />
            <table-header-cell
              :hiddenOnSmallScreens="true"
              headerText="Delivery"
            />
          </template>
          <template #bodyRows>
            <tr v-for="reservation in fieldsToBook" :key="reservation.id">
              <table-body-cell-with-image
                :cellData="reservation.selectedField.selectedFieldName"
              >
                <template #cellImage>
                  <field-thumbnail
                    :title="reservation.selectedField.selectedFieldName"
                    :geojson="reservation.selectedField.originalFieldObject"
                  ></field-thumbnail>
                </template>
              </table-body-cell-with-image>
              <table-body-cell
                :hiddenOnSmallScreens="true"
                :cellData="new Date(reservation.datetime).toLocaleString()"
              />
              <table-body-cell
                :hiddenOnSmallScreens="true"
                :cellData="
                  reservation.deliveryDatetime
                    ? new Date(reservation.deliveryDatetime).toLocaleString()
                    : null
                "
              />
            </tr>
          </template>
        </base-table>
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableBodyCellWithImage from "@/components/table/TableBodyCellWithImage.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    PageCard,
    BaseButton,
    BaseTable,
    TableHeaderCell,
    TableBodyCell,
    TableBodyCellWithImage,
    FieldThumbnail,
  },
  data() {
    return {
      searchValue: "",
      toast: useToast(),
    };
  },
  computed: {
    fieldsToBook() {
      return this.$store.state.growerHauling.haulingCart;
    },
    // totalTons() {
    //   let sumArray = [];
    //   for (let fieldToHaul in this.$store.state.growerHauling.haulingCart) {
    //     sumArray.push(this.$store.state.growerHauling.haulingCart[fieldToHaul].quantity);
    //   }
    //   const tonnageSum = sumArray.reduce(
    //     (partial_sum, a) => partial_sum + a,
    //     0
    //   );
    //   return tonnageSum;
    // },
  },
  methods: {
    async submitHaulingReservations() {
      for (let haulingField of this.$store.state.growerHauling.haulingCart) {
        let fieldReservation = {
          datetime: haulingField.datetime,
          quantity: haulingField.quantity,
          loads_quantity: haulingField.loads_quantity,
          listing: haulingField.selectedListing.selectedListingId,
          equipment_type: haulingField.selectedListing.equipment_type,
          origin_field: haulingField.selectedField.selectedFieldId,
          destination_text: haulingField.destination_text,
          destination_datetime: haulingField.deliveryDatetime,
          commodity: haulingField.commodity,
        };
        await this.$store.dispatch(
          "createCustomerReservation",
          fieldReservation,
        );
        // console.log("FIELD RES: ", fieldReservation);
        if (!this.$store.getters.getApiError) {
          this.toast.success("Reservation Submitted!", {
            timeout: 1500,
            hideProgressBar: true,
          });
        }
      }
      this.$store.commit("CLEAR_HAULING_CART");
      this.$router.push({ name: "grower-portal-hauling-reservations" });
    },
    returnToHaulingCartBuilder() {
      this.$router.push({ name: "grower-portal-hauling-order" });
    },
  },
  mounted() {
    // console.log(this.fieldsToBook);
  },
};
</script>

import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      notifications: [],
    };
  },
  mutations: {
    SET_NOTIFICATIONS(state, notificationData) {
      state.notifications = notificationData;
    },
    ADD_NOTIFICATION(state, notificationObject) {
      state.notifications.push(notificationObject);
    },
    UPDATE_NOTIFICATION(state, notificationObject) {
      state.notifications = state.notifications.filter(function (obj) {
        return obj.id !== notificationObject.id;
      });
      state.notifications.push(notificationObject);
    },
    DELETE_NOTIFICATION(state, notificationObject) {
      state.notifications = state.notifications.filter(function (obj) {
        return obj.id !== notificationObject.id;
      });
    },
    RESET_NOTIFICATIONS(state) {
      state.notifications = [];
    },
  },
  actions: {
    async getNotifications({ commit }) {
      const data = await client.notifications.read();
      if (data) {
        commit("SET_NOTIFICATIONS", data.notifications);
      }
    },
    async createNotification({ commit }, notification) {
      const data = await client.notifications.create(null, notification);
      if (data) {
        // console.log(data);
        commit("ADD_NOTIFICATION", data);
        this.dispatch("getNotifications");
      }
    },
    // Object payload
    async updateNotification({ commit }, { notificationId, notification }) {
      const data = await client.notifications.update(
        notificationId,
        notification,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_NOTIFICATION", data);
        this.dispatch("getNotifications");
      }
    },
    async dismissNotification({ commit, rootState }, notificationId) {
      // console.log(
      //   "dismiss notification",
      //   notificationId,
      //   rootState.notifications,
      // );
      let notification = rootState.notifications.notifications.find(
        (notification) => notification.id === parseInt(notificationId),
      );
      notification.read_status = true;
      // console.log("dismiss notification", notification);
      const data = await client.notifications.update(
        notificationId,
        notification,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_NOTIFICATION", data);
        this.dispatch("getNotifications");
      }
    },
    async deleteNotification({ commit }, notification) {
      const data = await client.notifications.delete(notification.id);
      if (data) {
        // console.log(data);
        commit("DELETE_NOTIFICATION", notification);
        this.dispatch("getNotifications");
      }
    },
  },
  getters: {
    getNotificationById: (state) => (notificationId) => {
      return state.notifications.find(
        (notification) => notification.id === parseInt(notificationId),
      );
    },
    getDerivedNotifications:
      (state, getters) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // add the user and source user objects to the notification so we can search / filter on them.
        let expandedNotifications = state.notifications.map((notification) => ({
          ...notification,
          user: getters.getUserById(notification.user),
        }));

        // This section checks if a filter or set of filters exists. If it does, it loops through and checks if any have nested objects to filter,
        // like "user.name". If it does, it substitutes the "user.name:value" key for an actual object {user{name:value}}
        // NOTE: only currently used in notifications, if it becomes generally needed, we can move to TableHeaderCellWithFilterButton to make it take effect
        // globally.
        if (
          filter &&
          Object.keys(filter).length > 0 &&
          Object.getPrototypeOf(filter) === Object.prototype
        ) {
          //console.log("break up", filter);
          let newFilter = {};
          // loop through each key in the filter to see if we need to parse it.
          for (let key in filter) {
            //console.log("filterClause", `${key}:${filter[key]}`);
            if (key && key.split(".").length > 1) {
              var schema = newFilter; // a moving reference to internal objects within obj
              var pList = key.split(".");
              var len = pList.length;

              for (var i = 0; i < len - 1; i++) {
                var elem = pList[i];
                if (!schema[elem]) schema[elem] = {};
                schema = schema[elem];
              }
              schema[pList[len - 1]] = filter[key];
              //console.log("cleaned",newFilter);
            } else {
              // Not one we need to break up, just copy it to our new object.
              newFilter[key] = filter[key];
            }
          }
          filter = newFilter;
        }
        //console.log("filter", filter);

        let filteredNotifications = _.isEmpty(filter)
          ? expandedNotifications
          : _.filter(expandedNotifications, filter);
        let matchedNotifications = _.isEmpty(match)
          ? filteredNotifications
          : _.filter(filteredNotifications, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedNotifications = _.isEmpty(sort)
          ? matchedNotifications
          : _.orderBy(matchedNotifications, sortColumns, sortOrders);
        let paginatedNotifications =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedNotifications,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedNotifications;
        return paginatedNotifications;
      },
  },
};

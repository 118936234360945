<template>
  <VendorForm :vendor-id="vendorId" />
</template>

<script>
import VendorForm from "@/layouts/common/VendorForm.vue";

export default {
  components: {
    VendorForm,
  },
  data() {
    const { vendorId } = this.$route.params;
    return {
      vendorId,
    };
  },
};
</script>

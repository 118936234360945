import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      equipment: [],
      bounds: [],
      filterObject: {},
    };
  },
  mutations: {
    SET_EQUIPMENT(state, equipmentData) {
      state.equipment = equipmentData;
    },
    SET_EQUIPMENT_BOUNDARY_BOX(state, boundsData) {
      if (boundsData) {
        state.bounds = [
          [boundsData[1], boundsData[0]],
          [boundsData[3], boundsData[2]],
        ];
      } else {
        state.bounds = [];
      }
    },
    ADD_EQUIPMENT(state, equipmentObject) {
      state.equipment.push(equipmentObject);
    },
    UPDATE_EQUIPMENT(state, equipmentObject) {
      state.equipment = state.equipment.filter(function (obj) {
        return obj.id !== equipmentObject.id;
      });
      state.equipment.push(equipmentObject);
    },
    DELETE_EQUIPMENT(state, equipmentObject) {
      state.equipment = state.equipment.filter(function (obj) {
        return obj.id !== equipmentObject.id;
      });
    },
    RESET_EQUIPMENT(state) {
      state.equipment = [];
      state.bounds = [];
      state.filterObject = {};
    },
  },
  actions: {
    async getEquipment({ commit }) {
      const data = await client.equipment.read();
      // console.log(client);
      if (data) {
        commit("SET_EQUIPMENT", data.equipment);
        if (data.bbox == null)
          data.bbox = ["-124.851155", "42.028131", "-65.281244", "23.066317"]; // empty equipment list, let's set it to the United states
        commit("SET_EQUIPMENT_BOUNDARY_BOX", data.bbox);
      }
    },
    async createEquipment({ commit }, equipment) {
      const data = await client.equipment.create(null, equipment);
      if (data) {
        // console.log(data);
        commit("ADD_EQUIPMENT", data);
        this.dispatch("getEquipment");
      }
    },
    async updateEquipment({ commit }, equipment) {
      const data = await client.equipment.update(equipment.id, equipment);
      if (data) {
        // console.log(data);
        commit("UPDATE_EQUIPMENT", data);
        this.dispatch("getEquipment");
      }
    },
    async deleteEquipment({ commit }, equipment) {
      const data = await client.equipment.delete(equipment.id);
      if (data) {
        // console.log(data);
        commit("DELETE_EQUIPMENT", equipment);
        this.dispatch("getEquipment");
      }
    },
    setEquipmentFilter({ commit }, filterObject) {
      commit("SET_FILTER", filterObject);
    },
  },
  getters: {
    getEquipmentById: (state) => (equipmentId) => {
      return state.equipment.find(
        (equipment) => equipment.id === parseInt(equipmentId),
      );
    },
    getEquipmentByName: (state) => (equipmentName) => {
      return state.equipment.find(
        (equipment) => equipment.properties.name === equipmentName,
      );
    },
    getDerivedEquipment:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = [], // ["updated", "id"] - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedEquipment = state.equipment.map((item) => ({
          ...item,
          ...item.location,
        }));
        let filteredEquipment = _.isEmpty(filter)
          ? expandedEquipment
          : _.filter(expandedEquipment, filter);
        let matchedEquipment = _.isEmpty(match)
          ? filteredEquipment
          : _.filter(filteredEquipment, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedEquipment = _.isEmpty(sort)
          ? matchedEquipment
          : _.orderBy(matchedEquipment, sortColumns, sortOrders);
        let paginatedEquipment =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedEquipment,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedEquipment;
        return paginatedEquipment;
      },
    getEquipmentFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};

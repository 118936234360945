import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      buyerDeliveries: [],
    };
  },
  mutations: {
    SET_BUYER_CROP_DELIVERIES(state, deliveryData) {
      state.buyerDeliveries = deliveryData;
    },
    RESET_BUYER_CROP_DELIVERIES(state) {
      state.buyerDeliveries = [];
    },
  },
  actions: {
    async getBuyerDeliveries({ commit }) {
      const data = await client.buyer.cropDeliveries.read();
      if (data) {
        commit("SET_BUYER_CROP_DELIVERIES", data.crop_deliveries);
      }
    },
  },
  getters: {
    getAllBuyerDeliveries: (state) => {
      return state.buyerDeliveries;
    },
    getBuyerDeliveryById: (state) => (deliveryId) => {
      return state.buyerDeliveries.find(
        (delivery) => delivery.id === parseInt(deliveryId),
      );
    },
    getBuyerDeliveriesByOrder: (state) => (orderId) => {
      return state.buyerDeliveries.filter(
        (delivery) => delivery.crop_order === parseInt(orderId),
      );
    },
  },
};

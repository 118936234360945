<template>
  <td
    scope="col"
    class="max-w-sm truncate whitespace-nowrap px-3 py-4 text-sm text-gray-500"
    :td-label="tdLabel"
  >
    <slot></slot>
  </td>
</template>

<script>
export default {
  props: {
    tdLabel: {
      type: String,
      default: "",
    },
  },
};
</script>

export default {
  state() {
    return {
      haulingCart: [],
    };
  },
  mutations: {
    ADD_HAULING_FIELD_TO_CART(state, haulingField) {
      state.haulingCart.push(haulingField);
    },
    DELETE_HAULING_FIELD_FROM_CART(state, haulingFieldId) {
      state.haulingCart = state.haulingCart.filter(function (obj) {
        // Ensure obj.UNIQUE_ID references the hauling field object's unique ID
        return obj.selectedField.selectedFieldId !== haulingFieldId;
      });
    },
    CLEAR_HAULING_CART(state) {
      state.haulingCart = [];
    },
  },
  actions: {},
  getters: {},
};

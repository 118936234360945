import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";
import { useStorage } from "@vueuse/core";
import _ from "lodash";

export default {
  state() {
    return {
      fields: [],
      bounds: [],
      filterObject: useStorage("fields_filter", {}),
    };
  },
  mutations: {
    SET_FIELDS(state, fieldData) {
      state.fields = fieldData;
    },
    SET_BOUNDARY_BOX(state, boundsData) {
      if (boundsData) {
        state.bounds = [
          [boundsData[1], boundsData[0]],
          [boundsData[3], boundsData[2]],
        ];
      } else {
        state.bounds = [];
      }
    },
    ADD_FIELD(state, fieldObject) {
      state.fields.push(fieldObject);
    },
    UPDATE_FIELD(state, fieldObject) {
      state.fields = state.fields.filter(function (obj) {
        return obj.id !== fieldObject.id;
      });
      state.fields.push(fieldObject);
    },
    DELETE_FIELD(state, fieldObject) {
      state.fields = state.fields.filter(function (obj) {
        return obj.id !== fieldObject.id;
      });
    },
    RESET_FIELDS(state) {
      state.fields = [];
      state.bounds = [];
      state.filterObject = {};
    },
    SET_FILTER(state, filterObject) {
      state.filterObject = filterObject;
    },
  },
  actions: {
    async getFields({ commit }) {
      const data = await client.fields.read();
      if (data) {
        commit("SET_FIELDS", data.fields);
        if (data.bbox == null)
          data.bbox = ["-124.851155", "42.028131", "-65.281244", "23.066317"]; // empty fields list, let's set it to the United states
        commit("SET_BOUNDARY_BOX", data.bbox);
      }
    },
    async createField({ commit }, field) {
      const data = await client.fields.create(null, field);
      if (data) {
        // console.log(data);
        commit("ADD_FIELD", data);
        // update fields list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getFields");
        this.dispatch("getCrops");
        this.dispatch("getCropHistory");
        return data;
      }
    },
    // _rm_importFields
    async importFields(_, geojson) {
      const data = await client.fields.import(geojson);
      if (data) {
        // console.log(data);
        this.dispatch("getFields");
      }
    },
    async updateField({ commit }, field) {
      const data = await client.fields.update(field.id, field);
      if (data) {
        // console.log(data);
        commit("UPDATE_FIELD", data);
        // update fields list and crops list to ensure we have the latest elsewhere (like order allocations)
        this.dispatch("getFields");
        this.dispatch("getCrops");
      }
    },
    async deleteField({ commit }, field) {
      const data = await client.fields.delete(field.id);
      if (data) {
        // console.log(data);
        commit("DELETE_FIELD", field);
        this.dispatch("getFields");
      }
    },
    setFieldsFilter({ commit }, filterObject) {
      commit("SET_FILTER", filterObject);
    },
  },
  getters: {
    getFieldById: (state) => (fieldId) => {
      return state.fields.find((field) => field.id === parseInt(fieldId));
    },
    getFieldByCropId: (state) => (cropId) => {
      // NOTE: only works for current crops. Historical crops must look up field ID from the crop object, then find field.
      return state.fields.find(
        (field) => field.properties.current_crop?.id === parseInt(cropId),
      );
    },
    getFieldByName: (state) => (fieldName) => {
      return state.fields.find((field) => field.properties.name === fieldName);
    },
    getAllFields: (state) => {
      return state.fields;
    },
    // UNIVERSAL GETTER
    getFields:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let derivedFields = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.fields,
        );
        return derivedFields;
      },
    getDerivedFields:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedFields = state.fields.map((item) => ({
          ...item,
          ...item.properties,
          ...item.properties.details,
          id: item.id, // just in case there's an id in the properties
        }));
        let filteredFields = _.isEmpty(filter)
          ? expandedFields
          : _.filter(expandedFields, filter);
        let matchedFields = _.isEmpty(match)
          ? filteredFields
          : _.filter(filteredFields, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedFields = _.isEmpty(sort)
          ? matchedFields
          : _.orderBy(matchedFields, sortColumns, sortOrders);
        let paginatedFields =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedFields,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedFields;
        return paginatedFields;
      },
    getFieldsFilter(state) {
      if (state.filterObject == null) {
        return {};
      }
      return state.filterObject;
    },
  },
};

/* 

  If adding a new sponsor, make sure to add the logo to the public/img/icons/sponsors folder
  the log is used in the SideMenu.vue component, the dark logo is used in screens with a white background, such as login.
  and add any new colors for the sponsor to tw-classes.txt so tailwind can use them
  */

export default {
  state: {
    sponsors: [
      {
        id: "GRP",
        growerMenuBgColor: "#295B4A",
        growerMenuTextColor: "#F7F7F7",
        logo_url: "/img/icons/sponsors/grp.png",
        dark_logo_url: "/img/icons/sponsors/grp-dark.png",
        login_logo_url: "/img/icons/sponsors/grp-parcel.png",
        login_logo_class: "h-48 w-auto",
      },
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    getAllSponsors(state) {
      return state.sponsors;
    },
    getSponsorById: (state) => (id) => {
      return state.sponsors.find((sponsor) => sponsor.id === id);
    },
  },
};

<!--
REFERENCE
https://tailwindui.com/components/ecommerce/page-examples/storefront-pages#component-464ebe7691b135031411ad52b3560cc1
-->
<template>
  <div class="rounded-xl bg-white">
    <section aria-labelledby="trending-heading">
      <div class="ml-2 text-base text-gray-900">Featured Products:</div>
      <div
        v-if="trendingProducts.length > 0"
        class="ml-2 mr-2 grid gap-x-6 gap-y-10 xl:gap-x-8"
        :class="'grid-cols-' + gridColumns"
      >
        <div
          v-for="product in trendingProducts"
          :key="product.id"
          class="group flex flex-col justify-start"
        >
          <router-link
            :to="{
              name:
                product.category == 'Input'
                  ? 'grower-portal-discover-single-input'
                  : 'grower-portal-discover-single-product',
              params: { productId: product.id },
            }"
          >
            <div
              class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200"
            >
              <img
                v-if="!product.image_url"
                :alt="product.title"
                src="@/assets/no_logo_default.jpeg"
                class="h-full w-full object-contain object-center group-hover:opacity-75"
              />
              <img
                v-else
                :src="product.image_url"
                :alt="product.title"
                class="h-full w-full object-contain object-center group-hover:opacity-75"
              />
            </div>
            <div class="py-2"></div>

            <!-- <h3 class="mt-4 text-sm text-gray-700">{{ product.category }}</h3> -->
            <p class="mt-1 text-lg font-medium text-gray-900">
              {{ product.title }}
            </p>
            <p class="mt-1 text-left text-sm font-medium text-gray-900">
              {{ product.seller_details?.name || "Learn More" }}
            </p>
          </router-link>
        </div>
      </div>
      <div class="ml-2 mt-2 md:flex md:items-center md:justify-between">
        <!-- <h2
            id="favorites-heading"
            class="text-l ml-1 font-bold tracking-tight text-gray-900"
          >
            &nbsp;
          </h2> -->
        <router-link
          :to="{ name: 'grower-portal-discover-search' }"
          class="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block"
        >
          Explore all products
          <span aria-hidden="true"> &rarr;</span>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
// More products...
//discover/search?search=einride
export default {
  props: {
    gridColumns: {
      type: String,
      default: "1",
    },
  },
  components: {},
  computed: {
    trendingProducts() {
      // let companies = this.$store.state.companies["companies"];
      // let seeds = this.$store.state.companies["seedCompanies"];
      // if (seeds) companies = companies.concat(seeds);
      // console.log("companies", companies);
      let products = this.$store.state.growerProducts["growerProducts"];
      let inputs = this.$store.state.growerInputListings["listings"];
      products = products.concat(inputs);
      const trendingProducts = [];
      // Use this if we want to pre-seed some companies
      // if (companies?.find((product) => product?.slug === "kubota")) {
      //   trendingProducts.push(
      //     companies.find((product) => product?.slug === "kubota")
      //   );
      // }
      let tries = 0;
      while (
        products?.length > 0 &&
        trendingProducts.length < 5 &&
        tries < 100
      ) {
        var product = products[Math.floor(Math.random() * products.length)];
        tries++;
        // if (!trendingProducts.includes(product)) {
        //   let vendor = this.$store.state.vendors.vendors.find(
        //     (vendor) => vendor?.id == product.org
        //   );
        //   if (!vendor) {
        //     // don't show companies that the grower is already working with.
        //     trendingProducts.push(product);
        //   }
        // }
        if (!trendingProducts.find((p) => p.id === product.id)) {
          trendingProducts.push(product);
        }
      }
      return trendingProducts;
    },
  },
  methods: {},
};
</script>

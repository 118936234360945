<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- <TransitionRoot as="template" :show="open"> -->
  <Dialog :open="open" as="div" class="fixed inset-0 z-10 overflow-y-auto">
    <div
      class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
    >
      <!-- <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        > -->
      <DialogOverlay
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />
      <!-- </TransitionChild> -->

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:h-screen sm:align-middle"
        aria-hidden="true"
        >&#8203;</span
      >
      <!-- <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        > -->
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
      >
        <div>
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
          >
            <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <DialogTitle
              as="h3"
              class="text-lg font-medium leading-6 text-gray-900"
            >
              ADD A NEW THREAD
            </DialogTitle>
            <div>
              <label for="acres" class="block text-sm font-medium text-gray-700"
                >Name</label
              >
              <input
                type="text"
                name="subject"
                id="subject"
                v-model="subject"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-lg"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
        >
          <button
            type="button"
            class="inline-flex w-full justify-center rounded-md border border-transparent bg-twilight-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-twilight-700 focus:outline-none focus:ring-2 focus:ring-twilight-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            @click="$emit('subject', subject)"
          >
            Create
          </button>
          <button
            type="button"
            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-twilight-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            @click="$emit('subject')"
            ref="cancelButtonRef"
          >
            Cancel
          </button>
        </div>
      </div>
      <!-- </TransitionChild> -->
    </div>
  </Dialog>
  <!-- </TransitionRoot> -->
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  // TransitionChild,
  // TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    // TransitionChild,
    // TransitionRoot,
    CheckIcon,
  },
  props: ["open"],
  emits: ["subject"],
  data() {
    return {
      subject: null,
    };
  },
  setup() {},
};
</script>

<template>
  <div :class="wideItem == true ? 'sm:col-span-3 ' : ''">
    <label class="block text-sm font-medium text-gray-700">
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText"
        ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
      /></span>
      <div class="relative mt-1 rounded-md shadow-sm">
        <vue-tel-input
          v-model="phone"
          v-bind="bindProps"
          @validate="phoneEntered"
          :class="conditionalInputStyling"
        />
        <div
          v-if="!isValid"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  components: { VueTelInput, ExclamationCircleIcon, ToolTip },
  props: {
    inputTitle: { type: String, default: "" },
    modelValue: { type: String },
    placeholder: { type: String, default: "" }, //- not supported by vue-tel-input
    classProp: { type: String }, //- not supported by vue-tel-input
    displayOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    wideItem: { type: Boolean, default: false },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  emits: ["update:modelValue", "valid"],
  data() {
    return {
      phone: this.modelValue,
      isValid: true,
      bindProps: {
        mode: "international",
        defaultCountry: "US",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: [],
        onlyCountries: ["US", "CA"],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          showDialCodeInSelection: false,
          showFlags: true,
        },
        inputOptions: {
          showDialCode: false,
          tabindex: 0,
          placeholder: this.placeholder,
        },
      },
    };
  },
  computed: {
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  watch: {
    phone() {
      //console.log("phone", this.phone);
      if (this.phone == "") {
        this.$emit("update:modelValue", "");
        this.isValid = true;
      }
    },
    isValid() {
      this.$emit("valid", this.isValid);
    },
  },

  methods: {
    phoneEntered(phone) {
      //console.log("validated", phone);
      this.isValid = phone.valid;
      if (this.phone == "") this.isValid = true;
      if (phone.number)
        this.$emit("update:modelValue", phone.formatted.split(" ").join(""));
    },
  },
};
</script>

<style></style>

<!--
<template>
<div class="mt-1">
  <label
    v-if="inputTitle.length > 0"
    class="block text-sm font-medium text-gray-700"
    >{{ inputTitle }}</label
  >
  <input
    type="tel"
    :placeholder="placeholder"
    @change="checkValidInput"
    :required="required"
    v-model="inputValue"
    class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-twilight-500 focus:border-twilight-500 sm:text-sm"
  />
  <div v-if="displayError" class="ml-4 -mt-4 text-xs italic text-red-500">
    Please enter a valid US phone number.
  </div>
</div>
</template>

<script>
//import * as yup from "yup";

export default {
  props: {
    inputTitle: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    emitErrors: { type: Boolean, default: false },
    required:{type:Boolean,default:false},
    modelValue: { type: String },
  },
  emits: ["update:modelValue", "phoneInputError"],
  data() {
    return {
      inputValue: this.modelValue,
      displayError: false,
    };
  },
  methods: {
    checkValidInput() {
      /* Might need regex if we go international. For now just keep it simple and strip the non-digits and make sure its either 10 or 1 followed by 10 digits.
      const phoneRegExp = /^\+[1-9]\d{10,14}$/;
      let phoneSchema = yup.string().matches(phoneRegExp);
      phoneSchema.isValid(phoneNumber).then((result) => {
        console.log("valid", result);
        if (result) this.displayError = false;
        else {
          if (this.emitErrors) this.$emit("phoneInputError", this.inputValue);
          else this.displayError = true;
        }
      });
    */

      let phoneNumber = this.inputValue.replace(/\D/g, ""); // pull numbers out
      if (
        phoneNumber.length == 0 ||
        phoneNumber.length == 10 ||
        phoneNumber.length == 11 && phoneNumber.substring(0,1) =='1'
      ) {
        this.displayError = false;
      } else {
        if (this.emitErrors) this.$emit("phoneInputError", this.inputValue);
        else this.displayError = true;
      }
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
  },
};
</script>
-->

<template>
  <!-- <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"> -->
  <select-dropdown
    :wideItem="true"
    inputTitle="Premium Type"
    inputHtmlType="text"
    v-model="selectedPremiumType"
    :selectOptions="premiumTypes"
  />
  <text-area
    :wideItem="true"
    inputTitle="Terms"
    inputHtmlType="text"
    v-model="terms"
  />
  <!-- Inputs for Non-Other Type Premiums -->
  <select-dropdown
    v-if="selectedPremiumType != 'other'"
    :wideItem="true"
    inputTitle="Lump Total Sum or Per Unit Premium?"
    inputHtmlType="text"
    v-model="total_or_unit"
    :selectOptions="totalOrUnitTypes"
    help-text="Total means the premium is paid once, Unit means the premium is paid per unit"
  />
  <text-input
    v-if="selectedPremiumType != 'other' && total_or_unit === 'unit'"
    :wideItem="true"
    inputTitle="Units (Bushels, Bags, Tons, etc)"
    inputHtmlType="text"
    v-model="harvest_units"
  />
  <!-- Inputs for Fixed & Threshold Premium -->
  <NumberInput
    v-if="
      selectedPremiumType === 'fixed' || selectedPremiumType === 'threshold'
    "
    :wideItem="true"
    inputTitle="Premium"
    inputHtmlType="text"
    v-model="base_premium"
    help-text="That value that will be paid per unit or as a lump sum if the threshold is met"
  />
  <!-- Inputs for Threshold Premium -->
  <number-input
    v-if="selectedPremiumType === 'threshold'"
    :wideItem="true"
    inputTitle="Threshold Score"
    inputHtmlType="text"
    v-model="score_target"
    help-text="The score that must be met to receive the premium"
  />
  <!-- Inputs for Threshold & Range Premium -->
  <select-dropdown
    v-if="
      selectedPremiumType === 'threshold' || selectedPremiumType === 'range'
    "
    :wideItem="true"
    inputTitle="Score Preferred"
    v-model="score_preference"
    :selectOptions="scorePreferenceTypes"
    help-text="lower means you prefer a lower score, higher means you  prefer a higher score"
  />
  <text-input
    v-if="
      selectedPremiumType === 'threshold' || selectedPremiumType === 'range'
    "
    :wideItem="true"
    inputTitle="Score Units (CI Score, Grams Carbon, etc)"
    inputHtmlType="text"
    v-model="score_units"
    help-text="The units of the score, e.g. CI Score, Grams Carbon, Brix, etc"
  />
  <!-- Inputs for Range Premium -->
  <number-input
    v-if="selectedPremiumType === 'range'"
    :wideItem="true"
    inputTitle="Lower Bound"
    inputHtmlType="text"
    v-model="score_lower_bound"
    help-text="The lower bound of the range"
  />
  <number-input
    v-if="selectedPremiumType === 'range'"
    :wideItem="true"
    inputTitle="Upper Bound"
    inputHtmlType="text"
    v-model="score_upper_bound"
    help-text="The upper bound of the range"
  />
  <currency-input
    v-if="selectedPremiumType === 'range'"
    :wideItem="true"
    inputTitle="Premium per Point"
    inputHtmlType="text"
    v-model="premium_per_point"
    help-text="The premium paid per point past the lower bound"
  />
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
export default {
  props: {
    initialPremium: {
      type: Object,
      default: null,
    },
    orderId: {
      type: [Number, String],
      default: null,
    },
  },
  emits: ["premiumUpdated"],
  components: {
    TextInput,
    CurrencyInput,
    TextArea,
    NumberInput,
    SelectDropdown,
  },
  data() {
    return {
      currentMarketOffer: {},
      // temp until build multiple premium support
      premiumTypes: [
        {
          value: "fixed",
          label: "Fixed",
        },
        {
          value: "threshold",
          label: "Threshold",
        },
        {
          value: "range",
          label: "Range",
        },
        {
          value: "other",
          label: "Other",
        },
      ],
      totalOrUnitTypes: [
        {
          value: "total",
          label: "Total",
        },
        {
          value: "unit",
          label: "Unit",
        },
      ],
      scorePreferenceTypes: [
        {
          value: "higher",
          label: "Higher",
        },
        {
          value: "lower",
          label: "Lower",
        },
      ],
      selectedPremiumType: "fixed", // Enum ("fixed", "threshold", "range", "other"), All Types
      terms: "", // String, All Types
      total_or_unit: "total", // Enum ("total" "unit"), Non-Other Premium Types
      harvest_units: "", // String, Non-Other Premium Types
      base_premium: null, // Number, Fixed or Threshold Premium Types
      score_target: null, // Number, Threshold Premium Type
      score_preference: "lower", // Enum ("higher" or "lower"), Threshold Premium Type
      score_units: "", // String, Threshold or Range Premium Types
      score_lower_bound: null, // Number, Range Premium Type
      score_upper_bound: null, // Number, Range Premium Type
      premium_per_point: null, // Number, Range Premium Type
    };
  },
  computed: {
    premiumForSubmission() {
      // if an initial premium is provided then each object should have the inital premium's id
      if (this.selectedPremiumType === "fixed") {
        return {
          id: this.initialPremium ? this.initialPremium.id : null,
          type: this.selectedPremiumType,
          terms: this.terms,
          total_or_unit: this.total_or_unit,
          harvest_units: this.harvest_units,
          base_premium: this.base_premium,
        };
      } else if (this.selectedPremiumType === "threshold") {
        return {
          id: this.initialPremium ? this.initialPremium.id : null,
          type: this.selectedPremiumType,
          terms: this.terms,
          total_or_unit: this.total_or_unit,
          harvest_units: this.harvest_units,
          base_premium: this.base_premium,
          score_target: this.score_target,
          score_preference: this.score_preference,
          score_units: this.score_units,
        };
      } else if (this.selectedPremiumType === "range") {
        return {
          id: this.initialPremium ? this.initialPremium.id : null,
          type: this.selectedPremiumType,
          terms: this.terms,
          total_or_unit: this.total_or_unit,
          harvest_units: this.harvest_units,
          score_preference: this.score_preference,
          score_units: this.score_units,
          score_lower_bound: this.score_lower_bound,
          score_upper_bound: this.score_upper_bound,
          premium_per_point: this.premium_per_point,
        };
      } else if (this.selectedPremiumType === "other") {
        return {
          id: this.initialPremium ? this.initialPremium.id : null,
          type: this.selectedPremiumType,
          terms: this.terms,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    readInitialPremium() {
      if (this.initialPremium) {
        this.selectedPremiumType = this.initialPremium.type;
        this.terms = this.initialPremium?.terms;
        this.total_or_unit = this.initialPremium?.total_or_unit;
        this.harvest_units = this.initialPremium?.harvest_units;
        this.base_premium = this.initialPremium?.base_premium;
        this.score_target = this.initialPremium?.score_target;
        this.score_preference = this.initialPremium?.score_preference;
        this.score_units = this.initialPremium?.score_units;
        this.score_lower_bound = this.initialPremium?.score_lower_bound;
        this.score_upper_bound = this.initialPremium?.score_upper_bound;
        this.premium_per_point = this.initialPremium?.premium_per_point;
      }
    },
    clearForm() {
      (this.selectedPremiumType = "fixed"), // Enum ("fixed", "threshold", "range", "other"), All Types
        (this.terms = ""), // String, All Types
        (this.total_or_unit = "total"), // Enum ("total" "unit"), Non-Other Premium Types
        (this.harvest_units = ""), // String, Non-Other Premium Types
        (this.base_premium = null), // Number, Fixed or Threshold Premium Types
        (this.score_target = null), // Number, Threshold Premium Type
        (this.score_preference = "lower"), // Enum ("higher" or "lower"), Threshold Premium Type
        (this.score_units = ""), // String, Threshold or Range Premium Types
        (this.score_lower_bound = null), // Number, Range Premium Type
        (this.score_upper_bound = null), // Number, Range Premium Type
        (this.premium_per_point = null); // Number, Range Premium Type
    },
    createPremium() {
      this.$store.dispatch("createBuyerCropOrderPremium", {
        orderId: parseInt(this.orderId),
        premium: this.premiumForSubmission,
      });
    },
    updatePremium() {
      this.$store.dispatch(
        "updateBuyerCropOrderPremium",
        this.premiumForSubmission,
      );
    },
    deletePremium() {
      this.$store.dispatch(
        "deleteBuyerCropOrderPremium",
        this.premiumForSubmission,
      );
    },
  },
  watch: {
    initialPremium: {
      // handler(value) or handler(newValue, oldValue)
      handler(value) {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        if (value !== null && value !== undefined) {
          this.readInitialPremium();
        } else {
          this.clearForm();
        }
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

<template>
  <AlertModal
    :show="showCIBOModal"
    modalTitle="Request Received"
    modalDescription="We have received your request to apply for EQIP. An email with login credentials to access our EQIP application will be sent shortly. Use the link provided in the email to complete the application process."
    @clickDismiss="showCIBOModal = false"
  />
  <div class="rounded bg-white px-4 py-4">
    <div class="flow-root">
      <img
        src="/img/icons/usda.png"
        alt="USDA Logo"
        class="float-left mt-2 h-16 w-16 flex-shrink-0 rounded-sm"
      />
      <a
        target="_blank"
        class="float-right mt-2 rounded bg-twilight-800 px-4 py-2 text-white hover:text-twilight-900"
        @click="submitCIBOApplication"
      >
        Sign up now!
      </a>
    </div>
    <div class="mt-4 text-xl">
      EQIP provides technical and financial assistance to agricultural producers
      to address natural resource concerns, such as:
      <ul class="ml-4 list-disc text-sm">
        <li>Improved water and air quality</li>
        <li>Conserved ground and surface water</li>
        <li>Increased soil health</li>
        <li>Reduced soil erosion and sedimentation</li>
        <li>Improved or created wildlife habitat</li>
        <li>Mitigation against drought and increasing weather volatility</li>
      </ul>
    </div>
    <div class="mt-4 text-xl">How It Works</div>
    <div class="mt-2 text-sm">
      Parcel, in partnership with CIBO Technologies, will gather the relevant
      information to help you apply for any EQIP programs you qualify for, and
      show you what your potential cost-share assistance could be. Once you have
      entered the information, Parcel will prepare the application and submit it
      to the NRCS on your behalf.
      <p>
        NRCS will then work one-on-one with you to develop a conservation plan
        that outlines conservation practices and activities to help solve
        on-farm resource issues. You implement the practices and activities in
        your conservation plan that can lead to cleaner water and air, healthier
        soil and better wildlife habitat, all while improving your agricultural
        operations.
      </p>
      <p>EQIP helps make conservation work for you.</p>
    </div>
    <div class="mt-4 text-xl">Benefits</div>
    <div>Some of these benefits include:</div>
    <ul class="ml-4 list-disc">
      <li>
        Recieve financial assistance for practices implemented through EQIP.
        Some producers may also qualify for advance payment.
      </li>
      <li>
        Efficient use of nutrients, reducing input costs and reduction in
        nonpoint source pollution.
      </li>
      <li>
        Improved soil health, which mitigates against increasing weather
        volatility, improves drought resiliency and can positively affect
        climate change.
      </li>
      <li>
        Implementation of climate-smart practices that improve carbon
        sequestration and reduce greenhouse gas emissions while building
        resilient landscapes.
      </li>
    </ul>
    <div class="mt-4 text-xl">How Much Can I Make?</div>
    <div class="mb-4 text-xs">(most payments are per acre)</div>
    <table class="w-full table-auto">
      <thead>
        <tr>
          <th class="text-left">Conservation Practice</th>
          <th>Minimum Payment</th>
          <th>Maximum Payment</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Conservation Cover</td>
          <td>$174.67</td>
          <td>$734.07</td>
        </tr>
        <tr>
          <td>Conservation Crop Rotation</td>
          <td>$10.31</td>
          <td>$14.59</td>
        </tr>
        <tr>
          <td>Contour Buffer Strips</td>
          <td>$403.40</td>
          <td>$617.33</td>
        </tr>
        <tr>
          <td>Cover Crop</td>
          <td>$61.45</td>
          <td>$92.91</td>
        </tr>
        <tr>
          <td>Field Border</td>
          <td>$97.24</td>
          <td>$558.17</td>
        </tr>
        <tr>
          <td>Filter Strips</td>
          <td>$175.31</td>
          <td>$652.48</td>
        </tr>
        <tr>
          <td>Grassed Waterway</td>
          <td>$2,811.05</td>
          <td>$4,014.63</td>
        </tr>
        <tr>
          <td>Herbaceous Wind Barriers</td>
          <td>$0.08</td>
          <td>$0.10</td>
        </tr>
        <tr>
          <td>Mulching</td>
          <td>$351.31</td>
          <td>$631.52</td>
        </tr>
        <tr>
          <td>Nutrient Management</td>
          <td>$29.25</td>
          <td>$82.33</td>
        </tr>
        <tr>
          <td>Nutrient Management Design and Implementation Activity</td>
          <td>$4,069.07</td>
          <td>$8,300.90</td>
        </tr>
        <tr>
          <td>Residue and Tillage Management No Till</td>
          <td>$16.39</td>
          <td>$19.84</td>
        </tr>
        <tr>
          <td>Residue and Tillage Management Reduced Till</td>
          <td>$17.01</td>
          <td>$20.60</td>
        </tr>
        <tr>
          <td>Soil and Source Testing for Nutrient Management</td>
          <td>$670.01</td>
          <td>$1,116.16</td>
        </tr>
        <tr>
          <td>Soil Carbon Amendment</td>
          <td>$87.79</td>
          <td>$923.01</td>
        </tr>
        <tr>
          <td>Soil Health Management Plan</td>
          <td>$1,416.00</td>
          <td>$2,162.61</td>
        </tr>
        <tr>
          <td>Soil Health Management System Design</td>
          <td>$3,092.49</td>
          <td>$4,492.25</td>
        </tr>
        <tr>
          <td>Soil Health Testing</td>
          <td>$229.52</td>
          <td>$275.43</td>
        </tr>
        <tr>
          <td>Stripcropping</td>
          <td>$1.47</td>
          <td>$1.96</td>
        </tr>
        <tr>
          <td>Vegetative Barriers</td>
          <td>$6.45</td>
          <td>$7.74</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal.vue";
import client from "@/api/parcel/api";

export default {
  components: {
    AlertModal,
  },
  data() {
    return {
      showCIBOModal: false,
    };
  },
  methods: {
    submitCIBOApplication() {
      this.showCIBOModal = true;
      client.grower.submissions.createCIBO();
    },
  },
};
</script>

<template>
  <table>
    <table-head
      :columns="[
        { name: 'properties.farm', label: 'Farm' },
        { name: 'properties.crop', label: 'Crop' },
        { name: 'properties.details.variety', label: 'Variety' },
        { name: 'padding', label: '', filtered: false },
      ]"
      :collection="fields"
      @update:derivedCollection="derivedFields = $event"
      @update:filter="selectFilter"
      :defaultFilter="defaultFilter"
    >
    </table-head>
    <!-- This section to force the table (and above search/filters) to full screen width-->
    <tbody>
      <tr>
        <td class="w-full" colspan="3"></td>
      </tr>
    </tbody>
  </table>
  <div id="chart" class="object-center" v-if="action?.series && showChart">
    <apexchart
      :height="action.chartOptions.chart.height"
      :type="action.chartOptions.chart.type"
      :options="action.chartOptions"
      :series="action.series"
    ></apexchart>
  </div>
</template>
<script>
import TableHead from "@/components/table/TableHead.vue";
import VueApexCharts from "vue3-apexcharts";
export default {
  components: {
    TableHead,
    apexchart: VueApexCharts,
  },
  data() {
    // let filterObject = this.$store.getters.getFieldsFilter;
    return {
      // defaultFilter: filterObject,
      searchValue: "",

      derivedFields: [],
      showChart: true,
    };
  },
  computed: {
    fields() {
      return this.$store.state.fields.fields;
    },
    defaultFilter() {
      // console.log("computing filter");
      return this.$store.getters.getFieldsFilter;
    },
    derivedFieldAcres() {
      let fields = this.derivedFields;
      let derivedFields = fields.map((field) => {
        let sold_acres = this.$store.getters.getSumAllocationAcresByCropId(
          field.properties?.current_crop?.id,
        );
        // console.log("sold_acres", sold_acres, field.properties.acres);
        return {
          ...field,
          sold_acres: Math.round(sold_acres * 100) / 100,
          available_acres:
            Math.round((field.properties.acres - sold_acres) * 100) / 100,
        };
      });

      return derivedFields;
    },
    cropVariety() {
      let crop_variety = {}; // variety:{sold:acres, available:acres}
      this.derivedFieldAcres.forEach((field) => {
        let key = field.properties?.crop;
        if (field?.properties?.current_crop?.details?.variety)
          key =
            field.properties?.current_crop?.details?.variety +
            " " +
            field.properties?.crop;
        if (!crop_variety[key]) {
          crop_variety[key] = {};
          crop_variety[key]["sold"] = Math.round(field.sold_acres);
          crop_variety[key]["available"] = Math.round(field.available_acres);
        } else {
          crop_variety[key]["sold"] += Math.round(field.sold_acres);
          crop_variety[key]["available"] += Math.round(field.available_acres);
        }
      });

      return crop_variety;
    },

    action() {
      let variety_sold = [];
      let variety_available = [];
      let crop_variety = this.cropVariety;
      for (let variety in crop_variety) {
        variety_sold.push(crop_variety[variety].sold);
        variety_available.push(crop_variety[variety].available);
      }
      let action = {};
      if (this.derivedFieldAcres.length > 0) {
        action = {
          series: [
            {
              name: "Sold (acres)",
              data: variety_sold, //[44, 55, 41, 67, 22, 43],
            },
            {
              name: "Available (acres)",
              data: variety_available, // [13, 23, 20, 8, 13, 27],
            },
          ],
          chartOptions: {
            chart: {
              type: "bar",
              height: "400",
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                //borderRadius: 20,
              },
            },
            xaxis: {
              type: "category",
              categories: Object.keys(crop_variety),
              labels: { trim: true, offsetX: 2 },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        };
      }
      // console.log("action", action);
      return action;
    },
  },
  methods: {
    selectFilter(e) {
      // console.log("selectFilter", e);
      this.$store.dispatch("setFieldsFilter", e);
    },
  },
  watch: {
    action() {
      // hack to force chart to redraw. Was showing multiple copies inside chart object
      this.showChart = false;
      this.$nextTick(() => {
        this.showChart = true;
      });
    },
  },
};
</script>

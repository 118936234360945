<template>
  <div
    :class="
      wideItem == true ? 'sm:col-span-3 ' : fullWidth ? 'sm:col-span-6' : ''
    "
  >
    <label class="block text-sm font-medium text-gray-700">
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText"
        ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
      /></span>
      <div class="mt-1">
        <textarea
          :id="inputTitle"
          type="text"
          v-model="inputValue"
          :required="required"
          :disabled="displayOnly"
          :class="conditionalInputStyling"
          :placeholder="placeholder"
          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-twilight-500 focus:ring-twilight-500 sm:text-sm"
          @blur="this.$emit('onBlur', inputValue)"
          :rows="controlHeight"
          :data-testid="dataTestid"
        />
      </div>
      <!--
  <div v-else class="flex mt-1 bg-gray-200 rounded-md shadow-sm">
    <span
      class="flex-1 block w-full h-full min-w-0 col-span-3 px-3 py-2 ml-1 whitespace-pre-wrap border-gray-300 rounded-none resize-none rounded-r-md sm:text-sm"
    >
      {{ displayOnlyValue }}
    </span>
  </div>
  -->
    </label>
  </div>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  props: {
    wideItem: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    inputTitle: { type: String, default: null },
    required: { type: Boolean, default: false },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: String },
    placeholder: { type: String, default: "" },
    modelValue: { type: String },
    classProp: { type: String, default: null },
    expandToContent: { type: Boolean, default: true },
    dataTestid: { type: String, default: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  components: {
    ToolTip,
  },
  emits: ["update:modelValue", "onBlur"],
  data() {
    let inputValue = this.modelValue;
    if (this.displayOnly) inputValue = this.displayOnlyValue;
    return {
      inputValue: inputValue,
    };
  },
  computed: {
    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      // calculate enough height for textarea to fit content
      var lines = " "; //default if no content yet
      if (this.inputValue) lines = this.inputValue.split("\n");
      var count = lines.length;
      if (this.expandToContent) style += " h-[" + (count * 1.25 + 1) + "rem]";
      //console.log("lines", count, style);
      return style;
    },
    controlHeight() {
      let height = 1;
      if (this.inputValue && this.expandToContent) {
        height = this.inputValue.split("\n").length;
      }
      return height;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
  },
};
</script>

<style></style>

import _ from "lodash";
import client from "@/api/parcel/api.js";
import { prettyOrderStatusVendor } from "@/components/composables/formatStringUtils.js";

export default {
  state() {
    return {
      vendorOrders: [],
    };
  },
  mutations: {
    SET_VENDOR_ORDERS(state, vendorOrderData) {
      state.vendorOrders = vendorOrderData;
    },
    UPDATE_VENDOR_ORDER(state, vendorOrderObject) {
      state.vendorOrders = state.vendorOrders.filter(function (obj) {
        return obj.id !== vendorOrderObject.id;
      });
      state.vendorOrders.push(vendorOrderObject);
    },
    RESET_VENDOR_ORDERS(state) {
      state.vendorOrders = [];
    },
  },
  actions: {
    async getVendorOrders({ commit }) {
      const data = await client.vendor.serviceOrders.read();
      if (data) {
        // console.log("vendorOrders", data);
        commit("SET_VENDOR_ORDERS", data.orders);
      }
    },
    async getOneVendorOrder(_, orderId) {
      const data = await client.vendor.serviceOrders.readOne(orderId);
      return data;
    },
    async updateVendorOrder({ commit }, vendorOrder) {
      const data = await client.vendor.serviceOrders.update(
        vendorOrder.id,
        vendorOrder,
      );
      if (data) {
        commit("UPDATE_VENDOR_ORDER", data);
        this.dispatch("getVendorOrders");
      }
    },
  },
  getters: {
    getVendorOrderById: (state) => (vendorOrderId) => {
      return state.vendorOrders.find(
        (vendorOrder) => vendorOrder.id === parseInt(vendorOrderId),
      );
    },
    getDerivedVendorOrders:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedVendorOrders = state.vendorOrders.map((order) => ({
          ...order,
          // seller_name: order.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterVendorOrders = filterFunction
          ? _.filter(expandedVendorOrders, filterFunction)
          : expandedVendorOrders;
        let filteredVendorOrders = _.isEmpty(filter)
          ? functionFilterVendorOrders
          : _.filter(functionFilterVendorOrders, filter);
        let matchedVendorOrders = _.isEmpty(match)
          ? filteredVendorOrders
          : _.filter(filteredVendorOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedVendorOrders = _.isEmpty(sort)
          ? matchedVendorOrders
          : _.sortBy(matchedVendorOrders, sort);
        let paginatedVendorOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendorOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendorOrders;
        return paginatedVendorOrders;
      },
    getEnrichedVendorOrders:
      (state, rootGetters) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let vendorQuotes = rootGetters.getAllVendorQuotes;
        // for each vendor order, add the vendor quote details
        let initalVendorOrders = state.vendorOrders;
        initalVendorOrders.forEach((vendorOrder) => {
          let vendorQuote = vendorQuotes.find(
            (vendorQuote) => vendorQuote.id === parseInt(vendorOrder.agreement),
          );
          vendorOrder.agreement_object = vendorQuote;
        });
        let expandedVendorOrders = initalVendorOrders.map((order) => ({
          ...order,
          prettyStatus: prettyOrderStatusVendor(order.status),
          service_title: order.agreement_object?.service_title,
          service_category: order.agreement_object?.service_category,
          agreement_status: order.agreement_object?.status,
          buyer_name: order.agreement_object?.buyer_details.name,
          seller_name: order.agreement_object?.seller_details.name,
        }));
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterVendorOrders = filterFunction
          ? _.filter(expandedVendorOrders, filterFunction)
          : expandedVendorOrders;
        let filteredVendorOrders = _.isEmpty(filter)
          ? functionFilterVendorOrders
          : _.filter(functionFilterVendorOrders, filter);
        let matchedVendorOrders = _.isEmpty(match)
          ? filteredVendorOrders
          : _.filter(filteredVendorOrders, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedVendorOrders = _.isEmpty(sort)
          ? matchedVendorOrders
          : _.sortBy(matchedVendorOrders, sort);
        let paginatedVendorOrders =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendorOrders,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendorOrders;
        return paginatedVendorOrders;
      },
    // Always prefer getDerivedOrders for component data. These are helpers to expose data for specific pages that only needs some data
    getNeedsActionVendorOrders: (state) => {
      return state.vendorOrders.filter(
        (vendorOrder) => vendorOrder.status === "buyer_submitted",
      );
    },
    getClosedVendorOrders: (state) => {
      return state.vendorOrders.filter(
        (vendorOrder) =>
          vendorOrder.status === "buyer_closed" ||
          vendorOrder.status === "seller_closed" ||
          vendorOrder.status === "seller_canceled" ||
          vendorOrder.status === "buyer_rejected",
      );
    },
    getCompletedVendorOrders: (state) => {
      return state.vendorOrders.filter(
        (vendorOrder) => vendorOrder.status === "seller_completed",
      );
    },
  },
};

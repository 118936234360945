import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      growerLocations: [],
    };
  },
  mutations: {
    SET_GROWER_SERVICE_LOCATIONS(state, growerLocationData) {
      state.growerLocations = growerLocationData;
    },
    SET_BOUNDARY_BOX(state, boundsData) {
      if (boundsData) {
        state.bounds = [
          [boundsData[1], boundsData[0]],
          [boundsData[3], boundsData[2]],
        ];
      } else {
        state.bounds = [];
      }
    },
    ADD_GROWER_SERVICE_LOCATION(state, growerLocationObject) {
      state.growerLocations.push(growerLocationObject);
    },
    UPDATE_GROWER_SERVICE_LOCATION(state, growerLocationObject) {
      state.growerLocations = state.growerLocations.filter(function (obj) {
        return obj.id !== growerLocationObject.id;
      });
      state.growerLocations.push(growerLocationObject);
    },
    DELETE_GROWER_SERVICE_LOCATION(state, growerLocationObject) {
      state.growerLocations = state.growerLocations.filter(function (obj) {
        return obj.id !== growerLocationObject.id;
      });
    },
    RESET_GROWER_SERVICE_LOCATIONS(state) {
      state.growerLocations = [];
      state.bounds = [];
    },
  },
  actions: {
    async getGrowerLocations({ commit }) {
      // let locationsList = [];
      // for (let customer of rootState.customers.customers) {
      const data = await client.grower.serviceLocations.read();
      if (data) {
        // add each set of fields to the collection.
        // console.log("location", data);
        // locationsList = locationsList.concat(data.locations);
        commit("SET_GROWER_SERVICE_LOCATIONS", data.locations);
      }
      // }
      // console.log("customer locations", locationsList);
    },
    async createGrowerLocation({ commit }, growerLocation) {
      const data = await client.grower.serviceLocations.create(
        null,
        growerLocation,
      );
      if (data) {
        // console.log(data);
        commit("ADD_GROWER_SERVICE_LOCATION", data);
        this.dispatch("getGrowerLocations");
      }
    },
    // _rm_importGrowerLocations
    async importGrowerLocations(_, geojson) {
      const data = await client.grower.serviceLocations.import(geojson);
      if (data) {
        // console.log(data);
        this.dispatch("getGrowerLocations");
      }
    },
    async updateGrowerLocation({ commit }, growerLocation) {
      const data = await client.grower.serviceLocations.update(
        growerLocation.id,
        growerLocation,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_GROWER_SERVICE_LOCATION", data);
        this.dispatch("getGrowerLocations");
      }
    },
    async deleteGrowerLocation({ commit }, growerLocation) {
      const data = await client.grower.serviceLocations.delete(
        growerLocation.id,
      );
      if (data) {
        // console.log(data);
        commit("DELETE_GROWER_SERVICE_LOCATION", growerLocation);
        this.dispatch("getGrowerLocations");
      }
    },
  },
  getters: {
    getGrowerLocationById: (state) => (growerLocationId) => {
      return state.growerLocations.find(
        (growerLocation) => growerLocation.id === parseInt(growerLocationId),
      );
    },
    getGrowerLocationsByCropId: (state) => (cropId) => {
      return state.growerLocations.find(
        (growerLocation) =>
          growerLocation.properties?.current_crop?.id === parseInt(cropId),
      );
    },
    getGrowerLocationsByFieldId: (state) => (fieldId) => {
      return state.growerLocations.filter(
        (growerLocation) => growerLocation.field === parseInt(fieldId),
      );
    },
    getGrowerLocationByName: (state) => (growerLocationName) => {
      return state.growerLocations.find(
        (growerLocation) =>
          growerLocation.properties.name === growerLocationName,
      );
    },
    getDerivedGrowerLocations:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = [], // ["updated", "id"] - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedGrowerLocations = state.growerLocations.map((item) => ({
          ...item,
          ...item.properties,
          ...item.properties.details,
        }));
        let filteredGrowerLocations = _.isEmpty(filter)
          ? expandedGrowerLocations
          : _.filter(expandedGrowerLocations, filter);
        let matchedGrowerLocations = _.isEmpty(match)
          ? filteredGrowerLocations
          : _.filter(filteredGrowerLocations, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedGrowerLocations = _.isEmpty(sort)
          ? matchedGrowerLocations
          : _.orderBy(matchedGrowerLocations, sortColumns, sortOrders);
        let paginatedGrowerLocations =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerLocations,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerLocations;
        return paginatedGrowerLocations;
      },
  },
};

<!--Renders a map in a widget. 
action: object which defines the icon, route, etc.

Must include map layers for this widget to work.
example: 

-->

<template>
  <div>
    <span
      :class="[
        action.iconBackground,
        action.iconForeground,
        'inline-flex rounded-lg p-3 ring-4 ring-white',
      ]"
    >
      <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
    </span>
  </div>
  <div class="mt-8">
    <h3 class="text-lg font-medium">
      <router-link :to="{ name: action.routeName }" class="focus:outline-none">
        <!-- Extend touch target to entire panel -->
        <span class="absolute inset-0" aria-hidden="true" />
        {{ action.name }}
      </router-link>
    </h3>
    <p class="mt-2 text-sm text-gray-500">
      {{ action.blurb }}
    </p>
    <div id="chart" class="object-center">
      <fields-map
        :layers="action.layers"
        :showLayerSelector="true"
        :showTurnByTurn="true"
        :showGoToFeature="true"
        height="h-screen-3/4"
      />
    </div>
  </div>
  <span
    class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
    aria-hidden="true"
    >Click here to select
    <svg
      class="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
      />
    </svg>
  </span>
</template>
<script>
import FieldsMap from "@/components/map/MapViewer.vue";
export default {
  props: ["action"],
  components: {
    FieldsMap,
  },
  data() {
    return {};
  },
};
</script>

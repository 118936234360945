<!-- select-dropdown supports two forms of selectOptions:
an array of strings ["option 1", "option 2"]
or an array of label/value pairs [{label:"option 1",value:"key 1"}, {label:"option 2, value:"key 2"}]
-->
<template>
  <div :class="wideItem == false ? ' sm:col-span-2 ' : ' sm:col-span-3 '">
    <label class="block text-sm font-medium text-gray-700">
      {{ inputTitle }}
      <span v-if="required" class="text-red-600">*</span>
      <span v-if="helpText"
        ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
      /></span>
      <div class="mt-1">
        <select
          v-model="inputValue"
          class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-twilight-500 focus:outline-none focus:ring-twilight-500 sm:text-sm"
          :required="required"
          :disabled="displayOnly"
          :class="conditionalInputStyling"
          :placeholder="placeholder"
          @blur="this.$emit('onBlur', inputValue)"
        >
          <option
            v-for="option in options"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <!--
    <div v-else class="flex mt-1 bg-gray-200 rounded-md shadow-sm">
      <span
        class="flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none rounded-r-md sm:text-sm"
      >
        {{ displayOnlyValue }}
      </span>
    </div>
    -->
    </label>
  </div>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
export default {
  props: {
    wideItem: { type: Boolean, default: true },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: String },
    placeholder: { type: String, default: "" },
    classProp: { type: String },
    required: { type: Boolean },
    inputTitle: { type: String },
    selectOptions: { type: Array },
    modelValue: { type: [Number, String, Object] },
    noSort: { type: Boolean, default: true },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
  },
  components: {
    ToolTip,
  },
  emits: ["update:modelValue", "onBlur"],
  data() {
    // This section is just to be backward compatible when displayOnlyValue was how the value was passed in for read only.
    let inputValue = this.modelValue;
    if (this.displayOnly && this.displayOnlyValue) {
      inputValue = this.displayOnlyValue;
    }
    //
    return {
      inputValue,
    };
  },
  computed: {
    options() {
      // turn simple array into a label / value pair if needed. Then sort.
      let options = this.selectOptions;
      //console.log("select", options, typeof options[0]);
      if (options && typeof options[0] == "string") {
        options = options.map((option) => ({ label: option, value: option }));
      }
      if (this.displayOnly)
        options = [
          { label: this.displayOnlyValue, value: this.displayOnlyValue },
        ];
      if (!this.noSort) {
        if (options.length > 0) {
          options = options.sort((a, b) =>
            a.label == b.label ? 0 : a.label > b.label ? 1 : -1,
          );
        }
      }
      return options;
    },

    conditionalInputStyling() {
      let style = "";
      if (this.displayOnly) style = "bg-gray-200 ";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
    modelValue(value) {
      this.inputValue = value;
    },
    displayOnly() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
    displayOnlyValue() {
      if (this.displayOnly) this.inputValue = this.displayOnlyValue;
    },
  },
};
</script>

<template>
  <display-only
    displayTitle="Total Harvested Quantity"
    :displayValue="totalHarvested"
  />
</template>

<script>
import DisplayOnly from "@/components/form/DisplayOnly.vue";

export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    perspective: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ["grower", "buyer", "controller"].includes(value);
      },
    },
  },
  components: {
    DisplayOnly,
  },
  computed: {
    currentOrderAllocations() {
      let allocations = [];
      switch (this.perspective) {
        case "grower":
          allocations = this.$store.getters.getGrowerAllocationsByOrderId(
            this.orderId,
          );
          break;
        case "buyer":
          allocations = this.$store.getters.getBuyerAllocationsByOrder(
            this.orderId,
          );
          break;
        case "controller":
          allocations = this.$store.getters.getControllerAllocationsByOrder(
            this.orderId,
          );
      }
      // console.log("allocations", allocations);
      return allocations;
    },
    currentOrderHarvestRecords() {
      if (!this.currentOrderAllocations) return [];
      let records = [];
      let fieldId = null;
      let fieldRecords = [];
      for (let a of this.currentOrderAllocations) {
        // console.log("a", a);
        switch (this.perspective) {
          case "grower":
            records = records.concat(
              this.$store.getters
                .getRecordsByCrop(a.crop)
                ?.filter((r) => r.category === "Harvest"),
            );
            fieldId = this.$store.getters.getAnyCropById(a.crop)?.field;
            // field = this.$store.getters.getFieldById(fieldId);
            fieldRecords = this.$store.getters.getRecordsByField(fieldId);
            records = records.concat(
              fieldRecords.filter((r) => r.category === "Harvest"),
            );
            break;
          case "buyer":
          case "controller":
            // console.log(
            //   "a.shared_data",
            //   a.shared_data.record_details,
            //   a.shared_data.record_details?.filter(
            //     (r) => r.category === "Harvest",
            //   ),
            // );
            records = records.concat(
              a.shared_data?.record_details?.filter(
                (r) => r.category === "Harvest",
              ),
            );
            break;
        }
      }
      // console.log("records", records);
      return records;
    },
    totalHarvested() {
      if (!this.currentOrderHarvestRecords) return 0;
      let quantity = this.currentOrderHarvestRecords.reduce(
        (acc, record) => acc + record?.payload?.yield,
        0,
      );
      // console.log("quantity", quantity, this.currentOrderHarvestRecords);
      return quantity;
    },
  },
  methods: {},
};
</script>

module.exports = [
  { name: "Alfalfa" },
  { name: "Almond" },
  { name: "Apple" },
  { name: "Apricot" },
  { name: "Aronia Berry" },
  { name: "Artichoke" },
  { name: "Asparagus" },
  { name: "Avocado" },
  { name: "Banana" },
  { name: "Barley" },
  { name: "Bean, Snap or green" },
  { name: "Beet, table" },
  { name: "Blackberry" },
  { name: "Blueberry" },
  { name: "Breadfruit" },
  { name: "Broccoli" },
  { name: "Brussels sprouts" },
  { name: "Cabbage" },
  { name: "Cacao" },
  { name: "Canola / Rapeseed" },
  { name: "Carrot" },
  { name: "Cashew" },
  { name: "Cauliflower" },
  { name: "Celeriac" },
  { name: "Celery" },
  { name: "Cherimoya" },
  { name: "Cherry" },
  { name: "Chestnut" },
  { name: "Chickpeas" },
  { name: "Chive" },
  { name: "Citrus" },
  { name: "Cilantro" },
  { name: "Coconut" },
  { name: "Coffee" },
  { name: "Collards" },
  { name: "Corn" },
  { name: "Corn - Sweet" },
  { name: "Corn - Silage" },
  { name: "Cotton" },
  { name: "Cranberry" },
  { name: "Cucumber" },
  { name: "Currant" },
  { name: "Date" },
  { name: "Beans, Dry, edible" },
  { name: "Edamame" },
  { name: "Eggplant" },
  { name: "Endive" },
  { name: "Fallow / Idle Cropland" },
  { name: "Feijoa" },
  { name: "Fig" },
  { name: "Filbert (hazelnut)" },
  { name: "Garlic" },
  { name: "Gooseberry" },
  { name: "Grape" },
  { name: "Guava" },
  { name: "Hay" },
  { name: "Horseradish" },
  { name: "Kiwi" },
  { name: "Kohlrabi" },
  { name: "Leek" },
  { name: "Lentils" },
  { name: "Lettuce" },
  { name: "Lima" },
  { name: "Litchi" },
  { name: "Macadamia" },
  { name: "Mango" },
  { name: "Melon" },
  { name: "Mushroom" },
  { name: "Mustard and other greens" },
  { name: "Mustard seed" },
  { name: "Nectarine" },
  { name: "Oats" },
  { name: "Okra" },
  { name: "Olive" },
  { name: "Onion" },
  { name: "Opuntia" },
  { name: "Papaya" },
  { name: "Parsley" },
  { name: "Parsnip" },
  { name: "Passion Fruit" },
  { name: "Pea" },
  { name: "Peach" },
  { name: "Pear" },
  { name: "Pecan" },
  { name: "Pepper" },
  { name: "Persimmion" },
  { name: "Pineapple" },
  { name: "Pistachio" },
  { name: "Plum / Prune" },
  { name: "Pomegranate" },
  { name: "Potato" },
  { name: "Pumpkin" },
  { name: "Quince" },
  { name: "Radish" },
  { name: "Rice" },
  { name: "Raspberry" },
  { name: "Rhubarb" },
  { name: "Rutabaga" },
  { name: "Rye" },
  { name: "Salsify" },
  { name: "Sorghum" },
  { name: "Soybeans" },
  { name: "Spinach" },
  { name: "Squash" },
  { name: "Strawberry" },
  { name: "Suriname cherry" },
  { name: "Sweet corn" },
  { name: "Sweet potato" },
  { name: "Swiss chard" },
  { name: "Triticale" },
  { name: "Taro" },
  { name: "Tomato" },
  { name: "Turnip" },
  { name: "Walnut" },
  { name: "Watermelon" },
  { name: "Wheat" },
];

export function calculateFixedPremium(premium, orderQuantity) {
  if (premium.type !== "fixed") {
    return "N/A";
  }

  // Ensure that orderQuantity is a number and is defined
  orderQuantity = orderQuantity || 0;

  if (premium.total_or_unit === "total") {
    return premium.base_premium;
  }

  if (premium.total_or_unit === "unit") {
    return premium.base_premium * orderQuantity;
  }

  return premium.base_premium;
}

export function interpretPremium(premium, validationDetailsOnly = false) {
  // Check if the premium is of type 'fixed', if not, return an empty array
  if (!premium || premium.type !== "fixed") {
    return [];
  }

  // Base premium interpretation as an array
  let premiumDetails = [];
  if (premium.total_or_unit === "total") {
    premiumDetails.push(
      `The total premium amount is $${premium.base_premium}.`,
    );
  } else if (premium.total_or_unit === "unit") {
    premiumDetails.push(
      `The premium is $${premium.base_premium} per ${premium.harvest_units}.`,
    );
  } else {
    premiumDetails.push("Unknown premium type or calculation method.");
  }

  // Interpret validation records as an array
  let validationDetails = [];
  if (premium.validation_records && premium.validation_records.length > 0) {
    premium.validation_records.forEach((record, index) => {
      validationDetails.push(`${index + 1}. ${record.requirements}`);
    });
  } else {
    validationDetails.push(
      "No specific validation requirements for this premium.",
    );
  }

  // Return only the validation details if requested
  if (validationDetailsOnly) {
    return validationDetails;
  }

  // Combine both the premium details and validation details and return as an array
  return [...premiumDetails, ...validationDetails];
}

export function validateAllocationAgainstPremium(premium, allocation) {
  let allo = { ...allocation };
  if (allo.validations_met.includes(premium.id)) {
    allo.premium_requirements_met = true;
    allo.number_of_requirements_met = premium.validation_records.length;
  } else {
    allo.premium_requirements_met = false;
    allo.number_of_requirements_met = 0;
  }
  return allo;
}

export function aggregateValidatedPremiumData(validatedPremiums) {
  return {
    // when calculating the total value, only include the value of premiums that are fixed
    totalValue: validatedPremiums
      .reduce(
        (acc, premium) => acc + (premium.type === "fixed" ? premium.value : 0),
        0,
      )
      .toFixed(2),
    // sum of value where allocations_met === allocations.length
    completedValue: validatedPremiums
      .reduce(
        (acc, premium) =>
          acc +
          (premium.allocations_met === premium.allocations.length
            ? premium.value
            : 0),
        0,
      )
      .toFixed(2),
    // count of premiums in validatedPremiums
    totalPremiums: validatedPremiums.length,
    // count of premiums where allocations_met === allocations.length
    completedPremiums: validatedPremiums.filter(
      (premium) => premium.allocations_met === premium.allocations.length,
    ).length,
    // non-fixed premiums - the number of premiums without a fixed value
    nonFixedPremiums: validatedPremiums.filter(
      (premium) => premium.type !== "fixed",
    ).length,
  };
}

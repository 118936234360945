import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      growerDeliveries: [],
    };
  },
  mutations: {
    SET_GROWER_CROP_DELIVERIES(state, deliveryData) {
      state.growerDeliveries = deliveryData;
    },
    ADD_GROWER_CROP_DELIVERY(state, deliveryObject) {
      state.growerDeliveries.push(deliveryObject);
    },
    UPDATE_GROWER_CROP_DELIVERY(state, deliveryObject) {
      state.growerDeliveries = state.growerDeliveries.filter(function (obj) {
        return obj.id !== deliveryObject.id;
      });
      state.growerDeliveries.push(deliveryObject);
    },
    DELETE_GROWER_CROP_DELIVERY(state, deliveryObject) {
      state.growerDeliveries = state.growerDeliveries.filter(function (obj) {
        return obj.id !== deliveryObject.id;
      });
    },
    RESET_GROWER_CROP_DELIVERIES(state) {
      state.growerDeliveries = [];
    },
  },
  actions: {
    async getGrowerDeliveries({ commit }) {
      const data = await client.grower.cropDeliveries.read();
      if (data) {
        commit("SET_GROWER_CROP_DELIVERIES", data.crop_deliveries);
      }
    },
    async createGrowerDelivery({ commit }, delivery) {
      const data = await client.grower.cropDeliveries.create(null, delivery);
      if (data) {
        // console.log(data);
        commit("ADD_GROWER_CROP_DELIVERY", data);
        this.dispatch("getGrowerDeliveries");
        return data;
      }
    },
    async updateGrowerDelivery({ commit }, delivery) {
      const data = await client.grower.cropDeliveries.update(
        delivery.id,
        delivery,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_GROWER_CROP_DELIVERY", data);
        this.dispatch("getGrowerDeliveries");
      }
    },
    async deleteGrowerDelivery({ commit }, delivery) {
      const data = await client.grower.cropDeliveries.delete(delivery.id);
      if (data) {
        // console.log(data);
        commit("DELETE_GROWER_CROP_DELIVERY", delivery);
        this.dispatch("getGrowerDeliveries");
      }
    },
    async uploadGrowerCropDeliveryAttachment(
      { commit, getters },
      deliveryIdAttachment,
    ) {
      const data = await client.grower.cropDeliveries.createAttachment(
        deliveryIdAttachment.deliveryId,
        deliveryIdAttachment.file,
      );
      if (data) {
        let delivery = getters.getGrowerCropDeliveryById(
          deliveryIdAttachment.deliveryId,
        );
        delivery.attachments.push(data);
        commit("UPDATE_GROWER_CROP_DELIVERY", delivery);

        return data;
      }
    },
    async removeGrowerCropDeliveryAttachment({ commit, state }, attachmentId) {
      let thisDelivery = null;
      for (let delivery of state.growerDeliveries) {
        if (!delivery?.attachments) {
          continue;
        }
        for (let attachment of delivery.attachments) {
          if (attachment.attachment_id === attachmentId) {
            thisDelivery = delivery;
            break;
          }
        }
        if (thisDelivery) {
          break;
        }
      }
      const data =
        await client.grower.cropDeliveries.deleteAttachment(attachmentId);
      // console.log(data, errors);
      if (data) {
        if (thisDelivery) {
          thisDelivery.attachments = thisDelivery.attachments.filter(
            (a) => a.attachment_id !== attachmentId,
          );
          commit("UPDATE_VENDOR_PRODUCT", thisDelivery);
        }
        return data;
      }
    },
  },
  getters: {
    getGrowerDeliveries(state) {
      return state.growerDeliveries;
    },
    getGrowerDeliveriesByOrderId: (state) => (orderId) => {
      return state.growerDeliveries.filter(
        (delivery) => delivery.crop_order === orderId,
      );
    },
    getGrowerCropDeliveryById: (state) => (id) => {
      return state.growerDeliveries.find(
        (delivery) => delivery.id === parseInt(id),
      );
    },
  },
};

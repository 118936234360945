<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'seller_name', label: 'Vendor' },
          { name: 'service_title', label: 'Product' },
          { name: 'status', label: 'Status' },
          { name: 'updated', label: 'Recommended On', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="opportunities"
        :defaultFilter="defaultFilter"
        @update:derived-collection="derivedOpportunities = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="opp in derivedOpportunities" :key="opp.id">
        <table-data td-label="Vendor">
          {{ opp.seller_name }}
        </table-data>
        <table-data td-label="Service">
          {{ opp.service_title }}
        </table-data>
        <table-data td-label="Status">
          {{ opp.status }}
        </table-data>
        <table-data td-label="Date">
          {{ new Date(opp?.updated)?.toLocaleString("en", dateFormat) }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'grower-single-opportunity',
              params: { oppId: opp.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    // TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
      derivedOpportunities: [],
      defaultFilter: {
        status: ["Seller Submitted"],
      },
    };
  },
  computed: {
    opportunities() {
      let opportunities = this.$store.getters.getEnrichedGrowerOpportunities(
        {},
      );
      for (let opportunity of opportunities) {
        if (opportunity.details?.inputs) {
          opportunity.service_title = "";
          for (let input of opportunity.details.inputs) {
            // console.log(input);
            if (opportunity.service_title.length > 0) {
              opportunity.service_title += ", ";
            }
            opportunity.service_title += input.product;
          }
        }
      }
      return opportunities;
    },
  },
  methods: {},
};
</script>

<template>
  <RateCalculator
    :show-modal="showRateModal"
    :skuList="skuList"
    @close-modal="showRateModal = false"
    @submit-rate-calc="updateQtyFromRateCalc"
    :current-qty="quantity"
    :current-sku-id="skuId"
    v-if="skuId"
  />
  <ListingContainer>
    <template #heroImage>
      <img
        v-if="!currentProduct.image_url"
        src="@/assets/no_logo_default.jpeg"
        class="object-contain object-center"
        :alt="`${currentProduct.title} Logo`"
      />
      <img
        v-else
        :src="currentProduct.image_url"
        :alt="`${currentProduct.title} Logo`"
        class="object-contain object-center"
      />
    </template>
    <template #heroTitle>{{ currentProduct.title }} </template>
    <template #heroDescription>
      <p class="mt-3">{{ currentProduct.manufacturer }}</p>
      <p class="mt-3">{{ currentProduct.description }}</p>
      <p class="mt-6">EPA registration: {{ currentProduct.epa_number }}</p>
      <p class="mt-3">{{ currentProduct.type }}</p>
      <p class="mt-3">Mode(s) of action: {{ currentProduct.mode_of_action }}</p>
      <p class="mt-3">
        Active ingredient(s): {{ currentProduct.active_ingredients }}
      </p>
    </template>
    <template #heroActions>
      <select-dropdown
        v-if="skuListOptions.length > 0"
        :selectOptions="skuListOptions"
        inputTitle="Select SKU"
        v-model="skuId"
      />

      <number-input :step="1" inputTitle="Quantity" v-model="quantity" />
      <div class="col-span-2 flex">
        <button
          type="button"
          class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          @click="addToCart()"
          :disabled="!skuId"
        >
          Add to Cart
        </button>
      </div>
      <!-- summary of price and actual quantity -->
      <div class="mt-3 text-xs" v-if="currentSkuQuantity">
        Order Quantity: {{ currentSkuQuantity }}
      </div>
      <div class="mt-3 text-xs" v-if="currentUserPrice">
        Your Price: {{ currentUserPrice }} each
      </div>
      <div
        class="mt-3 text-xs text-blue-500 hover:text-blue-700"
        @click="showRateModal = true"
      >
        Rate Calculator
      </div>
    </template>
    <template #sections>
      <ListingSections
        :tabSlots="tabs"
        :activeTab="selectedTab"
        @changeTab="changeTab"
      >
        <template #details>
          <div v-html="currentProduct.details" class="prose"></div>
        </template>
        <template #attachments>
          <base-table v-if="currentProduct.category === 'Input'">
            <template #headerCells>
              <table-header-cell
                :isLeadingCell="true"
                headerText="Attachment"
              />
              <table-header-cell
                :isEmptyHeader="true"
                headerText="View Attachments"
              />
            </template>
            <template #bodyRows>
              <tr
                v-for="attachment in currentProduct.attachments"
                :key="attachment.url"
              >
                <table-body-cell
                  :cellData="attachment.filename"
                  :isLeadingCell="true"
                />
                <table-body-cell
                  :isButtonCell="true"
                  buttonDisplayText="View"
                  @buttonCellClick="openFileTab(attachment.url)"
                />
              </tr>
            </template>
          </base-table>
        </template>
        <template #crops>
          {{ currentProduct.crops }}
        </template>
        <template #pests>
          {{ currentProduct.pests }}
        </template>
        <template #States>
          {{ currentProduct.states }}
        </template>
      </ListingSections>
    </template>
  </ListingContainer>
</template>

<script>
// import client from "@/api/parcel/api.js";
import ListingContainer from "@/layouts/common/listing/ListingContainer.vue";
import ListingSections from "@/layouts/common/listing/ListingSections.vue";
import RateCalculator from "@/layouts/grower-portal/discover/search/RateCalculator.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import NumberInput from "@/components/form/NumberInput.vue";
// import ListingHero from "@/layouts/common/listing/ListingHero.vue";
import { useToast } from "vue-toastification";
import BaseTable from "@/components/table/BaseTable.vue";
import TableBodyCell from "@/components/table/TableBodyCell.vue";
import TableHeaderCell from "@/components/table/TableHeaderCell.vue";
import { parseSku } from "@/components/composables/scriptUtils";
import {
  calculatePrice,
  formatCurrency,
} from "@/pages/grower-portal/discover/cart/priceCalcs";

export default {
  components: {
    ListingContainer,
    ListingSections,
    RateCalculator,
    SelectDropdown,
    NumberInput,
    BaseTable,
    TableBodyCell,
    TableHeaderCell,
  },
  data() {
    return {
      selectedTab: 0,
      showRateModal: false,
      skuList: [],
      toast: useToast(),
      skuId: null,
      quantity: 1,
      // Turn the tabs below into an array of objects with a displayName and a slotName
      tabs: [
        { displayName: "Details", slotName: "details" },
        { displayName: "Attachments", slotName: "attachments" },
        { displayName: "Crops", slotName: "crops" },
        { displayName: "Pests", slotName: "pests" },
        { displayName: "States", slotName: "States" },
      ],
    };
  },
  computed: {
    currentProduct() {
      let product = this.$store.getters.getGrowerInputListingById(
        this.$route.params.productId,
      );
      if (!product) {
        product = {};
      }
      return product;
    },
    skuListOptions() {
      if (!this.skuList) return [];
      return this.skuList.map((sku) => {
        return {
          label: sku.title,
          value: sku.id,
        };
      });
    },

    currentUserPrice() {
      let currentPrice = null;
      if (this.skuId) {
        let skus = this.skuList;
        // console.log("currentUserPrice skus: ", skus);
        if (skus) {
          let sku = this.skuList.find((sku) => sku.id === this.skuId);
          currentPrice = calculatePrice(sku, this.$store);
          if (currentPrice) {
            currentPrice = formatCurrency(currentPrice);
          }
        }
      }
      // console.log("computing currentUserPrice: ", this.skuId, currentPrice);
      return currentPrice;
    },
    currentSkuQuantity() {
      let currentQuantity = null;

      if (this.skuId) {
        //let sku = { title: "0.125 GA Package (8 / Case)" }; //this.$store.getters.getGrowerInputUnitById(this.skuId);
        let sku = this.skuList.find((sku) => sku.id === this.skuId);
        let skuSize = parseSku(sku.title, this.quantity);
        if (!skuSize) return null;
        currentQuantity = skuSize.displayText;
      }
      // console.log(
      //   "computing currentSkuQuantity: ",
      //   this.skuId,
      //   this.quantity,
      //   currentQuantity,
      // );
      return currentQuantity;
    },
  },
  methods: {
    async addToCart() {
      if (this.currentProduct == {}) return;
      let sku = this.skuList.find((sku) => sku.id === this.skuId);
      let cartItem = {
        productId: this.currentProduct.id,
        sku: sku,
        qty: this.quantity,
        order: this.currentOrder,
        productType: "input",
        priceType: "quoted",
      };
      // console.log("adding cartItem: ", cartItem);
      await this.$store.dispatch("addCartItem", cartItem);
      if (!this.$store.getters.getApiError) {
        this.toast.success("Added to Cart!", {
          timeout: 1500,
          hideProgressBar: true,
        });
      }

      this.$router.push({ name: "grower-portal-discover" });
    },
    changeTab(index) {
      this.selectedTab = index;
    },
    openFileTab(url) {
      window.open(url, "_blank");
    },
    updateQtyFromRateCalc(event) {
      this.quantity = event.quantity;
      this.skuId = event.skuId;
      this.showRateModal = false;
    },
  },
  mounted() {
    // programatically set document title so we get unique titles for each product
    // console.log("currentProduct: ", this.currentProduct);
    document.title = `${this.currentProduct.title} - Parcel`;
  },
  watch: {
    currentProduct: {
      async handler(value) {
        if (value === undefined) return;
        this.skuList = this.$store.getters.getGrowerInputUnitsByListingId(
          value.id,
        );
        if (this.skuList.length > 0) this.skuId = this.skuList[0].id;
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
  },
};
</script>

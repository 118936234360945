<template>
  <div
    class="form-check mt-1"
    :class="wideItem == true ? 'sm:col-span-3 ' : ''"
  >
    <input
      class="form-check-input float-left mb-1 ml-2 mr-2 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
      type="checkbox"
      v-model="allSelected"
      @change="selectAll()"
    />

    {{ inputTitle }}
    <span v-if="helpText"
      ><ToolTip :helpText="helpText" :helpTitle="helpTitle"
    /></span>
    <div
      class="overflow-auto rounded-md border border-gray-200 pb-2 pl-2"
      :class="rowHeight"
    >
      <div v-for="item in list" :key="item" class="flex">
        <label class="form-check-label inline-block text-sm text-gray-800">
          <input
            class="form-check-input float-left mr-2 mt-1 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none"
            type="checkbox"
            v-model="item.selected"
            :disabled="displayOnly"
            :placeholder="placeholder"
            :class="conditionalInputStyling"
            @blur="this.$emit('onBlur', inputValue)"
          />
          {{ item.label }}
        </label>
      </div>
    </div>
    <!-- </label> -->
  </div>
</template>

<script>
import ToolTip from "@/components/modals/ToolTip.vue";
import _ from "lodash";
export default {
  props: {
    inputTitle: { type: String, default: "" },
    displayOnly: { type: Boolean, default: false },
    displayOnlyValue: { type: [String, Boolean], default: "" },
    placeholder: { type: String, default: "" },
    listArray: { type: Array, default: new Array() }, // [{label: 'name1', selected: true}, {label: 'name2', selected: false}]
    wideItem: { type: Boolean, default: false },
    classProp: { type: String, defautl: null },
    helpText: { type: String, default: null },
    helpTitle: { type: String, default: null },
    rows: { type: Number, default: 3 },
  },
  components: {
    ToolTip,
  },
  emits: ["update:modelValue", "onBlur"],
  data() {
    let disabledStyle = "";
    if (this.displayOnly) disabledStyle = "bg-gray-200";
    let list = _.sortBy(this.listArray, ["label"]);
    return {
      allSelected: false,
      disabledStyle,
      list: list,
    };
  },
  computed: {
    rowHeight() {
      // return "h-[6rem]";
      let height = (this.rows + 1).toString();
      return "h-[" + height + "rem]";
    },
    conditionalInputStyling() {
      let style = "";
      if (this.classProp) style += this.classProp;
      return style;
    },
  },
  methods: {
    selectAll() {
      // this.allSelected = !this.allSelected
      // console.log(this.allSelected);
      this.listArray.forEach((item) => {
        item.selected = this.allSelected;
      });
    },
  },
  watch: {
    inputValue(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      growerCropCustomers: [],
    };
  },
  mutations: {
    SET_GROWER_CROP_CUSTOMERS(state, customerData) {
      state.growerCropCustomers = customerData;
    },
    ADD_GROWER_CROP_CUSTOMER(state, customerObject) {
      state.growerCropCustomers.push(customerObject);
    },
    UPDATE_GROWER_CROP_CUSTOMER(state, customerObject) {
      state.growerCropCustomers = state.growerCropCustomers.filter(
        function (obj) {
          return obj.id !== customerObject.id;
        },
      );
      state.growerCropCustomers.push(customerObject);
    },
    // DELETE_GROWER_CROP_CUSTOMER(state, customerObject) {
    //   state.growerCropCustomers = state.growerCropCustomers.filter(function (obj) {
    //     return obj.id !== customerObject.id;
    //   });
    // },
    RESET_GROWER_CROP_CUSTOMERS(state) {
      state.growerCropCustomers = [];
    },
  },
  actions: {
    async getGrowerCropCustomers({ commit }) {
      const data = await client.grower.cropCustomers.read();
      if (data) {
        commit("SET_GROWER_CROP_CUSTOMERS", data.customers);
      }
    },
    async createGrowerCropCustomer({ commit }, customer) {
      const data = await client.grower.cropCustomers.create(null, customer);
      if (data) {
        // console.log(data);
        commit("ADD_GROWER_CROP_CUSTOMER", data);
        this.dispatch("getGrowerCropCustomers");
        return data.id;
      }
    },
    async updateGrowerCropCustomer({ commit }, customer) {
      const data = await client.grower.cropCustomers.update(
        customer.id,
        customer,
      );
      if (data) {
        commit("UPDATE_GROWER_CROP_CUSTOMER", data);
        this.dispatch("getGrowerCropCustomers");
      }
    },
  },
  getters: {
    getGrowerCropCustomerById: (state) => (customerId) => {
      return state.growerCropCustomers.find(
        (customer) => customer.id === parseInt(customerId),
      );
    },
    // getGrowerCropCustomerBuyers: (state) => {
    //   return state.growerCropCustomers.reduce((buyers, customer) => {
    //     if (buyers[customer.buyer_details.id] == null) {
    //       buyers[customer.buyer_details.id] = customer.buyer;
    //     }
    //     return buyers;
    //   }, {});
    // },
    getGrowerCropCustomers:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedGrowerCustomers = state.growerCropCustomers.map(
          (customer) => ({
            ...customer,
            // seller_name: customer.seller_details.name,
          }),
        );
        // _.isEmpty(filter) will return true for functions. Added filterFunction to check for and execute functions
        let functionFilterGrowerCustomers = filterFunction
          ? _.filter(expandedGrowerCustomers, filterFunction)
          : expandedGrowerCustomers;
        let filteredGrowerCustomers = _.isEmpty(filter)
          ? functionFilterGrowerCustomers
          : _.filter(functionFilterGrowerCustomers, filter);
        let matchedGrowerCustomers = _.isEmpty(match)
          ? filteredGrowerCustomers
          : _.filter(filteredGrowerCustomers, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedGrowerCustomers = _.isEmpty(sort)
          ? matchedGrowerCustomers
          : _.sortBy(matchedGrowerCustomers, sort);
        let paginatedGrowerCustomers =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedGrowerCustomers,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedGrowerCustomers;
        return paginatedGrowerCustomers;
      },
  },
};

<!-- component
this component is used to display a table as a weekly calendar
 -->
<!-- basic use: create a table formatted as normal. Use a <table-card> tag in lieu of the <table> tag. 
     For each column, also add a td-label attribute to the data tag to define what will appear as a label for that data cell when in card format. e.g. <td td-label="Name"> 

Note: this component supports table-toolbar and table-header-cell-with-filter-button columns. 
To enable the filter to display on mobile, the parent .vue using this component must include a method to show / hide the 
table column headers and filters. To support this, add a computed class to the <thead> tag like  <thead :class="filterViewState"> 
and then include a computed variable to toggle the class.

    filterViewState() {
      /* this is to show the filters on mobile when the filter button is pressed, and always show the column headers on desktop */
      return this.$store.getters.getViewState
        ? "block" // show the filters on mobile if filter button is pressed
        : "sm:table-header-group hidden"; // hide on mobile if viewsState is false, show on desktop as a table header group
    },

 -->
<template>
  <div class="overflow-auto">
    <span v-if="title">{{ title }} - </span> Week of {{ thisWeek }}
    <table class="table-fixed border text-sm text-gray-600">
      <thead class="bg-gray-50">
        <tr>
          <th
            class="w-[10%] min-w-[10%] border-r-0 px-2 py-2"
            scope="col"
            td-label="Previous"
          >
            <button @click="back" class="-ml-2 p-2">&lt;</button>
          </th>
          <th class="w-[10%] border px-2 py-2" scope="col" td-label="Monday">
            Monday ( {{ monday.getDate() }})
          </th>
          <th class="w-[10%] border px-2 py-2" scope="col" td-label="Tuesday">
            Tuesday ({{ getDayOfMonth(1) }})
          </th>
          <th class="w-[10%] border px-2 py-2" scope="col" td-label="Wednesday">
            Wednesday ({{ getDayOfMonth(2) }})
          </th>
          <th class="w-[10%] border px-2 py-2" scope="col" td-label="Thursday">
            Thursday ({{ getDayOfMonth(3) }})
          </th>
          <th class="w-[10%] border px-2 py-2" scope="col" td-label="Friday">
            Friday ({{ getDayOfMonth(4) }})
          </th>
          <th class="w-[10%] border px-2 py-2" scope="col" td-label="Saturday">
            Saturday ({{ getDayOfMonth(5) }})
          </th>
          <th class="w-[10%] border px-2 py-2" scope="col" td-label="Sunday">
            Sunday ({{ getDayOfMonth(6) }})
          </th>
          <th
            class="w-[10%] min-w-[10%] border px-2 py-2"
            scope="col"
            td-label="Next"
          >
            <button @click="forward" class="-ml-2 p-2">&gt;</button>
          </th>
        </tr>
      </thead>
      <!-- NOTE: components using this calendar must supply rows of 9 cells in the slot
        first cell is under the back arrow, last cell is under the last arrow
        Middle 7 cells are Monday through Sunday
      -->
      <slot></slot>
    </table>
  </div>
</template>
<script>
import {
  getMonday,
  getDayofMonth,
} from "@/components/composables/dateUtils.js";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  emits: ["back", "forward"],
  data() {
    return {
      monday: getMonday(new Date()),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    thisWeek() {
      return (
        this.monday.toLocaleString("default", { month: "short" }) +
        " " +
        this.monday.getDate()
      );
    },
  },
  methods: {
    getDayOfMonth(offset) {
      return getDayofMonth(this.monday, offset);
    },
    back() {
      this.monday.setDate(this.monday.getDate() - 7);
      this.monday = new Date(this.monday);
      this.$emit("back", this.monday);
    },
    forward() {
      this.monday.setDate(this.monday.getDate() + 7);
      this.monday = new Date(this.monday);
      this.$emit("forward", this.monday);
    },
  },
};
</script>

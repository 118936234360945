<template>
  <div class="bg-gray-50">
    <main>
      <div class="bg-white">
        <h1 class="mb-2 text-3xl font-extrabold tracking-tight text-gray-900">
          Crop Listings
        </h1>
        <div v-if="loggedIn">
          <table-card>
            <template #header>
              <table-head
                :columns="[
                  { name: 'org_name', label: 'Grower' },
                  { name: 'crop_type', label: 'Crop' },
                  { name: 'crop_details.variety', label: 'Variety' },
                  {
                    name: 'asking_price',
                    label: 'Asking Price',
                    filtered: false,
                  },
                  {
                    name: 'total_available',
                    label: 'Qty. Avail.',
                    filtered: false,
                  },
                  {
                    name: 'qty_units',
                    label: 'Sold By',
                  },
                  {
                    name: 'harvest_year',
                    label: 'Harvest Year',
                  },
                  { name: 'actions', label: '', filtered: false },
                ]"
                :collection="listings"
                @update:derivedCollection="derivedListings = $event"
                :defaultFilter="defaultFilter"
                :defaultSort="defaultSort"
              >
              </table-head>
            </template>
            <tbody>
              <tr v-for="listing in derivedListings" :key="listing.id">
                <td td-label="Vendor">{{ listing.org_name }}</td>
                <td td-label="Variety">{{ listing.crop_type }}</td>

                <td td-label="Variety">{{ listing.crop_details?.variety }}</td>
                <td td-label="Asking Price">${{ listing.asking_price }}</td>
                <td td-label="Quantity Available">
                  {{ listing.total_available }}
                </td>
                <td td-label="Sold By">
                  {{ listing.qty_units }}
                </td>
                <td td-label="Harvest Year">{{ listing.harvest_year }}</td>
                <td class="link hover:bg-gray-100 hover:text-violet-600">
                  <router-link
                    :to="{
                      name: 'buyer-produce-listings-single',
                      params: { listingId: listing.id },
                    }"
                    class="text-violet-500 hover:text-twilight-900"
                    >View / Inquire
                    <span class="sr-only">View</span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table-card>
        </div>
        <div v-else>
          <p>Please sign up or log in to view listings.</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";

export default {
  components: {
    TableCard,
    TableHead,
  },
  data() {
    return {
      derivedListings: [],
      defaultFilter: {},
      defaultSort: { title: "asc", description: "asc" },
    };
  },
  computed: {
    listings() {
      let listings =
        this.$store.state.buyerListings.buyerListings.filter(
          (listing) => listing.crop_type != "Grape",
        ) || [];
      for (let listing of listings) {
        listing.asking_label =
          listing.asking_price +
          " / " +
          (listing.qty_units == "Tons"
            ? "Ton"
            : listing.qty_units == "Bushels"
              ? "Bushel"
              : "Acre");
        listing.available_label =
          listing.total_available + " " + listing.qty_units;
      }
      return listings;
    },
    loggedIn() {
      return this.$store.getters.getUserData;
    },
  },
  methods: {},
};
</script>

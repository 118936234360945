<template>
  <!-- hauling dashboard - 
    Can go right into an AppFrame router-view OR  
  is a Left Column Component within an app-layout/TwoColumn.vue component
     -->

  <div class="grid grid-cols-1 gap-4 lg:col-span-2">
    <!-- Welcome panel -->
    <section aria-labelledby="profile-overview-title">
      <div class="overflow-hidden rounded-lg bg-white shadow">
        <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
        <div class="bg-white p-6">
          <div class="sm:flex sm:items-center sm:justify-between">
            <div class="sm:flex sm:space-x-5">
              <div class="flex-shrink-0">
                <TruckIcon
                  class="mx-auto h-20 w-20 flex-shrink-0 rounded-full text-twilight-400 group-hover:text-twilight-500"
                  aria-hidden="true"
                />
                <!--<img class="w-20 h-20 mx-auto rounded-full" :src="user.imageUrl" alt="" /> -->
              </div>
              <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p class="py-5 text-3xl font-medium text-gray-600">Hauling</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="grid grid-cols-1 border-t border-gray-200 divide-y divide-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x"
        >
          <div
            v-for="stat in stats"
            :key="stat.label"
            class="px-6 py-5 text-sm font-medium text-center"
          >
            <span class="text-gray-900">{{ stat.value }}</span>
            {{ " " }}
            <span class="text-gray-600">{{ stat.label }}</span>
          </div>
        </div> -->
      </div>
    </section>

    <!-- Actions panel -->
    <section aria-labelledby="quick-links-title">
      <div
        class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0"
      >
        <h2 class="sr-only" id="quick-links-title">Quick links</h2>
        <div
          v-for="(action, actionIdx) in actions"
          :key="action.name"
          :class="[
            actionIdx === 0
              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
              : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1
              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
              : '',
            'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500',
          ]"
        >
          <div>
            <span
              :class="[
                action.iconBackground,
                action.iconForeground,
                'inline-flex rounded-lg p-3 ring-4 ring-white',
              ]"
            >
              <component :is="action.icon" class="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div class="mt-8">
            <h3 class="text-lg font-medium">
              <router-link
                :to="{ name: action.routeName, query: action.routeQuery }"
                class="focus:outline-none"
              >
                <!-- Extend touch target to entire panel -->
                <span class="absolute inset-0" aria-hidden="true" />
                {{ action.name }}
              </router-link>
            </h3>
            <p class="mt-2 text-sm text-gray-500">
              {{ action.blurb }}
            </p>
          </div>
          <span
            class="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
            >Click here to select
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
              />
            </svg>
          </span>
        </div>
      </div>
    </section>
    <section>
      <hauling-schedule />
    </section>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  // AcademicCapIcon,
  BellIcon,
  CashIcon,
  // ClockIcon,
  MenuIcon,
  // ReceiptRefundIcon,
  ClipboardListIcon,
  FolderIcon,
  BookOpenIcon,
  TruckIcon,
  XIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import HaulingSchedule from "@/layouts/common/hauling/HaulingSchedule.vue";

const user = {
  name: "Chelsea Hagon",
  email: "chelseahagon@example.com",
  role: "Human Resources Manager",
  imageUrl:
    "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  { name: "Home", href: "#", current: true },
  { name: "Profile", href: "#", current: false },
  { name: "Resources", href: "#", current: false },
  { name: "Company Directory", href: "#", current: false },
  { name: "Openings", href: "#", current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const stats = [
  { label: "Upcoming Loads", value: 12 },
  { label: "Active loads", value: 4 },
  { label: "Completed loads", value: 22 },
];
const actions = [
  {
    icon: BookOpenIcon,
    name: "New Order",
    blurb:
      "Once you an a hauling vendor have agreed upon pricing, submit orders for hauling on your fields.",
    routeName: "grower-portal-hauling-order",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    icon: ClipboardListIcon,
    name: "View Orders",
    blurb: "View order details, delivery estimates, and fulfilmment status.",
    routeName: "grower-portal-hauling-reservations",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    icon: FolderIcon,
    name: "Request Quotes",
    blurb: "Find new hauling vendors to work with and request quotes.",
    routeName: "grower-portal-discover-search-products",
    routeQuery: { category: "Hauling" },
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    icon: CashIcon,
    name: "Pay an Invoice",
    blurb:
      "Coming Soon: View and reconcile all of your existing invoices. Check in to see what still needs to be paid and view your prior payments.",
    routeName: "grower-portal-hauling-dashboard",
    // routeName: "grower-invoices",
    iconForeground: "text-teal-700",
    iconBackground: "bg-sky-50",
  },
];

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuIcon,
    SearchIcon,
    TruckIcon,
    XIcon,
    HaulingSchedule,
  },
  setup() {
    return {
      user,
      navigation,
      userNavigation,
      stats,
      actions,
    };
  },
};
</script>

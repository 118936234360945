<template>
  <text-input
    v-model="searchTerm"
    input-title="What do you want to do?"
    :wideItem="true"
    placeholder="Search actions..."
  />
  <ActionCardGrid>
    <template #gridItems>
      <ActionCard
        v-for="action in filteredActions"
        :key="action.title"
        :title="action.title"
        :routeName="action.routeName"
        :tags="action.tags"
        :notificationCount="
          $store.getters.actionsGrowerNotificationFactory(
            action.notificationType,
          )
        "
      />
    </template>
  </ActionCardGrid>
</template>

<script>
import ActionCard from "@/components/cards/ActionCard.vue";
import ActionCardGrid from "@/components/cards/ActionCardGrid.vue";
import TextInput from "@/components/form/TextInput.vue";

export default {
  components: { ActionCard, ActionCardGrid, TextInput },
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    actions() {
      return this.$store.getters.getAllGrowerActions;
    },
    filteredActions() {
      if (!this.searchTerm) {
        return this.actions;
      } else {
        // return this.actions.filter((action) =>
        //   action.title.toLowerCase().includes(this.searchTerm.toLowerCase())
        // );
        return this.actions.filter((action) => {
          return action.title
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase());
        });
      }
    },
  },
  methods: {},
};
</script>

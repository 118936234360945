<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'name', label: 'Name', filtered: false },
          { name: 'status', label: 'Status', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="customers"
        @update:derived-collection="derivedCustomers = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New Customer"
              :usePlusIcon="true"
              @buttonClick="newCustomer"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="c in derivedCustomers" :key="c.id">
        <table-data td-label="Name">
          {{ c.name }}
        </table-data>
        <table-data td-label="Status">
          {{ c.status }}
        </table-data>
        <table-data class="text-center">
          <router-link
            v-if="!c.agreementBased"
            :to="{
              name: 'vendor-customer-manage',
              params: { customerId: c.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            {{ c.seller_confirmed === null ? "Respond" : "Manage" }}
          </router-link>
          <div v-else></div>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedCustomers: [],
    };
  },
  computed: {
    customers() {
      let customers = this.$store.state.customers.customers;
      for (let c of customers) {
        if (c.status) continue;
        c.status =
          c.seller_confirmed === null
            ? "New Request"
            : c.buyer_confirmed === null
              ? "Pending"
              : (c.seller_confirmed && c.buyer_confirmed && "Accepted") ||
                "Rejected";
      }
      let quotes = this.$store.state.vendorQuotes.vendorQuotes;
      // console.log("quotes", quotes);
      if (quotes.length > 0) {
        for (let quote of quotes) {
          if (
            !customers.find((customer) => customer.id == quote.buyer_details.id)
          ) {
            let quote_status = "";
            if (quotes.find((quote) => quote.status == "buyer_confirmed")) {
              quote_status = "Active";
            } else {
              quote_status = "Inactive";
            }
            // console.log(
            //   "pushing new customer",
            //   quote.buyer_details?.name,
            //   quote_status,
            // );
            customers.push({
              id: quote.buyer_details?.id,
              name: quote.buyer_details?.name,
              status: quote_status,
              agreementBased: true,
            });
          }
        }
      }
      return customers;
    },
  },
  methods: {
    newCustomer() {
      this.$router.push({ name: "vendor-customer-new" });
    },
  },
};
</script>

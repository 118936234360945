<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Vendor"
    modalDescription="Are you sure you want to permanently delete this vendor?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteVendor"
  />
  <page-card headingTitle="Manage Vendor">
    <template #buttonArea>
      <base-button
        :buttonText="vendor_id ? 'Approve' : 'Save'"
        :marginLeft="false"
        @buttonClick="handleSave"
      />
      <base-button
        v-if="vendor_id"
        :buttonText="buyerConfirmed === null ? 'Reject' : 'Delete'"
        :redButton="true"
        @buttonClick="handleDelete"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :displayOnly="!editable"
        :displayOnlyValue="vendorName"
        :wideItem="true"
        inputTitle="Name"
        inputHtmlType="text"
        v-model="vendorName"
      />
      <variable-string-type-input
        :displayOnly="!editable"
        :displayOnlyValue="vendorEmail"
        :wideItem="true"
        inputTitle="Primary Contact Email"
        inputHtmlType="text"
        v-model="vendorEmail"
      />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

export default {
  props: {
    vendorId: [Number, String],
    navigateTo: {
      type: String,
      default: "vendors",
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
  },
  data() {
    let vendor_id = this.vendorId;
    if (typeof this.vendorId === "string") {
      vendor_id = parseInt(this.vendorId);
    }
    if (vendor_id !== undefined) {
      const vendor = this.$store.getters.getVendorById(vendor_id);
      // console.log("VENDOR", vendor);
      return {
        vendor_id: vendor_id,
        vendorName: vendor.name,
        vendorEmail: vendor.contact_email,
        vendorMessage: vendor.message,
        buyerConfirmed: vendor.buyer_confirmed,
        sellerConfirmed: vendor.seller_confirmed,
        modalIsActive: false,
      };
    } else {
      return {
        vendor_id: null,
        vendorName: "",
        vendorEmail: "",
        vendorMessage: null,
        buyerConfirmed: true,
        sellerConfirmed: null,
        modalIsActive: false,
      };
    }
  },
  methods: {
    createVendor() {
      this.$store.dispatch("createVendor", {
        placeholder_name: this.vendorName,
        placeholder_contact_email: this.vendorEmail.trim(),
      });
      this.$router.push({ name: this.navigateTo });
    },
    deleteVendor() {
      this.$store.dispatch("deleteVendor", this.vendor_id);
      this.$router.push({ name: this.navigateTo });
    },
    approveVendor() {
      this.$store.dispatch("updateVendor", {
        id: this.vendor_id,
        confirmed: true,
      });
      this.$router.push({ name: this.navigateTo });
    },
    rejectVendor() {
      this.$store.dispatch("updateVendor", {
        id: this.vendor_id,
        confirmed: false,
      });
      this.$router.push({ name: this.navigateTo });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    handleDelete() {
      if (this.buyerConfirmed !== null) {
        this.intializeDeleteModal();
      } else {
        this.rejectVendor();
      }
    },
    handleSave() {
      if (this.vendor_id) {
        this.approveVendor();
      } else {
        this.createVendor();
      }
    },
  },
};
</script>

<template>
  <error-notification />
  <div class="flex h-screen overflow-hidden bg-gray-100 pt-5 sm:pt-0">
    <side-menu
      settingsPath="vendor-settings"
      homePath="vendor-portal-home"
      orgPath="vendor-switch-org"
      :navigation="navigation"
      :showSideBar="showSideBar"
      barTheme="bg-dusk-800 text-white"
      @sidebarOpen="(open) => (this.showSideBar = open)"
    />
    <!-- main page area -->
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
      <div class="relative z-10 flex h-20 flex-shrink-0 bg-white shadow">
        <!-- App top bar -->
        <vendor-frame-page-header
          homeRouteName="vendor-portal-home"
          :solutions="solutions"
          @clickSidebarMenu="this.showSideBar = true"
        />
      </div>
      <!-- main content area -->
      <main class="relative flex-1 overflow-y-auto focus:outline-none">
        <div class="py-6">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <!-- Router View Page Content -->
            <router-view></router-view>
            <!-- /End page content -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import VendorFramePageHeader from "@/layouts/vendor-portal/VendorFramePageHeader.vue";
// import { ShoppingCartIcon } from "@heroicons/vue/outline";
import SideMenu from "@/layouts/common/SideMenu.vue";
import ErrorNotification from "@/components/notifications/ErrorNotification.vue";

const solutions = [];

export default {
  components: {
    VendorFramePageHeader,
    SideMenu,
    ErrorNotification,
  },
  setup() {
    return {
      solutions,
    };
  },
  data() {
    return {
      // For whatever reason :src="parcelLogoPath" didn't work so the img path is hard coded for now
      // parcelLogoPath: "../../assets/parcel-logo.svg",
      showSideBar: false,
    };
  },
  methods: {},
  computed: {
    navigation() {
      //IMPORTANT: keep next line so that the navigation is updated when an org accepts a new line of business
      let myOrg = this.$store.getters.getCurrentOrg; // eslint-disable-line

      let appNavigation = { sections: [] };
      appNavigation.sections.push({
        sectionHeading: "Commerce",
        featureFlagForAccess: "allow",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          {
            id: 1,
            name: "Company Profile",
            routeName: "vendor-company-listing",
            icon: "UserCircleIcon",
            featureFlagForAccess: "allow",
          },
          {
            id: 2,
            name: "Listings",
            routeName: "vendor-products-all",
            icon: "ClipboardListIcon",
            featureFlagForAccess: "vp-products",
          },
          {
            id: 3,
            name: "Quotes",
            routeName: "vendor-quotes-all",
            icon: "QuestionMarkCircleIcon",
            featureFlagForAccess: "vp-products",
          },
          {
            id: 4,
            name: "Orders",
            routeName: "vendor-orders-all",
            icon: "ShoppingCartIcon",
            featureFlagForAccess: "vp-products",
          },
          {
            id: 5,
            name: "Recommendations",
            routeName: "vendor-recommendations",
            icon: "DocumentTextIcon",
            featureFlagForAccess: "vp-products",
          },
        ],
      });
      appNavigation.sections.push({
        sectionHeading: "Carbon",
        featureFlagForAccess: "vp-carbon",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          {
            id: 1,
            name: "Carbon",
            routeName: "vendor-carbon",
            icon: "PresentationChartLineIcon",
            featureFlagForAccess: "vp-carbon",
          },
        ],
      });
      appNavigation.sections.push({
        sectionHeading: "Hauling",
        featureFlagForAccess: "vp-hauling",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          {
            id: 1,
            name: "Reservations",
            routeName: "vendor-hauling-reservations",
            icon: "TruckIcon",
            featureFlagForAccess: "vp-hauling",
          },
          {
            id: 2,
            name: "Listings",
            routeName: "vendor-listings",
            icon: "ClipboardListIcon",
            featureFlagForAccess: "vp-hauling",
          },
        ],
      });
      appNavigation.sections.push({
        sectionHeading: "Agronomic Services",
        featureFlagForAccess: "vp-consulting",
        expandable: true,
        expanded: true,
        sectionNavigation: [
          // {
          //   id: 1,
          //   name: "Submit Recommendation",
          //   routeName: "vendor-agronomy",
          //   icon: "DocumentTextIcon",
          //   featureFlagForAccess: "vp-consulting",
          // },
          {
            id: 1,
            name: "Work Orders",
            routeName: "vendor-activities",
            icon: "ClipboardCheckIcon",
            featureFlagForAccess: "vp-consulting",
          },
        ],
      });

      appNavigation.sections.push(
        {
          sectionHeading: "Operations",
          featureFlagForAccess: "allow",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Territories",
              routeName: "vendor-revenue-territories",
              icon: "MapIcon",
              featureFlagForAccess: "allow",
            },
            {
              id: 2,
              name: "Customers",
              routeName: "vendor-customers",
              icon: "UserCircleIcon",
              featureFlagForAccess: "allow",
            },
            {
              id: 3,
              name: "Invoices",
              routeName: "vendor-invoices",
              icon: "CashIcon",
              featureFlagForAccess: "invoices",
            },
            // {
            //   id: 4,
            //   name: "Add Line of Business",
            //   routeName: "vendor-addlob",
            //   icon: "PlusCircleIcon",
            //   featureFlagForAccess: "allow",
            //   requiresAdmin: true,
            // },
          ],
        },
        {
          sectionHeading: "Support",
          featureFlagForAccess: "vp-chat",
          expandable: true,
          expanded: true,
          sectionNavigation: [
            {
              id: 1,
              name: "Discussions",
              routeName: "vendor-chat",
              icon: "ChatAlt2Icon",
              featureFlagForAccess: "vp-chat",
            },
          ],
        },
      );
      return appNavigation;
    },
  },
};
</script>

<template>
  <login-form :userSignup="true" />
</template>
<script>
import LoginForm from "@/layouts/auth/LoginForm.vue";

export default {
  components: { LoginForm },
  mounted() {
    const { referrer } = this.$route.query;
    if (referrer !== undefined) {
      this.$store.dispatch("setReferrer", referrer);
    }
  },
};
</script>

import { universalGetter } from "@/components/composables/universalGetter";
import { useStorage } from "@vueuse/core";
import _ from "lodash";

export default {
  state() {
    return {
      cart_items: useStorage("shopping-cart", []),
      // filterObject: {},
    };
  },
  mutations: {
    SET_CARTITEMS(state, cartItems) {
      state.cart_items = cartItems;
    },
    ADD_CARTITEM(state, cart_itemObject) {
      state.cart_items.push(cart_itemObject);
    },
    UPDATE_CARTITEM(state, cart_itemObject) {
      state.cart_items = state.cart_items.filter(function (obj) {
        return obj.id !== cart_itemObject.id;
      });
      state.cart_items.push(cart_itemObject);
    },
    DELETE_CARTITEM(state, cart_itemObject) {
      state.cart_items = state.cart_items.filter(function (obj) {
        return obj.productId !== cart_itemObject.productId;
      });
    },
    RESET_CARTITEMS(state) {
      state.cart_items = [];
      // state.filterObject = {};
    },
    // SET_FILTER(state, filterObject) {
    //   state.filterObject = filterObject;
    // },
  },
  actions: {
    async addCartItem({ commit }, cart_item) {
      // console.log(data);
      commit("ADD_CARTITEM", cart_item);
    },
    async updateCartItem({ commit }, cart_item) {
      commit("UPDATE_CARTITEM", cart_item);
    },
    async deleteCartItem({ commit }, cart_item) {
      commit("DELETE_CARTITEM", cart_item);
    },
    async clearCartItems({ commit }) {
      commit("RESET_CARTITEMS");
    },
  },
  getters: {
    getCartItemById: (state) => (cart_itemId) => {
      return state.cart_items.find(
        (cart_item) => cart_item.id === parseInt(cart_itemId),
      );
    },
    // UNIVERSAL GETTER
    getCartItems:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let derivedcart_items = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.cart_items,
        );
        return derivedcart_items;
      },
    getDerivedcart_items:
      (state) =>
      ({
        filter = {}, // {crop: "Strawberry"} - return elements where crop is exactly "Strawberry"
        match = "", // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort = {}, // {"acres":'asc',"id":'desc'} - sort by updated then by id
        pageSize = null, // 5 - return 5 elements, else return all
        pageNum = 0, // 0 - return <pageSize> elements, starting at page zero, if pageSize is not null
      }) => {
        let expandedcart_items = state.cart_items;
        let filteredcart_items = _.isEmpty(filter)
          ? expandedcart_items
          : _.filter(expandedcart_items, filter);
        let matchedcart_items = _.isEmpty(match)
          ? filteredcart_items
          : _.filter(filteredcart_items, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedcart_items = _.isEmpty(sort)
          ? matchedcart_items
          : _.orderBy(matchedcart_items, sortColumns, sortOrders);
        let paginatedcart_items =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedcart_items,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedcart_items;
        return paginatedcart_items;
      },
    // getcart_itemsFilter(state) {
    //   if (state.filterObject == null) {
    //     return {};
    //   }
    //   return state.filterObject;
    // },
  },
};

<template>
  <confirm-modal
    :open="modalIsActive"
    modalTitle="Dismiss All Notificaitons"
    modalDescription="Are you sure you want to dismiss all your notifications?"
    modalAction="Dismiss All"
    @clickCancel="cancelDismiss"
    @clickConfirm="clearAllNotifications"
  />
  <nav class="relative">
    <!-- notification center -->
    <div class="relative p-1">
      <button class="focus:outline-none" @click="toggleNotifications">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="h-6 w-6"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
          <path d="M13.73 21a2 2 0 0 1-3.46 0" />
        </svg>
        <span class="badge">{{ allNotifications.length }}</span>
      </button>
    </div>

    <!-- notification dialog -->
    <div
      class="absolute -right-4 mt-1 h-screen max-w-sm overflow-auto rounded bg-white p-3 text-gray-600 shadow-md"
      v-if="showNotifications"
    >
      <div class="mt-3 flex justify-end">
        <button
          class="mb-2 rounded-md bg-blue-700 px-2 text-sm text-blue-200"
          @click="showConfirmDialog"
        >
          clear all
        </button>
      </div>
      <ul class="space-y-3">
        <li
          v-for="notification in allNotifications"
          :key="notification.id"
          class="truncate rounded border p-3"
        >
          <h3>{{ notification.label }}</h3>
          <p>{{ notification.prettyMessage }}</p>
          <div class="flex">
            <div class="mt-1">
              <button
                class="rounded-md bg-blue-700 px-2 text-sm text-blue-200"
                @click="dismissNotification(notification.id)"
              >
                dismiss
              </button>
            </div>
            <div class="mt-1 px-2">
              <button
                class="rounded-md bg-blue-700 px-2 text-sm text-blue-200"
                @click="openNotification(notification)"
              >
                open
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
export default {
  //  props: {"isCorrect":{type:Boolean}, "buttonText":{type:String}, "errorArray":{type:Array},"disabled":{type:Boolean,default:false}},
  //  emits: ["submitButtonClick"],
  components: {
    ConfirmModal,
  },
  data() {
    return {
      showNotifications: false,
      modalIsActive: false,
    };
  },
  methods: {
    submitForm() {
      this.$emit("submitButtonClick");
    },
    toggleNotifications() {
      // console.log("toggle");
      this.showNotifications
        ? (this.showNotifications = false)
        : (this.showNotifications = true);
    },
    openNotification(notification) {
      this.showNotifications = false;
      this.$router.push({ path: notification.link });
    },
    dismissNotification(id) {
      // console.log("dismiss", id);
      this.$store.dispatch("dismissNotification", id);
    },
    showConfirmDialog() {
      this.modalIsActive = true;
    },
    async clearAllNotifications() {
      this.modalIsActive = false;
      for (let notification of this.allNotifications) {
        this.$store.dispatch("dismissNotification", notification.id);
      }
    },
    cancelDismiss() {
      this.modalIsActive = false;
    },
  },
  computed: {
    allNotifications() {
      let allNotifications =
        this.$store.state.notifications.notifications.filter(
          (notification) => notification.read_status == false,
        );
      for (let notification of allNotifications) {
        switch (notification.label) {
          case "Field Update":
            notification.prettyMessage =
              this.$store.getters.getFieldById(notification.field)?.properties
                ?.name + " has been updated";
            break;
          case "Task Update":
            notification.prettyMessage =
              "Task " + notification.task + " has been updated";
            break;
          case "New Task":
            notification.prettyMessage =
              "Task " + notification.task + " has been created";
            break;
          default:
            notification.prettyMessage = notification.message;
        }
      }
      //console.log("notifications", allNotifications);
      return allNotifications;
    },
  },
  watch: {},
};
</script>

<style>
/* unread message count */
.badge {
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #4285f4;
  color: #d7e6fd;
  right: 0;
  border-radius: 9999px;
  font-size: 12px;
  min-width: 18px;
  line-height: 18px;
  min-height: 18px;
  text-align: center;
}
</style>

<script></script>

import Fuse from "fuse.js";

export function FilterProducts(products, search, filterOptions) {
  let filters = false;
  let activeProducts = []; //products;
  let filteredProducts = products;
  // filter product list by selected filters
  let productsMatchingThisFilter = [];
  for (let filter in filterOptions) {
    if (filterOptions[filter].active) {
      let activeOptions = [];
      // console.log("filter ", filter, filterOptions[filter]);
      for (let option in filterOptions[filter].options) {
        if (filterOptions[filter].options[option].checked) {
          activeOptions.push(filterOptions[filter].options[option].value);
        }
      }
      if (activeOptions.length > 0) {
        filters = true;
        productsMatchingThisFilter = [];
        for (let option of activeOptions) {
          let filterColumn = filterOptions[filter].filterColumn;
          // console.log("filterColumn", filterColumn, option, filteredProducts);
          productsMatchingThisFilter = productsMatchingThisFilter.concat(
            filteredProducts.filter((product) => {
              return product[filterColumn]?.includes(option);
            }),
          );
        }
        filteredProducts = productsMatchingThisFilter;
      }
    }
    // console.log("productsMatchingThisFilter", productsMatchingThisFilter);
  }
  // if no filters, show all products
  if (filters === false) {
    activeProducts = products;
  } else {
    activeProducts = filteredProducts;
  }
  // now turn the active products into a set to remove any duplicates
  activeProducts = Array.from(new Set(activeProducts));

  // search for products in filtered list
  if (search.length > 0) {
    const fuse = new Fuse(activeProducts, {
      useExtendedSearch: false,
      keys: [
        "title",
        "description",
        "manufacturer",
        "active_ingredients",
        "searchString",
        "keywords",
        "details",
      ],
      threshold: 0.3,
      distance: 5000,
    });
    activeProducts = fuse.search(search).map((product) => product.item);
  }
  return activeProducts;
}

<template>
  <TabGroup as="div">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            All
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            My To Do List ({{ derivedTasks.length }})
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Activity Status Report
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <activities-list />
      </TabPanel>
      <TabPanel class="pt-10">
        <to-do />
      </TabPanel>
      <TabPanel class="pt-10">
        <activity-status-report />
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import ActivitiesList from "@/layouts/grower-portal/operations/ActivitiesList.vue";
import ActivityStatusReport from "@/layouts/grower-portal/operations/ActivityStatusReport.vue";
import ToDo from "@/layouts/grower-portal/operations/ToDo.vue";
export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    ToDo,
    ActivitiesList,
    ActivityStatusReport,
  },

  data() {
    return {
      searchValue: "",
      filterObject: {},
      pageFixedFilters: {
        userid: this.$store.getters.getCurrentUser.id,
      },
      pageSorts: { deadline: "asc" },
    };
  },
  computed: {
    combinedFilters() {
      return {
        ...this.filterObject,
        ...this.pageFixedFilters,
      };
    },
    derivedTasks() {
      let derivedTasks = this.$store.getters.getDerivedTasks({
        filter: this.combinedFilters, // {category: "Irrigation"} - return elements where category is exactly "Irrigation"
        match: this.searchValue, // "Pajaro" - return all elements containing "pajaro"/"PAJARO"/"pAjArO"
        sort: this.pageSorts,
      });
      derivedTasks = derivedTasks.map((task) => {
        if (task.assigned_org) {
          // console.log(
          //   "task.assigned_org",
          //   task.assigned_org,
          //   this.$store.getters.getOrgById(task.assigned_org),
          //   this.$store.state.vendors.vendors
          // );
          task.user.name = this.$store.getters.getOrgById(
            task.assigned_org,
          )?.name;
        }

        return task;
      });
      derivedTasks = derivedTasks.filter((task) => {
        var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        // console.log(
        //   task.category,
        //   task.status,
        //   task.instructions,
        //   "yesterday",
        //   yesterday,
        //   task.updated,
        //   new Date(task.updated),
        //   new Date(task.updated) > yesterday
        // );
        return (
          task.status === "Assigned" ||
          (task.status === "Complete" &&
            new Date(task.updated) > yesterday &&
            task.user.id === this.$store.getters.getCurrentUser.id)
        );
      });
      return derivedTasks;
    },
  },
  methods: {},
};
</script>

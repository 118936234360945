import client from "@/api/parcel/api.js";
import _ from "lodash";
export default {
  state() {
    return { listings: [], units: [] };
  },
  mutations: {
    SET_VENDOR_INPUT_LISTINGS(state, data) {
      state.listings = data;
    },
    RESET_VENDOR_INPUT_LISTINGS(state) {
      state.listings = [];
    },
    ADD_VENDOR_INPUT_LISTING(state, listing) {
      state.listings.push(listing);
    },
    UPDATE_VENDOR_INPUT_LISTING(state, listing) {
      state.listings = state.listings.filter((a) => a.id !== listing.id);
      state.listings.push(listing);
    },
    REMOVE_VENDOR_INPUT_LISTING(state, listing) {
      state.listings = state.listings.filter((a) => a.id !== listing);
    },
    SET_VENDOR_INPUT_LISTING_UNITS(state, data) {
      state.units = data;
    },
    RESET_VENDOR_INPUT_LISTING_UNITS(state) {
      state.units = [];
    },
    ADD_VENDOR_INPUT_LISTING_UNIT(state, unit) {
      state.units.push(unit);
    },
    UPDATE_VENDOR_INPUT_LISTING_UNIT(state, unit) {
      state.units = state.units.filter((u) => u.id !== unit.id);
      state.units.push(unit);
    },
    REMOVE_VENDOR_INPUT_LISTING_UNIT(state, unit) {
      state.units = state.units.filter((u) => u.id !== unit);
    },
  },
  actions: {
    async getAllVendorInputUnits({ commit }) {
      const data = await client.vendor.input_listings.readAllUnits();
      if (data) {
        commit("SET_VENDOR_INPUT_LISTING_UNITS", data.input_units);
      }
    },
    async getVendorInputListings({ commit }) {
      const data = await client.vendor.input_listings.read();
      if (data) {
        commit("SET_VENDOR_INPUT_LISTINGS", data.input_listings);
      }
    },
    async createVendorInputListing({ commit }, listing) {
      const data = await client.vendor.input_listings.create(null, listing);
      if (data) {
        commit("ADD_VENDOR_INPUT_LISTING", data);
        return data;
      }
    },
    async updateVendorInputListing({ commit }, listing) {
      const data = await client.vendor.input_listings.update(
        listing.id,
        listing,
      );
      if (data) {
        commit("UPDATE_VENDOR_INPUT_LISTING", data);
        return data;
      }
    },
    async deleteVendorInputListing({ commit }, listing) {
      const data = await client.vendor.input_listings.delete(listing);
      if (data) {
        commit("REMOVE_VENDOR_INPUT_LISTING", data);
        return data;
      }
    },
    async getVendorInputUnits({ commit }, listing) {
      const data = await client.vendor.input_listings.readUnits(listing);
      if (data) {
        commit("SET_VENDOR_INPUT_LISTING_UNITS", data.input_units);
        return data.input_units;
      }
    },
    async createVendorInputUnit({ commit }, { listing, unit }) {
      const data = await client.vendor.input_listings.createUnit(listing, unit);
      if (data) {
        commit("ADD_VENDOR_INPUT_LISTING_UNIT", { listing, unit: data });
        return data;
      }
    },
    async updateVendorInputUnit({ commit }, { listing, unit }) {
      const data = await client.vendor.input_listings.updateUnit(unit.id, unit);
      if (data) {
        commit("UPDATE_VENDOR_INPUT_LISTING_UNIT", { listing, unit: data });
        return data;
      }
    },
    async deleteVendorInputUnit({ commit }, unit) {
      const data = await client.vendor.input_listings.deleteUnit(unit);
      if (data) {
        commit("ADD_VENDOR_INPUT_LISTING_UNIT", unit);
        return data;
      }
    },
    async uploadVendorInputImage({ commit, getters }, vendorProductIdAndImage) {
      const data = await client.vendor.input_listings.createInputImage(
        vendorProductIdAndImage.productId,
        vendorProductIdAndImage.file,
      );
      if (data) {
        let product = getters.getVendorInputListingById(
          vendorProductIdAndImage.productId,
        );
        product.image_url = data.image_url;
        commit("UPDATE_VENDOR_PRODUCT", product);

        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
    async uploadVendorInputAttachment(
      { commit, getters },
      productIdAndAttachment,
    ) {
      const data = await client.vendor.input_listings.createAttachment(
        productIdAndAttachment.parentId,
        productIdAndAttachment.file,
      );
      if (data) {
        let product = getters.getVendorInputListingById(
          productIdAndAttachment.parentId,
        );
        product.attachments.push(data);
        commit("UPDATE_VENDOR_PRODUCT", product);
        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
    async removeVendorInputAttachment({ commit, state }, attachmentId) {
      let product = null;
      for (let listing of state.listings) {
        if (!listing?.attachments) {
          continue;
        }
        for (let attachment of listing.attachments) {
          if (attachment.id === attachmentId) {
            product = listing;
            break;
          }
        }
        if (product) {
          break;
        }
      }

      const data =
        await client.vendor.input_listings.removeAttachment(attachmentId);
      if (data) {
        if (product) {
          product.attachments = product.attachments.filter(
            (a) => a.id !== attachmentId,
          );
          commit("UPDATE_VENDOR_PRODUCT", product);
        }
        return data;
        // await this.dispatch("getVendorProducts");
      }
    },
  },
  getters: {
    getVendorInputListingById: (state) => (id) => {
      return state.listings.find((listing) => listing.id === parseInt(id));
    },
    getDerivedVendorInputListings:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedVendors = state.listings;
        let filteredVendors = _.isEmpty(filter)
          ? expandedVendors
          : _.filter(expandedVendors, filter);
        let matchedVendors = _.isEmpty(match)
          ? filteredVendors
          : _.filter(filteredVendors, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortedVendors = _.isEmpty(sort)
          ? matchedVendors
          : _.sortBy(matchedVendors, sort);
        let paginatedVendors =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedVendors,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedVendors;
        return paginatedVendors;
      },
    getVendorInputListingUnitsById: (state) => (id) => {
      return state.units.find((unit) => unit.id === parseInt(id));
    },
    getVendorInputListingUnitsByListingId: (state) => (id) => {
      return state.units.filter((unit) => unit.input_listing === parseInt(id));
    },
  },
};

import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      threads: [],
      placeholder: [null, ""],
    };
  },
  mutations: {
    SET_THREADS(state, threadData) {
      state.threads = threadData;
    },
    SET_PLACEHOLDER_MESSAGE(state, message) {
      state.placeholder = message;
    },
    CLEAR_PLACEHOLDER_MESSAGE(state) {
      state.placeholder = [null, ""];
    },
    ADD_THREAD(state, threadObject) {
      //console.log("thread added");
      state.threads.push(threadObject);
    },
    // UPDATE_THREAD(state, threadObject) {
    //   state.threads = state.threads.filter(function(obj) {
    //     return obj.id !== threadObject.id;
    //   });
    //   state.threads.push(threadObject);
    // },
    // DELETE_THREAD(state, threadObject) {
    //   state.threads = state.threads.filter(function(obj) {
    //     return obj.id !== threadObject.id;
    //   });
    // },
    RESET_THREADS(state) {
      state.threads = [];
    },
  },
  actions: {
    async getThreads({ commit }) {
      const data = await client.threads.read();
      if (data) {
        //console.log("adding threads", data);
        commit("SET_THREADS", data.threads);
      }
    },
    async createThread({ commit }, thread) {
      //console.log("creating thread", thread);
      const data = await client.threads.create(null, thread);
      if (data) {
        // console.log(data);
        commit("ADD_THREAD", data);
        this.dispatch("getThreads");
      }
      return data.id;
    },
  },
  getters: {
    getThreadById: (state) => (threadId) => {
      return state.threads.find((thread) => thread.id === parseInt(threadId));
    },
    getThreadByPartner: (state, getters) => (partnerId) => {
      // the thread is between the current org and the partner, but who is org and who is partner will change depending on the perspective
      // note: will return the first thread found, if there are multiple threads between the same org and partner
      return state.threads.find(
        (thread) =>
          (thread.partner === parseInt(partnerId) &&
            thread.org === getters.getCurrentOrg.id) ||
          (thread.partner === getters.getCurrentOrg.id &&
            thread.org === parseInt(partnerId)),
      );
    },
    getDerivedThreads:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let expandedThreads = state.threads.map((thread) => {
          return {
            ...thread,
            latest_sent: thread.latest?.sent,
          };
        });
        let filteredThreads = _.isEmpty(filter)
          ? expandedThreads
          : _.filter(expandedThreads, filter);
        let matchedThreads = _.isEmpty(match)
          ? filteredThreads
          : _.filter(filteredThreads, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedThreads = _.isEmpty(sort)
          ? matchedThreads
          : _.orderBy(matchedThreads, sortColumns, sortOrders);
        let paginatedThreads =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedThreads,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedThreads;
        return paginatedThreads;
      },
  },
};

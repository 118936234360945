import buyerPortalRoutes from "@/routes/buyerPortalRoutes";

let buyerRouteActions = [];
for (let route of buyerPortalRoutes) {
  // flatten the buyerPortalRoutes array to get an array of objects without nesting
  if (route.children) {
    for (let childRoute of route.children) {
      // skip the route if it has a redirect property
      if (childRoute.redirect) {
        continue;
      }
      // if the route path contains a colon skip it
      if (childRoute.path.includes(":")) {
        continue;
      }
      buyerRouteActions.push({
        title:
          childRoute.meta.title.replace(" | Parcel", "") ||
          childRoute.meta.displayTitle,
        routeObject: { name: childRoute.name },
        tags: [],
        notificationType: null,
      });
    }
  } else {
    buyerRouteActions.push({
      title: route.meta.title || route.meta.displayTitle,
      routeObject: { name: route.name },
      tags: [],
      notificationType: null,
    });
  }
}

let processes = [
  {
    title: "Contract Flow",
    description:
      "View and create contracts, adjust assignments, and stay current with crop orders.",
    featureFlagForAccess: "bp-controller",
    actions: [
      {
        title: "Create Contracts",
        routeObject: { name: "buyer-contracts" },
        description: "",
        tags: ["Contracts", "Buy", "View"],
        notificationType: null,
        active: false,
      },
      {
        title: "View and Adjust Assignments",
        routeObject: { name: "buyer-assignments" },
        description: "",
        tags: ["Assignments", "Buy", "View"],
        notificationType: "activeBuyerControllerOffers",
        active: false,
      },
    ],
  },
];

export default {
  state: {
    actions: buyerRouteActions,
    processes: processes,
    activeProcess: null,
  },
  mutations: {
    SET_ACTIVE_PROCESS(state, process) {
      // create a deep copy of the process object
      state.activeProcess = JSON.parse(JSON.stringify(process));
    },
  },
  actions: {
    setBuyerControllerActionsActiveProcess({ commit }, process) {
      commit("SET_ACTIVE_PROCESS", process);
    },
    clearBuyerControllerActionsActiveProcess({ commit }) {
      commit("SET_ACTIVE_PROCESS", null);
    },
    setActiveProcessBuyerControllerActionToActive(
      { state, commit },
      actionIndex,
    ) {
      let process = state.activeProcess;
      for (let action of process.actions) {
        action.active = false;
      }
      process.actions[actionIndex].active = true;
      commit("SET_ACTIVE_PROCESS", process);
    },
  },
  getters: {
    getAllBuyerControllerActions(state) {
      return state.actions;
    },
    getAllBuyerControllerActionsProcesses(state) {
      let processes = state.processes;
      // place state dependent filters here
      return processes;
    },
    getBuyerControllerActionsActiveProcess(state) {
      return state.activeProcess;
    },
    getBuyerControllerActionsActiveProcessActiveAction(state) {
      return state.activeProcess.actions.find((action) => action.active);
    },
    actionsBuyerControllerNotificationFactory:
      (state, getters, rootState, rootGetters) => (actionNotificationType) => {
        switch (actionNotificationType) {
          case "activeBuyerControllerOffers":
            return rootGetters.getControllerMarketOffers.length;
          // case "cropOrderInquiries":
          //   return rootGetters.getBuyerInquiries.length;
          default:
            return null;
        }
      },
  },
};

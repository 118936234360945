import client from "@/api/parcel/api.js";
// import _ from "lodash";

export default {
  state() {
    return {
      controllerContractSummaries: [],
      controllerOfferSummaries: [],
    };
  },
  mutations: {
    SET_CONTROLLER_CONTRACT_SUMMARIES(state, summaryData) {
      state.controllerAllocations = summaryData;
    },
    SET_CONTROLLER_OFFER_SUMMARIES(state, summaryData) {
      state.controllerAllocations = summaryData;
    },
    RESET_CONTROLLER_SUMMARIES(state) {
      state.controllerContractSummaries = [];
      state.controllerOfferSummaries = [];
    },
  },
  actions: {
    async getControllerContractSummaries({ commit }) {
      const data = await client.controller.summary.readContractsSummary();
      if (data) {
        commit("SET_CONTROLLER_CONTRACT_SUMMARIES", data.buyer_contracts);
      }
    },
    async getControllerOfferSummaries({ commit }) {
      const data = await client.controller.summary.readOffersSummary();
      if (data) {
        commit("SET_CONTROLLER_OFFER_SUMMARIES", data.market_offers);
      }
    },
  },
  getters: {
    getAllControllerContractSummaries: (state) => {
      return state.controllerContractSummaries;
    },
    getAllControllerOfferSummaries: (state) => {
      return state.controllerOfferSummaries;
    },
  },
};

<template>
  <csv-import />
</template>

<script>
import CsvImport from "@/layouts/grower-portal/records/CsvImport.vue";

export default {
  components: {
    CsvImport,
  },
  props: {},
  data() {
    return {
      csv: null,
      importReport: "",
      showSlideOut: false,
    };
  },
  computed: {},
  methods: {},
  setup() {},
  async created() {},
  mounted() {},
};
</script>

<template>
  <template v-if="threadId && thread == null">
    <page-card headingTitle="Discussion could not be found."> </page-card>
  </template>
  <template v-else>
    <chat-window :threadId="threadId" @fetch-messages="roomNavigate" />
  </template>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import ChatWindow from "@/layouts/common/ChatWindow.vue";

export default {
  components: {
    ChatWindow,
    PageCard,
  },
  data() {
    return {
      // commented out to make it obvious this is all loaded by ChatWindow now.
      // users: this.$store.state.messages.users,
      // currentUser: this.$store.getters.getCurrentUser,
      // currentUserId: this.$store.getters.getCurrentUser.id,
      // currentComment: "",
      // shouldNavigate: false,
      // selectedRoom: null,
      // showNewThreadModal: false,
    };
  },
  computed: {
    threadId() {
      return this.$route.params?.threadId;
    },
    thread() {
      return this.$store.getters.getThreadById(this.threadId);
    },
    // commented out to make it obvious this is all loaded by ChatWindow now.
    // threadsFromState() {
    //   let threads = this.$store.getters.getDerivedThreads({
    //     sort: { latest_sent: "desc" },
    //   });
    //
    //   console.log("threads", this.vendorId, threads);
    //   return threads;
    // },
  },
  methods: {
    roomNavigate(e) {
      if (e.room.roomId != this.threadId) {
        this.$router.push({
          name: "buyer-chat-thread",
          params: { threadId: e.room.roomId },
        });
      }
    },
  },
};
</script>

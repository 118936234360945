<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Customer"
    modalDescription="Are you sure you want to permanently delete this customer?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteCustomer"
  />
  <page-card :headingTitle="customerId ? 'Manage Customer' : 'New Customer'">
    <template #buttonArea>
      <base-button
        v-if="buyerConfirmed !== false"
        :buttonText="
          currentCustomer?.seller_confirmed === null && customerId !== null
            ? 'Approve'
            : 'Save'
        "
        :marginLeft="false"
        @buttonClick="handleSave"
      />
      <base-button
        v-if="customerId"
        :buttonText="
          currentCustomer?.seller_confirmed === null ? 'Reject' : 'Delete'
        "
        :redButton="true"
        @buttonClick="handleDelete"
      />
    </template>
    <template #cardDetails>
      <variable-string-type-input
        :displayOnly="customerId"
        :displayOnlyValue="currentCustomer?.name"
        :wideItem="true"
        inputTitle="Name"
        inputHtmlType="text"
        v-model="customerName"
      />
      <variable-string-type-input
        :displayOnly="customerId"
        :displayOnlyValue="currentCustomer?.contact_email"
        :wideItem="true"
        inputTitle="Primary Contact Email"
        inputHtmlType="text"
        v-model="customerEmail"
      />
      <div class="sm:col-span-6">
        <text-area
          v-if="
            customerId !== null &&
            buyerConfirmed !== null &&
            sellerConfirmed === null
          "
          :displayOnly="true"
          :displayOnlyValue="currentCustomer.message"
          inputTitle="Message from customer"
          v-model="customerMessage"
        />
      </div>
    </template>
  </page-card>
</template>

<script>
import TextArea from "@/components/form/TextArea.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DeleteModal,
    TextArea,
  },
  data() {
    // const { customerId } = this.$route.params;
    // if (customerId !== undefined) {
    //   const customer = this.$store.getters.getCustomerById(customerId);
    //   console.log("CUSTOMER", customer);
    //   return {
    //     customerId,
    //     customerName: customer.name,
    //     customerEmail: customer.contact_email,
    //     customerMessage: customer.message,
    //     buyerConfirmed: customer.buyer_confirmed,
    //     sellerConfirmed: customer.seller_confirmed,
    //     modalIsActive: false,
    //   };
    // } else {
    return {
      // customerId: null,
      customerName: "",
      customerEmail: "",
      // customerMessage: null,
      // buyerConfirmed: null,
      // sellerConfirmed: null,
      modalIsActive: false,
    };
    // }
  },
  computed: {
    currentCustomer() {
      let customerId = this.$route.params.customerId;
      let customer = null;
      if (customerId !== undefined) {
        customer = this.$store.getters.getCustomerById(customerId);
      }
      // console.log("computed currentCustomer", customer);
      return customer;
    },
    customerId() {
      return this.currentCustomer?.id;
    },
  },
  methods: {
    submitRequest() {
      this.$store.dispatch("createCustomer", {
        placeholder_name: this.customerName,
        placeholder_contact_email: this.customerEmail,
      });
      this.$router.push({ name: "vendor-customers" });
    },
    saveCustomer() {
      this.$store.dispatch("updateCustomer", {
        id: this.customerId,
        details: {
          placeholder_name: this.customerName,
          placeholder_contact_email: this.customerEmail.trim(),
        },
      });
      this.$router.push({ name: "vendor-customers" });
    },
    deleteCustomer() {
      this.$store.dispatch("deleteCustomer", this.customerId);
      this.$router.push({ name: "vendor-customers" });
    },
    approveCustomer() {
      this.$store.dispatch("updateCustomer", {
        id: this.customerId,
        details: {},
        confirmed: true,
      });
      this.$router.push({ name: "vendor-customers" });
    },
    rejectCustomer() {
      this.$store.dispatch("updateCustomer", {
        id: this.customerId,
        details: {},
        confirmed: false,
      });
      this.$router.push({ name: "vendor-customers" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    handleSave() {
      if (this.customerId) {
        if (this.sellerConfirmed !== null) {
          this.saveCustomer();
        } else {
          this.approveCustomer();
        }
      } else {
        this.submitRequest();
      }
    },
    handleDelete() {
      if (this.sellerConfirmed !== null) {
        this.intializeDeleteModal();
      } else {
        this.rejectCustomer();
      }
    },
  },
  watch: {
    // initialFeature: {
    //   handler(newVal) {
    //     // console.log("updated feature", newVal);
    //     this.currentFeature = newVal;
    //   },
    //   deep: true,
    // },
    // customerId(newVal) {
    //   console.log("watch currentCustomer", this.currentCustomer);
    //   if (this.currentCustomer) {
    //     this.customerId = newVal.id;
    //     this.customerName = newVal.name;
    //     this.customerEmail = newVal.contact_email;
    //     this.customerMessage = newVal.message;
    //     this.buyerConfirmed = newVal.buyer_confirmed;
    //     this.sellerConfirmed = newVal.seller_confirmed;
    //   }
    // },
  },
};
</script>

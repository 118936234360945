<template>
  <router-link
    :to="{
      name: 'vendor-products-all',
    }"
    >To Products</router-link
  >
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<template>
  <div class="max-w-7xl py-3 sm:flex sm:items-center">
    <h3
      class="text-xs font-semibold uppercase tracking-wide"
      :class="active && active < total ? 'text-indigo-600' : 'text-gray-500'"
    >
      Filters
      <span class="sr-only">, active</span>
    </h3>

    <div
      aria-hidden="true"
      class="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"
    />

    <div class="mt-2 sm:ml-4 sm:mt-0">
      <div class="-m-1 flex flex-wrap items-center">
        <span
          v-for="(filterValue, filterKey) in activeFilters"
          :key="filterKey"
          class="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
        >
          <span>{{ filterValue }}</span>
          <button
            type="button"
            @click="clearNamedItem(filterKey)"
            class="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
          >
            <span class="sr-only">Remove filter for {{ filterKey }}</span>
            <svg
              class="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                stroke-linecap="round"
                stroke-width="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </span>
      </div>
    </div>
    <div
      v-if="active && active < total"
      class="ml-4 text-sm font-medium text-indigo-600"
    >
      {{ active }} of {{ total }} records shown
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeFilters: { type: Object },
    active: { type: Number, default: null },
    total: { type: Number, default: null },
  },
  emits: ["clearFilter"],
  methods: {
    clearNamedItem(value) {
      this.$emit("clearFilter", value);
    },
  },
};
</script>

<style></style>

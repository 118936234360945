import client from "@/api/parcel/api.js";
import _ from "lodash";

export default {
  state() {
    return {
      buyerListings: [],
    };
  },
  mutations: {
    SET_BUYER_CROP_LISTINGS(state, listingData) {
      state.buyerListings = listingData;
    },
    RESET_BUYER_CROP_LISTINGS(state) {
      state.buyerListings = [];
    },
  },
  actions: {
    async getBuyerListings({ commit }) {
      const data = await client.buyer.cropListings.read();
      if (data) {
        commit("SET_BUYER_CROP_LISTINGS", data.listings);
      }
    },
  },
  getters: {
    getBuyerListingById: (state) => (listingId) => {
      return state.buyerListings.find(
        (listing) => listing.id === parseInt(listingId),
      );
    },
    getHaulingBuyerListings: (state) => (category) => {
      return state.buyerListings.find(
        (buyerListings) => buyerListings.category === category,
      );
    },
    getDerivedBuyerListings:
      (state) =>
      ({
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        // let expandedBuyerListings = state.buyerListings;
        let expandedBuyerListings = state.buyerListings.map((item) => ({
          ...item,
          ...item.crop_details,
          ...(item.crop_details?.properties
            ? item.crop_details.properties
            : {}),
          ...(item.crop_details?.properties?.details
            ? item.crop_details.properties.details
            : {}),
          variety: item.crop_details?.variety, // bring variety to the top level so it can be filtered on
        }));
        // console.log(
        //   "expanded listings",
        //   expandedBuyerListings,
        //   state.buyerListings
        // );
        let filteredBuyerListings = _.isEmpty(filter)
          ? expandedBuyerListings
          : _.filter(expandedBuyerListings, filter);
        let matchedBuyerListings = _.isEmpty(match)
          ? filteredBuyerListings
          : _.filter(filteredBuyerListings, (item) =>
              _.some(item, (val) =>
                _.includes(_.lowerCase(val), _.lowerCase(match)),
              ),
            );
        let sortColumns = Object.keys(sort);
        let sortOrders = Object.values(sort);
        //console.log("sort in index",sort,sortColumns, sortOrders);
        let sortedBuyerListings = _.isEmpty(sort)
          ? matchedBuyerListings
          : _.orderBy(matchedBuyerListings, sortColumns, sortOrders);
        let paginatedBuyerListings =
          _.isNumber(pageSize) && _.isNumber(pageNum)
            ? _.slice(
                sortedBuyerListings,
                pageSize * pageNum, // skip
                pageSize * pageNum + pageSize, // limit
              )
            : sortedBuyerListings;
        return paginatedBuyerListings;
      },
  },
};

<template>
  <!-- <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"> -->
  <text-input
    :wideItem="true"
    inputTitle="Premium Type"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="premiumTypes[premium?.type]"
  />
  <text-area
    :wideItem="true"
    inputTitle="Terms"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="premium?.terms"
  />
  <!-- Inputs for Non-Other Type Premiums -->
  <text-input
    v-if="premium?.type != 'other'"
    :wideItem="true"
    inputTitle="Lump Total Sum or Per Unit Premium?"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="totalOrUnitTypes[premium?.total_or_unit]"
    help-text="Total means the premium is paid once, Unit means the premium is paid per unit"
  />
  <text-input
    v-if="premium?.type != 'other' && premium?.total_or_unit === 'unit'"
    :wideItem="true"
    inputTitle="Units (Bushels, Bags, Tons, etc)"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="premium?.harvest_units"
  />
  <!-- Inputs for Fixed & Threshold Premium -->
  <CurrencyInput
    v-if="premium?.type === 'fixed' || premium?.type === 'threshold'"
    :wideItem="true"
    inputTitle="Premium"
    inputHtmlType="text"
    :model-value="premium?.base_premium"
    :display-only="true"
    :display-only-value="premium?.base_premium"
    help-text="That value that will be paid per unit or as a lump sum if the threshold is met"
  />
  <!-- Inputs for Threshold Premium -->
  <number-input
    v-if="premium?.type === 'threshold'"
    :wideItem="true"
    inputTitle="Threshold Score"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="premium?.score_target"
    help-text="The score that must be met to receive the premium"
  />
  <!-- Inputs for Threshold & Range Premium -->
  <text-input
    v-if="premium?.type === 'threshold' || premium?.type === 'range'"
    :wideItem="true"
    inputTitle="Score Preferred"
    :display-only="true"
    :display-only-value="scorePreferenceTypes[premium?.score_preference]"
    help-text="lower means you prefer a lower score, higher means you  prefer a higher score"
  />
  <text-input
    v-if="premium?.type === 'threshold' || premium?.type === 'range'"
    :wideItem="true"
    inputTitle="Score Units (CI Score, Grams Carbon, etc)"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="premium?.score_units"
    help-text="The units of the score, e.g. CI Score, Grams Carbon, Brix, etc"
  />
  <!-- Inputs for Range Premium -->
  <number-input
    v-if="premium?.type === 'range'"
    :wideItem="true"
    inputTitle="Lower Bound"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="premium?.score_lower_bound"
    help-text="The lower bound of the range"
  />
  <number-input
    v-if="premium?.type === 'range'"
    :wideItem="true"
    inputTitle="Upper Bound"
    inputHtmlType="text"
    :display-only="true"
    :display-only-value="premium?.score_upper_bound"
    help-text="The upper bound of the range"
  />
  <currency-input
    v-if="premium?.type === 'range'"
    :wideItem="true"
    inputTitle="Premium per Point"
    inputHtmlType="text"
    :modelValue="premium?.premium_per_point"
    :display-only="true"
    :display-only-value="premium?.premium_per_point"
    help-text="The premium paid per point past the lower bound"
  />
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CurrencyInput from "@/components/form/CurrencyInput.vue";
export default {
  props: {
    premium: {
      type: Object,
      default: null,
    },
  },
  components: {
    TextInput,
    CurrencyInput,
    TextArea,
    NumberInput,
  },
  data() {
    return {
      premiumTypes: {
        fixed: "Fixed",
        threshold: "Threshold",
        range: "Range",
        other: "Other",
      },
      totalOrUnitTypes: { total: "Total", unit: "Unit" },
      scorePreferenceTypes: { higher: "Higher", lower: "Lower" },
    };
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>

import client from "@/api/parcel/api.js";

export default {
  state() {
    return {
      profile: {},
    };
  },
  mutations: {
    SET_PROFILE(state, profileData) {
      state.profile = profileData;
    },
    RESET_PROFILE(state) {
      state.profile = {};
    },
  },
  actions: {
    async getProfile({ commit }) {
      const data = await client.profile.read();
      if (data) {
        commit("SET_PROFILE", data);
      }
    },
    async updateProfile({ commit }, profile) {
      const data = await client.profile.update(null, profile);
      if (data) {
        // console.log(data);
        commit("SET_PROFILE", data);
      }
    },
    async uploadProfileAvatar(_, profileAvatar) {
      const data = await client.profile.createProfileLogo(null, profileAvatar);
      if (data) {
        // console.log(data);
        return data;
        // await this.dispatch("getProfile");
      }
    },
  },
  getters: {
    getCurrentUser(state) {
      return state.profile;
    },
  },
};

<template>
  <nav class="relative">
    <!-- notification center -->
    <div class="relative p-1">
      <button class="focus:outline-none" @click="showCart">
        <ShoppingCartIcon class="h-6 w-6" />
        <span class="badge -mb-4" v-if="cartItems.length > 0">{{
          cartItems.length
        }}</span>
      </button>
    </div>

    <!-- notification dialog -- >
    <div
      class="absolute -right-4 mt-1 h-screen max-w-sm overflow-auto rounded bg-white p-3 text-gray-600 shadow-md"
      v-if="showNotifications"
    >
      <div class="mt-3 flex justify-end">
        <button
          class="mb-2 rounded-md bg-blue-700 px-2 text-sm text-blue-200"
          @click="showConfirmDialog"
        >
          clear all
        </button>
      </div>
      <ul class="space-y-3">
        <li
          v-for="notification in cartItems"
          :key="notification.id"
          class="truncate rounded border p-3"
        >
          <h3>{{ notification.label }}</h3>
          <p>{{ notification.prettyMessage }}</p>
          <div class="flex">
            <div class="mt-1">
              <button
                class="rounded-md bg-blue-700 px-2 text-sm text-blue-200"
                @click="dismissNotification(notification.id)"
              >
                dismiss
              </button>
            </div>
            <div class="mt-1 px-2">
              <button
                class="rounded-md bg-blue-700 px-2 text-sm text-blue-200"
                @click="openNotification(notification)"
              >
                open
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  --></nav>
</template>

<script>
import { ShoppingCartIcon } from "@heroicons/vue/outline";
export default {
  //  props: {"isCorrect":{type:Boolean}, "buttonText":{type:String}, "errorArray":{type:Array},"disabled":{type:Boolean,default:false}},
  //  emits: ["submitButtonClick"],
  components: {
    ShoppingCartIcon,
  },
  data() {
    return {};
  },
  methods: {
    showCart() {
      this.$router.push({ name: "grower-portal-discover-cart" });
    },
  },
  computed: {
    cartItems() {
      let cartItems = this.$store.state.shoppingCart["cart_items"];
      if (!cartItems) return [];
      return cartItems;
    },
  },
  watch: {},
};
</script>

<style>
/* unread message count */
.badge {
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #4285f4;
  color: #d7e6fd;
  right: 0;
  border-radius: 9999px;
  font-size: 12px;
  min-width: 18px;
  line-height: 18px;
  min-height: 18px;
  text-align: center;
}
</style>

<script></script>

<template>
  <number-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Est. Yield (TPA)"
    :required="true"
    v-model="tpa"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Variety"
    inputHtmlType="text"
    v-model="variety"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Rows"
    inputHtmlType="text"
    v-model="rows"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Planted"
    inputHtmlType="text"
    v-model="planted"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Grafted"
    inputHtmlType="text"
    v-model="grafted"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Rootstock"
    inputHtmlType="text"
    v-model="rootstock"
  />
  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Clone"
    inputHtmlType="text"
    v-model="clone"
  />
  <number-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Row Spacing"
    inputHtmlType="text"
    v-model="rowspacing"
  />
  <number-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="Vine Spacing"
    inputHtmlType="text"
    v-model="plantspacing"
  />

  <variable-string-type-input
    :wideItem="true"
    :displayOnly="false"
    inputTitle="AVA"
    inputHtmlType="text"
    v-model="ava"
  />
</template>

<script>
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";

export default {
  components: {
    VariableStringTypeInput,
    NumberInput,
  },
  emits: ["updateFieldDetails"],
  props: {
    crop: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    let details = this.crop?.details;
    let parent = null;

    if (!details || details == null) {
      details = {};
    }
    // console.log("details", details);
    return {
      tpa: details.tpa,
      rows: details.rows,
      variety: details.variety,
      planted: details.planted,
      grafted: details.grafted,
      clone: details.clone,
      rootstock: details.rootstock,
      rowspacing: details.rowspacing,
      plantspacing: details.plantspacing,
      ava: details.ava,
      parent,
    };
  },
  methods: {},
  computed: {
    detailsToReturn() {
      return {
        tpa: this.tpa,
        rows: this.rows,
        variety: this.variety,
        planted: this.planted,
        grafted: this.grafted,
        clone: this.clone,
        rootstock: this.rootstock,
        rowspacing: this.rowspacing,
        plantspacing: this.plantspacing,
        ava: this.ava,
      };
    },
  },
  watch: {
    crop: {
      handler: function (newVal) {
        if (newVal.details == this.details) return;
        this.details = newVal.details;
        this.tpa = this.details.tpa;
        this.rows = this.details.rows;
        this.variety = this.details.variety;
        this.planted = this.details.planted;
        this.grafted = this.details.grafted;
        this.clone = this.details.clone;
        this.rootstock = this.details.rootstock;
        this.rowspacing = this.details.rowspacing;
        this.plantspacing = this.details.plantspacing;
        this.ava = this.details.ava;
      },
      deep: true,
    },
    detailsToReturn(value) {
      return this.$emit("updateFieldDetails", value);
    },
  },
};
</script>

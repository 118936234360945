<template>
  <delete-modal
    :open="modalIsActive"
    modalTitle="Delete Reservation"
    modalDescription="Are you sure you want to permanently delete this reservation?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteReservation"
  />
  <page-card :headingTitle="'Reservation Id: ' + currentReservation.id">
    <template #buttonArea>
      <base-button
        v-if="this.routeParam"
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
      />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-3">
        <text-input
          inputTitle="Field Name"
          :displayOnly="true"
          :displayOnlyValue="
            this.$store.getters.getFieldById(currentReservation.origin_field)
              ?.properties?.name
          "
        />
      </div>
      <select-dropdown
        :wideItem="true"
        inputTitle="Status"
        :displayOnly="true"
        :displayOnlyValue="currentReservation?.status"
      />
      <div class="sm:col-span-3">
        <text-input
          inputTitle="Hauling Partner"
          :displayOnly="true"
          :displayOnlyValue="reservationListing?.org_name"
        />
      </div>
      <div class="sm:col-span-3">
        <text-input
          inputTitle="Hauling Equipment"
          :displayOnly="true"
          :displayOnlyValue="reservationListing?.equipment_type.toString()"
        />
      </div>
      <div class="sm:col-span-3">
        <number-input
          inputTitle="Tons"
          :displayOnly="true"
          :displayOnlyValue="
            currentReservation.quantity ? currentReservation.quantity : 0
          "
        />
      </div>
      <div class="sm:col-span-3">
        <number-input
          inputTitle="Loads"
          :displayOnly="true"
          :displayOnlyValue="
            currentReservation.loads_quantity
              ? currentReservation.loads_quantity
              : 0
          "
        />
      </div>
      <div class="sm:col-span-3">
        <date-time-input
          inputTitle="Harvest Date"
          :displayOnly="true"
          :displayOnlyValue="formatDate(this.currentReservation?.datetime)"
          :step="1"
        />
      </div>
      <div class="sm:col-span-3">
        <date-time-input
          inputTitle="Delivery Date"
          :displayOnly="true"
          :displayOnlyValue="
            formatDate(currentReservation.destination_datetime)
          "
          :step="1"
        />
      </div>
      <div class="sm:col-span-6">
        <text-area
          inputTitle="Destination"
          :displayOnly="true"
          :displayOnlyValue="currentReservation?.destination_text"
          classProp="h-20"
        />
      </div>
    </template>
  </page-card>
</template>

<script>
import { reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TextInput from "@/components/form/TextInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    let routeParam = route.params.reservationId;
    let currentReservation = reactive(
      store.getters.getCustomerReservationById(route.params.reservationId),
    );
    return {
      routeParam,
      currentReservation,
      reservationListing: computed(() =>
        store.getters.getBuyerListingById(currentReservation.listing),
      ),
    };
  },

  components: {
    PageCard,
    BaseButton,
    TextInput,
    TextArea,
    DateTimeInput,
    SelectDropdown,
    NumberInput,
    DeleteModal,
  },
  data() {
    return {
      modalIsActive: false,
    };
  },
  methods: {
    // submitReservation() {
    //   console.log("SAVE", this.currentReservation);
    //   this.$store.dispatch(
    //     "updateCustomerReservation",
    //     this.currentReservation
    //   );
    //   this.$router.push({ name: "grower-portal-hauling-reservations" });
    // },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    deleteReservation() {
      this.modalIsActive = false;
      // console.log("DELETE");
      this.$store.dispatch(
        "deleteCustomerReservation",
        this.currentReservation,
      );
      this.$router.push({ name: "grower-portal-hauling-reservations" });
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    formatDate(date) {
      if (date == null) return null;
      let dateObj = new Date(date);
      return (
        dateObj.getFullYear() +
        "-" +
        (dateObj.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        dateObj.getDate().toString().padStart(2, "0") +
        "T" +
        dateObj.getHours().toString().padStart(2, "0") +
        ":" +
        dateObj.getMinutes().toString().padStart(2, "0") +
        ":" +
        dateObj.getSeconds().toString().padStart(2, "0")
      );
    },
  },
};
</script>

<template>
  <router-link
    @click="$emit('clickOnAction')"
    :to="routeObject"
    class="relative col-span-1 flex rounded-md shadow-sm"
  >
    <div
      v-if="stepNumber"
      :class="[
        stepGreen ? 'bg-green-500' : 'bg-slate-500',
        'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
      ]"
    >
      {{ stepNumber.toString() }}
    </div>
    <div
      :class="
        stepNumber
          ? 'rounded-r-md border-b border-r border-t'
          : 'rounded-md border'
      "
      class="flex flex-1 items-center justify-between truncate border-gray-200 bg-white"
    >
      <div
        v-if="notificationCount > 0"
        class="absolute -right-2 -top-2 flex h-6 w-6 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white"
      >
        {{ notificationCount <= 9 ? notificationCount : "9+" }}
      </div>

      <div class="flex-1 truncate px-4 py-4 text-sm">
        <h1 class="font-medium text-gray-900 hover:text-blue-600">
          {{ title }}
        </h1>
        <!-- <p class="text-gray-500">{{ prettyTags }}</p> -->
      </div>
      <div class="flex-shrink-0 pr-2">
        <span class="sr-only">Go to page</span>
        <OutlineHeroIcon
          :name="'ExternalLinkIcon'"
          classProp="h-5 w-5"
          aria-hidden="true"
        />
      </div>
    </div>
  </router-link>
</template>

<script>
import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";

export default {
  components: {
    OutlineHeroIcon,
  },
  emits: ["clickOnAction"],
  props: {
    title: {
      type: String,
      required: true,
    },
    routeObject: {
      type: Object,
      required: true,
      default: () => ({ name: "grower-portal-home" }),
    },
    tags: {
      type: Array,
      required: true,
    },
    notificationCount: {
      type: Number,
      default: null,
    },
    stepNumber: {
      type: [String, Number],
      default: null,
    },
    stepGreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prettyTags() {
      return this.tags.join(", ");
    },
  },
};
</script>

<style>
/* Ensure the badge content is centered and maintains a circular shape */
.badge-content {
  min-width: 1.5rem; /* 24px */
  height: 1.5rem; /* 24px */
  padding: 0.25rem; /* 4px */
  border-radius: 9999px; /* Fully rounded */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem; /* 12px */
  line-height: 1; /* Normal line-height */
}
</style>

<template>
  <button class="flex" v-if="sorted" @click="toggleSort">
    {{ headerText }}
    <arrow-narrow-up-icon
      v-if="sortOrder == 'asc'"
      class="mt-1 h-3 w-3 text-twilight-400 print:hidden"
    />
    <arrow-narrow-down-icon
      v-if="sortOrder == 'desc'"
      class="mt-1 h-3 w-3 text-twilight-400 print:hidden"
    />
    <div v-if="sortOrder === ''" class="pl-3"></div>
    <slot></slot>
  </button>
  <div class="flex" v-else>{{ headerText }}</div>
</template>
<script>
import { ArrowNarrowDownIcon, ArrowNarrowUpIcon } from "@heroicons/vue/outline";

export default {
  components: {
    ArrowNarrowDownIcon,
    ArrowNarrowUpIcon,
  },
  props: {
    headerText: { type: String },
    sorted: { type: Boolean, default: false },
    sortColumns: { type: Object, default: new Object() },
    sortColumn: { type: String, default: "" },
  },
  emits: ["sort"],
  data() {
    let sortOrder = "";
    if (this.sortColumns[this.sortColumn]) {
      sortOrder = this.sortColumns[this.sortColumn];
    }
    return {
      sortOrder: sortOrder,
    };
  },
  methods: {
    toggleSort() {
      //console.log("before toggle sort",this.sortOrder, this.sortColumns,this.sortColumn);
      let sort = {};
      // first time sorting this column, or already sorted descending
      sort[this.sortColumn] = "asc";
      this.sortOrder = "asc";
      if (this.sortColumns[this.sortColumn] === "asc") {
        sort[this.sortColumn] = "desc";
        this.sortOrder = "desc";
      }
      this.$emit("sort", sort);
      // console.log("toggle sort", sort, this.sortOrder);
    },
  },
  watch: {
    sortColumns: function () {
      //console.log("order changed",newOrder);
      if (
        Object.keys(this.sortColumns).find((key) => key === this.sortColumn)
      ) {
        this.sortOrder = this.sortColumns[this.sortColumn];
      } else {
        this.sortOrder = "";
      }
    },
  },
};
</script>

<template>
  <page-card headingTitle="New Crop Listing">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newProduceListingFormId"
      />
    </template>
    <template #cardDetails>
      <produce-listing-form :formId="newProduceListingFormId" />
    </template>
  </page-card>
</template>

<script>
import ProduceListingForm from "@/layouts/grower-portal/sell/ProduceListingForm.vue";
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";

export default {
  components: {
    PageCard,
    SubmitButton,
    ProduceListingForm,
  },
  data() {
    return {
      newProduceListingFormId: "newProduceListing",
    };
  },
};
</script>
